import React from 'react'

import { NxEsportIcon } from '@playvs-inc/nexus-components'

import { EsportSlug } from '@plvs/graphql/types'

export const transformForgeEsportSlugToMapToNxEsportLogoMap = (
  slug: EsportSlug | null | undefined
): React.FC => {
  if (!slug) {
    return ({ children }) => <>{children}</>
  }

  return (props) => <NxEsportIcon esportSlug={slug} {...props} />
}
