import React from 'react'
import { Provider, SchoolProvider } from '@plvs/graphql/generated'
import { makeStyles } from '@material-ui/core'
import {
  Battlenet,
  Discord,
  Epicgames,
  Playstation,
  Smite,
  Steam,
  Twitch,
  Xbox,
  Youtube,
  StayPluggedIn,
  Nintendoswitch,
  RiotGames,
  Vesl,
  Chess,
  StreetFighter6,
  Ea,
  Nba2K,
} from '@playvs-inc/nexus-icons'
import clsx from 'clsx'

const useStyles = makeStyles((theme) => ({
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    minHeight: 56,
    minWidth: 56,
  },
  icon: {
    color: theme.palette.ColorIconInvert,
    height: '32px',
    width: '32px',
  },
  smiteIcon: {
    color: '#FDE081',
    height: '42px',
    width: '42px',
  },
  providerWrapper: {
    backgroundColor: theme.palette.ColorBackgroundInvert,
  },
  smiteWrapper: {
    backgroundColor: '#7E6102',
  },
  youtubeIcon: {
    minWidth: 56,
    minHeight: 55,
  },
  veslIcon: {
    minWidth: 42,
    minHeight: 40,
  },
}))

export interface LogoMap {
  [key: string]: string | React.ReactElement
}

export const AccountProviderLogo: React.FC<{
  provider: Provider | SchoolProvider
}> = ({ provider }) => {
  const styles = useStyles()

  const accountProviderLogoMap: LogoMap = {
    [Provider.Discord]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Discord}
      >
        <Discord className={styles.icon} />
      </span>
    ),
    [SchoolProvider.DiscordSchool]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={SchoolProvider.DiscordSchool}
      >
        <Discord className={styles.icon} />
      </span>
    ),
    [Provider.Riot]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Riot}
      >
        <RiotGames className={styles.icon} />
      </span>
    ),
    [Provider.Smite]: (
      <span
        className={clsx(styles.iconWrapper, styles.smiteWrapper)}
        data-testid={Provider.Smite}
      >
        <Smite className={styles.smiteIcon} />
      </span>
    ),
    [Provider.Steam]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Steam}
      >
        <Steam className={styles.icon} />
      </span>
    ),
    [Provider.EpicGames]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.EpicGames}
      >
        <Epicgames className={styles.icon} />
      </span>
    ),
    [Provider.BattleDotNet]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.BattleDotNet}
      >
        <Battlenet className={styles.icon} />
      </span>
    ),
    [Provider.Twitch]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Twitch}
      >
        <Twitch className={styles.icon} />
      </span>
    ),
    [SchoolProvider.TwitchSchool]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={SchoolProvider.TwitchSchool}
      >
        <Twitch className={styles.icon} />
      </span>
    ),
    [Provider.XBoxLive]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.XBoxLive}
      >
        <Xbox className={styles.icon} />
      </span>
    ),
    [Provider.PlayStationNetwork]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.PlayStationNetwork}
      >
        <Playstation className={styles.icon} />
      </span>
    ),
    [Provider.Nintendo]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Nintendo}
      >
        <Nintendoswitch className={clsx(styles.icon, styles.providerWrapper)} />
      </span>
    ),
    [Provider.Youtube]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Youtube}
      >
        <Youtube className={clsx(styles.icon, styles.youtubeIcon)} />
      </span>
    ),
    [SchoolProvider.YoutubeSchool]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={SchoolProvider.YoutubeSchool}
      >
        <Youtube className={styles.icon} />
      </span>
    ),
    [Provider.Spin]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Spin}
      >
        <StayPluggedIn className={styles.icon} />
      </span>
    ),
    [Provider.Vesl]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Vesl}
      >
        <Vesl className={clsx(styles.icon, styles.veslIcon)} />
      </span>
    ),
    [Provider.ChessKid]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.ChessKid}
      >
        <Chess className={styles.icon} />
      </span>
    ),
    [Provider.ChessDotCom]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.ChessDotCom}
      >
        <Chess className={styles.icon} />
      </span>
    ),
    [Provider.ElectronicArts]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.ElectronicArts}
      >
        <Ea className={styles.icon} />
      </span>
    ),
    [Provider.StreetFighter]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.StreetFighter}
      >
        <StreetFighter6 className={styles.icon} />
      </span>
    ),
    [Provider.Nba2K]: (
      <span
        className={clsx(styles.iconWrapper, styles.providerWrapper)}
        data-testid={Provider.Nba2K}
      >
        <Nba2K className={styles.icon} />
      </span>
    ),
  }
  const mappedProvider = accountProviderLogoMap[provider]
  const returnedProvider = mappedProvider || <></>
  return returnedProvider as React.ReactElement
}
