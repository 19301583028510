import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, useTheme } from '@material-ui/core'

import { NxComboButton, NxDropdownButton } from '@playvs-inc/nexus-components'
import { Moremenu } from '@playvs-inc/nexus-icons'
import { Path } from '@plvs/const'
import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { MatchRow } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/MatchRow'
import { findMatchTourClicked } from '@plvs/respawn/features/analytics'
import dayjs from '@plvs/respawn/init/dayjs'
import {
  useActiveGlobalChatConversationsVar,
  useActiveGlobalWindowsVar,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { useScheduleRenderControllerContext } from '@plvs/respawn/renderController/schedule/ScheduleRenderControllerProvider'
import { hasRoleForResource } from '@plvs/utils'
import { useActionAndInfoSection } from '@plvs/rally/features/match/lobby/utils/useActionAndInfoSection'
import { ManageMatchDropdownMenuItems } from '@plvs/respawn/renderController/matchLobby/lobby/types/lobbyRenderController.types'
import { useBreakpointXs, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { determineRescheduleStepOnClick } from '@plvs/respawn/features/match-lobby/ActionAndInfoSection.helpers'
import {
  DropdownActionHandlersMap,
  useGetManageMatchActions,
} from '@plvs/respawn/features/match/useGetManageMatchActions'
import { ForfeitMatchDialog } from '@plvs/rally/features/match/cancel/ForfeitMatchDialog'
import { RescheduleMatchDialog } from '@plvs/respawn/features/rescheduleMatch/reschedule'
import { RescheduleMatchStep } from '@plvs/respawn/features/rescheduleMatch/rescheduleMatch'
import { onOpenGlobalChat } from '@plvs/rally/features/chat/utils'
import {
  determineScheduleMatchRowConditions,
  isDropdownDisabled,
} from './ScheduleMatchRow.helpers'

type MatchRowProps = {
  match: MappedMatch
  isQueueMatch?: boolean
  showEsportAccent?: boolean
}

export const ScheduleMatchRow: React.FC<MatchRowProps> = ({
  match,
  isQueueMatch = false,
  showEsportAccent = false,
}) => {
  const theme = useTheme()
  const isMobile = useBreakpointXs()
  const navigate = useNavigate()
  const { userId, userRoles } = useUserIdentityFn()
  const { activeConversations } = useActiveGlobalChatConversationsVar()
  const activeConversationsList = useActiveGlobalWindowsVar()
  const { findMatchTour, initiatedTimestamp } = useProductTours()
  const { schedule } = useScheduleRenderControllerContext()
  const {
    showRescheduleItem,
    showViewRescheduleItem,
    isRescheduleDialogOpen,
    setIsRescheduleDialogOpen,
    step,
    setStep,
    isForfeitDialogOpen,
    setIsForfeitDialogOpen,
    forfeitStep,
    setForfeitStep,
    loading,
  } = useActionAndInfoSection({
    esportSlug: match.esport?.slug,
    matchId: match.id,
    competitionGroup: match.competitionGroup,
  })
  const { id } = match

  const isMemberOfBothTeams = match.team2.id
    ? hasRoleForResource(userRoles, [match.team1.id, match.team1.school.id]) &&
      hasRoleForResource(userRoles, [match.team2.id, match?.team2?.school?.id])
    : false

  const myTeamId = match?.team1?.isMyTeam ? match.team1.id : match.team2.id
  const opposingTeamId = match?.team1?.isMyTeam
    ? match.team2.id
    : match.team1.id

  const manageMatchMenuItems = React.useMemo(() => {
    return schedule.getMenuItemIdsForSchedulePageDropdown({
      userRoles,
      team1: {
        id: match.team1.id,
        schoolId: match.team1.school.id,
      },
      team2: {
        id: match?.team2?.id ?? '',
        schoolId: match?.team2?.school?.id ?? null,
      },
      competitionGroup: match.competitionGroup,
    })
  }, [userRoles, id, match.slotId])

  const onNavigateToMatchLobbyClick = (): void => {
    const lobbyUrl = isQueueMatch
      ? `/app/queue-lobby/${match.slotId}/${match.team1.id}`
      : `${Path.Match}/${id}`

    navigate(lobbyUrl)

    if (findMatchTour?.isActive()) {
      findMatchTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: 3,
        clickTarget: 'Complete Button',
      })
      findMatchTour?.complete()
    }
  }

  const disabledDropdown = isDropdownDisabled({
    status: match.status,
    team2Id: match.team2.id,
    competitionGroup: match.competitionGroup,
    isQueueMatch,
  })

  const dropdownActionHandlers: DropdownActionHandlersMap = {
    [ManageMatchDropdownMenuItems.Reschedule]: {
      onClick: (): void => {
        setIsRescheduleDialogOpen(true)
      },
    },
    [ManageMatchDropdownMenuItems.ViewReschedule]: {
      onClick: () => {
        determineRescheduleStepOnClick({
          matchId: id,
          matchRescheduleRequests: match.matchRescheduleRequests,
          setStep,
          setIsRescheduleDialogOpen,
          myTeamId,
          opposingTeamId,
          isMemberOfBothTeams,
        })
      },
    },
    [ManageMatchDropdownMenuItems.Forfeit]: {
      onClick: (): void => setIsForfeitDialogOpen(true),
    },
    [ManageMatchDropdownMenuItems.JoinQueue]: {
      onClick: onNavigateToMatchLobbyClick,
    },
    [ManageMatchDropdownMenuItems.Open]: {
      onClick: onNavigateToMatchLobbyClick,
    },
    [ManageMatchDropdownMenuItems.Chat]: {
      onClick: () => {
        onOpenGlobalChat({
          uniqueName: id,
          activeConversations,
          activeConversationsList,
        })
      },
    },
  }

  const manageMatchDropdownConditionMap = determineScheduleMatchRowConditions({
    status: match.status,
    team2Id: match.team2.id,
    isQueueMatch,
    matchId: id,
    showRescheduleItem,
    showViewRescheduleItem,
  })

  const filteredMenuItemIds = disabledDropdown
    ? []
    : manageMatchMenuItems.items.filter((id) =>
        id in manageMatchDropdownConditionMap
          ? manageMatchDropdownConditionMap[id]
          : true
      )

  const renderedMenuItems = useGetManageMatchActions({
    menuItemIds: filteredMenuItemIds,
    esportSlug: match.esport?.slug,
    competitionGroup: match.competitionGroup,
    opposingTeamId,
    matchId: id,
    dropdownActionHandlers,
    withLabels: false,
  })

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      <MatchRow
        actions={
          <Box alignItems="flex-end" display="flex" justifyContent="center">
            {isMobile && (
              <NxDropdownButton
                data-testid="open-match-btn-icon"
                disabled={disabledDropdown}
                icon={<Moremenu height={20} width={20} />}
                menuBackgroundColor={theme.palette.ColorBackgroundBase}
                menuItems={renderedMenuItems}
                // This is a temporary fix. The NxDropdownButton does not merge the styles correctly when passed a class name.
                // https://playvs.atlassian.net/browse/MATCH-7567
                style={{ border: 'none' }}
                variant="secondary"
              />
            )}

            {!isMobile && (
              <NxComboButton
                data-testid="open-match-btn"
                disabled={disabledDropdown}
                label="Open Match"
                menuBackgroundColor={theme.palette.ColorBackgroundBase}
                menuItems={renderedMenuItems}
                primaryOnClick={onNavigateToMatchLobbyClick}
                variant="secondary"
              />
            )}
          </Box>
        }
        isMobile={isMobile}
        isQueueMatch={isQueueMatch}
        match={match}
        showEsportAccent={showEsportAccent}
      />
      <ForfeitMatchDialog
        closeDialog={(): void => setIsForfeitDialogOpen(false)}
        esportSlug={match.esport?.slug}
        isDialogOpen={isForfeitDialogOpen}
        match={{
          ...match,
          esportSlug: match.esport.slug ?? null,
          phaseType: match.slot?.phase?.type,
          status: match.status ?? null,
        }}
        setStep={setForfeitStep}
        step={forfeitStep}
        teamId={myTeamId}
      />
      <RescheduleMatchDialog
        closeDialog={(): void => setIsRescheduleDialogOpen(false)}
        esportSlug={match.esport?.slug}
        isDialogOpen={isRescheduleDialogOpen}
        match={{
          ...match,
          esportSlug: match.esport.slug ?? null,
          phaseType: match.slot?.phase?.type,
          status: match.status ?? null,
        }}
        onReschedule={(): void => {}}
        setStep={setStep}
        step={step as RescheduleMatchStep}
        teamId={myTeamId}
      />
    </WaitTillLoaded>
  )
}
