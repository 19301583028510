import React from 'react'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'

export const useGetAssignAwardIcon = (
  award: string
): React.ReactElement | undefined => {
  const map: Record<string, JSX.Element> = {
    'Rookie MVP': (
      <NxSpot
        domain="trophy"
        height={100}
        size="large"
        variant="primary"
        width={100}
      />
    ),
  }
  return map[award]
}
