import React, { useMemo } from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import {
  NxButton,
  NxSelectOption,
  NxTextInput,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { useSnackbar } from 'notistack'
import {
  refetchGetChildUserQuery,
  refetchGetUserChildrenQuery,
  useGetChildUserQuery,
  useSetChildAccountSettingsMutation,
} from '@plvs/graphql'
import {
  formErrorToString,
  getGradYearOptions,
  yupFirstNameRequired,
  yupGradYear,
  yupLastNameRequired,
} from '@plvs/utils'
import { NxSelectController } from '@plvs/respawn/features/form/NxSelectController'
import { getDate, getMonth, getYear } from 'date-fns'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { SetUserAvatar } from '@plvs/respawn/features/account/SetUserAvatar'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import { ProductType } from '@plvs/client-data/models'
import { countryNames, stateAbbrs, stateNames } from '@plvs/const'
import { BirthdaySelectController } from '../../form/BirthdaySelectController'

const useStyles = makeStyles((theme) => ({
  inputTitles: {
    marginBottom: theme.spacing(3),
  },
  nameInputRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  inputRow: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  inputFieldContainer: {
    maxWidth: '466px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  select: {
    backgroundColor: theme.palette.ColorBackgroundAlt,
  },
  button: {
    marginTop: theme.spacing(2),
  },
}))

const EditChildInfoFormSchema = yup.object().shape({
  country: yup.string(),
  day: yup.string().required(),
  displayName: yup.string(),
  firstName: yupFirstNameRequired,
  gradYear: yupGradYear,
  lastName: yupLastNameRequired,
  month: yup.string().required(),
  state: yup.string(),
  year: yup.string().required(),
})

interface EditChildInfoFormInput {
  firstName: string
  lastName: string
  gradYear: number
  month: string
  day: string
  year: string
  country: string
  state: string
  displayName: string
}

export const ChildEditInfoForm: React.FC<{
  userId: string
}> = ({ userId }) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const productType = useProductTypeFn()
  const isStadium = productType === ProductType.Stadium

  const [setChildAccountSettings, setChildAccountSettingsResult] =
    useSetChildAccountSettingsMutation({
      refetchQueries: [
        refetchGetUserChildrenQuery(),
        refetchGetChildUserQuery({ id: userId }),
      ],
    })
  const { data, loading } = useGetChildUserQuery({ variables: { id: userId } })

  const dateOfBirthDefaultValues = useMemo(() => {
    const parsedDate = data?.user?.dateOfBirth
      ? new Date(data?.user?.dateOfBirth)
      : null
    return parsedDate
      ? {
          year: getYear(parsedDate),
          // Note: months from dayjs are 0 indexed, our select options are 1 indexed
          month: getMonth(parsedDate) + 1,
          day: getDate(parsedDate),
        }
      : undefined
  }, [data])

  const { control, register, getValues, handleSubmit, errors } =
    useForm<EditChildInfoFormInput>({
      resolver: yupResolver<EditChildInfoFormInput>(EditChildInfoFormSchema),
    })

  const gradYearOptions = useMemo(() => getGradYearOptions(), [])

  const formDisabled = loading || setChildAccountSettingsResult.loading

  const submitDisabled = formDisabled

  function calculateDateOfBirth({
    day,
    month,
    year,
  }: Partial<EditChildInfoFormInput>): string {
    return `${month}-${day}-${year}`
  }

  const submit: React.FormEventHandler<
    HTMLFormElement
  > = async (): Promise<void> => {
    const {
      firstName,
      lastName,
      gradYear,
      day,
      month,
      year,
      country,
      state,
      displayName,
    } = getValues()

    const dateOfBirth = calculateDateOfBirth({ day, month, year })

    const input = {
      firstName,
      lastName,
      dateOfBirth,
      gradYear: +gradYear,
      schoolId: data?.user?.school?.id,
    }

    if (isStadium) {
      Object.assign(input, {
        ...(country && { country }),
        ...(state && { state }),
        ...(displayName && { username: displayName }),
      })
    }

    try {
      await setChildAccountSettings({
        variables: {
          userId,
          input,
        },
      })
      enqueueSnackbar('Child info has been successfully updated.', {
        variant: 'success',
        autoHideDuration: 2000,
      })
    } catch (e: any) {
      enqueueSnackbar('Something went wrong please contact support.', {
        variant: 'error',
        autoHideDuration: 2000,
      })
    }
  }

  const inputStyle = {
    height: '56px',
    backgroundColor: theme.palette.ColorBackgroundAlt,
  }

  return (
    <Box>
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <form onSubmit={handleSubmit(submit)}>
          <NxTypography className={classes.inputTitles} variant="h2">
            Edit Child Information
          </NxTypography>

          <Box className={classes.inputFieldContainer}>
            <Box className={classes.inputRow}>
              <NxUserCluster
                avatarHashId={data?.user?.id ?? ''}
                avatarHeight={80}
                avatarUrl={data?.user?.avatarUrl ?? ''}
                avatarWidth={80}
              />
              <Box ml={2}>
                <SetUserAvatar userId={userId} />
              </Box>
            </Box>

            <Box className={classes.nameInputRow}>
              <NxTextInput
                ref={register}
                data-testid="first-name"
                defaultValue={data?.user?.firstName ?? undefined}
                fullWidth
                helperText={formErrorToString(errors.firstName)}
                label="First Name"
                name="firstName"
                style={inputStyle}
                variant={errors.firstName ? 'error' : 'default'}
              />
              <NxTextInput
                ref={register}
                data-testid="last-name"
                defaultValue={data?.user?.lastName ?? undefined}
                fullWidth
                helperText={formErrorToString(errors.lastName)}
                label="Last Name"
                name="lastName"
                style={inputStyle}
                variant={errors.lastName ? 'error' : 'default'}
              />
            </Box>
            {isStadium && (
              <Box className={classes.inputRow}>
                <NxTextInput
                  ref={register}
                  data-testid="display-name"
                  defaultValue={data?.user?.username ?? undefined}
                  fullWidth
                  helperText={formErrorToString(errors.displayName)}
                  label="Display Name"
                  name="displayName"
                  style={inputStyle}
                  variant={errors.displayName ? 'error' : 'default'}
                />
              </Box>
            )}
            <Box className={classes.inputRow}>
              <BirthdaySelectController
                control={control}
                defaultValues={dateOfBirthDefaultValues}
                selectClassName={classes.select}
                showBirthInLabels
              />
            </Box>
            {!isStadium && (
              <Box className={classes.inputRow} data-testid="grad-year">
                <NxSelectController
                  className={classes.select}
                  control={control}
                  defaultValue={data?.user?.gradYear?.toString()}
                  fullWidth
                  label="Graduation Year"
                  name="gradYear"
                  required
                >
                  {gradYearOptions.map((year) => (
                    <NxSelectOption key={year} value={year}>
                      {year}
                    </NxSelectOption>
                  ))}
                </NxSelectController>
              </Box>
            )}
            {isStadium && (
              <Box
                className={classes.inputRow}
                data-testid="country-and-state"
                display="flex"
                gridColumnGap={16}
              >
                <NxSelectController
                  className={classes.select}
                  control={control}
                  defaultValue={data?.user?.country ?? ''}
                  fullWidth
                  label="Country"
                  name="country"
                  required
                >
                  {Object.entries(countryNames).map((country) => (
                    <NxSelectOption key={country[0]} value={country[0]}>
                      {country[1]}
                    </NxSelectOption>
                  ))}
                </NxSelectController>
                <NxSelectController
                  className={classes.select}
                  control={control}
                  defaultValue={data?.user?.state ?? ''}
                  fullWidth
                  label="State"
                  name="state"
                  required
                >
                  {Object.entries(stateNames)
                    .filter(([abbr]) => {
                      return stateAbbrs.some((state) => state === abbr)
                    })
                    .map((state) => (
                      <NxSelectOption key={state[0]} value={state[0]}>
                        {state[1]}
                      </NxSelectOption>
                    ))}
                </NxSelectController>
              </Box>
            )}
            <NxButton
              className={classes.button}
              disabled={submitDisabled}
              fullWidth
              label="Save"
              type="submit"
              variant="primary"
            />
          </Box>
        </form>
      </WaitTillLoaded>
    </Box>
  )
}
