import { TaskState } from '@plvs/graphql/generated'
import React, { useState } from 'react'
import { Path } from '@plvs/const'
import { NxTypography, NxTaskCard } from '@playvs-inc/nexus-components'

import { Box } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { TaskCtaButton } from '../components/TaskCtaButton'

export const PlayMatchTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isMobile: boolean
  index: number
}> = ({ task, isMobile, index }) => {
  const navigate = useNavigate()

  const [completedState] = useState<boolean>(task.state === TaskState.Completed)

  const handleScrimmageRedirect = async (): Promise<void> => {
    navigate(Path.Scrimmage)
  }

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography data-cy="download-coach-handbook" variant="h4">
              Play a Match
            </NxTypography>
          </Box>
          <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
            Complete a practice, scrimmage, pre-season, or regular season match.
          </NxTypography>
        </>
      }
      taskCta={
        <TaskCtaButton
          buttonText="Scrimmage Now"
          completed={completedState}
          onClick={handleScrimmageRedirect}
          task={task}
        />
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <NxSpot
            domain="keyboard"
            height={64}
            size="small"
            variant="primary"
            width={64}
          />
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
