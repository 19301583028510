import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import {
  NxTypography,
  useGetEnrolledLeagueCardHelpers,
  NxTooltip,
  NxEsportIcon,
} from '@playvs-inc/nexus-components'

import { NxMiscAssets } from '@playvs-inc/nexus-misc-assets'
import dayjs from '@plvs/respawn/init/dayjs'
import clsx from 'clsx'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { EsportRating, MatchStatus } from '@plvs/graphql/generated'
import {
  LONG_DATE_AND_DAY_AND_TZ,
  Path,
  SHORT_MONTH_AND_DAY_AND_DOW,
} from '@plvs/const'
import { useLocation } from 'react-router-dom'
import { includes } from 'ramda'
import tz from 'dayjs/plugin/timezone'
import { LeagueMatchRow } from '@plvs/rally/features/explore/LeagueMatches/types'
import {
  getInvalidMatchResults,
  getTooltip,
  INVALID_RESULTS_MATCH_STATUSES,
} from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import { LeagueMatchTeamCluster } from '@plvs/rally/features/explore/LeagueMatches/LeagueMatchTeamCluster'
import { MatchResults } from '@plvs/rally/features/explore/LeagueMatches/MatchResults'
import { colors } from '@playvs-inc/nexus-core'
import { CreateCSSProperties } from '@material-ui/styles'

import { formatInTz } from '@plvs/utils'

import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { LiveIndicator } from '../../app/drawer/matches/LiveIndicator'

const { FirebobDisabled } = NxMiscAssets

dayjs.extend(tz)

interface StyleProps {
  tabColor: string | undefined
  isPublic: boolean
}

export const useStyles = makeStyles((theme) => ({
  root: ({ tabColor }: StyleProps): CreateCSSProperties => ({
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    padding: theme.spacing(2, 3),
    borderLeft: `8px solid ${tabColor}`,
    paddingRight: theme.spacing(1.5),
  }),
  matchDateSection: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  matchDate: {
    marginBottom: theme.spacing(0.5),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  avatarLeft: {
    marginLeft: theme.spacing(2),
  },
  avatarRight: {
    marginRight: theme.spacing(2),
  },
  leftTeam: {
    textAlign: 'right',
  },
  matchResults: {
    textAlign: 'center',
  },
  opaqueTeam: {
    opacity: '30%',
  },
  vsText: {
    margin: theme.spacing(0, 1),
    textAlign: 'center',
  },
  scoreContainer: {
    margin: 'auto',
  },
  icon: {
    height: 56,
    width: 56,
    float: 'right',
    opacity: 0.1,
  },
  teamNameMobile: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  vsAvatarText: {
    margin: theme.spacing(0, 5),
  },
  liveText: {
    marginLeft: theme.spacing(0.5),
    textTransform: 'uppercase',
  },
}))

interface Props {
  className?: string
  match: LeagueMatchRow
}

export const ProfileLeagueMatchHistoryRow: React.FC<Props> = ({
  className,
  match,
}) => {
  const esportSlug = match.esport.slug
  const tabColor2 = useGetEnrolledLeagueCardHelpers(esportSlug).borderColor
  const now = dayjs()

  const isXs = useBreakpointXs()

  const location = useLocation()
  const isPublic = !location.pathname.includes(Path.App)

  const isMatchLive = match?.status === MatchStatus.Live

  const classes = useStyles({ tabColor: tabColor2, isPublic })

  const matchDate = dayjs(match?.scheduledStartsAt).format(
    SHORT_MONTH_AND_DAY_AND_DOW
  )
  const matchTime = dayjs(match?.scheduledStartsAt).format('h:mm A')

  const matchResults = match?.matchResults ?? []

  const team1 = matchResults?.[0]?.team
  const team2 = matchResults?.[1]?.team

  const isPastMatch =
    !isMatchLive && dayjs(match?.scheduledStartsAt).isBefore(now)

  const hasValidResults =
    matchResults.length >= 2 &&
    !includes(match?.status, INVALID_RESULTS_MATCH_STATUSES)

  const team1Lost =
    hasValidResults && isPastMatch && matchResults[0]?.placing === 2
  const team2Lost =
    hasValidResults && isPastMatch && matchResults[1]?.placing === 2

  const isRestricted = match?.esport?.rating === EsportRating.Restricted

  const teamALogo = isRestricted ? undefined : team1?.school?.logoUrl
  const teamBLogo = isRestricted ? undefined : team2?.school?.logoUrl

  const resultsXs = hasValidResults ? (
    <MatchResults
      leftTeam={{
        score: matchResults[0]?.gamesWon,
        placing: matchResults[0]?.placing,
      }}
      rightTeam={{
        score: matchResults[1]?.gamesWon,
        placing: matchResults[1]?.placing,
      }}
    />
  ) : (
    <>{getInvalidMatchResults(match?.status)}</>
  )
  const pastMatchXs = isPastMatch ? (
    <Box alignItems="center" display="flex" mx={1}>
      {resultsXs}
    </Box>
  ) : (
    <NxTypography className={classes.vsAvatarText} variant="subtitle2">
      VS
    </NxTypography>
  )
  const statusXs = isMatchLive ? (
    <Box mx={2.5}>
      <LiveIndicator
        isLive={isMatchLive}
        typographyClassName={classes.liveText}
        typographyVariant="body2"
      />
    </Box>
  ) : (
    <>{pastMatchXs}</>
  )

  const resultsLg = hasValidResults ? (
    <MatchResults
      leftTeam={{
        score: matchResults[0]?.gamesWon,
        placing: matchResults[0]?.placing,
      }}
      rightTeam={{
        score: matchResults[1]?.gamesWon,
        placing: matchResults[1]?.placing,
      }}
    />
  ) : (
    <>{getInvalidMatchResults(match?.status)}</>
  )
  const pastMatchLg = isPastMatch ? (
    resultsLg
  ) : (
    <NxTypography variant="subtitle1">VS</NxTypography>
  )

  return (
    <>
      {isXs ? (
        <Box
          alignItems="center"
          className={clsx(classes.root, className)}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <NxTypography
            className={classes.matchDate}
            colorToken="ColorTextAlt2"
            data-testid="matchDate"
            variant="overline"
          >
            {formatInTz({
              at: match?.scheduledStartsAt,
              format: LONG_DATE_AND_DAY_AND_TZ,
              timezone: dayjs.tz.guess(),
            })}
          </NxTypography>
          <Box alignItems="center" display="flex" mb={1} width="100%">
            <Box className={classes.teamNameMobile} flex="1">
              <NxTypography className={classes.leftTeam} variant="body2">
                {team1?.name ?? 'N/A'}
              </NxTypography>
            </Box>
            <NxTypography className={classes.vsText} variant="body3">
              VS
            </NxTypography>
            <Box className={classes.teamNameMobile} flex="1">
              <NxTypography variant="body2">
                {team2?.name ?? 'N/A'}
              </NxTypography>
            </Box>
          </Box>
          <Box alignItems="center" display="flex" justifyContent="center">
            <NxUserAvatar
              avatarUrl={teamALogo}
              className={clsx(
                classes.avatarLeft,
                team1Lost && classes.opaqueTeam
              )}
              hashId={team1?.id ?? ''}
              size={NxAvatarSize.SM}
            />
            {statusXs}
            {hasValidResults ? (
              <NxUserAvatar
                avatarUrl={teamBLogo}
                className={clsx(
                  classes.avatarRight,
                  team2Lost && classes.opaqueTeam
                )}
                hashId={team2?.id ?? ''}
                size={NxAvatarSize.SM}
              />
            ) : (
              <NxTooltip
                arrow
                placement="right"
                title={getTooltip(match?.status)}
              >
                <div>
                  <FirebobDisabled height={40} width={40} />
                </div>
              </NxTooltip>
            )}
          </Box>
        </Box>
      ) : (
        <Grid
          alignItems="center"
          className={clsx(classes.root, className)}
          container
          direction="row"
          justifyContent="space-between"
        >
          <Grid item xs>
            <NxTypography
              className={clsx(classes.matchDate, classes.matchDateSection)}
              data-testid="matchDate"
              variant="body2"
            >
              {matchDate}
            </NxTypography>
            <NxTypography className={classes.matchDateSection} variant="body2">
              {matchTime}
            </NxTypography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            xs={10}
          >
            <Grid item xs>
              <LeagueMatchTeamCluster
                didTeamLose={team1Lost}
                isLeftSide
                isRestrictedEsport={isRestricted}
                matchStatus={match?.status}
                team={team1}
              />
            </Grid>
            <Grid className={classes.scoreContainer} item xs={2}>
              <Box
                alignItems="center"
                className={classes.matchResults}
                display="flex"
                justifyContent="center"
              >
                {pastMatchLg}
              </Box>
            </Grid>
            <Grid item xs>
              <LeagueMatchTeamCluster
                didTeamLose={team2Lost}
                isLeftSide={false}
                isRestrictedEsport={isRestricted}
                matchStatus={match?.status}
                team={team2}
              />
            </Grid>
          </Grid>
          <Grid item xs>
            <NxEsportIcon
              className={classes.icon}
              color={colors.OpBlack[14]}
              esportSlug={esportSlug}
            />
          </Grid>
          <Grid item xs={1} />
        </Grid>
      )}
    </>
  )
}
