import { MatchStatus } from '@plvs/graphql/generated'

export function generateMatchTitle(
  hasUserRole: boolean,
  teamId: string,
  team1: { id: string; name: string },
  team2: { id: string; name: string }
): string {
  if (hasUserRole) {
    const myTeamName = teamId === team1.id ? team1.name : team2.name
    const opponentTeamName = teamId === team1.id ? team2.name : team1.name
    return `(Match) ${myTeamName} vs. ${opponentTeamName}`
  }
  return `(Match) ${team1.name} vs ${team2.name}`
}

export const HOURS_FOR_MATCH_BANNER = 4

export const shouldShowMatchQueueBanner = (
  matchStatus: MatchStatus | undefined,
  hasRoleForSomeResourceIdsReturn: boolean,
  timeSinceStart: number
): boolean => {
  return (
    (matchStatus === MatchStatus.Completed ||
      matchStatus === MatchStatus.Forfeited) &&
    hasRoleForSomeResourceIdsReturn &&
    timeSinceStart < HOURS_FOR_MATCH_BANNER
  )
}
