import React from 'react'
import { SelectObject } from '@plvs/rally/components/filter/SelectObject'
import { useSchoolMetaseason } from '@plvs/rally/containers/filter/league/SchoolMetaseasons'

export const SchoolPageFilters: React.FC = () => {
  const { metaseasons, setMetaseason, metaseason } = useSchoolMetaseason()

  const selectedMetaseason = metaseason ?? { name: 'Season', id: '' }

  return (
    <SelectObject<typeof selectedMetaseason>
      data-testid="LeagueFilter__MetaseasonSelect"
      option={selectedMetaseason}
      options={metaseasons}
      renderValue={(m): string => m?.name ?? 'n/a'}
      setOption={(m): void => setMetaseason(m?.id ?? '')}
    />
  )
}
