import React, { memo } from 'react'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { Path } from '@plvs/const'

export const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  rightButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 'unset',
      marginTop: theme.spacing(1),
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))

export const JoinPlayVs: React.FC = memo(function JoinPlayVS() {
  const classes = useStyles()

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      mt="auto"
      pb={3}
      pt={[4, 6]}
      px={4.5}
    >
      <NxSpot
        domain="arena"
        height="49px"
        size="large"
        variant="primary"
        width="49px"
      />
      <NxTypography className={classes.title} variant="h4">
        Join PlayVS Today
      </NxTypography>
      <Box className={classes.buttonContainer}>
        <NxButton href={Path.Registration} label="Sign Up" variant="primary" />
        <NxButton
          className={classes.rightButton}
          href={Path.Login}
          label="Sign In"
          variant="secondary"
        />
      </Box>
    </Box>
  )
})
