import { init } from '@elastic/apm-rum'
import { getRallyEnv } from '../../env'

export function initElasticRum(): void {
  const rallyEnv = getRallyEnv()

  if (rallyEnv.APM_RUM_ENABLED !== 'true') {
    // eslint-disable-next-line no-console
    console.log(
      'Elastic RUM is not enabled, skipping Elastic RUM initialization.'
    )
    return
  }

  init({
    serviceName: 'rally',
    serverUrl: rallyEnv.APM_SERVER_URL,
    // todo: serviceVersion is required for sourcemaps, but we don't currently bake a version into the app
    // serviceVersion: '',
    environment: rallyEnv.APM_ENVIRONMENT_NAME,
    distributedTracingOrigins: [rallyEnv.API_URL],
    propagateTracestate: true,
    disableInstrumentations: ['history', 'click', 'eventtarget'],
    transactionSampleRate: rallyEnv.APM_SAMPLE_RATE,
  })
}
