import React from 'react'
import { Helmet } from 'react-helmet'

import { withTransaction } from '@elastic/apm-rum-react'
import { Page } from './Page'

export const PrivatePageInternal: React.FC<{
  children: React.ReactNode
  title: string
}> = ({ children, title }) => (
  <Page>
    <Helmet>
      <title>{title}</title>
      <meta content="noindex" name="robots" />
    </Helmet>
    {children}
  </Page>
)

export const PrivatePage = withTransaction(
  'PrivatePage',
  'component'
)(PrivatePageInternal)
