import { useTheme } from '@material-ui/core'
import { map } from 'ramda'
import React from 'react'

import { Provider } from '@plvs/graphql'
import { Colors } from '@plvs/rally/themes'
import { getAccountProviderUsername } from '@plvs/utils'
import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTypography } from '@playvs-inc/nexus-components'

import { UserCluster } from '../../cluster'
import type { Team } from './MatchLeaderboard.types'

export const MatchLeaderboardTableComponent = ({
  row,
}): React.ReactElement | null => {
  const theme = useTheme()

  // eslint-disable-next-line no-underscore-dangle
  const players = (row?._original as Team).team?.roster?.players ?? []

  const hasPlayers = players.some((player) => !!player.user.id)

  return hasPlayers ? (
    <div
      style={{
        backgroundColor: Colors.Grey6,
        padding: theme.spacing(1.5, 3, 1),
      }}
    >
      <NxTypography variant="overline">Player</NxTypography>
      {map((member): React.ReactElement => {
        const { isCaptain, user } = member
        return (
          <UserCluster
            key={user.id}
            avatarSize={NxAvatarSize.SM}
            avatarUrl={user.avatarUrl || ''}
            id={user.id}
            isCaptain={isCaptain}
            subtitle={
              getAccountProviderUsername(user, Provider.EpicGames) ?? 'N/A'
            }
            title={user.name || 'N/A'}
          />
        )
      }, players)}
    </div>
  ) : null
}
