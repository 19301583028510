import React from 'react'

import {
  GetPlayerPhaseStatsByTeamQuery,
  Phase,
  useGetPlayerPhaseStatsByTeamQuery,
} from '@plvs/graphql'
import { GeneralEsportAdapter } from '@plvs/respawn/features/esport/creator/types'
import { getTableColumns } from '@plvs/rally/containers/table'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { PhaseStats } from './PhaseStats'

type Member = NonNullable<
  NonNullable<GetPlayerPhaseStatsByTeamQuery['team']>['roster']
>['players'][0]

export interface PhaseStatRow {
  player: {
    avatarUrl: string | undefined
    name: string
    id: string
    username: string | undefined
  }
  stats: any
}

export const mapMembersToTableFormat = (
  getUsername: GeneralEsportAdapter['getUsername']
) => (member: Member): PhaseStatRow => {
  const { id, name, avatarUrl, phaseStats } = member.user
  return {
    player: {
      name: name || 'N/A',
      avatarUrl: avatarUrl || '',
      id,
      username: getUsername(member.user),
    },
    stats: phaseStats?.[0]?.stats,
  }
}

export interface PlayerPhaseStatsProps {
  teamId: string
  metaseasonId: string
  phase?: Pick<Phase, 'id' | 'endsAt'>
}

export const PlayerPhaseStats: React.FC<PlayerPhaseStatsProps> = ({
  teamId,
  metaseasonId,
  phase,
}) => {
  const { data, loading } = useGetPlayerPhaseStatsByTeamQuery({
    variables: {
      id: teamId,
      metaseasonId,
      phaseId: phase?.id ?? '',
      date: phase?.endsAt ?? '',
    },
    skip: !phase?.id || !phase?.endsAt,
  })
  const { getUsername, phaseStatColumns, rankingInfoCopy } = useEsportContext()
  const columns = getTableColumns(phaseStatColumns ?? [], rankingInfoCopy)

  return (
    <PhaseStats
      columns={columns}
      data={(data?.team?.roster?.players ?? [])
        .filter(({ user }) => user.phaseStats)
        .map(mapMembersToTableFormat(getUsername))}
      loading={loading}
    />
  )
}
