import { createTheme, Theme } from '@material-ui/core'
import {
  PlayVsDarkTheme,
  PlayVsTheme,
  MUIThemeModeEnum,
} from '@playvs-inc/nexus-theme'

import { mergeDeepLeft } from 'ramda'

import * as overrides from './overrides'
import { Colors } from './colors'

export function mergeThemes(
  themeMode: MUIThemeModeEnum = MUIThemeModeEnum.Light
): Theme {
  let mergeInTheme

  switch (themeMode) {
    case MUIThemeModeEnum.Dark: {
      mergeInTheme = PlayVsDarkTheme
      break
    }
    case MUIThemeModeEnum.Light:
    default:
      mergeInTheme = PlayVsTheme
  }

  // Merge theme with theme overrides.
  const theme = createTheme(
    mergeDeepLeft(
      {
        // TODO: Eventually all the remaining needed props should be moved into nexus so rally
        // will be 100% themeable.
        overrides: {
          MuiAppBar: {
            colorPrimary: {
              backgroundColor: Colors.White,
              color: Colors.Grey2,
            },
            root: {
              regular: {
                minHeight: 92,
              },
              '& .MuiButtonBase-root': {
                fontWeight: 700,
              },
            },
          },
          MuiAvatar: overrides.MuiAvatar,
          MuiCard: overrides.MuiCard,
          MuiExpansionPanel: overrides.MuiExpansionPanel,
          MuiExpansionPanelSummary: overrides.MuiExpansionPanelSummary,
          MuiInput: overrides.MuiInput,
          MuiLink: overrides.MuiLink,
          MuiMenuItem: {
            root: {
              color: Colors.Black,
              fontSize: '14px',
              lineHeight: '18px',
              fontWeight: 600,
            },
          },
          MuiPaper: overrides.MuiPaper,
          MuiSnackbarContent: overrides.MuiSnackbarContent,
          MuiTabs: overrides.MuiTabs,
          MuiToolbar: overrides.MuiToolbar,
        },
        // Avoid overriding colors when a theme from nexus is pulled in.
        palette: mergeInTheme.palette,
        props: {
          MuiButtonBase: {
            disableRipple: true, // No more ripple, on the whole application 💣!
          },
        },
      },
      mergeInTheme
    )
  )

  // for overrides that require the theme object...
  if (!theme.overrides) theme.overrides = {}
  theme.overrides.MuiCssBaseline = overrides.MuiCssBaseline(theme)
  theme.overrides.MuiButton = overrides.MuiButton(theme)
  theme.overrides.MuiTab = overrides.MuiTab(theme)
  theme.overrides.MuiFilledInput = overrides.MuiFilledInput(theme)
  theme.overrides.MuiFormLabel = overrides.MuiFormLabel(theme)

  return theme
}

export const theme = mergeThemes()

export const getRaisedHoverStyle = (
  isMobile?: boolean
): Record<string, any> => ({
  transition: '150ms all ease-in-out',
  '&:hover': {
    transform: isMobile ? 'none' : 'translateY(-4px)',
    borderRadius: 12,
    boxShadow:
      '0px 8px 12px rgba(0, 0, 0, 0.12), 0px 1px 4px rgba(0, 0, 0, 0.04)',
  },
})
