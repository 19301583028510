import { Star } from '@material-ui/icons'
import React from 'react'

import { Colors } from '@plvs/rally/themes'
import { useTheme } from '@material-ui/core'

type Size = 'tiny' | 'small'

const containerStyle: Record<
  Size,
  Pick<React.CSSProperties, 'bottom' | 'height' | 'left' | 'position' | 'width'>
> = {
  tiny: {
    bottom: -3,
    position: 'absolute',
    height: 18,
    left: -7,
    width: 18,
  },
  small: {
    bottom: -3,
    position: 'absolute',
    height: 24,
    left: -7,
    width: 24,
  },
}

const iconStyle: Record<Size, Pick<React.CSSProperties, 'height' | 'width'>> = {
  tiny: {
    height: 8,
    width: 8,
  },
  small: {
    height: 12,
    width: 12,
  },
}

export const TeamCaptainBadge: React.FC<{
  size?: Size
}> = ({ size = 'small' }) => {
  const theme = useTheme()

  return (
    <div style={containerStyle[size]}>
      <div
        style={{
          alignItems: 'center',
          backgroundColor: Colors.Yellow,
          border: `2px solid ${theme.palette.ColorBackgroundBase}`,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          position: 'absolute',
          height: '100%',
          width: '100%',
        }}
      >
        <Star htmlColor={Colors.White} style={iconStyle[size]} />
      </div>
    </div>
  )
}
