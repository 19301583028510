import { ChildOnboardPath, Path, SessionStorageKey } from '@plvs/const'
import { ProductType } from '@plvs/client-data/models'
import { ChildUser } from './types'

export const getPathUrl = (
  childUser: ChildUser,
  productType: ProductType
): string => {
  sessionStorage.removeItem(SessionStorageKey.OnboardingContext)

  if (productType === ProductType.Stadium) {
    if (!childUser.firstName || !childUser.lastName) {
      return `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildInfo}/${childUser.id}`
    }

    return `${Path.Child}/${childUser.id}`
  }

  if (!childUser.firstName || !childUser.lastName || !childUser.gradYear) {
    return `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildInfo}/${childUser.id}`
  }

  if (!childUser.school) {
    return `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildSchool}/${childUser.id}`
  }

  return `${Path.Child}/${childUser.id}`
}
