import {
  CompetitionGroup,
  UserRole,
  UserRoleName,
  GetMyIdentityQueryVariables,
  GetMyIdentityQuery,
  RoleStatus,
  Maybe,
  Role,
  SponsorshipCampaign,
  SchoolType,
  EsportSlug,
} from '@plvs/graphql'
import { ApolloQueryResult, makeVar, ReactiveVar } from '@apollo/client'

interface IdentityTeams {
  id: string
  esport: {
    id: string
    slug: EsportSlug
  }
}

export type UserIdentityInterface = {
  refetch?: (
    variables?: Partial<GetMyIdentityQueryVariables>
  ) => Promise<ApolloQueryResult<GetMyIdentityQuery>>
  userId: string
  avatarUrl: string
  orgId: string | undefined
  orgCompetitionGroup: CompetitionGroup | undefined | null
  orgName: string | undefined | null
  isCoachAtOrg: boolean
  isFacultyAtOrg: boolean
  isStudentAtOrg: boolean
  isPlayerAtOrg: boolean
  isCaptainAtOrg: boolean
  isUserPendingVerificationAtOrg: boolean
  isUserDeniedAtOrg: boolean
  isUnderage: boolean
  orgCountry: string | undefined | null
  orgProvince: string | undefined | null
  orgCity: string | undefined | null
  orgDeletedAt: string | undefined | null
  orgSchoolType: SchoolType | undefined | null
  userRoleNames: UserRoleName[]
  userName: string
  userRoles: Pick<
    UserRole,
    'roleName' | 'resourceType' | 'resourceId' | 'userId' | 'status'
  >[]
  userSchoolRoleStatus:
    | Maybe<
        (Pick<RoleStatus, 'status'> & {
          role: Pick<
            Role,
            'resourceType' | 'resourceId' | 'roleName' | 'userId'
          >
        })[]
      >
    | undefined
  loading: boolean
  error: Error | undefined
  hasMultiOrgAccess: boolean | undefined
  isParent: boolean | undefined
  activeCampaign:
    | Pick<SponsorshipCampaign, 'sponsorshipComponents' | 'sponsorshipImages'>
    | null
    | undefined
  isLoggedIn: boolean
  teamIds: string[]
  firstName: string
  lastName: string
  hmac: string
  promotionalEmail: string
  emails: {
    email: string
    isSchoolEmail: boolean
  }[]
  state: string
  gradYears: {
    gradYear: number
    schoolId: string
  }[]
  teams: IdentityTeams[]
  managedTeams: IdentityTeams[]
}

export const userIdentityVar: ReactiveVar<UserIdentityInterface> = makeVar<
  UserIdentityInterface
>({
  userId: '',
  orgId: undefined,
  orgCompetitionGroup: null,
  orgCountry: undefined,
  orgProvince: undefined,
  orgName: undefined,
  orgCity: undefined,
  orgDeletedAt: undefined,
  orgSchoolType: undefined,
  isCoachAtOrg: false,
  isFacultyAtOrg: false,
  isStudentAtOrg: false,
  isPlayerAtOrg: false,
  isCaptainAtOrg: false,
  isUserPendingVerificationAtOrg: false,
  isUserDeniedAtOrg: false,
  isUnderage: false,
  userRoleNames: [],
  userName: '',
  userRoles: [],
  userSchoolRoleStatus: [],
  refetch: undefined,
  loading: true,
  error: undefined,
  avatarUrl: '',
  hasMultiOrgAccess: false,
  isParent: false,
  activeCampaign: null,
  isLoggedIn: false,
  teamIds: [],
  firstName: '',
  lastName: '',
  hmac: '',
  promotionalEmail: '',
  emails: [],
  state: '',
  gradYears: [],
  teams: [],
  managedTeams: [],
})
