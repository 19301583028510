import { Box } from '@material-ui/core'
import { FullHeightBox, Hidden } from '@plvs/respawn/features/layout'
import { RallyAppDrawer } from '@plvs/rally/features/app/drawer'
import React from 'react'
import { Helmet } from 'react-helmet'

import { withTransaction } from '@elastic/apm-rum-react'
import { Page } from './Page'

export const PublicPageInternal: React.FC<{
  children: React.ReactNode
  description: string
  title: string
  hasDrawer?: boolean
}> = ({ children, description, title, hasDrawer }) => (
  <Page>
    <Helmet>
      <title>{title}</title>
      <meta content={description} name="description" />
    </Helmet>
    {hasDrawer ? (
      <Box display="flex" justifyContent="center">
        <Hidden smDown>
          <RallyAppDrawer isPublic />
        </Hidden>
        <FullHeightBox maxWidth="1042px">{children}</FullHeightBox>
      </Box>
    ) : (
      children
    )}
  </Page>
)

export const PublicPage = withTransaction(
  'PublicPage',
  'component'
)(PublicPageInternal)
