import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Dayjs } from 'dayjs'

import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { formMultiErrorResolver } from '@plvs/utils'
import { BirthdaySelectController } from '@plvs/respawn/features/form/BirthdaySelectController'
import { EligibilityCallout } from '@plvs/rally/features/login/EligibilityCallout'
import { useNavigate } from 'react-router'
import {
  BirthdayInput,
  BirthdaySchema,
  handleBirthdaySubmit,
} from '../utils/helpers'

const useStyles = makeStyles({
  errorSubtitle: {
    textAlign: 'center',
  },
})

interface AgeGateProps {
  isParentSignup: boolean
  setBirthday: React.Dispatch<React.SetStateAction<Dayjs | undefined>>
  shouldRedirectToParentSignup: boolean
}

export const AgeGate: React.FC<AgeGateProps> = ({
  isParentSignup,
  setBirthday,
  shouldRedirectToParentSignup,
}) => {
  const [hasError, setHasError] = React.useState(false)

  const { handleSubmit, control } = useForm<BirthdayInput>({
    resolver: formMultiErrorResolver<BirthdayInput>(BirthdaySchema),
  })

  const flags = useFlags()
  const navigate = useNavigate()

  const classes = useStyles()

  const submit = (data: BirthdayInput): void => {
    handleBirthdaySubmit({
      data,
      isParentSignup,
      setHasError,
      hasCoppaSupport: flags.coppaSupport,
      navigate,
      setBirthday,
      shouldRedirectToParentSignup,
    })
  }

  return (
    <Box
      alignItems="center"
      component="form"
      data-testid="birthday-form"
      display="flex"
      flexDirection="column"
      onSubmit={handleSubmit(submit)}
      padding={[
        [0, 2],
        [0, 0],
      ]}
      width="100%"
    >
      {!isParentSignup ? (
        <Box mx={[2]} px={0} py={3} textAlign="center">
          <NxTypography variant="subtitle1">
            Please tell us your birthday
          </NxTypography>
        </Box>
      ) : (
        <Box mb={3}>
          <NxTypography variant="subtitle1">
            Parent/Guardian Date of Birth
          </NxTypography>
        </Box>
      )}

      <BirthdaySelectController control={control} />
      <EligibilityCallout isParentSignup={isParentSignup} />

      <Box maxWidth="466px" mt={3} width="100%">
        <NxButton fullWidth label="Continue" type="submit" variant="primary" />

        {hasError && (
          <Box mt={1}>
            <NxTypography
              className={classes.errorSubtitle}
              colorToken="ColorTextError"
              variant="body3"
            >
              Unfortunately, we are unable to continue creating your account.
            </NxTypography>
          </Box>
        )}
      </Box>
    </Box>
  )
}
