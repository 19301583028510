import React, { useEffect, useState } from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useGetLeagueSeasonDataQuery } from '@plvs/graphql/generated'
import {
  PhaseFilterType,
  SlotFilterType,
} from '@plvs/rally/features/explore/LeagueDetails.types'
import { sortByStartsAt } from '@plvs/utils'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { last } from 'ramda'
import { LeagueMatches } from '@plvs/rally/features/explore/LeagueMatches/LeagueMatches'
import { SelectedTeam } from '@plvs/rally/components/search/TeamSearch/types'
import { EmbedFilters } from './EmbedFilters'

interface EmbedScheduleProps {
  leagueId: string
}

export const EmbedSchedule: React.FC<EmbedScheduleProps> = ({ leagueId }) => {
  const [phaseFilter, setPhaseFilter] = useState<PhaseFilterType | undefined>()
  const [slotFilter, setSlotFilter] = useState<SlotFilterType | undefined>()
  const [team, setTeam] = useState<SelectedTeam | null>(null)

  const {
    loading: schoolLeagueInfoContextLoading,
    metaseason,
  } = useSchoolLeagueInfoContext()
  const metaseasonId = metaseason?.id ?? ''

  const {
    data: leagueSeasonData,
    loading: leagueSeasonDataLoading,
  } = useGetLeagueSeasonDataQuery({
    skip: !leagueId || !metaseasonId || schoolLeagueInfoContextLoading,
    variables: { leagueId, metaseasonId },
  })

  const season = leagueSeasonData?.league?.seasonByMetaseasonId
  const seasonPhases = sortByStartsAt(season?.activePhases ?? [])

  useEffect(() => {
    if (seasonPhases) {
      const lastPhase = last(seasonPhases)
      if (lastPhase) {
        setPhaseFilter({ ...lastPhase, name: lastPhase.name ?? '' })
      }
    }
  }, [leagueSeasonDataLoading])

  return (
    <WaitTillLoaded
      loading={leagueSeasonDataLoading || schoolLeagueInfoContextLoading}
      showSpinnerWhileLoading
    >
      <Box alignItems="center" flexDirection="column" flexGrow="0">
        <Box alignItems="center" display="flex" mb={2}>
          <EmbedFilters
            phase={phaseFilter}
            phases={seasonPhases as PhaseFilterType[]}
            setPhase={setPhaseFilter}
            setSlotFilter={setSlotFilter}
            slotFilter={slotFilter}
            team={team}
          />
        </Box>
        <LeagueMatches
          leagueId={leagueId}
          metaseasonId={metaseasonId}
          phase={phaseFilter}
          seasonIds={undefined}
          setTeam={setTeam}
          slotFilter={slotFilter}
          team={team}
        />
      </Box>
    </WaitTillLoaded>
  )
}
