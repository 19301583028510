import { Box, makeStyles } from '@material-ui/core'
import {
  useGetSlotQuery,
  useGetTeamOverviewQuery,
  SlotLobbyStatus,
  ClosedSlotLobbyDetails,
  OpenSlotLobbyDetails,
  UnopenedSlotLobbyDetails,
  useSlotLobbyStatusQuery,
  SlotLobbyStatusPayload,
  PhaseType,
  useGetMatchesQuery,
  MatchStatus,
  EsportSlug,
  TeamRosterFormat,
} from '@plvs/graphql'
import { TitleHero } from '@plvs/rally/components/hero'
import { useBreakpointMd, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import React, { useEffect, useState } from 'react'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'

import dayjs from 'dayjs'

import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { findOpponentButtonClicked } from '@plvs/respawn/features/analytics'
import { head } from 'ramda'
import { MOMENT_SHORT_DATE_TWO_DIGIT_YEAR } from '@plvs/const'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { getHasAccountProvidersForPlayers } from '@plvs/utils'
import { useNavigate } from 'react-router-dom'
import {
  useQueueControllerState,
  useQueueRenderControllerContext,
} from '@plvs/respawn/renderController/queue/QueueRenderControllerProvider'
import { DesktopQueueLobbyHeader } from './components/DesktopQueueLobbyHeader'
import { MobileQueueLobbyHeader } from './components/MobileQueueLobbyHeader'
import { QueueLobbyFAQ } from './QueueLobbyFAQ'
import { esportSlugToProvider } from '../../account/connections/utils'

const useStyles = makeStyles((theme) => ({
  newPill: {
    display: 'inline-block',
    marginLeft: theme.spacing(2),
    verticalAlign: 'middle',
  },
  wrapper: {
    maxWidth: '1042px',
  },
}))

export interface QueueLobbyProps {
  slotId: string
  teamId: string
}

export enum DisabledMessage {
  HasMatch = 'Teams without a full roster or with a current open match cannot queue.',
  NoConnectedAccounts = 'Teams without connected accounts cannot queue.',
}

export function getDisabledMessage({
  hasMatches,
  hasConnectedAccounts,
  requiresConnectedAccounts,
}: {
  hasMatches: boolean
  hasConnectedAccounts: boolean
  requiresConnectedAccounts: boolean
}): string {
  if (hasMatches) {
    return DisabledMessage.HasMatch
  }

  if (requiresConnectedAccounts && !hasConnectedAccounts) {
    return DisabledMessage.NoConnectedAccounts
  }

  return ''
}

export const QueueLobby: React.FC<QueueLobbyProps> = ({ teamId, slotId }) => {
  const classes = useStyles()
  const mobile = useBreakpointMd()
  const navigate = useNavigate()

  const { userRoles, userId } = useUserIdentityFn()
  const productType = useProductTypeFn()
  const {
    data: teamOverviewData,
    error: teamOverviewError,
    loading: teamOverviewLoading,
  } = useGetTeamOverviewQuery({
    variables: { teamId },
    skip: !teamId,
  })
  const team = teamOverviewData?.team
  const teamRoster = team?.roster
  const teamRosterFormat = head(teamRoster?.formats ?? [])
  const filledStarterRosterSize = (
    teamRosterFormat?.starters?.filter((starter) => starter.player?.user.id) ??
    []
  ).length
  const teamFormat = teamRosterFormat?.teamSize ?? 0

  const isRosterFull = filledStarterRosterSize >= teamFormat
  const esportSlug = team?.esport.slug
  const requiresConnectedAccounts = esportSlug === EsportSlug.RocketLeague
  const hasConnectedAccounts = getHasAccountProvidersForPlayers(
    teamRosterFormat as TeamRosterFormat,
    esportSlugToProvider[esportSlug as EsportSlug]
  )

  const { hasSeries } = useGeneralEsportAdapter(esportSlug)

  const { data: slotData, loading: slotLoading } = useGetSlotQuery({
    variables: { id: slotId },
    skip: !slotId,
  })
  const slot = slotData?.slot

  const { data: slotStatusData, refetch } = useSlotLobbyStatusQuery({
    variables: { slotId },
  })

  const isPreseason = slotData?.slot?.phase?.type === PhaseType.Preseason

  const { data: matchesData } = useGetMatchesQuery({
    variables: {
      isFortnite: false,
      limit: 1,
      filters: {
        teamIds: [teamId],
        status: [MatchStatus.Live, MatchStatus.Open],
      },
    },
    skip: !teamId || !isPreseason,
  })

  const details = slotStatusData?.slotLobbyStatus?.details

  const [countdown, setCountdown] = useState(dayjs())
  const [statusTitle, setStatusTitle] = useState('')
  const [statusSubtitle, setStatusSubtitle] = useState('')
  const [slotLobbyQueueDate, setSlotLobbyQueueDate] = useState('')
  const [status, setStatus] = useState(SlotLobbyStatus.Closed)

  const { queue } = useQueueRenderControllerContext()
  const {
    setRenderControllerStateFn,
    getRenderControllerState,
  } = useQueueControllerState()
  const queueComponents = queue.getQueueComponentsToRender({
    userRoles,
    team: { id: teamId, schoolId: team?.school?.id ?? null },
    productType,
  })
  const { canEnqueue, showFAQs } = queueComponents

  useEffect(
    function updateRenderControllerState() {
      const currentState = getRenderControllerState()
      setRenderControllerStateFn({
        queue: {
          ...currentState,
          ...queueComponents,
        },
      })
    },
    [...Object.values(queueComponents)]
  )

  const hasPermissionToJoinQueue = canEnqueue
  const doesNotHaveRequiredConnections =
    requiresConnectedAccounts && !hasConnectedAccounts
  const isDisabled =
    !hasPermissionToJoinQueue || !isRosterFull || doesNotHaveRequiredConnections
  const disabledMessage = getDisabledMessage({
    hasMatches: Boolean(matchesData?.getMatches.totalCount),
    requiresConnectedAccounts,
    hasConnectedAccounts,
  })

  const calculateCountdown = (slotStatus: SlotLobbyStatusPayload): void => {
    const now = dayjs()
    const newStatus = slotStatus?.status

    let timeToCountdownTo = now
    let newStatusTitle = ''
    let newStatusSubtitle = ''
    let newSlotLobbyQueueDate = ''
    let moreThan24Hours = false

    if (isPreseason) {
      newStatusTitle = 'Recommended Queue Time'
      newStatusSubtitle =
        'Once your team has a full roster & is ready to play, click "Join Queue" to get your Match for this week. For preseason you can join multiple times!'
    } else if (slotStatusData && details) {
      switch (status) {
        case SlotLobbyStatus.Closed:
          timeToCountdownTo = dayjs(
            (details as ClosedSlotLobbyDetails).lobbyClosedAt
          )
          newStatusTitle = 'Queue Closed'
          newStatusSubtitle = 'The queue period has ended.'
          break
        case SlotLobbyStatus.Open:
          timeToCountdownTo = dayjs(
            (details as OpenSlotLobbyDetails).lobbyClosesAt
          )
          newStatusTitle = 'Queue closes in...'
          newStatusSubtitle =
            'Once your team is ready to play, click "Join Queue" to get your Match. ' +
            "If you are not joined by the time the queue closes, you won't be able to get a match."
          break
        case SlotLobbyStatus.Unopened:
          if (!isPreseason) {
            timeToCountdownTo = dayjs(
              (details as UnopenedSlotLobbyDetails).lobbyOpensAt
            )
            moreThan24Hours =
              dayjs((details as UnopenedSlotLobbyDetails).lobbyOpensAt).diff(
                now,
                'hours'
              ) > 24

            newStatusTitle = moreThan24Hours
              ? 'Queue opens on...'
              : 'Queue opens in...'
            newStatusSubtitle =
              "When the queue is open, the team's coach will be able to find an opponent to play against. A full roster is required to join the queue."
            newSlotLobbyQueueDate = dayjs(
              (details as UnopenedSlotLobbyDetails).lobbyOpensAt
            ).format(MOMENT_SHORT_DATE_TWO_DIGIT_YEAR)
          }
          break
        default:
      }
    }

    setCountdown(timeToCountdownTo)
    setStatusTitle(newStatusTitle)
    setStatusSubtitle(newStatusSubtitle)
    setStatus(newStatus ?? SlotLobbyStatus.Closed)
    setSlotLobbyQueueDate(newSlotLobbyQueueDate)
  }

  useEffect(() => {
    if (slotStatusData?.slotLobbyStatus) {
      calculateCountdown(slotStatusData?.slotLobbyStatus)
    }
  }, [slotStatusData, isPreseason])

  const onCountdownComplete = async (): Promise<void> => {
    const { data } = await refetch({ slotId })
    calculateCountdown(data.slotLobbyStatus)
  }

  const onAccept = async (): Promise<void> => {
    findOpponentButtonClicked({
      timeStamp: new Date().toISOString(),
      userId,
      teamId,
      slotId,
    })
    if (isPreseason) {
      navigate(`/preseason-queue/${slotId}/${teamId}/${esportSlug}`)
    } else {
      navigate(`/slot-queue/${slotId}/${teamId}/${esportSlug}`)
    }
  }

  const formattedTime = isPreseason
    ? new Intl.DateTimeFormat('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
      }).format(Date.parse(slotData?.slot?.startsAt ?? ''))
    : ''

  const header = mobile ? (
    <MobileQueueLobbyHeader
      countdown={countdown}
      disableButton={isDisabled}
      disableMessage={disabledMessage}
      esportSlug={esportSlug}
      hasSeries={hasSeries}
      onAccept={onAccept}
      onCountdownComplete={onCountdownComplete}
      showButton={isPreseason || status === SlotLobbyStatus.Open}
      showCountdown={!isPreseason && status !== SlotLobbyStatus.Closed}
      showStatusInfo={Boolean(slotStatusData)}
      slot={slot}
      slotLobbyQueueDate={slotLobbyQueueDate}
      statusSubtitle={statusSubtitle}
      statusTitle={statusTitle}
      time={formattedTime}
    />
  ) : (
    <DesktopQueueLobbyHeader
      countdown={countdown}
      disableButton={isDisabled}
      disableMessage={disabledMessage}
      esportSlug={esportSlug}
      hasSeries={hasSeries}
      onAccept={onAccept}
      onCountdownComplete={onCountdownComplete}
      showButton={isPreseason || status === SlotLobbyStatus.Open}
      showCountdown={!isPreseason && status !== SlotLobbyStatus.Closed}
      showStatusInfo={Boolean(slotStatusData)}
      slot={slot}
      slotLobbyQueueDate={slotLobbyQueueDate}
      statusSubtitle={statusSubtitle}
      statusTitle={statusTitle}
      team={team}
      time={formattedTime}
    />
  )

  return slot ? (
    <WaitTillLoaded
      error={teamOverviewError}
      loading={teamOverviewLoading || slotLoading}
      LoadingComponent={(): React.ReactElement => {
        return (
          <Box className={classes.wrapper} mx={2} pb={4}>
            <MatchImageCard esportSlug={esportSlug}>
              <Box flexGrow={1} padding="0 32px">
                <TitleHero subtitle="Loading..." title="Match Lobby" />
              </Box>
            </MatchImageCard>
          </Box>
        )
      }}
      renderErrorState={({ error: lobbyError }): React.ReactElement => {
        return (
          <Box className={classes.wrapper} mx={2} pb={4}>
            <MatchImageCard esportSlug={esportSlug}>
              <Box flexGrow={1} padding="0 32px">
                <TitleHero
                  subtitle={lobbyError ? lobbyError.message : 'Unknown Error'}
                  title="Match Lobby"
                />
              </Box>
            </MatchImageCard>
          </Box>
        )
      }}
      showSpinnerWhileLoading
    >
      <Box className={classes.wrapper} pb={4}>
        {header}
        {showFAQs && (
          <Box mr={2} mt={3}>
            <MatchCard>
              <Box pb={1} pt={2}>
                <NxTypography variant="h3">
                  FAQs
                  <Pill
                    className={classes.newPill}
                    label="New"
                    size="small"
                    variant="info"
                  />
                </NxTypography>
                <NxTypography colorToken="ColorTextAlt2" variant="body1">
                  Where&apos;s your opponent? Read some of the common questions
                  we think you may have.
                </NxTypography>
              </Box>
              <Box>
                <QueueLobbyFAQ isPreseason={isPreseason} />
              </Box>
            </MatchCard>
          </Box>
        )}
      </Box>
    </WaitTillLoaded>
  ) : (
    <></>
  )
}
