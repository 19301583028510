/* eslint-disable @typescript-eslint/no-duplicate-enum-values */

import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { isNumber } from 'ramda-adjunct'
import { EsportSlug } from '@plvs/graphql'

export { alpha, darken } from '@material-ui/core/styles'

// Colors taken from brand guide lines
// ------
// https://www.figma.com/file/qyQiDWaw4haY03LI2lZKS8/Fall-Product-Branding-(SHARED)?node-id=135%3A213

// Color Tool
// ------
// https://material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=ec4f0c&secondary.color=0085d7
// https://codepen.io/jcblw/pen/qBWrOJv?&editable=true

/**
 * @deprecated - Use theme.palette.(themeToken) or getColorValue(colorToken) instead.
 */
export enum Colors {
  Orange = '#FF530A',
  LightOrange = '#FFF2E8', // scrimmage status
  NexusLightThemeText = '#B83A00',
  DisableOrange = '#FFA47D',
  Blue = '#0F72CC',
  Blue2 = '#1068C6',
  Blue3 = '#D9EAFC',
  Blue4 = '#0E5CAF',
  LightBlue = '#E7F1FA',

  Green = '#00AA6F', // success
  Green2 = '#B3FFB3',
  Green3 = '#016A01',
  LightGreen = '#E6F7f1', // scrimmage status
  LightGreen2 = '#F0FFF0',
  CheckboxGreen = '#C3E5C0',
  CheckmarkGreen = '#2AAA2B',
  Yellow = '#EB9F1D', // warning
  Red = '#DB383A', // failure
  LightRed = '#F2DADC',
  Beige = '#F6E6D8', // Fortnite information
  Pink = '#F2DADC',

  // black and white
  NexusBlack = 'rgba(0, 0, 0, 0.85)',
  NexusBlack1 = '#181A1B',
  NexusBlack2 = 'rgba(0, 0, 0, 0.5)',
  NexusBlack3 = 'rgba(0, 0, 0, 0.9)',
  NexusBlack4 = '#000000',
  NexusWhite = 'rgba(255, 255, 255, 0.9)',
  NexusWhite1 = 'rgba(255, 255, 255, 0.95)',
  NexusWhite2 = 'rgba(255, 255, 255, 0.15)',
  NexusWhite3 = 'rgba(255, 255, 255, 0.5)',
  Black = '#161C22',
  White = '#FFFFFF',

  // greys
  Grey1 = '#2F3B43',
  Grey2 = '#535D66',
  Grey3 = '#8A939C',
  Grey4 = '#D9DCDF',
  Grey5 = '#EAEDEF',
  Grey6 = '#F6F7F8',
  Grey7 = '#5C6366',
  Grey8 = '#EAEEEF',
  Grey9 = '#D7DFE0',
  Grey10 = '#2E3133',
  Grey11 = '#161718',
  Grey12 = '#8A9599',
  Grey600 = '#2E3133',
  NexusGrey = '#EFF0F0',
  NexusGrey1 = '#555B5E',
  NexusGrey2 = '#3D4143',
  NexusGrey3 = 'rgba(0, 0, 0, 0.1)',
  NexusGrey4 = '#C4C4C4',
  NexusGrey5 = '#F7F8F8',
  NexusGrey8 = '#878F92',

  // team colors
  TeamBlue = '#23CCF7',
  TeamOrange = '#F78823',
  TeamRed = '#DB383A',
  TeamPurple = '#B153FA', // NOTE: may be temporary
  TeamNoColor = '#8A939D', // same as Grey3... almost

  // extended colors
  Honey1 = '#FFFDD8',
  Honey3 = '#FFB938',
  Violet1 = '#F3E4FF',
  Violet3 = '#AC3EFF',
  Lava = '#FF7E70',
  Lava1 = '#FFECEA',
  Lava2 = '#CC1400',
  Lava5 = '#A31000',
  Sapphire = '#109EF0',
  Fern = '#005A00',
  Fern1 = '#E7F2C4',
  Fern4 = '#0B8A15',
  Brand = '#FF5509',

  // common css
  Inherit = 'inherit',

  // Non standard
  TEMPNewOrange = '#FF8100',

  // Used By Callout
  SkyBlue94 = 'rgba(223, 242, 254, 1)',
  Blue75 = 'rgba(110, 195, 250, 1)',
}

export enum StatusColors {
  Success = Colors.Green,
  Warning = Colors.Yellow,
  Failure = Colors.Red,
}

export const namesOfColors: Record<Colors, string> = {
  [Colors.Orange]: 'Orange',
  [Colors.LightOrange]: 'Light Orange',
  [Colors.NexusLightThemeText]: 'Light Theme Text Link Orange',
  [Colors.DisableOrange]: 'Disable Orange',
  [Colors.Blue]: 'Blue',
  [Colors.Blue2]: 'Blue2',
  [Colors.Blue3]: 'Blue3',
  [Colors.Blue4]: 'Blue4',
  [Colors.LightBlue]: 'Light Blue',
  [Colors.Green]: 'Green',
  [Colors.Green2]: 'Green Success',
  [Colors.Green3]: 'Dark Green Success',
  [Colors.LightGreen]: 'Light Green',
  [Colors.LightGreen2]: 'Light Green 2',
  [Colors.CheckboxGreen]: 'Checkbox Green',
  [Colors.CheckmarkGreen]: 'Checkmark Green',
  [Colors.Yellow]: 'Yellow',
  [Colors.Red]: 'Red',
  [Colors.LightRed]: 'Light Red',
  [Colors.NexusBlack]: 'rgba(0, 0, 0, 0.85)',
  [Colors.NexusBlack1]: '#181A1B',
  [Colors.NexusBlack2]: 'rgba(0, 0, 0, 0.5)',
  [Colors.NexusBlack3]: 'rgba(0, 0, 0, 0.9)',
  [Colors.NexusWhite]: 'rgba(255, 255, 255, 0.9)',
  [Colors.NexusWhite1]: 'rgba(255, 255, 255, 0.95)',
  [Colors.NexusWhite2]: 'rgba(255, 255, 255, 0.15)',
  [Colors.NexusWhite3]: 'rgba(255, 255, 255, 0.5)',
  [Colors.NexusBlack4]: '#000000',
  [Colors.Black]: 'Black',
  [Colors.White]: 'White',
  [Colors.TeamOrange]: 'Orange',
  [Colors.TeamBlue]: 'Blue',
  [Colors.TeamPurple]: 'Purple',
  [Colors.TeamNoColor]: 'N/A',
  [Colors.Grey1]: 'Grey',
  [Colors.Grey2]: 'Grey',
  [Colors.Grey3]: 'Grey',
  [Colors.Grey4]: 'Grey',
  [Colors.Grey5]: 'Grey',
  [Colors.Grey6]: 'Grey',
  [Colors.Grey7]: 'Grey',
  [Colors.Grey8]: 'Grey',
  [Colors.Grey9]: 'Grey',
  [Colors.Grey10]: 'Grey',
  [Colors.Grey11]: 'Grey',
  [Colors.Grey12]: 'Grey',
  [Colors.NexusGrey]: 'Grey',
  [Colors.NexusGrey1]: 'Grey',
  [Colors.NexusGrey2]: 'Grey',
  [Colors.NexusGrey3]: 'Grey',
  [Colors.NexusGrey4]: 'Grey',
  [Colors.NexusGrey5]: 'Grey',
  [Colors.NexusGrey8]: 'Grey',
  [Colors.Inherit]: 'N/A',
  [Colors.Beige]: 'Beige',
  [Colors.TEMPNewOrange]: 'Logo Orange',
  [Colors.Honey1]: 'Honey',
  [Colors.Honey3]: 'Honey',
  [Colors.Violet1]: 'Violet',
  [Colors.Violet3]: 'Violet',
  [Colors.Lava]: 'Lava',
  [Colors.Lava1]: 'Lava',
  [Colors.Lava2]: 'Lava',
  [Colors.Lava5]: 'Lava',
  [Colors.Sapphire]: 'Sapphire',
  [Colors.Fern]: 'Fern',
  [Colors.Fern1]: 'Fern',
  [Colors.Fern4]: 'Fern',
  [Colors.Brand]: 'Brand',
  [Colors.SkyBlue94]: 'Sky Blue',
  [Colors.Blue75]: 'Blue',
}

const primary = {
  main: Colors.Orange,
}

const secondary = {
  main: Colors.Blue,
}

const error = {
  main: Colors.Red,
}

export const esportTeamColors: Record<EsportSlug | string, Colors[]> = {
  [EsportSlug.LeagueOfLegends]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.RocketLeague]: [Colors.TeamBlue, Colors.TeamOrange],
  [EsportSlug.Smite]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Fortnite]: [Colors.Blue, Colors.TeamPurple],
  [EsportSlug.FortniteSolos]: [Colors.Blue, Colors.TeamPurple],
  [EsportSlug.SmashBrosUltimate]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Splatoon]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Overwatch]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Valorant]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Hearthstone]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Fifa21PS4]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Fifa21SolosPS4]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Fifa21XboxOne]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Fifa21SolosXboxOne]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Madden21PS4]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Madden]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Madden21XboxOne]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.Madden21SolosXboxOne]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.BlackOpsColdWar]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.MarioKart]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.NBA2K]: [Colors.TeamBlue, Colors.TeamRed],
  [EsportSlug.FC]: [Colors.TeamBlue, Colors.TeamRed],
}

export type MatchTeamSide = 1 | 2 | undefined
export type GetMatchTeamSideColor = (
  side: MatchTeamSide,
  esportSlug?: EsportSlug
) => Colors

export const defaultTeamColors = [Colors.Blue, Colors.TeamPurple]

export const getMatchTeamSideColor: GetMatchTeamSideColor = (
  side,
  esportSlug
) => {
  if (esportSlug && side) {
    const colors = esportTeamColors[esportSlug]
    if (colors) {
      return colors[side - 1]
    }
  }
  return (isNumber(side) && defaultTeamColors[side - 1]) || Colors.TeamNoColor
}

export const palette: PaletteOptions = {
  primary,
  secondary,
  error,
  common: { black: Colors.Black, white: Colors.White },
}

const stringToIndex = (id: string): number => {
  let index = 0
  if (id.length === 0) return index
  for (let i = 0; i < id.length; i += 1) {
    index += id.charCodeAt(i)
  }
  return parseInt(index.toString().charAt(0), 10)
}

export const getRandomColor = ({
  colors = [
    Colors.Honey3,
    Colors.Violet3,
    Colors.Lava,
    Colors.Sapphire,
    Colors.Fern,
    Colors.Brand,
  ],
  id,
}: {
  colors?: Colors[]
  id: string | null
}): Colors => {
  if (id) {
    const integer = stringToIndex(id)
    const index = integer >= colors.length ? colors.length - 1 : integer
    return colors[index]
  }
  return colors[Math.floor(Math.random() * colors.length)]
}
