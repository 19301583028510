import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { NxEmptyState, NxButton } from '@playvs-inc/nexus-components'
import { useScheduleControllerState } from '@plvs/respawn/renderController/schedule/ScheduleRenderControllerProvider'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'

export const EmptySchedulePage: React.FC<{
  isEnrolledInCurrentSeason: boolean
}> = ({ isEnrolledInCurrentSeason }) => {
  const { getRenderControllerState } = useScheduleControllerState()
  const {
    emptyMatchesButtonPath,
    emptyMatchesButtonTitle,
    emptyMatchesSubtitleCopyPartial,
  } = getRenderControllerState().schedule

  const { metaseason } = useSchoolLeagueInfoContext()

  const navigate = useNavigate()

  // This may need to be updated with the render controller as well.
  const nonEnrolledSubtitle = `${emptyMatchesSubtitleCopyPartial} for the ${
    metaseason?.name ?? 'current'
  } season.`

  return (
    <NxEmptyState
      cta={
        !isEnrolledInCurrentSeason && (
          <NxButton
            disabled={!emptyMatchesButtonPath}
            label={emptyMatchesButtonTitle}
            onClick={(): void => navigate(emptyMatchesButtonPath)}
            variant="secondary"
          />
        )
      }
      spot={
        <NxSpot
          domain="schedule"
          height={100}
          size="large"
          variant="secondary"
          width={100}
        />
      }
      subtitle={
        isEnrolledInCurrentSeason
          ? 'The schedule will be available before the preseason. We will notify you when the schedule comes out via email.'
          : nonEnrolledSubtitle
      }
      title={
        isEnrolledInCurrentSeason
          ? 'Schedule not available yet'
          : 'No Schedule available'
      }
    />
  )
}
