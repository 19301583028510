import React from 'react'
import { ResourceType, TaskState } from '@plvs/graphql/generated'
import { Box } from '@material-ui/core'
import { Path } from '@plvs/const'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { NxTypography, NxTaskCard } from '@playvs-inc/nexus-components'

import { useNavigate } from 'react-router-dom'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { TaskCtaButton } from '../components/TaskCtaButton'

export const SchoolTeamCreationTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isMobile: boolean
  schoolId?: string
  index: number
}> = ({ task, isMobile, schoolId, index }) => {
  const { setEntity, selectedEntityId } = useProfileContext()
  const navigate = useNavigate()

  const onNavigateToCreateTeam = (): void => {
    if (selectedEntityId !== schoolId) {
      setEntity(ResourceType.Organization, schoolId)
    }
    navigate(Path.CreateTeam)
  }

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography data-cy="download-coach-handbook" variant="h4">
              Create a Team
            </NxTypography>
          </Box>
          <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
            Start building your schools teams for students to join
          </NxTypography>
        </>
      }
      taskCta={
        <TaskCtaButton
          buttonText="Create A Team"
          completed={task.state === TaskState.Completed}
          disabled={task.state === TaskState.Disabled}
          onClick={onNavigateToCreateTeam}
          task={task}
        />
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <NxSpot
            domain="createTeam"
            height={60}
            size="small"
            variant="primary"
            width={60}
          />
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
