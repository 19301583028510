import { ProductType } from '@plvs/client-data/models/ProductType'
import { CompetitionGroup } from '@plvs/graphql/generated'
import { ManageMatchDropdownMenuItems } from '../../matchLobby/lobby/types/lobbyRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../matchLobby/MatchLobbyRenderController.types'
import { MatchTeamForRenderController } from '../../matchLobby/match/types/matchRenderController.types'

export const coachScheduleMenuItemIds = [
  ManageMatchDropdownMenuItems.Reschedule,
  ManageMatchDropdownMenuItems.ViewReschedule,
  ManageMatchDropdownMenuItems.Forfeit,
  ManageMatchDropdownMenuItems.Dispute,
  ManageMatchDropdownMenuItems.Rulebook,
  ManageMatchDropdownMenuItems.LeagueRules,
  ManageMatchDropdownMenuItems.MatchInstructions,
  ManageMatchDropdownMenuItems.Scout,
  ManageMatchDropdownMenuItems.Chat,
  ManageMatchDropdownMenuItems.JoinQueue,
]

// All owners in stadium are also captains of the team.
export const ownerScheduleMenuItemIds = [
  ManageMatchDropdownMenuItems.Forfeit,
  ManageMatchDropdownMenuItems.Dispute,
  ManageMatchDropdownMenuItems.MatchInstructions,
  ManageMatchDropdownMenuItems.Chat,
  ManageMatchDropdownMenuItems.JoinQueue,
]

export const captainScheduleMenuItemIdsScholastic = [
  ManageMatchDropdownMenuItems.Rulebook,
  ManageMatchDropdownMenuItems.LeagueRules,
  ManageMatchDropdownMenuItems.MatchInstructions,
  ManageMatchDropdownMenuItems.Scout,
  ManageMatchDropdownMenuItems.Chat,
  ManageMatchDropdownMenuItems.JoinQueue,
]

export const playerScheduleMenuItemIdsNonScholastic = [
  ManageMatchDropdownMenuItems.MatchInstructions,
  ManageMatchDropdownMenuItems.Chat,
]

export const playerScheduleMenuItemIdsScholastic = [
  ManageMatchDropdownMenuItems.Rulebook,
  ManageMatchDropdownMenuItems.LeagueRules,
  ManageMatchDropdownMenuItems.MatchInstructions,
  ManageMatchDropdownMenuItems.Scout,
  ManageMatchDropdownMenuItems.Chat,
]

export type SchedulePageDropdownMenuItemsReturn = {
  items: ManageMatchDropdownMenuItems[]
}

export type ScheduleComponentDetailsProps = {
  productType: ProductType
}
export type MenuItemIdsForSchedulePageManageMatchDropdownProps = {
  competitionGroup: CompetitionGroup | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController | null | undefined
  team2: MatchTeamForRenderController | null | undefined
}

export type ScheduleComponentDetailsReturn = {
  emptyMatchesButtonTitle: string
  emptyMatchesButtonPath: string
  emptyMatchesSubtitleCopyPartial: string
}

export type ScheduleRenderController = {
  getScheduleComponentDetails: ({
    productType,
  }: ScheduleComponentDetailsProps) => ScheduleComponentDetailsReturn
  getMenuItemIdsForSchedulePageDropdown: ({
    competitionGroup,
    userRoles,
    team1,
    team2,
  }: MenuItemIdsForSchedulePageManageMatchDropdownProps) => SchedulePageDropdownMenuItemsReturn
}

export type ScheduleRenderControllerState = {
  schedule: ScheduleComponentDetailsReturn & SchedulePageDropdownMenuItemsReturn
}

export type UseScheduleRenderControllerContextReturn = {
  schedule: ScheduleRenderController
}
