import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

import { EsportSlug } from '@plvs/graphql'

export const useStyles = makeStyles((theme) => {
  return {
    root: ({
      isMatchLive,
      showEsportAccent,
      esportSlug,
      esportPalettes,
    }: {
      isMatchLive: boolean
      showEsportAccent: boolean
      esportSlug: EsportSlug | null | undefined
      esportPalettes: { [key: string]: { palette: { baseColor: string } } }
    }): CreateCSSProperties => {
      let borderColor

      if (showEsportAccent && esportSlug) {
        const esportPalette = esportPalettes[esportSlug]
        borderColor = esportPalette?.palette?.baseColor
      }

      if (isMatchLive) {
        borderColor = theme.palette.OverlayColorBackgroundError
      }

      return {
        backgroundColor: theme.palette.ColorBackgroundBase,
        borderRadius: theme.shape.borderRadius * 2.5,
        boxShadow: theme.mixins.boxShadow.elevation1,
        borderLeft: borderColor ? `8px solid ${borderColor}` : 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
          borderRadius: theme.shape.borderRadius,
        },

        '&:hover': {
          zIndex: 100,
        },
      }
    },
    liveText: {
      marginLeft: theme.spacing(0.5),
      textTransform: 'uppercase',
    },
    matchDateSection: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    matchDate: {
      marginBottom: theme.spacing(0.5),
      [theme.breakpoints.down('xs')]: {
        marginBottom: theme.spacing(0),
      },
    },
    avatarLeft: {
      marginLeft: theme.spacing(2),
    },
    avatarRight: {
      marginRight: theme.spacing(2),
    },
    leftTeam: {
      textAlign: 'right',
    },
    matchResults: {
      textAlign: 'center',
    },
    opaqueTeam: {
      opacity: '30%',
    },
    vsText: {
      margin: theme.spacing(0, 1),
      textAlign: 'center',
    },
    pill: {
      margin: theme.spacing(0, 1),
    },
    vsAvatarText: {
      margin: theme.spacing(0, 5),
    },
  }
})
