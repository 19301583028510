import React from 'react'
import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import {
  Guide,
  List,
  Logout,
  Money,
  QuestionOutlined,
  Sendfeedback,
  Settings,
  Substitute,
  Theme,
  Theme1,
  UserOutlined,
} from '@playvs-inc/nexus-icons'
import { IntercomArticleMappings, Path } from '@plvs/const'
import { productTourClicked } from '@plvs/respawn/features/analytics'
import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { Tour, useSetTourViewedMutation } from '@plvs/graphql/generated'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import dayjs from '@plvs/respawn/init/dayjs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { rallyEnv } from '@plvs/rally/env'
import { ProductType } from '@plvs/client-data/models'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { useThemeProviderContext } from '../../theme'

export type MenuItemConfig = {
  dataCy?: string
  handleOnClick: () => void
  icon: JSX.Element
  key: string
  label: string
  className?: string
  isExternal?: boolean
}

const useStyles = makeStyles((theme) => ({
  menuItemIcon: {
    marginRight: theme.spacing(1),
  },
}))

interface Props {
  handleAnalytics(menuItem: string): void
  handleMenuItemClick(path: Path | string): void
  handleClose(): void
  openSwitchSchoolModal(): void
}

export const useMenuItems = ({
  handleAnalytics,
  handleMenuItemClick,
  handleClose,
  openSwitchSchoolModal,
}: Props): { menuItems: MenuItemConfig[] } => {
  const classes = useStyles()
  const theme = useTheme()

  const {
    userId,
    isCoachAtOrg,
    hasMultiOrgAccess,
    isParent,
  } = useUserIdentityFn()

  const { changeTheme, themeMode } = useThemeProviderContext()
  const productType = useProductTypeFn()

  const {
    onboardingTour,
    initiatedTimestamp,
    setInitiatedTimestamp,
  } = useProductTours()

  const flags = useFlags()
  const isProductTourEnabled = flags.productTour
  const isMultipleCoachesSchoolSwitcher = flags.multipleCoachesSchoolSwitcher

  const isDesktop = useMediaQuery(useTheme().breakpoints.up('md'))

  const [setTourViewed] = useSetTourViewedMutation()

  const switchSchool = {
    key: 'switch-school',
    label: 'Switch School',
    icon: (
      <Substitute
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    handleOnClick: (): void => {
      openSwitchSchoolModal()
      handleClose()
    },
  }

  const userProfile = {
    key: 'user-profile',
    label: 'User Profile',
    icon: (
      <UserOutlined
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    handleOnClick: (): void => {
      handleMenuItemClick(`${Path.App}${Path.Profile}/${userId}`)
    },
  }

  const accountSettings = {
    key: 'menu-item-account-settings',
    label: 'Account Settings',
    icon: (
      <Settings
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    handleOnClick: (): void => {
      handleAnalytics('Account Settings')
      handleMenuItemClick(Path.Settings)
    },
    dataCy: 'settings-link',
    className: 'shepherd-account-settings',
  }

  const refer = {
    key: 'menu-item-refer',
    label: 'Refer to PlayVS',
    icon: (
      <Money
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    handleOnClick: (): void => {
      handleAnalytics('Refer to PlayVS')
      handleMenuItemClick(Path.GlobalRefer)
    },
  }

  const getHelp = {
    key: 'menu-item-get-help',
    label: 'Get Help',
    icon: (
      <QuestionOutlined
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    handleOnClick: (): void => {
      handleAnalytics('Get Help')
      showIntercomArticle(IntercomArticleMappings.allArticles)
    },
  }

  const giveFeedback = {
    key: 'menu-item-give-feedback',
    label: 'Give Feedback',
    icon: (
      <Sendfeedback
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    isExternal: true,
    handleOnClick: (): void => {
      handleAnalytics('Give Feedback')
      window.open('https://playvs.hellonext.co/', '_blank', 'noopener')
    },
  }

  const productTour = {
    key: 'menu-item-product-tour',
    label: 'Product Tour',
    icon: (
      <Guide
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    handleOnClick: async (): Promise<void> => {
      if (!onboardingTour?.isActive()) {
        productTourClicked({
          userId,
          initiatedTimestamp: initiatedTimestamp ?? dayjs().toISOString(),
          timeStamp: dayjs().toISOString(),
          stepNumber: 1,
          clickTarget: 'Maybe Later Button',
        })
        await setTourViewed({ variables: { tour: Tour.Product } })
        setInitiatedTimestamp(dayjs().toISOString())
        onboardingTour?.start()
        handleClose()
      }
    },
  }

  const changeLog = {
    key: 'menu-item-change-log',
    label: 'Change Log',
    icon: (
      <List
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    isExternal: true,
    handleOnClick: (): void => {
      window.open('https://playvs.hellonext.co/changelog', '_blank', 'noopener')
    },
  }

  const darkMode = {
    key: 'menu-item-change-theme',
    label: themeMode === MUIThemeModeEnum.Dark ? 'Light Theme' : 'Dark Theme',
    icon:
      themeMode === MUIThemeModeEnum.Dark ? (
        <Theme
          className={classes.menuItemIcon}
          color={theme.palette.ColorTextBase}
        />
      ) : (
        <Theme1
          className={classes.menuItemIcon}
          color={theme.palette.ColorTextBase}
        />
      ),
    handleOnClick: (): void => {
      handleAnalytics('Change Theme')
      changeTheme()
    },
  }

  const logout = {
    key: 'menu-item-logout',
    label: 'Logout',
    icon: (
      <Logout
        className={classes.menuItemIcon}
        color={theme.palette.ColorTextBase}
      />
    ),
    handleOnClick: (): void => {
      handleAnalytics('Logout')
      handleMenuItemClick(Path.Logout)
    },
  }

  let menuItems = isCoachAtOrg
    ? [
        ...(isMultipleCoachesSchoolSwitcher && hasMultiOrgAccess
          ? [switchSchool]
          : []),
        accountSettings,
        refer,
        getHelp,
        giveFeedback,
        ...(isProductTourEnabled && isDesktop ? [productTour] : []),
        changeLog,
        darkMode,
        logout,
      ]
    : [
        userProfile,
        accountSettings,
        refer,
        getHelp,
        giveFeedback,
        changeLog,
        darkMode,
        logout,
      ]

  if (isParent && flags?.coppaSupport) {
    menuItems = [
      accountSettings,
      getHelp,
      giveFeedback,
      changeLog,
      darkMode,
      logout,
    ]
  }

  if (rallyEnv.ENABLE_DARK_MODE === false) {
    menuItems = menuItems.filter(({ key }) => key !== 'menu-item-change-theme')
  }

  // Normally this should be driven by a renderController (e.g. `shouldRenderAccountSettings`)
  // but to avoid refactoring the bulk of this function, we're taking the easy way.
  if (productType === ProductType.Stadium) {
    menuItems = [accountSettings, logout]
  }

  return { menuItems }
}
