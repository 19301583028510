import { Race } from '@plvs/graphql/generated'

export const ETHNICITY_OPTIONS = [
  {
    title: 'American Indian or Alaska Native',
    category: 'Select all that apply',
    value: Race.AmericanIndianOrAlaskaNative,
  },
  { title: 'Asian', category: 'Select all that apply', value: Race.Asian },
  {
    title: 'Black or African American',
    category: 'Select all that apply',
    value: Race.BlackOrAfricanAmerican,
  },
  {
    title: 'Hispanic or Latino',
    category: 'Select all that apply',
    value: Race.HispanicOrLatino,
  },
  {
    title: 'Native Hawaiian or Other Pacific Islander',
    category: 'Select all that apply',
    value: Race.NativeHawaiianOrOtherPacificIslander,
  },
  { title: 'White', category: 'Select all that apply', value: Race.White },
  {
    title: 'Not Listed',
    category: 'Select all that apply',
    value: Race.NotListed,
  },
  {
    title: 'Prefer not to answer',
    category: '-OR-',
    value: Race.PreferNotToAnswer,
  },
]

export const formatEthnicity = (
  race: Race
): { title: string; category: string; value: Race } => {
  const option = ETHNICITY_OPTIONS.find((o) => o.value === race)

  return {
    title: option?.title ?? '',
    category: option?.category ?? '',
    value: race,
  }
}
