import React, { useEffect, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import * as yup from 'yup'

import { Box } from '@plvs/respawn/features/layout'
import { CookieProps, formErrorToString } from '@plvs/utils'
import {
  useUnlinkUserProviderAccountMutation,
  refetchGetUserProviderAccountsFromIdQuery,
  Provider,
  useLinkAccountMutation,
} from '@plvs/graphql'
import { yupResolver } from '@hookform/resolvers'

import { RouteComponentProps } from '@plvs/respawn/features/route/WithRouter'
import { AccountProviderComponentProps } from '@plvs/rally/features/account/connections/utils'
import ConnectionCard from '@plvs/rally/features/account/connections/ConnectionCard'
import { useSnackbar } from 'notistack'
import { providerSubtitleMap } from '@plvs/rally/features/account/connections/ProviderConnection'
import { NxTextInput } from '@playvs-inc/nexus-components'
import { providerTitleMap } from '@plvs/respawn/features/account/connections/ProviderConnection/utils'

interface ProviderConnectionProps
  extends AccountProviderComponentProps,
    RouteComponentProps {}

type Schema = {
  displayName: string
}

export const GenericConnection: React.FC<
  ProviderConnectionProps & CookieProps
> = (props) => {
  const { name, providerDetails, checkpoint, userId, isParent } = props

  const providerName = name ? providerTitleMap[name] : ''

  const { enqueueSnackbar } = useSnackbar()
  const [providerId, setProviderId] = useState<string>()

  const lastUpdated =
    providerDetails?.updatedAt ?? providerDetails?.createdAt ?? ''
  const subtitle = name && providerSubtitleMap[name]
  // Cheap way of getting a provider-specific label without creating another map.
  const inputLabel =
    (subtitle as string)?.replace('Add your ', '') ?? 'Username'

  const { errors, handleSubmit, register, watch } = useForm<Schema>({
    defaultValues: {
      displayName: providerDetails?.providerDisplayName ?? '',
    },
    resolver: yupResolver<Schema>(
      yup.object().shape({
        displayName: yup
          .string()
          .required(`Enter a ${inputLabel ?? 'username'}`),
      })
    ),
  })

  const [
    remove,
    { loading: disconnectMutationLoading },
  ] = useUnlinkUserProviderAccountMutation()

  const [
    connectAccount,
    { loading: connectMutationLoading },
  ] = useLinkAccountMutation()

  const onConnect = handleSubmit(
    async (input: Schema): Promise<void> => {
      if (connectMutationLoading) {
        return
      }

      try {
        const result = await connectAccount({
          awaitRefetchQueries: true,
          refetchQueries: [
            refetchGetUserProviderAccountsFromIdQuery({ userId: userId ?? '' }),
          ],
          variables: {
            userId: userId ?? '',
            attributes: {
              provider: name as Provider,
              displayName: input.displayName,
            },
          },
        })

        setProviderId(result.data?.linkManualAccount.id ?? '')
        enqueueSnackbar('Account connected.')
      } catch (err) {
        enqueueSnackbar('Account failed to connect.', { variant: 'error' })
      }
    }
  )

  const disconnectAccount = async (): Promise<void> => {
    if (!providerId) {
      return
    }

    if (!disconnectMutationLoading) {
      await remove({
        awaitRefetchQueries: true,
        refetchQueries: [
          refetchGetUserProviderAccountsFromIdQuery({ userId: userId ?? '' }),
        ],
        variables: {
          providerId,
        },
      })

      setProviderId('')
    }
  }

  const formValues = watch()
  const displayName = formValues?.displayName
  const error = (errors as FieldErrors)?.displayName
  const isConnected = Boolean(providerDetails?.providerDisplayName)

  const EditComponent = !isConnected ? (
    <form key={name} onSubmit={onConnect}>
      <Box maxWidth={400}>
        <NxTextInput
          ref={register}
          data-testid="edit"
          fullWidth
          helperText={formErrorToString(error)}
          label={inputLabel}
          name="displayName"
          value={displayName}
          variant={error ? 'error' : 'default'}
        />
      </Box>
    </form>
  ) : undefined

  useEffect(() => {
    setProviderId(providerDetails?.id)
  }, [providerDetails?.id])

  return (
    <ConnectionCard
      checkpoint={checkpoint}
      connectedText={
        isConnected
          ? `${providerDetails?.providerDisplayName} connected to ${providerName}`
          : ''
      }
      disabled={connectMutationLoading || disconnectMutationLoading}
      EditComponent={!isConnected ? EditComponent : undefined}
      isConnected={isConnected}
      isParent={isParent}
      lastUpdated={lastUpdated}
      onConnectClick={onConnect}
      onUpdateClick={disconnectAccount}
      providerName={name}
      subtitle={subtitle}
      title={providerName}
      userId={userId}
    />
  )
}
