import {
  CompetitionGroup,
  EsportRating,
  MatchStatus,
} from '@plvs/graphql/generated'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'
import { MatchTeamForRenderController } from '../../match/types/matchRenderController.types'

export const genericQueueBannerActionsCopy =
  'After finishing your match or if your opponent forfeits, you can play against other teams without worrying about your standings. Have fun and take advantage of the extra practice!'

export enum ManageMatchDropdownMenuItems {
  Reschedule = 'manage-match-reschedule',
  ViewReschedule = 'manage-match-view-reschedule',
  Forfeit = 'manage-match-forfeit',
  Dispute = 'manage-match-dispute',
  Rulebook = 'manage-match-rulebook',
  LeagueRules = 'manage-match-league-rules',
  Scout = 'manage-match-scout',
  MatchInstructions = 'manage-match-match-instructions',
  LobbyTour = 'manage-match-lobby-tour',
  CopyGameCode = 'manage-match-copy-game-code',
  Open = 'manage-match-open-match',
  Chat = 'manage-match-open-chat',
  JoinQueue = 'manage-match-join-queue',
  CodeOfConduct = 'manage-match-code-of-conduct',
}

export const coachMenuItemIds = [
  ManageMatchDropdownMenuItems.Reschedule,
  ManageMatchDropdownMenuItems.ViewReschedule,
  ManageMatchDropdownMenuItems.Forfeit,
  ManageMatchDropdownMenuItems.Dispute,
]

export const ownerMenuItemIds = [
  ManageMatchDropdownMenuItems.Forfeit,
  ManageMatchDropdownMenuItems.Dispute,
]
export const scholasticMenuItemIdsForAllRoles = [
  ManageMatchDropdownMenuItems.Rulebook,
  ManageMatchDropdownMenuItems.LeagueRules,
  ManageMatchDropdownMenuItems.Scout,
  ManageMatchDropdownMenuItems.LobbyTour,
  ManageMatchDropdownMenuItems.CopyGameCode,
  ManageMatchDropdownMenuItems.MatchInstructions,

  ManageMatchDropdownMenuItems.CodeOfConduct,
]

export const nonScholasticMenuItemIdsForAllRoles = [
  ManageMatchDropdownMenuItems.LobbyTour,
  ManageMatchDropdownMenuItems.CopyGameCode,
  ManageMatchDropdownMenuItems.MatchInstructions,

  ManageMatchDropdownMenuItems.CodeOfConduct,
]

export type LobbyTeamForRenderController = {
  name?: string | null
  schoolName?: string | null
  avatarUrl: string | null
  schoolSlug?: string | null
  id: string
}
export type LobbyComponentProps = {
  status: MatchStatus | undefined
  isMatchAssistantEnabled: boolean
  team1: MatchTeamForRenderController | null | undefined
  team2: MatchTeamForRenderController | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  // Note, if we ever had a match or an event with two teams from different competition groups, this would need to be updated.
  competitionGroup: CompetitionGroup | null | undefined
}

export type LobbyComponentReturn = {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  myTeamIds: string[]
  opposingTeamId: string
  canManageQueueBannerActions: boolean
  canManageQueueBannerActionsCopy: string
  isMemberOfBothTeams: boolean
  showActionAndInfoSection: boolean
  showRescheduleMatchAlert: boolean
  enableScoutingToTeamsPage: boolean
}

export type MenuItemIdsForManageMatchDropdownProps = {
  competitionGroup: CompetitionGroup | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController | null | undefined
  team2: MatchTeamForRenderController | null | undefined
}

export type HomeAndAwayTeamDisplayDetailsProps = {
  competitionGroup: CompetitionGroup | null | undefined
  team1: LobbyTeamForRenderController | null | undefined
  team2: LobbyTeamForRenderController | null | undefined
  esportRating: EsportRating | null | undefined
}

export type HomeAndAwayTeamDisplayDetailsReturn = {
  homeTeam: {
    id: string
    teamName: string
    avatarUrl: string
    schoolSlug?: string
    schoolName?: string
  }
  awayTeam: {
    id: string
    teamName: string
    avatarUrl: string
    schoolSlug?: string
    schoolName?: string
  }
}

export type ManageMatchDropdownMenuItemsReturn = {
  items: ManageMatchDropdownMenuItems[]
}

export type LobbyRenderController = {
  getLobbyComponentsToRender: ({
    status,
    userRoles,
    competitionGroup,
    team1,
    team2,
    isMatchAssistantEnabled,
  }: LobbyComponentProps) => LobbyComponentReturn
  getHomeAndAwayTeamDisplayDetails: ({
    competitionGroup,
    team1,
    team2,
    esportRating,
  }: HomeAndAwayTeamDisplayDetailsProps) => HomeAndAwayTeamDisplayDetailsReturn
  getMenuItemIdsForManageMatchDropdown: ({
    competitionGroup,
    userRoles,
    team1,
    team2,
  }: MenuItemIdsForManageMatchDropdownProps) => ManageMatchDropdownMenuItemsReturn
}
