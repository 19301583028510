import React, { useCallback, useContext } from 'react'
import { noop } from 'ramda-adjunct'

import { determineQueueRender } from './utils/queueRenderController.helpers'
import {
  UseQueueRenderControllerContextReturn,
  QueueRenderControllerState,
} from './types/queueController.types'

type QueueRenderControllerContext = UseQueueRenderControllerContextReturn & {
  getRenderControllerState: () => QueueRenderControllerState
  setRenderControllerStateFn: (
    updatedStateValues: Partial<QueueRenderControllerState>
  ) => void
}

export const queueRenderControllerContext =
  React.createContext<QueueRenderControllerContext>({
    queue: {
      getQueueComponentsToRender: determineQueueRender,
    },
    getRenderControllerState: () => ({
      queue: {
        canEnqueue: false,
        showFAQs: false,
      },
    }),
    setRenderControllerStateFn: noop,
  })

export const useQueueRenderControllerContext =
  (): QueueRenderControllerContext => useContext(queueRenderControllerContext)

export const QueueRenderControllerProvider: React.FC = ({ children }) => {
  const [renderControllerState, setRenderControllerState] =
    React.useState<QueueRenderControllerState>({
      queue: {
        canEnqueue: false,
        showFAQs: false,
      },
    })

  const getRenderControllerState =
    useCallback((): QueueRenderControllerState => {
      return renderControllerState
    }, [renderControllerState])

  const setRenderControllerStateFn = useCallback(
    (newState: Partial<QueueRenderControllerState>): void => {
      setRenderControllerState((prevState) => ({
        ...prevState,
        ...newState,
      }))
    },
    []
  )

  const values = useQueueRenderControllerContext()
  return (
    <queueRenderControllerContext.Provider
      value={{
        ...values,
        getRenderControllerState,
        setRenderControllerStateFn,
      }}
    >
      {children}
    </queueRenderControllerContext.Provider>
  )
}

export const useQueueControllerState = (): {
  getRenderControllerState: () => QueueRenderControllerState
  setRenderControllerStateFn: (
    updatedStateValues: Partial<QueueRenderControllerState>
  ) => void
} => {
  const { getRenderControllerState, setRenderControllerStateFn } =
    React.useContext(queueRenderControllerContext)

  return { getRenderControllerState, setRenderControllerStateFn }
}
