import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core'
import React from 'react'

import { NxTypography } from '@playvs-inc/nexus-components'

import { EmptyPage } from '@plvs/rally/components/empty'
import { Icons } from '@plvs/rally/components/icon'
import { EsportSlug, MatchStatus } from '@plvs/graphql'
import { HeroMatch } from '@plvs/respawn/features/match-lobby/types/Lobby.types'
import { VsMatchFaceoffContainer } from '../VsMatchFaceoffContainer'

const useStyles = makeStyles((theme) => ({
  copy: {
    color: theme.palette.common.white,
  },
}))

export const MatchFaceoffContainer: React.FC<{
  countdown?: string
  teamAId?: string
  size: 'small' | 'medium' | 'large'
  match: HeroMatch
  esportSlug: EsportSlug | null | undefined
  breadcrumbHref?: string
}> = ({ countdown, esportSlug, size, match, breadcrumbHref }) => {
  const theme = useTheme()
  const classes = useStyles()
  const status = match?.status
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('sm'))

  if (status === MatchStatus.Bye)
    return (
      <EmptyPage
        icon={Icons.calendarX}
        mb={6}
        mt={6}
        showIcon
        subtitle={
          <NxTypography className={classes.copy} variant="body1">
            Your team has been assigned a bye-week. There will be no opponent
            scheduled for this match.
          </NxTypography>
        }
        title={
          <NxTypography
            className={classes.copy}
            data-testid="byeWeekTitle"
            variant="h4"
          >
            Bye-Week
          </NxTypography>
        }
      />
    )

  return (
    <VsMatchFaceoffContainer
      breadcrumbHref={breadcrumbHref}
      countdown={countdown}
      esportSlug={esportSlug}
      isSmallBreakpoint={isSmallBreakpoint}
      match={match}
      mobile={isMobile}
      size={size}
    />
  )
}
