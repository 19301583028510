/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-this-in-sfc */
import React, { useContext, useState } from 'react'
import Shepherd from 'shepherd.js'
import { useNavigate } from 'react-router-dom'
import Tour from 'shepherd.js/src/types/tour'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { createOnboardTourSteps } from '../onboardingTourSteps'
import { createFindMatchTourSteps } from '../findMatchTourSteps'
import { createMatchLobbyTourSteps } from '../matchLobbyTourSteps'
import dayjs from '../../../init/dayjs'

export interface UseProductTours {
  onboardingTour?: Tour
  findMatchTour?: Tour
  matchLobbyTour?: Tour
  initiatedTimestamp?: string
  setInitiatedTimestamp: (time: string) => void
}

export const context = React.createContext<UseProductTours>({
  onboardingTour: undefined,
  findMatchTour: undefined,
  matchLobbyTour: undefined,
  initiatedTimestamp: '',
  setInitiatedTimestamp: () => {},
})

const { Provider } = context

export const useProductTours = (): UseProductTours => useContext(context)

export const ProductToursProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const navigate = useNavigate()
  const { userId } = useUserIdentityFn()

  const [initiatedTimestamp, setInitiatedTimestamp] = useState(
    dayjs().toISOString()
  )

  const [findMatchTour] = useState(
    new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shepherd-theme-custom',
      },
      steps: createFindMatchTourSteps({ userId }),
    })
  )

  const [onboardingTour] = useState(
    new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shepherd-theme-custom',
      },
      steps: createOnboardTourSteps({ userId, navigate }),
    })
  )

  const [matchLobbyTour] = useState(
    new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'shepherd-theme-custom',
      },
      steps: createMatchLobbyTourSteps({ userId }),
    })
  )

  return (
    <Provider
      value={{
        onboardingTour,
        findMatchTour,
        matchLobbyTour,
        initiatedTimestamp,
        setInitiatedTimestamp,
      }}
    >
      {children}
    </Provider>
  )
}
