import React, { Dispatch, SetStateAction } from 'react'
import {
  Table,
  TableColumn,
  TablePagination,
} from '@plvs/rally/components/table'

import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { MatchLeaderboardTableComponent } from './MatchLeaderboardTableComponent'
import type { Team } from './MatchLeaderboard.types'

// NOTE: There is still not type safety with this component when it comes to
//  what columns are being pumped in. Those columns are typed as
//  TableColumn<any>[].
export const MatchLeaderboard: React.FC<{
  buttons: React.ReactNode
  columns: TableColumn<Team>[]
  data: Team[]
  numPages: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  title: string
  titleDetail: string
}> = ({
  buttons,
  columns,
  data,
  numPages,
  page,
  setPage,
  title,
  titleDetail,
}) => {
  return (
    <MatchCard
      footer={
        <TablePagination numPages={numPages} page={page} setPage={setPage} />
      }
      px={0}
      py={0}
      sideContent={buttons}
      title={title}
      titleDetail={titleDetail}
    >
      <Table
        columns={columns}
        data={data}
        getTrProps={(
          state: any,
          rowInfo: any,
          instance: any
        ): { className: string; onClick(): void } => {
          const { expanded } = state
          const path = rowInfo?.nestingPath[0]
          const isRowExpanded = expanded[path]

          return {
            className: isRowExpanded ? 'rt-expanded' : '',
            onClick: (): void =>
              instance.setState({
                expanded: {
                  [path]: !isRowExpanded,
                },
              }),
          }
        }}
        sortable={false}
        SubComponent={MatchLeaderboardTableComponent}
      />
    </MatchCard>
  )
}
