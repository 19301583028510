import {
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  hasRoleForResource,
  isAdminForSystem,
  isCaptainForResource,
  isCoachAtOrganization,
  isCoachForScholasticRelatedTeam,
  isMemberOfOrganization,
  isPlayerForResource,
  isTeamOwnerForResource,
} from '@plvs/utils'
import { MatchStatus } from '@plvs/graphql/generated'
import {
  MatchActionProps,
  MatchActionReturn,
  MatchLobbyComponentProps,
  MatchLobbyComponentReturn,
  MatchTeamForRenderController,
} from '../types/matchRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'

export const determineMatchLobbyComponentsRenderForOrgBasedMatch = ({
  status,
  userRoles,
  team1,
  team2,
  shouldRenderMatchAssistant,
  isOnMissionControl,
}: {
  status: MatchStatus | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  shouldRenderMatchAssistant: boolean
  isOnMissionControl: boolean
}): {
  shouldRenderMatchReporting: boolean
  shouldRenderMatchAssistantForBothTeams: boolean
  shouldShowMatchLobbyTour: boolean
  isScrimmageQueueEnabled: boolean
} => {
  const isMemberOfEitherSchoolInMatch = isMemberOfOrganization(userRoles, [
    team1?.schoolId ?? '',
    team2?.schoolId ?? '',
  ])

  const userIsCoachForHomeTeam = isCoachForScholasticRelatedTeam(
    userRoles,
    team1
  )
  const userIsCoachForAwayTeam = isCoachForScholasticRelatedTeam(
    userRoles,
    team2
  )
  const isCoachForEitherSchoolOrTeamInMatch =
    userIsCoachForHomeTeam || userIsCoachForAwayTeam

  const userIsCoachOfBothTeams =
    userIsCoachForHomeTeam && userIsCoachForAwayTeam

  const shouldRenderMatchAssistantForBothTeams =
    shouldRenderMatchAssistant &&
    userIsCoachOfBothTeams &&
    status !== MatchStatus.Quarantined
  return {
    shouldRenderMatchReporting:
      !shouldRenderMatchAssistant && isCoachForEitherSchoolOrTeamInMatch,
    shouldRenderMatchAssistantForBothTeams,
    shouldShowMatchLobbyTour:
      shouldRenderMatchAssistant &&
      isMemberOfEitherSchoolInMatch &&
      isOnMissionControl,
    isScrimmageQueueEnabled: true,
  }
}

export const determineMatchLobbyComponentsRenderForNonOrgBasedMatch = ({
  status,
  userRoles,
  team1,
  team2,
  shouldRenderMatchAssistant,
  isOnMissionControl,
}: {
  status: MatchStatus | null | undefined
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  shouldRenderMatchAssistant: boolean
  isOnMissionControl: boolean
}): {
  shouldRenderMatchAssistantForBothTeams: boolean
  shouldRenderMatchReporting: boolean
  shouldShowMatchLobbyTour: boolean
  isScrimmageQueueEnabled: boolean
} => {
  const isOwnerOfEitherTeam = isTeamOwnerForResource(userRoles, [
    team1.id,
    team2.id,
  ])

  const isParticipantInMatch =
    hasRoleForResource(userRoles, [team1.id, team1.schoolId]) ||
    hasRoleForResource(userRoles, [team2.id, team2.schoolId])

  const isAdmin = isAdminForSystem(userRoles ?? [])
  const userIsCoachForHomeTeam = isCoachForScholasticRelatedTeam(
    userRoles,
    team1
  )
  const userIsCoachForAwayTeam = isCoachForScholasticRelatedTeam(
    userRoles,
    team2
  )
  const userIsCoachOfBothTeams =
    userIsCoachForHomeTeam && userIsCoachForAwayTeam
  const shouldRenderMatchAssistantForBothTeams =
    shouldRenderMatchAssistant &&
    (userIsCoachOfBothTeams || isAdmin) &&
    status !== MatchStatus.Quarantined

  return {
    shouldRenderMatchReporting:
      !shouldRenderMatchAssistant && isOwnerOfEitherTeam,

    shouldRenderMatchAssistantForBothTeams,
    shouldShowMatchLobbyTour:
      shouldRenderMatchAssistant && isParticipantInMatch && isOnMissionControl,
    isScrimmageQueueEnabled: false,
  }
}

export const determineMatchLobbyComponentsRender = ({
  status,
  userRoles,
  team1,
  team2,
  isMatchAssistantEnabled,
  competitionGroup,
  isOnMissionControl,
}: MatchLobbyComponentProps): MatchLobbyComponentReturn => {
  const matchIsNotQuarantined = status !== MatchStatus.Quarantined
  const hasBothTeams = Boolean(team1?.id && team2?.id)
  const shouldRenderMA =
    hasBothTeams && isMatchAssistantEnabled && matchIsNotQuarantined

  if (!competitionGroup) {
    return {
      shouldRenderMatchReporting: false,
      shouldRenderMatchAssistant: shouldRenderMA,
      shouldRenderMatchAssistantForBothTeams: false,
      shouldShowMatchLobbyTour: false,
      isScrimmageQueueEnabled: false,
    }
  }

  if (isAdminForSystem(userRoles ?? [])) {
    return {
      shouldRenderMatchReporting: false,
      shouldRenderMatchAssistant: shouldRenderMA,
      shouldRenderMatchAssistantForBothTeams: shouldRenderMA,
      shouldShowMatchLobbyTour: false,
      isScrimmageQueueEnabled: false,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const {
      shouldRenderMatchAssistantForBothTeams,
      shouldRenderMatchReporting,
      shouldShowMatchLobbyTour,
      isScrimmageQueueEnabled,
    } = determineMatchLobbyComponentsRenderForOrgBasedMatch({
      status,
      userRoles,
      team1,
      team2,
      shouldRenderMatchAssistant: shouldRenderMA,
      isOnMissionControl,
    })
    return {
      shouldRenderMatchReporting,
      shouldRenderMatchAssistant: shouldRenderMA,
      shouldRenderMatchAssistantForBothTeams,
      shouldShowMatchLobbyTour,
      isScrimmageQueueEnabled,
    }
  }

  const {
    shouldRenderMatchReporting,
    shouldShowMatchLobbyTour,
    isScrimmageQueueEnabled,
  } = determineMatchLobbyComponentsRenderForNonOrgBasedMatch({
    status,
    userRoles,
    team1,
    team2,
    shouldRenderMatchAssistant: shouldRenderMA,
    isOnMissionControl,
  })
  return {
    shouldRenderMatchReporting,
    shouldRenderMatchAssistant: shouldRenderMA,
    shouldRenderMatchAssistantForBothTeams: false,
    shouldShowMatchLobbyTour,
    isScrimmageQueueEnabled,
  }
}

export const determineMatchAssistantActionsForOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
}): MatchActionReturn => {
  const isCoachOfEitherTeamsSchool = isCoachAtOrganization(userRoles, [
    team1?.schoolId ?? '',
    team2?.schoolId ?? '',
  ])

  const isCaptainOfEitherTeam = isCaptainForResource(userRoles, [
    team1?.id ?? '',
    team2?.id ?? '',
  ])

  const isPlayerOfEitherTeam = isPlayerForResource(userRoles, [
    team1?.id ?? '',
    team2?.id ?? '',
  ])

  const canParticipate = isCoachOfEitherTeamsSchool || isPlayerOfEitherTeam

  return {
    canManageMatch: isCoachOfEitherTeamsSchool,
    canReport: isCoachOfEitherTeamsSchool || isCaptainOfEitherTeam,
    canParticipate,
    canSpectate: !canParticipate,
    canDisputeMatch: isCoachOfEitherTeamsSchool,
    canManageRoster: isCaptainOfEitherTeam,
    canQueue: canParticipate,
    rolesThatCanManageRosterCopy: ['coaches', 'captains'],
    showRulebookButton: true,
  }
}

export const determineMatchAssistantActionsForNonOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
}): MatchActionReturn => {
  const isOwnerOfEitherTeam = isTeamOwnerForResource(userRoles, [
    team1?.id ?? '',
    team2?.id ?? '',
  ])

  const canParticipate =
    hasRoleForResource(userRoles, [team1.id, team1.schoolId]) ||
    hasRoleForResource(userRoles, [team2.id, team2.schoolId])

  return {
    canReport: isOwnerOfEitherTeam,
    canManageMatch: isOwnerOfEitherTeam,
    canParticipate,
    canSpectate: !canParticipate,
    canDisputeMatch: isOwnerOfEitherTeam,
    canManageRoster: isOwnerOfEitherTeam,
    canQueue: false,
    rolesThatCanManageRosterCopy: ['owners'],
    showRulebookButton: false,
  }
}

export const determineMatchAssistantActions = ({
  userRoles,
  team1,
  team2,
  competitionGroup,
}: MatchActionProps): MatchActionReturn => {
  const isAdmin = isAdminForSystem(userRoles)

  if (isAdmin) {
    return {
      canReport: true,
      canManageMatch: true,
      canParticipate: true,
      canSpectate: false,
      canDisputeMatch: true,
      canManageRoster: false,
      canQueue: false,
      rolesThatCanManageRosterCopy: ['coaches'],
      showRulebookButton: true,
    }
  }

  if (!competitionGroup) {
    return {
      canReport: false,
      canManageMatch: false,
      canParticipate: false,
      canSpectate: false,
      canDisputeMatch: false,
      canManageRoster: false,
      canQueue: false,
      rolesThatCanManageRosterCopy: ['coaches'],
      showRulebookButton: false,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    return determineMatchAssistantActionsForOrgBasedMatch({
      userRoles,
      team1,
      team2,
    })
  }

  return determineMatchAssistantActionsForNonOrgBasedMatch({
    userRoles,
    team1,
    team2,
  })
}
