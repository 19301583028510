import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core'
import {
  EsportSlug,
  Phase,
  useGetPhaseResultsLazyQuery,
  useRollupAllPhaseResultsMutation,
} from '@plvs/graphql'
import { CSVLink } from 'react-csv'
import { MOMENT_DASHED_DATE_AND_TIME } from '@plvs/const'
import { MoreMenu } from '@plvs/respawn/features/button/MoreMenu'
import { useSnackbar } from 'notistack'
import { useAppLocationFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import dayjs from '@plvs/respawn/init/dayjs'
import { isAdminForSystem } from '@plvs/utils'
import {
  convertStandingsDataForCsv,
  standingsCsvHeaders,
  standingsSeriesCsvHeaders,
  teamStandingsCsvHeader,
} from './standingsCsvHelper'
import { addPositionAndDateToPhaseResults } from './VsRegularStandings'

export const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.common.white,
  },
}))

export interface StandingsMenu {
  phase?: Pick<Phase, 'id' | 'name' | 'format'>
  leagueName?: string | null
}

export const StandingsActions: React.FC<StandingsMenu> = ({
  phase,
  leagueName,
}) => {
  const appLocation = useAppLocationFn()
  const phaseId = phase?.id
  const styles = useStyles()
  const adapter = useEsportContext()
  const slug = adapter?.slug ?? EsportSlug.LeagueOfLegends
  const csvFileName = `${leagueName || 'Standings Export'} - ${dayjs().format(
    MOMENT_DASHED_DATE_AND_TIME
  )}.csv`
  const [getPhaseResults, { data }] = useGetPhaseResultsLazyQuery()
  const { userRoles } = useUserIdentityFn()
  const isAdmin = isAdminForSystem(userRoles ?? [])

  const phaseResults = addPositionAndDateToPhaseResults(
    slug,
    data?.phase?.phaseResults ?? [],
    data?.phase?.startsAt || ''
  )
  const csvData = convertStandingsDataForCsv(phaseResults, isAdmin)

  const cvsHeaders =
    csvData.length && csvData[0].seriesRecord
      ? standingsSeriesCsvHeaders
      : standingsCsvHeaders

  const updatedHeaders = isAdmin
    ? [...cvsHeaders, teamStandingsCsvHeader]
    : cvsHeaders

  const csvRef = useRef() as any
  useEffect(() => {
    if (csvData.length > 0 && csvRef.current) {
      setTimeout(() => {
        csvRef.current.link.click()
      })
    }
  }, [csvData])

  const { enqueueSnackbar } = useSnackbar()
  const [rollupPhaseResults] = useRollupAllPhaseResultsMutation()

  const onUpdate = async (): Promise<void> => {
    try {
      await rollupPhaseResults({
        variables: {
          input: {
            phaseId: phase?.id ?? '',
          },
        },
      })

      enqueueSnackbar(`Queued rolling up phase results`, {
        variant: 'success',
      })
    } catch (err) {
      enqueueSnackbar(`Failed to rollup phase results.`, {
        variant: 'error',
      })
    }
  }
  const menuItemProps = [
    {
      label: 'Export as CSV',
      onClick: (): void => {
        if (phaseId) {
          getPhaseResults({ variables: { phaseId } })
        }
      },
    },
  ]
  const updateStandingsProp = {
    label: 'Update Standings',
    onClick: onUpdate,
  }
  useEffect(() => {
    if (appLocation === Location.Checkpoint) {
      menuItemProps.push(updateStandingsProp)
    }
  }, [appLocation, phaseResults])

  return (
    <>
      <MoreMenu iconClassName={styles.icon} menuItemProps={menuItemProps} />
      <CSVLink
        ref={csvRef}
        data={csvData}
        filename={csvFileName}
        headers={updatedHeaders}
        target="_blank"
      />
    </>
  )
}
