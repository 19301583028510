import React, { useEffect } from 'react'
import { Box, Hidden, makeStyles } from '@material-ui/core'
import {
  NxTypography,
  NxEmptyState,
  NxButton,
  NxAlert,
  NxTooltip,
} from '@playvs-inc/nexus-components'

import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { ChildOnboardPath, Param, Path } from '@plvs/const'
import {
  UserRoleName,
  UserRoleStatusName,
  refetchGetMyIdentityQuery,
  useGetParentEmailQuery,
  useGetUserChildrenQuery,
  useSendParentVerificationEmailMutation,
} from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useLocation, useNavigate } from 'react-router-dom'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { MembersCard } from './components/MembersCard'
import { ParentBanner } from './components/ParentBanner'

export const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.spacing(3),
  },
  emptyStateSubtitle: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    marginBottom: theme.spacing(3),
    maxWidth: 569,
    [theme.breakpoints.down('md')]: {
      maxWidth: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'unset',
    },
  },
  membersRoot: {
    boxShadow: theme.mixins.boxShadow.elevation1,
  },
  membersTitle: {
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderTopRightRadius: theme.shape.borderRadius * 2.5,
    borderTopLeftRadius: theme.shape.borderRadius * 2.5,
  },
}))

const CHILD_CREATE_ACCOUNT_CONTENT =
  'There are no child accounts in your family group at this time. In order for your child to participate, you will need to create their account and share their login information with them.'

export const ParentHome: React.FC = () => {
  const location = useLocation()
  const { userRoles } = useUserIdentityFn()
  const { data, loading } = useGetParentEmailQuery()
  const [sendParentVerificationEmail] = useSendParentVerificationEmailMutation()

  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const navigate = useNavigate()
  const [hasResentEmail, setHasResentEmail] = React.useState(false)

  const { data: childrenData, loading: childrenLoading } =
    useGetUserChildrenQuery()

  const isVerified =
    userRoles?.find((role) => role.roleName === UserRoleName.Parent)?.status ===
    UserRoleStatusName.Approved

  const parentEmail = data?.me?.emails ? data?.me?.emails[0]?.email : 'you'
  const parentName = data?.me?.firstName ?? 'Parent'
  const urlSearchParams = new URLSearchParams(location.search)

  const verifiedParentParam = urlSearchParams.get(Param.Parent) === 'verified'
  const [verificationLoading, setVerificationLoading] =
    React.useState(verifiedParentParam)

  const resendVerificationEmail = async (): Promise<void> => {
    try {
      await sendParentVerificationEmail()
      enqueueSnackbar('Email sent. Please check your inbox.', {
        variant: 'success',
      })
      setHasResentEmail(true)
    } catch {
      enqueueSnackbar('Something went wrong. Please contact support.', {
        variant: 'error',
      })
    }
  }

  const CreateChildAccountButton = (
    <NxTooltip
      arrow
      disableHoverListener={isVerified}
      interactive
      placement="right"
      title="Your identity must be verified before you can create a child account."
    >
      <Box>
        <NxButton
          disabled={!isVerified}
          label="Create Child Account"
          onClick={(): void =>
            navigate(
              `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildDataUsage}`
            )
          }
          variant="primary"
        />
      </Box>
    </NxTooltip>
  )

  const childUsers = childrenData?.getUserChildren?.users ?? []

  useEffect(() => {
    if (verifiedParentParam) {
      setTimeout(() => {
        refetchGetMyIdentityQuery()
        setVerificationLoading(false)
      }, 1000)
    }
  }, [verifiedParentParam])

  return (
    <Box display="flex" flexDirection="column">
      <ParentBanner />
      <WaitTillLoaded
        loading={loading || childrenLoading || verificationLoading}
        showSpinnerWhileLoading
      >
        <Box ml={4} mr={5} mt={5} pb={5}>
          {!isVerified && (
            <NxAlert
              actions={
                <NxTooltip
                  arrow
                  disableHoverListener={!hasResentEmail}
                  title={
                    hasResentEmail
                      ? "This email was already sent. Please contact support if you don't receive it within 15 minutes."
                      : ''
                  }
                >
                  <Box>
                    <NxButton
                      data-testid="resend-verify-email-btn"
                      disabled={hasResentEmail}
                      label="Resend Email"
                      onClick={resendVerificationEmail}
                      variant="secondary"
                    />
                  </Box>
                </NxTooltip>
              }
              className={classes.alert}
              content={`Your identity verification is pending. Please follow the instructions in the email we sent to ${parentEmail}. Once verified, you can create an account for your child.`}
              title="Identity Verification Pending. Please Check Your Email."
              variant="warn"
            />
          )}
          {verifiedParentParam && isVerified && (
            <NxAlert
              canClose
              className={classes.alert}
              content="Your parent identity has been successfully verified. You can now manage your family group using the Parent Dashboard."
              title={`Thanks, ${parentName}!`}
              variant="success"
            />
          )}
          <NxTypography data-testid="family-group-title" variant="h2">
            My Family Group
          </NxTypography>
          <Box mt={2}>
            {childUsers.length > 0 ? (
              <MembersCard childUsers={childUsers} />
            ) : (
              <NxEmptyState
                cta={
                  <>
                    <Hidden mdDown>
                      <NxTypography
                        className={classes.emptyStateSubtitle}
                        colorToken="ColorTextAlt2"
                        variant="subtitle1"
                      >
                        {CHILD_CREATE_ACCOUNT_CONTENT}
                      </NxTypography>
                    </Hidden>

                    <Hidden lgUp>
                      <NxTypography
                        className={classes.emptyStateSubtitle}
                        colorToken="ColorTextAlt2"
                        variant="body1"
                      >
                        {CHILD_CREATE_ACCOUNT_CONTENT}
                      </NxTypography>
                    </Hidden>

                    {CreateChildAccountButton}
                  </>
                }
                spot={
                  <NxSpot
                    domain="createTeam"
                    height={100}
                    size="large"
                    variant="primary"
                    width={100}
                  />
                }
                title="Create Your Child's Account"
              />
            )}
          </Box>
        </Box>
      </WaitTillLoaded>
    </Box>
  )
}
