import { makeStyles } from '@material-ui/core'
import React from 'react'
import { generatePath, useLocation } from 'react-router-dom'
import {
  NxUserCluster,
  NxTypography,
  NxTextLink,
  NxTooltip,
} from '@playvs-inc/nexus-components'
import { NxTypographyVariant } from '@playvs-inc/nexus-theme'
import { BadgeLight } from '@playvs-inc/nexus-icons'

import { EsportRating } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { Path, IntercomArticleMappings } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'

const ellipsis = {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
} as const

const useStyle = makeStyles((theme) => ({
  avatar: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
    },
    ...ellipsis,
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    ...ellipsis,
  },
}))

export const TeamCluster: React.FC<{
  avatarUrl?: string | null
  badge?: React.ReactElement
  opposing?: boolean
  subtitle?: string | null
  teamId?: string | null
  title?: string | null
  subtitleVariant?: NxTypographyVariant
  titleVariant?: NxTypographyVariant
  schoolSlug?: string
  esportRating?: EsportRating
  hasClinchedPlayoffs?: boolean | null
}> = ({
  avatarUrl,
  badge,
  opposing,
  subtitle,
  subtitleVariant,
  titleVariant,
  teamId,
  title,
  schoolSlug,
  esportRating,
  hasClinchedPlayoffs,
}) => {
  const location = useLocation()
  const isPublic = !location.pathname.includes('/app/')
  const classes = useStyle()
  const subtitleUrl = schoolSlug
    ? generatePath(isPublic ? Path.OrgPublicPage : Path.SchoolPage, {
        slug: schoolSlug,
      })
    : ``
  const titleUrl = teamId ? `${isPublic ? '/team' : Path.Team}/${teamId}` : ''

  const SubIcon = (
    <NxTooltip
      arrow
      leaveDelay={3000}
      placement="top"
      PopperProps={{ style: { pointerEvents: 'auto' } }}
      title={
        <>
          Playoffs team.{' '}
          <NxTextLink
            label="Learn More"
            onClick={(e: React.SyntheticEvent): void => {
              e.stopPropagation()
              showIntercomArticle(IntercomArticleMappings.playoffInfo)
            }}
          />
        </>
      }
    >
      <Box>
        <BadgeLight />
      </Box>
    </NxTooltip>
  )

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection={opposing ? 'row-reverse' : 'row'}
      maxWidth="100%"
      textAlign={opposing ? 'right' : 'left'}
    >
      <Box {...(opposing ? { pl: 2 } : { pr: 3 })}>
        <div style={{ position: 'relative' }}>
          {titleUrl ? (
            <NxUserCluster
              avatarHashId={teamId ?? ''}
              avatarUrl={
                esportRating === EsportRating.Restricted || !avatarUrl
                  ? undefined
                  : avatarUrl
              }
              onClickAvatar={(): void => {
                window.open(titleUrl, '_blank')
              }}
              {...(hasClinchedPlayoffs ? { SubIcon } : {})}
            />
          ) : (
            <Box>
              <NxUserCluster
                avatarHashId={teamId ?? ''}
                avatarUrl={
                  esportRating === EsportRating.Restricted || !avatarUrl
                    ? undefined
                    : avatarUrl
                }
                {...(hasClinchedPlayoffs ? { SubIcon } : {})}
              />
            </Box>
          )}
          {badge}
        </div>
      </Box>
      <div style={{ minWidth: 0 }}>
        {titleUrl ? (
          <NxTypography
            className={classes.title}
            onClick={(): void => {
              window.open(titleUrl, '_blank')
            }}
            variant={titleVariant ?? 'h3'}
          >
            {title}
          </NxTypography>
        ) : (
          <NxTypography
            className={classes.title}
            variant={titleVariant ?? 'h3'}
          >
            {title}
          </NxTypography>
        )}
        {subtitleUrl ? (
          <NxTypography
            className={classes.subtitle}
            data-testid="TeamCluster_subtitle_url"
            onClick={(): void => {
              window.open(subtitleUrl, '_blank')
            }}
            variant={subtitleVariant ?? 'subtitle2'}
          >
            {subtitle}
          </NxTypography>
        ) : (
          <NxTypography
            className={classes.subtitle}
            data-testid="TeamCluster_subtitle"
            variant={subtitleVariant ?? 'subtitle2'}
          >
            {subtitle}
          </NxTypography>
        )}
      </div>
    </Box>
  )
}
