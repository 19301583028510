import React from 'react'
import { TeamOverview } from '@plvs/rally/features/teams/team/Overview'
import { Path } from '@plvs/const'
import { TeamLeagues } from '@plvs/rally/containers/filter/league/TeamLeagues'
import { UserOnlineStatusProvider } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'
import { FilterCacheProvider } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { useLocation, useParams } from 'react-router'
import { withTransaction } from '@elastic/apm-rum-react'
import { PrivatePage } from '../page/PrivatePage'
import { PublicPage } from '../page/PublicPage'

export const TeamPageInternal: React.FC = () => {
  const params = useParams<{ teamId: string }>()
  const { pathname: path } = useLocation()

  const isPublic = !path.includes('/app')

  if (!params.teamId) {
    return <></>
  }

  if (isPublic) {
    return (
      <PublicPage
        description="Public Leage Page"
        hasDrawer
        title="Competitions"
      >
        <FilterCacheProvider id={Path.Team}>
          <UserOnlineStatusProvider>
            <TeamLeagues teamId={params.teamId}>
              <TeamOverview path={path} teamId={params.teamId} />
            </TeamLeagues>
          </UserOnlineStatusProvider>
        </FilterCacheProvider>
      </PublicPage>
    )
  }

  return (
    <PrivatePage title="Team">
      <FilterCacheProvider id={Path.Team}>
        <UserOnlineStatusProvider>
          <TeamLeagues teamId={params.teamId}>
            <TeamOverview path={path} teamId={params.teamId} />
          </TeamLeagues>
        </UserOnlineStatusProvider>
      </FilterCacheProvider>
    </PrivatePage>
  )
}

export const TeamPage = withTransaction(
  'TeamPage',
  'component'
)(TeamPageInternal)
