import * as yup from 'yup'
import { yupGradYear, yupGradYearRequired } from '@plvs/utils'
import { UserRoleName } from '@plvs/graphql/generated'
import { OnboardingContextValues } from '@plvs/respawn/features/onboard/OnboardingContext'

export const Under18MoreDetails = yup.object().shape({
  graduationYear: yupGradYearRequired,
  schoolType: yup.string().required(),
})

export const Over18MoreDetails = yup.object().shape({
  graduationYear: yupGradYear,
  role: yup.string().required('Please select an option'),
  schoolType: yup.string().required(),
})

export const getInitialRole = (
  onboardingContext: OnboardingContextValues,
  isUnder18: boolean
): UserRoleName | null => {
  let initialRole: UserRoleName | null =
    (onboardingContext.role as UserRoleName) || null
  if (isUnder18) {
    initialRole = UserRoleName.Student
  }

  return initialRole
}
