import React from 'react'

import { NxBreadcrumbs, NxTypography } from '@playvs-inc/nexus-components'
import { useNavigate, useParams } from 'react-router-dom'
import { Path } from '@plvs/const'
import { useGetChildPlatformConnectionsQuery } from '@plvs/graphql/generated'
import {
  gameConnectionComponentMap,
  platformConnectionComponentMap,
} from '@plvs/rally/features/account/connections/connections'
import { Divider, makeStyles } from '@material-ui/core'
import {
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { ParentBanner } from '@plvs/respawn/features/parent/components/ParentBanner'
import { AppPage } from '../../page'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
  },
  divider: {
    marginLeft: -theme.spacing(3),
    marginRight: -theme.spacing(3),
    boxShadow: theme.mixins.divider.bottom,
    backgroundColor: 'transparent',
  },
}))

type Connection = {
  name: string
  Component: typeof React.Component
}

export const ChildPlatformConnectionsPage: React.FC = () => {
  const navigate = useNavigate()

  const { userId } = useParams<{ userId: string }>()

  const classes = useStyles()

  const { data, error, loading, refetch } = useGetChildPlatformConnectionsQuery(
    {
      variables: { id: userId },
      skip: !userId,
    }
  )

  const providerAccounts = data?.user?.userProviderAccounts ?? []

  const mapConnectionToComponent = (
    { name, Component }: Connection,
    index: number,
    arr: Connection[]
  ): React.ReactElement => {
    return (
      <div key={name} data-testid="provider">
        <Component
          key={name}
          apolloError={error}
          checkpoint={false}
          isParent
          name={name}
          providerDetails={providerAccounts.find(
            (account) => account.providerName === name
          )}
          refetchUserProviderAccounts={refetch}
          userId={userId}
        />
        {index < arr.length - 1 && (
          <Divider className={classes.divider} variant="fullWidth" />
        )}
      </div>
    )
  }

  return (
    <AppPage title="Platform Connections">
      <ParentBanner />
      <PageContentGutter>
        <NxBreadcrumbs
          breadcrumbs={[
            {
              label: 'My Family Group',
              onClick: (): void => navigate(Path.ParentDashboard),
            },
            {
              label: 'Settings for Family Group Member',
              onClick: (): void => navigate(`${Path.App}/child/${userId}`),
            },
            {
              label: 'Edit Platform Connections',
              onClick: (): void => {},
            },
          ]}
          className={classes.breadcrumbs}
        />
        <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
          <div className={classes.cardContent}>
            <NxTypography variant="h2">Platform Connections</NxTypography>
            {gameConnectionComponentMap.map(mapConnectionToComponent)}
            <Divider className={classes.divider} variant="fullWidth" />
            {platformConnectionComponentMap.map(mapConnectionToComponent)}
          </div>
        </WaitTillLoaded>
      </PageContentGutter>
    </AppPage>
  )
}
