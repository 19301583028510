import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'
import tz from 'dayjs/plugin/timezone'
import {
  NxButton,
  NxEsportAvatar,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import dayjs from '@plvs/respawn/init/dayjs'
import { Dayjs } from 'dayjs'
import { MOMENT_SHORT_DATE_AND_TIME } from '@plvs/const'
import clsx from 'clsx'
import { EsportSlug } from '@plvs/graphql/types'
import { Box } from '@plvs/respawn/features/layout'
import { BasicRowsSkeleton } from '@plvs/respawn/features/skeleton/BasicRowsSkeleton'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '../tooltip'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  backgroundBox: {
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
  body: {
    color: theme.palette.text.secondary,
  },
  h3: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.text.primary,
  },
  icon: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.OverlayColorIconBase,
    '&:hover': {
      color: theme.palette.ColorIconBase,
    },
  },
  contentHeaders: {
    color: theme.palette.text.primary,
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  enrollmentButton: {
    [theme.breakpoints.down('sm')]: {
      width: 'calc(100vw - 48px)',
      maxWidth: 400,
    },
  },
  skeleton: {
    marginBottom: theme.spacing(1),
  },
  leagueContainer: {
    display: 'flex',
    flex: 1,
    overflowY: 'auto',
    maxHeight: '120px',
    paddingTop: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    marginBottom: theme.spacing(2),
    '&::-webkit-scrollbar': {
      right: theme.spacing(0.5),
      background: 'transparent',
      width: theme.spacing(1),
      border: `${theme.spacing(0.5)}px transparent`,
    },
    '&::-webkit-scrollbar-thumb': {
      width: theme.spacing(1.5),
      background: theme.palette.ColorBackgroundAlt2,
      borderRadius: 0,
      borderRight: `${theme.spacing(0.5)}px solid rgba(255,255,255,0)`,
      outlineColor: 'transparent',
      outline: '0 4px 0 0',
      backgroundClip: 'padding-box',
    },
    '&::-webkit-scrollbar-track-piece': {
      display: 'none',
    },
  },
  leagueRow: {
    borderBottom: `1px solid ${theme.palette.BorderLight}`,
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  esportLogo: {
    marginRight: theme.spacing(2),
    maxHeight: '36px',
  },
  registrationLabel: {
    color: theme.palette.ColorTextAlt2,
    fontSize: '12px',
  },
}))

export const MANAGE_ENROLLMENT = 'Manage Enrollments'
export const VIEW_ENROLLMENT = 'View Enrollments'

export type EnrollmentCardSeason = {
  metaseasonId: string | null
  registrationStartsAt: string | null
  teamRegistrationEndsAt: string | null
  suggestedRegistrationEndsAt: string | null
  teamDeregistrationEndsAt: string | null
}

export type EnrollmentCardLeague = {
  id: string
  displayName: string | null
  name: string | null
  seasons: EnrollmentCardSeason[] | null
}
export interface ManageTeamsEnrollmentCardProps {
  leagues: EnrollmentCardLeague[]
  loading: boolean
  title: string
  path: string
  metaseasonId: string
  hasAnEnrolledTeam?: boolean
  esportSlug: EsportSlug | null
}

type LeagueEnrollmentStatus = {
  isBeforeAnyEnrollmentDate: boolean
  isAllPastDeRegistrationDate: boolean
  isAnyEnrollmentStillOpen: boolean
  isAnyAfterRegEndDateButBeforeDeRegEndDate: boolean
  firstEnrollmentDate: Dayjs
}

export const getEnrollmentLeagueDateStatus = (
  leagues: EnrollmentCardLeague[],
  metaseasonId: string
): LeagueEnrollmentStatus | undefined => {
  if (!leagues.length) return undefined
  const today = dayjs()
  const dates = leagues.reduce(
    (accum, league) => {
      const currentSeason = league?.seasons?.find((season) => {
        return season.metaseasonId === metaseasonId
      })
      const regStartDate = dayjs(currentSeason?.registrationStartsAt)
      const regEndDate = dayjs(currentSeason?.suggestedRegistrationEndsAt)
      const deRegEndDate = dayjs(currentSeason?.teamDeregistrationEndsAt)

      return {
        earliestRegStartDate: regStartDate.isBefore(accum.earliestRegStartDate)
          ? regStartDate
          : accum.earliestRegStartDate,
        earliestRegEndDate: regEndDate.isBefore(accum.earliestRegEndDate)
          ? regEndDate
          : accum.earliestRegEndDate,
        latestDeRegDate: deRegEndDate.isAfter(accum.latestDeRegDate)
          ? deRegEndDate
          : accum.latestDeRegDate,
        isAnyAfterRegEndDateButBeforeDeRegEndDate:
          accum.isAnyAfterRegEndDateButBeforeDeRegEndDate ||
          (today.isSameOrAfter(regEndDate) && today.isBefore(deRegEndDate)),
        isAnyEnrollmentStillOpen:
          accum.isAnyEnrollmentStillOpen ||
          (today.isSameOrAfter(regStartDate) && today.isBefore(regEndDate)),
      }
    },
    {
      earliestRegStartDate: dayjs().add(100, 'years'),
      earliestRegEndDate: dayjs().add(100, 'years'),
      latestDeRegDate: dayjs().subtract(100, 'years'),
      isAnyEnrollmentStillOpen: false,
      isAnyAfterRegEndDateButBeforeDeRegEndDate: false,
    }
  )

  return {
    isBeforeAnyEnrollmentDate: today.isSameOrBefore(dates.earliestRegStartDate),
    isAllPastDeRegistrationDate: today.isAfter(dates.latestDeRegDate),
    isAnyEnrollmentStillOpen: dates.isAnyEnrollmentStillOpen,
    isAnyAfterRegEndDateButBeforeDeRegEndDate:
      dates.isAnyAfterRegEndDateButBeforeDeRegEndDate,
    firstEnrollmentDate: dates.earliestRegStartDate,
  }
}

export const getEnrollmentButtonProps = (
  status: LeagueEnrollmentStatus | undefined,
  hasAnEnrolledTeam: boolean
): { cta: string; disabled: boolean; tooltipMsg: string } => {
  let cta = MANAGE_ENROLLMENT
  let disabled = true
  let tooltipMsg = ''
  if (status) {
    if (status.isAnyEnrollmentStillOpen) {
      cta = MANAGE_ENROLLMENT
      disabled = false
    } else if (status.isBeforeAnyEnrollmentDate) {
      cta = MANAGE_ENROLLMENT
      tooltipMsg = `Enrollment Opens ${status.firstEnrollmentDate.format(
        MOMENT_SHORT_DATE_AND_TIME
      )}`
    } else if (status.isAllPastDeRegistrationDate) {
      cta = MANAGE_ENROLLMENT
      tooltipMsg =
        'The unenrollment deadline has passed. Please contact support for assistance.'
    }
    if (status.isAnyAfterRegEndDateButBeforeDeRegEndDate) {
      if (hasAnEnrolledTeam) {
        cta = VIEW_ENROLLMENT
        disabled = false
      } else {
        cta = MANAGE_ENROLLMENT
        tooltipMsg = 'Enrollment is Closed'
      }
    }
  }
  return { cta, disabled, tooltipMsg }
}

export const ManageTeamsEnrollmentCard: React.FC<ManageTeamsEnrollmentCardProps> = ({
  leagues,
  loading,
  title,
  path,
  metaseasonId,
  hasAnEnrolledTeam,
  esportSlug,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const {
    backgroundBox,
    h3,
    enrollmentButton,
    skeleton,
    leagueContainer,
    leagueRow,
    esportLogo,
    registrationLabel,
    buttonContainer,
  } = useStyles()
  const leaguesFormatted = leagues.map((league) => {
    const currentSeason = league?.seasons?.find((season) => {
      return season.metaseasonId === metaseasonId
    })
    const regEndDate = dayjs(currentSeason?.suggestedRegistrationEndsAt)
    const isPast = dayjs() > regEndDate
    return {
      id: league?.id,
      name: league?.displayName || league?.name,
      isPast,
      registrationEndDate: regEndDate
        .tz(dayjs.tz.guess())
        .format('MMM Do ha z'),
    }
  })
  const leagueEnrollmentStatus = getEnrollmentLeagueDateStatus(
    leagues,
    metaseasonId
  )
  const buttonProps = getEnrollmentButtonProps(
    leagueEnrollmentStatus,
    hasAnEnrolledTeam || false
  )

  return leaguesFormatted.length ? (
    <MatchCard
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      backgroundColor={theme.palette.ColorBackgroundBase as any}
      className={clsx(backgroundBox, 'shepherd-manage-enrollments-widget')}
      px={3}
      py={4}
    >
      <NxTypography
        className={h3}
        data-testid="ManageTeamsEnrollmentCard_Title"
        variant="h3"
      >
        {title}
      </NxTypography>
      <Box
        className={leagueContainer}
        display="flex"
        flexDirection="column"
        py={3}
      >
        {loading ? (
          <BasicRowsSkeleton numRows={3} rowClassname={skeleton} />
        ) : (
          leaguesFormatted.map(({ id, ...league }) => (
            <Box
              key={id}
              className={leagueRow}
              data-testid="ManageTeamsEnrollmentCard_Row"
              display="flex"
              flexDirection="row"
              py={2}
            >
              {esportSlug && (
                <NxEsportAvatar className={esportLogo} esport={esportSlug} />
              )}
              <Box>
                <NxTypography>{league.name}</NxTypography>
                <NxTypography
                  className={registrationLabel}
                  data-testid="ManageTeamsEnrollmentCard_EnrollmentLabel"
                >
                  {league.isPast
                    ? `Enrollment Closed on ${league.registrationEndDate}`
                    : `Enroll by ${league.registrationEndDate}`}
                </NxTypography>
              </Box>
            </Box>
          ))
        )}
      </Box>
      <Box className={buttonContainer}>
        <Tooltip title={buttonProps.disabled ? buttonProps.tooltipMsg : ''}>
          <NxButton
            className={enrollmentButton}
            component="a"
            data-testid="ManageTeamsEnrollmentCard_Button"
            fullWidth
            href={path ?? ''}
            label={buttonProps.cta}
            onClick={(evt): void => {
              // If command click, allow the browser to just open this in a new window.
              if (!evt.metaKey) {
                evt.preventDefault()
                navigate(path)
              }
            }}
            variant="primary"
          />
        </Tooltip>
      </Box>
    </MatchCard>
  ) : (
    <></>
  )
}
