import { MinimalMetaseason } from '@plvs/utils'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)
dayjs.extend(tz)

type DisabledReturn = {
  disabled: boolean
  disabledMessage: string
}

export const getDisabledPlayerRow = ({
  isPromotedMetaseason,
  isEnrolledInCurrentSeason,
  currentEnrolledSeason,
}: {
  isPromotedMetaseason: boolean
  isEnrolledInCurrentSeason: boolean
  currentEnrolledSeason: MinimalMetaseason | null | undefined
}): DisabledReturn => {
  if (isPromotedMetaseason && isEnrolledInCurrentSeason) {
    return {
      disabled: true,
      disabledMessage: `This roster is currently competing. We recommend updating this roster after the ${currentEnrolledSeason?.name} season ends.`,
    }
  }
  return {
    disabled: false,
    disabledMessage: '',
  }
}

export const DISABLED_LEAGUE_BC_OF_PLAYERS_MSG =
  'has at least one player on another team that is enrolled in this league. A player cannot be enrolled in the same league twice.'
export const getDisabledLeagueBcOfPlayersMsg = (): string => {
  return DISABLED_LEAGUE_BC_OF_PLAYERS_MSG
}

export const getDisabled = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hasNoMembers,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  teamName,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  notEnoughStarters,
}: {
  hasNoMembers: boolean
  teamName: string | null | undefined
  notEnoughStarters: boolean
}): DisabledReturn => {
  // GP-2112 - Temporarily disable enrollment team count check.
  // const disabledMessageBeginning = `You cannot select ${
  //   teamName ?? 'this team'
  // } to enroll because `

  // if (hasNoMembers)
  //   return {
  //     disabled: true,
  //     disabledMessage: `${disabledMessageBeginning}the team has no members.`,
  //   }

  // if (notEnoughStarters) {
  //   return {
  //     disabled: true,
  //     disabledMessage: `${disabledMessageBeginning}the team does not have enough roster players.`,
  //   }
  // }

  return {
    disabled: false,
    disabledMessage: '',
  }
}

export const formatPlayDate = (isoDate: string): string => {
  const m = dayjs(isoDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
  return m.format('ddd @ h:mm a z')
}
