import {
  CompetitionGroup,
  CompetitionModel,
  ResourceType,
  UserRole,
  UserRoleName,
} from '@plvs/graphql'
import {
  rosterCardMenuEmptyRowRules,
  rosterCardMenuItemCoachRules,
  rosterCardMenuItemMultipleCoachRules,
  rosterCardMenuItemRules,
  rosterCardMenuRowItemDisabledRules,
  rosterCardMenuRowItemRules,
  rosterCardMenuRowSubItemDisabledRules,
  rosterCardMenuRowSubRules,
  Rules,
} from './rosterMenuRequirements'

export type Roles = Pick<UserRole, 'resourceType' | 'resourceId' | 'roleName'>[]
export type EntityOptions = {
  resourceType?: ResourceType
  resourceId?: string
  roleName?: UserRoleName
}
export type TeamContext = {
  id: string
  competitionModel: CompetitionModel | undefined
  competitionGroup: CompetitionGroup | undefined
  resourceType: ResourceType
  schoolId: string | null
}

export type GrantedRosterMenuPermissions = Array<Rules[0]['menuItemName']>

export enum ContentContextPermissions {
  ROSTER_MENU,
  ROSTER_ITEMS_MENU,
  ROSTER_ITEMS_MENU_DISABLED_REMOVAL,
  ROSTER_EMPTY_ITEMS_MENU,
  BENCH_ITEMS_MENU_DISABLED_REMOVAL,
  BENCH_ITEMS_MENU,
  COACH_ITEMS_MENU,
  MULTIPLE_COACH_ITEMS_MENU,
}

export const getRulesByContentPermissions = (
  contentContext: ContentContextPermissions
): Rules | null => {
  switch (contentContext) {
    case ContentContextPermissions.ROSTER_MENU:
      return rosterCardMenuItemRules
    case ContentContextPermissions.ROSTER_ITEMS_MENU:
      return rosterCardMenuRowItemRules
    case ContentContextPermissions.ROSTER_ITEMS_MENU_DISABLED_REMOVAL:
      return rosterCardMenuRowItemDisabledRules
    case ContentContextPermissions.ROSTER_EMPTY_ITEMS_MENU:
      return rosterCardMenuEmptyRowRules
    case ContentContextPermissions.BENCH_ITEMS_MENU_DISABLED_REMOVAL:
      return rosterCardMenuRowSubItemDisabledRules
    case ContentContextPermissions.BENCH_ITEMS_MENU:
      return rosterCardMenuRowSubRules
    case ContentContextPermissions.COACH_ITEMS_MENU:
      return rosterCardMenuItemCoachRules
    case ContentContextPermissions.MULTIPLE_COACH_ITEMS_MENU:
      return rosterCardMenuItemMultipleCoachRules
    default:
      return null
  }
}

export interface FilterRolesProps {
  roles: Roles
  options: EntityOptions
}
export const filterRoles = ({ roles, options }: FilterRolesProps): Roles => {
  return roles.filter(
    (role) =>
      (options.resourceType
        ? role.resourceType === options.resourceType
        : true) &&
      (options.resourceId ? role.resourceId === options.resourceId : true) &&
      (options.roleName ? role.roleName === options.roleName : true)
  )
}

export interface GetRosterMenuRowItemsProps {
  isSelf: boolean
  team: TeamContext
  userRoles: Roles
  memberRoles: Roles
  rules: Rules | null
  memberEntityOptions?: EntityOptions
}
export const getRosterMenuRowItems = ({
  team,
  userRoles,
  rules,
  isSelf,
  memberRoles,
  memberEntityOptions,
}: GetRosterMenuRowItemsProps): GrantedRosterMenuPermissions => {
  if (!rules) return []
  const userRoleNamesForEntity = userRoles
    .filter(
      (role) =>
        (role.roleName === UserRoleName.Admin &&
          role.resourceType === ResourceType.System) ||
        role.resourceId === team.id ||
        role.resourceId === team.schoolId
    )
    .map((role) => role.roleName)

  const memberRoleNamesForTeam = filterRoles({
    roles: memberRoles,
    options: memberEntityOptions || {
      resourceId: team.id,
      resourceType: team.resourceType,
    },
  }).map((role) => role.roleName)

  return rules
    .filter((rule) => {
      return rule.requirements.some((requirement) => {
        const matchesCompGroupAndModel =
          requirement.competitionGroup === team.competitionGroup &&
          (requirement.competitionModel
            ? team.competitionModel === requirement.competitionModel
            : true)
        const matchesUserRoleNames = requirement.userRoleNames.length
          ? requirement.userRoleNames.some((roleName) =>
              userRoleNamesForEntity.includes(roleName)
            )
          : true
        const matchesIncludesSelf = isSelf ? requirement?.includesSelf : true
        const matchesMemberRoles = requirement?.memberRoleNames?.length
          ? requirement.memberRoleNames.some((roleName) =>
              memberRoleNamesForTeam.includes(roleName)
            )
          : true
        const matchesExcludeMemberRoles = requirement?.excludeMemberRoleNames
          ?.length
          ? !requirement.excludeMemberRoleNames.some((roleName) =>
              memberRoleNamesForTeam.includes(roleName)
            )
          : true
        const matchesExcludeOtherMembers = !isSelf
          ? !requirement.excludeOtherMembers
          : true

        return (
          matchesExcludeMemberRoles &&
          matchesCompGroupAndModel &&
          matchesUserRoleNames &&
          matchesIncludesSelf &&
          matchesExcludeOtherMembers &&
          matchesMemberRoles
        )
      })
    })
    .map((requirement) => requirement.menuItemName)
}
