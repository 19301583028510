import {
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  isAdminForSystem,
  isCaptainForResource,
  isCoachForScholasticRelatedTeam,
  isPlayerForResource,
  isTeamOwnerForResource,
} from '@plvs/utils'
import { ChatRole } from '@plvs/graphql/generated'
import { MatchTeamForRenderController } from '../../match/types/matchRenderController.types'
import {
  ChatComponentProps,
  ChatComponentReturn,
} from '../types/chatRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'

const determineChatRole = ({
  userRoles,
  team1,
  team2,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
}): ChatRole => {
  const isCoachOfEitherTeamOrSchool =
    isCoachForScholasticRelatedTeam(userRoles, team1) ||
    isCoachForScholasticRelatedTeam(userRoles, team2)
  const isCaptainOfEitherTeam = isCaptainForResource(userRoles, [
    team1.id,
    team2.id,
  ])
  const isTeamOwnerOfEitherTeam = isTeamOwnerForResource(userRoles, [
    team1.id,
    team2.id,
  ])
  // Order matters here. For example, if a user is a captain, they also have a player role on the team.
  // Therefore, we need to check for captain first.
  if (isCoachOfEitherTeamOrSchool) {
    return ChatRole.Coach
  }
  if (isCaptainOfEitherTeam || isTeamOwnerOfEitherTeam) {
    return ChatRole.Captain
  }
  return ChatRole.Player
}

export const determineChatRenderForOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
}): ChatComponentReturn => {
  const isPlayerOfEitherTeam = isPlayerForResource(userRoles, [
    team1.id,
    team2.id,
  ])
  const isCoachOfEitherTeamOrSchool =
    isCoachForScholasticRelatedTeam(userRoles, team1) ||
    isCoachForScholasticRelatedTeam(userRoles, team2)

  const chatRole = determineChatRole({ userRoles, team1, team2 })

  return {
    shouldRenderChat: isPlayerOfEitherTeam || isCoachOfEitherTeamOrSchool,
    shouldRenderCoachChat: isCoachOfEitherTeamOrSchool,
    chatRole,
  }
}

export const determineChatRenderForNonOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
}): ChatComponentReturn => {
  // Note that being a player is lowest level of association with a team.
  const isPlayerOfEitherTeam = isPlayerForResource(userRoles, [
    team1.id,
    team2.id,
  ])

  const chatRole = determineChatRole({ userRoles, team1, team2 })

  return {
    shouldRenderChat: isPlayerOfEitherTeam,
    shouldRenderCoachChat: false,
    chatRole,
  }
}

export const determineChatRender = ({
  userRoles,
  team1,
  team2,
  competitionGroup,
}: ChatComponentProps): ChatComponentReturn => {
  if (!competitionGroup) {
    return {
      shouldRenderChat: false,
      shouldRenderCoachChat: false,
      // Player is the lowest level of permissions in chat.
      chatRole: ChatRole.Player,
    }
  }

  const isAdmin = isAdminForSystem(userRoles)
  const isOrgAssociatedCompetitionGroup =
    ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)
  if (isAdmin) {
    return {
      shouldRenderChat: true,
      shouldRenderCoachChat: !!isOrgAssociatedCompetitionGroup,
      chatRole: ChatRole.Admin,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    return determineChatRenderForOrgBasedMatch({
      userRoles,
      team1,
      team2,
    })
  }

  return determineChatRenderForNonOrgBasedMatch({
    userRoles,
    team1,
    team2,
  })
}
