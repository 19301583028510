import { Button, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import React, { useState } from 'react'
import { Iframe } from '@plvs/rally/components/sheerId/Iframe'
import { rallyEnv } from '@plvs/rally/env'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { Colors } from '@plvs/rally/themes'
import { useGetScheduleConsultLinkQuery } from '@plvs/graphql/generated/graphql'
import { getNow, hasEnrolledTeams } from '@plvs/utils'
import { useUserIdentityFn } from '@plvs/client-data/hooks/useUserIdentityFn'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    height: `calc(100vh - ${theme.spacing(11.2)}px)`,
    minHeight: '53em',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  docUploadContainer: {
    display: 'flex',
    height: '100%',
    width: '100%',
    padding: '0px 80px',
    flexDirection: 'column',
  },
  docUpload: {
    width: '456px',
    alignSelf: 'center',
  },

  skip: {
    textDecoration: 'none',
    padding: theme.spacing(1),
    fontWeight: 600,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
    color: Colors.Black,
  },
}))

interface Props {
  verificationId: string
  isStudent: boolean
}

export const OnboardSheerIdDocUpload: React.FC<Props> = ({
  verificationId,
  isStudent,
}) => {
  const [visibleTeamsTimeWindow] = useState(getNow({ minuteDifference: -120 }))
  const classes = useStyles()
  const navigate = useNavigate()
  const { userId } = useUserIdentityFn()

  const scheduleConsultEnabled = true

  const { data: contextData } = useOnboardingContext()
  const { schoolId } = contextData

  const { data: scheduleData } = useGetScheduleConsultLinkQuery({
    variables: {
      schoolId: schoolId as string,
      userId,
      effectiveAt: visibleTeamsTimeWindow,
    },
    skip: !scheduleConsultEnabled || !schoolId,
    // in case SF integration is down so we can at least safely fallback
    // to completing onboarding.
    errorPolicy: 'ignore',
  })

  const hasEnrolled = hasEnrolledTeams({
    teamEnrollments: scheduleData?.findTeamEnrollmentsByUser?.teamEnrollments,
  })
  const scheduleLink = scheduleData?.school?.salesRep?.calendarLink
  const showNASEFUx = contextData?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const onSkip = (): void => {
    if (scheduleConsultEnabled) {
      if (scheduleLink && !hasEnrolled) {
        navigate(`${Path.SpawnPoint}/schedule-consult${showNASEFUx}`, {
          state: { scheduleLink },
        })
        return
      }
    }

    navigate('finish')
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.docUploadContainer} mt={4}>
        <MatchCard className={classes.docUpload}>
          <Iframe
            programId={
              isStudent
                ? rallyEnv.STUDENT_SHEERID_PROGRAM_ID
                : rallyEnv.FACULTY_SHEERID_PROGRAM_ID
            }
            verificationId={verificationId}
          />
        </MatchCard>
        <Box m={2} textAlign="center">
          <Button
            className={classes.skip}
            data-testid="OnboardSheerIdDocUpload_SkipButton"
            onClick={onSkip}
            variant="text"
          >
            SKIP
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
