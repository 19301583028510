import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { generatePath, Link, useLocation } from 'react-router-dom'

import { NxEsportAvatar, NxTypography } from '@playvs-inc/nexus-components'

import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import dayjs from '@plvs/respawn/init/dayjs'
import { Path } from '@plvs/const'
import {
  MatchStatus,
  EsportSlug,
  ChatRole,
  Maybe,
  MatchScheduleContext,
} from '@plvs/graphql'
import { LiveIndicator } from './LiveIndicator'
import { UnreadMessagesIndicator } from './UnreadMessagesIndicator'
import { LowLevelMatchData } from './drawerMatchListHelpers'

export interface MinimumMatchData {
  id: string
  esport?: {
    slug?: EsportSlug
  }
  scheduledStartsAt: string
  status: MatchStatus
  matchResults: {
    team: {
      id: string
      name: string
    }
  }[]
  // for TBD matches
  teamId?: string
  slotId?: string
  teamName?: string
  scheduleContext: Maybe<MatchScheduleContext>
}

interface DrawerMatchCardProps {
  data: LowLevelMatchData
  chatRole?: ChatRole
}

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: theme.spacing(1, 2),
    display: 'flex',
    borderRadius: theme.shape.borderRadius * 1.5,
    background: 'inherit',
    color: theme.palette.ColorTextBase,
    width: '100%',
    '&:hover': {
      background:
        theme.palette.type === MUIThemeModeEnum.Dark
          ? theme.palette.ColorBackgroundAlt2
          : theme.palette.ColorBackgroundBase,
    },
  },
  link: {
    textDecoration: 'none',
    display: 'block',
    marginRight: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  teamNameWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  teamName: ({ isActive }: { isActive: boolean }): CreateCSSProperties => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: isActive ? 600 : undefined,
  }),
  teamInfo: {
    overflow: 'hidden',
    width: '100%',
  },
  liveText: {
    color: theme.palette.ColorTextError,
  },
  upcomingText: {
    color: theme.palette.ColorTextAlt2,
  },
}))

interface MatchStates {
  isActive: boolean
  isLive: boolean
  isInMatchQueue: boolean
  isUpcoming: boolean
  isPast: boolean
}

export const deriveMatchStates = (
  data: LowLevelMatchData,
  pathname: string
): MatchStates => {
  const hasId = data?.id || data?.slotId
  const isActive = hasId
    ? pathname.indexOf((data?.id || data?.slotId) as string) >= 0
    : false
  const isLive =
    data.status === MatchStatus.Live || data.status === MatchStatus.Open
  const isInMatchQueue = data?.status
    ? [
        MatchStatus.Cancelled,
        MatchStatus.Completed,
        MatchStatus.Forfeited,
        MatchStatus.Reviewed,
      ].indexOf(data?.status) >= 0 &&
      dayjs(data.scheduledStartsAt).add(4, 'hours').isAfter()
    : false

  const isUpcoming =
    !isLive && !isInMatchQueue && dayjs(data.scheduledStartsAt).isAfter()
  const isPast = !isLive && dayjs(data.scheduledStartsAt).isBefore()

  return {
    isActive,
    isLive,
    isInMatchQueue,
    isUpcoming,
    isPast,
  }
}

export const DrawerMatchCard: React.FC<DrawerMatchCardProps> = ({
  data,
  chatRole,
}) => {
  const location = useLocation()

  const esportSlug = data?.esport?.slug

  let matchPath = generatePath(Path.Schedule)
  if (data?.id) {
    matchPath = generatePath(Path.MatchLobby, {
      matchId: data?.id,
    })
  } else if (data?.slotId && data?.teamId) {
    matchPath = generatePath(Path.QueueLobby, {
      slotId: data.slotId,
      teamId: data.teamId,
    })
  }

  const { isActive, isLive, isUpcoming, isPast } = deriveMatchStates(
    data,
    location.pathname
  )

  const styles = useStyles({ isActive })

  const formattedDate = dayjs(data.scheduledStartsAt).format('MMM DD, h:mmA')

  return (
    <Box className={styles.wrapper}>
      <Link className={styles.link} to={matchPath}>
        {esportSlug && <NxEsportAvatar esport={esportSlug} />}
      </Link>
      <div className={styles.teamInfo}>
        <div className={styles.teamNameWrapper}>
          <Link className={styles.link} to={matchPath}>
            <NxTypography className={styles.teamName} variant="body3">
              {data?.teamName}
            </NxTypography>
          </Link>
          {data.id && (
            <UnreadMessagesIndicator chatRole={chatRole} matchId={data.id} />
          )}
        </div>
        {isLive && <LiveIndicator isLive />}
        {isUpcoming && (
          <NxTypography className={styles.upcomingText} variant="body5">
            Upcoming · {formattedDate}
          </NxTypography>
        )}
        {isPast && (
          <NxTypography className={styles.upcomingText} variant="body5">
            Past · {formattedDate}
          </NxTypography>
        )}
      </div>
    </Box>
  )
}
