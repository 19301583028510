import React from 'react'
import { Box, FormControlLabel } from '@material-ui/core'

import { NxCheckbox, NxTypography } from '@playvs-inc/nexus-components'

import { BetaPill } from '@plvs/respawn/features/beta/BetaPill'

type ChangeRosterProps = {
  checked: boolean
  disabled?: boolean
  onChange(checked: boolean): void
}

export const ChangeRoster: React.FC<ChangeRosterProps> = ({
  checked,
  onChange,
  disabled = false,
}) => {
  return (
    <Box alignItems="center" display="flex" justifyContent="center">
      <FormControlLabel
        control={
          <NxCheckbox
            checked={checked}
            disabled={disabled}
            name="change-roster"
            onChange={(_evnt, value): void => onChange(value)}
            value={checked}
          />
        }
        data-testid="change-roster"
        disabled={disabled}
        label={
          <NxTypography
            colorToken={disabled ? 'ColorTextDisabled' : undefined}
            variant="body1"
          >
            Roster Change Next Game
          </NxTypography>
        }
      />
      <BetaPill title="Change Roster" />
    </Box>
  )
}
