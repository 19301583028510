import React, { useMemo } from 'react'
import { makeStyles, Paper, Theme, useTheme } from '@material-ui/core'
import { useGetAdapter } from '@plvs/respawn/features/esport/adapters'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  EsportSlug,
  Phase,
  PhaseFormat,
  useBracketResultsQuery,
  useSeasonSponsorshipImagesQuery,
} from '@plvs/graphql'
import { competitionModelToDisplay } from '@plvs/utils'
import {
  Box,
  useBreakpointXs,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'

import { useOrganizationsContext } from '@plvs/rally/containers/organization/OrganizationsProvider'
import { NxTypography, NxErrorState } from '@playvs-inc/nexus-components'
import { CreateCSSProperties } from '@material-ui/styles'

import { LeagueMetadata } from '@plvs/rally/containers/filter/league/myLeaguesTypes'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  MUIThemeModeEnum,
  useEsport as useNexusEsport,
} from '@playvs-inc/nexus-theme'
import { BracketContainer } from '@plvs/checkpoint/containers/bracket/BracketContainer'
import { RegionalNational } from '@plvs/respawn/assets/icon'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { FnRegularStandings } from './FnRegularStandings'
import { VsDoubleElimStandings } from './VsDoubleElimStandings'
import { VsRegularStandings } from './VsRegularStandings'
import { StandingsActions } from './StandingsActions'
import { getLeagueSponsorLogos } from '../explore/ExploreHelpers'
import { VsPlayoffStandings } from './VsPlayoffStandings'

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.common.white,
  },
  buttonGroup: {
    height: 34,
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2,
    '&:first-letter': {
      textTransform: 'capitalize',
    },
  },
  phaseAndSeasonName: {
    textTransform: 'capitalize',
  },
  header: ({
    esportBackgroundColor,
  }: {
    esportBackgroundColor: string
  }): CreateCSSProperties => ({
    backgroundColor: esportBackgroundColor,
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    position: 'relative',
  }),
  page: {
    overflow: 'hidden',
  },
  icon: {
    marginRight: theme.spacing(2),
    minHeight: 32,
    minWidth: 32,
    color: theme.palette.OverlayColorIconBase,
  },
  download: {
    marginLeft: 'auto',
    zIndex: 1,
  },
  mobileSponsorLogo: {
    marginLeft: -theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'absolute',
    width: '100%',
  },
}))

export const StandingsByEsport: React.FC<{
  phase: Pick<Phase, 'id' | 'name' | 'format'>
  seasonId: string
  league?: LeagueMetadata
}> = ({ phase, seasonId, league }) => {
  const flags = useFlags()
  const isMobile = useBreakpointXs()

  // custom hooks
  const theme = useTheme<Theme>()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark
  const { isFortnite, slug } = useEsportContext()
  const { competitionModel } = useSchoolLeagueInfoContext()
  const { isLoggedIn } = useUserIdentityFn()

  const esportSlug = slug || EsportSlug.LeagueOfLegends

  const esport = useNexusEsport(esportSlug)
  const classes = useStyles({
    esportBackgroundColor: esport?.palette.baseColor || '',
  })

  const { competitionModels, competitionGroup } = useOrganizationsContext()
  const displayCompetitionModels = competitionModels.length > 1

  // Match card title info
  const competitionModelDisplayName = competitionModelToDisplay({
    competitionModel,
    competitionGroup,
  })

  // bracket information
  const isDoubleElimination = phase?.format === PhaseFormat.DoubleElimination
  const toMatch = (matchId: string): string => `/app/match/${matchId}`

  const { data: bracketData, loading, refetch } = useBracketResultsQuery({
    skip: !phase.id || isDoubleElimination || flags.newBracketDesign,
    variables: { phaseId: phase.id },
    notifyOnNetworkStatusChange: !flags.newBracketDesign,
  })

  const { data: sponsorshipImageData } = useSeasonSponsorshipImagesQuery({
    variables: { seasonId },
    skip: !seasonId,
  })
  const { sectionLogo, mobileSectionLogo } = useMemo(
    () =>
      getLeagueSponsorLogos({
        images: sponsorshipImageData?.season?.sponsorshipImages ?? [],
        isDarkMode,
      }),
    [sponsorshipImageData]
  )

  let isBracketView = isDoubleElimination
  if (!isBracketView) {
    const phaseFromData = bracketData?.phase
    const teams = phaseFromData?.teams ?? []
    isBracketView =
      !!teams.length ||
      (flags.newBracketDesign &&
        phase?.format === PhaseFormat.SingleElimination)
  }

  const { NexusIcon } = useGetAdapter(esportSlug)

  // This useEffect refetches the bracket data from the cache based on the phase changing. Enabling notifyOnNetworkStatusChange will trigger the loading state to update correctly.
  // Now the loading state will be true when the phase changes and the bracket data is refetched,
  // resulting in the cache updating based on the new phase, and the correct data being pulled from the cache and passed.
  React.useEffect(() => {
    if (phase?.id && !isDoubleElimination && !flags.newBracketDesign) {
      refetch({ phaseId: phase.id })
    }
  }, [phase?.id, isDoubleElimination, refetch])

  if (flags.errorStateStandings) {
    return (
      <NxErrorState
        additionalInfo="Please check back."
        componentDescription="standings page"
      />
    )
  }

  if (isFortnite) {
    return <FnRegularStandings esportSlug={slug} phaseId={phase.id} />
  }

  const bracketView = flags.newBracketDesign ? (
    <BracketContainer
      esportSlug={esportSlug}
      isLoggedIn={isLoggedIn}
      phaseId={phase.id}
    />
  ) : (
    <VsPlayoffStandings
      bracketData={bracketData}
      slug={slug}
      toMatch={toMatch}
    />
  )

  return (
    <>
      {mobileSectionLogo && isMobile && (
        <Box mb={10}>
          <Box className={classes.mobileSponsorLogo}>{mobileSectionLogo}</Box>
        </Box>
      )}
      <Paper className={classes.page}>
        <Box
          className={classes.header}
          display="flex"
          flexDirection="column"
          p="32px"
        >
          <Box display="flex" flexDirection="row">
            <Box
              alignItems="center"
              display="flex"
              flexDirection="row"
              width="100%"
            >
              <NexusIcon className={classes.icon} mode="dark" />

              <NxTypography className={classes.title} variant="h3">
                {displayCompetitionModels && competitionModelDisplayName}{' '}
                {league?.displayName && league.displayName} Standings
              </NxTypography>
              {sectionLogo && !isMobile && (
                <Box position="absolute" right={theme.spacing(6.5)} top={0}>
                  {sectionLogo}
                </Box>
              )}
              {!isBracketView && (
                <Box ml="auto">
                  <StandingsActions
                    leagueName={league?.displayName}
                    phase={phase}
                  />
                </Box>
              )}
            </Box>

            {displayCompetitionModels ? RegionalNational : null}
          </Box>
        </Box>
        <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
          {isBracketView ? (
            <>
              {isDoubleElimination ? (
                <VsDoubleElimStandings
                  phaseId={phase.id}
                  slug={slug}
                  toMatch={toMatch}
                />
              ) : (
                bracketView
              )}
            </>
          ) : (
            <Box px={3} py={0}>
              <VsRegularStandings phaseId={phase.id} />
            </Box>
          )}
        </WaitTillLoaded>
      </Paper>
    </>
  )
}
