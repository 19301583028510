import React, { useEffect, useRef, useState } from 'react'

import { Box, makeStyles, useTheme } from '@material-ui/core'
import { InfoFilled, Ingite, StandingOutlined } from '@playvs-inc/nexus-icons'
import {
  NxTextLink,
  NxTooltip,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { EsportSlug } from '@plvs/graphql/types'
import { isNotValidNumber } from 'ramda-adjunct'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { CreateCSSProperties } from '@material-ui/styles'
import { useBreakpointSm } from '@plvs/respawn/features/layout'
import { IntercomArticleMappings, Path } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { generatePath } from 'react-router-dom'
import { useRosterCardControllerState } from '@plvs/respawn/renderController/rosterCard/RosterCardRenderControllerProvider'

interface StyleProps {
  showTooltipOnTitle?: boolean
  isTeamDeleted?: boolean
}

const useStyles = makeStyles((theme) => ({
  teamNameContainer: ({
    showTooltipOnTitle,
  }: StyleProps): CreateCSSProperties => ({
    display: 'flex',
    cursor: showTooltipOnTitle ? 'pointer' : 'auto',
    width: 'calc(100% - 125px)',
    alignItems: 'center',
    gridGap: theme.spacing(1),
  }),
  teamNameTooltipBox: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  teamName: ({ isTeamDeleted }: StyleProps): CreateCSSProperties => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: isTeamDeleted ? 'auto' : 'pointer',
    textDecoration: isTeamDeleted ? 'line-through' : 'none',
  }),
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 3, 0, 3),
    alignItems: 'center',
    overflow: 'hidden',
  },
  deletedTooltip: {
    height: 20,
    width: 20,
  },
  statsContainer: {
    display: 'flex',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    gridGap: theme.spacing(1.5),
  },
  iconContainer: {
    display: 'flex',
  },
  stat: {
    paddingLeft: theme.spacing(0.5),
  },
  icon: {
    display: 'flex',
  },
  tooltip: {
    pointerEvents: 'auto',
  },
}))

interface TeamStatsRosterHeaderProps {
  teamName?: string | null
  teamId?: string
  isTeamDeleted: boolean
  ranking?: number | null
  winLossRecord: {
    matchesWon?: number | null
    matchesLost?: number | null
  }
  hasClinchedPlayoffs: boolean
  esportSlug: EsportSlug | null | undefined
  phaseId: string | undefined
  seasonId?: string | null
  enableScoutingToTeamsPage: boolean
}

export const TeamStatsRosterHeader: React.FC<TeamStatsRosterHeaderProps> = ({
  teamName,
  teamId,
  isTeamDeleted,
  ranking,
  winLossRecord,
  hasClinchedPlayoffs,
  esportSlug,
  phaseId,
  enableScoutingToTeamsPage,
  seasonId,
}) => {
  const theme = useTheme()

  const { NexusIcon } = useGeneralEsportAdapter(esportSlug)
  const mobile = useBreakpointSm()
  const [showTooltipOnTitle, setShowTooltipOnTitle] = useState<boolean>(false)
  const [titleTooltipOpen, setTitleTooltipOpen] = useState<boolean>(false)
  const [recordTooltipOpen, setRecordTooltipOpen] = useState<boolean>(false)
  const [rankTooltipOpen, setRankTooltipOpen] = useState<boolean>(false)
  const { getRenderControllerState } = useRosterCardControllerState()
  const {
    rosterCard: { standingsHref },
  } = getRenderControllerState()

  const classes = useStyles({
    showTooltipOnTitle,
    isTeamDeleted,
  })

  const titleElementRef = useRef<HTMLDivElement>(null)
  const el = titleElementRef as any

  useEffect(() => {
    if (
      el?.current?.scrollWidth &&
      el?.current?.offsetWidth &&
      el?.current?.scrollWidth > el?.current?.offsetWidth
    ) {
      setShowTooltipOnTitle(true)
    } else {
      setShowTooltipOnTitle(false)
    }
  }, [el])

  const isRankingUndefined = isNotValidNumber(ranking)
  const displayedRanking = isRankingUndefined ? '-' : (ranking as number) + 1

  const standingsPath = standingsHref
    ? generatePath(standingsHref, {
        eventId: seasonId || '',
      })
    : `${Path.Standings}/${phaseId}`

  return (
    <Box className={classes.titleContainer}>
      <Box
        className={classes.teamNameContainer}
        id="match_lobby_roster_team_header"
        onClick={(): void => {
          if (mobile && showTooltipOnTitle) {
            setTitleTooltipOpen(true)
            setTimeout(() => {
              setTitleTooltipOpen(false)
            }, 3000)
          }
        }}
      >
        <NxTooltip
          arrow
          disableHoverListener={!showTooltipOnTitle}
          placement="top"
          title={teamName ?? ''}
          {...(mobile ? { open: titleTooltipOpen } : {})}
        >
          <Box className={classes.teamNameTooltipBox}>
            <NxTypography
              className={classes.teamName}
              data-testid="match_lobby_roster_team_name"
              id="match_lobby_roster_team_name"
              innerRef={titleElementRef}
              onClick={(): void => {
                if (!isTeamDeleted && enableScoutingToTeamsPage) {
                  window.open(`${Path.Team}/${teamId}`, '_blank', 'noreferrer')
                }
              }}
              variant="h4"
            >
              {teamName ?? ''}
            </NxTypography>
          </Box>
        </NxTooltip>
        {isTeamDeleted && (
          <NxTooltip arrow placement="top" title="This team was deleted">
            <Box className={classes.deletedTooltip}>
              <InfoFilled />
            </Box>
          </NxTooltip>
        )}
      </Box>
      <Box
        className={classes.statsContainer}
        data-testid="match_lobby_roster_rank_and_standings"
        id="match_lobby_roster_rank_and_standings"
        onClick={(): void => {
          if (phaseId) {
            window.open(standingsPath, '_blank', 'noreferrer')
          }
        }}
      >
        {hasClinchedPlayoffs && (
          <Box className={classes.iconContainer}>
            <NxTooltip
              arrow
              leaveDelay={3000}
              placement="top"
              PopperProps={{ style: { pointerEvents: 'auto' } }}
              title={
                <>
                  This team qualified for the playoffs.{' '}
                  <NxTextLink
                    label="Learn More"
                    onClick={(e: React.SyntheticEvent): void => {
                      e.stopPropagation()
                      showIntercomArticle(IntercomArticleMappings.playoffInfo)
                    }}
                  />
                </>
              }
              {...(mobile ? { open: recordTooltipOpen } : {})}
            >
              <Box
                className={classes.icon}
                onClick={(): void => {
                  if (mobile) {
                    setRecordTooltipOpen(true)
                    setTimeout(() => {
                      setRecordTooltipOpen(false)
                    }, 3000)
                  }
                }}
              >
                <Ingite />
              </Box>
            </NxTooltip>
          </Box>
        )}

        <Box className={classes.iconContainer}>
          <NxTooltip
            arrow
            placement="top"
            title="Season Record"
            {...(mobile ? { open: recordTooltipOpen } : {})}
          >
            <Box
              className={classes.icon}
              onClick={(): void => {
                if (mobile) {
                  setRecordTooltipOpen(true)
                  setTimeout(() => {
                    setRecordTooltipOpen(false)
                  }, 3000)
                }
              }}
            >
              <NexusIcon mode={theme.palette.type} />
            </Box>
          </NxTooltip>
          <NxTypography
            className={classes.stat}
            data-cy="seasonRecord"
            variant="subtitle2"
          >
            {winLossRecord?.matchesWon ?? 0}-{winLossRecord?.matchesLost ?? 0}
          </NxTypography>
        </Box>

        <Box className={classes.iconContainer}>
          <NxTooltip
            arrow
            placement="top"
            title="League Rank"
            {...(mobile ? { open: rankTooltipOpen } : {})}
          >
            <Box
              className={classes.icon}
              onClick={(): void => {
                if (mobile) {
                  setRankTooltipOpen(true)
                  setTimeout(() => {
                    setRankTooltipOpen(false)
                  }, 3000)
                }
              }}
            >
              <StandingOutlined />
            </Box>
          </NxTooltip>
          <NxTypography
            className={classes.stat}
            data-cy="leagueRank"
            variant="subtitle2"
          >
            {displayedRanking}
          </NxTypography>
        </Box>
      </Box>
    </Box>
  )
}
