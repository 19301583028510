import React, { SyntheticEvent, useState } from 'react'
import { Box } from '@material-ui/core'

import {
  NxEmptyState,
  NxSelectableSingle,
  NxTab,
  NxTabs,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxIconProps } from '@plvs/respawn/features/esport/creator/types'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { GameTopPerformers } from '@plvs/rally/containers/match'

import { useMatchStatsContext } from '@plvs/respawn/features/match-lobby/MatchStatsContainer'
import {
  GameStatus,
  useGetMatchGamesForStatsQuery,
} from '@plvs/graphql/generated'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { GameStats } from './containers/GameStats'
import { OverviewStats } from './containers/OverviewStats'
import { PlayerMatchStats } from './containers/PlayerMatchStats'
import { PlayerGameStats } from './containers/PlayerGameStats'

interface MatchGame {
  id: string
  status: GameStatus
  seriesId: string | null
  gameResults:
    | {
        id: string
        teamId: string | null
        sidePlayed: number | null
        placing: number | null
        score: number | null
      }[]
    | null
}

interface MatchStatsProps {
  matchId: string
  team1Id: string
  team1Name: string
  team2Id: string
  team2Name: string
  esportLogo: React.FC<NxIconProps> | null
}

enum StatsTabs {
  Team,
  Player,
}

export function onMatchStatsSelect(
  matchGames: MatchGame[],
  newValue: number,
  setSelectedGameId: React.Dispatch<React.SetStateAction<string>>
): void {
  const gameId = newValue === 0 ? '' : matchGames[newValue - 1]?.id ?? ''
  setSelectedGameId(gameId)
}

export const MatchStats: React.FC<MatchStatsProps> = ({
  matchId,
  team1Id,
  team1Name,
  team2Id,
  team2Name,
  esportLogo,
}) => {
  const [tabValue, setTabValue] = useState<StatsTabs>(StatsTabs.Team)
  const {
    hasStats,
    statsData,
    hasStatsPerGame,
    isLoading,
  } = useMatchStatsContext()

  const [selectedGameId, setSelectedGameId] = useState('')

  const EsportLogoComponent = esportLogo
  const tabs = [
    <Box key={0} alignItems="center" display="flex" textAlign="center">
      {EsportLogoComponent ? <EsportLogoComponent /> : <></>}
      <NxTypography variant="button">Overview</NxTypography>
    </Box>,
  ]
  statsData?.match?.games?.forEach((game, i) => {
    if (hasStatsPerGame[game.id]) {
      tabs.push(
        <Box
          key={game.id}
          alignItems="center"
          display="flex"
          textAlign="center"
        >
          {EsportLogoComponent ? <EsportLogoComponent /> : <></>}
          <NxTypography variant="button">Game {i + 1}</NxTypography>
        </Box>
      )
    }
  })

  const { data, loading } = useGetMatchGamesForStatsQuery({
    variables: {
      matchId,
    },
    skip: !matchId,
  })

  // There is an issue with closure when using an arrow function; matchGames, even when passed in, is undefined.
  function onSelect(newValue: number, matchGames: MatchGame[]): void {
    onMatchStatsSelect(matchGames, newValue, setSelectedGameId)
  }

  const handleTabChange = (_: any, newValue: StatsTabs): void => {
    setTabValue(newValue)
  }

  const teamStatsTab = selectedGameId ? (
    <GameStats gameId={selectedGameId} team1Id={team1Id} team2Id={team2Id} />
  ) : (
    <OverviewStats matchId={matchId} team1Id={team1Id} team2Id={team2Id} />
  )

  const playerStatsTab = selectedGameId ? (
    <PlayerGameStats gameId={selectedGameId} team1Id={team1Id} />
  ) : (
    <PlayerMatchStats
      matchId={matchId}
      team1Id={team1Id}
      team1Name={team1Name}
      team2Id={team2Id}
      team2Name={team2Name}
    />
  )

  const topPerformers = selectedGameId ? (
    <MatchCard py={3} title="Top Performers">
      <GameTopPerformers gameId={selectedGameId} />
    </MatchCard>
  ) : null

  return (
    <Box data-testid="MatchStats_Wrapper">
      <Box pb={3} pt={1}>
        <NxSelectableSingle
          defaultValue={0}
          height="54px"
          hideCheckmark
          justifyContent="flex-start"
          onChange={(_: SyntheticEvent, newValue: number): void => {
            onSelect(newValue, data?.match?.games ?? [])
          }}
          padding={0}
          width="119px"
        >
          {tabs}
        </NxSelectableSingle>
      </Box>

      <WaitTillLoaded
        loading={isLoading || loading}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {hasStats ? (
          <>
            <MatchCard px={3} py={4}>
              <Box mb={3}>
                <NxTabs
                  onChange={handleTabChange}
                  size="small"
                  value={tabValue}
                >
                  <NxTab label="Team Stats" value={StatsTabs.Team} />
                  <NxTab label="Player Stats" value={StatsTabs.Player} />
                </NxTabs>
              </Box>

              {tabValue === StatsTabs.Team && teamStatsTab}

              {tabValue === StatsTabs.Player && playerStatsTab}
            </MatchCard>

            {topPerformers}
          </>
        ) : (
          <NxEmptyState
            isStandalone
            spot={
              <NxSpot
                domain="standing"
                height={200}
                size="large"
                variant="secondary"
                width={200}
              />
            }
            subtitle="Check back later."
            title="No stats found"
          />
        )}
      </WaitTillLoaded>
    </Box>
  )
}
