import React from 'react'
import { NxSelect, NxSelectOption } from '@playvs-inc/nexus-components'
import { Gender } from '@plvs/graphql/generated'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    color: theme.palette.ColorTextAlt2,
  },
  inputWrapper: {
    '& label': {
      color: theme.palette.ColorTextAlt2,
    },
  },
}))

interface Props {
  fullWidth?: boolean
  required?: boolean
  value?: Gender
  onChange: React.Dispatch<React.SetStateAction<Gender | undefined>>
}

export const GenderSelect: React.FC<Props> = ({
  fullWidth = true,
  required,
  value,
  onChange,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.inputWrapper} data-testid="gender-select">
      <NxSelect
        autoComplete="off"
        className={classes.input}
        fullWidth={fullWidth}
        label="Gender"
        name="gender"
        onChange={(e): void => onChange(e.target.value as Gender)}
        required={required}
        value={value}
        variant="outlined"
      >
        <NxSelectOption value={Gender.Male}>{Gender.Male}</NxSelectOption>
        <NxSelectOption value={Gender.Female}>{Gender.Female}</NxSelectOption>
        <NxSelectOption value={Gender.DifferentIdentity}>
          Different Identity
        </NxSelectOption>
        <NxSelectOption value={Gender.PreferNotToAnswer}>
          Prefer Not To Answer
        </NxSelectOption>
      </NxSelect>
    </div>
  )
}
