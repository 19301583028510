import React, { useRef, useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'

import { NxTooltip } from '@playvs-inc/nexus-components'

const useStyles = makeStyles(() => ({
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

export const OverflowTooltip: React.FC = ({ children }): React.ReactElement => {
  const styles = useStyles()
  const [isOverflowed, setIsOverflow] = useState(false)
  const textElementRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsOverflow(
      (textElementRef?.current?.scrollWidth ?? 0) >
        (textElementRef?.current?.clientWidth ?? 0)
    )
  }, [])

  return (
    <NxTooltip disableHoverListener={!isOverflowed} title={children as string}>
      <div ref={textElementRef} className={styles.truncate}>
        {children}
      </div>
    </NxTooltip>
  )
}
