import { Param, Path } from '@plvs/const'
import { Gender, Race } from '@plvs/graphql/generated'
import { EthnicityValue } from '@plvs/respawn/features/account/ethnicity-autocomplete/types'

export const getUsernameNextPath = ({
  isParentSignup,
  showNASEFUx,
  shouldGoToDashboard,
  search,
  shouldGoToVerifyEmailStep,
  isStillOnboarding = false,
}: {
  isParentSignup: boolean
  showNASEFUx: string
  shouldGoToDashboard?: boolean
  search?: string
  shouldGoToVerifyEmailStep?: boolean
  isStillOnboarding?: boolean
}): string => {
  const query = new URLSearchParams(search)
  const next = query.get(Param.Next)
  const teamId = query.get(Param.teamId)
  const eventId = query.get(Param.eventId)
  const stadiumInviteCode = query.get(Param.stadiumInviteCode)

  if (next && !isStillOnboarding) {
    query.delete(Param.Next)
    const qs = query.toString()

    return `${next}${qs ? `?${qs}` : ''}`
  }

  if (shouldGoToVerifyEmailStep) {
    return `${Path.VerifyEmail}${search ?? ''}`
  }

  if (eventId && teamId && stadiumInviteCode) {
    return `${Path.Event}/${eventId}/team?inviteCode=${stadiumInviteCode}&teamId=${teamId}`
  }

  if (shouldGoToDashboard) {
    return Path.Dashboard
  }

  if (isParentSignup) {
    return `/parent${Path.SpawnPoint}/parent-verification`
  }

  return `${Path.SpawnPoint}/tell-us-more${showNASEFUx}`
}

export const getRaces = (race: EthnicityValue): Race => race.value

export const isUsernameStepDisabled = ({
  isUserDemographicsEnabled,
  isLoading,
  gender,
  ethnicities,
  state,
  country,
  nameValues,
  shouldShowFullDemographicForm,
}: {
  isUserDemographicsEnabled: boolean
  isLoading: boolean
  gender: Gender | undefined
  ethnicities: EthnicityValue[]
  state: string | undefined
  country: string | undefined
  nameValues: { firstName: string; lastName: string }
  shouldShowFullDemographicForm: boolean
}): boolean => {
  return isUserDemographicsEnabled
    ? isLoading ||
        !gender ||
        !ethnicities.length ||
        (shouldShowFullDemographicForm && (!state || !country)) ||
        !nameValues.firstName ||
        !nameValues.lastName
    : isLoading
}
