import React, { useCallback, useContext } from 'react'
import { noop } from 'ramda-adjunct'

import {
  determineRosterCardRender,
  determineRosterRowItemRender,
} from './utils/rosterCardRenderController.helpers'
import {
  UseRosterCardRenderControllerContextReturn,
  RosterCardRenderControllerState,
  DeleteTeamType,
} from './types/rosterCardController.types'

type RosterCardRenderControllerContext =
  UseRosterCardRenderControllerContextReturn & {
    getRenderControllerState: () => RosterCardRenderControllerState
    setRenderControllerStateFn: (
      updatedStateValues: Partial<RosterCardRenderControllerState>
    ) => void
  }

export const rosterCardRenderControllerContext =
  React.createContext<RosterCardRenderControllerContext>({
    rosterCard: {
      getRosterCardComponentsToRender: determineRosterCardRender,
      getRosterRowItemRender: determineRosterRowItemRender,
    },
    getRenderControllerState: () => ({
      rosterCard: {
        shouldRenderTeamAvatar: false,
        shouldShowInvitePlayer: false,
        shouldShowDeleteTeamDisclaimer: false,
        deleteTeamSubtitle: 'This team will be removed from my teams.',
        isContactsTabEnabled: false,
        deleteTeamMutation: DeleteTeamType.deleteScholastic,
        enableScoutingToTeamsPage: false,
        shouldRenderCaptainIcon: false,
        shouldShowRivalsTab: true,
        shouldShowRosterTitle: true,
        shouldShowEvent: false,
        standingsHref: null,
      },
    }),
    setRenderControllerStateFn: noop,
  })

export const useRosterCardRenderControllerContext =
  (): RosterCardRenderControllerContext =>
    useContext(rosterCardRenderControllerContext)

export const RosterCardRenderControllerProvider: React.FC = ({ children }) => {
  const [renderControllerState, setRenderControllerState] =
    React.useState<RosterCardRenderControllerState>({
      rosterCard: {
        shouldRenderTeamAvatar: false,
        shouldShowInvitePlayer: false,
        shouldShowDeleteTeamDisclaimer: false,
        deleteTeamSubtitle: 'This team will be removed from my teams.',
        isContactsTabEnabled: false,
        deleteTeamMutation: DeleteTeamType.deleteScholastic,
        enableScoutingToTeamsPage: false,
        shouldRenderCaptainIcon: false,
        shouldShowRivalsTab: true,
        shouldShowRosterTitle: true,
        shouldShowEvent: false,
        standingsHref: null,
      },
    })

  const getRenderControllerState =
    useCallback((): RosterCardRenderControllerState => {
      return renderControllerState
    }, [renderControllerState])

  const setRenderControllerStateFn = useCallback(
    (newState: Partial<RosterCardRenderControllerState>): void => {
      setRenderControllerState((prevState) => ({
        ...prevState,
        ...newState,
      }))
    },
    []
  )

  const values = useRosterCardRenderControllerContext()
  return (
    <rosterCardRenderControllerContext.Provider
      value={{
        ...values,
        getRenderControllerState,
        setRenderControllerStateFn,
      }}
    >
      {children}
    </rosterCardRenderControllerContext.Provider>
  )
}

export const useRosterCardControllerState = (): {
  getRenderControllerState: () => RosterCardRenderControllerState
  setRenderControllerStateFn: (
    updatedStateValues: Partial<RosterCardRenderControllerState>
  ) => void
} => {
  const { getRenderControllerState, setRenderControllerStateFn } =
    React.useContext(rosterCardRenderControllerContext)

  return { getRenderControllerState, setRenderControllerStateFn }
}
