import React from 'react'
import { Box, Grid, makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'

import signOn from '@plvs/rally/assets/backgrounds/sign-on.png'
import { LoginSideBanner } from './LoginSideBanner'
import { AuthLogo } from './AuthLogo'

interface LoginProps {
  children: React.ReactNode
  title: string
  // Defaults to signOn
  bannerImageSrc?: string
  // Defaults to AuthLogo
  Logo?: React.ReactNode
  // Defaults to 'corner'
  logoPosition?: 'corner' | 'center'
  className?: string
}

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: 'center',
  },
  content: {
    height: 'calc(100vh - 240px)',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(13),
    [theme.breakpoints.down('sm')]: {
      height: 584,
      paddingTop: theme.spacing(9),
    },
  },
}))

export const Login = ({
  children,
  title,
  bannerImageSrc = signOn,
  Logo = <AuthLogo />,
  logoPosition = 'corner',
  className,
}: LoginProps): React.ReactElement => {
  const classes = useStyles()

  return (
    <Box className={className} display="flex" flex={1} flexDirection="column">
      <Grid container>
        <Grid item md={6}>
          <LoginSideBanner
            height="100%"
            position={['unset', 'fixed']}
            src={bannerImageSrc}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          {logoPosition === 'corner' && Logo}

          <Box className={classes.content}>
            {logoPosition === 'center' && (
              <Box alignSelf="center" mb={3.5}>
                {Logo}
              </Box>
            )}

            <NxTypography className={classes.title} variant="display2">
              {title}
            </NxTypography>

            <Box>{children}</Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
