import { makeStyles } from '@material-ui/core'
import { NxSelect, NxSelectOption } from '@playvs-inc/nexus-components'
import { stateAbbrs, stateNames } from '@plvs/const'
import React from 'react'

interface StateSelectorProps {
  value: string
  onChange: React.Dispatch<React.SetStateAction<string | undefined>>
  required?: boolean
  fullWidth?: boolean
  label?: string
}

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    color: theme.palette.ColorTextAlt2,
  },
  inputWrapper: {
    '& label': {
      color: theme.palette.ColorTextAlt2,
    },
  },
}))

export const StateSelector: React.FC<StateSelectorProps> = ({
  label,
  fullWidth = true,
  required,
  value,
  onChange,
}) => {
  const classes = useStyles()
  return (
    <div className={classes.inputWrapper} data-testid="state-select">
      <NxSelect
        className={classes.input}
        fullWidth={fullWidth}
        label={label ?? 'Select State/Province'}
        onChange={(e): void => onChange(e.target.value as string)}
        required={required}
        value={value}
        variant="outlined"
      >
        {Object.entries(stateNames)
          .filter(([abbr]) => {
            return stateAbbrs.some((state) => state === abbr)
          })
          .map((state) => (
            <NxSelectOption key={state[0]} value={state[0]}>
              {state[1]}
            </NxSelectOption>
          ))}
      </NxSelect>
    </div>
  )
}
