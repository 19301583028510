import { useCallback, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

// Hook to handle stacking of routes when using menu item navigation in left nav
const useBackButton = (): void => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleEvent = useCallback((): void => {
    const backPath = window.location.pathname
    // If there is a backpath is the same as the path we are on, navigate back
    if (backPath === pathname) {
      navigate(-1)
    }
  }, [pathname, navigate])

  useEffect(() => {
    window.addEventListener('popstate', handleEvent)
    return () => window.removeEventListener('popstate', handleEvent)
  }, [handleEvent])
}

export default useBackButton
