import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import { EsportRating } from '@plvs/graphql'
import { appendClasses } from '@plvs/utils'
import dayjs from '@plvs/respawn/init/dayjs'
import {
  NxUserAvatar,
  NxAvatarSize,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'

import { UnknownAvatar } from '@playvs-inc/nexus-icons'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { SchedulePageQueueMatch } from '@plvs/rally/features/match/schedule/schedule'
import { useNavigate } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    scrollSnapAlign: 'start',
    minWidth: 'min-content',
  },
  matchCard: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.mixins.cornerRadius['border-radius-10'],
    flexBasis: '33%',
    width: '16em',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    fontFamily: '"TT Commons", Whitney',
    fontSize: '1.25rem',
    lineHeight: '133%',
    boxShadow: theme.shadows[1],
  },
  vsBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  vsAvatarRow: {
    position: 'relative',
    alignContent: 'center',
    display: 'flex',
    flexBasis: 10,
    justifyContent: 'center',
  },

  matchDetailsContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  gameNameLoading: {
    marginTop: '-1em',
    marginBottom: '-1em',
  },
  vsText: {
    alignContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexBasis: 5,
    justifyContent: 'center',
    color: theme.palette.ColorTextAlt,
    fontSize: '1.3em',
    fontWeight: 700,
  },
  esportIcon: {
    marginRight: theme.spacing(1),
  },
}))

export const DashboardQueueMatchCard: React.FC<{
  match?: SchedulePageQueueMatch
}> = ({ match }) => {
  const { NexusIcon } = useGeneralEsportAdapter(match?.esport.slug)
  const classes = useStyles()
  const navigate = useNavigate()

  // Computed Props
  const dateFormat = dayjs(match?.scheduledStartsAt).isAfter(
    dayjs().add(6, 'd')
  )
    ? 'MMM Do @ h:mm A'
    : 'dddd @ h:mm A'

  const formatTime = match?.scheduledStartsAt
    ? dayjs(match?.scheduledStartsAt).format(dateFormat)
    : ''

  const teamLogo = match?.schoolLogoUrl ?? ''

  const teamName = match?.teamName ?? ''

  return (
    <Box
      className={appendClasses(
        classes.container,
        'MatchCard' /* This is used as a locator for '<UpcomingMatches />' component. */
      )}
      mr={3}
    >
      <Box className={classes.matchCard} data-cy="matchCard">
        <Box display="flex" maxHeight={20}>
          <NxTypography variant="h4">{formatTime}</NxTypography>
        </Box>
        <Box pb={2} pt={4}>
          <Box className={classes.vsBox}>
            <Box className={classes.vsAvatarRow}>
              <NxUserAvatar
                avatarUrl={
                  match?.esport?.rating === EsportRating.Restricted
                    ? null
                    : teamLogo
                }
                hashId=""
                size={NxAvatarSize.LG}
              />
            </Box>
            <Box className={classes.vsText}>vs</Box>
            <Box className={classes.vsAvatarRow}>
              <UnknownAvatar height="80px" width="80px" />
            </Box>
          </Box>
        </Box>

        <Box mb={3.75} mt={2.25}>
          <Box>
            <NxTypography color="textPrimary" variant="body1">
              {`${teamName} vs TBD`}
            </NxTypography>
          </Box>
          {/* // Match Details */}
          <Box className={classes.matchDetailsContainer} mt={1}>
            <NexusIcon className={classes.esportIcon} height={20} width={20} />
            <NxTypography colorToken="ColorTextAlt" variant="body3">
              {match?.esport.name ?? (
                <Skeleton
                  className={classes.gameNameLoading}
                  data-testid="skeleton-loader"
                  variant="text"
                  width="15em"
                />
              )}
              &nbsp;
            </NxTypography>
          </Box>
        </Box>
        <Box mt="auto">
          <NxButton
            fullWidth
            label="Open Match"
            onClick={(): void => {
              const url = `/app/queue-lobby/${match?.slotId}/${match?.teamId}`
              navigate(url)
            }}
            variant="secondary"
          />
        </Box>
      </Box>
    </Box>
  )
}
