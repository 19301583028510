import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import React from 'react'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.ColorTextLink,
    '&:hover': {
      color: theme.palette.ColorTextLinkHover,
    },
  },
}))

interface Props {
  'data-testid'?: string
  label: string
  to: string
}

export const StyledRouterLink: React.FC<Props> = ({
  label,
  to,
  'data-testid': dataTestId,
}) => {
  const classes = useStyles()

  return (
    <Link className={classes.link} data-testid={dataTestId} to={to}>
      <NxTypography color="inherit" variant="body1">
        {label}
      </NxTypography>
    </Link>
  )
}
