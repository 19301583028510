import React, { useMemo } from 'react'
import { List, makeStyles, useTheme } from '@material-ui/core'
import { format, toDate } from 'date-fns-tz'
import dayjs from 'dayjs'
import { uniqBy } from 'ramda'
import { useGetAdapter } from '@plvs/respawn/features/esport/adapters'

import {
  EsportRating,
  MatchStatus,
  School,
  useGetMatchesForSeasonByTeamIdsQuery,
  useGetTeamMatchHistoryQuery,
  useGetTeamOverviewQuery,
} from '@plvs/graphql'
import { ExpandableCard } from '@plvs/rally/components/card'
import {
  Box,
  useBreakpointXs,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTypography } from '@playvs-inc/nexus-components'
import MatchHistoryItem from '@plvs/respawn/features/teams/MatchHistoryItem'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'

interface MatchHistoryMinimalPhase {
  id: string
  // These 2 fields are ISODateTime strings
  startsAt: string
  endsAt: string
}

export interface MatchHistoryProps {
  teamId: string
  phase?: MatchHistoryMinimalPhase
}

interface OpponentTeam {
  id: string
  name: string | null
  school?: Pick<School, 'id' | 'name' | 'logoUrl'> | null
}

interface OpponentDetails {
  avatarUrl: string
  schoolName: string
  name: string
  teamId: string
}

const useStyles = makeStyles((theme) => ({
  overline: {
    color: theme.palette.ColorTextAlt2,
  },
}))

export const generateOpponentDetails = (
  opponentTeam: OpponentTeam | undefined | null,
  isSingleTeamForfeit: boolean
): OpponentDetails => {
  return {
    avatarUrl: opponentTeam?.school?.logoUrl ?? '',
    schoolName: opponentTeam?.school?.name ?? '',
    name: isSingleTeamForfeit ? 'Forfeit' : opponentTeam?.name ?? '',
    teamId: opponentTeam?.id ?? '',
  }
}

export const MatchHistory: React.FC<MatchHistoryProps> = ({
  teamId,
  phase,
}) => {
  const theme = useTheme()
  const mobile = useBreakpointXs()
  const { metaseason } = useSchoolLeagueInfoContext()
  const styles = useStyles()
  const metaseasonId = metaseason?.id ?? ''
  const skip = !teamId || !metaseasonId
  const {
    data: teamOverviewData,
    loading: teamOverviewLoading,
  } = useGetTeamOverviewQuery({ variables: { teamId }, skip: !teamId })

  const myTeam = teamOverviewData?.team
  const { hasSeries } = useGetAdapter(myTeam?.esport.slug ?? null)

  const { data } = useGetMatchesForSeasonByTeamIdsQuery({
    variables: {
      teamIds: [teamId],
      metaseasonId,
    },
    skip,
  })

  const { startsAt, endsAt } = phase ?? {}

  // Use date range provided by Phase, otherwise use current 3 month window.
  const afterDateTimeString = useMemo(
    () =>
      (startsAt ? dayjs(startsAt) : dayjs().subtract(3, 'month')).toISOString(),
    []
  )
  const beforeDateTimeString = useMemo(
    () => (endsAt ? dayjs(endsAt) : dayjs()).toISOString(),
    []
  )

  const {
    data: pastMatchesForTeam,
    loading: pastMatchesLoading,
  } = useGetTeamMatchHistoryQuery({
    variables: {
      input: {
        teamIds: [teamId],
        scheduledStartsAtRange: {
          before: beforeDateTimeString,
          after: afterDateTimeString,
        },
      },
    },
    skip,
  })

  const matchesData = data?.matchesForSeasonByTeamIds?.length
    ? data?.matchesForSeasonByTeamIds
    : pastMatchesForTeam?.findMatchesByTeamIds
  const matches = uniqBy(({ id }) => id, matchesData ?? [])

  const matchHistoryItems = matches
    .map((match) => {
      const { id, status, isScrimmage } = match

      if (
        isScrimmage ||
        (status !== MatchStatus.Completed &&
          status !== MatchStatus.Forfeited &&
          status !== MatchStatus.Bye)
      ) {
        return false
      }
      const matchResults = match?.matchResults ?? []

      const homeTeam = matchResults.find(
        (matchResult) => matchResult?.team?.id === teamId
      )
      const opponents = matchResults.filter(
        (matchResult) => matchResult.team?.id !== teamId
      )

      const isSingleTeamForfeit =
        status === MatchStatus.Forfeited && !opponents.length

      const {
        avatarUrl,
        schoolName,
        name,
        teamId: opponentTeamId,
      } = generateOpponentDetails(opponents[0]?.team, isSingleTeamForfeit)

      const date = format(toDate(match?.scheduledStartsAt ?? ''), 'MMM d')

      // If phase is selected, we want to filter out matches not related to this phase.
      if (phase && match.slot?.phase?.id !== phase?.id) {
        return false
      }
      return (
        <MatchHistoryItem
          key={match.id}
          avatarUrl={avatarUrl === '' ? undefined : avatarUrl}
          esportRating={teamOverviewData?.team?.esport.rating}
          lost={(hasSeries ? homeTeam?.seriesLost : homeTeam?.gamesLost) ?? 0}
          matchDate={date}
          matchId={id}
          placing={homeTeam?.placing ?? 0}
          schoolName={schoolName}
          status={status}
          teamId={opponentTeamId}
          teamName={name || 'BYE'}
          won={(hasSeries ? homeTeam?.seriesWon : homeTeam?.gamesWon) ?? 0}
        />
      )
    })
    .filter((item) => item)
    .reverse()

  const loading = pastMatchesLoading || teamOverviewLoading

  const hasExpandedContent = matchHistoryItems.length > 4
  const previewMatches = hasExpandedContent
    ? matchHistoryItems.splice(0, 4)
    : matchHistoryItems
  const expandedContent = hasExpandedContent ? matchHistoryItems : false

  const previewContent = (
    <List>
      <Box
        key="matchHistory"
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        pb={3}
        pt={2}
        style={{
          borderBottom: `1px solid ${theme.palette.BorderLight}`,
        }}
        width="100%"
      >
        <Box
          alignItems="center"
          display="flex"
          justifyContent="space-between"
          pt={1}
          px={5}
          width="100%"
        >
          <Box alignItems="center" display="flex">
            <>
              <NxUserAvatar
                avatarUrl={
                  teamOverviewData?.team?.esport.rating ===
                  EsportRating.Restricted
                    ? null
                    : myTeam?.school?.logoUrl
                }
                hashId={teamId}
                size={NxAvatarSize.XS}
              />
              <Box ml={2}>
                <NxTypography
                  className={styles.overline}
                  data-cy="teamMatchHistory"
                  variant="overline"
                >
                  {`${myTeam?.name ?? 'Team'} Match History`}
                </NxTypography>
              </Box>
            </>
          </Box>
          {matchHistoryItems.length > 0 ? (
            <NxTypography className={styles.overline} variant="overline">
              Score
            </NxTypography>
          ) : (
            <></>
          )}
        </Box>
      </Box>
      <WaitTillLoaded
        loading={loading}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {matchHistoryItems.length > 0 ? (
          previewMatches
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            mb={4}
            mt={4}
          >
            <NxSpot
              domain="megaphone"
              height={64}
              size="small"
              variant="primary"
              width={64}
            />
            <Box pb={1} pt={2}>
              <NxTypography variant="h3">No Matches Yet</NxTypography>
            </Box>
            <NxTypography variant="body1">
              This team hasn&apos;t played any PlayVS matches.
            </NxTypography>
          </Box>
        )}
      </WaitTillLoaded>
    </List>
  )

  return (
    <Box mt={-1} style={{ overflow: 'auto', width: '100%' }}>
      <ExpandableCard
        buttonPosition={mobile ? 'flex-start' : 'flex-end'}
        expandedContent={expandedContent}
        expandText="Show More"
        previewContent={previewContent}
      />
    </Box>
  )
}
