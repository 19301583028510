import { NxTextLink, NxTooltip, Pill } from '@playvs-inc/nexus-components'
import { ExternalLinks } from '@plvs/const'
import React from 'react'

type BetaPillProps = {
  title?: string
}

export const BetaPill: React.FC<BetaPillProps> = ({ title = '' }) => {
  return (
    <NxTooltip
      arrow
      leaveDelay={3000}
      placement="top"
      PopperProps={{ style: { pointerEvents: 'auto' } }}
      title={
        <>
          {title && `${title} `}
          <NxTextLink
            label="Learn More"
            labelVariant="body3"
            onClick={(e: React.SyntheticEvent): void => {
              e.stopPropagation()
              window.open(ExternalLinks.betaFAQ, '_blank', 'noreferrer')
            }}
          />
        </>
      }
    >
      {/* Needs wrapper for tooltip to pop */}
      <div>
        <Pill label="Beta" size="small" variant="misc" />
      </div>
    </NxTooltip>
  )
}
