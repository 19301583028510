import {
  findMatchTourClicked,
  productTourClicked,
} from '@plvs/respawn/features/analytics'
import dayjs from '@plvs/respawn/init/dayjs'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useNavigate } from 'react-router-dom'
import { LinkObj } from '@plvs/respawn/renderController/appDrawer/types/appDrawerRenderController.types'
import { useProductTours } from '../../shepherd/utils/useProductTours'

const ONBOARDING_TOUR_STEP = {
  competitions: 2,
  mySchool: 3,
  manageTeams: 5,
  schedule: 8,
  standings: 11,
}

export const useLink = (link: LinkObj): LinkObj => {
  const navigate = useNavigate()
  const { userId } = useUserIdentityFn()

  const { onboardingTour, initiatedTimestamp, findMatchTour } =
    useProductTours()

  const handleClick = (): void => {
    if (findMatchTour?.isActive()) {
      findMatchTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: 2,
        clickTarget: 'Next Button',
      })
      findMatchTour?.next()
      if (link.to) {
        navigate(link.to)
        return
      }
      return
    }

    if (onboardingTour?.isActive()) {
      productTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: link.className ? ONBOARDING_TOUR_STEP[link.className] : 0,
        clickTarget: `Navigation ${link.label} Button`,
      })
      onboardingTour?.next()
      if (link.to) {
        navigate(link.to)
        return
      }
      return
    }
    if (link.to) {
      navigate(link.to)
    }
  }

  return {
    ...link,
    handleOnClick: handleClick,
  }
}
