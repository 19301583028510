import { SelectInputProps } from '@material-ui/core/Select/SelectInput'
import { uniqBy } from 'ramda'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import React from 'react'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout'
import {
  EnrolledPlayerOnTeam,
  EnrollmentLeague,
} from '@plvs/respawn/containers/enrollment/types'
import { Tooltip } from '../../../components/tooltip'
import { formatPlayDate } from './TeamRow.helpers'

export const INVALID_LEAGUE_IDS = new Set([
  'default',
  'unenroll',
  'notenrolled',
])

export type FlattenedLeague = {
  id: string
  displayName?: string | null
  playDate?: string | null
  isPastRegDate: boolean
  isPastDeRegDate: boolean
}

export type EnrolledFlattenedLeague = {
  playDate: string | undefined
} & EnrollmentLeague

type SelectStyles = {
  tooltip: string
  option: string
  displayOnlyContainer: string
  element: string
  select: string
  selectLabel: string
  formHelperText: string
}

export type GetSelectArgs = {
  isDisabled: boolean
  isAllRegDatePassed: boolean
  isPastDeregistrationDate: boolean
  isAdmin: boolean
  enrolledLeague?: EnrolledFlattenedLeague
  flattenedLeagues: FlattenedLeague[]
  isEnrolledLeaguePastDeRegDate: boolean
  showLeagueNameOnly: boolean
  disabledMessage?: string
  setShowLeagueNameOnly: (value: boolean) => void
  selectedLeagueId: string
  onChange: SelectInputProps['onChange']
  disabledLeagues: Record<string, string>
  styles: SelectStyles
}

export const getSelect = (args: GetSelectArgs): React.ReactElement => {
  const {
    isDisabled,
    isAllRegDatePassed,
    isPastDeregistrationDate,
    isAdmin,
    enrolledLeague,
    flattenedLeagues,
    isEnrolledLeaguePastDeRegDate,
    showLeagueNameOnly,
    disabledMessage,
    setShowLeagueNameOnly,
    selectedLeagueId,
    onChange,
    disabledLeagues,
    styles,
  } = args
  const isEnrolled = !!enrolledLeague
  const canOnlyUnenroll = isEnrolled && flattenedLeagues.length < 2

  if (isAllRegDatePassed && !isEnrolled && !isAdmin) {
    return (
      <FormControl
        data-testid="TeamRowSelect_Form"
        disabled={isAllRegDatePassed || !isAdmin}
        fullWidth
      >
        <InputLabel className={styles.selectLabel}>League</InputLabel>
        <Tooltip
          className={styles.element}
          disableFocusListener={!isAllRegDatePassed}
          disableHoverListener={!isAllRegDatePassed}
          disableTouchListener={!isAllRegDatePassed}
          placement="bottom-end"
          title="Enrollment Closed"
        >
          <Select
            className={styles.select}
            data-testid="TeamRow_Select_Disabled"
            disableUnderline
            label="League"
            value="notenrolled"
          >
            <MenuItem
              className={styles.option}
              disabled={isEnrolledLeaguePastDeRegDate || !isAdmin}
              value="notenrolled"
            >
              <NxTypography data-testid="TeamRowSelect_SingleOption">
                Not Enrolled
              </NxTypography>
            </MenuItem>
          </Select>
        </Tooltip>
      </FormControl>
    )
  }

  if (enrolledLeague && showLeagueNameOnly) {
    return (
      <Box className={styles.displayOnlyContainer}>
        <NxTypography data-testid="TeamRowLeagueName_EnrolledLeagueName">
          {enrolledLeague.displayName || enrolledLeague.name}
        </NxTypography>
        <Tooltip
          disableFocusListener={
            !isAllRegDatePassed && !isPastDeregistrationDate
          }
          disableHoverListener={
            !isAllRegDatePassed && !isPastDeregistrationDate
          }
          disableTouchListener={
            !isAllRegDatePassed && !isPastDeregistrationDate
          }
          placement="bottom-end"
          title="Enrollment Closed"
        >
          <NxButton
            data-testid="TeamRowSelect_ChangeButton"
            disabled={
              (isAllRegDatePassed && !isAdmin) ||
              (isPastDeregistrationDate && !isAdmin)
            }
            label={canOnlyUnenroll ? 'Unenroll' : 'Change or Unenroll'}
            onClick={(): void => setShowLeagueNameOnly(false)}
            variant="text"
          />
        </Tooltip>
      </Box>
    )
  }

  if (!isEnrolledLeaguePastDeRegDate && isDisabled && isEnrolled && !isAdmin) {
    return (
      <FormControl data-testid="DisabledTeamRowSelect_Form" fullWidth>
        <InputLabel className={styles.selectLabel}>League</InputLabel>
        <div className={styles.element}>
          <Select
            className={styles.select}
            disableUnderline
            label="League"
            onChange={onChange}
            value={selectedLeagueId}
          >
            <MenuItem
              className={styles.option}
              data-testid="DisabledTeamRow_Option"
              disabled
              value={enrolledLeague?.id}
            >
              <Box>
                <NxTypography>
                  {enrolledLeague?.displayName || enrolledLeague?.name}
                </NxTypography>
              </Box>
              <Box data-testid="DisabledReamRowSelect_Line2">
                {enrolledLeague?.playDate && (
                  <NxTypography>
                    Matches Play on {formatPlayDate(enrolledLeague.playDate)}
                  </NxTypography>
                )}
              </Box>
            </MenuItem>
            <MenuItem
              className={styles.option}
              data-testid="DisabledTeamRow_Option"
              value="unenroll"
            >
              <Box>
                <NxTypography>Unenroll Team</NxTypography>
              </Box>
            </MenuItem>
          </Select>
          <FormHelperText className={styles.formHelperText}>
            {disabledMessage}
          </FormHelperText>
        </div>
      </FormControl>
    )
  }

  if (!showLeagueNameOnly || isAdmin) {
    return (
      <FormControl
        disabled={(isAllRegDatePassed && !isAdmin) || (isDisabled && !isAdmin)}
        fullWidth
      >
        <InputLabel className={styles.selectLabel}>League</InputLabel>
        <Tooltip arrow className={styles.element} title={disabledMessage}>
          <Select
            className={styles.select}
            disableUnderline
            label="League"
            onChange={onChange}
            value={selectedLeagueId}
          >
            {!isEnrolled && (
              <MenuItem value="default">
                <Box>
                  <NxTypography>Select One to Enroll</NxTypography>
                </Box>
              </MenuItem>
            )}
            {flattenedLeagues.map((league) => {
              const pastRegDate = !league.isPastRegDate
                ? false
                : league.isPastRegDate && !isAdmin
              const isPastDeRegDate = !league.isPastRegDate
                ? false
                : league.isPastRegDate && !isAdmin
              const disabledPlayersMsg = disabledLeagues[league.id]
              return (
                <MenuItem
                  key={league.id}
                  className={styles.option}
                  data-testid="TeamRowSelect_Option"
                  disabled={
                    pastRegDate || isPastDeRegDate || !!disabledPlayersMsg
                  }
                  value={league.id}
                >
                  <Box>
                    <NxTypography>{league.displayName}</NxTypography>
                  </Box>
                  <Box data-testid="ReamRowSelect_Line2">
                    {!pastRegDate && league.playDate && !disabledPlayersMsg && (
                      <NxTypography>
                        Matches Play on {formatPlayDate(league.playDate)}
                      </NxTypography>
                    )}
                    {isPastDeRegDate && (
                      <NxTypography>Enrollment is Closed</NxTypography>
                    )}
                    {disabledPlayersMsg && (
                      <NxTypography>{disabledPlayersMsg}</NxTypography>
                    )}
                  </Box>
                </MenuItem>
              )
            })}
            {isEnrolled && (
              <MenuItem
                className={styles.option}
                disabled={isEnrolledLeaguePastDeRegDate && !isAdmin}
                value="unenroll"
              >
                <Box>
                  <NxTypography>Unenroll Team</NxTypography>
                </Box>
              </MenuItem>
            )}
          </Select>
        </Tooltip>
      </FormControl>
    )
  }

  if (!isAllRegDatePassed) {
    return (
      <FormControl
        disabled={(isAllRegDatePassed && !isAdmin) || isDisabled}
        fullWidth
      >
        <InputLabel className={styles.selectLabel} style={{ marginTop: 6 }}>
          League
        </InputLabel>
        <Tooltip arrow className={styles.element} title={disabledMessage}>
          <Select
            className={styles.select}
            data-testid="TeamRowSelect_Select"
            disableUnderline
            label="League"
            onChange={onChange}
            value={selectedLeagueId}
          >
            {!isEnrolled && (
              <MenuItem value="default">
                <Box>
                  <NxTypography>Select League to Enroll</NxTypography>
                </Box>
              </MenuItem>
            )}
            {flattenedLeagues.map((league) => {
              const disabledPlayersMsg = disabledLeagues[league.id]
              return (
                <MenuItem
                  key={league.id}
                  className={styles.option}
                  data-testid="TeamRowSelect_Option"
                  disabled={
                    (league.isPastRegDate && !isAdmin) ||
                    (!!disabledPlayersMsg && !isAdmin)
                  }
                  value={league.id}
                >
                  <Box>
                    <NxTypography>{league.displayName}</NxTypography>
                  </Box>
                  {league.playDate && !disabledPlayersMsg && (
                    <Box>
                      <NxTypography>
                        {league.isPastRegDate
                          ? 'Enrollment Closed'
                          : `Matches Play on ${formatPlayDate(
                              league.playDate
                            )}`}
                      </NxTypography>
                    </Box>
                  )}
                  {disabledPlayersMsg && (
                    <Box>
                      <NxTypography>{disabledPlayersMsg}</NxTypography>
                    </Box>
                  )}
                </MenuItem>
              )
            })}
            {isEnrolled && (
              <MenuItem
                className={styles.option}
                disabled={isEnrolledLeaguePastDeRegDate && !isAdmin}
                value="unenroll"
              >
                <Box>
                  <NxTypography>Unenroll Team</NxTypography>
                </Box>
              </MenuItem>
            )}
          </Select>
        </Tooltip>
      </FormControl>
    )
  }
  return <></>
}

export function countTeamsInSeason(
  league: EnrollmentLeague,
  enrolledPlayers: EnrolledPlayerOnTeam[]
): number {
  const teamsInLeague = uniqBy((x) => x.teamId, enrolledPlayers).filter(
    (t) => t.leagueId === league.id
  )
  return teamsInLeague.length
}
