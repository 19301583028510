import React from 'react'
import { hydrate } from 'react-dom'
import * as Sentry from '@sentry/browser'
import { CookiesProvider } from 'react-cookie'
import { BrowserRouter } from 'react-router-dom'
import * as analytics from '@plvs/respawn/features/analytics'
import { SnackbarProvider } from 'notistack'
import Helmet from 'react-helmet'
import { PrivacyScript } from '@plvs/const'
import { env } from '@plvs/env'
import App from './App'
import 'shepherd.js/dist/css/shepherd.css'
import '@plvs/respawn/features/shepherd/shepherd.css'
import { initElasticRum } from './init/elastic/initElasticRum'
import { rallyEnv } from './env'
import { ApolloProviderWrapper } from './ApolloProviderWrapper'
import './global.css'

analytics.initSentry(Sentry, rallyEnv.SENTRY_DSN)
const { SentryProvider } = analytics

initElasticRum()

const Client = (): React.ReactElement => {
  React.useEffect((): void => {
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles)
    }
  }, [])

  const scriptSource = env.IS_NODE_ENV_PRODUCTION
    ? PrivacyScript.PROD
    : PrivacyScript.STAGING

  return (
    <SentryProvider client={Sentry}>
      <Helmet>
        <script
          data-domain-script={scriptSource}
          src={`https://cdn.cookielaw.org/consent/${scriptSource}/otSDKStub.js`}
          type="text/javascript"
        />
      </Helmet>
      <CookiesProvider>
        <ApolloProviderWrapper
          defaultEnvironmentURI={rallyEnv.DEFAULT_API_URL}
          name="rally"
        >
          <BrowserRouter>
            <SnackbarProvider maxSnack={3}>
              <App />
            </SnackbarProvider>
          </BrowserRouter>
        </ApolloProviderWrapper>
      </CookiesProvider>
    </SentryProvider>
  )
}

// analytics
analytics.init({
  facebookPixelId: rallyEnv.FACEBOOK_PIXEL_ID,
  intercomKey: rallyEnv.INTERCOM_KEY,
  linkedinPixelId: rallyEnv.LINKEDIN_PIXEL_ID,
  segmentKey: rallyEnv.SEGMENT_KEY,
  snapchatPixelId: rallyEnv.SNAPCHAT_PIXEL_ID,
})

// render
hydrate(<Client />, document.getElementById('root'))
