import { useReactiveVar } from '@apollo/client'
import {
  ResourceType,
  useGetManagedTeamsQuery,
  useGetMyIdentityQuery,
  UserRoleName,
  UserRoleStatusName,
} from '@plvs/graphql'
import { useCallback, useEffect } from 'react'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'
import {
  isAcceptedUserRoleForResource,
  isCaptainForResource,
  isFacultyForOrganization,
  isStudentForOrganization,
} from '../../../utils/src/user'
import { UserIdentityInterface, userIdentityVar } from '../models/UserIdentity'

function getTeamIdsRelevantToUser(
  teamIds: string[],
  schoolTeamIds: string[],
  isCoach: boolean
): string[] {
  return isCoach ? Array.from(new Set([...teamIds, ...schoolTeamIds])) : teamIds
}

export const useUserIdentityFn = (): UserIdentityInterface => {
  const { data, loading, error, refetch } = useGetMyIdentityQuery()

  const { updateResources } = useResourceImageProvider()
  const user = data?.me
  const avatarUrl = user?.avatarUrl ?? ''
  const userId = user?.id ?? ''
  const orgId = user?.school?.id
  const orgCompetitionGroup = user?.school?.competitionGroup
  const orgCountry = user?.school?.country
  const orgProvince = user?.school?.state
  const orgCity = user?.school?.city
  const orgName = user?.school?.name
  const orgDeletedAt = user?.school?.deletedAt
  const orgSchoolType = user?.school?.type
  const userName = user?.name ?? user?.username ?? ''
  const userRoles = user?.roles ?? []
  const userRoleNames = userRoles.map((role) => role?.roleName)
  const userSchoolRoleStatus = user?.schoolRoleStatus
  const hasMultiOrgAccess = user?.hasMultiorgAccess ?? false
  const isParent = user?.isParent ?? false
  const activeCampaign = user?.school?.activeCampaign
  const isLoggedIn = !loading && !!userId
  const schoolTeams = user?.school?.teams ?? []
  const teamIdsFromRoles = userRoles
    .filter((role) => role?.resourceType === ResourceType.Team)
    .map((role) => role?.resourceId)
  const schoolTeamIds = schoolTeams.map((team) => team?.id)
  const firstName = user?.firstName ?? ''
  const lastName = user?.lastName ?? ''
  const hmac = user?.hmac ?? ''
  const promotionalEmail = user?.promotionalEmail ?? ''
  const emails = user?.emails ?? []
  const state = user?.state ?? ''
  const gradYears = user?.gradYears ?? []

  const {
    data: managedTeamData,
    loading: managedTeamsLoading,
  } = useGetManagedTeamsQuery({
    variables: { schoolId: orgId ?? '' },
    skip: !orgId,
  })

  // All role and role request logic should be refactored to use the endpoint schoolRoleStatus.

  // Faculty and Student roles don't need to be approved
  const isFacultyAtOrg = isFacultyForOrganization(userRoles, [orgId ?? ''])
  const isStudentAtOrg = isStudentForOrganization(userRoles, [orgId ?? ''])

  const isCoachAtOrg = isAcceptedUserRoleForResource(
    userSchoolRoleStatus ?? [],
    UserRoleName.Coach,
    orgId ?? ''
  )

  const teamIds = getTeamIdsRelevantToUser(
    teamIdsFromRoles,
    schoolTeamIds,
    isCoachAtOrg
  )

  const isPlayerAtOrg = isAcceptedUserRoleForResource(
    userSchoolRoleStatus ?? [],
    UserRoleName.Player,
    orgId ?? ''
  )

  const isCaptainAtOrg = isCaptainForResource(userRoles, teamIds)

  const isUserApprovedAtOrg = isCoachAtOrg || isPlayerAtOrg

  const isUserDeniedAtOrg =
    !isUserApprovedAtOrg &&
    !!userSchoolRoleStatus?.length &&
    userSchoolRoleStatus?.every(
      (roleStatus) => roleStatus.status === UserRoleStatusName.Denied
    )

  const isUserPendingVerificationAtOrg =
    !isUserApprovedAtOrg &&
    !!userSchoolRoleStatus?.length &&
    userSchoolRoleStatus?.some(
      (roleStatus) => roleStatus?.status === UserRoleStatusName.Pending
    )

  const isUnderage = user?.isUnderage ?? false
  updateResources({
    resources: [
      { id: userId, type: ResourceType.User },
      { id: orgId, type: ResourceType.Organization },
    ],
  })
  const setVar = useCallback(() => {
    userIdentityVar({
      userId,
      orgId,
      avatarUrl,
      orgCompetitionGroup,
      orgCountry,
      orgProvince,
      orgName,
      orgDeletedAt,
      isCoachAtOrg,
      isFacultyAtOrg,
      isStudentAtOrg,
      isPlayerAtOrg,
      isCaptainAtOrg,
      isUserPendingVerificationAtOrg,
      isUserDeniedAtOrg,
      isUnderage,
      userRoleNames,
      userName,
      userRoles,
      userSchoolRoleStatus,
      refetch,
      loading,
      error,
      hasMultiOrgAccess,
      isParent,
      activeCampaign,
      isLoggedIn,
      teamIds,
      firstName,
      lastName,
      hmac,
      promotionalEmail,
      emails,
      orgCity,
      orgSchoolType,
      state,
      gradYears,
      teams: user?.teams ?? [],
      managedTeams: managedTeamData?.me?.managedTeams ?? [],
    })
  }, [data, managedTeamData, loading, managedTeamsLoading])

  useEffect(() => {
    const setUserId = userIdentityVar().userId
    if (!loading && (!setUserId || setUserId !== userId)) {
      setVar()
    }
  }, [data, managedTeamData, loading, managedTeamsLoading, userId, user])

  return useReactiveVar(userIdentityVar)
}
