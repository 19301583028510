import {
  MatchRescheduleRequestStatus,
  Tour,
  useGetMatchRescheduleRequestDataForMenuQuery,
} from '@plvs/graphql/generated'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import React, { useCallback } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { WaitTillLoaded, useBreakpointSm } from '@plvs/respawn/features/layout'
import { RescheduleMatchAlert } from '@plvs/respawn/features/rescheduleMatch/reschedule'
import {
  EsportSlug,
  SponsorshipComponent as SponsorshipComponentType,
} from '@plvs/graphql'
import { NxErrorState } from '@playvs-inc/nexus-components'
import { MatchTeamForRenderController } from '@plvs/respawn/renderController/matchLobby/match/types/matchRenderController.types'
import { Box, useTheme } from '@material-ui/core'
import { SponsorshipComponent } from '@plvs/respawn/features/sponsorship/SponsorshipComponent'
import { SponsorshipComponentName } from '@plvs/const'
import { SponsorshipComponentWrapper } from '@plvs/respawn/features/sponsorship/SponsorshipComponentWrapper'
import { ShepherdStarter } from '@plvs/respawn/features/shepherd/ShepherdStarter'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { MatchAssistantWrapper } from './MatchAssistantWrapper'
import MatchChatContainer from './chat/MatchChatContainer'
import MatchReportCardWrapper from './MatchReportCardWrapper'

interface MissionControlProps {
  matchId: string
  teamId: string
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  esportSlug: EsportSlug | null | undefined
  components?: SponsorshipComponentType[] | null
  skipMatchAssistant(): Promise<void>
  refetchTopLevelMatchData(): Promise<void>
}
export const MissionControl: React.FC<MissionControlProps> = ({
  team1,
  team2,
  matchId,
  teamId,
  esportSlug,
  components,
  skipMatchAssistant,
  refetchTopLevelMatchData,
}) => {
  const theme = useTheme()
  const isMobile = useBreakpointSm()
  const { matchLobbyTour } = useProductTours()
  const flags = useFlags()
  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  const { lobby, match, chat } = getMatchLobbyRenderControllerState()

  // Reusing the same query used by the ActionAndInfoSectionWrapper.
  // This will hit the cache first.
  const { data, loading } = useGetMatchRescheduleRequestDataForMenuQuery({
    variables: {
      matchId,
    },
    skip: !matchId,
  })

  const hasMatchRescheduleRequest = Boolean(
    data?.match?.matchRescheduleRequests?.some(
      (rq) => rq.status === MatchRescheduleRequestStatus.Pending
    )
  )
  const hasShowMatchLobbyTourFlag =
    matchLobbyTour && Boolean(flags.matchDayOnboardingTour)

  const renderMatchAssistantOrDMR = useCallback((): React.ReactNode => {
    if (flags.errorStateMatchAssistant) {
      return (
        <NxErrorState
          additionalInfo="Please use the chat feature to report results for this match."
          componentDescription="match assistant feature"
        />
      )
    }
    return (
      <>
        {match.shouldRenderMatchReporting && (
          <MatchReportCardWrapper
            canManageMatch={match.canManageMatch}
            matchId={matchId}
            myTeamId={teamId}
            refetchCallback={refetchTopLevelMatchData}
          />
        )}
        {!match.shouldRenderMatchReporting && esportSlug && (
          <MatchAssistantWrapper
            esportSlug={esportSlug}
            matchId={matchId}
            skipMatchAssistant={skipMatchAssistant}
            team1={team1}
            team2={team2}
            teamId={teamId}
          />
        )}
      </>
    )
  }, [
    match.shouldRenderMatchReporting,
    match.canManageMatch,
    flags.errorStateMatchAssistant,
    esportSlug,
  ])

  const renderChat = useCallback((): React.ReactNode => {
    if (flags.errorStateMatchChat) {
      return (
        <NxErrorState
          additionalInfo="Please reach out to the coach on the opposing team to communicate directly for this match."
          componentDescription="match chat feature"
        />
      )
    }
    if (chat.shouldRenderChat) {
      return <MatchChatContainer matchId={matchId} />
    }
    return null
  }, [chat.shouldRenderChat, flags.errorStateMatchChat])

  const bannerComponent = components?.find(
    (x) => x.name === SponsorshipComponentName.MatchLobbyBanner
  )

  const logoComponent = components?.find(
    (x) => x.name === SponsorshipComponentName.MatchLobbyLogo
  )

  return (
    <>
      {match.shouldShowMatchLobbyTour && hasShowMatchLobbyTourFlag && (
        <ShepherdStarter tour={matchLobbyTour} tourType={Tour.MatchLobby} />
      )}
      <WaitTillLoaded loading={loading}>
        {lobby.showRescheduleMatchAlert && hasMatchRescheduleRequest ? (
          <RescheduleMatchAlert
            match={{
              matchRescheduleRequests:
                data?.match?.matchRescheduleRequests ?? [],
              id: matchId,
              esportSlug: esportSlug ?? null,
            }}
            teamId={teamId}
          />
        ) : null}
      </WaitTillLoaded>
      <Box
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
        gridGap={theme.spacing(2)}
        justifyContent="space-between"
      >
        <Box maxWidth={isMobile ? 'none' : '414px'}>{renderChat()}</Box>
        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(3)}
          width="100%"
        >
          {renderMatchAssistantOrDMR()}
          {bannerComponent ? (
            <SponsorshipComponent component={bannerComponent} />
          ) : (
            <></>
          )}
          {logoComponent ? (
            <SponsorshipComponent component={logoComponent} />
          ) : (
            <></>
          )}
          <SponsorshipComponentWrapper
            name={SponsorshipComponentName.MatchLobbyBanner}
          />
          <SponsorshipComponentWrapper
            name={SponsorshipComponentName.MatchLobbyLogo}
          />
        </Box>
      </Box>
    </>
  )
}
