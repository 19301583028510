import React, { ChangeEvent } from 'react'
import {
  NxButton,
  NxSpotModal,
  NxRadioButtonGroup,
  NxTypography,
  Pill,
  NxTextInput,
} from '@playvs-inc/nexus-components'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { Path, DEFAULT_DEBOUNCE_TIME } from '@plvs/const'
import {
  CompetitionGroup,
  useChangeSchoolIdForUserMutation,
  useGetSchoolRolesQuery,
} from '@plvs/graphql/generated'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core'
import { useDebounce } from 'use-debounce'

interface Props {
  onClose(): void
  open: boolean
  orgId: string
}

const useStyles = makeStyles((theme) => ({
  schoolTypeText: {
    margin: theme.spacing(0, 0, 2, 1.5),
  },

  label: {
    maxWidth: '250px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '150px',
    },
  },
}))

const SCHOOL_LIMIT = 10

export const SwitchSchoolModal: React.FC<Props> = ({
  onClose,
  open,
  orgId,
}) => {
  const [schoolId, setSchoolId] = React.useState(orgId)
  const [schoolSearchName, setSchoolSearchName] = React.useState('')
  const [displayLimit, setDisplayLimit] = React.useState(SCHOOL_LIMIT)
  const [debouncedSchoolSearchName] = useDebounce(
    schoolSearchName,
    DEFAULT_DEBOUNCE_TIME
  )
  const [radioProps, setRadioProps] = React.useState({
    hsProps: {},
    msProps: {},
  })
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const { data, loading } = useGetSchoolRolesQuery({ skip: !open })

  const [
    changeSchool,
    { loading: changeSchoolLoading },
  ] = useChangeSchoolIdForUserMutation()

  const handleClose = (): void => {
    setRadioProps({ hsProps: {}, msProps: {} })
    onClose()
  }

  const handleContinue = async (): Promise<void> => {
    try {
      if (orgId === schoolId) {
        handleClose()
        return
      }

      const result = await changeSchool({ variables: { schoolId } })
      if (result?.data?.changeSchoolIdForUser?.success) {
        handleClose()
        window.location.replace(`${Path.Dashboard}`)
      }
    } catch {
      enqueueSnackbar('Error changing school', { variant: 'error' })
    }
  }

  const roleData = data?.getSchoolRoles?.roles ?? []
  const needsSearch = roleData.length > SCHOOL_LIMIT

  const getFilteredRadioOptions = (
    schoolRoles: any[],
    competitionGroup: string,
    searchSchoolName: string
  ): any[] => {
    return schoolRoles
      .filter((item) => item?.school?.competitionGroup === competitionGroup)
      .filter((item) => {
        if (!needsSearch || !searchSchoolName || item?.schoolId === orgId) {
          return true
        }

        return item.school?.name
          .toUpperCase()
          .includes(searchSchoolName.toUpperCase())
      })
      .sort((a, b) => {
        if (a?.schoolId === orgId) {
          return -1
        }
        if (b?.schoolId === orgId) {
          return 1
        }
        const schoolA = a?.school?.name?.toLowerCase() ?? ''
        const schoolB = b?.school?.name?.toLowerCase() ?? ''
        return schoolA.localeCompare(schoolB)
      })
      .map((item) => ({
        label: (
          <Box
            data-testid={`test-school-${item?.school?.name}`}
            display="flex"
            gridGap="10px"
          >
            <NxTypography className={classes.label} variant="body1">
              {item?.school?.name ?? ''}
            </NxTypography>
            {item?.schoolId === orgId && (
              <Pill label="Selected" size="small" variant="info" />
            )}
          </Box>
        ),
        value: item?.schoolId ?? '',
      }))
  }

  const highSchoolRadioOptions = getFilteredRadioOptions(
    roleData,
    CompetitionGroup.HighSchool,
    debouncedSchoolSearchName
  )

  const middleSchoolRadioOptions = getFilteredRadioOptions(
    roleData,
    CompetitionGroup.MiddleSchool,
    debouncedSchoolSearchName
  )

  const filteredLength =
    highSchoolRadioOptions.length + middleSchoolRadioOptions.length

  const handleMsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSchoolId((event.target as HTMLInputElement).value)
    setRadioProps({ hsProps: { checked: false }, msProps: {} })
  }

  const handleHsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSchoolId((event.target as HTMLInputElement).value)
    setRadioProps({ hsProps: {}, msProps: { checked: false } })
  }

  return (
    <NxSpotModal
      actions={
        <>
          <NxButton
            data-testid="cancel-button"
            label="Cancel"
            onClick={handleClose}
            variant="text"
          />
          <NxButton
            data-testid="continue-button"
            disabled={changeSchoolLoading || !schoolId}
            label="Continue"
            onClick={handleContinue}
            variant="primary"
          />
        </>
      }
      onClose={handleClose}
      open={open}
      spot={
        <NxSpot
          domain="school"
          height={100}
          size="large"
          variant="primary"
          width={100}
        />
      }
      subtitle={
        <Box>
          <Box mb={2}>
            <NxTypography variant="body1">
              Once you choose a school, you&apos;ll be taken directly to the
              coach view for that school.
            </NxTypography>
          </Box>
          {needsSearch && (
            <NxTextInput
              defaultValue=""
              fullWidth
              label="Search School"
              onChange={(
                evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
              ): void => {
                setDisplayLimit(SCHOOL_LIMIT)
                setSchoolSearchName(evt.currentTarget.value)
              }}
            />
          )}
        </Box>
      }
      title="Select a School to Manage"
    >
      <WaitTillLoaded
        loading={
          loading && (!middleSchoolRadioOptions || !highSchoolRadioOptions)
        }
        showSpinnerWhileLoading
      >
        {middleSchoolRadioOptions.length > 0 && (
          <Box mb={2}>
            <NxTypography className={classes.schoolTypeText} variant="overline">
              Middle School
            </NxTypography>
            <NxRadioButtonGroup
              {...radioProps.msProps}
              defaultValue={orgId}
              onChange={handleMsChange}
              radioOptions={middleSchoolRadioOptions.slice(0, displayLimit)}
            />
          </Box>
        )}
        {highSchoolRadioOptions.length > 0 && (
          <>
            <NxTypography className={classes.schoolTypeText} variant="overline">
              High School
            </NxTypography>
            <NxRadioButtonGroup
              {...radioProps.hsProps}
              defaultValue={orgId}
              onChange={handleHsChange}
              radioOptions={highSchoolRadioOptions.slice(0, displayLimit)}
            />
          </>
        )}
        {needsSearch && displayLimit < filteredLength && (
          <>
            <NxButton
              label="Load more..."
              onClick={(): void => {
                setDisplayLimit(displayLimit + SCHOOL_LIMIT)
              }}
              variant="text"
            />
          </>
        )}
      </WaitTillLoaded>
    </NxSpotModal>
  )
}
