import React, { useEffect, useState } from 'react'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useGetLeagueSeasonDataQuery } from '@plvs/graphql/generated'
import { PhaseFilterType } from '@plvs/rally/features/explore/LeagueDetails.types'
import { sortByStartsAt } from '@plvs/utils'
import { LeagueStandings } from '@plvs/rally/features/explore/LeagueStandings/LeagueStandings'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { last } from 'ramda'
import { EsportSlug } from '@plvs/graphql/types'
import { EmbedFilters } from './EmbedFilters'

interface EmbedStandingsProps {
  leagueId: string
  esportSlug: EsportSlug | undefined
}

export const EmbedStandings: React.FC<EmbedStandingsProps> = ({
  leagueId,
  esportSlug,
}) => {
  const [phaseFilter, setPhaseFilter] = useState<PhaseFilterType | undefined>()

  const {
    loading: schoolLeagueInfoContextLoading,
    metaseason,
  } = useSchoolLeagueInfoContext()
  const metaseasonId = metaseason?.id ?? ''

  const {
    data: leagueSeasonData,
    loading: leagueSeasonDataLoading,
  } = useGetLeagueSeasonDataQuery({
    skip: !leagueId || !metaseasonId || schoolLeagueInfoContextLoading,
    variables: { leagueId, metaseasonId },
  })

  const season = leagueSeasonData?.league?.seasonByMetaseasonId
  const seasonPhases = sortByStartsAt(season?.activePhases ?? [])

  useEffect(() => {
    if (seasonPhases) {
      const lastPhase = last(seasonPhases)
      if (lastPhase) {
        setPhaseFilter({ ...lastPhase, name: lastPhase.name ?? '' })
      }
    }
  }, [leagueSeasonDataLoading])

  return (
    <WaitTillLoaded
      loading={leagueSeasonDataLoading || schoolLeagueInfoContextLoading}
      showSpinnerWhileLoading
    >
      <Box alignItems="center" flexDirection="column" flexGrow="0">
        <Box alignItems="center" display="flex" mb={2}>
          <EmbedFilters
            phase={phaseFilter}
            phases={seasonPhases as PhaseFilterType[]}
            setPhase={setPhaseFilter}
            setSlotFilter={undefined}
            slotFilter={undefined}
            team={null}
          />
        </Box>
        <LeagueStandings
          esportSlug={esportSlug}
          phase={phaseFilter}
          seasonId={season?.id ?? ''}
        />
      </Box>
    </WaitTillLoaded>
  )
}
