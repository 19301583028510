import React from 'react'
import { Box } from '@material-ui/core'

import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { groupBy } from 'ramda'
import { NxTypography } from '@playvs-inc/nexus-components'
import { MinimalMetaseason } from '@plvs/utils'
import { getDay } from 'date-fns'
import { MatchList } from './MatchList/MatchList'

type MatchDayGroupProps = {
  date: string
  matches: MappedMatch[]
  metaseasons: MinimalMetaseason[]
}

export const MatchDayGroup: React.FC<MatchDayGroupProps> = ({
  date,
  matches,
  metaseasons,
}) => {
  // Adding logic here that will allow for matches of the same day but different metaseasons to be grouped apart,
  // but also allow for matches such as scrimmages that do not have a metaseasonId but are of the same day to be grouped together.
  const groupedByMetaseason = groupBy((match) => {
    const metaseasonId = match.metaseasonId ?? ''
    if (metaseasonId) {
      return metaseasonId
    }
    const dayToMatchOn = getDay(new Date(match.scheduledStartsAt ?? ''))
    return (
      matches.find((m) => {
        const day = getDay(new Date(m.scheduledStartsAt ?? ''))

        return day === dayToMatchOn && m.metaseasonId !== match.metaseasonId
      })?.metaseasonId ?? ''
    )
  }, matches)
  const metaseasonKeys = Object.keys(groupedByMetaseason)

  return (
    <Box
      data-testid="match-day-group"
      display="flex"
      flexDirection="column"
      mb={5}
    >
      {metaseasonKeys.map((metaseasonId) => {
        const metaseasonGroup = groupedByMetaseason[metaseasonId]
        const metaseason = metaseasons.find((m) => m.id === metaseasonId)
        return (
          <Box key={metaseasonId} data-testid="match-time-group">
            <Box mb={2}>
              <NxTypography
                data-testid="MathDayGroup_Title_General"
                variant="overline"
              >
                {date} {metaseason?.name && `· ${metaseason?.name}`}
              </NxTypography>
            </Box>
            <Box>
              <MatchList matches={metaseasonGroup} />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
