import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { MaintenanceErrorState } from './MaintenanceErrorState'

type DefaultMaintenanceProps = {
  /** Overrides feature flag */
  isMaintenance?: string
}

export const DefaultMaintenance: React.FC<DefaultMaintenanceProps> = ({
  children,
  isMaintenance,
}) => {
  const flags = useFlags()

  // Note: environment variable set to true should override LD value https://playvs.atlassian.net/browse/GP-4015
  if (isMaintenance === 'true') {
    return <MaintenanceErrorState />
  }

  if (flags.maintenanceMode === true) {
    return <MaintenanceErrorState />
  }

  return <>{children}</>
}
