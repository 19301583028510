import React, { useEffect, useState } from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import {
  NxButton,
  NxCheckbox,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { PlasmicComponent } from '@plasmicapp/loader-react'
import { useCreateUserConsentMutation } from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { Path, ChildOnboardPath } from '@plvs/const'
import { useAuthRenderControllerContext } from '@plvs/respawn/renderController'
import { useOnboardingContext } from '../onboard/OnboardingContext'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  button: {
    marginBottom: theme.spacing(2),
  },
  noUnderline: {
    textDecoration: 'none',
  },
  agreement: {
    marginBottom: theme.spacing(3),
  },
  checkbox: {
    paddingTop: theme.spacing(1.125),
    color: theme.palette.ColorIconBase,
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
}))

export const OnboardChildDataUsage: React.FC = () => {
  const { auth } = useAuthRenderControllerContext()
  const { shouldRenderUsernameField } = auth.getAuthComponentsToRender({
    location: window.location.href,
  })
  const containerRef = React.useRef<HTMLDivElement>(null)

  const classes = useStyles()

  const navigate = useNavigate()

  const { enqueueSnackbar } = useSnackbar()

  const [createUserConsent, { loading }] = useCreateUserConsentMutation()

  const { assign } = useOnboardingContext()

  const [isVisible, setIsVisible] = useState(false)

  const [checkboxes, setCheckboxes] = React.useState({
    termsOfUse: false,
    dataCollection: false,
    parentGuardian: false,
  })

  const handleChange = (event): void => {
    setCheckboxes({ ...checkboxes, [event.target.name]: event.target.checked })
  }

  const handleContinue = async (): Promise<void> => {
    try {
      const result = await createUserConsent()
      const consentId = result?.data?.createUserConsent?.id
      if (!consentId) {
        enqueueSnackbar('Error creating user consent', { variant: 'error' })
        return
      }

      assign({ consentId })

      navigate(
        `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildBirthday}`
      )
    } catch (error) {
      enqueueSnackbar('Error creating user consent', { variant: 'error' })
    }
  }

  const allChecked =
    checkboxes.termsOfUse &&
    checkboxes.dataCollection &&
    checkboxes.parentGuardian

  const checkForTermsVisibility = (entries): void => {
    const [entry] = entries

    // To prevent checking offsetHeight before it's available
    setTimeout(() => {
      if (containerRef?.current?.offsetHeight) {
        setIsVisible(entry.isIntersecting)
      }
    }, 1000)
  }

  useEffect(() => {
    const observer = new IntersectionObserver(checkForTermsVisibility, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    })

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return (): void => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [containerRef])

  return (
    <div className={classes.root}>
      <QuestionnaireCard
        childWidth="100%"
        maxChildWidth={850}
        title="Data Usage Agreement"
      >
        <div
          ref={containerRef}
          className={classes.agreement}
          data-testid="policy"
        >
          <Box mb={3}>
            <NxTypography variant="body1">
              PlayVS takes your child’s privacy very seriously. PlayVS will
              never share child data with third parties for advertising
              purposes. Please review and approve PlayVS’s right to collect the
              information necessary for your child to use our platform. If you
              do not provide your consent, then PlayVS will not collect, use, or
              disclose any of your child’s personal information.
            </NxTypography>
          </Box>
          <PlasmicComponent
            component={
              shouldRenderUsernameField
                ? 'DataUsageAgreementStadium'
                : 'DataUsageAgreement'
            }
          />
        </div>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          mb={3}
          width="100%"
        >
          <Box alignItems={['start', 'center']} display="flex" mb={[1, 0]}>
            <NxCheckbox
              checked={checkboxes.termsOfUse}
              className={classes.checkbox}
              name="termsOfUse"
              onChange={handleChange}
            />
            <NxTypography variant="body1">
              I have read and agreed to PlayVS{' '}
              <a
                className={classes.noUnderline}
                href="https://www.playvs.com/terms"
                rel="noreferrer"
                target="_blank"
              >
                <NxTypography
                  colorToken="ColorTextLink"
                  component="span"
                  variant="body1"
                >
                  Terms of Use
                </NxTypography>
              </a>{' '}
              and{' '}
              <a
                className={classes.noUnderline}
                href="https://www.playvs.com/privacy"
                rel="noreferrer"
                target="_blank"
              >
                <NxTypography
                  colorToken="ColorTextLink"
                  component="span"
                  variant="body1"
                >
                  Privacy Policy
                </NxTypography>
              </a>
            </NxTypography>
          </Box>
          <Box alignItems={['start', 'center']} display="flex" mb={[1, 0]}>
            <NxCheckbox
              checked={checkboxes.dataCollection}
              className={classes.checkbox}
              name="dataCollection"
              onChange={handleChange}
            />
            <NxTypography variant="body1">
              I provide my consent for PlayVS to collect and share this data for
              my child
            </NxTypography>
          </Box>
          <Box alignItems={['start', 'center']} display="flex" mb={[1, 0]}>
            <NxCheckbox
              checked={checkboxes.parentGuardian}
              className={classes.checkbox}
              name="parentGuardian"
              onChange={handleChange}
            />
            <NxTypography variant="body1">
              I verify that I am the parent or legal guardian of the child for
              which I am about to create a PlayVS account
            </NxTypography>
          </Box>
        </Box>

        <NxButton
          className={classes.button}
          data-testid="continue"
          disabled={loading || !isVisible || !allChecked}
          fullWidth
          label="Continue"
          onClick={handleContinue}
          variant="primary"
        />
        {!allChecked && (
          <NxTypography colorToken="ColorTextError" variant="body1">
            Please check all the boxes before proceeding to next step.
          </NxTypography>
        )}
      </QuestionnaireCard>
    </div>
  )
}
