import React, { useEffect, useState } from 'react'
import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import utc from 'dayjs/plugin/utc'
import tz from 'dayjs/plugin/timezone'
import { CompetitionGroup, EsportRating } from '@plvs/graphql'
import {
  competitionModelToDisplay,
  CompetitionGroupMap,
  DateFormats,
} from '@plvs/utils'
import { restrictedEsportLabel } from '@plvs/rally/libs/data-utils/esport-utils'

import {
  MatchCheckInMatch,
  MatchCheckInMatchStatus,
} from '@plvs/rally/features/match/checkIns/MatchCheckInTypes'
import { ScheduleOutlined, Verified } from '@playvs-inc/nexus-icons'
import dayjs from '@plvs/respawn/init/dayjs'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'

dayjs.extend(utc)
dayjs.extend(tz)

export interface MatchCheckInHeaderProps {
  match?: MatchCheckInMatch
  status: MatchCheckInMatchStatus
  isCheckedIn: boolean
  isCheckInOpen: boolean
}

const useStyles = makeStyles((theme) => ({
  green: {
    color: theme.palette.ColorTextSuccess,
  },
}))

export const MatchCheckInHeader = ({
  match,
  status,
  isCheckedIn,
  isCheckInOpen,
}: MatchCheckInHeaderProps): React.ReactElement => {
  const { green } = useStyles()
  const isMobile = useBreakpointSm()
  const matchResults = match?.matchResults ?? []
  const team1 = matchResults[0]?.team
  const team2 = matchResults[1]?.team
  const competitionGroupDisplay =
    match?.esport?.rating === EsportRating.General
      ? `${
          CompetitionGroupMap[
            match.league?.competitionGroup || CompetitionGroup.HighSchool
          ]
        }s`
      : restrictedEsportLabel

  const subtitles = [
    match?.esport?.name,
    competitionGroupDisplay,
    competitionModelToDisplay({
      competitionModel: match?.league?.competitionModel,
      competitionGroup: match?.league?.competitionGroup,
    }),
  ]
  const subtitle = subtitles.filter(Boolean).join(' | ')

  const checkInFromNow = (): string => dayjs(match?.checkInClosesAt).fromNow()

  const [timeUntilCheckInCloses, setTimeUntilCheckInCloses] = useState(
    checkInFromNow()
  )

  useEffect(() => {
    setTimeUntilCheckInCloses(checkInFromNow())
  }, [match?.id])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeUntilCheckInCloses(checkInFromNow())
    }, 1000)

    return (): void => clearInterval(intervalId)
  }, [timeUntilCheckInCloses])

  let pill: React.ReactElement | undefined
  switch (status) {
    case MatchCheckInMatchStatus.Forfeited:
      pill = <Pill label="Forfeited" size="large" variant="error" />
      break
    case MatchCheckInMatchStatus.OpponentForfeited:
      pill = <Pill label="Opponent Forfeited" size="large" variant="error" />
      break
    case MatchCheckInMatchStatus.RescheduleRequested:
      pill = (
        <Pill
          data-cy="rescheduleRequested"
          label="Reschedule Requested"
          size="large"
          variant="warn"
        />
      )
      break
    case MatchCheckInMatchStatus.Rescheduled:
      pill = <Pill label="Rescheduled" size="large" variant="warn" />
      break
    case MatchCheckInMatchStatus.Closed:
      pill = <Pill label="Check-in Closed" size="large" variant="error" />
      break
    case MatchCheckInMatchStatus.Available:
    case MatchCheckInMatchStatus.CheckedIn:
    default:
      pill =
        !isCheckedIn && isCheckInOpen ? (
          <Pill
            label={`Check in closes ${timeUntilCheckInCloses}`}
            size="large"
            variant="warn"
          />
        ) : (
          <></>
        )
      break
  }

  return (
    <Box>
      <NxTypography component="span" variant="h1">
        <Box>
          {isCheckedIn ? (
            <>
              <Box
                className={green}
                component="span"
                id="checked-in-green"
                position="relative"
                top="5px"
              >
                <Verified fontSize="large" />
              </Box>
              <Box className={green} component="span" id="checked-in-green">
                Checked in
              </Box>
            </>
          ) : (
            <span>Check in</span>
          )}
          <span>{` for ${team1?.name} vs ${team2?.name}`}</span>
        </Box>
      </NxTypography>
      <Box pb={1} pt={2}>
        <NxTypography id="check-in-header-time" variant="subtitle2">
          {subtitle}
        </NxTypography>
      </Box>
      <Box
        alignItems={isMobile ? 'flex-start' : 'center'}
        display="flex"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <Box alignItems="center" display="flex">
          <ScheduleOutlined />
          <Box ml={1} mr={2}>
            <NxTypography id="check-in-header-time" variant="body1">
              {dayjs(match?.scheduledStartsAt).format(
                DateFormats.PrettyPrintFormat
              )}
            </NxTypography>
          </Box>
        </Box>
        <Box mt={isMobile ? 2 : 0}>{pill}</Box>
      </Box>
    </Box>
  )
}
