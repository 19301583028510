import { find, map } from 'ramda'
import { Colors } from '@plvs/rally/themes'
import {
  BracketMatchTeam,
  MatchResult,
  MatchSlot,
  Maybe,
  School,
  SeededTeam,
  Slot,
  SlotLabel,
  TeamSlot,
  EsportRating,
} from '@plvs/graphql'

export const POD_HEIGHT = 32
export const POD_GUTTER = 16
export const LEG_WIDTH = 15
export const TAIL_WIDTH = 20
export const FALL_2022_DATE = '2022-08-31 21:51:00.000 -0700'

type RawTeam = Pick<SeededTeam, 'id' | 'name' | 'seed'> & {
  school: Maybe<Pick<School, 'logoUrl'>>
}
export type BracketTeam = RawTeam | null

export type BracketResult = {
  matchId: string | null
  esportRating: EsportRating | null
  team: BracketTeam
  status: BracketResultStatus
  isUnfilledForBye?: boolean
  matchCompleted?: boolean
  matchSlotId?: string
}
export type Slots = ({
  __typename?: 'Slot'
} & Pick<Slot, 'id' | 'startsAt' | 'label' | 'ordinal'> & {
    matchSlots: Maybe<
      ({
        __typename?: 'MatchSlot'
      } & Pick<MatchSlot, 'id' | 'ordinal' | 'slotId'>)[]
    >
  })[]

export type BracketMatchResult = Pick<
  MatchResult,
  'id' | 'matchId' | 'teamId' | 'placing'
> | null

export enum BracketResultStatus {
  Bye = 'Bye', // when there is no team avaiable to play in the first slot or the previous bracket result pair are both Byes, a Bye results
  Tbd = 'Tbd', // when a result cannot be determined due to lack of information, it's Tbd
  Pending = 'Pending', // when a team is yet to play, the result is Pending
  Win = 'Win', // when a team places 1st in a match or wins by default, in the case of a Bye, a Win results
  Loss = 'Loss', // when a team places 2nd in a match, a Loss results
  Unknown = 'Unknown', // when the data produces an Unknown state, like placing 3rd in a match
}

// compute the number of rounds in the bracket based on the number of teams entered
// 2 => 1
// 3 => 2
// 4 => 2
// 5 => 3
// 8 => 3
// 9 => 4
// 15 => 4
export const computeRoundCount = (teamsCount: number): number => {
  let working = teamsCount
  let count = 0
  while (working > 1) {
    working = Math.ceil(working / 2)
    count += 1
  }
  return count
}

export const teamToBracketResult = (
  team: BracketTeam,
  esportRating = EsportRating.General
): BracketResult =>
  team?.id
    ? {
        esportRating,
        matchId: null,
        team,
        status: BracketResultStatus.Unknown,
      }
    : {
        esportRating,
        matchId: null,
        team,
        status: BracketResultStatus.Bye,
      }

export const computeBracketResult = (
  bracketMatchResults: BracketMatchResult[],
  team: BracketTeam,
  esportRating = EsportRating.General
): BracketResult => {
  // find a match result in the current slot that has the same team id
  const foundMatchResult = find(
    (matchResult) => (matchResult?.teamId ?? '-1') === (team?.id ?? '-2'),
    bracketMatchResults
  )

  // no match has been scheduled for this paring
  if (!foundMatchResult)
    return {
      esportRating,
      matchId: null,
      status: BracketResultStatus.Pending,
      team,
    }

  // a match has been scheduled for this paring
  const { matchId, placing } = foundMatchResult
  if (placing === 1)
    // this team won
    return {
      esportRating,
      matchId,
      status: BracketResultStatus.Win,
      team,
      matchCompleted: true,
    }
  if (placing === 2)
    // this team lost
    return {
      esportRating,
      matchId,
      status: BracketResultStatus.Loss,
      team,
      matchCompleted: true,
    }

  // the match hasn't been played
  return {
    esportRating,
    matchId,
    status: BracketResultStatus.Pending,
    team,
  }
}

const getNextSeedOrder = (seedOrder: number[]): number[] => {
  const newSeedOrder: number[] = []
  const newLength = seedOrder.length * 2 + 1
  seedOrder.forEach((seed) => {
    newSeedOrder.push(seed)
    newSeedOrder.push(newLength - seed)
  })
  return newSeedOrder
}

// Returns paired seeds in a flat array ordered such that the top two seeds
// wont be paired until the final
// e.g. [1, 8, 4, 5, 2, 7, 3, 6]
export const getSeedOrder = (numRounds: number): number[] => {
  let seedOrder = [1, 2]
  for (let i = 0; i < numRounds - 1; i += 1) {
    seedOrder = getNextSeedOrder(seedOrder)
  }
  return seedOrder
}

export const rawTeamsToOrderedTeams = (teams: RawTeam[]): BracketTeam[] => {
  return map(
    (seed) =>
      find((team) => team.seed === seed, teams) || {
        seed,
        id: '',
        name: '',
        schoolId: null,
        school: null,
      },
    getSeedOrder(computeRoundCount(teams.length))
  )
}

export const computeBracketSlotResults = (
  teams: RawTeam[],
  matchResults: BracketMatchResult[][],
  esportRating = EsportRating.General
): BracketResult[][] => {
  const bracketSlotResults: BracketResult[][] = []
  const numSlots = computeRoundCount(teams.length)
  for (let slotIndex = 0; slotIndex < numSlots; slotIndex += 1) {
    const numTeams = 2 ** (numSlots - slotIndex)

    // creating the first slot of bracket results is a special case, because
    //  we're computing the results based off the teams array and not from the
    //  previous slot of bracket results (which doesn't exist)
    if (slotIndex === 0) {
      // A. Convert raw teams with seed to ordered teams. That means filling
      //  missing entries with null and then reordering by seed-based pairing
      const orderedTeams = rawTeamsToOrderedTeams(teams)
      // B. No team is equivalent to a Bye; a team is a bracket result with
      //  status Unknown--but will be computed in the next step
      bracketSlotResults[0] = map(teamToBracketResult, orderedTeams)
      // C. Now compute all the Unknowns (bracket results with a team), using
      //  match results
      bracketSlotResults[0] = map((bracketResult) => {
        const { team } = bracketResult

        return team?.id
          ? computeBracketResult(
              matchResults?.[slotIndex] ?? [],
              team,
              esportRating
            )
          : bracketResult
      }, bracketSlotResults[0])
      // hypothetically, the bracket results at this stage will be one of Bye,
      //  Pending, Win, Loss, or, in an extreme edge case, Unknown
      // bracket results for subsequent slots may include Tbd
    }

    // prepare the next slot of bracket results as an array of `null`s, half the
    //  length of the current slot
    const nextSlotIndex = slotIndex + 1
    bracketSlotResults[nextSlotIndex] = new Array(numTeams / 2).fill(null)

    for (let teamIndex = 0; teamIndex < numTeams; teamIndex += 2) {
      const teamA = bracketSlotResults[slotIndex][teamIndex]
      const teamB = bracketSlotResults[slotIndex][teamIndex + 1]

      // NOTE: Read after 1 & 2.
      // 3. Otherwise, we don't have enough information to determine this result
      let bracketResult: BracketResult = {
        esportRating,
        matchId: null,
        status: BracketResultStatus.Tbd,
        team: null,
      }

      if (
        teamA.status === BracketResultStatus.Bye &&
        teamB.status === BracketResultStatus.Bye
      ) {
        // 1. A pair of Byes result in a Bye
        bracketResult = {
          esportRating,
          matchId: null,
          status: BracketResultStatus.Bye,
          team: null,
        }
      } else if (
        teamB.team &&
        (teamA.status === BracketResultStatus.Bye ||
          teamB.status === BracketResultStatus.Win)
      ) {
        // 2. If there is a Team B, a Bye from Team A or a win by Team B means
        //  we should check match results for Team B
        bracketResult = computeBracketResult(
          matchResults?.[nextSlotIndex] ?? [],
          teamB.team,
          esportRating
        )
      } else if (
        teamA.team &&
        (teamB.status === BracketResultStatus.Bye ||
          teamA.status === BracketResultStatus.Win)
      ) {
        // 2. If there is a Team A, a Bye from Team B or a win by Team A means
        //  we should check match results for Team A
        bracketResult = computeBracketResult(
          matchResults?.[nextSlotIndex] ?? [],
          teamA.team,
          esportRating
        )
      }

      // save the result to the next slot
      bracketSlotResults[nextSlotIndex][teamIndex / 2] = bracketResult
    }
  }
  return bracketSlotResults
}

interface ResultMap {
  backgroundColor: string
  color: string
  shortText: string
}

export const bracketResultStatusMap: Record<BracketResultStatus, ResultMap> = {
  [BracketResultStatus.Loss]: {
    backgroundColor: Colors.Lava1,
    color: Colors.Lava2,
    shortText: 'L',
  },
  [BracketResultStatus.Win]: {
    backgroundColor: Colors.Fern1,
    color: Colors.Fern4,
    shortText: 'W',
  },
  [BracketResultStatus.Unknown]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    shortText: '?',
  },
  [BracketResultStatus.Pending]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    shortText: '',
  },
  [BracketResultStatus.Bye]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    shortText: '',
  },
  [BracketResultStatus.Tbd]: {
    backgroundColor: 'transparent',
    color: 'inherit',
    shortText: '',
  },
}

/**
 * Double elimination bracket helpers
 */

export type DoubleElimTeamSlot = Maybe<
  Pick<TeamSlot, 'type' | 'sourceSeed' | 'isUnfilledForBye'> & {
    team: Maybe<
      Pick<BracketMatchTeam, 'id' | 'name' | 'seed'> & {
        school: Maybe<Pick<School, 'id' | 'logoUrl'>>
        result: Maybe<Pick<MatchResult, 'matchId' | 'placing'>>
      }
    >
    matchSlotId: Maybe<string>
  }
>

export type BracketType = 'winners' | 'losers'

export const parseWinnerSlotsByLabel = (slot: Pick<Slot, 'label'>): boolean =>
  slot.label === SlotLabel.Winners || slot.label === SlotLabel.Finals

export const parseDoubleElimWinnerSlotsByLabel = (
  slot: Pick<Slot, 'label'>
): boolean => slot.label === SlotLabel.Winners

export const parseLoserSlotsByLabel = (slot: Pick<Slot, 'label'>): boolean =>
  slot.label === SlotLabel.Losers

export const parseFinalSlotsByLabel = (slot: Pick<Slot, 'label'>): boolean =>
  slot.label === SlotLabel.Finals

export const determineBracketResultStatus = (
  placing: number
): BracketResultStatus => {
  if (placing === 1) {
    return BracketResultStatus.Win
  }
  if (placing === 2) {
    return BracketResultStatus.Loss
  }

  return BracketResultStatus.Tbd
}

export const computeDoubleElimResults = (
  teamSlot: DoubleElimTeamSlot,
  completed = true,
  esportRating = EsportRating.General
): BracketResult => {
  const team = teamSlot?.team

  return {
    esportRating,
    matchId: team?.result?.matchId ?? '',
    status: determineBracketResultStatus(team?.result?.placing ?? 0),
    isUnfilledForBye: teamSlot?.isUnfilledForBye ?? false,
    matchCompleted: completed,
    matchSlotId: teamSlot?.matchSlotId ?? '',
    team: {
      id: team?.id ?? '',
      name: team?.name ?? null,
      seed: teamSlot?.sourceSeed ?? null,
      school: {
        logoUrl: team?.school?.logoUrl ?? null,
      },
    },
  }
}

export const computeIncompleteDoubleElimResults = (
  teamSlot: DoubleElimTeamSlot
): BracketResult => {
  return computeDoubleElimResults(teamSlot, false)
}

export const getWinnerOfBracket = (
  bracketResult: Pick<BracketResult, 'status'>
): boolean => bracketResult.status === BracketResultStatus.Win

export const getLastDoubleElimSlot = (slots: Slots): number => {
  const lastDoubleElimSlot = slots?.find(
    (lslots) => lslots?.matchSlots?.length === 1
  )

  const doubleElimLastSlotIndex = slots?.findIndex(
    (lslots) =>
      lastDoubleElimSlot?.matchSlots &&
      lslots?.matchSlots &&
      lslots?.matchSlots[0]?.id === lastDoubleElimSlot?.matchSlots[0]?.id
  )

  return doubleElimLastSlotIndex ?? 50
}
