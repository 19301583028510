import React, { useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import { InputAdornment, Paper, TextField, useTheme } from '@material-ui/core'
import {
  EsportRating,
  EsportSlug,
  useGetAllLeagueTeamsQuery,
} from '@plvs/graphql'
import {
  Table,
  TablePagination,
  usePagination,
} from '@plvs/rally/components/table'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'
import { Param, Path } from '@plvs/const'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'

import { useNavigate } from 'react-router'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { generalColumns, youthProgramColumns } from './columns'
import { useStyles } from './styles'

interface Props {
  esportRating: EsportRating
  esportSlug: EsportSlug
  leagueId: string
  metaseasonId: string
}

const TEAMS_LIMIT = 15

export const LeagueDetailsTeamsTable: React.FC<Props> = ({
  esportRating,
  esportSlug,
  leagueId,
  metaseasonId,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const theme = useTheme()

  const [keyword, setKeyword] = useState<string>('')

  const handleKeywordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setKeyword(event.target.value)
  }

  const { page, setPage, getNumPages, offset } = usePagination({
    itemsPerPage: TEAMS_LIMIT,
  })

  const { data, loading } = useGetAllLeagueTeamsQuery({
    variables: {
      filters: { metaseasonId, leagueId, esportSlugs: [esportSlug], keyword },
      limit: TEAMS_LIMIT,
      offset,
    },
    skip: !leagueId || !metaseasonId,
  })

  const teams = data?.getTeams?.teams ?? []
  const sortedTeams = teams.slice().sort((a, b) => {
    const teamAName = a?.name ?? ''
    const teamBName = b?.name ?? ''

    return teamAName.localeCompare(teamBName)
  })

  const totalTeams = data?.getTeams?.totalCount ?? 0
  const numPages = getNumPages(totalTeams)

  const handleCreateTeamClick = (): void =>
    navigate(`${Path.CreateTeam}?${Param.Esport}=${esportSlug}`)

  const columns =
    esportRating === EsportRating.Restricted
      ? youthProgramColumns
      : generalColumns

  return (
    <>
      <div className={classes.search}>
        <TextField
          className={classes.textfield}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          label="Search team by name or school"
          onChange={handleKeywordChange}
          value={keyword}
          variant="outlined"
        />
      </div>
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        {teams.length > 0 ? (
          <div>
            <Table
              columns={columns}
              data={sortedTeams}
              tdStyles={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
              }}
              thStyles={{ backgroundColor: theme.palette.ColorBackgroundAlt }}
            />
            <TablePagination
              className={classes.pagination}
              numPages={numPages}
              page={page}
              setPage={setPage}
              totalCount={totalTeams}
            />
          </div>
        ) : (
          <Paper className={classes.paper}>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <NxSpot
                domain="createTeam"
                height="152px"
                size="large"
                variant="secondary"
                width="183px"
              />
              <NxTypography className={classes.text} variant="h3">
                No Teams Available
              </NxTypography>
              <NxTypography className={classes.text}>
                There are no teams enrolled in this league yet. Why don&apos;t
                you create a team?
              </NxTypography>
              <NxButton
                data-cy="createTeamButton"
                label="Create Team"
                onClick={handleCreateTeamClick}
                variant="primary"
              />
            </Box>
          </Paper>
        )}
      </WaitTillLoaded>
    </>
  )
}
