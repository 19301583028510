import {
  MatchCheckInStatus,
  Match,
  GetMatchReportHistoryQuery,
  GetLobbyMatchStaticQuery,
  GetLobbyMatchQuery,
  MatchStatus,
  MatchDisputeRequest,
  MatchDisputeStatus,
  PhaseType,
  MatchScheduleContext,
  Maybe,
  TeamRosterFormat,
  School,
  GetDmrMatchQuery,
} from '@plvs/graphql'
import { head, includes, reject } from 'ramda'
// eslint-disable-next-line no-restricted-imports
import dayjs from '@plvs/respawn/init/dayjs'

export type MatchSettleFetchRequest = NonNullable<
  GetMatchReportHistoryQuery['fetchMatchSettleRequest']['matchSettleRequest']
>[0]

export type MatchSettleFetchRequests = NonNullable<
  GetMatchReportHistoryQuery['fetchMatchSettleRequest']['matchSettleRequest']
>

export type LobbyMatch = GetLobbyMatchStaticQuery['match'] &
  GetLobbyMatchQuery['match']

export type DmrMatch = GetDmrMatchQuery['match']

export type DmrTeam = NonNullable<DmrMatch>['team1']

export type DmrMatchResultTeam = NonNullable<
  NonNullable<DmrMatch>['matchResults']
>[0]['team']

export type DmrSeries = NonNullable<NonNullable<DmrMatch>['series']>[0]

export type DmrSeriesTeam = NonNullable<DmrSeries['team1']>

export type LobbyTeam = {
  id?: string | null
  archivedAt?: string | null
  name?: string | null
  teamSide?: number | null
  enrolledSeason?: any
  leagues?: any
  roster?:
    | {
        id: string
        name: string
        avatarUrl: string
        publisherAccountName: string
      }[]
    | null
  rosterFormat?: Partial<TeamRosterFormat>
  starters?:
    | {
        id: string
        name: string
        avatarUrl: string
        publisherAccountName: string
      }[]
    | null
  school?: Pick<School, 'id' | 'name' | 'logoUrl'> | null
  regularSeasonTeamPhaseStandings?: {
    winLossRecord: {
      matchesWon: number | null
      matchesLost: number | null
    } | null
    ranking: number | null
    hasClinchedPlayoffs: boolean | null
  } | null
} | null

// enums

export enum CanViewReportedMatchResults {
  Yes,
  NoBecauseUserIsNotACoachOfMatchTeam,
  NoBecauseIncompleteMatchSettleRequestDoesNotExist,
}

export enum CanDisputeMatchResults {
  Yes,
  NoBecauseUserIsNotACoachOfMatchTeam,
  NoBecauseMatchStatusIsNotDisputeable,
  NoBecauseTheTimeframeToDisputeHasClosed,
}

export enum CanViewMatchDisputeRequest {
  Yes,
  NoBecauseUserIsNotACoachOfMatchTeam,
  NoBecausePendingDisputeDoesNotExist,
}

// consts

const DISPUTE_MATCH_WINDOW = 7

export const MATCH_PAST_STATUSES = [
  MatchStatus.Completed,
  MatchStatus.Reviewed,
  MatchStatus.Quarantined,
  MatchStatus.Forfeited,
  MatchStatus.Bye,
  MatchStatus.Cancelled,
]

export const CANCELLABLE_MATCH_STATUSES: MatchStatus[] = [
  MatchStatus.Scheduled,
  MatchStatus.Open,
  MatchStatus.Live,
  MatchStatus.Quarantined,
  MatchStatus.Bye,
]

export const SCOUTABLE_MATCH_STATUSES: MatchStatus[] = [
  MatchStatus.Bye,
  MatchStatus.Completed,
  MatchStatus.Forfeited,
  MatchStatus.Open,
  MatchStatus.Live,
]

export const ACTIVE_MATCH_STATUSES: MatchStatus[] = [
  MatchStatus.Live,
  MatchStatus.Open,
  MatchStatus.Rescheduling,
]

// utils

export const getIsMatchCheckedIn = (
  checkedInTeamIds: string[],
  match?: {
    matchCheckIns: {
      teamId: string
      status: Maybe<string>
    }[]
  }
): boolean => {
  return (
    match?.matchCheckIns?.some(
      ({ teamId, status }) =>
        checkedInTeamIds.includes(teamId) &&
        status === MatchCheckInStatus.Completed
    ) ?? false
  )
}

export type DisputeMatch = Pick<Match, 'id' | 'status' | 'scheduledStartsAt'>

export const userCanDisputeMatchResults = ({
  canManageMatch,
  match,
}: {
  canManageMatch: boolean
  match: DisputeMatch
}): { result: boolean; message: string; enum: CanDisputeMatchResults } => {
  const today = new Date()
  const dateOfScheduledStartsAt = new Date(match?.scheduledStartsAt ?? '')
  const maxTimeToDispute = dayjs(dateOfScheduledStartsAt).add(
    DISPUTE_MATCH_WINDOW,
    'day'
  )
  const disputeIsWithinTimeframe = dayjs(today).isBetween(
    dayjs(dateOfScheduledStartsAt),
    maxTimeToDispute
  )

  if (disputeIsWithinTimeframe) {
    if (!canManageMatch)
      return {
        result: false,
        message: 'You must be a coach of the team.',
        enum: CanDisputeMatchResults.NoBecauseUserIsNotACoachOfMatchTeam,
      }
    if (
      match?.status !== MatchStatus.Completed &&
      match?.status !== MatchStatus.Forfeited
    )
      return {
        result: false,
        message: 'This match is not disputable.',
        enum: CanDisputeMatchResults.NoBecauseMatchStatusIsNotDisputeable,
      }
    return { result: true, message: '', enum: CanDisputeMatchResults.Yes }
  }
  return {
    result: false,
    message: `Matches must be disputed within ${DISPUTE_MATCH_WINDOW} days of the scheduled date of the match.`,
    enum: CanDisputeMatchResults.NoBecauseTheTimeframeToDisputeHasClosed,
  }
}

export const isMatchQuoteUnquoteCancelled = (
  match: Pick<Match, 'status'>
): boolean =>
  match.status === MatchStatus.Cancelled ||
  match.status === MatchStatus.Rescheduled ||
  match.status === MatchStatus.Rescheduling

export function rejectQuoteUnquoteCancelledMatches<
  T extends Pick<Match, 'status'>
>(x: T[]): T[] {
  return reject(isMatchQuoteUnquoteCancelled, x)
}

export const isMatchCancellableByStatus = (status: MatchStatus): boolean =>
  includes(status, CANCELLABLE_MATCH_STATUSES)

export const isMatchCancellable = (match: Pick<Match, 'status'>): boolean =>
  isMatchCancellableByStatus(match.status)

export const isMatchPast = (match: Pick<Match, 'status'>): boolean => {
  return includes(match.status, MATCH_PAST_STATUSES)
}

export const getFirstPedingMatchDisputeRequest = <
  T extends Pick<MatchDisputeRequest, 'id' | 'status'>
>(
  matchSettleRequests: T[] | null
): T | undefined =>
  head(
    matchSettleRequests?.filter(
      (request) => request.status === MatchDisputeStatus.Pending
    ) ?? []
  )

export const userCanViewMatchDisputeRequest = <
  T extends Pick<MatchDisputeRequest, 'id' | 'status'>
>({
  isTeamCoach,
  match,
}: {
  isTeamCoach: boolean
  match: {
    matchDisputeRequests: T[] | null
  }
}): { canView: CanViewMatchDisputeRequest; request?: T } => {
  if (!isTeamCoach)
    return {
      canView: CanViewMatchDisputeRequest.NoBecauseUserIsNotACoachOfMatchTeam,
    }
  const request = getFirstPedingMatchDisputeRequest(match.matchDisputeRequests)
  if (!request)
    return {
      canView: CanViewMatchDisputeRequest.NoBecausePendingDisputeDoesNotExist,
    }
  return { canView: CanViewMatchDisputeRequest.Yes, request }
}

export const isMatchPreseason = (
  match?: {
    slot: Maybe<{
      phase: Maybe<{
        type: Maybe<PhaseType>
      }>
    }>
    scheduleContext?: Maybe<MatchScheduleContext>
  } | null
): boolean => {
  return (
    match?.slot?.phase?.type === PhaseType.Preseason ||
    match?.scheduleContext === MatchScheduleContext.PreseasonQueueMatch
  )
}

export const isMatchScoutable = (
  matchStatus: MatchStatus | undefined,
  isMemberOfEitherSchoolInMatch: boolean
): boolean => {
  const isNotApartOfEitherSchool = !isMemberOfEitherSchoolInMatch

  return matchStatus
    ? isNotApartOfEitherSchool && SCOUTABLE_MATCH_STATUSES.includes(matchStatus)
    : false
}

export const isMatchActive = (matchStatus: MatchStatus): boolean => {
  return ACTIVE_MATCH_STATUSES.includes(matchStatus)
}

export function getForfeitText(canQueue: boolean): string {
  return canQueue
    ? `We’re sorry to hear that your opponent never showed up! After 
    you submit make sure to head over to Match Queue to find someone else to play!
    `
    : `Thank you for submitting the match results. We’re sorry to 
    hear that your opponent never showed up!
    `
}
