import {
  Maybe,
  ResourceType,
  Role,
  RoleStatus,
  UserRole,
  UserRoleName,
  UserRoleStatusName,
} from '@plvs/graphql'
import { uniq } from 'ramda'
import { ProfileType } from './types'

export const getEntityIdsFromVerifiedRoles = (
  roles: Pick<UserRole, 'resourceType' | 'resourceId' | 'roleName'>[],
  resourceType: ResourceType
): string[] => {
  const resourceIds = roles
    .filter(
      (role) =>
        role?.resourceType === resourceType &&
        (role.roleName === UserRoleName.Player ||
          role.roleName === UserRoleName.Coach)
    )
    .map((role) => role?.resourceId)
  // return unique
  return uniq(resourceIds)
}

export const getAllProfileEntities = (
  roles: Pick<UserRole, 'resourceType' | 'resourceId' | 'roleName'>[]
): ProfileType[] => {
  const allUniqProfileIds = uniq(roles.map((role) => role?.resourceId))
  const allProfiles = roles
    .map((role) => ({
      resourceType: role?.resourceType,
      id: role?.resourceId,
    }))
    .filter((role) => role?.resourceType !== ResourceType.System)

  const uniqueProfiles = allUniqProfileIds
    .map((id) => allProfiles.find((prof) => prof?.id === id))
    .filter((prof) => prof !== undefined) as ProfileType[]
  return uniqueProfiles
}

export const getOnlyOrganizationProfiles = (
  userSchoolRoleStatus:
    | Maybe<
        (Pick<RoleStatus, 'status'> & {
          role: Pick<
            Role,
            'resourceType' | 'resourceId' | 'roleName' | 'userId'
          >
        })[]
      >
    | undefined
): ProfileType[] => {
  // TODO: ID-1836
  // This is a temporary fix.  Ideally this should be addressed by
  // backend endpoint.  This frontend fix has too much business logic
  // that should be calculated on the backend.

  const allOrgPermissions: {
    resourceType: ResourceType
    id: string
    approved: boolean
  }[] = []

  // Sort approved organizations and pending
  userSchoolRoleStatus?.forEach((roleStatus) => {
    if (
      (roleStatus?.role?.roleName === UserRoleName.Player ||
        roleStatus?.role?.roleName === UserRoleName.Coach) &&
      roleStatus?.role.resourceId &&
      roleStatus?.status === UserRoleStatusName.Approved
    ) {
      allOrgPermissions.push({
        resourceType: ResourceType.Organization,
        id: roleStatus?.role.resourceId,
        approved: true,
      })
    }
    if (
      (roleStatus?.role?.roleName === UserRoleName.Player ||
        roleStatus?.role?.roleName === UserRoleName.Coach) &&
      roleStatus?.role.resourceId &&
      roleStatus?.status === UserRoleStatusName.Pending
    ) {
      allOrgPermissions.push({
        resourceType: ResourceType.Organization,
        id: roleStatus?.role.resourceId,
        approved: false,
      })
    }
  })

  return allOrgPermissions
}
