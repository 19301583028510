import React from 'react'
import { UserRequiredFieldsAtAppLaunch } from '@plvs/graphql/generated'
import { InitAppState } from '@plvs/rally/containers/app'
import { Navigate } from 'react-router-dom'
import { Path } from '@plvs/const'
import { useFlags } from 'launchdarkly-react-client-sdk'

interface Props {
  missingFields: string[]
  isParent: boolean | undefined
}

export const MissingFieldsRedirect: React.FC<Props> = ({
  missingFields,
  isParent,
}) => {
  const flags = useFlags()

  const hasIncompleteDemographics =
    flags.userDemographicFields &&
    missingFields.includes(UserRequiredFieldsAtAppLaunch.DemographicData)

  if (hasIncompleteDemographics) {
    const onboardPath = isParent ? Path.ParentSpawnPoint : Path.SpawnPoint
    return <Navigate to={`${onboardPath}/about`} />
  }

  if (missingFields.includes(UserRequiredFieldsAtAppLaunch.Name) && isParent) {
    return <Navigate to={`${Path.ParentSpawnPoint}/about`} />
  }

  return (
    <>
      <InitAppState />
      <Navigate to={Path.MoreDetails} />
    </>
  )
}
