import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'

import dayjs from 'dayjs'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { UserNotificationContentAdapter } from '../notification.types'
import { SystemMessage } from '../templates/SystemMessage'

export const FillTeamRosterAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Fill Team Roster',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <SystemMessage
        IconComponent={
          <NxSpot
            domain="createTeam"
            height="4rem"
            size="small"
            variant="primary"
          />
        }
        message={notification.contentData.message}
        messageDates={notification.contentData.messageDates}
        receivedAt={dayjs(notification.createdAt).toDate()}
        url={notification.contentData.url || undefined}
      />
    )
  },
}
