import React, { Dispatch, SetStateAction } from 'react'
import { MetaseasonFilter } from '@plvs/rally/components/filter/MetaseasonFilter'
import { sortByMaybeStartsAt } from '@plvs/utils'
import { PhaseFilter } from '@plvs/rally/components/filter/PhaseFilter'
import { makeStyles } from '@material-ui/core'

import {
  FilterSlot,
  SlotFilter,
} from '@plvs/rally/components/filter/SlotFilter'
import { SelectedTeam } from '@plvs/rally/components/search/TeamSearch/types'
import { StringParam, useQueryParams } from 'use-query-params'
import {
  LeagueDetailsTab,
  MetaseasonFilterType,
  PhaseFilterType,
  SlotFilterType,
} from './LeagueDetails.types'

export const useStyles = makeStyles((theme) => ({
  metaseasonFilter: {
    marginRight: theme.spacing(2),
  },
  slotFilter: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  selectedMetaseason: MetaseasonFilterType | undefined
  metaseasonOptions: MetaseasonFilterType[]
  setMetaseason: (id: string) => void
  selectedPhase: PhaseFilterType | undefined
  phaseOptions: PhaseFilterType[]
  setPhase: Dispatch<SetStateAction<PhaseFilterType | undefined>>
  selectedSlot: SlotFilterType | undefined
  slotOptions: SlotFilterType[]
  setSlot: Dispatch<SetStateAction<SlotFilterType | undefined>>
  tab: LeagueDetailsTab
  team: SelectedTeam | null
}

export const LeagueDetailsFilters: React.FC<Props> = ({
  selectedMetaseason,
  metaseasonOptions,
  setMetaseason,
  selectedPhase,
  phaseOptions,
  setPhase,
  selectedSlot,
  slotOptions,
  setSlot,
  tab,
  team,
}) => {
  const classes = useStyles()
  const [queryFilters, setQueryFilters] = useQueryParams({
    queryMetaseasonId: StringParam,
    queryPhaseId: StringParam,
    querySlotId: StringParam,
  })

  const showPhaseFilter =
    tab === LeagueDetailsTab.Standings || tab === LeagueDetailsTab.Matches

  const showSlotFilter = tab === LeagueDetailsTab.Matches
  const filterSlots = sortByMaybeStartsAt(slotOptions).map((slot, index) => ({
    ...slot,
    name: `Week ${index + 1}`,
  }))
  const selectedFilterSlot = filterSlots.find((s) => s.id === selectedSlot?.id)

  const handleSetMetaseason = (metaseasonId: string): void => {
    setMetaseason(metaseasonId)
    setQueryFilters({ ...queryFilters, queryMetaseasonId: metaseasonId })
  }

  const handleSetPhase = (phaseId: string): void => {
    const newPhase = phaseOptions.find((p) => p.id === phaseId)
    setPhase(newPhase)
    setQueryFilters({ ...queryFilters, queryPhaseId: newPhase?.id })
  }

  const handleSetSlot = (slotId: string): void => {
    const newSlot = filterSlots.find((s) => s.id === slotId)
    setSlot(newSlot)
    setQueryFilters({ ...queryFilters, querySlotId: newSlot?.id })
  }

  return (
    <>
      <MetaseasonFilter
        className={classes.metaseasonFilter}
        metaseason={selectedMetaseason}
        metaseasons={metaseasonOptions}
        setMetaseason={handleSetMetaseason}
      />
      {showPhaseFilter && (
        <PhaseFilter
          phase={selectedPhase}
          phases={phaseOptions}
          setPhase={handleSetPhase}
        />
      )}
      {showSlotFilter && (
        <SlotFilter
          className={classes.slotFilter}
          disabled={!!team}
          setSlot={handleSetSlot}
          slot={selectedFilterSlot as FilterSlot}
          slots={filterSlots}
        />
      )}
    </>
  )
}
