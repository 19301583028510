import React from 'react'
import { Match } from '@g-loot/react-tournament-brackets'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { NxTypography } from '@playvs-inc/nexus-components'
import { BRACKET_DATE_TIME_FORMAT } from '@plvs/checkpoint/pages/league/leaguePageHelpers'
import dayjs from '@plvs/respawn/init/dayjs'
import { NxBracketTeam } from './BracketTeam'

const useStyles = makeStyles(() => ({
  match: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    color: '#000',
    width: '363px',
    minHeight: '158px',
  },
  matchHeader: { textAlign: 'end' },
  matchFooter: { textAlign: 'end' },
}))

export interface BracketMatchProps {
  match: Match
  onMatchClick
  onPartyClick
  onMouseEnter
  onMouseLeave
  topParty
  bottomParty
  topWon
  bottomWon
  topHovered
  bottomHovered
  topText
  bottomText
  connectorColor
  computedStyles
  teamNameFallback
  resultFallback
  esportColor
}

export function NxBracketMatch({
  match,
  onMouseEnter,
  onMouseLeave,
  topParty,
  bottomParty,
  topHovered,
  bottomHovered,
  esportColor,
}: BracketMatchProps): React.ReactElement {
  const classes = useStyles()

  return (
    <Box className={classes.match}>
      <Box className={classes.matchHeader}>
        <NxTypography variant="body4">{match.name}</NxTypography>
      </Box>
      <Box mb={1}>
        <NxBracketTeam
          esportColor={esportColor}
          hovered={topHovered}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          party={topParty}
        />
      </Box>
      <Box>
        <NxBracketTeam
          esportColor={esportColor}
          hovered={bottomHovered}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          party={bottomParty}
        />
      </Box>
      <Box className={classes.matchFooter}>
        <NxTypography variant="body4">
          {match.startTime
            ? dayjs(match.startTime).format(BRACKET_DATE_TIME_FORMAT)
            : 'TBD'}
        </NxTypography>
      </Box>
    </Box>
  )
}
