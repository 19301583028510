import React from 'react'

import {
  PersonaImageVariant,
  NxEsportBanner,
} from '@playvs-inc/nexus-components'

import { EsportSlug } from '@plvs/graphql'
import { MatchFaceoffContainer } from '@plvs/rally/containers/match'
import { Box } from '@plvs/respawn/features/layout'
import { HeroMatch } from '@plvs/respawn/features/match-lobby/types/Lobby.types'

interface MatchHeroProps {
  isScrimmage: boolean
  esportSlug: EsportSlug | null | undefined
  match: HeroMatch
  breadcrumbHref?: string
}

export const MatchHero: React.FC<MatchHeroProps> = ({
  isScrimmage,
  esportSlug,
  match,
  breadcrumbHref,
}) => {
  const container = (
    <MatchFaceoffContainer
      breadcrumbHref={breadcrumbHref}
      esportSlug={esportSlug}
      match={match}
      size="large"
    />
  )

  return (
    <>
      {esportSlug && (
        <Box mb={2} pr={2} pt={2}>
          <NxEsportBanner
            additionalContent={
              <Box ml={2} width="100%">
                {container}
              </Box>
            }
            esportSlug={esportSlug}
            size="small"
            useOverlayImage
            variant={
              isScrimmage
                ? PersonaImageVariant.Scrimmage
                : PersonaImageVariant.Match
            }
          />
        </Box>
      )}
    </>
  )
}
