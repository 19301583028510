import React from 'react'
import { NxEmptyState, NxButton } from '@playvs-inc/nexus-components'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'

import { Path } from '@plvs/const'

interface ScrimmagesEmptyProps {
  subtitle?: string
}

export const ScrimmagesEmpty: React.FC<ScrimmagesEmptyProps> = ({
  subtitle,
}) => {
  return (
    <NxEmptyState
      cta={
        <NxButton
          href={Path.CreateScrimmage}
          label="Create a Scrimmage"
          variant="secondary"
        />
      }
      isStandalone
      spot={<NxSpot domain="stadium" size="large" variant="secondary" />}
      subtitle={subtitle}
      title="No Scrimmages"
    />
  )
}
