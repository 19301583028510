import { Box, makeStyles, TextField } from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import { Autocomplete, AutocompleteInputChangeReason } from '@material-ui/lab'
import { DEFAULT_DEBOUNCE_TIME } from '@plvs/const'
import {
  SchoolSearchResult,
  SchoolType,
  useOnboardingSchoolSearchQuery,
} from '@plvs/graphql/generated/graphql'
import { LoadingSpinner, NxTypography } from '@playvs-inc/nexus-components'
import { schoolSearchResultSelected } from '@plvs/respawn/features/analytics/segment/segment'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useDebounce } from 'use-debounce'
import { NxTypographyVariant } from '@playvs-inc/nexus-theme'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'
import { SearchResultItemRenderer } from './OnboardSchoolSelect.SearchResultItemRenderer'
import {
  ADD_SCHOOL_PLACEHOLDER,
  SchoolId,
  SchoolName,
} from './OnboardSchoolSelect.types'

const useStyles = makeStyles((theme) => ({
  schoolSearch: {
    backgroundColor: theme.palette.background.paper,
    width: '100% !important',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none !important',
    },
  },
  loadingIcon: {
    width: '1em',
    height: '1em',
  },
}))

export const SchoolSearchForm: React.FC<{
  province: string | null
  onSchoolSelected: (schoolId: SchoolId, schoolName: SchoolName) => void
  onSuggestSchool: () => void
  onClear: () => void
  schoolType: SchoolType
  titleTypographyVariant?: NxTypographyVariant
}> = ({
  province,
  onSchoolSelected,
  onSuggestSchool,
  onClear,
  schoolType,
  titleTypographyVariant = 'body1',
}) => {
  const classes = useStyles()
  const { data: onboardingContext } = useOnboardingContext()

  // State vars
  const [query, setQuery] = useState<string>('')
  const [debouncedQuery] = useDebounce(query, DEFAULT_DEBOUNCE_TIME)

  const debouncingInProgress = query !== '' && query !== debouncedQuery

  const [
    selectedSchool,
    setSelectedSchool,
  ] = useState<SchoolSearchResult | null>(
    onboardingContext.selectedSchool as SchoolSearchResult
  )

  // Query hooks
  const {
    data: schoolData,
    loading: searchLoading,
  } = useOnboardingSchoolSearchQuery({
    variables: {
      query: debouncedQuery,
      state: province ?? '',
      type: schoolType,
    },
    fetchPolicy: 'network-only',
    skip: !province || !debouncedQuery || !schoolType,
  })

  const schoolResults = [...(schoolData?.searchSchools?.results ?? [])]
  if (schoolData) {
    schoolResults.push(ADD_SCHOOL_PLACEHOLDER)
  }

  // This side effect triggers the 'onSchoolSelected()' in the consumer after
  // the data is loaded.
  useEffect(() => {
    if (!selectedSchool) {
      return
    }

    // Don't fire event if we selected the placeholder for addschool
    if (selectedSchool.schoolId === ADD_SCHOOL_PLACEHOLDER.schoolId) {
      return
    }

    // Note: converting SchoolId of a schoolSearch results from elastic search into
    // a school ID for lookup
    const schoolId: SchoolId = (selectedSchool.schoolId as unknown) as SchoolId
    const schoolName: SchoolName = (selectedSchool.name as unknown) as SchoolName
    const esWeight: number = (selectedSchool.searchScore as unknown) as number
    onSchoolSelected(schoolId, schoolName)
    const searchIndexRange = schoolResults.indexOf(selectedSchool)
    schoolSearchResultSelected({
      initialSearchTerm: query,
      searchIndexRange,
      esWeight,
    })
  }, [selectedSchool, onSchoolSelected])

  const onSchoolSearchChange = (
    event: ChangeEvent<unknown>,
    value: string,
    _reason: AutocompleteInputChangeReason
  ): void => {
    // value is the value in the combo box, which is the school name.
    if (!!event && value === '') {
      // clear out the query to prevent the placeholder from replacing the
      // value.
      setQuery('')
      onSuggestSchool()
      return
    }

    // Since this is a controlled component, we receive a null event, when
    // 'value' is force changed by the parent component state. In this case
    // we want to clear the query.
    if (!event) {
      setQuery('')
      return
    }

    // Only update query on text type event.
    if ((event.target as HTMLInputElement).type !== 'text') {
      return
    }

    setQuery(value)
  }

  return (
    <>
      <Box py={1} width="100%">
        <NxTypography variant={titleTypographyVariant}>
          Search Schools
        </NxTypography>
      </Box>
      <Autocomplete
        className={classes.schoolSearch}
        data-testid="OnboardSchoolSelect_SchoolSearch"
        disabled={!province}
        // Note: This will initially display a warning on loading from save value
        // `Material-UI: The value provided to Autocomplete is invalid.`
        // because the search results have not yet loaded.  This is expected.
        filterOptions={(): SchoolSearchResult[] => schoolResults}
        getOptionLabel={(option: SchoolSearchResult | null): string =>
          option?.name ?? ''
        }
        loading={searchLoading}
        noOptionsText="Please search for your school name above."
        onChange={(evt, value): void => {
          // User cleared input
          if (selectedSchool && value === null) {
            setQuery('')
            onClear()
          }

          setSelectedSchool(value)
        }}
        onInputChange={onSchoolSearchChange}
        options={schoolResults}
        renderInput={(params): React.ReactNode => {
          Object.assign(params.InputProps, {
            'data-testid': 'Onboard_Search_Input',
            startAdornment:
              searchLoading || debouncingInProgress ? (
                <>
                  <LoadingSpinner size="small" />
                  &nbsp;
                </>
              ) : (
                <SearchIcon />
              ),
          })

          return (
            <TextField {...params} placeholder="Search" variant="outlined" />
          )
        }}
        renderOption={SearchResultItemRenderer}
        value={selectedSchool}
      />
    </>
  )
}
