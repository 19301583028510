import { Esport } from '@plvs/graphql/generated'
import { EsportSlug } from '@plvs/graphql/types'
import { uniqBy } from 'ramda'

type ResourceEsport = Pick<Esport, 'id' | 'name' | 'slug'>

export const getUniqueEsportsById = (
  esports: ResourceEsport[]
): ResourceEsport[] => uniqBy((esport) => esport.id, esports)

export const getAvailableEsportSlugsAndNames = (
  esports: ResourceEsport[]
): {
  allAvailableEsportsSlugs: EsportSlug[]
  allAvailableEsportsNames: (string | null)[]
} => {
  const allAvailableEsportsSlugs: EsportSlug[] = []
  const allAvailableEsportsNames: (string | null)[] = []

  esports.forEach((esport) => {
    allAvailableEsportsSlugs.push(esport.slug)
    allAvailableEsportsNames.push(esport.name)
  })

  return { allAvailableEsportsSlugs, allAvailableEsportsNames }
}
