import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { cleanGraphQLError } from '@plvs/utils'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { NxTypography, NxButton, NxModal } from '@playvs-inc/nexus-components'
import { RosterRowData } from '@plvs/respawn/features/roster/modal/RosterRow'
import { RosterRowGroup } from '@plvs/respawn/features/roster/modal/RosterRowGroup'
import { Position } from './types'

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
  },
  content: {
    '& .MuiDialogContent-root': {
      color: theme.palette.ColorTextBase,
    },
    maxHeight: '796px',
    overflow: 'auto',
  },
  confirmationText: {
    marginLeft: theme.spacing(2),
  },
}))

export type RosterRowDataSections = Array<{
  label: string
  list: RosterRowData[]
}>

export interface RepositionPlayersInTeamModalProps {
  allRosterRowMembers: RosterRowDataSections
  error: Error | null | undefined
  isBench?: boolean
  isSubstitute: boolean
  loading: boolean
  onChange(input: string): void
  onClick(): Promise<void>
  onClose(): void
  open: boolean
  rosterRowStarters: RosterRowData[]
  selectedPosition: Position
}

export const RepositionPlayersInTeamModal: React.FC<
  RepositionPlayersInTeamModalProps
> = ({
  allRosterRowMembers,
  error,
  isBench,
  isSubstitute,
  loading,
  onChange,
  onClick,
  onClose,
  open,
  rosterRowStarters,
  selectedPosition,
}) => {
  const classes = useStyles()
  const errorMessage = cleanGraphQLError(error?.message ?? '')

  const movePlayerText = isBench ? 'Move to Roster' : 'Change Position'
  const selectionInfoText = isBench
    ? 'Select a roster position to move player to'
    : 'Select a player from the list'

  return (
    <NxModal
      actions={
        <>
          <NxButton label="Cancel" onClick={onClose} variant="text" />
          <NxButton
            disabled={loading}
            label="Done"
            onClick={onClick}
            variant="primary"
          />
        </>
      }
      onClose={onClose}
      open={open}
      showTopRightClose
      size="small"
      subtitle={selectionInfoText}
      title={movePlayerText}
    >
      <>
        {errorMessage && (
          <Box py={2}>
            <Banner
              subtitle={errorMessage}
              title="Unable to change positions"
              type={BannerType.Error}
            />
          </Box>
        )}
        {isSubstitute ? (
          <RosterRowGroup
            onChange={onChange}
            rowEntries={rosterRowStarters}
            selectedId={selectedPosition.id}
          />
        ) : (
          <>
            {allRosterRowMembers.map(({ label, list }) => {
              return (
                <Box key={label} pb={2}>
                  <NxTypography className={classes.label} variant="overline">
                    {label}
                  </NxTypography>
                  <RosterRowGroup
                    onChange={onChange}
                    py={1}
                    rowEntries={list}
                    selectedId={selectedPosition.id}
                  />
                </Box>
              )
            })}
          </>
        )}
      </>
    </NxModal>
  )
}
