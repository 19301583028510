/* istanbul ignore file */
import React, { useMemo } from 'react'
import { yupGradYearRequired, yupString, getGradYearOptions } from '@plvs/utils'
import { makeStyles } from '@material-ui/core'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import {
  NxButton,
  NxSelectOption,
  NxTextInput,
} from '@playvs-inc/nexus-components'
import { NxSelectController } from '@plvs/respawn/features/form/NxSelectController'

import { useNavigate, useParams } from 'react-router-dom'
import { useSetChildAccountSettingsMutation } from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { ChildOnboardPath } from '@plvs/const'
import { getNextPath } from './helpers'
import { useOnboardingContext } from '../onboard/OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  gradWrapper: {
    width: '100%',
    '& label': {
      color: theme.palette.ColorTextAlt2,
    },
  },
  gradYearSelect: {
    backgroundColor: theme.palette.common.white,
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

interface ChildInfoInput {
  firstName: string
  lastName: string
  graduationYear: number
}

const ChildInfoSchema = yup.object().shape({
  firstName: yupString.required(),
  graduationYear: yupGradYearRequired,
  lastName: yupString.required(),
})

export const OnboardChildInfo: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams<{ childId: string | undefined }>()

  const { control, handleSubmit, register, errors } = useForm<ChildInfoInput>({
    resolver: yupResolver<ChildInfoInput>(ChildInfoSchema),
  })

  const [setChildAccountSettings, { loading }] =
    useSetChildAccountSettingsMutation()

  const { assign, data: onboardingData } = useOnboardingContext()

  const onSubmit = async (data: ChildInfoInput): Promise<void> => {
    assign({ graduationYear: data.graduationYear })
    try {
      const childId = onboardingData.childId || params.childId
      if (!childId) {
        enqueueSnackbar('No child ID found', { variant: 'error' })
        enqueueSnackbar('No child ID found', { variant: 'error' })
        return
      }

      await setChildAccountSettings({
        variables: {
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
          userId: childId as string,
        },
      })

      navigate(
        getNextPath({
          childId: params.childId,
          path: ChildOnboardPath.ChildEsportInterests,
        })
      )
    } catch (error) {
      enqueueSnackbar('Error setting child info', { variant: 'error' })
    }
  }

  const gradYearOptions = useMemo(() => getGradYearOptions(), [])

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <QuestionnaireCard
        childWidth={416}
        subtitle="Tell us a bit more about your child to set up their account"
        title="Child Information"
      >
        <NxTextInput
          ref={register}
          data-testid="firstName"
          fullWidth
          label="Your Child’s First Name"
          name="firstName"
          required
          style={{ height: '56px' }}
          variant={errors?.firstName ? 'error' : 'default'}
        />
        <NxTextInput
          ref={register}
          data-testid="lastName"
          fullWidth
          label="Your Child’s Last Name"
          name="lastName"
          required
          style={{ height: '56px' }}
          variant={errors?.lastName ? 'error' : 'default'}
        />
        <div className={classes.gradWrapper}>
          <NxSelectController
            className={classes.gradYearSelect}
            control={control}
            data-testid="graduationYear"
            fullWidth
            label="8th Grade Graduation Year"
            name="graduationYear"
            required
          >
            {gradYearOptions.map((x) => (
              <NxSelectOption key={x} value={x}>
                {x}
              </NxSelectOption>
            ))}
          </NxSelectController>
        </div>
        <NxButton
          className={classes.button}
          data-testid="submit"
          disabled={loading}
          fullWidth
          label="Continue"
          type="submit"
          variant="primary"
        >
          Continue
        </NxButton>
      </QuestionnaireCard>
    </form>
  )
}
