/* istanbul ignore file */
// Provider component is not tested.  Components using it are tested instead

import React from 'react'
import { uniqBy } from 'ramda'
import { SeasonStatus, useGetLeagueSeasonsForFilterQuery } from '@plvs/graphql'
import {
  RelativeTiming,
  mapMetaseasonsForFilters,
  MinimalMetaseason,
} from '@plvs/utils'
import { FilterCacheContext } from '@plvs/respawn/containers/filter/FilterCacheProvider'

import { LeagueFilterContext } from '@plvs/respawn/features/filters/types'
import { Provider } from '@plvs/respawn/containers/filter/league/context'

interface AllMetaseasonsProps {
  setDefaultMetaseason?: (
    metaseasons: MinimalMetaseason[]
  ) => MinimalMetaseason | undefined
  leagueId: string
}

const FALL_2022_CUTOFF_DATE = new Date('2022-08-30 00:00:00+00')

const defaultSetMetaseason = (
  metaseasons: MinimalMetaseason[]
): MinimalMetaseason | undefined =>
  metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Past)

export const AllMetaseasons: React.FC<AllMetaseasonsProps> = ({
  children,
  setDefaultMetaseason = defaultSetMetaseason,
  leagueId,
}) => {
  const {
    metaseasonId,
    setCompetitionModel,
    setLeagueId,
    setMetaseasonId,
    setTeamId,
    setPhaseId,
  } = React.useContext(FilterCacheContext)

  const {
    data: nonLoggedInMetaseasonData,
    loading: loadingNonLoggedInUserMetaseasons,
    error,
  } = useGetLeagueSeasonsForFilterQuery({
    variables: {
      leagueId,
    },
  })

  const value: LeagueFilterContext = {
    leagues: [],
    metaseasons: [],
    teams: [],
    loading: loadingNonLoggedInUserMetaseasons,
    error,
    setMetaseason: setMetaseasonId,
    setLeague: setLeagueId,
    setTeam: setTeamId,
    phases: [],
    setCompetitionModel,
    setPhase: setPhaseId,
    defaultPhases: [],
  }
  if (!loadingNonLoggedInUserMetaseasons) {
    const flatMetaseasons =
      nonLoggedInMetaseasonData?.league?.seasons
        ?.filter((s) => s.status === SeasonStatus.Active)
        .flatMap((s) => s.metaseason)
        .filter((m) => {
          const startsAtDate = m?.startsAt ? new Date(m.startsAt) : undefined
          return startsAtDate
            ? startsAtDate?.getTime() > FALL_2022_CUTOFF_DATE.getTime()
            : false
        }) ?? []
    const metaseasons = uniqBy(
      (m) => m.id,
      mapMetaseasonsForFilters({
        metaseasons:
          flatMetaseasons.filter(
            (e): e is Exclude<typeof e, null> => e !== null
          ) ?? [],
      })
    )
    const metaseason =
      metaseasons.find((ms) => ms.id === metaseasonId) ||
      setDefaultMetaseason(metaseasons)

    value.metaseasons = metaseasons
    value.metaseason = metaseason
  }

  return <Provider value={value}>{children}</Provider>
}
