import { forEachObjIndexed, head } from 'ramda'
import { noop } from 'ramda-adjunct'
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import {
  sortStartsAtClosestToNow,
  useAutoskipQuery,
  RelativeTiming,
  MinimalMetaseason,
  findPromotedMetaseason,
  mapMetaseasonTimingToRelative,
  isCoachAtOrganization,
} from '@plvs/utils'
import {
  useCompetitionIntervalsForOrganizationTeamManagementQuery,
  IntervalTense,
} from '@plvs/graphql'

import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  getCompetedEndDateFromCompetitionInterval,
  getDefaultCompetitionInterval,
} from '@plvs/respawn/features/manage-teams/helpers'
import {
  CompetitionIntervalWithMetaseasonsForOrg,
  Interval,
  IntervalWithEnrolledSeasons,
} from '@plvs/respawn/features/manage-teams/types'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { useEnrollment } from '@plvs/respawn/containers/enrollment/useEnrollment'
import {
  EnrollmentStatus,
  UseEnrollmentReturn,
} from '@plvs/respawn/containers/enrollment/types'
import { useSchoolLeagueInfoContext } from '../filter/league/hooks'

dayjs.extend(tz)

type Metaseason = MinimalMetaseason & { enrollmentEndsAt?: string }

export type ManageTeamContext = Pick<UseEnrollmentReturn, 'enrolledTeamIds'> & {
  addRefetchEnrollmentFn(key: string, fn: () => Promise<void>): void
  refetchEnrollment(): Promise<void>
  metaseason: MinimalMetaseason | undefined
  metaseasons: MinimalMetaseason[]
  promotedMetaseason: MinimalMetaseason | undefined
  loading: boolean
  organizationId: string
  isCoachForOrganization: boolean
  competitionIntervals: Interval[]
  setCompetitionIntervalPosition(input: number): void
  currentCompetitionIntervalPosition: number | undefined
  setDefaultCompetitionInterval(): void
  competitionInterval: Interval | undefined
  handleTabChange(e: React.ChangeEvent<unknown>, value: number): void
  tab: number
}

// NOTE: Leaving the below in even though not being used. Potentially
// will bring back depending on how many refetches need to take place
// once school year is implemented.
const refetchEnrollmentFns: Record<string, () => Promise<void>> = {}
const refetchEnrollment = async (): Promise<void> => {
  forEachObjIndexed((fn) => {
    fn()
  }, refetchEnrollmentFns)
}
const addRefetchEnrollmentFn = (key: string, fn: () => Promise<void>): void => {
  refetchEnrollmentFns[key] = fn
}

const getLatestDateFn = (dates: (Date | undefined)[]): Date | undefined => {
  if (!dates?.length) {
    return undefined
  }
  return dates.reduce((date1, date2) => {
    if (!date1) {
      return date2
    }
    if (!date2) {
      return date1
    }
    return date1 > date2 ? date1 : date2
  })
}

const getCurrentInterval = ({
  competitionIntervals,
}: {
  competitionIntervals: CompetitionIntervalWithMetaseasonsForOrg[]
}): CompetitionIntervalWithMetaseasonsForOrg | undefined => {
  return competitionIntervals.find(
    (competitionInterval) =>
      competitionInterval?.interval?.tense === IntervalTense.Current
  )
}

const setDefaultMetaseason = ({
  metaseasons,
}: {
  metaseasons: MinimalMetaseason[]
}): MinimalMetaseason | undefined => {
  const presentMetaseasons = metaseasons.filter(
    (ms) => ms.timing === RelativeTiming.Present
  )
  const closestPresentMetaseason = sortStartsAtClosestToNow({
    list: presentMetaseasons,
  })
  return (
    closestPresentMetaseason ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Past)
  )
}

const resetMetaseasonTabsBasedOnCurrent = ({
  defaultMetaseasons,
}: {
  defaultMetaseasons: MinimalMetaseason[]
}): { tabValue: number; metaseason: MinimalMetaseason | undefined } => {
  if (!defaultMetaseasons.length) {
    return {
      tabValue: 0,
      metaseason: undefined,
    }
  }
  const sortedMetaseasons = defaultMetaseasons.sort(
    (a, b) => dayjs(a?.startsAt).unix() - dayjs(b?.startsAt).unix()
  )
  const defaultMetaseason = setDefaultMetaseason({
    metaseasons: sortedMetaseasons,
  })
  const tabValue = sortedMetaseasons
    .map((sm) => sm.id)
    .indexOf(defaultMetaseason?.id ?? '')

  return {
    tabValue,
    metaseason: defaultMetaseason,
  }
}

const manageTeamsContext = createContext<ManageTeamContext>({
  addRefetchEnrollmentFn,
  enrolledTeamIds: [],
  refetchEnrollment,
  metaseason: undefined,
  promotedMetaseason: undefined,
  metaseasons: [],
  loading: false,
  organizationId: '',
  isCoachForOrganization: false,
  setCompetitionIntervalPosition: noop,
  competitionIntervals: [],
  currentCompetitionIntervalPosition: undefined,
  setDefaultCompetitionInterval: noop,
  competitionInterval: undefined,
  handleTabChange: noop,
  tab: 0,
})

export const useManageTeamsContext = (): ManageTeamContext => {
  return useContext(manageTeamsContext)
}

export const ManageTeamsProvider: React.FC<{ children: React.ReactChild }> = ({
  children,
}) => {
  // state

  const [competitionIntervalPosition, setCompetitionIntervalPosition] =
    useState<number>()
  const [defaultInterval, setDefaultInterval] =
    useState<CompetitionIntervalWithMetaseasonsForOrg>()
  const [currentInterval, setCurrentInterval] =
    useState<CompetitionIntervalWithMetaseasonsForOrg>()
  const [hasEnrolledTeams, setHasEnrolledTeams] = useState<boolean>()

  const [registrationEndDate, setRegistrationEndDate] = useState<Date>()
  const [lastSeasonEndDate, setLastSeasonEndDate] = useState<Date | null>()

  const [tab, setTab] = useState<number>(0)
  const {
    selectedEntityId,
    organizationIds,
    roles,
    loading: profileLoading,
  } = useProfileContext()

  // NOTE: for V1 filtering out any metaseason from competition interval that is not included in eligibleMetaseasons. This will
  // change in PRO-3384.

  const {
    setMetaseason,
    metaseasons: eligibleMetaseasons,
    metaseason,
  } = useSchoolLeagueInfoContext()
  const organizationId = (selectedEntityId || head(organizationIds)) ?? ''

  const { data, loading: competitionIntervalsLoading } = useAutoskipQuery(
    useCompetitionIntervalsForOrganizationTeamManagementQuery,
    {
      variables: {
        input: {
          organizationId,
        },
      },
      skip: profileLoading || !organizationId,
    }
  )
  const eligibleMetaseasonsIds = eligibleMetaseasons.map((em) => em?.id)
  const results: CompetitionIntervalWithMetaseasonsForOrg[] = (
    data?.competitionIntervalsForOrganization.results ?? []
  ).map((sortedResult, i) => {
    const interval: IntervalWithEnrolledSeasons = {
      ...sortedResult.interval,
      position: i,
    }
    const metaseasons: Metaseason[] =
      sortedResult.metaseasonsForOrganization.map((metaseason) => {
        const minMetaseason = mapMetaseasonTimingToRelative(metaseason)
        return {
          ...minMetaseason,
          enrollmentEndsAt: metaseason.enrollmentEndsAt ?? undefined,
        }
      })
    const eligibleMetaseasons = metaseasons.filter((metaseason) =>
      eligibleMetaseasonsIds.includes(metaseason.id)
    )
    return {
      interval,
      metaseasonsForOrganization: eligibleMetaseasons,
    }
  })

  const competitionIntervals = results.map((result) => result.interval)

  useEffect(() => {
    if (!currentInterval && !!results.length) {
      const interval = getCurrentInterval({ competitionIntervals: results })
      if (interval) {
        setCurrentInterval(interval)
      }
    }
  }, [results])
  // NOTE: Currently connecting into useSchoolLeagueInfo for V1. This will change once the league filter
  // becomes multi-select. Story PRO-3386.
  const isCoachForOrganization = isCoachAtOrganization(roles, [organizationId])
  const {
    loading: enrollmentLoading,
    refreshPlayers,
    ...rest
  } = useEnrollment({
    schoolId: organizationId,
    isCoach: isCoachForOrganization,
    metaseasonId: metaseason?.id ?? '',
  })
  const selectedCompetitionInterval =
    results.find(
      (competitionInterval) =>
        competitionInterval.interval.position === competitionIntervalPosition
    ) || currentInterval

  const currentCompetitionIntervalPosition =
    competitionIntervalPosition ||
    selectedCompetitionInterval?.interval.position
  const metaseasons =
    selectedCompetitionInterval?.metaseasonsForOrganization ?? []

  const indexOfSelectedMetaseason = metaseasons
    .map((m) => m.id)
    .indexOf(metaseason?.id ?? '')
  const tabValue = indexOfSelectedMetaseason < 0 ? 0 : indexOfSelectedMetaseason

  const promotedMetaseason = findPromotedMetaseason({ metaseasons })

  // useEffects

  useEffect(() => {
    setTab(tabValue)
  }, [tabValue])

  useEffect(() => {
    if (
      !enrollmentLoading &&
      rest.status !== EnrollmentStatus.NotInitialized &&
      hasEnrolledTeams === undefined
    ) {
      setHasEnrolledTeams(rest.hasEnrolledTeams)
    }
  }, [enrollmentLoading, rest, currentInterval])

  // helper functions
  const navigateToInterval = useCallback(
    (position: number, metaseasonsForTabs: MinimalMetaseason[]): void => {
      if (metaseasonsForTabs) {
        const { tabValue: newTabValue, metaseason: newMetaseason } =
          resetMetaseasonTabsBasedOnCurrent({
            defaultMetaseasons: metaseasonsForTabs,
          })
        setCompetitionIntervalPosition(position)
        setTab(newTabValue)
        setMetaseason(newMetaseason?.id ?? '')
      }
    },
    []
  )

  const setCurrentCompetitionInterval = useCallback((): void => {
    const currentPosition = currentInterval?.interval.position
    if (currentPosition !== undefined) {
      navigateToInterval(
        currentPosition,
        currentInterval?.metaseasonsForOrganization ?? []
      )
    }
  }, [competitionIntervals])

  const handleCompetitionIntervalChange = (
    newIntervalPosition: number
  ): void => {
    navigateToInterval(
      newIntervalPosition,
      results[newIntervalPosition]?.metaseasonsForOrganization ?? []
    )
  }

  useEffect(() => {
    if (!registrationEndDate && !competitionIntervalsLoading) {
      const currentEnrolledDates =
        currentInterval?.metaseasonsForOrganization
          .filter((metaseason) => !!metaseason.enrollmentEndsAt)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map((metaseason) => new Date(metaseason.enrollmentEndsAt!)) ?? []
      if (currentEnrolledDates) {
        const regEndDate = getLatestDateFn(currentEnrolledDates)

        if (regEndDate) {
          setRegistrationEndDate(regEndDate)
        }
      }
    }
    if (!lastSeasonEndDate && !competitionIntervalsLoading) {
      const lastEndsAtDate =
        getCompetedEndDateFromCompetitionInterval(currentInterval)
      setLastSeasonEndDate(lastEndsAtDate)
    }
  }, [metaseason, organizationId, competitionIntervals])

  useEffect(() => {
    const hasNoCurrentMetaseason =
      currentInterval && !currentInterval.metaseasonsForOrganization.length
    const areDatesAvailable =
      !!registrationEndDate && lastSeasonEndDate !== undefined
    if (
      (hasEnrolledTeams !== undefined && areDatesAvailable) ||
      hasNoCurrentMetaseason
    ) {
      const competitionInterval = getDefaultCompetitionInterval({
        intervals: results,
        currentRegEndDate: registrationEndDate!,
        participatingInCurrentMetaseason: lastSeasonEndDate
          ? lastSeasonEndDate > new Date()
          : false,
      })
      if (competitionInterval && competitionInterval !== defaultInterval) {
        setDefaultInterval(competitionInterval)
        navigateToInterval(
          competitionInterval.interval.position,
          competitionInterval.metaseasonsForOrganization
        )
      }
    }
  }, [
    currentInterval,
    lastSeasonEndDate,
    registrationEndDate,
    hasEnrolledTeams,
  ])

  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    newTab: number
  ): void => {
    const updatedMetaseason = metaseasons[newTab]
    setTab(newTab)
    setMetaseason(updatedMetaseason.id)
  }

  if (enrollmentLoading) {
    return null
  }

  return (
    <WaitTillLoaded
      loading={
        enrollmentLoading && profileLoading && competitionIntervalsLoading
      }
    >
      <manageTeamsContext.Provider
        value={{
          addRefetchEnrollmentFn,
          refetchEnrollment: refreshPlayers,
          metaseason,
          promotedMetaseason,
          loading: profileLoading || competitionIntervalsLoading,
          metaseasons,
          organizationId,
          isCoachForOrganization,
          competitionIntervals,
          competitionInterval: selectedCompetitionInterval?.interval,
          setDefaultCompetitionInterval: setCurrentCompetitionInterval,
          currentCompetitionIntervalPosition,
          setCompetitionIntervalPosition: handleCompetitionIntervalChange,
          handleTabChange,
          tab,
          ...rest,
        }}
      >
        {children}
      </manageTeamsContext.Provider>
    </WaitTillLoaded>
  )
}
