import {
  AuthComponentProps,
  AuthComponentReturn,
} from '../types/authRenderController.types'

export const determineAuthRender = ({
  location,
}: AuthComponentProps): AuthComponentReturn => {
  const isInStadium =
    location.includes('stadium') || location.includes('localhost:3009')

  if (isInStadium) {
    return {
      shouldRenderUsernameField: true,
      shouldRenderDOBField: false,
      shouldRenderOAuth: false,
      shouldRenderAgeGate: true,
      shouldRenderParentFields: true,
      shouldRedirectToParentSignup: false,
      shouldShowFullDemographicForm: true,
      shouldShowPlayerSignup: true,
    }
  }

  return {
    shouldRenderUsernameField: false,
    shouldRenderDOBField: false,
    shouldRenderAgeGate: true,
    shouldRenderOAuth: true,
    shouldRenderParentFields: true,
    shouldRedirectToParentSignup: true,
    shouldShowFullDemographicForm: false,
    shouldShowPlayerSignup: false,
  }
}
