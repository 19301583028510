import { Box, makeStyles, useTheme } from '@material-ui/core'
import { NASEF_QUERY_PARAM, stateNames } from '@plvs/const'
import {
  SchoolType,
  useGetMyAccountDetailsQuery,
} from '@plvs/graphql/generated/graphql'
import { ApolloError } from '@apollo/client'
import { noop } from 'ramda-adjunct'
import { openIntercom } from '@plvs/respawn/features/analytics/intercom/intercom'
import { OnboardSelectField } from '@plvs/rally/components/onboard/OnboardSelectField'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { cleanGraphQLError } from '@plvs/utils'
import React, { ChangeEvent, useState } from 'react'
import { NxTypography } from '@playvs-inc/nexus-components'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'
import { SchoolPreview } from './OnboardSchoolSelect.SchoolPreview'
import { SchoolSearchForm } from './OnboardSchoolSelect.SchoolSearchForm'
import { SchoolId, SchoolName } from './OnboardSchoolSelect.types'
import { useOnboardAdditonalContactController } from '../OnboardAdditionalContact.controller'
import { handlePostSchoolSelect, handleStateSelect } from './utils'
import { CoachCallout } from './CoachCallout'
import { OnboardErrorBanner } from '../components/OnboardErrorBanner'

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  schoolName: {
    marginTop: theme.spacing(1),
  },
  schoolLocation: {
    marginTop: theme.spacing(0.5),
  },
}))

interface CustomError {
  message: string
}

const SchoolTypePrettyName = {
  [SchoolType.HighSchool]: 'high schools',
  [SchoolType.College]: 'colleges',
  [SchoolType.MiddleSchool]: 'middle schools',
}

export const OnboardSchoolSelect: React.FC = () => {
  // Simple hooks and context
  const theme = useTheme()
  const classes = useStyles()
  const navigate = useNavigate()
  const { assign, data: contextData } = useOnboardingContext()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<ApolloError | CustomError | null>(null)
  const flags = useFlags()

  const controller = useOnboardAdditonalContactController(() => {})

  // Queries
  const {
    loading: loggedInUserDataLoading,
    data: loggedInUserData,
  } = useGetMyAccountDetailsQuery()

  // state vars
  const [province, setProvince] = useState<string | ''>(
    (contextData.schoolProvince as string) ?? ''
  )

  const [selectedSchoolId, setSelectedSchoolId] = useState<SchoolId | null>(
    contextData.schoolId as SchoolId
  )
  const [
    selectedSchoolName,
    setSelectedSchoolName,
  ] = useState<SchoolName | null>(contextData.schoolName as SchoolName)

  const [isCifSchool, setIsCifSchool] = useState(contextData?.isNasefSignUp)

  const [numCoaches, setNumCoaches] = useState(0)

  // computed values
  const schoolType: SchoolType =
    (contextData.schoolType as SchoolType) ?? SchoolType.HighSchool

  const requiredFieldsComplete = selectedSchoolId

  const showNASEFUx =
    isCifSchool && contextData?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const schoolEmail =
    loggedInUserData?.me?.emails && loggedInUserData?.me?.emails[0]?.email

  // Form Handlers
  const onSubmit = async (evt: React.SyntheticEvent): Promise<void> => {
    evt.preventDefault()

    try {
      setLoading(true)

      await handlePostSchoolSelect({
        navigate,
        controller,
        personalEmailOptional: flags.personalEmailOptional,
        assign,
        role: contextData.role,
        numCoaches,
        showNASEFUx,
        schoolEmail,
        selectedSchoolId,
        province,
        selectedSchoolName,
        isCifSchool,
      })
    } catch (e: unknown) {
      setError(e as Error)
    } finally {
      setLoading(false)
    }
  }

  const setSelectedSchool = (
    schoolId: SchoolId,
    schoolName: SchoolName
  ): void => {
    setSelectedSchoolId(schoolId)
    setSelectedSchoolName(schoolName)
  }

  const suggestSchool = (): void => {
    openIntercom()
  }

  const onSchoolClear = (): void => {
    setSelectedSchoolId(null)
  }

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  const subtitle = `We support ${SchoolTypePrettyName[schoolType]} across all of the United States and Canada.`

  return (
    <WaitTillLoaded loading={loggedInUserDataLoading}>
      <form className={classes.form} noValidate onSubmit={onSubmit}>
        <QuestionnaireCard
          childWidth={400}
          disableContinue={!requiredFieldsComplete}
          isContinuing={loading}
          // Note: onSubmit of the form element above actually handles the submission.
          mobileHeightOverride="100%"
          onContinue={noop}
          subtitle={subtitle}
          title="Find your school"
        >
          <OnboardErrorBanner errorMessage={errorMessage} />
          <Box py={theme.spacing(0.25)} width="100%">
            <NxTypography>Enter State/Province</NxTypography>
          </Box>
          <OnboardSelectField
            defaultValue={province}
            name="state"
            onChange={(
              e: ChangeEvent<{
                name?: string
                value: unknown
              }>
            ): void => {
              const value = e?.target?.value ? (e.target.value as string) : ''

              handleStateSelect({
                value,
                setProvince,
                setSelectedSchoolId,
                province,
              })
            }}
            prompt="State/Province"
          >
            {Object.entries(stateNames).map(([abbr, name]) => (
              <option key={abbr} value={abbr}>
                {name}
              </option>
            ))}
          </OnboardSelectField>

          <SchoolSearchForm
            onClear={onSchoolClear}
            onSchoolSelected={setSelectedSchool}
            onSuggestSchool={(): void => suggestSchool()}
            province={province}
            schoolType={schoolType}
          />

          {!!selectedSchoolId && (
            <>
              <SchoolPreview
                onNumCoaches={setNumCoaches}
                schoolId={selectedSchoolId}
                setIsCifSchool={setIsCifSchool}
              />
              <CoachCallout numCoaches={numCoaches} />
            </>
          )}
        </QuestionnaireCard>
      </form>
    </WaitTillLoaded>
  )
}
