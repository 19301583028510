import React, { useEffect } from 'react'
import { Route, useLocation, useNavigate, Outlet } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Path } from '@plvs/const'
import { NxTypography, NxTab, NxTabs } from '@playvs-inc/nexus-components'

import { useProductTypeFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  useAccountRenderControllerContext,
  useAccountRenderControllerState,
} from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { ApmRoutes } from '@elastic/apm-rum-react'
import AccountProfileSettings from './AccountProfileSettings'
import { AccountProviders } from './connections/AccountProviders'
import { NotificationSettings } from './notifications/NotificationSettings'
import { useStyles } from './AccountSettings.styles'

type AccountSettingsTab = {
  id: string
  label: string
  route: string
}

const BASE_ACCOUNT_SETTING_TABS: AccountSettingsTab[] = [
  {
    id: 'account-settings-profile-tab',
    label: 'Profile',
    route: Path.Settings,
  },
  {
    id: 'account-settings-connections-tab',
    label: 'Connections',
    route: Path.ConnectionSettings,
  },
  {
    id: 'account-settings-notifications-tab',
    label: 'Notifications',
    route: Path.NotificationSettings,
  },
]

const UNDERAGE_ACCOUNT_SETTINGS_TABS: AccountSettingsTab[] = [
  {
    id: 'account-settings-profile-tab',
    label: 'Profile',
    route: Path.Settings,
  },
  {
    id: 'account-settings-connections-tab',
    label: 'Connections',
    route: Path.ConnectionSettings,
  },
]

const PARENT_ACCOUNT_SETTINGS_TABS: AccountSettingsTab[] = [
  {
    id: 'account-settings-profile-tab',
    label: 'Profile',
    route: Path.Settings,
  },
  {
    id: 'account-settings-notifications-tab',
    label: 'Notifications',
    route: Path.NotificationSettings,
  },
]

export const AccountSettingsFormWrapper: React.FC = () => {
  const classes = useStyles()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const flags = useFlags()
  const { isUnderage, isParent } = useUserIdentityFn()
  const product = useProductTypeFn()

  let tabs =
    isUnderage && flags?.coppaSupport
      ? UNDERAGE_ACCOUNT_SETTINGS_TABS
      : BASE_ACCOUNT_SETTING_TABS

  if (isParent) {
    tabs = PARENT_ACCOUNT_SETTINGS_TABS
  }

  const handleOnChange = (
    event: React.ChangeEvent<unknown>,
    value: string
  ): void => {
    navigate(value)
  }

  const {
    settings: { getSettingsComponentsToRender },
    providers: { getProvidersComponentsToRender },
  } = useAccountRenderControllerContext()
  const settingsRenderController = getSettingsComponentsToRender({
    productType: product,
    isParent: Boolean(isParent),
  })
  const providersRenderController = getProvidersComponentsToRender({
    productType: product,
  })

  const {
    setAccountRenderControllerStateFn,
    getAccountRenderControllerState,
  } = useAccountRenderControllerState()

  useEffect(
    function updateRenderController() {
      const currentState = getAccountRenderControllerState()
      setAccountRenderControllerStateFn({
        ...currentState,
        settings: {
          ...currentState.settings,
          ...settingsRenderController,
        },
        providers: {
          ...currentState.providers,
          ...providersRenderController,
        },
      })
    },
    [
      ...Object.values(settingsRenderController),
      ...Object.values(providersRenderController.shouldRender),
    ]
  )

  return (
    <Box className={classes.baseTextColor}>
      <PenaltyBox>
        <Box my={2}>
          <NxTypography variant="display">Account Settings</NxTypography>
        </Box>

        <Box mb={2}>
          <NxTabs onChange={handleOnChange} value={pathname}>
            {tabs.map((tab) => {
              return (
                <NxTab
                  key={tab.id}
                  id={tab.id}
                  label={tab.label}
                  value={tab.route}
                />
              )
            })}
          </NxTabs>
        </Box>

        <Outlet />

        <ApmRoutes>
          <Route element={<AccountProfileSettings />} path="settings" />

          <Route element={<AccountProviders />} path="connections" />

          <Route element={<NotificationSettings />} path="notifications" />
        </ApmRoutes>
      </PenaltyBox>
    </Box>
  )
}

export default AccountSettingsFormWrapper
