/**
 * Goal is to centralize all data required to render Esports into this file.
 *
 * Render esports in a data-driven way rather than code-driven.
 *
 * Look to derive this data from back end queries.
 */
import { head, nth } from 'ramda'
import {
  betterCompact,
  isAccountProviderLinked,
  getAccountProviderUsername,
  ConnectedAccountProviders,
  getSafeGeneralEsportAdapterDetails,
  getArticleIdFromRulebookUrl,
  getIsYouthProgram,
} from '@plvs/utils'
import {
  EsportSlug,
  useGetGeneralEsportAdapterDetailsQuery,
  GetGeneralEsportAdapterDetailsQuery,
  Provider,
  GetGeneralEsportAdapterDetailsQueryVariables,
  EsportRating,
} from '@plvs/graphql'

import {
  esportBlackAndWhiteSvgLogosMap,
  esportImagesMap,
  esportAvatarLogoUrl,
  esportDisabledAvatarLogoUrl,
  largeEsportCardImage,
  smallEsportCardImage,
  esportWatermarkUrl,
  headerBackgroundUrl,
  themeGradient,
  EsportRecord,
} from '../esportDesignAssets'
import { randomImage } from '../utils'
import {
  VersusBar,
  StatsScope,
  MatchOverviewFormat,
  GeneralEsportAdapter,
  AllEsportsData,
  Objective,
  ObjectivesScope,
} from '../types'
import {
  esportTeamColors,
  esportVersusBars,
  esportTopPerformerCategories,
  esportPhaseTopPerformerCategories,
  esportPlatformType,
  esportPlatformIcon,
  providerPrettyNames,
  esportObjectives,
  esportStandardMapList,
  esportPlayoffMapList,
  esportHasArena,
  esportHasStatsEnabled,
  esportMatchesCanBeSettled,
  esportIsPlayerDesignatedMatchSetUp,
  esportMatchChecklistArticle,
  esportIsAccountConnectionOptional,
  getScrimmageRatings,
  TeamBlue,
  TeamRed,
  Blue,
} from '../esportStaticDetails'
import {
  esportGamePlayerStatColumns,
  esportMatchPlayerStatColumns,
  esportPhaseStatColumns,
  esportStandingsColumns,
  esportTeamRecentGamesColumns,
} from '../esportComponents'
import { transformForgeEsportSlugToMapToNxEsportLogoMap } from '../../esportConverter'

/** ******************************************************************
 * Esport General Adapter
 * All fields are detailed in this document:
 * https://www.notion.so/playvs/How-to-integrate-a-new-esport-WIP-e1050bbe28ac4f07844c82ccc95e40db#231501155b8f4e0daff3049e36499136
 *
 * Any updates to this code should have a corresponding update to documentation!
 */
export const generateEsportDetails = (
  slug: EsportSlug | null | undefined,
  esportDetailsData: GetGeneralEsportAdapterDetailsQuery | undefined,
  loading: boolean
): GeneralEsportAdapter => {
  const esportData =
    esportDetailsData?.esports?.find(
      (esportDataObject) => esportDataObject?.slug === slug
    ) ?? null

  const accountProvider =
    nth(0, esportData?.details?.accountProviders ?? []) ?? null

  return {
    /**
     * Data below is pulled from GraphQL queries
     */
    id: esportData?.id ?? '',
    slug: slug ?? null,
    rating: esportData?.rating ?? EsportRating.General,
    abbreviation: esportData?.details?.abbreviation ?? '',
    name: esportData?.name ?? '',
    accountProvider,
    getUsername: (user: ConnectedAccountProviders): string =>
      slug && accountProvider
        ? getAccountProviderUsername(user, accountProvider)
        : '',
    getIsUserConnected: (user: ConnectedAccountProviders): boolean =>
      slug && accountProvider
        ? isAccountProviderLinked(user, accountProvider)
        : false,
    accountProviderPrettyName: esportData?.details?.accountProviders?.[0]
      ? providerPrettyNames[esportData.details.accountProviders[0] as Provider]
      : '',
    positions: (esportData?.details?.positionsArray ?? []).map(
      (positionObj) => ({
        position: positionObj.position,
        abbreviation: positionObj.abbreviation,
        name: positionObj.name,
        colloquial: positionObj.colloquial,
      })
    ),
    avatarLogoUrl:
      esportData?.details?.assets?.avatarLogoUrl ??
      getSafeGeneralEsportAdapterDetails(slug, esportAvatarLogoUrl) ??
      '',
    disabledAvatarLogoUrl:
      getSafeGeneralEsportAdapterDetails(slug, esportDisabledAvatarLogoUrl) ??
      esportData?.details?.assets?.disabledAvatarLogoUrl ??
      '',
    watermarkUrl:
      getSafeGeneralEsportAdapterDetails(slug, esportWatermarkUrl) ?? '',
    headerBackgroundUrl:
      getSafeGeneralEsportAdapterDetails(slug, headerBackgroundUrl) ?? '',
    themeGradient:
      getSafeGeneralEsportAdapterDetails(slug, themeGradient) ?? '',
    largeEsportCardImage:
      getSafeGeneralEsportAdapterDetails(slug, largeEsportCardImage) ?? '',
    smallEsportCardImage:
      getSafeGeneralEsportAdapterDetails(slug, smallEsportCardImage) ?? '',
    bestOf: esportData?.details?.defaultBestOf ?? 0,
    seriesBestOf: esportData?.details?.defaultSeriesBestOf ?? 0,
    rankingInfoCopy: esportData?.details?.rankingInfoCopy ?? '',
    competitionGroups: esportData?.details?.competitionGroups ?? [],
    missingConnectionCopy: {
      match: {
        title: esportData?.details?.missingConnectionCopy?.match?.title ?? '',
        subtitle:
          esportData?.details?.missingConnectionCopy?.match?.subtitle ?? '',
      },
      overview: {
        title:
          esportData?.details?.missingConnectionCopy?.overview?.title ?? '',
        subtitle:
          esportData?.details?.missingConnectionCopy?.overview?.subtitle ?? '',
      },
    },
    matchInstructionsArticleId:
      esportData?.details?.matchInstructionsArticleId ?? '',
    isScrimmageEnabled:
      esportData?.details?.features?.isScrimmageEnabled ?? false,
    isEsportEnabled: esportData?.isEnabled ?? false,
    isDiscordEnabled: esportData?.details?.features?.isDiscordEnabled ?? false,
    hasSubstitutesEnabled:
      esportData?.details?.features?.hasSubstitutesEnabled ?? false,
    isPlayerLedTeamEnabled:
      esportData?.details?.features?.isPlayerLedTeamEnabled ?? false,
    hasApiIntegration:
      esportData?.details?.features?.hasApiIntegration ?? false,
    hasSeries: esportData?.details?.features?.hasSeries ?? false,
    matchAssistantEnabled:
      esportData?.details?.features?.matchAssistantEnabled ?? false,
    hasMatchAssistantScore:
      esportData?.details?.features?.hasMatchAssistantScore ?? false,
    gameType: esportData?.details?.gameType ?? '',
    playerStatRankCategory: esportData?.details?.playerStatRankCategory ?? '',
    matchOverviewFormat:
      (esportData?.details?.matchOverviewFormat as MatchOverviewFormat) ??
      MatchOverviewFormat.HeadToHead,
    platforms: esportData?.details?.platforms ?? [],
    getPositionName: (position: number | null): string => {
      if (position === null) return ''
      const pos = esportData?.details?.positionsArray?.find(
        (positionEntry) => positionEntry.position === position
      )
      return pos?.name ?? ''
    },
    getPositionShortName: (position: number | null): string => {
      if (position === null) return ''
      if (position === -1) return 'SUB'
      const pos = esportData?.details?.positionsArray?.find(
        (positionEntry) => positionEntry.position === position
      )
      return pos?.abbreviation ?? ''
    },
    getPositionColloquial: (position: number | null): string => {
      if (position === null) return ''
      const pos = esportData?.details?.positionsArray?.find(
        (positionEntry) => positionEntry.position === position
      )
      return pos?.colloquial ?? ''
    },
    isGamelessType: new Set(['Battle Royale']).has(
      esportData?.details?.gameType ?? ''
    ),
    isFortnite:
      slug === EsportSlug.Fortnite || slug === EsportSlug.FortniteSolos,
    teamMinStarterSize: esportData?.details?.teamMinStarterSize ?? 0,
    teamFormat: esportData?.leagueDefaults?.teamFormat ?? 0,
    teamMaxSize: esportData?.leagueDefaults?.teamMaxSize ?? 0,
    /**
     * Data below is pulled from static data in Forge
     */
    standardMapList: getSafeGeneralEsportAdapterDetails(
      slug,
      esportStandardMapList
    ),
    playoffMapList: getSafeGeneralEsportAdapterDetails(
      slug,
      esportPlayoffMapList
    ),
    colors: getSafeGeneralEsportAdapterDetails(slug, esportTeamColors) ?? [
      TeamBlue,
      TeamRed,
    ],
    blackAndWhiteSvgIcon: getSafeGeneralEsportAdapterDetails(
      slug,
      esportBlackAndWhiteSvgLogosMap
    ),
    NexusIcon: transformForgeEsportSlugToMapToNxEsportLogoMap(slug),
    topPerformerCategories:
      getSafeGeneralEsportAdapterDetails(slug, esportTopPerformerCategories) ??
      [],
    phaseTopPerformerCategories: getSafeGeneralEsportAdapterDetails(
      slug,
      esportPhaseTopPerformerCategories
    ),
    gamePlayerStatsColumns: getSafeGeneralEsportAdapterDetails(
      slug,
      esportGamePlayerStatColumns
    ),
    matchPlayerStatsColumns: getSafeGeneralEsportAdapterDetails(
      slug,
      esportMatchPlayerStatColumns
    ),
    phaseStatColumns: getSafeGeneralEsportAdapterDetails(
      slug,
      esportPhaseStatColumns
    ),
    standingsColumns: getSafeGeneralEsportAdapterDetails(
      slug,
      esportStandingsColumns
    ),
    teamRecentGamesColumns: getSafeGeneralEsportAdapterDetails(
      slug,
      esportTeamRecentGamesColumns
    ),
    getRandomImage: (): string =>
      slug && randomImage(slug) ? randomImage(slug) : '',
    getSideColor: (side: number): string =>
      getSafeGeneralEsportAdapterDetails(slug, esportTeamColors)?.[side] ??
      Blue,
    getVersusBars: (scope?: StatsScope): VersusBar[] => {
      if (!slug || !esportVersusBars[slug]) return []
      return scope && !!esportVersusBars[slug][scope]
        ? esportVersusBars[slug][scope]
        : esportVersusBars[slug].default
    },
    getObjectives: (scope?: ObjectivesScope): Objective[] => {
      if (!slug || !esportObjectives[slug]) return []
      return scope && !!esportObjectives[slug][scope]
        ? esportObjectives[slug][scope]
        : esportObjectives[slug][ObjectivesScope.Game]
    },
    platformType: getSafeGeneralEsportAdapterDetails(slug, esportPlatformType),
    platformIcon: getSafeGeneralEsportAdapterDetails(slug, esportPlatformIcon),
    loading,
    headerImages:
      getSafeGeneralEsportAdapterDetails(slug, esportImagesMap) ?? [],
    hasArena: getSafeGeneralEsportAdapterDetails(slug, esportHasArena) ?? false,
    hasStatsEnabled:
      getSafeGeneralEsportAdapterDetails(slug, esportHasStatsEnabled) ?? false,
    canSettleMatches:
      getSafeGeneralEsportAdapterDetails(slug, esportMatchesCanBeSettled) ??
      false,
    isPlayerDesignatedMatchSetUp:
      getSafeGeneralEsportAdapterDetails(
        slug,
        esportIsPlayerDesignatedMatchSetUp
      ) ?? false,
    matchInstructionArticleUrl:
      getSafeGeneralEsportAdapterDetails(slug, esportMatchChecklistArticle) ??
      '',
    isAccountConnectionOptional:
      getSafeGeneralEsportAdapterDetails(
        slug,
        esportIsAccountConnectionOptional
      ) ?? false,
    rulebookImage: `https://s3.amazonaws.com/assets.playvs.com/esports/${slug}/RulebookCover.webp`,
    rulebookUrl:
      esportData?.details?.rulebookUrl ??
      'https://help.playvs.com/en/articles/5464981',
    rulebookArticleId: getArticleIdFromRulebookUrl(
      esportData?.details?.rulebookUrl
    ),
    scrimmageRatings: getScrimmageRatings(slug),
    matchAssistantVideoSrc: esportData?.details?.matchAssistantVideoSrc ?? '',
    isYouthProgram: getIsYouthProgram(esportData?.rating),
    scoreDisplayName: esportData?.details?.scoreDisplayName ?? '',
    theme: esportData?.colors,
  }
}

export const useGeneralEsportAdapter = (
  slug: EsportSlug | null | undefined,
  variables: GetGeneralEsportAdapterDetailsQueryVariables = {}
): GeneralEsportAdapter => {
  const { data, loading } = useGetGeneralEsportAdapterDetailsQuery({
    variables: {
      filters: {
        ...variables.filters,
        ...(slug && { slug }),
      },
    },
    skip: !slug,
  })
  return generateEsportDetails(slug, data, loading)
}

export const useAllEsportAdapters = (
  variables: GetGeneralEsportAdapterDetailsQueryVariables = {}
): AllEsportsData => {
  const { data, loading } = useGetGeneralEsportAdapterDetailsQuery({
    variables,
  })
  const esportSlugs = betterCompact(
    data?.esports?.map((esportData) => esportData?.slug ?? null) ?? []
  )
  return esportSlugs?.map((slug) => generateEsportDetails(slug, data, loading))
}

export const allEsportAdaptersMap = (
  esportDetailsData: GetGeneralEsportAdapterDetailsQuery | undefined,
  loading: boolean
): EsportRecord<GeneralEsportAdapter> =>
  Object.values(EsportSlug).reduce((acc, esportSlug) => {
    acc[esportSlug] = generateEsportDetails(
      esportSlug,
      esportDetailsData,
      loading
    )
    return acc
  }, {})

export const useAllEsportProviderMap = (
  esportDetailsData: GetGeneralEsportAdapterDetailsQuery | undefined,
  loading: boolean
): Record<EsportSlug, Provider> => {
  const { data: queryData, loading: queryLoading } =
    useGetGeneralEsportAdapterDetailsQuery({
      skip: loading ?? !!esportDetailsData,
    })
  const data = esportDetailsData ?? queryData
  const isLoading = loading ?? queryLoading
  const empty = {} as Record<EsportSlug, Provider>
  if (isLoading) return empty
  return (
    data?.esports?.reduce<Record<EsportSlug, Provider>>((accum, esport) => {
      return {
        ...accum,
        [esport.slug as EsportSlug]:
          head(esport?.details?.accountProviders ?? []) ?? null,
      }
    }, empty) ?? empty
  )
}
