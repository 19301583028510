import React from 'react'
import { makeStyles } from '@material-ui/core'
import { NxTooltip, NxTypography } from '@playvs-inc/nexus-components'
import clsx from 'clsx'
import { Box } from '@plvs/respawn/features/layout'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { AssignedSeasonalAward } from './PlayerAwardsProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    padding: theme.spacing(3),
  },
  icon: {
    marginRight: theme.spacing(3),
    width: 80,
    height: 80,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
}))

interface Props {
  award: AssignedSeasonalAward
  className?: string
}

export const AssignedSeasonalAwardsCard: React.FC<Props> = ({
  award,
  className,
}) => {
  const classes = useStyles()

  return (
    <Box
      alignItems="center"
      className={clsx(classes.root, className)}
      display="flex"
    >
      <Box className={classes.icon}>
        <NxSpot
          domain="league"
          height={80}
          size="large"
          variant="primary"
          width={80}
        />
      </Box>

      <div>
        <NxTypography className={classes.title} variant="body2">
          Seasonal Award
        </NxTypography>
        <NxTooltip arrow placement="top" title={award.description}>
          <div>
            <NxTypography variant="body3">{award.title}</NxTypography>
          </div>
        </NxTooltip>
        <NxTypography className={classes.title} variant="body3">
          {award.metaseasonName}
        </NxTypography>
      </div>
    </Box>
  )
}
