import React, { useCallback, useState } from 'react'

import { LocalStorageKey } from '@plvs/const'
import { useGetChatAccessTokenMutation } from '@plvs/graphql'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { rallyEnv } from '@plvs/rally/env'
import { logger } from '@plvs/rally/logging'
import { ChatAccessTokenProviderContext } from './Providers.types'

export const chatAccessTokenProviderContext = React.createContext<
  ChatAccessTokenProviderContext
>({
  loading: false,
  shouldDefaultConnectToTwilio: false,
  accessToken: undefined,
  namespace: '',
})

export const useChatAccessTokenProviderContext = (): ChatAccessTokenProviderContext => {
  return React.useContext(chatAccessTokenProviderContext)
}

export const ChatAccessTokenProvider: React.FC = ({ children }) => {
  const [
    shouldDefaultConnectToTwilio,
    setShouldDefaultConnectToTwilio,
  ] = useState(rallyEnv.API_ENV === 'production')

  const { userId, loading: idLoading } = useUserIdentityFn()

  const namespace = `${LocalStorageKey.TwilioAccessToken}-${userId}`
  const [accessToken, setAccessToken] = useState<string>()

  const [
    getChatAccessTokenMutation,
    { loading },
  ] = useGetChatAccessTokenMutation()

  const fetchAccessToken = useCallback(async (): Promise<
    string | undefined
  > => {
    if (userId) {
      try {
        const result = await getChatAccessTokenMutation({
          variables: {
            input: {
              id: userId,
            },
          },
        })
        const newAccessToken =
          result?.data?.generateTwilioAccessTokenByUserId.payload
            .twilioAccessToken
        localStorage.setItem(namespace, newAccessToken || '')
        setAccessToken(newAccessToken || '')
        return newAccessToken
      } catch (err) {
        // Swallow error in case we hit Twilio rate limits
        logger.error(err)
      }
    }
    return undefined
  }, [accessToken, namespace, idLoading, shouldDefaultConnectToTwilio, userId])

  React.useEffect(() => {
    const localAccessToken = localStorage.getItem(namespace)

    if (localAccessToken !== accessToken) {
      setAccessToken(localAccessToken || '')
    }

    if (
      !idLoading &&
      !localAccessToken &&
      shouldDefaultConnectToTwilio &&
      namespace.includes(userId)
    ) {
      fetchAccessToken()
    }
  }, [accessToken, namespace, idLoading, shouldDefaultConnectToTwilio, userId])

  const removeAccessTokenOnLogout = (): void => {
    localStorage.removeItem(namespace)
  }

  return (
    <chatAccessTokenProviderContext.Provider
      value={{
        accessToken,
        loading: idLoading || loading,
        setShouldDefaultConnectToTwilio,
        removeAccessTokenOnLogout,
        shouldDefaultConnectToTwilio,
        namespace,
        getAccessToken: fetchAccessToken,
      }}
    >
      {children}
    </chatAccessTokenProviderContext.Provider>
  )
}
