import { Message, Participant, Media } from '@twilio/conversations'
import { updateActiveGlobalChatConversationsVar } from '@plvs/client-data/mutations/updateActiveGlobalChatConversationsVar'
import {
  emotesMap,
  OnlineStatus as NxOnlineStatus,
} from '@playvs-inc/nexus-components'
import type { NxChatMessageProps } from '@playvs-inc/nexus-chat'

import { ChatRole, OnlineStatus } from '@plvs/graphql'
// eslint-disable-next-line no-restricted-imports
import { UserOnlineStatusMap } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'

import { ActiveGlobalConversations } from '@plvs/client-data/models/GlobalChatConversations'
import { executeCommands } from './commands'

// interfaces

export interface NxChatMedia {
  url: string
  sid: string
  fileName: string
  fileType?: string
  size?: string
}

export interface ParticipantAttributesJSON {
  avatarUrl?: string
  friendlyName?: string
  chatRole?: ChatRole
}

// consts

export const MAX_ACTIVE_CONVERSATIONS = 4

export const maxMessageLengths = {
  [ChatRole.Player]: 280,
  [ChatRole.Coach]: 280,
  [ChatRole.Captain]: 280,
  [ChatRole.Admin]: 1000,
}

export const Forbidden = 403
export const NotFound = 404

export enum MessageErrors {
  EmptyMessage = 'Enter a message',
  TooLong = 'Message is too long',
}

export function getMessageError(
  message: string,
  media: File[],
  chatRole: ChatRole = ChatRole.Player
): string {
  // Allow empty message as long as there media attachments.
  if (!message && !media.length) {
    return MessageErrors.EmptyMessage
  }

  let numOfEmotes = 0
  const messageWithoutEmotes = message.replace(/:(\w+?):/g, (_match, group) => {
    const emote = emotesMap[group]

    if (emote) {
      // eslint-disable-next-line no-plusplus
      numOfEmotes++
    }

    return ''
  })

  const messageLength = messageWithoutEmotes.length + numOfEmotes
  const maxMessageLength = maxMessageLengths[chatRole]

  if (messageLength > maxMessageLength) {
    return MessageErrors.TooLong
  }

  return ''
}

export async function getParticipantData(
  message: Message,
  onlineStatusByUserId?: UserOnlineStatusMap
): Promise<
  Pick<
    NxChatMessageProps,
    | 'avatarUrl'
    | 'isAdmin'
    | 'isCaptain'
    | 'isCoach'
    | 'author'
    | 'onlineStatus'
  >
> {
  let participant: Participant | undefined
  try {
    participant = await message.getParticipant()
  } catch (err) {
    // swallow error

    participant = undefined
  }

  const { avatarUrl, friendlyName, chatRole } = (participant?.attributes ??
    {}) as ParticipantAttributesJSON

  const isAdmin = chatRole === ChatRole.Admin
  const isCoach = chatRole === ChatRole.Coach
  const isCaptain = chatRole === ChatRole.Captain

  let name = ''
  if (participant === undefined) {
    name = 'PlayVS Message'
  } else if (isAdmin) {
    name = 'Admin'
  } else {
    name = friendlyName || ''
  }

  const avatarSrc = isAdmin ? '' : avatarUrl

  const onlineStatus = (onlineStatusByUserId &&
  participant?.identity &&
  onlineStatusByUserId[participant.identity] === OnlineStatus.Online
    ? 'online'
    : 'placeholder') as NxOnlineStatus

  return {
    avatarUrl: avatarSrc,
    isAdmin,
    isCoach,
    isCaptain,
    author: {
      id: message.participantSid ?? '',
      name,
    },
    onlineStatus,
  }
}

export async function getMediaData(
  media: Media[] | null
): Promise<NxChatMedia[]> {
  if (media) {
    return Promise.all(
      media.map(
        async (img) =>
          ({
            fileName: img.filename,
            sid: img.sid,
            fileType: img.contentType,
            url: await img.getContentTemporaryUrl(),
          } as NxChatMedia)
      )
    )
  }

  return []
}

export async function mapMessagesToNxMessage({
  messages,
  onlineStatusByUserId,
  isGlobal,
}: {
  messages: Message[] | undefined
  onlineStatusByUserId?: UserOnlineStatusMap
  isGlobal?: boolean
}): Promise<NxChatMessageProps[]> {
  if (!messages) {
    return []
  }

  const results = await Promise.all(
    messages.map(
      async (message): Promise<NxChatMessageProps> => {
        const mediaData = await getMediaData(message.attachedMedia)
        const participantData = await getParticipantData(
          message,
          onlineStatusByUserId
        )

        const body = executeCommands(message.body ?? '', {
          execute: false,
          replace: true,
        })

        return {
          message: body,
          messageId: message.sid,
          timestamp: message.dateCreated?.toString() ?? '',
          media:
            // Allowing captains to send media is safe for now as stadium owners are captains in chat and scholastic captains do not see the attach media button.
            participantData.isCoach ||
            participantData.isAdmin ||
            participantData.isCaptain
              ? mediaData
              : [],
          global: isGlobal,
          ...participantData,
        }
      }
    )
  )

  return results
}

export function mapMediaToNxMedia(media: File[]): NxChatMedia[] {
  return media.map((img) => ({
    fileName: img.name,
    fileType: img.type,
    url: URL.createObjectURL(img),
    sid: img.lastModified.toString(),
  }))
}

export const getDerivedChatUniqueName = ({
  chatRole,
  matchId,
}: {
  chatRole?: ChatRole
  matchId: string
}): string => {
  return chatRole === ChatRole.Coach || chatRole === ChatRole.Admin
    ? `${matchId}-coach`
    : ''
}

export const getMatchIdFromCoachChat = ({
  uniqueName,
}: {
  uniqueName: string
}): string => {
  const position = uniqueName.indexOf('coach')
  const newMatchId = uniqueName.slice(0, position - 1)
  return newMatchId
}

export const onOpenGlobalChat = ({
  uniqueName,
  activeConversations,
  activeConversationsList,
}: {
  uniqueName: string
  activeConversations: ActiveGlobalConversations
  activeConversationsList: string[]
}): void => {
  if (
    activeConversationsList.length === MAX_ACTIVE_CONVERSATIONS &&
    // This handles the use case where a user clicks an already open chat conversation. This avoids only rendering three chat windows.
    !activeConversations[uniqueName]?.active
  ) {
    const newActiveConversations = { ...activeConversations }
    delete newActiveConversations[activeConversationsList[0]]
    updateActiveGlobalChatConversationsVar({
      ...newActiveConversations,
      [uniqueName]: {
        active: true,
        expanded: true,
      },
    })
  } else {
    updateActiveGlobalChatConversationsVar({
      ...activeConversations,
      [uniqueName]: {
        active: true,
        expanded: true,
      },
    })
  }
}
