import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { Box } from '@plvs/respawn/features/layout/Box'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import {
  Maybe,
  useAssignWeeklyPlayerAwardMutation,
  User,
} from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { isNil } from 'ramda'
import { AwardsCanvas } from './AwardsCanvas'
import { NominatePlayerModal } from './NominatePlayerModal'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    boxShadow: theme.mixins.boxShadow.elevation1,
    borderRadius: theme.shape.borderRadius * 2.5,
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  subtitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  spot: {
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
    },
  },
  inputLabel: {
    color: theme.palette.ColorTextAlt2,
  },
  nominateButton: {
    minHeight: '40px',
    alignSelf: 'flex-end',
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
  downloadAwardButton: {
    minHeight: '40px',
    minWidth: '158px',
    marginLeft: 'auto',
    marginTop: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
      width: '100%',
    },
  },
  menuItem: {
    color: theme.palette.ColorTextAlt,
  },
}))

interface Props {
  beginningOfWeek:
    | {
        id: string
        name: string
      }
    | undefined
  className?: string
  currentAssignedAwardUserId?: string | null
  players: Maybe<Pick<User, 'id' | 'name' | 'avatarUrl'>>[] | undefined
  refetchAwardData(): Promise<void>
  schoolName: string
}

export const SelectPlayerOfTheWeek: React.FC<Props> = ({
  beginningOfWeek,
  className,
  currentAssignedAwardUserId,
  players,
  refetchAwardData,
  schoolName,
}) => {
  const classes = useStyles()

  const { enqueueSnackbar } = useSnackbar()

  const [selectedPlayerId, setSelectedPlayerId] = useState<string>('')

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    setSelectedPlayerId(event.target.value as string)
  }

  const [open, setOpen] = useState<boolean>(false)
  const handleOpen = (): void => setOpen(true)
  const handleClose = (): void => setOpen(false)

  useEffect(() => {
    if (currentAssignedAwardUserId) {
      setSelectedPlayerId(currentAssignedAwardUserId)
    }
  }, [currentAssignedAwardUserId])

  const handleDownload = (): void => {
    try {
      const canvas = document.getElementById('awardCanvas') as HTMLCanvasElement
      const url = canvas?.toDataURL('image/png')
      const link = document.createElement('a')
      link.download = 'award.png'
      link.href = url
      link.click()
    } catch (e) {
      enqueueSnackbar('Something went wrong downloading', { variant: 'error' })
    }
  }

  const [
    assignWeeklyPlayerAward,
    { loading },
  ] = useAssignWeeklyPlayerAwardMutation()

  const handleAssignAward = async (): Promise<void> => {
    try {
      const result = await assignWeeklyPlayerAward({
        variables: {
          input: {
            userId: selectedPlayerId,
            beginningOfWeekTime: beginningOfWeek?.id ?? '',
          },
        },
      })

      if ((result?.errors ?? []).length > 0) {
        enqueueSnackbar('Something went wrong assigning award', {
          variant: 'error',
        })
        return
      }

      enqueueSnackbar('Assigned Award!', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar('Something went wrong assigning award', {
        variant: 'error',
      })
    } finally {
      handleClose()
      refetchAwardData()
      setSelectedPlayerId('')
    }
  }

  const selectedUser = players?.find((p) => p?.id === selectedPlayerId)

  const disableNomination = !isNil(currentAssignedAwardUserId)

  return (
    <>
      <Box
        alignItems="center"
        className={clsx(classes.root, className)}
        display="flex"
        flexDirection={['column', 'row']}
      >
        <Box className={classes.spot}>
          <NxSpot
            domain="trophy"
            height={100}
            size="large"
            variant="primary"
            width={100}
          />
        </Box>
        <div>
          <NxTypography className={classes.title} variant="h4">
            Player of The Week
          </NxTypography>
          <NxTypography
            className={classes.subtitle}
            colorToken="ColorTextAlt"
            variant="body1"
          >
            You may nominate a Player of the Week each week that has at least
            one completed match.
          </NxTypography>
          <Box
            alignItems="center"
            display="flex"
            flexDirection={['column', 'row']}
          >
            <FormControl
              disabled={disableNomination}
              fullWidth
              size="small"
              variant="outlined"
            >
              <InputLabel className={classes.inputLabel}>
                Who would you like to nominate?
              </InputLabel>
              <Select
                disabled={disableNomination}
                label="Who would you like to nominate?"
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                onChange={handleChange}
                value={selectedPlayerId}
              >
                {players?.map((player) => (
                  <MenuItem
                    key={player?.id}
                    className={classes.menuItem}
                    value={player?.id}
                  >
                    {player?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <NxButton
              className={classes.nominateButton}
              disabled={disableNomination || !selectedPlayerId}
              label="Nominate"
              onClick={handleOpen}
              variant="secondary"
            />
          </Box>
        </div>
        <NxButton
          className={classes.downloadAwardButton}
          disabled={isNil(currentAssignedAwardUserId)}
          label="Download Award"
          onClick={handleDownload}
          variant="secondary"
        />
      </Box>
      {selectedUser && (
        <NominatePlayerModal
          beginningOfWeek={beginningOfWeek}
          handleAssignAward={handleAssignAward}
          handleClose={handleClose}
          loading={loading}
          open={open}
          selectedUser={selectedUser}
        />
      )}
      {selectedUser && (
        <AwardsCanvas
          name={selectedUser?.name ?? ''}
          schoolName={schoolName}
          weekString={beginningOfWeek?.name ?? ''}
        />
      )}
    </>
  )
}
