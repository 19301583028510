import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { NxIconButton, NxTypography } from '@playvs-inc/nexus-components'
import { CaretRight, AddteamHover } from '@playvs-inc/nexus-icons'
import { ChildOnboardPath, Path, SessionStorageKey } from '@plvs/const'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useNavigate } from 'react-router-dom'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import { ChildUser } from './types'
import { getPathUrl } from './helpers'

export const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: theme.mixins.boxShadow.elevation1,
    borderBottomRightRadius: theme.shape.borderRadius * 2.5,
    borderBottomLeftRadius: theme.shape.borderRadius * 2.5,
    maxWidth: 686,
  },
  title: {
    backgroundColor: theme.palette.ColorBackgroundAlt,
    borderTopRightRadius: theme.shape.borderRadius * 2.5,
    borderTopLeftRadius: theme.shape.borderRadius * 2.5,
  },
  item: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    boxShadow: theme.mixins.divider.bottom,
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  button: {
    marginLeft: 'auto',
  },
  addChild: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    borderBottomRightRadius: theme.shape.borderRadius * 2.5,
    borderBottomLeftRadius: theme.shape.borderRadius * 2.5,
  },
  text: {
    marginLeft: theme.spacing(3),
  },
  pointer: {
    cursor: 'pointer',
  },
}))

interface Props {
  childUsers: ChildUser[]
}

export const MembersCard: React.FC<Props> = ({ childUsers }) => {
  const classes = useStyles()

  const productType = useProductTypeFn()

  const navigate = useNavigate()

  const handleAddChild = (): void => {
    sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
    navigate(
      `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildDataUsage}`
    )
  }

  const handleChildClick = (childUser: ChildUser): void => {
    const path = getPathUrl(childUser, productType)
    navigate(path)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.title} px={3} py={2}>
        <NxTypography variant="h4">Members</NxTypography>
      </Box>
      {childUsers.map((child) => (
        <Box
          key={child.id}
          alignItems="center"
          className={classes.item}
          display="flex"
          p={3}
        >
          <NxUserAvatar
            avatarUrl={child?.avatarUrl}
            className={classes.avatar}
            hashId={child.id}
            size={NxAvatarSize.MD}
          />
          <div>
            <NxTypography variant="body2">
              {child.name || 'No name'}
            </NxTypography>
            <NxTypography colorToken="ColorTextAlt" variant="body3">
              Child
            </NxTypography>
          </div>
          <NxIconButton
            className={classes.button}
            data-testid="child-button"
            icon={<CaretRight />}
            onClick={(): void => handleChildClick(child)}
            variant="text"
          />
        </Box>
      ))}
      <Box
        alignItems="center"
        className={classes.addChild}
        display="flex"
        px={4}
        py={5}
      >
        <Box
          className={classes.pointer}
          data-testid="add-child"
          display="flex"
          onClick={handleAddChild}
        >
          <AddteamHover />
          <NxTypography className={classes.text} variant="body2">
            Add a child
          </NxTypography>
        </Box>
      </Box>
    </Box>
  )
}
