import React, { useState } from 'react'

import { ChangeRoster } from '../steps/components/ChangeRoster'

type ChangeRosterReturn = {
  ChangeRoster: React.ReactElement
  isChangeRosterChecked: boolean
}

type UseChangeRosterProps = {
  disabled?: boolean
  initChecked?: boolean
}

export function useChangeRoster({
  disabled = false,
  initChecked = false,
}: UseChangeRosterProps): ChangeRosterReturn {
  const [checked, setChecked] = useState(initChecked)

  return {
    ChangeRoster: (
      <ChangeRoster
        checked={checked}
        disabled={disabled}
        onChange={setChecked}
      />
    ),
    isChangeRosterChecked: checked,
  }
}
