import {
  AnnualPassInstanceInfo,
  CompetitionModel,
  Esport,
  EsportSlug,
  GetMySchoolEnrollmentQuery,
  League,
  Metaseason,
  Season,
  SlotExclusionWindowResourceType,
} from '@plvs/graphql'
import { EnrollmentProduct } from '@plvs/utils'
import { ApolloError } from '@apollo/client'

export type EnrollmentSeason = Pick<
  Season,
  | 'id'
  | 'teamDeregistrationEndsAt'
  | 'metaseasonId'
  | 'name'
  | 'registrationStartsAt'
  | 'teamRegistrationEndsAt'
  | 'maxTeamsPerSchool'
> & { weeklySlotDateEstimate: { sample: string } | null }

export type EnrollmentLeagueEsport = Pick<Esport, 'id' | 'slug' | 'name'>

export type EnrollmentLeague = Pick<
  League,
  'id' | 'competitionModel' | 'displayName' | 'name'
> & {
  esport: EnrollmentLeagueEsport
  seasons: EnrollmentSeason[] | null
  teamFormat: number
  teamMaxSize: number
  teamMinimumStarterSize: number
  numTeamsInSeasonForSchool?: number
}

export interface EnrollmentLeagueGroup {
  id: string
  leagues: EnrollmentLeague[]
}

export type EnrollmentLeagues = EnrollmentLeague[]

export type IneligibleLeagues = EnrollmentLeague[]

export type DisabledEnrollmentCTAStatus = {
  value: boolean
  message?: string
}

export type EnrolledPlayerNotOnTeam = {
  esportSlug: EsportSlug
  leagueId: string
  metaseasonId: string
  userId: string
}

export type EnrolledPlayerOnTeam = EnrolledPlayerNotOnTeam & {
  teamId: string
  teamFormat: number
  position: number
}

export type EnrolledPlayer = EnrolledPlayerOnTeam | EnrolledPlayerNotOnTeam

export type EnrolledTeam = { leagueId: string; teamId: string }

export type SelectedPlayersOnTeams = {
  toSeasonId?: string
  fromSeasonId?: string
  toLeagueId?: string
  fromLeagueId?: string
  teamId: string
  teamFormat: number
  members: { id: string; position: number }[]
  esportSlug: EsportSlug
}

export interface Selection {
  hash: string
  startsAt: string
  endsAt: string
  formattedSelection?: string
  isEditable: boolean
  id?: string
}

export type SelectionObj = {
  confirmedSelection: Selection | null
  selection: Selection | null
}

export type FinalSelectionsToCreate = {
  confirmedSelection: Selection | null
  selection: Selection | null
  resourceId: string
  seasonId?: string | null
  resourceType: SlotExclusionWindowResourceType
}

export type OptionalWeeksSelections = {
  resourceType: SlotExclusionWindowResourceType
  resourceId: string
  selections: Array<SelectionObj>
  seasonId?: string
}

export type OptionalSeasonWeeksSelections = OptionalWeeksSelections & {
  seasonId: string
}

export type RangeConfiguration = {
  maxSlotExclusions: number
  defaultsAppliedAt: string
  suggestions: Array<{
    startsAt: string
    endsAt: string
    hash: string
    isAvailable: boolean
    title: string
  }>
}

export type EnrollmentRangeData = Array<{
  configuration: RangeConfiguration
  range: string
  resourceType: SlotExclusionWindowResourceType
  resourceId: string
}>

export type SeasonEnrollmentRange = {
  configuration: RangeConfiguration
  seasonId: string
  leagueName: string
  resourceType: SlotExclusionWindowResourceType
  resourceId: string
}

export type SeasonEnrollmentRangeData = Array<SeasonEnrollmentRange>

export type OptionalWeeksCache = Record<string, OptionalWeeksSelections>

export type TeamEnrollmentUpdate = {
  teamId: string
  fromSeasonId?: string
  toSeasonId?: string
}

export type TeamLeagueSelectFn = (data: SelectedPlayersOnTeams) => void

export type TeamSelectFn = (
  event: React.ChangeEvent<HTMLInputElement>,
  data: SelectedPlayersOnTeams
) => void

export interface UseEnrollmentOptions {
  isCoach: boolean
  metaseasonId: string
  schoolId: string
  includeOrganizationEnrollment?: boolean
}

export interface UseSlotExclusionsOptions {
  metaseasonId: string
  schoolId: string
}

export interface EnrollmentDiff {
  playerAdditions: EnrolledPlayer[]
  playerRemovals: EnrolledPlayer[]
  teamAdditions: EnrolledTeam[]
  teamRemovals: EnrolledTeam[]
}

export type Esports = Array<EsportSlug>

export enum EnrollmentStatus {
  NotInitialized = 'Not initialized',
  Enrolled = 'Enrolled',
  Enrolling = 'Enrolling',
  NotEnrolled = 'Not enrolled',
  EnrollmentError = 'Error',
}

export enum EnrollmentSummaryType {
  Teams = 'Teams',
  SubstitutePlayers = 'Substitute Players',
  TotalPlayers = 'Total Players',
  PlayerPasses = 'Estimated Player Passes',
  SubstitutePlayerPasses = 'Substitute Player Passes',
  FreePasses = 'Free Passes',
}

export type AnnualPassInstanceEnrollment = {
  id: string
  productId: string | null
  productInstanceInfo: AnnualPassInstanceInfo
  seasonPassBundles: Array<{
    metaseason: Pick<
      Metaseason,
      'id' | 'name' | 'nextMetaseasonId' | 'previousMetaseasonId'
    > | null
  }> | null
}

export type UseEnrollmentReturn = {
  // API values
  enrolledTeamIds: string[]
  esports: Esports
  hasAnnualPassInstance: boolean
  currentAnnualPassInstance: AnnualPassInstanceEnrollment | undefined
  annualPassInstanceMetaseasonId: string | undefined
  ineligibleLeagues: IneligibleLeagues
  leagues: EnrollmentLeagues
  onProductSelected: (plan: EnrollmentProduct | null) => void
  product: EnrollmentProduct | undefined
  products: EnrollmentProduct[]
  purchaseProduct: () => Promise<boolean>
  schoolId: string | undefined
  schoolLogo: string
  schoolName: string
  season: EnrollmentSeason | undefined
  hasEnrolledTeams: boolean

  // Cache
  changeCompetitionModel: (competitionModel: CompetitionModel) => void
  hasTeamUpdates: boolean
  competitionModel: CompetitionModel | null | undefined
  competitionModels: CompetitionModel[]
  enrolledPlayers: EnrolledPlayer[]
  enrolledPlayersOnTeam: EnrolledPlayerOnTeam[]
  enrolledPlayersNotOnTeam: EnrolledPlayerNotOnTeam[]
  enrolledSeasonIds: Set<string>
  numberOfPassesUsed: number
  enrolledTeamsCount: number
  onPlayersSelected: TeamLeagueSelectFn
  leagueEsports: EnrollmentLeagueEsport[]

  // Initial API Status
  error: ApolloError | Error | undefined
  loading: boolean

  // Enrollment Status
  status: EnrollmentStatus

  // Mutation
  changeEnrollment: () => Promise<boolean>
  refreshPlayers(): Promise<void>

  // Computed Values
  presubmitTeamsToEnroll: string[]
}

export type UseSlotExclusions = {
  // Cache
  optionalSeasonWeeksCache: OptionalWeeksCache
  optionalWeeksCache: OptionalWeeksCache
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
  addEnrollmentSeasonIds: (seasonIds: string[]) => void
  removeEnrollmentSeasonId: (seasonId: string) => void
  setOptionalSeasonWeeksCache(input: OptionalWeeksCache): void
  setOptionalWeeksCache(input: OptionalWeeksCache): void
  slotExclusionRangeData: EnrollmentRangeData

  // Initial API Status
  error: ApolloError | Error | undefined
  loading: boolean

  // Mutation
  updateSlotExclusionSelection: () => Promise<boolean>
}

export type EnrollmentTeam = NonNullable<
  NonNullable<GetMySchoolEnrollmentQuery['school']>['teams']
>[0]

export interface TeamWithEnrolledSeason {
  enrolledSeasons?: { metaseasonId: string | null }[] | null
}

export type OptionalSlotExclusionRangeData = SeasonEnrollmentRange & {
  selections: Selection[]
}
