import React, { useEffect } from 'react'
import { Box } from '@material-ui/core'

import { NxScoreboardRows, ScoreboardRow } from '@playvs-inc/nexus-components'

import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import { EsportRating, MatchStatus } from '@plvs/graphql/generated'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import * as analytics from '@plvs/respawn/features/analytics'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'

import { MatchAssistantMatch } from '@plvs/utils'
import { mapNonSeriesData, mapSeriesData } from './MatchFinalScore.helpers'
import { SmashFinalScore } from './SmashFinalScore'
import { useMatchAssistantContext } from '../../assistant/useMatchAssistant'

interface MatchFinalScoreProps {
  match?: MatchAssistantMatch
}

export const MatchFinalScore: React.FC<MatchFinalScoreProps> = ({ match }) => {
  const { userId } = useUserIdentityFn()
  const { hasSeries } = useEsportContext()
  const {
    currentStep,
    submittedUserIdForNPS,
    refetch,
  } = useMatchAssistantContext()
  const [loading, setLoading] = React.useState(true)

  const homeTeamMatchResults = match?.matchResults?.find(
    (result) => result?.teamSide === 0
  )
  const awayTeamMatchResults = match?.matchResults?.find(
    (result) => result?.teamSide === 1
  )

  const isMatchComplete = currentStep?.matchDetails?.isComplete ?? false
  const isYouthProgram = match?.esport?.rating === EsportRating.Restricted

  // This will show the NPS survey for both Smash and General
  useEffect(() => {
    if (isMatchComplete && submittedUserIdForNPS === userId) {
      analytics.matchAssistantCompletedNPSSurvey({
        userId,
        matchId: match?.id ?? '',
        esportSlug: match?.esport?.slug ?? '',
      })
    }
  }, [])

  const finalScoreData: ScoreboardRow[] = hasSeries
    ? mapSeriesData(
        match,
        homeTeamMatchResults?.teamId ?? '',
        awayTeamMatchResults?.teamId ?? ''
      )
    : mapNonSeriesData(
        match,
        // @ts-ignore
        homeTeamMatchResults,
        awayTeamMatchResults,
        isYouthProgram
      )

  useEffect(() => {
    if (match?.status === MatchStatus.Forfeited) {
      setLoading(false)
    }
    if (
      finalScoreData.some(
        (row) =>
          (row.leftSide.score ?? 0) >= 1 || (row.rightSide.score ?? 0) >= 1
      )
    ) {
      setLoading(false)
    }
  }, [finalScoreData.toString(), match?.status])

  useEffect(() => {
    refetch()
  }, [])

  const slug = match?.esport?.slug

  if (
    slug?.includes('super-smash-bros-ultimate') ||
    slug?.includes('street-fighter')
  ) {
    return (
      <SmashFinalScore
        awayTeamId={awayTeamMatchResults?.teamId ?? ''}
        homeTeamId={homeTeamMatchResults?.teamId ?? ''}
        match={match}
      />
    )
  }

  return (
    <Box mb={2}>
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <NxScoreboardRows
          data={finalScoreData?.[0] ? [finalScoreData?.[0]] : []}
        />
      </WaitTillLoaded>
    </Box>
  )
}
