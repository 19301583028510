import React from 'react'

import { NxEsportsProvider } from '@playvs-inc/nexus-theme'

import { useGetEsportsForNexusQuery } from '@plvs/graphql/generated/graphql'
import { normalizeNexusEsportsData } from './NexusProvider.helpers'

export const NexusProvider: React.FC = ({ children }) => {
  const { data } = useGetEsportsForNexusQuery()

  const nexusData = normalizeNexusEsportsData(data?.esports)

  return <NxEsportsProvider esports={nexusData}>{children}</NxEsportsProvider>
}
