import dayjs from 'dayjs'
import { groupBy } from 'ramda'

import { EsportSlug, MatchStatus } from '@plvs/graphql'

const LIVE = 'A'
const NOT_LIVE = 'B'

export type LowLevelMatchData = {
  status?: MatchStatus
  scheduledStartsAt: string
  teamName: string
  id?: string
  esport: {
    slug: EsportSlug
  }
  slotId: string | null
  teamId?: string
}

export const groupByLive = groupBy((match: LowLevelMatchData) =>
  match.status === MatchStatus.Live || match.status === MatchStatus.Open
    ? LIVE
    : NOT_LIVE
)

export const sortMatches = (
  matches: LowLevelMatchData[]
): LowLevelMatchData[] => {
  const groupedMatches: {
    [index: string]: LowLevelMatchData[]
  } = groupByLive(matches)

  const sortedGroups = [
    groupedMatches[LIVE] || [],
    groupedMatches[NOT_LIVE] || [],
  ].map((group) =>
    group.sort(
      (matchA, matchB) =>
        dayjs(matchA?.scheduledStartsAt).unix() -
        dayjs(matchB?.scheduledStartsAt).unix()
    )
  )

  return sortedGroups.flat()
}
