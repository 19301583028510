import React, { createContext, useContext, useState } from 'react'
import {
  useSchoolLeaguesFiltersQuery,
  SchoolLeaguesFiltersQueryResult,
  PhaseType,
} from '@plvs/graphql/generated/graphql'
import {
  RelativeTiming,
  mapMetaseasonsForFilters,
  MinimalMetaseason,
} from '@plvs/utils'
import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import {
  SetDefaultMetaseason,
  SetDefaultLeague,
  SetDefaultTeam,
} from '@plvs/respawn/features/filters/types'
import { noop } from 'ramda-adjunct'

interface SchoolLeaguesProps {
  setDefaultMetaseason?: SetDefaultMetaseason<SchoolLeaguesFiltersQueryResult>
  setDefaultLeague?: SetDefaultLeague<SchoolLeaguesFiltersQueryResult>
  setDefaultTeam?: SetDefaultTeam<SchoolLeaguesFiltersQueryResult>
  defaultPhaseType?: PhaseType
  includeDeletedSeasonTeam?: boolean
}

const defaultSetMetaseason: SetDefaultMetaseason<SchoolLeaguesFiltersQueryResult> = (
  metaseasons
) =>
  metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
  metaseasons.find((ms) => ms.timing === RelativeTiming.Past)

export const firstNonInitialMetaseason: SetDefaultMetaseason<SchoolLeaguesFiltersQueryResult> = (
  metaseasons
): MinimalMetaseason | undefined => {
  const firstNonInitialMetaseasonResult = metaseasons.find(
    (ms) => ms.timing === RelativeTiming.Present && !ms.isInitialMetaseason
  )
  if (firstNonInitialMetaseasonResult) {
    return firstNonInitialMetaseasonResult
  }
  return (
    metaseasons.find((ms) => ms.timing === RelativeTiming.Present) ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Future) ??
    metaseasons.find((ms) => ms.timing === RelativeTiming.Past)
  )
}

export interface SchoolMetaseasonContextType {
  promotedMetaseason?: MinimalMetaseason
  metaseason?: MinimalMetaseason
  setMetaseason: (id: string) => void
  metaseasons: MinimalMetaseason[]
  loading: boolean
}

const SchoolMetaseasonContext = createContext<SchoolMetaseasonContextType>({
  setMetaseason: noop,
  metaseasons: [],
  loading: true,
})

export const SchoolMetaseasons: React.FC<SchoolLeaguesProps> = ({
  children,
  setDefaultMetaseason = defaultSetMetaseason,
  includeDeletedSeasonTeam = false,
}) => {
  const { id } = useSelectedOrganizationFn()

  const [metaseasonId, setMetaseason] = useState<string>()

  const { data, loading } = useSchoolLeaguesFiltersQuery({
    variables: { schoolId: id ?? '', includeDeletedSeasonTeam },
    skip: !id,
  })

  const eligibleMetaseasons = data?.eligibleMetaseasons ?? []

  const metaseasons = mapMetaseasonsForFilters({
    metaseasons: eligibleMetaseasons,
  })

  const promotedMetaseason = metaseasons.find((ms) => !!ms?.isPromoted)
  const metaseason =
    metaseasons.find((ms) => ms.id === metaseasonId) ??
    setDefaultMetaseason(metaseasons, data)

  return (
    <SchoolMetaseasonContext.Provider
      value={{
        promotedMetaseason,
        metaseasons,
        metaseason,
        loading,
        setMetaseason,
      }}
    >
      {children}
    </SchoolMetaseasonContext.Provider>
  )
}

export const useSchoolMetaseason = (): SchoolMetaseasonContextType =>
  useContext(SchoolMetaseasonContext)
