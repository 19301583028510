import React, { useEffect } from 'react'

import { makeStyles } from '@material-ui/core'

import { Duplicate, Regularcheck, Ban } from '@playvs-inc/nexus-icons'
import { NxIconButton } from '@playvs-inc/nexus-components'
import { useClipboard } from '@plvs/respawn/features/button'

const useStyles = makeStyles((theme) => ({
  text: {
    color: theme.palette.ColorTextAlt,
    marginRight: theme.spacing(2),
  },
  copyButtonLabel: {
    color: theme.palette.ColorTextBase,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
  copyButtonIcon: {
    marginLeft: theme.spacing(1),
    '& svg': {
      height: 20,
      width: 20,
    },
  },
}))

interface GameCodeCopyButtonProps {
  lolGameCode: string | undefined
  gameOrdinal: number | undefined
  isWaitingForTournamentCode: boolean
}

export const GameCodeCopyButton: React.FC<GameCodeCopyButtonProps> = ({
  lolGameCode,
  gameOrdinal,
  isWaitingForTournamentCode,
}) => {
  const classes = useStyles()
  const [thereIsNoCode, setThereIsNoCode] = React.useState(false)
  const { copy, wasRecentlyCopied } = useClipboard(lolGameCode || '')

  let buttonLabel: string
  let icon: JSX.Element
  if (!lolGameCode && thereIsNoCode) {
    buttonLabel = 'Game Code Unavailable'
    icon = <Ban />
  } else if (wasRecentlyCopied) {
    buttonLabel = 'Copied'
    icon = <Regularcheck className={classes.copyButtonIcon} />
  } else {
    buttonLabel = `Game ${gameOrdinal} Code`
    icon = <Duplicate className={classes.copyButtonIcon} />
  }

  useEffect(() => {
    if (!lolGameCode) {
      const timer = setTimeout(() => {
        setThereIsNoCode(true)
      }, 30000) // 30 seconds

      // Cleanup function to clear the timeout if the component unmounts.
      return () => clearTimeout(timer)
    }
    return undefined
  }, [lolGameCode])

  return (
    <NxIconButton
      data-testid="game-code-copy"
      disabled={thereIsNoCode || wasRecentlyCopied}
      fullWidth
      icon={icon}
      id="game-code-copy"
      label={buttonLabel}
      loading={isWaitingForTournamentCode && !thereIsNoCode}
      onClick={copy}
      variant={thereIsNoCode ? 'error' : 'secondary'}
    />
  )
}
