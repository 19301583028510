import React from 'react'
import { Column, useFlexLayout, useTable } from 'react-table'
import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import clsx from 'clsx'
import { FixedSizeList as VirtualList } from 'react-window'

import { EsportSlug } from '@plvs/graphql'
import { NxTypography } from '@playvs-inc/nexus-components'
import { isSafari } from '@plvs/rally/core'
import { Box } from '@plvs/respawn/features/layout'
import { useEsport as useNexusEsport } from '@playvs-inc/nexus-theme'
import { TableColumn } from '../../components/table'
import { PhaseResultData } from './VsRegularStandings'

const useStyles = makeStyles((theme) => ({
  td: {
    fontFamily: 'Whitney, sans-serif',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  tr: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  trOverall: {
    boxShadow: theme.mixins.divider.bottom,
  },
  noBoxShadow: {
    boxShadow: 'none',
  },
  trTeams: {
    boxShadow: theme.mixins.boxShadow.elevation2,
    borderRadius: `0 ${theme.shape.borderRadius * 3}px ${
      theme.shape.borderRadius * 3
    }px 0`,
    marginBottom: theme.spacing(1),
  },
  rowAccent: ({
    esportBackgroundColor,
  }: {
    esportBackgroundColor: string
  }): CreateCSSProperties => ({
    boxShadow: theme.mixins.boxShadow.elevation2,
    borderRadius: `${theme.shape.borderRadius * 3}px 0 0 ${
      theme.shape.borderRadius * 3
    }px`,
    marginBottom: theme.spacing(1),
    borderLeft: `${theme.spacing(1)}px solid ${esportBackgroundColor}`,
  }),
  tableSection: {
    marginBottom: theme.spacing(5),
  },
  sectionHeadingText: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
    fontWeight: 600,
  },
  tableBody: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(0.5),
    overflow: 'hidden',
  },
  myTeamRow: {
    cursor: 'pointer',
  },
}))

export function EsportStandingsTable({
  esportSlug,
  columns,
  overallData,
  teamData,
}: {
  columns: TableColumn<any>[]
  overallData: PhaseResultData[]
  teamData?: PhaseResultData[]
  esportSlug: EsportSlug
}): React.ReactElement {
  const esport = useNexusEsport(esportSlug)
  const classes = useStyles({
    esportBackgroundColor: esport?.palette.baseColor || '#000',
  })
  const tableColumns = columns as readonly Column<PhaseResultData>[]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns: tableColumns,
      data: overallData,
    },
    useFlexLayout
  )

  const {
    getTableBodyProps: getTableBodyPropsTeam,
    rows: rowsTeam,
    prepareRow: prepareRowTeam,
  } = useTable(
    {
      columns: tableColumns,
      data: teamData || [],
    },
    useFlexLayout
  )
  const showMyTeamsSection = Boolean(rowsTeam.length > 0)

  const Row = ({ index, style }): React.ReactElement => {
    const row = rows[index]
    prepareRow(row)

    return (
      <div style={style}>
        <Box
          className={clsx(
            classes.trOverall,
            classes.tr,
            index === rows.length - 1 && classes.noBoxShadow
          )}
          id={row?.original?.id}
          {...row.getRowProps()}
        >
          {row.cells.map((cell) => (
            <Box className={classes.td} {...cell.getCellProps()}>
              {cell.render('Cell')}
            </Box>
          ))}
        </Box>
      </div>
    )
  }

  return (
    <Box>
      <Box className={classes.tableBody} {...getTableProps()} style={{}}>
        <Box mb={showMyTeamsSection ? 3 : 0}>
          {headerGroups.map((headerGroup) => (
            <Box {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Box {...column.getHeaderProps()}>
                  <NxTypography component="div" variant="subtitle1">
                    {column.render('Header')}
                  </NxTypography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
        {showMyTeamsSection ? (
          <Box className={classes.tableSection}>
            <NxTypography
              className={classes.sectionHeadingText}
              variant="subtitle2"
            >
              Your Teams
            </NxTypography>
            <Box {...getTableBodyPropsTeam()}>
              {rowsTeam.map((row) => {
                prepareRowTeam(row)

                const teamId = row?.original?.id ?? ''

                return (
                  <Box
                    key={`${row.id}-my-teams`}
                    className={classes.myTeamRow}
                    display="flex"
                    onClick={(e): void => {
                      e.preventDefault()

                      const element = document.getElementById(teamId)
                      element?.scrollIntoView({
                        behavior: isSafari ? undefined : 'smooth',
                        block: 'center',
                      })
                    }}
                  >
                    <Box className={classes.rowAccent} />
                    <Box
                      className={clsx(classes.trTeams, classes.tr)}
                      {...row.getRowProps()}
                    >
                      {row.cells.map((cell) => (
                        <Box className={classes.td} {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        ) : null}
        <Box className={classes.tableSection} mr-={-1}>
          {showMyTeamsSection && (
            <NxTypography
              className={classes.sectionHeadingText}
              variant="subtitle2"
            >
              Overall
            </NxTypography>
          )}

          <Box {...getTableBodyProps()}>
            <VirtualList height={500} itemCount={rows.length} itemSize={100}>
              {Row}
            </VirtualList>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
