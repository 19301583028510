import React from 'react'
import { Box } from '@material-ui/core'

import { NxTypography } from '@playvs-inc/nexus-components'

import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useLocation } from 'react-router-dom'
import { StyledRouterLink } from '@plvs/respawn/features/route/StyledRouterLink'

interface LoginLinkProps {
  isParentSignup: boolean
  isNasefSignUp: boolean
  shouldRenderParentFields: boolean
  shouldShowPlayerSignup?: boolean
}

export const LoginLink: React.FC<LoginLinkProps> = ({
  isParentSignup,
  isNasefSignUp,
  shouldRenderParentFields,
  shouldShowPlayerSignup,
}) => {
  const location = useLocation()
  const signInCta = isParentSignup
    ? 'Already have a parent account?'
    : 'Already have an account?'

  const loginPath = isNasefSignUp
    ? `${Path.Login}${NASEF_QUERY_PARAM}`
    : `${Path.Login}${location.search}`

  const signupPath = isNasefSignUp
    ? `${Path.Registration}${NASEF_QUERY_PARAM}`
    : `${Path.Registration}${location.search}`

  const userSignupText = shouldShowPlayerSignup
    ? 'Looking to sign up as a player?'
    : 'Looking to sign up as a coach or player?'

  const playerCoachSignup = (
    <Box display="flex" mt={1}>
      <NxTypography variant="body1">{userSignupText}&nbsp;</NxTypography>
      <StyledRouterLink
        data-testid="user-sign-in"
        label="Click here"
        to={signupPath}
      />
    </Box>
  )

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent={['center', 'space-between']}
      mt={3}
    >
      <Box display="flex">
        <NxTypography variant="body1">{signInCta}&nbsp;</NxTypography>
        <StyledRouterLink
          data-testid="sign-in"
          label="Sign in"
          to={loginPath}
        />
      </Box>

      {shouldRenderParentFields ? (
        <Box display="flex" mt={1}>
          <NxTypography variant="body1">
            Looking to sign up as a parent?&nbsp;
          </NxTypography>
          <StyledRouterLink
            data-testid="parent-sign-up"
            label="Click here"
            to={`${Path.ParentSignUp}${location.search}`}
          />
        </Box>
      ) : (
        isParentSignup && playerCoachSignup
      )}
    </Box>
  )
}
