import { ListItem, makeStyles } from '@material-ui/core'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  EsportRating,
  useGetPracticeDaySchedulesQuery,
} from '@plvs/graphql/generated'
import { EsportSlug } from '@plvs/graphql/types'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useAllEsportAdapters } from '@plvs/respawn/features/esport/creator'
import { betterCompact } from '@plvs/utils'
import React from 'react'
import { PracticeDayCard } from './PracticeDayCard'

const useStyles = makeStyles((theme) => ({
  practiceDayListItem: {
    paddingBottom: theme.spacing(1),
  },
}))

interface PracticeDayListProps {
  canView?: boolean
  isCoach?: boolean
  setHasAnnouncements(input: boolean): void
  setLoadingPracticeDayAnnouncements(input: boolean): void
}

export const PracticeDayList: React.FC<PracticeDayListProps> = ({
  canView,
  isCoach,
  setHasAnnouncements,
  setLoadingPracticeDayAnnouncements,
}) => {
  const { orgId } = useUserIdentityFn()
  const allEsportAdapterData = useAllEsportAdapters()
  const styles = useStyles()
  const allEsportSlugs = betterCompact(
    allEsportAdapterData.map((esportAdapter) => {
      const isMaddenSlug = (esportAdapter.slug ?? '').match(/madden/g)
      const slug = isMaddenSlug ? EsportSlug.Madden : esportAdapter.slug
      return esportAdapter.rating === EsportRating.General ? slug : null
    })
  )

  const { data, loading } = useGetPracticeDaySchedulesQuery({
    variables: {
      input: {
        schoolId: orgId ?? '',
      },
    },
    skip: !orgId,
  })

  const esportSlugs = allEsportSlugs

  const isPracticeDaysLive = Boolean(
    data?.getPracticeDaySchedules?.payload?.isLive
  )

  React.useEffect(() => {
    if (isPracticeDaysLive) {
      setHasAnnouncements(true)
    }
  }, [isPracticeDaysLive])

  React.useEffect(() => {
    setLoadingPracticeDayAnnouncements(loading)
  }, [loading])

  return (
    // Only the first announcement will show the loading spinner so that we avoid multiple stacked loading spinners.
    <WaitTillLoaded loading={loading}>
      {isPracticeDaysLive ? (
        <ListItem className={styles.practiceDayListItem}>
          <PracticeDayCard
            canView={canView}
            esportSlugs={esportSlugs}
            isCoach={isCoach}
            isLive={isPracticeDaysLive}
          />
        </ListItem>
      ) : null}
    </WaitTillLoaded>
  )
}
