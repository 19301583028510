// This component is a wrapper around a component hosted by Plasmic.
// Cannot be effectively unit tested.
/* istanbul ignore file */
import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import { PlasmicComponent } from '@plasmicapp/loader-react'

const useStyles = makeStyles((theme) => ({
  widgetContainer: {
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center',
      width: 'calc(100vw - 51px) !important',
    },
  },
}))

export const StudentPromotedEventWidget: React.FC = () => {
  const classes = useStyles()

  return (
    <Box className={classes.widgetContainer}>
      <PlasmicComponent component="StadiumPromotionalWidget" />
    </Box>
  )
}
