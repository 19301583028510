/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useGetLocalReferralSchoolsQuery } from '@plvs/graphql/generated'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxTypography, NxEsportAvatar } from '@playvs-inc/nexus-components'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Avatar, makeStyles } from '@material-ui/core'
import { truncate } from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import dayjs from '@plvs/respawn/init/dayjs'

export const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2),
    minWidth: 54,
  },
  text: {
    marginBottom: theme.spacing(0.5),
  },
  esportAvatar: {
    marginRight: -theme.spacing(1),
  },
  remainingAvatar: {
    height: 30,
    width: 30,
    backgroundColor: theme.palette.ColorBackgroundAccent,
    marginRight: -theme.spacing(1),
    color: theme.palette.common.white,
  },
  competingTitle: {
    marginTop: theme.spacing(5),
  },
}))

interface Props {
  lat: number
  lon: number
  miles?: number
  schoolId: string
}

export const CompetingSchoolsNear: React.FC<Props> = ({
  lat,
  lon,
  miles = 50,
  schoolId,
}) => {
  const classes = useStyles()

  const { data, loading } = useGetLocalReferralSchoolsQuery({
    variables: {
      limit: 5,
      filters: {
        geolocation: {
          miles,
          lat,
          lon,
        },
        hasTeamsEnrolledInCurrentMetaseason: true,
        deleted: false,
      },
    },
  })

  const schools = (data?.getSchoolsES?.schools ?? []).filter(
    (school) => school?.id !== schoolId
  )

  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      {schools.length > 0 && (
        <>
          <NxTypography className={classes.competingTitle} variant="h4">
            Competing Schools Near You
          </NxTypography>

          {schools.map((school) => {
            const teams = school?.teams ?? []
            const numberOfTeams = teams.length

            const esportSlugs = teams
              .map((team) => team?.esport?.slug)
              .sort()
              .slice(0, 5)

            const remainingTeams = numberOfTeams - 5

            return (
              <Box
                key={`${school.id}-near-me`}
                alignItems="flex-start"
                display="flex"
                mt={3}
              >
                <NxUserAvatar
                  avatarUrl={school?.logoUrl}
                  className={classes.avatar}
                  hashId={school.id}
                  size={NxAvatarSize.MD}
                />
                <div>
                  <NxTypography
                    className={classes.text}
                    data-cy="competingSchoolName"
                    variant="body2"
                  >
                    {truncate(school?.name ?? '', 24)}
                  </NxTypography>
                  <NxTypography
                    className={classes.text}
                    data-cy="competingSince"
                    variant="body3"
                  >
                    {numberOfTeams} team{numberOfTeams > 1 ? 's' : ''} |
                    Competing since {dayjs(school?.createdAt).format('YYYY')}
                  </NxTypography>
                  <Box
                    data-cy="competingTeamIcon"
                    display="flex"
                    flexWrap="wrap"
                  >
                    {esportSlugs.map((esport, index) => (
                      <NxEsportAvatar
                        key={`${school.id}-${esport}-${index}`}
                        className={classes.esportAvatar}
                        esport={esport}
                        iconHeight={14}
                        iconWidth={14}
                      />
                    ))}
                    {remainingTeams > 0 && (
                      <Avatar className={classes.remainingAvatar}>
                        <NxTypography
                          colorToken="OverlayColorTextBase"
                          variant="overline"
                        >
                          +{remainingTeams}
                        </NxTypography>
                      </Avatar>
                    )}
                  </Box>
                </div>
              </Box>
            )
          })}
        </>
      )}
    </WaitTillLoaded>
  )
}
