import {
  AllTeamsAccountStatusFns,
  UseAllTeamsAccountStatusFnsReturn,
} from '../models/AllTeamsAccountStatus'
import { useAllTeamsAccountStatus } from './useAllTeamsAccountStatus'

export const useAllTeamsAccountStatusFns = (
  teamIds: string[]
): UseAllTeamsAccountStatusFnsReturn => {
  const [teamsStatus, loading, error] = useAllTeamsAccountStatus(teamIds)

  const allTeamsAccountStatusFns: AllTeamsAccountStatusFns = {
    isFullyConnected: teamsStatus ? teamsStatus.isFullyConnected : false,
    areAllAccountsConnected: teamsStatus
      ? teamsStatus.areAllAccountsConnected
      : false,
    areAllPlatformsSelected: teamsStatus
      ? teamsStatus.areAllPlatformsSelected
      : false,
    getTeamAccountStatus: (teamId: string) => teamsStatus?.teams?.[teamId],
    getPlayerAccountStatus: (teamId: string, playerId: string) =>
      teamsStatus?.teams?.[teamId]?.players?.[playerId],
    getTeamEsportProviderName: (teamId: string) =>
      teamsStatus?.teams?.[teamId]?.esportProviderName,
  }
  return { allTeamsAccountStatusFns, loading, error }
}
