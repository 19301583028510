import React from 'react'
import { Box } from '@plvs/respawn/features/layout/Box'
import { NxTypography, Pill } from '@playvs-inc/nexus-components'
import { TeamsOutlined, MetaseasonsOutlined } from '@playvs-inc/nexus-icons'
import { GetLeagueTeamsQuery } from '@plvs/graphql/generated'
import { Path } from '@plvs/const'
import { Menu, MenuItem } from '@material-ui/core'
import { MoreIconButton } from '@plvs/respawn/features/button/MoreIconButton'

import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './LeagueTeams.styles'
import { getPillProps, getTeamEnrollmentStatus } from './leagueTeamHelpers'

interface Props {
  isCoachAtOrg: boolean
  isEnrolled: boolean
  isYouthProgram: boolean
  metaseasonId: string
  path: string
  isPastRegDate: boolean
  team: NonNullable<NonNullable<GetLeagueTeamsQuery['getTeams']>['teams']>[0]
}

export const LeagueTeam: React.FC<Props> = ({
  isCoachAtOrg,
  isEnrolled,
  isYouthProgram,
  metaseasonId,
  path,
  isPastRegDate,
  team,
}) => {
  const navigate = useNavigate()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleEnrollClick = (): void => {
    navigate(`${Path.Enrollment}/${metaseasonId}`)
    handleClose()
  }

  const handleEditRosterClick = (): void => {
    navigate(path)
    handleClose()
  }

  const teamEnrollmentStatus = getTeamEnrollmentStatus({
    isEnrolled,
  })

  const { label, variant } = getPillProps(teamEnrollmentStatus)

  return (
    <Box
      key={team.id}
      data-testid="LeagueTeams_Card"
      display="flex"
      flexDirection="column"
      pt={4}
    >
      <Box alignItems="center" display="flex" flexDirection="row">
        <NxUserAvatar
          avatarUrl={isYouthProgram ? undefined : team?.school?.logoUrl}
          className={classes.avatar}
          hashId={team.id}
          size={NxAvatarSize.SM}
        />
        <Box display="flex" flex={1} flexDirection="column">
          <NxTypography
            className={classes.name}
            data-testid="teamName"
            variant="body2"
          >
            {team.name ?? ''}
          </NxTypography>
          <Box alignItems="center" display="flex" flexDirection="row">
            <Pill label={label} size="small" variant={variant} />
          </Box>
        </Box>
        {isCoachAtOrg && (
          <>
            <MoreIconButton onClick={handleClick} />
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              getContentAnchorEl={null}
              keepMounted
              onClose={handleClose}
              open={Boolean(anchorEl)}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem
                disabled={isEnrolled || isPastRegDate}
                onClick={handleEnrollClick}
              >
                <MetaseasonsOutlined className={classes.menuItemIcon} />
                <NxTypography>Enroll Team</NxTypography>
              </MenuItem>
              <MenuItem onClick={handleEditRosterClick}>
                <TeamsOutlined className={classes.menuItemIcon} />
                <NxTypography>Edit Roster</NxTypography>
              </MenuItem>
            </Menu>
          </>
        )}
      </Box>
    </Box>
  )
}
