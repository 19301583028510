import React from 'react'
import { Maybe } from '@plvs/graphql/generated'
import SelectObject from './SelectObject'

export interface FilterSlot {
  id: string
  name: string
  startsAt: Maybe<string>
}

interface Props {
  className?: string
  disabled?: boolean
  slot?: FilterSlot
  slots: FilterSlot[]
  setSlot: (id: string) => void
}

const defaultOption = { name: 'Period', id: '' }

export const SlotFilter: React.FC<Props> = ({
  className,
  disabled,
  slot,
  slots,
  setSlot,
}) => {
  const selectedSlot = slot ?? defaultOption

  return (
    <SelectObject<typeof selectedSlot>
      className={className}
      data-testid="LeagueFilter__PhaseSelect"
      disabled={disabled}
      option={selectedSlot}
      options={slots}
      renderValue={(s): string => s?.name ?? 'n/a'}
      setOption={(s): void => setSlot(s?.id ?? '')}
    />
  )
}
