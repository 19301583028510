import React from 'react'

import { ApmRoutes, withTransaction } from '@elastic/apm-rum-react'
import { PrivatePage } from '@plvs/rally/pages/page'
import { ScrollToTop } from '@plvs/rally/pages/route/ScrollToTop'
import { MatchLobbyRenderControllerProvider } from '@plvs/respawn/renderController/matchLobby/MatchLobbyRenderControllerProvider'
import { Lobby } from '@plvs/rally/features/match/lobby/Lobby/Lobby'
import { Route, useParams } from 'react-router-dom'

interface MatchLobbyPageInternalProps {
  breadcrumbHref?: string
}

const MatchLobbyPageInternal: React.FC<MatchLobbyPageInternalProps> = ({
  breadcrumbHref,
}) => {
  const { matchId } = useParams<{ matchId: string }>()
  return (
    <PrivatePage title="Match Lobby">
      <ScrollToTop />
      <MatchLobbyRenderControllerProvider>
        <ApmRoutes>
          <Route
            element={
              <Lobby breadcrumbHref={breadcrumbHref} matchId={matchId ?? ''} />
            }
            path="/*"
          />
        </ApmRoutes>
      </MatchLobbyRenderControllerProvider>
    </PrivatePage>
  )
}

export const MatchLobbyPage = withTransaction(
  'MatchLobbyPage',
  'component'
)(MatchLobbyPageInternal)
