import React, {
  useState,
  useEffect,
  SyntheticEvent,
  useRef,
  useLayoutEffect,
} from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { useSnackbar } from 'notistack'
import { Avatar, TextField, useTheme } from '@material-ui/core'
import { PostMatchSurveyDetailsQuery, Question } from '@plvs/graphql'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  NxButton,
  NxTypography,
  NxModal,
  NxRating,
  NxSelectableSingle,
  NxTextArea,
  NxCheckbox,
} from '@playvs-inc/nexus-components'
import { NxRandomAvatar } from '@playvs-inc/nexus-random-avatars'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import {
  prettyPrintISOString,
  isCoachForScholasticRelatedTeam,
} from '@plvs/utils'

import { useStyles } from './PostMatchSurveyOverlay.styles'

dayjs.extend(tz)
const MAX_CHAR_COMMENT_LENGTH = 1000
const RATING_SCALE = [1, 2, 3, 4, 5]

interface PostMatchSurveyOverlayProps {
  onPrimaryClick({
    funRating,
    surveyComment,
    shouldContact,
    teamId,
  }: {
    funRating?: number
    surveyComment?: string
    shouldContact?: boolean
    teamId?: string
    surveyQuestions?: Question[]
  }): void
  onCancelClick(): void
  setShowPostMatchSurveySuccessStep(
    showPostMatchSurveySuccessStep: boolean
  ): void
  match: PostMatchSurveyDetailsQuery['match'] | null
  loading?: boolean
  submitError?: boolean
  submitSuccess?: boolean
  isSubmitting?: boolean
  esportName: string
  hasSeries: boolean
  isFirstMatch?: boolean
}

const MIN_RATING_FOR_PROMPT = 3

export const PostMatchSurveyOverlayPresentation: React.FC<PostMatchSurveyOverlayProps> = ({
  onPrimaryClick,
  onCancelClick,
  setShowPostMatchSurveySuccessStep,
  match,
  loading,
  submitError,
  submitSuccess,
  isSubmitting,
  esportName,
  hasSeries,
  isFirstMatch,
}) => {
  const firstSurveyRef = useRef<HTMLDivElement>(null)
  const [isDialogOpen, setIsDialogOpen] = useState(true)

  const classes = useStyles()
  const theme = useTheme()
  const { loading: profileContextLoading, roles } = useProfileContext()

  const [funRating, setFunRating] = useState<undefined | number>(undefined)
  const [surveyComment, setSurveyComment] = useState('')
  const [shouldContact, setShouldContact] = useState(false)
  const [firstSurveyContainerSize, setFirstSurveyContainerSize] = useState(465)
  const [firstSurvey, setFirstSurvey] = useState([
    {
      questionText: 'How prepared were you for your first match today?',
      response: '',
    },
    { questionText: 'What went well?', response: '' },
    { questionText: 'What could have gone better?', response: '' },
    {
      questionText: 'Was anything missing form the PlayVS team?',
      response: '',
    },
  ])

  const handleSurveyCommentChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    evt.preventDefault()
    setSurveyComment(evt.target.value)
  }

  const closeDialog = (): void => {
    onCancelClick()
    setIsDialogOpen(false)
  }

  const { enqueueSnackbar } = useSnackbar()

  const startsAt = match?.scheduledStartsAt ?? ''
  const matchResults = match?.matchResults ?? []
  const bestOf = (hasSeries ? match?.seriesBestOf : match?.bestOf) ?? 0

  const isMyTeam1 = match?.team1?.isMyTeam ?? false
  const team1Id = match?.team1?.id ?? 'unknown'
  const team1LogoUrl = match?.team1?.school?.logoUrl ?? ''

  const team2Id = match?.team2?.id ?? 'unknown'
  const team2LogoUrl = match?.team2?.school?.logoUrl ?? ''
  const myTeam = isMyTeam1 ? match?.team1 : match?.team2
  const myTeamId = myTeam?.id
  const myTeamMatchResultsIndex = myTeamId
    ? matchResults.findIndex((result) => result?.teamId === myTeamId)
    : 0

  const myTeamAvatarUrl = isMyTeam1 ? team1LogoUrl : team2LogoUrl
  const oppTeamAvatarUrl = isMyTeam1 ? team2LogoUrl : team1LogoUrl

  const myTeamWins = hasSeries
    ? matchResults[myTeamMatchResultsIndex]?.seriesWon
    : matchResults[myTeamMatchResultsIndex]?.gamesWon ?? 0
  const otherTeamWins = hasSeries
    ? matchResults[myTeamMatchResultsIndex]?.seriesLost
    : matchResults[myTeamMatchResultsIndex]?.gamesLost ?? 0

  const isCoachOfTeam = myTeam
    ? isCoachForScholasticRelatedTeam(roles, myTeam)
    : false

  const shouldShowPrompt =
    isCoachOfTeam &&
    funRating !== undefined &&
    funRating <= MIN_RATING_FOR_PROMPT &&
    Boolean(surveyComment)

  useEffect(() => {
    if (submitSuccess) {
      enqueueSnackbar('Thanks for your feedback!', {
        variant: 'success',
      })
    }
  }, [submitSuccess])

  const onSelect = (_: SyntheticEvent, newValue: number): void => {
    const response = `${newValue + 1}`
    setFirstSurvey((prevState) => {
      return prevState.map((question) => {
        if (question.questionText === firstSurvey[0].questionText) {
          return {
            questionText: question.questionText,
            response,
          }
        }

        return question
      })
    })
  }

  useLayoutEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setFirstSurveyContainerSize(entries[0].contentRect.width - 80)
    })

    if (firstSurveyRef?.current?.offsetWidth) {
      observer.observe(firstSurveyRef?.current)
    }
    return (): void => {
      observer.disconnect()
    }
  }, [firstSurveyRef?.current?.offsetWidth])

  const handleFirstSurveyChange = (
    evt: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ): void => {
    evt.preventDefault()
    const response = evt.target.value

    setFirstSurvey((prevState) => {
      return prevState.map((question) => {
        if (question.questionText === prevState[index].questionText) {
          return {
            questionText: prevState[index].questionText,
            response,
          }
        }

        return question
      })
    })
  }

  const title = isFirstMatch
    ? 'How was your first PlayVS match?'
    : 'A quick fun check...'

  const isDisabled =
    isSubmitting ||
    submitError ||
    (isFirstMatch
      ? firstSurvey.some((question) => question.response === '')
      : !funRating || surveyComment.length > MAX_CHAR_COMMENT_LENGTH)

  const content = isFirstMatch ? (
    <Box className={classes.firstSurveyContainer} mt={3}>
      <NxTypography variant="h4">
        How prepared were you for your first match today?
      </NxTypography>
      <Box ref={firstSurveyRef} mb={3} mt={2}>
        <NxSelectableSingle
          defaultValue={undefined}
          height="47px"
          justifyContent="space-between"
          onChange={onSelect}
          padding={1}
          width={firstSurveyContainerSize / 5}
        >
          {RATING_SCALE.map((rate) => (
            <NxTypography key={`${rate}-rating`}>{rate}</NxTypography>
          ))}
        </NxSelectableSingle>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          px={1}
        >
          <NxTypography variant="body4">Not Prepared</NxTypography>
          <NxTypography variant="body4">Very Prepared</NxTypography>
        </Box>
      </Box>
      <NxTypography className={classes.infoInputTitle} variant="h4">
        What went well?
      </NxTypography>
      <NxTextArea
        cols={30}
        fullWidth
        label="What went well during your first match day?"
        onChange={(evt): void => handleFirstSurveyChange(evt, 1)}
        rows={2.5}
      />
      <NxTypography className={classes.infoInputTitle} variant="h4">
        What could have gone better?
      </NxTypography>
      <NxTextArea
        cols={30}
        fullWidth
        label="What didn't go so well today?"
        onChange={(evt): void => handleFirstSurveyChange(evt, 2)}
        rows={2.5}
      />
      <NxTypography className={classes.infoInputTitle} variant="h4">
        Was anything missing from the PlayVS team?
      </NxTypography>
      <NxTextArea
        cols={30}
        fullWidth
        label="What could we have done better?"
        onChange={(evt): void => handleFirstSurveyChange(evt, 3)}
        rows={2.5}
      />
    </Box>
  ) : (
    <>
      <Box className={classes.ratingContainer} data-cy="rating-title">
        <NxTypography variant="h4">How fun was your match?</NxTypography>
        <NxRating onClick={setFunRating} value={funRating} />
      </Box>

      <Box className={classes.commentContainer}>
        <Box className={classes.commentHeader}>
          <NxTypography
            className={classes.commentHeaderText}
            variant="subtitle1"
          >
            Tell us more!
          </NxTypography>
          <NxTypography
            className={classes.commentHeaderText}
            variant="subtitle2"
          >
            (Optional)
          </NxTypography>
        </Box>

        <TextField
          error={surveyComment.length > MAX_CHAR_COMMENT_LENGTH}
          fullWidth
          helperText={
            surveyComment.length > MAX_CHAR_COMMENT_LENGTH
              ? MAX_CHAR_COMMENT_LENGTH - surveyComment.length
              : ''
          }
          id="surveyComment"
          InputLabelProps={{
            style: { color: 'blue', backgroundColor: 'blue' },
          }}
          InputProps={{
            style: {
              height: 133,
              display: 'flex',
              alignItems: 'flex-start',
              padding: theme.spacing(1, 2),
            },
          }}
          maxRows={5}
          multiline
          onChange={handleSurveyCommentChange}
          placeholder="How could we make your experience more fun?"
          value={surveyComment}
          variant="outlined"
        />
      </Box>

      {shouldShowPrompt && (
        <Box className={classes.checkContainer}>
          <NxCheckbox
            checked={shouldContact}
            onChange={(): void => {
              setShouldContact(!shouldContact)
            }}
          />
          <NxTypography variant="body3">
            I would like to talk to PlayVS about my experience
          </NxTypography>
        </Box>
      )}
    </>
  )

  return (
    <NxModal
      actions={
        <>
          <NxButton label="Cancel" onClick={closeDialog} variant="text" />
          <NxButton
            disabled={isDisabled}
            label="Submit"
            onClick={(): void => {
              const shouldSubmitToSupport = shouldContact && shouldShowPrompt
              closeDialog()
              if (shouldSubmitToSupport) {
                setShowPostMatchSurveySuccessStep(true)
              }
              onPrimaryClick({
                funRating,
                surveyComment,
                shouldContact: shouldSubmitToSupport,
                teamId: myTeamId,
                surveyQuestions: firstSurvey,
              })
            }}
            variant="primary"
          />
        </>
      }
      contentClassName={classes.contentClass}
      onClose={closeDialog}
      open={isDialogOpen}
      showTopRightClose
      size="large"
      title={title}
      titleClassName={classes.titleClass}
      titleDataCy="fun-survey-title"
    >
      <WaitTillLoaded
        loading={loading || profileContextLoading}
        showSpinnerWhileLoading
      >
        <Box className={classes.body}>
          <Box className={classes.matchInfo}>
            <Box>
              <NxTypography variant="body1">
                {`${esportName ?? 'Unknown'}, Best of ${bestOf}`}
              </NxTypography>
              <NxTypography variant="body2">
                {`${
                  isMyTeam1 ? match?.team1?.name : match?.team2?.name ?? '???'
                } vs ${
                  isMyTeam1 ? match?.team2?.name : match?.team1?.name ?? '???'
                }`}
              </NxTypography>
              <NxTypography variant="body1">
                {prettyPrintISOString({
                  isostring: startsAt,
                  timezone: dayjs.tz.guess(),
                })}
              </NxTypography>
            </Box>

            <Box className={classes.scoreContainer}>
              <Box className={classes.vsContainer}>
                {myTeamAvatarUrl ? (
                  <Avatar
                    alt="avatar"
                    className={classes.avatar}
                    src={myTeamAvatarUrl}
                  />
                ) : (
                  <NxRandomAvatar
                    hashId={isMyTeam1 ? team1Id : team2Id}
                    height={26}
                    viewBox="0 0 150 150"
                    width={26}
                  />
                )}
                <NxTypography className={classes.vsText} variant="body4">
                  vs
                </NxTypography>
                {oppTeamAvatarUrl ? (
                  <Avatar
                    alt="avatar"
                    className={classes.avatar}
                    src={oppTeamAvatarUrl}
                  />
                ) : (
                  <NxRandomAvatar
                    hashId={isMyTeam1 ? team2Id : team1Id}
                    height={26}
                    viewBox="0 0 150 150"
                    width={26}
                  />
                )}
              </Box>
              <NxTypography variant="h4">
                {myTeamWins} - {otherTeamWins}
              </NxTypography>
            </Box>
          </Box>

          {content}
        </Box>
      </WaitTillLoaded>
    </NxModal>
  )
}
