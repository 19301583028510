import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import {
  UserRoleName,
  useMyOnboardReferralQuery,
  ResourceType,
  useGenerateReferralLinkMutation,
} from '@plvs/graphql/generated/graphql'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { OnboardBox, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Container } from '@material-ui/core'
import { OnboardReferUser } from '@plvs/rally/containers/onboard/OnboardReferUser'
import { StudentOrFaculty } from '@plvs/rally/containers/onboard/ReferUser'
import { assert } from '@plvs/utils'
import { noop } from 'ramda-adjunct'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

export const getCardProps = (
  userRoleName: StudentOrFaculty,
  isFaculty: boolean
): {
  subtitle: string
  title: string
} => {
  if (userRoleName === UserRoleName.Student) {
    return {
      subtitle:
        'Your school is full of students who want to play. Invite them to join your program and compete in the titles they love by sharing the link below or sending them an email.',
      title: 'Invite Players',
    }
  }
  return {
    subtitle: isFaculty
      ? `Have someone in mind who would make a great coach? Invite them to start their school's esports program by sharing the link below or sending them an email.`
      : 'Got a favorite teacher? Now‘s your chance to make ‘em your favorite coach. Use the link below to nominate a teacher, administrator, or any other school employee to coach your esports team.',
    title: 'Invite a Coach',
  }
}

const incentive = {
  subtitle: `If a new coach signs up with your referral link and enrolls a team for Spring 2021, you'll get a $500 Amazon gift card.`,
  title: 'Refer a Coach',
}

const InviteMap = {
  'invite-player': UserRoleName.Student,
  'invite-coach': UserRoleName.Fac,
}

export enum InviteTypes {
  Player = 'invite-player',
  Coach = 'invite-coach',
}

export const ReferPage: React.FC = () => {
  const { invite } = useParams<{ invite: InviteTypes }>()
  const refer = (InviteMap[invite ?? ''] ||
    UserRoleName.Fac) as StudentOrFaculty
  const { loading, data, error } = useMyOnboardReferralQuery()
  const { isFacultyAtOrg, userRoles } = useUserIdentityFn()

  const [
    generateReferralLink,
    { data: referralLinkData, loading: referralLoading },
  ] = useGenerateReferralLinkMutation()

  /** This is seasonal and usually utilized before the season starts
   * Marketing has asked that we turn off the current incentive
   * see const incentive above
   */
  const hasIncentive = false
  const isStudentReferral = refer === UserRoleName.Student
  const source = isStudentReferral
    ? 'refer_page_invite'
    : 'refer_page_nomination'

  const props = hasIncentive ? incentive : getCardProps(refer, isFacultyAtOrg)
  const { title, subtitle } = props

  const referralLink = referralLinkData?.generateReferralLink

  useEffect(() => {
    if (data?.me?.school) {
      const schoolId = data?.me?.school?.id
      const competitionGroup = data?.me?.school?.competitionGroup

      try {
        assert(schoolId)
        assert(competitionGroup)
        generateReferralLink({
          variables: {
            resourceId: schoolId,
            resourceType: ResourceType.System,
            roleName: UserRoleName.User,
            source,
            medium: 'share_link',
            competitionGroup,
          },
        })
      } catch (err: any) {
        noop(err)
      }
    }
  }, [data?.me?.school])

  if (error)
    return (
      <Banner
        subtitle={(error && error.message) || ''}
        title="Error"
        type={BannerType.Error}
      />
    )

  return (
    <WaitTillLoaded
      loading={loading || !referralLinkData || referralLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <OnboardBox>
        <Container maxWidth="md">
          <QuestionnaireCard childWidth={460} subtitle={subtitle} title={title}>
            <OnboardReferUser
              competitionGroup={data?.me?.school?.competitionGroup}
              hasIncentive={hasIncentive}
              isFaculty={isFacultyAtOrg}
              myUserRoles={userRoles}
              refer={refer}
              referralLink={referralLink}
              source={source}
            />
          </QuestionnaireCard>
        </Container>
      </OnboardBox>
    </WaitTillLoaded>
  )
}
