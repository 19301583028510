import React from 'react'
import {
  AccordionCard,
  AccordionCardTab,
} from '@plvs/rally/components/accordionCard/AccordionCard'
import { makeStyles, useTheme } from '@material-ui/core'
import {
  EsportSlug,
  useGetEsportBySlugQuery,
  useGetSchoolTeamsByEsportQuery,
} from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { Link } from 'react-router-dom'
import { Path } from '@plvs/const'
import { Badge } from '@plvs/rally/components/badge'
import { useAutoskipQuery } from '@plvs/utils'
import { NxTypography } from '@playvs-inc/nexus-components'

import { CreateCSSProperties } from '@material-ui/styles'
import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  EnrolledPlayerOnTeam,
  EnrollmentLeague,
  SeasonEnrollmentRangeData,
  TeamLeagueSelectFn,
} from '@plvs/respawn/containers/enrollment/types'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { countTeamsInSeason } from '@plvs/rally/features/enrollment/Row/TeamRow.Select.helper'
import { EnrollmentTeamRow } from './EnrollmentTeamRow'

interface CardProps {
  enrolledPlayersOnTeam: EnrolledPlayerOnTeam[]
  esportId: string
  esportSlug: EsportSlug
  metaseasonId: string
  onPlayersSelected: TeamLeagueSelectFn
  schoolId: string | undefined
  leagues: EnrollmentLeague[]
  isAdmin: boolean
  seasonSlotExclusionRangeData: SeasonEnrollmentRangeData
  backgroundColor?: string
  addEnrollmentSeasonIds: (seasonIds: string[]) => void
  removeEnrollmentSeasonId: (seasonId: string) => void
}

const useStyles = makeStyles((theme) => ({
  container: ({
    backgroundColor,
  }: {
    backgroundColor: string | undefined
  }): CreateCSSProperties => ({
    display: 'flex',
    verticalAlign: 'top',
    marginBottom: theme.spacing(2),
    border: `1px solid ${theme.palette.BorderLight}`,
    borderRadius: '8px',
    padding: theme.spacing(3),
    backgroundColor: backgroundColor || theme.palette.ColorBackgroundBase,
  }),
  noTeams: {
    color: theme.palette.ColorTextAlt2,
  },
  link: {
    fontWeight: 'bold',
    textDecorationLine: 'none',
    color: theme.palette.ColorTextAlt2,
    borderBottom: `1px solid ${theme.palette.ColorTextAccent}`,
  },
  subsTab: {
    maxHeight: '875px',
  },
}))

export const EnrollmentEsportCard: React.FC<CardProps> = ({
  leagues,
  esportId,
  esportSlug,
  schoolId,
  metaseasonId,
  onPlayersSelected,
  addEnrollmentSeasonIds,
  removeEnrollmentSeasonId,
  enrolledPlayersOnTeam,
  isAdmin,
  seasonSlotExclusionRangeData,
  backgroundColor,
}) => {
  const theme = useTheme()
  const { metaseasons } = useSchoolLeagueInfoContext()
  const metaseason = metaseasons.find((ms) => ms?.id === metaseasonId)
  const { name } = useEsportContext()
  const { data } = useGetSchoolTeamsByEsportQuery({
    variables: {
      esportId,
      schoolId: schoolId || '',
      metaseasonId,
    },
    skip: !schoolId || !esportId || !metaseasonId,
    fetchPolicy: 'network-only',
  })

  const classes = useStyles({ backgroundColor })
  const schoolTeams = data?.school?.teams ?? []
  // GP-2112 - temporarily allowing non-full rosters to enroll.
  // const leagueDefaultTeamFormat = data?.esport?.leagueDefaults?.teamFormat ?? 0

  const enrollableTeamCount = schoolTeams?.filter((team) => {
    // GP-2112 - temporarily allowing non-full rosters to enroll.
    // const hasFullRoster =
    //   team.members?.filter(
    //     (member) => member.position < leagueDefaultTeamFormat
    //   ).length === leagueDefaultTeamFormat
    // return hasFullRoster && team
    return !!team
  }).length

  const { data: esportData } = useAutoskipQuery(useGetEsportBySlugQuery, {
    variables: { slug: esportSlug },
  })

  if (!schoolTeams.length) {
    return null
  }

  return (
    <AccordionCard
      badge={
        esportData?.esport?.isPromoted ? (
          <Badge
            backgroundColor={theme.palette.ColorBackgroundSuccess ?? ''}
            color={theme.palette.ColorTextInfo ?? ''}
            text="New"
          />
        ) : (
          <></>
        )
      }
      className={classes.container}
      esportSlug={esportSlug}
      hideTabIfSingle
      initialToggle={!!enrollableTeamCount}
      title={name}
      titleDetail={`${enrollableTeamCount} teams ready to be enrolled`}
    >
      <AccordionCardTab tab="Your Teams">
        {schoolTeams.length ? (
          <>
            {schoolTeams.map((teamData) => {
              const enrolledLeagueId = teamData?.leagues?.find(
                (league) => league.esportId === esportId
              )?.id
              const enrolledLeague = leagues.find(
                ({ id }) => id === enrolledLeagueId
              )
              const esportLeagues = leagues
                .filter((league) => {
                  return league.esport.id === esportId
                })
                .map((l) => {
                  return {
                    ...l,
                    numTeamsInSeasonForSchool: countTeamsInSeason(
                      l,
                      enrolledPlayersOnTeam
                    ),
                  }
                })
              return (
                <EnrollmentTeamRow
                  key={teamData.id}
                  addEnrollmentSeasonIds={addEnrollmentSeasonIds}
                  enrolledLeague={enrolledLeague}
                  enrolledPlayersOnTeam={enrolledPlayersOnTeam}
                  isAdmin={isAdmin}
                  leagues={esportLeagues}
                  metaseasonId={metaseasonId}
                  onChange={onPlayersSelected}
                  removeEnrollmentSeasonId={removeEnrollmentSeasonId}
                  seasonSlotExclusionRangeData={seasonSlotExclusionRangeData}
                  teamId={teamData.id}
                />
              )
            })}
          </>
        ) : (
          <Box mt={5}>
            <NxTypography
              className={classes.noTeams}
              component="span"
              variant="body2"
            >
              <Link className={classes.link} to={Path.CreateTeam}>
                Create a team
              </Link>
              {` to enroll in this esport for the ${
                metaseason?.name ?? ''
              } season`}
            </NxTypography>
          </Box>
        )}
      </AccordionCardTab>
    </AccordionCard>
  )
}
