import React from 'react'
import { makeStyles } from '@material-ui/core'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import {
  BirthdayInput,
  BirthdaySchema,
  isUnderage,
} from '@plvs/rally/features/auth/utils/helpers'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'
import { EligibilityCallout } from '@plvs/rally/features/login/EligibilityCallout'
import dayjs from '@plvs/respawn/init/dayjs'
import { BirthdaySelectController } from '@plvs/respawn/features/form/BirthdaySelectController'
import { useNavigate } from 'react-router-dom'
import { Path, ChildOnboardPath } from '@plvs/const'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  inputs: {
    alignItems: 'center',
    display: 'flex',
    gridColumnGap: '8px',
    maxWidth: 460,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      gridRowGap: '8px',
    },
  },
  errorText: {
    marginTop: theme.spacing(1),
  },
  continueButton: {
    marginTop: theme.spacing(3),
  },
}))

export const OnboardChildBirthday: React.FC = () => {
  const classes = useStyles()

  const [hasError, setHasError] = React.useState(false)
  const navigate = useNavigate()

  const { handleSubmit, control } = useForm<BirthdayInput>({
    resolver: yupResolver<BirthdayInput>(BirthdaySchema),
  })

  const { assign } = useOnboardingContext()

  const onSubmit = (data: BirthdayInput): void => {
    const date = dayjs(
      `${data.year}-${data.month}-${data.day}`,
      'YYYY-M-D',
      true
    )

    if (!date.isValid()) {
      setHasError(true)
      return
    }

    assign({ dateOfBirth: date.toISOString() })

    if (isUnderage(date)) {
      navigate(
        `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildEmail}`
      )
    } else {
      navigate(
        `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildOver13}`
      )
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <QuestionnaireCard
        childWidth={466}
        subtitle="Please enter your child’s date of birth"
        title="Create an Account for your Child"
      >
        <BirthdaySelectController control={control} />
        <EligibilityCallout isParentSignup={false} />

        {hasError && (
          <NxTypography
            className={classes.errorText}
            colorToken="ColorTextError"
            variant="body3"
          >
            Unfortunately we are unable to continue creating your account.
          </NxTypography>
        )}

        <NxButton
          className={classes.continueButton}
          fullWidth
          label="Continue"
          type="submit"
          variant="primary"
        />
      </QuestionnaireCard>
    </form>
  )
}
