import { styled, Theme } from '@material-ui/core'
import { SelectOption, StateAbbr } from '@plvs/const'
import {
  eqProps,
  groupBy,
  map,
  mapObjIndexed,
  toPairs,
  uniqWith,
  reduce,
  intersection,
} from 'ramda'
import { compact } from 'ramda-adjunct'
import SanitizeFilter from 'bad-words'
import { UserRoleName } from '@plvs/graphql/generated'

// regex
export const alphaNumericRegex = /^[A-z0-9\s]+$/

// types

type StringOrNumber = string | number
export type SanitizedStringRecord = {
  isProfane: boolean
  cleaned: string
}
type AsyncSeriesHOF<Return, Param> = (arg: Param) => Promise<Return>

// enums

export enum CountryCode {
  UnitedStates = 'US',
  Canada = 'CA',
}

// utils

export function* iteratorOfN(n: number): Iterable<number> {
  for (let i = 0; i < n; i += 1) {
    yield i
  }
}

/**
 * Creates a simple array for iteration map of form
 * [0, 1, 2, ..., n]
 * @param n number of elements in the array
 */
export function arrayOfN(n: number): number[] {
  return Array.from(iteratorOfN(n))
}

export const r = (values: StringOrNumber[]): Record<string, unknown> => ({
  ...(values[0] && { xs: values[0] }),
  ...(values[1] && { sm: values[1] }),
  ...(values[2] && { md: values[2] }),
  ...(values[3] && { lg: values[3] }),
  ...(values[4] && { xl: values[4] }),
})

const mapArrayToValue = (
  index: number,
  props: Record<string, any>
): Record<string, any> =>
  mapObjIndexed(
    (values: StringOrNumber[]): StringOrNumber => values[index],
    props
  )

// Do not use one of these functions more than once at the same level
//  within a MUI style object. The ultimate instance will invalidate all
//  previous instances.
//
// BAD:
// withStyles(
//   (theme: Theme): object => ({
//     root: {
//       ...rup(theme, {
//         fontWeight: [600, 600, 700], // I will go bye-bye
//       }),
//       ...rup(theme, {
//         fontWeight: [600, 600, 700],
//       }),
//     },
//   })
// )
//
// GOOD:
// withStyles(
//   (theme: Theme): object => ({
//     root: {
//       ...rup(theme, {
//         fontWeight: [600, 600, 700],
//         fontWeight: [600, 600, 700],
//       }),
//       ...ronly(theme, {
//         fontFamily: ['serif', 'sans-serif'],
//       }),
//     },
//   })
// )

const rbase = (
  fnName: 'only' | 'up' | 'down'
): ((theme: Theme, props: Record<string, any>) => Record<string, any>) => (
  theme: Theme,
  props: Record<string, any>
): Record<string, any> => ({
  ...{ [theme.breakpoints[fnName]('xs')]: mapArrayToValue(0, props) },
  ...{ [theme.breakpoints[fnName]('sm')]: mapArrayToValue(1, props) },
  ...{ [theme.breakpoints[fnName]('md')]: mapArrayToValue(2, props) },
  ...{ [theme.breakpoints[fnName]('lg')]: mapArrayToValue(3, props) },
  ...{ [theme.breakpoints[fnName]('xl')]: mapArrayToValue(4, props) },
})

export const ronly = rbase('only')
export const rup = rbase('up')
export const rdown = rbase('down')

// Get typed props and theme into the MUI styled function!
//
// ex.
// const ToggleRedBox = ttyled<BoxProps & { isRed: boolean }>(Box, props => ({
//   background: props.isRed ? props.theme.pallette.red,
// }))
//
export function ttyled<Props>(
  Component: React.FC | React.ComponentClass,
  fn: (props: Props & { theme: Theme }) => any
): React.FC<Props> {
  // @ts-ignore
  return styled<React.FC<Props>>(Component)(fn)
}

export function betterCompact<T>(list: (T | null | undefined)[]): T[] {
  return compact(list)
}

// This function filters an array of two types, returning an array of one type.
//
// ex. (string | number)[] => string[]
//
// You may have to explicitly pass types T and U for it to type properly.
//
// ex. filterType<string, number>(myFn, myData)
export function filterType<T, U>(
  fn: (item: T | U) => T | null,
  list: (T | U)[]
): T[] {
  return compact(map(fn, list))
}

export const typedGroupBy = <U, V extends string | number>(
  fn: (x: U) => V,
  arr: U[]
): Record<V, U[]> =>
  groupBy(
    fn as any, // groupBy can handle a function that returns a number, despite what the types say
    arr
  ) as Record<V, U[]>

export const typedToPairs = <U extends string | number | symbol, V>(
  obj: Record<U, V>
): [U, V][] => toPairs(obj) as Array<[U, V]>

export const uniqueById = <T extends { id: string }>(arr: T[]): T[] =>
  uniqWith(eqProps('id'), arr)

export const uniqueByTeamId = <T extends { teamId: string }>(arr: T[]): T[] =>
  uniqWith(eqProps('teamId'), arr)

export const slugify = (input: string): string =>
  input.toLowerCase().split(' ').join('-')

// Use this function when you get conflicts on `__typename` but duck typing is OK
export const stripTypename = <T extends { __typename?: string }>({
  __typename,
  ...rest
}: T): Omit<T, '__typename'> => rest

export const getTypedStateOptions = (
  stateNames: Record<StateAbbr, string>
): SelectOption[] =>
  typedToPairs<StateAbbr, string>(stateNames).map(
    ([value, label]: [string, string]): SelectOption => ({
      label,
      value,
    })
  )

export const parseStringNewLines = (textWithNewLines: string): string[] =>
  textWithNewLines.split(/[\r\n]+/)

export const appendClasses = (...args: (string | null | undefined)[]): string =>
  args.filter((x) => !!x).join(' ')

/**
 * Picks a random item from a sample of similar items.
 * @param choices
 */
export const sample = <T>(choices: T[]): T => {
  const randIndex = Math.floor(Math.random() * choices.length)
  return choices[randIndex]
}

/**
 * Thrown when an assertion fails.
 */
export class AssertionError extends Error {}

/**
 * Simple assertion guard.  Will throw on non-truthy values
 * @param value
 */
export function assert(
  condition: unknown,
  message?: string
): asserts condition {
  if (!condition) {
    throw new AssertionError(message ?? undefined)
  }
}

/**
 * Sorts an array in alphabetical order regardless of case
 * example: 'aAbBcCdD'
 * @param a string
 * @param b string
 */
export const caseInsensitiveSort = (a: string, b: string): number =>
  a.toLowerCase().localeCompare(b.toLowerCase())

/**
 * Returns an object that contains values in `objB` where `objB` is different
 * than `objA`
 */
export const recordDiffRight = (
  objA: Record<string, unknown>,
  objB: Record<string, unknown>
): Record<string, unknown> => {
  const objAKeys = Object.keys(objA)
  const objBKeys = Object.keys(objB)

  const diffObj: Record<string, unknown> = {}

  objAKeys.forEach((key) => {
    if (objA[key] !== objB[key]) {
      diffObj[key] = objB[key]
    }
  })

  objBKeys.forEach((key) => {
    if (objA[key] !== objB[key]) {
      diffObj[key] = objB[key]
    }
  })

  return diffObj
}

export const sanitizeString = (input: string): SanitizedStringRecord => {
  const filter = new SanitizeFilter()
  const isProfane = filter.isProfane(input)
  let cleaned: string
  try {
    cleaned = filter.clean(input)
  } catch {
    cleaned = !isProfane ? input : ''
  }
  return {
    isProfane,
    cleaned,
  }
}

export const PLAYVS_HELP_ARTICLES_BASE_URL =
  'https://help.playvs.com/en/articles/'
export const PLAYVS_HS_COMP_RULEBOOK_ARTICLE_ID = '5464981'

export const getArticleIdFromRulebookUrl = (
  rulebookUrl?: string | null
): string => {
  const parsedArticleId = rulebookUrl
    ? rulebookUrl.replace(PLAYVS_HELP_ARTICLES_BASE_URL, '')
    : ''
  return parsedArticleId.length
    ? parsedArticleId
    : PLAYVS_HS_COMP_RULEBOOK_ARTICLE_ID
}

export const asyncSeries = async <Return, Param>(
  fn: AsyncSeriesHOF<Return, Param>,
  arr: Array<Param>,
  defaultArg: Return
): Promise<Array<Return>> => {
  let count = 0
  const results: Array<Return> = []
  // NOTE: push the last result with this push
  results.push(
    await reduce(
      async (promise, arg): Promise<any> => {
        return promise.then(async (lastResolve) => {
          if (count) {
            results.push(lastResolve)
          }
          const resolvedValue = await fn(arg)
          count += 1
          return resolvedValue
        })
      },
      Promise.resolve(defaultArg),
      arr
    )
  )
  return results
}

export const numberToPhoneFormat = (phoneNumber: string): string => {
  return phoneNumber
    .replace(/\D/g, '')
    .replace(
      /(\d*)(\d{3})(\d{3})(\d{4})$/,
      (s, a, b, c, d) => `+${a} (${b}) ${c}-${d}`
    )
    .replace(/\+(1\b|\s)\s*/, '')
}

export const getPath = (locationUrl: string): string => {
  return locationUrl.split('/').slice(0, 2).join('/')
}

export const checkIfAllowedByRoles = ({
  userRoleNames,
  requireAnyOf,
}: {
  userRoleNames: UserRoleName[]
  requireAnyOf: UserRoleName[]
}): boolean => {
  return !!intersection(userRoleNames, requireAnyOf).length
}

export const IS_UNDERAGE_EVENT_NAME = 'isUnderage'
