import React from 'react'
import clsx from 'clsx'
import { includes } from 'ramda'
import tz from 'dayjs/plugin/timezone'

import {
  NxEsportIcon,
  NxTooltip,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { NxMiscAssets } from '@playvs-inc/nexus-misc-assets'

import { EsportRating, MatchStatus } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { Box } from '@plvs/respawn/features/layout'
import {
  getInvalidMatchResults,
  getTooltip,
  INVALID_RESULTS_MATCH_STATUSES,
} from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import { LiveIndicator } from '@plvs/rally/features/app/drawer/matches/LiveIndicator'
import { MatchResults } from '@plvs/rally/features/explore/LeagueMatches/MatchResults'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { Grid, useTheme } from '@material-ui/core'
import dayjs from '@plvs/respawn/init/dayjs'
import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { Path } from '@plvs/const'
import { useNavigate } from 'react-router'
import { useNxEsportsContext } from '@playvs-inc/nexus-theme'
import { useStyles } from './MatchRow.styles'

dayjs.extend(tz)

const { FirebobDisabled } = NxMiscAssets

interface MatchRowMobileProps {
  className?: string
  match: MappedMatch
  showEsportAccent?: boolean
  actions: React.ReactNode
  isQueueMatch?: boolean
}

export const MatchRowMobile: React.FC<MatchRowMobileProps> = ({
  className,
  match,
  showEsportAccent = false,
  actions,
  isQueueMatch,
}) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const now = dayjs()
  const isMatchLive = match?.status === MatchStatus.Live

  const esportPalettes = useNxEsportsContext()
  const classes = useStyles({
    isMatchLive,
    showEsportAccent,
    esportSlug: match?.esport?.slug,
    esportPalettes,
  })

  const esportSlug = match?.esport?.slug
  const { name: esportName } = useGeneralEsportAdapter(esportSlug)

  const matchResults = match?.matchResults ?? []

  const team1 = matchResults?.[0]?.team
  const team2 = matchResults?.[1]?.team

  const isPastMatch =
    !isMatchLive && dayjs(match?.scheduledStartsAt).isBefore(now)

  const hasValidResults =
    matchResults.length >= 2 &&
    !includes(match?.status, INVALID_RESULTS_MATCH_STATUSES)

  const team1Lost =
    hasValidResults && isPastMatch && matchResults[0]?.placing === 2
  const team2Lost =
    hasValidResults && isPastMatch && matchResults[1]?.placing === 2

  const isRestricted = match?.esport?.rating === EsportRating.Restricted
  const matchTime = dayjs(match?.scheduledStartsAt).format('h:mm A')

  const navigateToMatch = (): void => {
    const lobbyUrl = isQueueMatch
      ? `/app/queue-lobby/${match?.slot?.id}/${match?.team1?.id}`
      : `${Path.Match}/${match?.id}`

    navigate(lobbyUrl)
  }

  return (
    <Box
      alignItems="center"
      className={clsx(classes.root, className)}
      data-cy="teamMatchRow"
      data-testid="match-row"
      display="flex"
      flexDirection="column"
      gridGap={theme.spacing(0.5)}
      justifyContent="center"
      onClick={navigateToMatch}
      p={3}
      position="relative"
    >
      <Box position="absolute" right={theme.spacing(1)} top={theme.spacing(2)}>
        {actions}
      </Box>
      {getInvalidMatchResults(match?.status)}

      <NxTypography
        className={classes.matchDate}
        data-testid="matchDate"
        variant="body2"
      >
        {matchTime}
      </NxTypography>
      <NxTypography
        className={classes.matchDate}
        colorToken="ColorTextAlt"
        data-testid="esportName"
        variant="body3"
      >
        {esportName}
      </NxTypography>

      <Box mt={1.5}>
        <Grid alignItems="center" container justifyContent="center" spacing={2}>
          <Grid item xs={5}>
            <Box textAlign="right">
              <NxTypography className={classes.leftTeam} noWrap variant="body2">
                {team1?.name ?? 'N/A'}
              </NxTypography>
              {team1?.school?.name && (
                <NxTypography noWrap variant="body4">
                  {team1?.school?.name}
                </NxTypography>
              )}
            </Box>
          </Grid>

          <Grid item xs={2}>
            <NxTypography className={classes.vsText} variant="body1">
              VS
            </NxTypography>
          </Grid>

          <Grid item xs={5}>
            <NxTypography noWrap variant="body2">
              {team2?.name ?? 'N/A'}
            </NxTypography>
            {team2?.school?.name && (
              <NxTypography noWrap variant="body4">
                {team2?.school?.name}
              </NxTypography>
            )}
          </Grid>

          <Grid item xs={5}>
            <Box display="flex" justifyContent="flex-end">
              <NxUserAvatar
                avatarUrl={isRestricted ? undefined : team1?.avatarUrl}
                className={clsx(
                  classes.avatarLeft,
                  team1Lost && classes.opaqueTeam
                )}
                hashId={team1?.id ?? ''}
                size={NxAvatarSize.SM}
              />
            </Box>
          </Grid>

          <Grid item xs={2}>
            {isMatchLive ? (
              <LiveIndicator
                isLive={isMatchLive}
                typographyClassName={classes.liveText}
                typographyVariant="body2"
              />
            ) : (
              <Box display="flex" justifyContent="center">
                {isPastMatch ? (
                  <Box alignItems="center" display="flex" mx={1}>
                    {hasValidResults ? (
                      <MatchResults
                        leftTeam={{
                          score: matchResults[0]?.gamesWon,
                          placing: matchResults[0]?.placing,
                        }}
                        rightTeam={{
                          score: matchResults[1]?.gamesWon,
                          placing: matchResults[1]?.placing,
                        }}
                      />
                    ) : (
                      <NxEsportIcon
                        data-testid="esport-icon"
                        esportSlug={esportSlug ?? ''}
                        mode={theme.palette.type}
                      />
                    )}
                  </Box>
                ) : (
                  <NxEsportIcon
                    data-testid="esport-icon"
                    esportSlug={esportSlug ?? ''}
                    mode={theme.palette.type}
                  />
                )}
              </Box>
            )}
          </Grid>

          <Grid item xs={5}>
            {hasValidResults ? (
              <NxUserAvatar
                avatarUrl={isRestricted ? undefined : team2?.avatarUrl}
                className={clsx(
                  classes.avatarRight,
                  team2Lost && classes.opaqueTeam
                )}
                hashId={team2?.id ?? ''}
                size={NxAvatarSize.SM}
              />
            ) : (
              <NxTooltip
                arrow
                placement="right"
                title={getTooltip(match?.status)}
              >
                <div>
                  <FirebobDisabled height={40} width={40} />
                </div>
              </NxTooltip>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
