import React from 'react'

import { NxTypography } from '@playvs-inc/nexus-components'
import { Box, makeStyles } from '@material-ui/core'

import { ScreenshotSizeLimit } from '@plvs/rally/components/form/attach/attachHelpers'
import { LobbyTeam } from '@plvs/utils'
import {
  SeriesAttachments,
  SetAttachmentsFn,
} from './AttachScreenshotsButton.helpers'
import { AttachGame } from './AttachGame'

const useStyles = makeStyles((theme) => ({
  seriesTitle: {
    color: theme.palette.ColorTextBase,
    marginBottom: theme.spacing(3),
  },
}))

interface AttachSeriesProps {
  seriesResult: any
  series: any
  getPlayerName(team?: LobbyTeam | null | undefined): string | null | undefined
  attachments: SeriesAttachments
  setAttachments: SetAttachmentsFn
  screenshotSizeLimit: ScreenshotSizeLimit
}

export const AttachSeries: React.FC<AttachSeriesProps> = ({
  seriesResult,
  series,
  getPlayerName,
  attachments,
  setAttachments,
  screenshotSizeLimit = '3MB',
}) => {
  const styles = useStyles()

  return (
    <>
      <Box pb={3}>
        <NxTypography variant="body1">
          You may upload multiple screenshots for each game played. Acceptable
          file types include JPG, GIF, and PNG.
        </NxTypography>
      </Box>
      {series && (
        <NxTypography className={styles.seriesTitle} variant="overline">
          Series {seriesResult.ordinalNumber} -{' '}
          <>
            {getPlayerName(series.team1)} vs {getPlayerName(series.team2)}
          </>
        </NxTypography>
      )}

      {seriesResult.gameResults.map((gameResult: any) => (
        <AttachGame
          key={seriesResult.id}
          attachments={attachments}
          gameOrdinal={gameResult.ordinalNumber}
          screenshotSizeLimit={screenshotSizeLimit}
          seriesId={seriesResult.id}
          setAttachments={setAttachments}
        />
      ))}
    </>
  )
}
