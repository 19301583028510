import React, { ChangeEvent, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import * as yup from 'yup'
import { useSnackbar } from 'notistack'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { yupResolver } from '@hookform/resolvers'
import { useForm } from 'react-hook-form'

import {
  NxTypography,
  NxButton,
  NxIconButton,
  NxTextLink,
  NxModal,
  NxTextInput,
} from '@playvs-inc/nexus-components'

import { Google, Hide, Unhide } from '@playvs-inc/nexus-icons'

import { Path } from '@plvs/const'
import { useRequestAccountEditMutation } from '@plvs/graphql/generated/graphql'
import { formErrorToString } from '@plvs/utils'
import { rallyEnv } from '@plvs/rally/env'
import { useAccountRenderControllerState } from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { useNavigate } from 'react-router-dom'
import { useStyles } from './AccountSettings.styles'

interface FormInput {
  password: string
}

const FormSchema = yup.object().shape({
  password: yup.string().required('Enter a password.'),
})

export interface MinimalUser {
  id: string
  firstName: string
  lastName: string
  avatarUrl?: string
}

export interface AccountSettingsPromptEditPasswordDialogProps {
  onClose: () => void
  onSuccess: (expirationDate?: Date) => void
  isChild?: boolean
}

export const AccountSettingsPromptEditPasswordDialog: React.FC<AccountSettingsPromptEditPasswordDialogProps> = ({
  onClose,
  onSuccess,
  isChild,
}) => {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  // keeping a reference to the password as an object, so it can be force deleted
  // from memory once we're done with password.
  const [passwordWrapper, setPassword] = useState<{ password?: string }>({
    password: '',
  })
  const { errors, handleSubmit, register } = useForm<FormInput>({
    resolver: yupResolver<FormInput>(FormSchema),
  })
  const flags = useFlags()

  const [
    getEditToken,
    { loading: isSubmitting },
  ] = useRequestAccountEditMutation()
  const [error, setError] = React.useState<Error>()
  const [showPassword, setShowPassword] = React.useState(false)
  const ShowPasswordIcon = showPassword ? Unhide : Hide

  const navigate = useNavigate()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const passwordFieldError = (error as any)?.graphQLErrors?.[0]?.extensions
    ?.validationErrors?.password

  useEffect(() => {
    if (!passwordFieldError && error?.message) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [error])

  const onSubmit = handleSubmit(
    async (input: FormInput): Promise<void> => {
      setError(undefined)
      try {
        const editTokenResponse = await getEditToken({
          variables: { password: input.password },
        })

        if (
          !editTokenResponse ||
          !editTokenResponse.data ||
          !editTokenResponse.data.requestAccountEdit
        ) {
          throw new Error('Empty response received from server.')
        }
        delete passwordWrapper.password
        const expirationDate = editTokenResponse?.data?.requestAccountEdit
          .expiresAt
          ? new Date(editTokenResponse.data.requestAccountEdit.expiresAt)
          : undefined
        onSuccess(expirationDate)

        return
      } catch (e: any) {
        setError(e)
      }
    }
  )

  const onOAuth = (): void => {
    window.location.href = rallyEnv.GOOGLE_VERIFY_URI
  }

  const { getAccountRenderControllerState } = useAccountRenderControllerState()
  const {
    settings: { shouldRenderOAuth },
  } = getAccountRenderControllerState()

  return (
    <NxModal onClose={onClose} open showTopRightClose title="Sign In">
      <Box className={classes.passwordEditDialog}>
        <Box>
          <Box mb={2}>
            <form
              className={classes.form}
              data-testid="AccountSettingsPromptEditPasswordDialog__Form"
              method="post"
              noValidate
              onSubmit={onSubmit}
            >
              <Box
                display="flex"
                flexDirection="column"
                maxWidth={391}
                mt={1}
                textAlign="center"
                width="100%"
              >
                {shouldRenderOAuth && flags.isGoogleOAuthVisible && !isChild ? (
                  <>
                    <Box>
                      <NxIconButton
                        className={classes.ssoButton}
                        disabled={isSubmitting}
                        fullWidth
                        icon={<Google />}
                        label="Sign In With Google"
                        onClick={onOAuth}
                        size="large"
                        variant="secondary"
                      />
                    </Box>
                    <Box mb={2} mt={2}>
                      <NxTypography variant="subtitle2">OR</NxTypography>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                <Box mt={0.5}>
                  <NxTextInput
                    ref={register}
                    data-testid="AccountSettingsPromptEditPasswordDialog__PasswordField"
                    endAdornment={
                      <ShowPasswordIcon
                        className={classes.endAdornment}
                        data-cy="showPasswordIcon"
                        onClick={(): void => setShowPassword(!showPassword)}
                      />
                    }
                    fullWidth
                    helperText={formErrorToString(errors.password)}
                    label="Password"
                    name="password"
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ): void => {
                      setPassword({ password: evt.currentTarget.value })
                    }}
                    // Setting style inline because className overrides base style
                    style={{ height: '54px' }}
                    type={showPassword ? 'text' : 'password'}
                    variant={errors.password ? 'error' : 'default'}
                  />
                </Box>
                <Box textAlign="left">
                  <NxTextLink
                    data-testid="forgotPassword"
                    label="Forgot your password?"
                    onClick={(): void => navigate(Path.ForgotPassword)}
                  />
                </Box>
                <Box mt={2}>
                  <NxButton
                    className={classes.submitButton}
                    data-testid="AccountSettingsPromptEditPasswordDialog__SubmitButton"
                    disabled={isSubmitting}
                    fullWidth
                    label="Login"
                    size="large"
                    type="submit"
                    variant="primary"
                  />
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </NxModal>
  )
}
