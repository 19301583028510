import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core'

import { EsportSlug, Maybe, SponsorshipImage } from '@plvs/graphql'

import { NxLeagueCard } from '@playvs-inc/nexus-components'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'

import { Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { getLeagueSponsorLogos } from './ExploreHelpers'

export const useStyles = makeStyles((theme) => ({
  leagueCard: {
    marginBottom: theme.spacing(3),
    width: '100%',
  },
}))

interface Props {
  id: string
  name: Maybe<string>
  publicSlug: string
  metaseasonId?: string
  seasonRange: string
  time: string
  teamsCount: number
  status: {
    label: string
    variant: 'disabled' | 'success' | 'info'
  }
  esportSlug: EsportSlug
  sponsorshipImages: SponsorshipImage[]
}

export const ExploreLeagueCard: React.FC<Props> = ({
  id,
  publicSlug,
  metaseasonId,
  seasonRange,
  name,
  time,
  teamsCount,
  status,
  esportSlug,
  sponsorshipImages,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const theme = useTheme()
  const isDarkMode = theme.palette.type === MUIThemeModeEnum.Dark

  const { widgetLogo, mobileWidgetLogo } = getLeagueSponsorLogos({
    images: sponsorshipImages,
    isDarkMode,
  })

  return (
    <>
      {esportSlug && (
        <NxLeagueCard
          buttonProps={
            {
              'data-cy': id,
              onClick: (): void =>
                navigate(
                  `${Path.Explore}/leagues/${publicSlug}/overview?queryMetaseasonId=${metaseasonId}`
                ),
            } as any
          }
          className={classes.leagueCard}
          competesOn={seasonRange}
          competesOnLabel="Schedule"
          esport={esportSlug}
          leagueName={name ?? ''}
          matchesTime={time}
          matchesTimeLabel="Matches"
          mobileSponsorImg={mobileWidgetLogo}
          numberOfTeams={teamsCount.toString()}
          numberOfTeamsLabel="Teams"
          pillProps={{
            label: status.label,
            variant: status.variant,
          }}
          sponsorImg={widgetLogo}
        />
      )}
    </>
  )
}
