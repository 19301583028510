import React from 'react'
import { Box, makeStyles } from '@material-ui/core'

import { NxTooltip, NxUserCluster } from '@playvs-inc/nexus-components'
import { RadioButton } from '../../button/RadioButton'

const useStyles = makeStyles((theme) => ({
  container: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  radioButton: {
    '& svg': {
      height: '20px',
      width: '20px',
    },
    '&.MuiIconButton-root': {
      padding: 0,
    },
  },
  title: {
    textTransform: 'capitalize',
    color: theme.palette.ColorTextBase || theme.palette.text.primary,
  },
  subtitle: {
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
  },
  position: {
    color: theme.palette.ColorTextAlt2 || theme.palette.text.hint,
  },
}))

export type RosterRowData = {
  avatarSrc?: string
  title: string
  subtitle?: string
  position?: string
  id: string
  positionId?: string | null
  positionIndex?: number
  hasConsumedPass?: boolean
  disabled?: boolean
  disabledTooltip?: string
}

export const getSubtitle = (
  position: string | undefined,
  subtitle: string | undefined
): string => {
  if (position) {
    return position
  }

  return subtitle || 'No school email'
}

export const RosterRow: React.FC<
  Partial<RosterRowData> & {
    onClick(input: string): void
    checked: boolean
  }
> = ({
  avatarSrc,
  title,
  subtitle,
  position,
  id,
  onClick,
  checked,
  disabled = false,
  disabledTooltip,
}) => {
  const classes = useStyles()

  const innerComponent = (
    <Box
      alignItems="center"
      className={disabled ? '' : classes.container}
      data-testid="row"
      display="flex"
      onClick={(): void => {
        if (!disabled) {
          onClick(id ?? '')
        }
      }}
      role="row"
      style={{ opacity: disabled ? 0.5 : 1 }}
    >
      <Box alignItems="center" display="flex" flex={1}>
        <Box pr={2}>
          <RadioButton
            checked={checked}
            className={classes.radioButton}
            data-testid="RosterRow_RadioButton"
            disabled={disabled}
          />
        </Box>
        <NxUserCluster
          avatarHashId={id ?? ''}
          avatarUrl={avatarSrc}
          subtitles={[{ title: getSubtitle(position, subtitle) }]}
          title={title}
        />
      </Box>
    </Box>
  )

  return disabledTooltip ? (
    <NxTooltip data-testid="RosterRow_DisabledTooltip" title={disabledTooltip}>
      {innerComponent}
    </NxTooltip>
  ) : (
    innerComponent
  )
}
