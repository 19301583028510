/* istanbul ignore file */
// This is a top level routing file and context provider. Pages will be unit tested individually.

import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import React, { useEffect } from 'react'
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom'
import { usePromptOnWindowUnload } from '@plvs/utils'
import { ChildOnboardPath, Path, SessionStorageKey } from '@plvs/const'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { AuthRenderControllerProvider } from '@plvs/respawn/renderController'
import {
  OnboardChildInfo,
  OnboardChildDataUsage,
  OnboardChildReview,
  OnboardChildEmail,
  OnboardChildOver13,
} from '@plvs/respawn/features/childOnboard'
import {
  OnboardingContext,
  useOnboardingContextProvider,
} from '@plvs/respawn/features/onboard/OnboardingContext'
import { OnboardChildSchool } from './OnboardChildSchool'
import { OnboardChildBirthday } from './OnboardChildBirthday'
import { OnboardChildEsports } from './OnboardChildEsports'

export const ChildOnboard: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { assign, data: contextValues } = useOnboardingContextProvider()

  const doPrompt = !pathname.includes(ChildOnboardPath.ChildReview)

  usePromptOnWindowUnload(doPrompt)

  useEffect(() => {
    const handleEvent = (): void => {
      sessionStorage.removeItem(SessionStorageKey.OnboardingContext)
      window.location.replace(Path.ParentDashboard)
    }
    window.addEventListener('popstate', handleEvent)
    return (): void => window.removeEventListener('popstate', handleEvent)
  }, [])

  // Using this prompt till more support for react-router v6 is available
  window.onpopstate = (): void => {
    if (!!contextValues.onboardingComplete && !pathname.includes('invite-')) {
      // eslint-disable-next-line
      const result = window.confirm(
        'Your account has been created so you will be returning to the signup screen. Are you sure you want to go back?'
      )
      if (result) {
        navigate(Path.ParentDashboard)
      }
    }
  }

  return (
    <PenaltyBox>
      <AuthRenderControllerProvider>
        <OnboardingContext.Provider value={{ assign, data: contextValues }}>
          <ApmRoutes>
            <Route
              element={<Navigate to={`${ChildOnboardPath.ChildDataUsage}`} />}
              path="/"
            />
            <Route
              element={<OnboardChildDataUsage />}
              path={`${ChildOnboardPath.ChildDataUsage}`}
            />
            <Route
              element={<OnboardChildBirthday />}
              path={`${ChildOnboardPath.ChildBirthday}`}
            />
            <Route
              element={<OnboardChildEmail />}
              path={`${ChildOnboardPath.ChildEmail}`}
            />
            <Route
              element={<OnboardChildInfo />}
              path={`${ChildOnboardPath.ChildInfo}/:childId?`}
            />
            <Route
              element={<OnboardChildEsports />}
              path={`${ChildOnboardPath.ChildEsportInterests}/:childId?`}
            />
            <Route
              element={<OnboardChildSchool />}
              path={`${ChildOnboardPath.ChildSchool}/:childId?`}
            />
            <Route
              element={<OnboardChildReview />}
              path={`${ChildOnboardPath.ChildReview}/:childId?`}
            />
            <Route
              element={<OnboardChildOver13 />}
              path={`${ChildOnboardPath.ChildOver13}`}
            />
          </ApmRoutes>
        </OnboardingContext.Provider>
      </AuthRenderControllerProvider>
    </PenaltyBox>
  )
}
