import React from 'react'
import { UserNotification } from '@plvs/graphql/generated/graphql'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { UserNotificationContentAdapter } from '../notification.types'
import { SchoolSystemMessage } from '../templates/SchoolSystemMessage'

const UpdateSchoolITPhoneNumberAdapterContainer: React.FC<{
  notification: UserNotification
}> = ({ notification }) => {
  return (
    <SchoolSystemMessage
      IconComponent={
        <NxSpot
          domain="highAlert"
          height="4rem"
          size="small"
          variant="primary"
        />
      }
      message={notification.contentData.message}
      messageDates={notification.contentData.messageDates}
      url={notification.contentData.url || undefined}
    />
  )
}

export const UpdateSchoolITPhoneNumberAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Update School It Phone Number',

  createNotification(notification: UserNotification): React.ReactElement {
    return (
      <UpdateSchoolITPhoneNumberAdapterContainer notification={notification} />
    )
  },
}
