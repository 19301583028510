import { makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { NxButton, NxTextInput } from '@playvs-inc/nexus-components'
import { Hide, Unhide } from '@playvs-inc/nexus-icons'
import React from 'react'
import {
  formErrorToString,
  passwordRequirementsValidation,
  yupEmailRequired,
} from '@plvs/utils'
import { useSnackbar } from 'notistack'
import { useRegisterUserMutation } from '@plvs/graphql/generated'
import { useNavigate } from 'react-router-dom'
import { Path, ChildOnboardPath } from '@plvs/const'
import { useOnboardingContext } from '../onboard/OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  endAdornment: {
    marginRight: theme.spacing(1.375),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

interface CreateAccountInput {
  email: string
  password: string
}

const SignUpFormSchema = yup.object().shape({
  email: yupEmailRequired,
  password: passwordRequirementsValidation,
})

export const OnboardChildEmail: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const [showPassword, setShowPassword] = React.useState(false)

  const { data: onboardingData, assign } = useOnboardingContext()

  const { handleSubmit, register, errors } = useForm<CreateAccountInput>({
    resolver: yupResolver<CreateAccountInput>(SignUpFormSchema),
  })

  const [registerUser, { loading: isMutating }] = useRegisterUserMutation()

  const handleCreate = async (data: CreateAccountInput): Promise<void> => {
    try {
      const dob = onboardingData?.dateOfBirth
      if (!dob) {
        enqueueSnackbar('Birthday from previous step not found', {
          variant: 'error',
        })
        return
      }

      const result = await registerUser({
        variables: {
          input: {
            email: data.email,
            password: data.password,
            dateOfBirth: dob as string,
            userConsentId: onboardingData.consentId
              ? `${onboardingData.consentId}`
              : undefined,
          },
          options: {},
        },
      })

      if (result.data?.registerUser?.user?.id) {
        assign({ childId: result.data.registerUser.user.id })
        navigate(
          `${Path.App}${Path.ChildSpawnPoint}/${ChildOnboardPath.ChildInfo}`
        )
      }
    } catch (error) {
      enqueueSnackbar('Error creating user', { variant: 'error' })
    }
  }

  const ShowPasswordIcon = showPassword ? Unhide : Hide

  return (
    <form className={classes.form} onSubmit={handleSubmit(handleCreate)}>
      <QuestionnaireCard
        childWidth={416}
        subtitle="Please enter school email address and password for the child who will use this account"
        title="Create an Account for your Child"
      >
        <Box width="100%">
          <NxTextInput
            ref={register}
            data-testid="email"
            fullWidth
            helperText={formErrorToString(errors.email)}
            label="Email address"
            name="email"
            style={{ height: '56px' }}
            type="email"
            variant={errors.email ? 'error' : 'default'}
          />
        </Box>
        <Box mt={0.5} width="100%">
          <NxTextInput
            ref={register}
            data-testid="password"
            endAdornment={
              <ShowPasswordIcon
                className={classes.endAdornment}
                data-cy="showPasswordIcon"
                data-testid="showPassword"
                onClick={(): void => setShowPassword(!showPassword)}
              />
            }
            fullWidth
            helperText="Password must be between 8-64 characters long"
            label="Password"
            name="password"
            style={{ height: '56px' }}
            type={showPassword ? 'text' : 'password'}
            variant={errors.password ? 'error' : 'default'}
          />
        </Box>
        <NxButton
          className={classes.button}
          disabled={isMutating}
          fullWidth
          label="Create Account"
          type="submit"
          variant="primary"
        />
      </QuestionnaireCard>
    </form>
  )
}
