import React, { useEffect, useState } from 'react'
import confetti from 'canvas-confetti'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Container,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { GetUserAttrs } from '@plvs/rally/containers/app'
import { Location } from '@plvs/client-data/models'
import { NxTypography, NxButton, Pill } from '@playvs-inc/nexus-components'
import { CaretDown } from '@playvs-inc/nexus-icons'

import {
  useAppLocationFn,
  useSelectedOrganizationFn,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { CountryCode } from '@plvs/utils'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import {
  CoachLeadComponent,
  SchoolPropType,
} from '../coach/coachLeadGen/CoachLeadComponent'
import { OnboardSurvey } from './OnboardSurvey/OnboardSurvey'

const useStyles = makeStyles((theme) => ({
  accordions: {
    width: '100%',
  },
  accordion: {
    boxShadow: 'none',
  },
  accordionDetails: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  accordionDisabled: {
    backgroundColor: `${theme.palette.ColorBackgroundBase} !important`,
    opacity: `${1} !important`,
  },
  accordionSummary: {
    boxShadow: theme.mixins.divider.bottom,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0, 2),
      alignItems: 'flex-end',
    },
  },
  container: {
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  coachLeadTitle: {
    fontSize: theme.typography.fontSize * 2,
    fontWeight: 600,
  },
  icon: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1),
    },
  },
  iconButton: {
    border: `1px solid ${theme.palette.OverlayColorIconAlt}`,
    padding: theme.spacing(1.25),
  },
  referAccordionWrapper: {
    marginBottom: theme.spacing(2),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))

export interface SuccessfulEnrollmentProps {
  seasonName: string
  pathname: string
}

export const SuccessfulEnrollment: React.FC<SuccessfulEnrollmentProps> = ({
  seasonName,
  pathname,
}) => {
  const { isFacultyAtOrg, orgCountry, orgProvince } = useUserIdentityFn()
  const { id: orgId } = useSelectedOrganizationFn()
  const appLocation = useAppLocationFn()
  const classes = useStyles()
  const isXs = useBreakpointXs()

  const isEnrollmentUpdated = !!(pathname.match(/enrollment-updated/gi) ?? [])
    .length

  const [isReferExpanded, setReferExpanded] = useState<boolean>(false)
  const [isOnboardingExpanded, setOnboardingExpanded] = useState<boolean>(false)
  const [onboardingSubmitted, setOnboardingSubmitted] = useState<boolean>(false)

  useEffect(() => {
    if (isEnrollmentUpdated) {
      setReferExpanded(true)
    } else {
      setOnboardingExpanded(true)
    }
  }, [isEnrollmentUpdated])

  useEffect(() => {
    window.scrollTo(0, 0)

    confetti({
      particleCount: 100,
      spread: 100,
      origin: {
        y: 0.35,
      },
    })

    const timer = setTimeout(() => {
      confetti.reset()
    }, 2000)

    return (): void => clearTimeout(timer)
  }, [])

  const isUsersSchoolInUnitedStates = orgCountry === CountryCode.UnitedStates

  const school = { id: orgId, state: orgProvince }

  const isRally = appLocation === Location.Rally
  const backToManageTeams = (
    <NxButton
      color="primary"
      fullWidth
      href={Path.ManageTeams}
      label="Manage Teams"
      variant="primary"
    />
  )
  const backToSchoolPage = (
    <NxButton
      color="primary"
      fullWidth
      href={`/school/${orgId}`}
      label="School's Page"
      variant="primary"
    />
  )

  const enrollmentSubtitle = isEnrollmentUpdated
    ? `Your enrollment for ${seasonName} has been successfully updated. You can make any necessary modifications until the season enrollment deadline.`
    : `Congratulations! Your school has succesfully registered for the ${seasonName} season.  You can make any necessary modifications until the season enrollment deadline.`

  return (
    <Container className={classes.container} maxWidth="md">
      <GetUserAttrs />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        mb={[5, 7]}
      >
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="center"
          width={['100%', '680px']}
        >
          <NxTypography
            className={classes.title}
            data-testid="enrollmentText"
            variant={isXs ? 'h2' : 'display'}
          >
            {isEnrollmentUpdated
              ? 'Enrollment Updated'
              : 'You‘re Enrolled in the Season!'}
          </NxTypography>
          <NxTypography variant="body1">{enrollmentSubtitle}</NxTypography>
          <Box mt={2} width={['100%', '200px']}>
            {isRally ? backToManageTeams : backToSchoolPage}
          </Box>
        </Box>
      </Box>
      <div className={classes.accordions}>
        {isUsersSchoolInUnitedStates && (
          <div className={classes.referAccordionWrapper}>
            <Accordion
              className={classes.accordion}
              expanded={isReferExpanded}
              onChange={(): void => setReferExpanded(!isReferExpanded)}
            >
              <AccordionSummary
                className={classes.accordionSummary}
                expandIcon={
                  <IconButton className={classes.iconButton}>
                    <CaretDown />
                  </IconButton>
                }
              >
                <Box
                  alignItems={['flex-start', 'center']}
                  display="flex"
                  flexDirection={['column', 'row']}
                >
                  <Box className={classes.icon}>
                    <NxSpot
                      domain="school"
                      height={64}
                      size="small"
                      variant="primary"
                      width={64}
                    />
                  </Box>

                  <div>
                    <NxTypography variant="h4">
                      Help Grow Scholastic Esports
                    </NxTypography>
                    <NxTypography colorToken="ColorTextAlt" variant="body1">
                      Refer your district or another school
                    </NxTypography>
                  </div>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.accordionDetails}>
                <CoachLeadComponent
                  fullWidthSubmitButton={isXs}
                  hideModalPadding
                  isBorderless
                  isFaculty={isFacultyAtOrg}
                  isModalView
                  school={school as SchoolPropType}
                  source="faculty-enrollconfirmation-coach-lead"
                  title={
                    <NxTypography variant="body1">
                      Help grow the scholastic esports community by referring a
                      coach from another school. Fill out the fields below and
                      click &quot;Submit&quot;.
                    </NxTypography>
                  }
                />
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        <div>
          <Accordion
            classes={{ disabled: classes.accordionDisabled }}
            className={classes.accordion}
            disabled={onboardingSubmitted}
            expanded={isOnboardingExpanded}
            onChange={(): void => setOnboardingExpanded(!isOnboardingExpanded)}
          >
            <AccordionSummary
              classes={{ disabled: classes.accordionDisabled }}
              className={classes.accordionSummary}
              expandIcon={
                onboardingSubmitted ? (
                  <Pill label="Submitted" size="small" variant="success" />
                ) : (
                  <IconButton className={classes.iconButton}>
                    <CaretDown />
                  </IconButton>
                )
              }
            >
              <Box
                alignItems={['flex-start', 'center']}
                display="flex"
                flexDirection={['column', 'row']}
              >
                <Box className={classes.icon}>
                  <NxSpot
                    domain="chat"
                    height={64}
                    size="small"
                    variant="primary"
                    width={64}
                  />
                </Box>
                <div>
                  <NxTypography variant="h4">
                    Help Us Improve Onboarding
                  </NxTypography>
                  <NxTypography colorToken="ColorTextAlt" variant="body1">
                    Please give us feedback on your onboarding experience
                  </NxTypography>
                </div>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <OnboardSurvey
                isEnrollmentUpdated={isEnrollmentUpdated}
                setOnboardingExpanded={setOnboardingExpanded}
                setOnboardingSubmitted={setOnboardingSubmitted}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </Container>
  )
}
