import { makeStyles, useTheme } from '@material-ui/core'
import { UserRoleName } from '@plvs/graphql/generated/graphql'
import { OnboardIsCoachOptions } from '@plvs/rally/components/onboard/OnboardIsCoachOptions'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { cleanGraphQLError } from '@plvs/utils'
import { useSnackbar } from 'notistack'
import React, { SyntheticEvent, useState } from 'react'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
}))

export const OnboardPlanningToCoach: React.FC = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { assign, data: onboardContext } = useOnboardingContext()

  // computed props
  const showNASEFUx = onboardContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  // state vars
  const [selectedOption, setSelectedOption] = useState<UserRoleName | null>(
    (onboardContext.facOrCoach as UserRoleName) ?? null
  )

  // Form handlers

  const onSubmit = async (evt: SyntheticEvent): Promise<void> => {
    evt.preventDefault()

    try {
      assign({
        facOrCoach: selectedOption,
      })

      if (selectedOption === UserRoleName.Coach) {
        navigate(`${Path.SpawnPoint}/faculty/faculty-contact${showNASEFUx}`)
      } else {
        // TODO: push this after additional contact
        navigate(`${Path.SpawnPoint}/faculty/know-a-coach${showNASEFUx}`)
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        const errorMessage = cleanGraphQLError(err.message)
        enqueueSnackbar(errorMessage, { variant: 'error' })
      } else {
        throw err
      }
    }
  }

  return (
    <form className={classes.form} noValidate onSubmit={onSubmit}>
      <QuestionnaireCard
        buttonOpacity={!selectedOption}
        childWidth={theme.spacing(50)}
        onContinue={(): void => {}}
        subtitle="A coach is responsible for building and maintaining their school‘s esports program."
        title="Will you be coaching?"
      >
        <OnboardIsCoachOptions
          onChange={(opt: UserRoleName): void => setSelectedOption(opt)}
          selected={selectedOption}
        />
      </QuestionnaireCard>
    </form>
  )
}
