import React from 'react'

import { Pill, PillProps } from '@playvs-inc/nexus-components'

interface PreseasonBadgeProps {
  size?: PillProps['size']
  variant?: PillProps['variant']
}

export const PreseasonBadge: React.FC<PreseasonBadgeProps> = ({
  size = 'medium',
  variant = 'brand',
}) => <Pill label="Preseason" size={size} variant={variant} />
