export default (key: string): void => {
  /* eslint-disable */
  // https://segment.com/docs/sources/website/analytics.js/quickstart/
  // @ts-ignore
  !(function () {
    // @ts-ignore
    const analytics = (window.analytics = window.analytics || [])
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console &&
          console.error &&
          console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
        ]
        // @ts-ignore
        analytics.factory = function (t) {
          return function () {
            const e = Array.prototype.slice.call(arguments)
            e.unshift(t)
            analytics.push(e)
            return analytics
          }
        }
        analytics.methods.forEach((method) => {
          analytics[method] = analytics.factory(method)
        })
        // @ts-ignore
        analytics.load = function (t, e) {
          const n = document.createElement('script')
          n.type = 'text/javascript'
          n.async = !0
          n.src =
            'https://cdn.segment.com/analytics.js/v1/' + t + '/analytics.min.js'
          const a = document.getElementsByTagName('script')[0]
          // @ts-ignore
          a.parentNode.insertBefore(n, a)
          analytics._loadOptions = e
        }
        analytics.SNIPPET_VERSION = '4.1.0'
        analytics.load(key)
        // NOTE: If we were identifying anonymous users, we would track the
        //  first page view here, with analytics.page()
      }
  })()
  /* eslint-enable */
}
