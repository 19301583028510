import { Maybe, Metaseason, Phase, Slot } from '@plvs/graphql/generated'

export type MetaseasonFilterType = Pick<Metaseason, 'id'> & {
  name: string
}

export type PhaseFilterType = Pick<Phase, 'id' | 'format'> & {
  name: string
} & { slots: Maybe<Pick<Slot, 'id' | 'startsAt'>[]> }

export type SlotFilterType = Pick<Slot, 'id' | 'startsAt'> & {
  name?: string | null
}

export enum LeagueDetailsTab {
  Overview = 'overview',
  Teams = 'teams',
  Standings = 'standings',
  Matches = 'matches',
}
