import { Grid, makeStyles } from '@material-ui/core'
import { Param } from '@plvs/const'
import {
  EsportRating,
  refetchGetAggregateSchoolMessagesQuery,
  useDeleteSchoolMessageMutation,
  useGetAggregateSchoolMessagesQuery,
  useUpdateSchoolMessageMutation,
} from '@plvs/graphql'
import { NxTypography, NxEmptyState } from '@playvs-inc/nexus-components'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import dayjs from 'dayjs'
import {
  useUserIdentityFn,
  useSelectedOrganizationFn,
} from '@plvs/client-data/hooks'
import React, { useCallback, useLayoutEffect, useRef } from 'react'
import { useLocation } from 'react-router'

import { isSafari } from '@plvs/rally/core'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { School, SchoolMessage, SchoolUserMessage } from './helper'
import { Message } from './Message'
import { MessageMatch } from './MessageMatch'
import { SchoolMessageForm } from './SchoolMessageForm'

const useStyles = makeStyles({
  container: {
    maxWidth: '100%',
  },
  avatar: {
    margin: '20px',
  },
  messageTitle: {
    alignItems: 'left',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    padding: '8px 8px 24px 8px',
  },
  message: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    flex: 1,
  },
  teams: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  action: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    height: '100%',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  coachItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

/**
 * Gets the SchoolMessageId from the URL query
 * string.
 */
function useGetFocusedMessageId(): string | null {
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)
  const focusedMessageId = urlSearchParams.get(Param.Message)
  return focusedMessageId
}

export const Messages: React.FC<{ school: School }> = ({ school }) => {
  const hasFocused = useRef<boolean>(false)

  const focusedMessageId = useGetFocusedMessageId()

  const { userId, isCoachAtOrg } = useUserIdentityFn()
  const { id: orgId } = useSelectedOrganizationFn()

  const isCoachOfCurrentSchool = isCoachAtOrg && orgId === school?.id

  const { data, loading } = useGetAggregateSchoolMessagesQuery({
    variables: {
      schoolId: school?.id,
    },
    fetchPolicy: focusedMessageId ? 'network-only' : 'cache-first',
    nextFetchPolicy: 'cache-and-network',
    skip: !school?.id,
  })

  const [mutateDelete] = useDeleteSchoolMessageMutation()
  const [mutateUpdate] = useUpdateSchoolMessageMutation()

  const messages: SchoolMessage[] = data?.getAggregateSchoolMessages ?? []
  /*
    TODO: GP-2809
    https://playvs.atlassian.net/browse/GP-2809
    Use only that top 25 messages to prevent locking down the app.
    This will be addressed and updated with the ticket GP-2809
  */
  const sortedMessages = [...messages]
    .sort((a, b) => {
      // eslint-disable-next-line no-underscore-dangle
      if (a?.__typename === 'SchoolUserMessage' && a?.isPinned) return -1
      // eslint-disable-next-line no-underscore-dangle
      if (b?.__typename === 'SchoolUserMessage' && b?.isPinned) return 1
      const dayjsA = dayjs(b?.createdAt).unix()
      const dayjsB = dayjs(a?.createdAt).unix()
      if (dayjsB > dayjsA) return -1
      if (dayjsB < dayjsA) return 1
      if (dayjsA === dayjsB) return 0
      return 0
    })
    .slice(0, 25)

  const onDelete = useCallback(
    async (message: SchoolUserMessage): Promise<void> => {
      await mutateDelete({
        refetchQueries: [
          refetchGetAggregateSchoolMessagesQuery({ schoolId: school?.id }),
        ],
        variables: { id: message?.id ?? '' },
      })
    },
    [mutateDelete]
  )

  const togglePin = useCallback(
    async (message: SchoolUserMessage): Promise<void> => {
      await mutateUpdate({
        refetchQueries: [
          refetchGetAggregateSchoolMessagesQuery({ schoolId: school?.id }),
        ],
        variables: {
          id: message?.id ?? '',
          isPinned: !message?.isPinned,
          schoolId: school?.id,
          userId: userId || '',
        },
      })
    },
    [mutateUpdate]
  )

  const classes = useStyles()
  const teams = school?.teams?.filter((team) => !team?.isHidden) ?? []

  const youthProgramTeams = teams.filter(
    (team) => team?.esport?.rating === EsportRating.Restricted
  )

  // If we have a target message to focus on, focus on it only once.
  // This is too low level to unit test effectively.
  /* istanbul ignore next */
  useLayoutEffect(() => {
    if (focusedMessageId && !hasFocused.current) {
      hasFocused.current = true
      // Add a slight delay on the scroll into view trigger to allow DOM
      // elements to render into view before scrolling to them.  This avoids
      // scrolling while elements are popping into the screen causing
      // a jarring experience.
      setTimeout(() => {
        const messageToFocus = window.document.querySelector(
          `#message${focusedMessageId}`
        )
        messageToFocus?.scrollIntoView?.({
          behavior: isSafari ? undefined : 'smooth',
        })
      }, 800)
    }
  }, [hasFocused, focusedMessageId, data?.getAggregateSchoolMessages])

  return (
    <MatchCard py={3}>
      <Grid className={classes.container} container>
        {isCoachOfCurrentSchool ? (
          <Grid item xs={12}>
            <Box>
              <SchoolMessageForm school={school} />
            </Box>
          </Grid>
        ) : (
          <></>
        )}
        <WaitTillLoaded
          loading={loading}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <Grid item xs={12}>
            {sortedMessages.length > 0 ? (
              <>
                <Box className={classes.messageTitle} mt={7} px={0.1}>
                  <NxTypography variant="h4">Recent Activity</NxTypography>
                </Box>
                <Box data-testid="Messages_MessagesSection" pt={2} px={0.1}>
                  {sortedMessages.map((m) => {
                    // eslint-disable-next-line no-underscore-dangle
                    switch (m?.__typename) {
                      case 'SchoolUserMessage':
                        return (
                          <React.Fragment key={m?.id}>
                            <span id={`message${m.id}`} />
                            <Message
                              key={m?.id}
                              data-testid="Messages_MessagesSection_Message"
                              isCoachOfCurrentSchool={isCoachOfCurrentSchool}
                              message={m}
                              onDelete={onDelete}
                              togglePin={togglePin}
                            />
                          </React.Fragment>
                        )
                      case 'SchoolOneOnOneMatchResultMessage':
                        return (
                          <MessageMatch
                            key={m?.matchId}
                            data-testid="Messages_MessagesSection_Message"
                            esportRating={
                              youthProgramTeams
                                .map((t) => t.id)
                                .some((tid) => tid === m.homeTeamId)
                                ? EsportRating.Restricted
                                : EsportRating.General
                            }
                            message={m}
                          />
                        )
                      default:
                        return <></>
                    }
                  })}
                </Box>
              </>
            ) : (
              <NxEmptyState
                isStandalone
                spot={
                  <NxSpot
                    domain="megaphone"
                    height={100}
                    size="large"
                    variant="secondary"
                    width={100}
                  />
                }
                subtitle="School activity will appear here"
                title={`Welcome to ${school.name}`}
              />
            )}
          </Grid>
        </WaitTillLoaded>
      </Grid>
    </MatchCard>
  )
}
