import { equals, uniq } from 'ramda'
import changeCase from 'change-case-object'

import {
  Maybe,
  SetAccountSettingsInput,
  School,
  User,
  UserRole,
  UserRoleName,
  ResourceType,
  UserEmail,
  SchoolType,
  EsportSlug,
} from '@plvs/graphql'
import { PAFStep } from '@plvs/const'
import { noop } from 'ramda-adjunct'
import {
  UserType,
  userRolesToUserRoleNames,
  userRolesNamesByResourceType,
} from '@plvs/utils'
import { ProductType } from '@plvs/client-data/models'
import { makeSafeObjectOfFunctions } from '../utils'
// import { rallyEnv } from '../../env'

// ///////
// Core //
// ///////

export const segment = makeSafeObjectOfFunctions(
  ['analytics'],
  ['group', 'identify', 'page', 'ready', 'reset', 'track', 'user']
) as {
  group: (groupId: string, traits: Record<string, unknown>) => void
  identify: (
    userId: string,
    traits: Record<string, unknown>,
    context: { integrations: { Intercom: Record<string, unknown> } }
  ) => void
  page: () => void
  ready: (fn: () => void) => void
  reset: () => void
  track: (eventName: string, properties?: Record<string, unknown>) => void
  user: () => { id: () => string }
}

// cut down on unnecessary identify calls...
let prevGroupTraits: Record<string, unknown>

export const group = (
  traits: Pick<School, 'id' | 'city' | 'name' | 'state' | 'deletedAt'>
): void => {
  if (!equals(traits, prevGroupTraits)) {
    prevGroupTraits = traits
    // control what traits are reported
    const { id, city, name, state, deletedAt } = traits

    segment.group(id, {
      city,
      name,
      state,
      deletedAt,
    })
  }
}

// cut down on unnecessary identify calls...
let prevIdentifyTraits: Record<string, unknown>

let identified = false
export const identify = (
  traits: Pick<
    User,
    'id' | 'firstName' | 'lastName' | 'promotionalEmail' | 'hmac'
  > & {
    emails: Maybe<Array<Maybe<Pick<UserEmail, 'email' | 'isSchoolEmail'>>>>
    roles: Maybe<Pick<UserRole, 'roleName' | 'resourceType'>[]>
    schoolId: string | undefined
    hmac: Maybe<string>
    isUnderage: Maybe<boolean>
    app: ProductType
    schoolType: SchoolType | undefined | null
    state: string | undefined | null
    gradYears: Array<{ gradYear: number; schoolId: string }>
    teams: {
      id: string
      esport: {
        id: string
        slug: EsportSlug
      }
    }[]
  }
): void => {
  if (!equals(traits, prevIdentifyTraits)) {
    prevIdentifyTraits = traits

    // control what traits are reported
    const {
      emails,
      id,
      firstName,
      lastName,
      roles: traitRoles,
      promotionalEmail,
      hmac,
      isUnderage,
      app,
      schoolType,
      state,
      gradYears,
      teams = [],
    } = traits
    const roles = traitRoles || []

    const teamEsportSlugs = uniq(teams.map((team) => team.esport.slug))

    try {
      if (!isUnderage) {
        segment.identify(
          id,
          changeCase.snakeCase({
            accountExternalId: traits.schoolId ?? '',
            contactExternalId: id,
            emails,
            firstName,
            lastName,
            userRoles: userRolesToUserRoleNames(roles),
            organizationRoles: uniq(
              userRolesNamesByResourceType(roles, ResourceType.Organization) ??
                []
            ),
            teamRoles: uniq(
              userRolesNamesByResourceType(roles, ResourceType.Team) ?? []
            ),
            email: promotionalEmail,
            isUnderage,
            app,
            schoolType,
            state,
            gradYears,
            teams: teamEsportSlugs,
          }) as Record<string, unknown>,
          {
            integrations: {
              Intercom: {
                user_hash: hmac ?? '',
              },
            },
          }
        )
      }
      if (!identified) {
        segment.page() // first page view occurs now, rather than on segment initialization
        identified = true
      }
    } catch (err: unknown) {
      noop(err)
    }
  }
}

// More information on `ready` and `user` functions https://segment.com/docs/sources/website/analytics.js/#user-group-information
export const page = (): void =>
  segment.ready((): void => {
    // NOTE: If we were identifying anonymous users, we would not check for
    //  segment.user().id() here.
    if (segment.user().id()) segment.page()
  })

export const reset = (): void => segment.reset()

// /////////
// Events //
// /////////

// XXX: Call this function to track segment events; DO NOT use segment.track!!!
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const track =
  <T extends Record<string, any>>(
    eventName: string
  ): ((properties?: T) => void) =>
  (properties?: T): void => {
    const isUnderageRaw = localStorage.getItem('isUnderage')
    const isUnderage = isUnderageRaw
      ? (JSON.parse(isUnderageRaw) as boolean)
      : undefined

    // // Avoid logging when segment is enabled.
    // // istanbul ignore next
    // if (!rallyEnv.SEGMENT_KEY) {
    //   // avoid console logging in unit test.
    //   if (typeof jest === 'undefined') {
    //     // eslint-disable-next-line no-console
    //     console.log('Segment Event: ', { eventName, properties, isUnderage })
    //   }
    // }

    if (isUnderage === false) {
      return properties
        ? segment.track(
            eventName,
            changeCase.snakeCase({ ...properties, isUnderage }) as Record<
              string,
              unknown
            >
          )
        : segment.track(eventName)
    }

    return undefined
  }

type SegmentFnNoArgs = () => void
type SegmentFn<Properties> = (properties: Properties) => void

export enum Feature {
  CoachOnboarding = 'CoachOnboarding',
  PlayerOnboarding = 'PlayerOnboarding',
}

export interface CopyInviteLinkProperties {
  feature: Feature
  inviteeUserType: UserType
  inviteLink: string
  inviteToken?: string
}

export const copyInviteLink: SegmentFn<CopyInviteLinkProperties> =
  track('Copy Invite Link')

export interface InviteByEmailProperties {
  feature: Feature.CoachOnboarding | Feature.PlayerOnboarding
  inviteeEmails: string[]
  inviteeUserType: UserType
}

export const inviteByEmail: SegmentFn<InviteByEmailProperties> =
  track('Invite by Email')

export const login: SegmentFn<{
  email: string
  userId: string
  next?: string
  provider?: string
  userRoles: UserRoleName[]
}> = track('Login')

export const logout: SegmentFnNoArgs = track('Logout')

export const register: SegmentFn<{
  email: string
  userId: string
  next?: string
  provider?: string
  referralToken?: string
  userRoles: UserRoleName[]
}> = track('Register')

export const setRole: SegmentFn<{
  role: UserRoleName
}> = track('Set Role')

export const setRoleDescription: SegmentFn<{
  roleDescription: string
}> = track('Set Role Description')

export const setSchool: SegmentFn<{
  id?: string
  city?: string
  name?: string
  slug?: string
  state?: string
  zip?: string
  suggested: boolean
}> = track('Set School')

export const userEnteredNameAndDateOfBirth: SegmentFn<{
  userId: string
}> = track('Set Name and Date of Birth')

export const setSchoolSubmission: SegmentFn<{
  isInterestedInFall2019?: string | null
  isInterestedInSpring2020?: string | null
  needsHelpWithApproval: string
}> = track('Set School Submission')

// NOTE: This isn't being used, currently
export const updatePlayerProfile: SegmentFn<
  SetAccountSettingsInput & {
    feature: Feature.CoachOnboarding | Feature.PlayerOnboarding
    graduationYear?: number
  }
> = track('Update Player Profile')

export const seasonWizardActivated: SegmentFn<{ userAction: string }> = track(
  'Season Wizard Activated'
)

export const seasonWizardDismissed: SegmentFn<{
  confirmDismissal?: boolean
  reverseDismissal?: boolean
  cancelled?: boolean
  userInput: string | undefined
}> = track('Season Wizard Dismissed')

export const seasonWizardCompleted: SegmentFn<{
  success?: boolean
  cancelled?: boolean
}> = track('Season Wizard Completed')

// Player Activation Flow

export const playerActivationFlowTeamSelection: SegmentFn<{
  idOfUserAdded: string
  addedToExistingTeam?: boolean
  newTeamCreated?: boolean
  addToNewTeam?: boolean
  teamId?: string
}> = track('Player Activation Flow Team Selection')

export const playerActivationFlowTeamActivation: SegmentFn<{
  attemptToActivate: boolean
  wasTeamActivated: boolean
  teamId: string
}> = track('Player Activation Flow Team Activation')

export const playerActivationFlowCloseModalAfterAbandoned: SegmentFn<{
  whereAbandoned?: PAFStep
  modalClosed?: boolean
  cancelAbandonment?: boolean
}> = track('Player Activation Flow Close Modal After Abandoned')

export const connectAccountCTAAcknowledged: SegmentFn<{
  connectAccountClicked: boolean
}> = track('Connect Account CTA Acknowledged')

export const referCoachModalDiscovered: SegmentFn<{
  userRoleType: string
}> = track('Refer Coach Modal Discovered')

export const referPlayerModalDiscovered: SegmentFn<{
  userRoleType: string
}> = track('Refer Player Modal Discovered')

export const referUserModalDiscovered: SegmentFn<{
  userRoleType: string
}> = track('Refer User Modal Discovered')

export const referralModalCTAConverted: SegmentFn<{
  copyLink?: boolean
  messageSent?: boolean
}> = track('Referral Modal CTA Converted')

// Program Education

export const votedOnArticle: SegmentFn<{
  upVote?: boolean
  article: string
  downVote?: boolean
}> = track('Voted On Article')

export const readArticle: SegmentFn<{
  article: string
  timeStamp: string
  readAgain?: boolean
}> = track('Read Article')

export const inAppClickedArticleCTA: SegmentFn<{
  cta: string
  article: string
}> = track('In App Clicked Article CTA')

export const watchedArticleVideo: SegmentFn<{
  url: string
  article: string
}> = track('Watched Article Video')

export const openedArticleResource: SegmentFn<{
  name: string
  article: string
}> = track('Opened Article Resource')

export const clickedArticleCTA: SegmentFn<{
  name: string
  article: string
}> = track('Clicked Article CTA')

export const productPlanSelected: SegmentFn<{
  productId: string
  schoolId: string
}> = track('Product Plan Selected')

// Regional and State

export const nonSanctionedSchoolInterestedInStateOrConference: SegmentFn<{
  schoolId: string
}> = track('Nonsanctioned School Interested in State ')

// Notification Service Tracking

export const notificationService: SegmentFn<{
  name: string
  action: string
}> = track('Notification Service')

// Billing Contact at School

export const billingContactAtSchool: SegmentFn<{
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  facultyRole?: string | null
  phone?: string | null
  response: string
  id?: string | null
}> = track('Billing Contact At School')

// Deny Verified Player

export const deniedVerifiedPlayer: SegmentFn<{
  deniedUserId: string
  schoolId: string
  gradYear: number | null
}> = track('Denied Verified Player')

// Global Chat

export const leftNavUnreadNotificationIconClicked: SegmentFn<{
  chatOpened: boolean
  chatUnqiueName: string
}> = track('Left Nav Unread Notification Icon Clicked')

export const globalChatNotificationsDrawerOpened: SegmentFn<{
  openedWithUnreadMessages: boolean
}> = track('Global Chat Notifications Drawer Opened')

export const globalChatWindowClosed: SegmentFn<{
  chatUniqueName: string
  closedWithUnreadMessages: boolean
}> = track('Global Chat Window Closed')

export const messageSentFromGlobal: SegmentFn<{
  chatUniqueName: string
}> = track('Message Sent From Global')

export const userReadUnreadMessageViaGlobal: SegmentFn<{
  chatUniqueName?: string
  numberOfUnreadMessagesRead: number
  location: string
}> = track('User Read Unread Message Via Global')

export const globalChatDrawerOpenedAndLoaded: SegmentFn<{
  openedTimestamp: string
  finishedLoading: string
  numberOfConversations: number
}> = track('Global Chat Drawer Opened and Loaded')

export const globalChatTwilioCallAndDataBuilt: SegmentFn<{
  startedFetchingFromTwilio: string
  finishedFetchingFromTwilio: string
}> = track('Global Chat Twilio Call and Data Built')

// Match Lobby Chat

export const matchLobbyChatLoaded: SegmentFn<{
  startedLoading: string
  finishedLoading: string
  chatUnqiueName: string
  archivedChat: boolean
}> = track('Match Lobby Chat Loaded')

// User Notifications (Notification Center)

export const userNotificationsDrawerOpened: SegmentFn<{
  userId: string
  timeStamp: string
  notificationsCleared: number
}> = track('User Notification: Drawer Opened')

export const userNotificationsDrawerClosed: SegmentFn<{
  userId: string
  timeStamp: string
}> = track('User Notification: Drawer Closed')

export const userNotificationMessageOpened: SegmentFn<{
  userNotificationId: string
  userNotificationType: string
  timeStamp: string
  userId: string
  firstOpened: boolean
}> = track('User Notification: Message Opened')

export const userNotificationTaskOpened: SegmentFn<{
  userNotificationId: string
  userNotificationType: string
  timeStamp: string
  userId: string
  firstOpened: boolean
}> = track('User Notification: Task Opened')

export const userNotificationTaskRead: SegmentFn<{
  userNotificationId: string
  userNotificationType: string
  timeStamp: string
  userId: string
}> = track('User Notification: Task Viewed')

export const userNotificationMessageRead: SegmentFn<{
  userNotificationId: string
  userNotificationType: string
  timeStamp: string
  userId: string
}> = track('User Notification: Message Viewed')

export const userDropdownMenuItemClicked: SegmentFn<{
  menuItem: string
  userId: string
  timeStamp: string
}> = track('User Dropdown Menu Item Clicked')

export const addSchoolButtonClicked: SegmentFn<{
  sourcePage: string
  timeStamp: string
  userId: string
}> = track('Add School: Button Clicked')

export const tryMatchQueueButtonClicked: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
}> = track('Try Match Queue: Button Clicked')

export const acceptMatchQueueClicked: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
}> = track('Accept Match Queue: Button Clicked')

export const declineMatchQueueClicked: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
}> = track('Decline Match Queue: Button Clicked')

export const matchQueueExpired: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
}> = track('Match Queue Expired: Timeout')

export const schoolSearchResultSelected: SegmentFn<{
  initialSearchTerm: string
  searchIndexRange: number
  esWeight: number
}> = track('School Search Result Selected')

export const supportLinkClicked: SegmentFn<{
  source: string
}> = track('Support Link: Button Clicked')

export const findOpponentButtonClicked: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
  slotId: string
}> = track('Find Opponent: Button Clicked')

export const leaveQueueButtonClicked: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
  slotId: string
  matchId: string
}> = track('Leave Queue: Button Clicked')

export const pageRedirectFromMatchQueueToMatchLobby: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
  slotId: string
  matchId: string
}> = track('Page Redirect: Match Queue to Match Lobby')

export const continuedQueue: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
  slotId: string
  matchId: string
}> = track('Queue Continued')

export const scrimmageQueueShown: SegmentFn<{
  timeStamp: string
  userId: string
  teamId: string
  slotId: string
  matchId: string
}> = track('Scrimmage Queue Shown')

export const adminUnlinkRiotAccount: SegmentFn<{
  adminUserId: string
  userId: string
  summonerName: string
  puuid: string
}> = track('Admin Unlink Riot Account')

// Archiving players
export const graduateVerifiedPlayer: SegmentFn<{
  graduatedUserId: string
  schoolId: string
  gradYear: number | null
}> = track('Graduate Verified Player')

export const transferVerifiedPlayer: SegmentFn<{
  transferredUserId: string
  schoolId: string
  gradYear: number | null
}> = track('Graduate Verified Player')

export const gettingStartedTaskOpened: SegmentFn<{
  userId: string
  userTaskId: string
  userTaskType: string
  schoolId: string
}> = track('Getting Started Task: Task Opened')

// Match Assistant

export const matchAssistantStepTimestamps: SegmentFn<{
  fromStepToStep: string
  timeActionWasResolved: string
  timeActionWasTaken: string
}> = track('Match Assisant Step Timestamps')

export const matchAssistantHelperClicked: SegmentFn<{
  matchId: string
  seriesOrdinal?: number
  gameOrdinal: number
  stepOrdinal: number
}> = track('Match Assistant Helper Clicked')

export const matchAssistantCompletedNPSSurvey: SegmentFn<{
  userId: string
  matchId: string
  esportSlug: string
}> = track('Match Assistant Completed NPS Survey')

export const rewindOpenClicked: SegmentFn<{
  timeStamp: string
  userId: string
}> = track('Rewind: Button Clicked')

export const rewindShareClicked: SegmentFn<{
  timeStamp: string
  userId: string
}> = track('Rewind Share: Button Clicked')

export const productTourViewed: SegmentFn<{
  userId: string
  initiatedTimestamp: string
  stepNumber: number
  timeStamp: string
  clickTarget: string
}> = track('Product Intro Tour: Step Viewed')

export const productTourClicked: SegmentFn<{
  userId: string
  initiatedTimestamp: string
  stepNumber: number
  timeStamp: string
  clickTarget: string
}> = track('Product Intro Tour: Step Clicked')

export const findMatchTourViewed: SegmentFn<{
  userId: string
  initiatedTimestamp: string
  stepNumber: number
  timeStamp: string
  clickTarget: string
}> = track('Find Your Match Tour: Step Viewed')

export const findMatchTourClicked: SegmentFn<{
  userId: string
  initiatedTimestamp: string
  stepNumber: number
  timeStamp: string
  clickTarget: string
}> = track('Find Your Match Tour: Step Clicked')

export const matchLobbyTourViewed: SegmentFn<{
  userId: string
  initiatedTimestamp: string
  stepNumber: number
  timeStamp: string
  clickTarget: string
}> = track('Match Lobby Tour: Step Viewed')

export const matchLobbyTourClicked: SegmentFn<{
  userId: string
  initiatedTimestamp: string
  stepNumber: number
  timeStamp: string
  clickTarget: string
}> = track('Match Lobby Tour: Step Clicked')
