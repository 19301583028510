import React from 'react'

import { NxTypography } from '@playvs-inc/nexus-components'

import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { ScrimmageBadge } from '@plvs/rally/components/badge'
import {
  MatchStatus,
  PhaseType,
  useGetMatchPhaseTypeAndScheduleStartsAtQuery,
} from '@plvs/graphql/generated'
import { generatePath, Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { TextGrouping } from './TextGrouping'
import { MatchCountdown } from './MatchCountdown'

const useStyles = makeStyles(() => ({
  link: {
    color: 'inherit',
    textDecoration: 'none',
  },
}))

export const MatchFaceoffHeader: React.FC<{
  countdown?: string
  liveLabel: string
  mobile?: boolean
  bestOf: number
  status: MatchStatus | null | undefined
  matchId: string
  refetchMatchStatus(): Promise<void>
  breadcrumbHref?: string
}> = ({
  countdown,
  liveLabel,
  bestOf,
  mobile,
  status,
  matchId,
  refetchMatchStatus,
  breadcrumbHref,
}) => {
  const styles = useStyles()
  const countdownStyle = mobile ? { fontSize: 12 } : {}

  const { data, loading } = useGetMatchPhaseTypeAndScheduleStartsAtQuery({
    variables: { matchId },
    skip: !matchId,
  })

  const scheduledStartsAt = data?.match?.scheduledStartsAt
  const isPreseason = data?.match?.slot?.phase?.type === PhaseType.Preseason
  const isScrimmage = data?.match?.isScrimmage
  const season = data?.match?.slot?.phase?.season

  return (
    <Box alignItems="center" display="flex" mb={mobile ? 1 : 3}>
      {breadcrumbHref && (
        <Box display="flex">
          <TextGrouping
            label={
              <Link
                className={styles.link}
                to={generatePath(breadcrumbHref, {
                  eventId: season?.id ?? '',
                })}
              >
                {season?.name}
              </Link>
            }
            value=""
          />
        </Box>
      )}
      <Box display="flex">
        <TextGrouping label={`Best of ${bestOf}`} value="" />
      </Box>
      <Box alignItems="center" display="flex" flexWrap="wrap">
        <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
          {countdown ? (
            <NxTypography
              colorToken="OverlayColorTextBase"
              style={countdownStyle}
              variant="subtitle2"
            >
              {countdown}
            </NxTypography>
          ) : (
            <MatchCountdown
              data-cy="countDown"
              date={scheduledStartsAt}
              liveLabel={liveLabel}
              mobile={mobile}
              refetchMatchStatus={refetchMatchStatus}
              status={status}
            />
          )}
          {mobile && isScrimmage && (
            <ScrimmageBadge isPreseason={isPreseason} size="small" />
          )}
        </WaitTillLoaded>
      </Box>
    </Box>
  )
}
