import React, { CSSProperties, memo } from 'react'
import { Box } from '@plvs/respawn/features/layout'
import { FixedSizeList as List } from 'react-window'
import { RosterRow, RosterRowData } from './RosterRow'

export interface RosterRowGroupProps {
  rowEntries: RosterRowData[]
  onChange(input: string): void
  selectedId?: string
  py?: number
}
export const RosterRowGroup: React.FC<RosterRowGroupProps> = ({
  onChange,
  rowEntries,
  selectedId,
  py = 2,
}) => {
  const size = rowEntries.length

  const Row: React.FC<{
    index: number
    style: CSSProperties
    data: RosterRowData[]
  }> = memo(({ index, style, data }) => {
    const rowInfo = data[index]

    return (
      <Box py={py} style={style}>
        <RosterRow
          checked={selectedId === rowInfo.id}
          onClick={onChange}
          {...rowInfo}
        />
      </Box>
    )
  })

  return (
    <>
      <List
        height={400}
        itemCount={size}
        itemData={rowEntries}
        itemSize={90}
        style={{ marginTop: 16 }}
        width="100%"
      >
        {Row}
      </List>
    </>
  )
}
