import { useMemo } from 'react'
import { MenuItem } from '@playvs-inc/nexus-components'
import { ManageMatchDropdownMenuItems } from '@plvs/respawn/renderController/matchLobby/lobby/types/lobbyRenderController.types'
import { IntercomArticleMappings, Path } from '@plvs/const'
import { generatePath, useNavigate } from 'react-router-dom'
import {
  LeagueOutlined,
  ResourcesOutlined,
  Unhide,
  Guide,
  Notes,
  Reschedule,
  Forfeit,
  Sendfeedback,
  Chat,
  TeamsOutlined,
} from '@playvs-inc/nexus-icons'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { CompetitionGroup, EsportSlug } from '@plvs/graphql'
import { getRenderedMenuItemsForManageMatchDropdown } from '@plvs/respawn/renderController/matchLobby/lobby/utils/lobbyRenderController.helpers'
import { useGeneralEsportAdapter } from '../esport/creator'

export type ConfirmSections = {
  matchLobbyTour: boolean
  manageMatchSection: boolean
}

export type DropdownActionHandlersMap = {
  [K in (typeof ManageMatchDropdownMenuItems)[keyof typeof ManageMatchDropdownMenuItems]]?: {
    onClick: () => void
    disabled?: boolean
    children?: React.ReactNode
  }
}

type DraftMenuItem = Omit<MenuItem, 'onClick'> & {
  onClick?: () => void
}

type MenuItemWithChildren = MenuItem & { children?: React.ReactNode }
interface DraftMenuGroup {
  label?: string
  items: DraftMenuItem[] | MenuItem[]
}

interface MenuGroupWithChildren {
  label?: string
  items: MenuItemWithChildren[]
}

interface GetManageMatchActions {
  menuItemIds: ManageMatchDropdownMenuItems[]
  esportSlug: EsportSlug | null | undefined
  opposingTeamId?: string
  competitionGroup: CompetitionGroup | null | undefined
  dropdownActionHandlers?: DropdownActionHandlersMap
  matchId: string
  withLabels?: boolean
  confirmRenderingSections?: ConfirmSections
}

const CompetitionLeagueRulesMap: Record<CompetitionGroup, number> = {
  [CompetitionGroup.HighSchool]: IntercomArticleMappings.ruleBooks,
  [CompetitionGroup.College]: IntercomArticleMappings.ruleBooks,
  [CompetitionGroup.Open]: IntercomArticleMappings.ruleBooks,
  [CompetitionGroup.Youth]: IntercomArticleMappings.youthRuleBooks,
  [CompetitionGroup.MiddleSchool]:
    IntercomArticleMappings.middleSchoolRuleBooks,
  [CompetitionGroup.Stadium]: IntercomArticleMappings.ruleBooks,
}

const DefaultConfirmSections: ConfirmSections = {
  matchLobbyTour: true,
  manageMatchSection: true,
}

const RulebookAction = {
  label: 'Rulebook',
  id: ManageMatchDropdownMenuItems.Rulebook,
  Icon: ResourcesOutlined,
  isExternalLink: true,
}

const LeagueRulesAction = {
  label: 'League Rules',
  id: ManageMatchDropdownMenuItems.LeagueRules,
  Icon: LeagueOutlined,
  isExternalLink: true,
}

const ScoutThisOpponentAction = {
  label: 'Scout this Opponent',
  id: ManageMatchDropdownMenuItems.Scout,
  Icon: Unhide,
  isExternalLink: true,
}

const CodeOfConductAction = {
  label: 'Code of Conduct',
  id: ManageMatchDropdownMenuItems.CodeOfConduct,
  Icon: ResourcesOutlined,
  isExternalLink: true,
  onClick: (): void =>
    showIntercomArticle(IntercomArticleMappings.codeOfConduct),
}

const MatchLobbyTourAction = {
  label: 'Match Lobby Tour',
  id: ManageMatchDropdownMenuItems.LobbyTour,
  Icon: Guide,
  isExternalLink: false,
  className: 'shepherd-match-lobby-tour',
}

const MatchInstructionsAction = {
  label: 'Match Instructions',
  id: ManageMatchDropdownMenuItems.MatchInstructions,
  Icon: Notes,
}

const RescheduleAction = {
  label: 'Reschedule',
  id: ManageMatchDropdownMenuItems.Reschedule,
  Icon: Reschedule,
}

const ViewRescheduleRequestAction = {
  label: 'View Reschedule Request',
  id: ManageMatchDropdownMenuItems.ViewReschedule,
  Icon: Reschedule,
}

const ForfeitAction = {
  label: 'Forfeit',
  id: ManageMatchDropdownMenuItems.Forfeit,
  Icon: Forfeit,
}

const DisputeAction = {
  label: 'Dispute Match',
  id: ManageMatchDropdownMenuItems.Dispute,
  Icon: Sendfeedback,
}

const ChatAction = {
  label: 'Chat',
  id: ManageMatchDropdownMenuItems.Chat,
  Icon: Chat,
}

const JoinQueue = {
  label: 'Join Queue',
  id: ManageMatchDropdownMenuItems.JoinQueue,
  Icon: TeamsOutlined,
}

const insertDropdownActionHandlers = ({
  draftMenuItems,
  dropdownActionHandlers,
}: {
  draftMenuItems: DraftMenuGroup[]
  dropdownActionHandlers: DropdownActionHandlersMap
}): MenuGroupWithChildren[] => {
  return draftMenuItems.map((menuGroup) => {
    return {
      ...menuGroup,
      items: menuGroup.items.map((item) => {
        const id = item.id as keyof typeof ManageMatchDropdownMenuItems
        const action = dropdownActionHandlers[id]

        if (action) {
          return {
            ...item,
            onClick: action.onClick,
            disabled: action.disabled,
            children: action.children,
          }
        }
        return item
      }),
    }
  })
}

const createMenuItems = ({
  withLabels,
  dropdownActionHandlers,
  confirmRenderingSections,
}: {
  withLabels: boolean
  dropdownActionHandlers: DropdownActionHandlersMap
  confirmRenderingSections: ConfirmSections
}): MenuGroupWithChildren[] => {
  let menuItems: MenuGroupWithChildren[] = []
  if (withLabels) {
    const draftMenuItemsWithLabels = [
      {
        label: 'Resources',
        items: [
          RulebookAction,
          LeagueRulesAction,
          ScoutThisOpponentAction,
          CodeOfConductAction,
        ],
      },
      ...(confirmRenderingSections.matchLobbyTour
        ? [
            {
              label: 'Match Lobby Tour',
              items: [MatchLobbyTourAction],
            },
          ]
        : []),
      ...(confirmRenderingSections.manageMatchSection
        ? [
            {
              label: 'Manage Match',
              items: [
                JoinQueue,
                ChatAction,
                MatchInstructionsAction,
                RescheduleAction,
                ViewRescheduleRequestAction,
                ForfeitAction,
                DisputeAction,
              ],
            },
          ]
        : []),
    ]
    menuItems = insertDropdownActionHandlers({
      draftMenuItems: draftMenuItemsWithLabels,
      dropdownActionHandlers,
    })
    return menuItems
  }
  const draftMenuItemsWithoutLabels = [
    {
      items: [
        JoinQueue,
        ChatAction,
        RulebookAction,
        LeagueRulesAction,
        ScoutThisOpponentAction,
        CodeOfConductAction,
        MatchLobbyTourAction,
        MatchInstructionsAction,
        RescheduleAction,
        ViewRescheduleRequestAction,
        ForfeitAction,
        DisputeAction,
      ],
    },
  ]
  menuItems = insertDropdownActionHandlers({
    draftMenuItems: draftMenuItemsWithoutLabels,
    dropdownActionHandlers,
  })
  return menuItems
}

export const useGetManageMatchActions = ({
  menuItemIds,
  esportSlug,
  competitionGroup,
  opposingTeamId,
  matchId,
  dropdownActionHandlers,
  withLabels = true,
  confirmRenderingSections = DefaultConfirmSections,
}: GetManageMatchActions): MenuGroupWithChildren[] => {
  const navigate = useNavigate()
  const { rulebookArticleId, matchInstructionsArticleId } =
    useGeneralEsportAdapter(esportSlug)
  const leagueRulebookId = competitionGroup
    ? CompetitionLeagueRulesMap[competitionGroup]
    : ''

  const onClickRulebook = (): void => {
    showIntercomArticle(rulebookArticleId)
  }
  const onClickLeagueRulebook = (): void => {
    showIntercomArticle(leagueRulebookId)
  }
  const onClickScoutThisOpponent = (): void => {
    window.open(`/app/team/${opposingTeamId}`, '_blank', 'noreferrer')
    window?.focus()
  }

  const onClickMatchInstructionsAction = (): void => {
    showIntercomArticle(
      matchInstructionsArticleId ?? IntercomArticleMappings.allArticles
    )
  }
  const disabledOnClickMatchInstructionsAction = !matchInstructionsArticleId

  const onClickDisputeAction = (): void => {
    if (matchId) {
      navigate({
        pathname: generatePath(Path.MatchDispute, {
          matchId,
        }),
      })
    }
  }

  const combinedDropdownActionHandlers: DropdownActionHandlersMap = {
    [ManageMatchDropdownMenuItems.Rulebook]: {
      onClick: onClickRulebook,
    },
    [ManageMatchDropdownMenuItems.LeagueRules]: {
      onClick: onClickLeagueRulebook,
    },
    [ManageMatchDropdownMenuItems.Scout]: {
      onClick: onClickScoutThisOpponent,
    },
    [ManageMatchDropdownMenuItems.MatchInstructions]: {
      onClick: onClickMatchInstructionsAction,
      disabled: disabledOnClickMatchInstructionsAction,
    },
    [ManageMatchDropdownMenuItems.Dispute]: {
      onClick: onClickDisputeAction,
    },
    ...dropdownActionHandlers,
  }

  const menuItems = createMenuItems({
    withLabels,
    dropdownActionHandlers: combinedDropdownActionHandlers,
    confirmRenderingSections,
  })

  return useMemo(() => {
    const renderedMenuItems = getRenderedMenuItemsForManageMatchDropdown(
      menuItems,
      menuItemIds
    )

    return renderedMenuItems
  }, [menuItems, menuItemIds])
}
