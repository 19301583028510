import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { Box, useTheme } from '@material-ui/core'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import {
  NxScoreboardRows,
  NxTypography,
  ScoreboardRow,
  Pill,
  NxFirebobMessage,
  NxButton,
} from '@playvs-inc/nexus-components'

import {
  MatchStatus,
  useGetFinalMatchResultsQuery,
  useRequestNextGameRosterChangeMutation,
} from '@plvs/graphql/generated'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { EsportSlug } from '@plvs/graphql/types'
import * as analytics from '@plvs/respawn/features/analytics'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { defaultToEmptyString, sortByGameOrdinal } from '@plvs/utils'
import { useMatchAssistantContext } from '../../../useMatchAssistant'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'

dayjs.extend(duration)

interface ScoreboardProps {
  isFinal?: boolean
}

export const Scoreboard: React.FC<ScoreboardProps> = ({ isFinal = false }) => {
  const theme = useTheme()
  const { userId } = useUserIdentityFn()
  const {
    matchId,
    gameOrdinal,
    currentStep,
    homeTeam,
    awayTeam,
    esportSlug,
    match,
    myTeam,
  } = useMatchAssistantContext()
  const matchDetails = currentStep?.matchDetails
  const isMatchComplete =
    matchDetails?.isComplete ??
    (match?.status === MatchStatus.Completed ||
      match?.status === MatchStatus.Forfeited)
  const [loadingState, setLoadingState] = useState(false)
  const [hasRequestedRosterChange, setHasRequestedRosterChange] = useState(
    currentStep?.nextGameRosterChangeRequested ?? false
  )

  const games = [...(matchDetails?.games ?? [])]
  const sortedGames = sortByGameOrdinal(games)
  const currentGame = !isMatchComplete
    ? sortedGames.find((game) => game.gameOrdinal === gameOrdinal)
    : undefined
  const timeRemainingInSeconds = currentGame?.timeRemainingInSeconds ?? 0

  const estimatedTimeLeft = dayjs.duration({
    minutes: Math.floor(timeRemainingInSeconds / 60),
    seconds: timeRemainingInSeconds % 60,
  })

  const { data, loading } = useGetFinalMatchResultsQuery({
    variables: { id: matchId },
    skip: !matchId || !isMatchComplete,
  })

  const leftSide = {
    avatarUrl: homeTeam?.avatarUrl ?? '',
    id: homeTeam?.id ?? '',
  }
  const rightSide = {
    avatarUrl: awayTeam?.avatarUrl ?? '',
    id: awayTeam?.id ?? '',
  }

  const gameResults: ScoreboardRow[] = sortedGames
    .filter((game) => game.gameOrdinal !== currentGame?.gameOrdinal)
    .map((game) => {
      return {
        title: `Game ${game.gameOrdinal}`,
        showScore: true,
        isScoreFinal: true,
        leftSide: {
          ...leftSide,
          score: game.scores?.find((g) => g.teamId === leftSide.id)?.score ?? 0,
        },
        rightSide: {
          ...rightSide,
          score:
            game.scores?.find((g) => g.teamId === rightSide.id)?.score ?? 0,
        },
      }
    })

  const finalScore: ScoreboardRow[] = [
    {
      showScore: true,
      isScoreFinal: true,
      title: 'Final',
      leftSide: {
        ...leftSide,
        title: homeTeam?.name ?? '',
        subtitle: homeTeam?.school?.name ?? '',
        score:
          data?.match?.matchResults?.find(
            (result) => result.teamId === leftSide?.id
          )?.gamesWon ?? 0,
      },
      rightSide: {
        ...rightSide,
        title: awayTeam?.name ?? '',
        subtitle: awayTeam?.school?.name ?? '',
        score:
          data?.match?.matchResults?.find(
            (result) => result.teamId === rightSide?.id
          )?.gamesWon ?? 0,
      },
    },
  ]

  const currentGameScore: ScoreboardRow[] = [
    {
      showScore: true,
      isScoreFinal: false,
      title: '',
      leftSide: {
        ...leftSide,
        title: homeTeam?.name ?? '',
        subtitle: homeTeam?.school?.name ?? '',
        score:
          currentGame?.scores?.find((score) => score.teamId === homeTeam?.id)
            ?.score ?? 0,
      },
      rightSide: {
        ...rightSide,
        title: awayTeam?.name ?? '',
        subtitle: awayTeam?.school?.name ?? '',
        score:
          currentGame?.scores?.find((score) => score.teamId === awayTeam?.id)
            ?.score ?? 0,
      },
    },
  ]

  const hasPreviousGameResults = Boolean(gameResults.length)
  const scoreIsTied =
    currentGameScore[0]?.leftSide?.score ===
    currentGameScore[0]?.rightSide?.score
  const isCurrentGameInOT =
    scoreIsTied &&
    typeof currentGame?.timeRemainingInSeconds === 'number' &&
    currentGame?.timeRemainingInSeconds < 1

  useEffect(() => {
    if (loading) {
      setLoadingState(true)
    }
  }, [loading])

  useEffect(() => {
    if (
      data?.match?.matchResults?.some((result) => (result.gamesWon ?? 0) > 1)
    ) {
      setLoadingState(false)
    }
  }, [(data?.match?.matchResults ?? []).toString()])

  const { enqueueSnackbar } = useSnackbar()
  const [
    mutateRequestNextGameRosterChange,
    { loading: isLoadingChangeRoster },
  ] = useRequestNextGameRosterChangeMutation()

  async function requestRosterChange(): Promise<void> {
    try {
      await mutateRequestNextGameRosterChange({
        variables: {
          input: {
            matchId,
            teamId: defaultToEmptyString(myTeam?.id),
            currentGameOrdinal: gameOrdinal,
          },
        },
      })
      setHasRequestedRosterChange(true)
    } catch (err) {
      enqueueSnackbar(
        'Failed to request roster change. Please try again or contact support.',
        { variant: 'error' }
      )
    }
  }

  const currentTimeAndScore = esportSlug === EsportSlug.RocketLeague && (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mx={3}
    >
      <Box mt={2}>
        <Pill
          label={
            isCurrentGameInOT ? 'OT' : `~ ${estimatedTimeLeft.format('m:ss')}`
          }
          size="small"
          variant="disabled"
        />
      </Box>

      <NxScoreboardRows data={currentGameScore} />
    </Box>
  )

  const header = (
    <Box
      alignItems="center"
      borderBottom={
        hasPreviousGameResults && `1px solid ${theme.palette.BorderLight}`
      }
      display="flex"
      flexDirection="column"
      justifyContent="center"
      mx={-3}
      pb={1}
      px={2}
    >
      {isMatchComplete ? (
        <WaitTillLoaded
          loading={loadingState}
          loadingSpinnerProps={{ size: 'medium' }}
          showSpinnerWhileLoading
        >
          <NxScoreboardRows data={finalScore} />
        </WaitTillLoaded>
      ) : (
        <>
          <NxTypography variant="h1">
            Game {gameOrdinal} in Progress
          </NxTypography>
          {hasPreviousGameResults && currentTimeAndScore}
        </>
      )}
    </Box>
  )

  useEffect(() => {
    if (isMatchComplete) {
      analytics.matchAssistantCompletedNPSSurvey({
        userId,
        matchId,
        esportSlug: esportSlug ?? '',
      })
    }
  }, [isMatchComplete])

  return (
    <>
      {header}

      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        pb={3}
        pt={4}
      >
        {hasPreviousGameResults ? (
          <NxScoreboardRows
            data={gameResults}
            dataRowMargin={0}
            gridGap={10}
            titleMargin={0}
          />
        ) : (
          currentTimeAndScore
        )}
      </Box>

      {!isFinal && (
        <MatchAssistantFooter>
          <Box alignItems="center" display="flex">
            <Box flex={1}>
              <NxFirebobMessage
                message={
                  hasRequestedRosterChange
                    ? `Roster Change: Teams will confirm rosters at the end of Game ${gameOrdinal}.`
                    : 'Retry for match reinvites or request roster change for next game.'
                }
              />
            </Box>
          </Box>

          <NxButton
            disabled={hasRequestedRosterChange}
            label={
              hasRequestedRosterChange
                ? 'Change Requested'
                : 'Roster Change Next Game'
            }
            loading={isLoadingChangeRoster}
            onClick={requestRosterChange}
            variant="secondary"
          />
        </MatchAssistantFooter>
      )}
    </>
  )
}
