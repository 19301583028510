import React from 'react'
import { Link, makeStyles, useTheme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

import { NxTypography } from '@playvs-inc/nexus-components'
import { NxTypographyVariant } from '@playvs-inc/nexus-theme'

import { RouterLink } from '@plvs/respawn/features/route'
import { Box } from '@plvs/respawn/features/layout'
import { OnlineBadge, TeamCaptainBadge } from '@plvs/rally/components/badge'
import { EsportRating } from '@plvs/graphql/generated'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { UserClusterSubtitle } from './UserClusterSubtitle'
import { ErrorBadge } from '../badge/ErrorBadge'

export interface StyleProps {
  dim?: boolean
  titleColor: string
  titleUrl?: string
  shouldStrikeThroughTitle?: boolean
}

const useStyle = makeStyles((theme) => ({
  title: ({
    titleUrl,
    dim,
    titleColor,
    shouldStrikeThroughTitle,
  }: StyleProps): CreateCSSProperties => ({
    cursor: titleUrl ? 'pointer' : 'default',
    '&:hover': {
      textDecoration: titleUrl ? 'underline' : 'none',
    },
    color: dim ? theme.palette.ColorTextDisabled : titleColor,
    textDecoration: shouldStrikeThroughTitle ? 'line-through' : 'inherit',
  }),
}))

export interface UserClusterProps {
  id: string | null | undefined
  isCaptain?: boolean | null
  hasError?: boolean | null
  title: string | null | undefined
  subtitle?: string | null
  avatarUrl: string | null | undefined
  avatarSize?: NxAvatarSize
  rtl?: boolean
  flex?: number
  ringColor?: string | null
  pt?: number
  pb?: number
  dim?: boolean
  titleColor?: string
  subtitleColor?: string
  lastSeen?: string | null
  useCheckpointUrl?: boolean
  titleUrl?: string
  subtitleUrl?: string
  subtitleNode?: React.ReactNode
  showSubtitleLinkNoUnderline?: boolean
  shouldStrikeThroughTitle?: boolean
  typographyVariant?: NxTypographyVariant
  esportRating?: EsportRating
}

export const UserCluster = ({
  id,
  avatarUrl,
  avatarSize = NxAvatarSize.MD,
  isCaptain,
  hasError,
  subtitle,
  subtitleColor,
  title,
  titleColor,
  rtl,
  dim,
  ringColor,
  pt = 2,
  pb = 2,
  lastSeen,
  useCheckpointUrl = false,
  titleUrl,
  subtitleUrl,
  showSubtitleLinkNoUnderline,
  subtitleNode,
  typographyVariant = 'h4',
  shouldStrikeThroughTitle = false,
  esportRating = EsportRating.General,
  ...rest
}: UserClusterProps): React.ReactElement => {
  const theme = useTheme()
  const themedTitleColor =
    titleColor ?? (theme.palette.ColorTextBase || theme.palette.text.primary)
  const showRandom = esportRating === EsportRating.Restricted
  const classes = useStyle({
    titleColor: themedTitleColor,
    titleUrl,
    dim,
    shouldStrikeThroughTitle,
  })

  const cluster = (
    <Box
      display="flex"
      flexDirection={rtl ? 'row-reverse' : 'row'}
      textAlign={rtl ? 'right' : 'left'}
      {...rest}
    >
      <Box
        flex="0"
        pb={pb}
        pt={pt}
        {...(rtl
          ? { pl: avatarSize === NxAvatarSize.SM ? 1.5 : 2 }
          : { pr: avatarSize === NxAvatarSize.SM ? 1.5 : 2 })}
      >
        <Box position="relative" width="100%">
          <NxUserAvatar
            avatarUrl={showRandom ? null : avatarUrl}
            hashId={id ?? ''}
            ringColor={ringColor}
            showRing={!!ringColor}
            size={avatarSize}
          />
          {typeof lastSeen !== 'undefined' && (
            <OnlineBadge lastSeen={lastSeen} />
          )}
          {isCaptain && <TeamCaptainBadge />}
          {hasError && <ErrorBadge />}
        </Box>
      </Box>
      <Box
        alignItems={rtl ? 'flex-end' : 'flex-start'}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <NxTypography
          className={classes.title}
          data-testid="UserCluster_Title"
          variant={typographyVariant}
        >
          {title}
        </NxTypography>
        <Box
          color={dim ? theme.palette.ColorTextAlt2 : theme.palette.ColorTextAlt}
        >
          <UserClusterSubtitle
            isCaptain={isCaptain}
            removeSubtitleLinkUnderline={showSubtitleLinkNoUnderline}
            subtitle={subtitle}
            subtitleColor={
              subtitleColor ??
              (theme.palette.ColorTextAlt2 || theme.palette.text.hint)
            }
            subtitleNode={subtitleNode}
            subtitleUrl={subtitleUrl}
          />
        </Box>
      </Box>
    </Box>
  )

  return id && useCheckpointUrl ? (
    <Link
      color="inherit"
      component={RouterLink}
      to={`/user/${id}`}
      underline="none"
    >
      {cluster}
    </Link>
  ) : (
    cluster
  )
}
