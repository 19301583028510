import React from 'react'
import {
  NxSpotModal,
  NxButton,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { makeStyles, MenuItem, TextField } from '@material-ui/core'

import { Box } from '@plvs/respawn/features/layout'

import { Connectaccounts } from '@playvs-inc/nexus-icons'
import {
  GameHighlight,
  GameHighlightSource,
  MatchStatus,
  Maybe,
  Provider,
  useCreateGameHighlightMutation,
  useGetHighlightMatchesQuery,
  useGetMyProviderAccountsQuery,
  useUpdateGameHighlightMutation,
} from '@plvs/graphql/generated'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { Path } from '@plvs/const'
import { useForm } from 'react-hook-form'
import { SelectController } from '@plvs/rally/components/select/SelectController'
import { yupResolver } from '@hookform/resolvers'
import { Skeleton } from '@material-ui/lab'
import { providerPrettyNames } from '@plvs/respawn/features/esport/creator/esportStaticDetails'
import { useSnackbar } from 'notistack'
import { isNil } from 'ramda'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNavigate } from 'react-router-dom'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import {
  getCreateVideoError,
  getSubmitVideoSchema,
  SubmitVideoInput,
} from './highlightHelpers'

const useStyles = makeStyles((theme) => ({
  connectError: {
    backgroundColor: theme.palette.ColorBackgroundError,
  },
  connectIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.ColorIconError,
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  menuItem: {
    color: theme.palette.ColorTextAlt,
  },
}))

interface Props {
  open: boolean
  onClose(): void
  userId: string
  highlight?: Maybe<
    Pick<GameHighlight, 'id' | 'title' | 'videoUrl' | 'userId' | 'matchId'>
  >
  refetchHighlights(): void
  isAdmin?: boolean
  metaseasonId?: string
}

export const UploadVideoModal: React.FC<Props> = ({
  open,
  onClose,
  userId,
  highlight,
  refetchHighlights,
  isAdmin = false,
  metaseasonId,
}) => {
  const classes = useStyles()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const flags = useFlags()

  const isEditMode = !isNil(highlight)

  const { control, register, handleSubmit, errors } = useForm<SubmitVideoInput>(
    {
      resolver: yupResolver<SubmitVideoInput>(getSubmitVideoSchema(isEditMode)),
      defaultValues: {
        title: highlight?.title ?? '',
        videoUrl: highlight?.videoUrl ?? '',
        matchId: highlight?.matchId ?? '',
      },
    }
  )

  const { metaseason: schooLeagueInfoMetaseason } = useSchoolLeagueInfoContext()

  const currentMetaseasonId =
    (metaseasonId || schooLeagueInfoMetaseason?.id) ?? ''

  const { data, loading } = useGetHighlightMatchesQuery({
    variables: {
      playerId: isAdmin ? highlight?.userId ?? '' : userId,
      metaseasonId: currentMetaseasonId,
      statuses: [MatchStatus.Completed],
      order: 'asc',
    },
    skip: !open || !currentMetaseasonId,
  })
  const {
    data: providerData,
    loading: providersLoading,
  } = useGetMyProviderAccountsQuery()

  const [
    createHighlight,
    { loading: createHighlightLoading, error: createError },
  ] = useCreateGameHighlightMutation()

  const [
    editHighlight,
    { loading: editHighlightLoading },
  ] = useUpdateGameHighlightMutation()

  const onSubmit = async (input: SubmitVideoInput): Promise<void> => {
    const isYoutubeUrl =
      input.videoUrl?.includes('youtube') ||
      input.videoUrl?.includes('youtu.be')

    try {
      const source = isYoutubeUrl
        ? GameHighlightSource.Youtube
        : GameHighlightSource.Twitch

      if (isEditMode) {
        const result = await editHighlight({
          variables: {
            input: {
              id: highlight?.id ?? '',
              attributes: {
                title: input.title,
                ...(input.matchId && { matchId: input.matchId }),
                ...(isAdmin && { videoUrl: input.videoUrl }),
                ...(isAdmin && input.videoUrl && { source }),
              },
            },
          },
        })

        if (result?.data?.updateGameHighlight?.gameHighlight?.id) {
          enqueueSnackbar('Successfully edited video', { variant: 'success' })
          refetchHighlights()
          onClose()
        }

        return
      }

      const result = await createHighlight({
        variables: {
          input: {
            title: input.title,
            videoUrl: input.videoUrl,
            ...(input.matchId && { matchId: input.matchId }),
            userId,
            source,
          },
        },
      })

      if (result?.data?.createGameHighlight?.gameHighlight?.id) {
        enqueueSnackbar('Successfully uploaded video', { variant: 'success' })
      }

      refetchHighlights()
      onClose()
    } catch (error) {
      enqueueSnackbar('Failed to upload video', { variant: 'error' })
    }
  }

  const hasTwitchAccount = providerData?.me?.userProviderAccounts?.some(
    (account) => account?.providerName === Provider.Twitch
  )
  const hasYoutubeAccount = providerData?.me?.userProviderAccounts?.some(
    (account) => account?.providerName === Provider.Youtube
  )
  const showYoutubeConnection = flags.youtubeAccountConnection

  const providersText = showYoutubeConnection
    ? `${providerPrettyNames.Twitch} or ${providerPrettyNames.Youtube}`
    : providerPrettyNames.Twitch

  const matches = data?.findPlayerMetaseasonMatches ?? []

  const videoUrlError =
    (errors?.videoUrl?.message ?? '') ||
    getCreateVideoError(createError?.message)
  const titleError = errors?.title?.message ?? ''
  const matchIdError = errors?.matchId?.message ?? ''

  const getSubmitDisabled = (): boolean => {
    if (isAdmin) {
      return loading || editHighlightLoading
    }

    return (
      loading ||
      !(hasTwitchAccount || hasYoutubeAccount) ||
      createHighlightLoading ||
      editHighlightLoading ||
      providersLoading
    )
  }

  const showAccountConnectionBanner =
    !(hasTwitchAccount || hasYoutubeAccount) && !isAdmin

  return (
    <NxSpotModal
      actions={
        <form onSubmit={handleSubmit(onSubmit)}>
          <NxButton label="Cancel" onClick={onClose} variant="text" />
          <NxButton
            disabled={getSubmitDisabled()}
            label="Publish"
            type="submit"
            variant="primary"
          />
        </form>
      }
      onClose={onClose}
      open={open}
      showTopRightClose
      size="large"
      spot={
        <NxSpot
          domain="video"
          height={200}
          size="large"
          variant="primary"
          width={200}
        />
      }
      title="Submit Game Highlights"
    >
      {showAccountConnectionBanner && (
        <Box className={classes.connectError} display="flex" mb={2} p={2}>
          <Connectaccounts className={classes.connectIcon} />
          <NxTypography variant="body1">
            Please connect your {providersText} account to submit a clip.
          </NxTypography>
          <NxButton
            label="Connect"
            onClick={(): void => navigate(Path.ConnectionSettings)}
            variant="secondary"
          />
        </Box>
      )}
      <TextField
        className={classes.textField}
        defaultValue={highlight?.videoUrl ?? ''}
        disabled={isAdmin ? false : isEditMode}
        error={!!videoUrlError}
        fullWidth
        helperText={videoUrlError}
        inputRef={register}
        label="Video URL"
        name="videoUrl"
        required={!isEditMode}
        variant="outlined"
      />
      <TextField
        className={classes.textField}
        defaultValue={highlight?.title ?? ''}
        error={!!titleError}
        fullWidth
        helperText={titleError}
        inputRef={register}
        label="Title"
        name="title"
        required
        variant="outlined"
      />
      {loading ? (
        <Skeleton height={60} />
      ) : (
        <>
          {matches.length > 0 && (
            <SelectController
              control={control}
              defaultValue={highlight?.matchId}
              error={!!matchIdError}
              fullWidth
              label="Select Match"
              name="matchId"
              variant="outlined"
            >
              {matches.map((match) => (
                <MenuItem
                  key={match.id}
                  className={classes.menuItem}
                  value={match.id}
                >
                  {match?.team1?.name ?? ''} vs {match?.team2?.name ?? ''}
                </MenuItem>
              ))}
            </SelectController>
          )}
        </>
      )}
    </NxSpotModal>
  )
}
