import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import {
  UserRoleName,
  VerificationStep,
  useValidateScholasticRoleMutation,
  ResourceType,
  useGetMyVerificationStatusQuery,
  refetchGetMyVerificationStatusQuery,
} from '@plvs/graphql/generated/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'
import { OnboardSheerIdDocUpload } from './components/OnboardSheerIdDocUpload'
import { OnboardSheerIdVerificationStatus } from './components/OnboardSheerIdVerificationStatus'

export const OnboardSheerId: React.FC = () => {
  // Mutation Hook
  const [
    mutate,
    { loading: scholasticLoading },
  ] = useValidateScholasticRoleMutation()

  // Query Hook
  const {
    data: verificationData,
    loading: verificationLoading,
  } = useGetMyVerificationStatusQuery()

  const navigate = useNavigate()
  const { data: onboardContext } = useOnboardingContext()
  const [requestTimer, setRequestTimer] = useState(2)
  const [timerSwitch, setTimerSwitch] = useState(true)

  const userVerifcationData = verificationData?.me
  const { schoolHasCoach, schoolId } = onboardContext
  const isStudent =
    userVerifcationData?.roles?.some(
      (r) =>
        r.resourceType === ResourceType.Organization &&
        r.roleName === UserRoleName.Student
    ) ?? false
  const roleName = (isStudent && UserRoleName.Player) || UserRoleName.Coach

  const schoolIdForSheerId = userVerifcationData?.school?.id ?? schoolId ?? ''
  const verificationId = userVerifcationData?.currentVerification?.id ?? ''
  const isVerified =
    userVerifcationData?.currentVerification?.step === VerificationStep.Success
  const requiresDocUpload =
    userVerifcationData?.currentVerification?.step ===
    VerificationStep.DocUpload
  const sheerIdOrganizationId =
    verificationData?.me?.school?.sheeridOrganizationId
  const showNASEFUx = onboardContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  useEffect(() => {
    const pollingSpeed = requestTimer * 1000

    if (
      timerSwitch &&
      schoolIdForSheerId &&
      sheerIdOrganizationId &&
      (requiresDocUpload || !userVerifcationData?.currentVerification) &&
      !scholasticLoading &&
      !verificationLoading
    ) {
      // Keep multiplying timer until it hits 60 seconds otherwise increase timing by 2 seconds
      setTimeout(() => {
        if (requestTimer < 60) {
          setRequestTimer(requestTimer + 2)
        }
        try {
          mutate({
            refetchQueries: [refetchGetMyVerificationStatusQuery()],
            variables: {
              input: {
                schoolId: schoolIdForSheerId as string,
                roleName,
              },
            },
          })
        } catch (err: any) {
          return err
        }
        return setTimerSwitch(!timerSwitch)
      }, pollingSpeed)
    }
  }, [
    verificationData,
    schoolIdForSheerId,
    scholasticLoading,
    verificationLoading,
    requiresDocUpload,
  ])

  // Lands on scholastic verification page first before deciding which route to replace
  useEffect(() => {
    if (requiresDocUpload) {
      navigate(`${Path.DocUpload}${showNASEFUx}`, { replace: true })
    } else if (isVerified) {
      navigate(`${Path.VerificationSuccess}${showNASEFUx}`, { replace: true })
    } else if (!isVerified && !requiresDocUpload) {
      navigate(`${Path.VerificationPending}${showNASEFUx}`, { replace: true })
    }
  }, [requiresDocUpload, isVerified])

  return (
    <WaitTillLoaded
      loading={
        !!(
          verificationLoading ||
          (!userVerifcationData?.currentVerification && sheerIdOrganizationId)
        )
      }
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      {!isVerified && requiresDocUpload ? (
        <OnboardSheerIdDocUpload
          isStudent={isStudent}
          verificationId={verificationId}
        />
      ) : (
        <OnboardSheerIdVerificationStatus
          isStudent={isStudent}
          isVerified={isVerified}
          schoolHasCoach={schoolHasCoach}
        />
      )}
    </WaitTillLoaded>
  )
}
