import React from 'react'
import { Route, Navigate, useLocation } from 'react-router-dom'

import { CompetitionGroup, UserRoleName } from '@plvs/graphql'
import { useUserAttrsContext } from '@plvs/rally/containers/app'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { Path } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { useOrganizationsContext } from '@plvs/rally/containers/organization/OrganizationsProvider'
import {
  checkIfAllowedByRoles,
  getPath,
  isCoachAtOrganization,
} from '@plvs/utils'
import { UserOnlineStatusProvider } from '@plvs/respawn/features/userOnlineStatus/UserOnlineStatusProvider'
import { ManageTeamsProvider } from '@plvs/respawn/containers/manageTeams/ManageTeamsProvider'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { ManagePlayersPage } from './ManagePlayersPage'
import { ManageTeamsPage } from './ManageTeamsPage'
import { EnrollmentPage } from './EnrollmentPage'

export const ManageRoute: React.FC = () => {
  const { pathname } = useLocation()

  const path = getPath(pathname)

  const {
    organization,
    loading: organizationLoading,
  } = useOrganizationsContext()
  const {
    roles,
    selectedEntityId,
    loading: profileLoading,
  } = useProfileContext()
  const {
    isUserPendingVerificationAtOrg,
    loading: verificationLoading,
  } = useUserIdentityFn()
  const { attributes, loading: loadingAttributes } = useUserAttrsContext()
  const isCoachAtOrg = isCoachAtOrganization(roles, [selectedEntityId])

  const isHighSchool =
    organization?.competitionGroup === CompetitionGroup.HighSchool

  const hasManageTeams = isHighSchool
    ? isCoachAtOrg
    : !!attributes?.hasManageTeams
  if (isUserPendingVerificationAtOrg && !verificationLoading) {
    return <Navigate to={Path.Dashboard} />
  }

  const loadingState =
    organizationLoading ||
    profileLoading ||
    verificationLoading ||
    loadingAttributes
  const userRoleNames = roles.map(({ roleName }) => roleName)

  const isAllowed = checkIfAllowedByRoles({
    userRoleNames,
    requireAnyOf: [UserRoleName.Coach, UserRoleName.Admin],
  })
  return (
    <WaitTillLoaded loading={loadingState}>
      <ApmRoutes>
        {hasManageTeams ? (
          <Route
            element={
              <UserOnlineStatusProvider>
                <ManageTeamsProvider>
                  <ManageTeamsPage />
                </ManageTeamsProvider>
              </UserOnlineStatusProvider>
            }
            path="teams"
          />
        ) : (
          <Route element={<Navigate to={Path.Dashboard} />} />
        )}
        {isAllowed && (
          <>
            <Route element={<ManagePlayersPage />} path="players" />
            <Route element={<EnrollmentPage />} path="enrollment/:id/*" />
          </>
        )}

        <Route element={<Navigate to={`${path}/teams`} />} />
      </ApmRoutes>
    </WaitTillLoaded>
  )
}
