import React from 'react'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'

import {
  useScrimmageRequestQuery,
  ScrimmageRequestQuery,
  Team,
  CompetitionGroup,
  useMySchoolDetailsQuery,
} from '@plvs/graphql'
import { NxModal, NxButton } from '@playvs-inc/nexus-components'
import { Param, Path } from '@plvs/const'
import { useQueryParam } from '@plvs/rally/components/filter'
import { ScrimmagePageRefreshContext } from '@plvs/rally/components/scrimmage/scrimmageHelpers'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { JoinScrimmageStepB } from './JoinScrimmageStepB'
import { JoinScrimmageStepC } from './JoinScrimmageStepC'
import { SpotModalData } from './types'
import { JoinScrimmageStepA } from './JoinScrimmageStepA'

dayjs.extend(tz)

enum JoinScrimmageStep {
  Initialize = 0,
  ScrimmageJoined = 1,
  RecommendedScrimmages = 2,
}

const getSpotModalData = ({
  scrimmageFilled,
  scrimmageNotFound,
  joinRecommendedError,
  teamsNotAvailable,
}: {
  scrimmageFilled: boolean
  scrimmageNotFound: boolean
  teamsNotAvailable: boolean
  joinRecommendedError: string
}): SpotModalData => {
  if (scrimmageNotFound) {
    return {
      spotSubtitle:
        'We couldn‘t find this scrimmage in our records. It may have been deleted.',
      spotTitle: 'Scrimmage No Longer Available',
      action: (
        <NxButton
          href={Path.Scrimmage}
          label="See Available Scrimmages"
          variant="secondary"
        />
      ),
    }
  }
  if (scrimmageFilled) {
    return {
      spotSubtitle: 'Another team has already joined this scrimmage.',
      spotTitle: 'Scrimmage no longer available',
      action: (
        <NxButton
          href={Path.Scrimmage}
          label="See Available Scrimmages"
          variant="secondary"
        />
      ),
    }
  }

  if (joinRecommendedError) {
    return {
      spotSubtitle: joinRecommendedError,
      spotTitle: 'An Error has Occured',
    }
  }

  if (teamsNotAvailable) {
    return {
      spotTitle: 'Unable to Join Scrimmage',
      spotSubtitle:
        "This could be caused by one of two issues: 1) you don't have any activated teams or 2) you don't have any teams that are free to play during the time slot of this scrimmage.",
      action: (
        <NxButton
          href={Path.ManageTeams}
          label="Manage Teams"
          variant="secondary"
        />
      ),
    }
  }
  return { spotSubtitle: '', spotTitle: '' }
}

const getScrimmageModals = ({
  esportName,
  myAvailableTeams,
  onClose,
  scrimmageNotFound,
  scrimmageRequest,
  step,
  schoolId,
  setJoinRecommendedError,
  setSuccessStep,
  id,
  onJoinRecommendedSuccess,
  schoolCompetitionGroup,
  open,
  spotModalData,
  toggleRefresh,
  loadingScrimmageRequest,
}: {
  esportName: string
  myAvailableTeams: Pick<Team, 'id' | 'name'>[]
  onClose(): void
  scrimmageNotFound: boolean
  scrimmageRequest?: ScrimmageRequestQuery['scrimmageRequest']
  step: JoinScrimmageStep
  setJoinRecommendedError: React.Dispatch<React.SetStateAction<string>>
  schoolId: string
  onJoinRecommendedSuccess(scrimmageId: string): Promise<void>
  schoolCompetitionGroup: CompetitionGroup | null
  open: boolean
  spotModalData: SpotModalData
  setSuccessStep(): void
  id: string
  loadingScrimmageRequest: boolean
  toggleRefresh(): void
}): React.ReactElement => {
  const timezone = dayjs.tz.guess()
  const formattedDatetime =
    scrimmageRequest?.startsAt && timezone
      ? dayjs(scrimmageRequest?.startsAt)
          .tz(timezone)
          .format('h:mma z MMM D, YYYY')
      : '???'

  const requestingTeamName = scrimmageRequest?.requestingTeam?.name ?? ''
  const opposingTeamName = scrimmageRequest?.opposingTeam?.name ?? ''

  if (scrimmageNotFound) {
    return (
      <NxModal
        onClose={onClose}
        open={open}
        showTopRightClose
        size="small"
        subtitle="Something has gone wrong. We are unable to find selected scrimmage"
        title="Scrimmage Not Found"
      >
        {null}
      </NxModal>
    )
  }

  switch (step) {
    case JoinScrimmageStep.ScrimmageJoined:
      return (
        <JoinScrimmageStepC
          loading={loadingScrimmageRequest}
          onClose={onClose}
          open={open}
          opposingTeamName={opposingTeamName}
          requestingTeamName={requestingTeamName}
          scrimmageRequest={scrimmageRequest}
        />
      )

    case JoinScrimmageStep.RecommendedScrimmages:
      return (
        <JoinScrimmageStepB
          loading={loadingScrimmageRequest}
          myAvailableTeams={myAvailableTeams}
          onClose={onClose}
          onSuccess={onJoinRecommendedSuccess}
          open={open}
          resetError={(): void => {
            setJoinRecommendedError('')
          }}
          schoolCompetitionGroup={schoolCompetitionGroup}
          schoolId={schoolId}
          scrimmageRequest={scrimmageRequest}
          spotModalData={spotModalData}
        />
      )

    case JoinScrimmageStep.Initialize:
    default:
      return (
        <JoinScrimmageStepA
          esportName={esportName ?? 'Unknown'}
          formattedDatetime={formattedDatetime}
          loading={loadingScrimmageRequest}
          myAvailableTeams={myAvailableTeams}
          onClose={onClose}
          open={open}
          schoolId={schoolId}
          scrimmageId={id}
          scrimmageRequest={scrimmageRequest}
          setSuccessStep={setSuccessStep}
          toggleRefresh={toggleRefresh}
        />
      )
  }
}

interface DialogProps {
  schoolId: string
}

export const JoinScrimmageDialog: React.FC<DialogProps> = ({ schoolId }) => {
  const [step, setStep] = React.useState<JoinScrimmageStep>(
    JoinScrimmageStep.Initialize
  )
  const { isRefreshed, toggleRefresh } =
    React.useContext(ScrimmagePageRefreshContext) ?? {}
  const [joinRecommendedError, setJoinRecommendedError] = React.useState<
    string
  >('')

  // join scrimmage id query param & dialog state
  const [joinParam, setJoinParam] = useQueryParam(Param.Join)
  const open = !!joinParam
  const onClose = (): void => {
    setJoinParam('') // this is closing the dialog
  }
  const id = joinParam || ''

  const setSuccessStep = (): void => setStep(JoinScrimmageStep.ScrimmageJoined)

  // query for the list of my available teams
  const variables = { id, schoolId }
  const { data, refetch, loading } = useScrimmageRequestQuery({
    skip: !id,
    variables,
  })

  const { data: schoolData } = useMySchoolDetailsQuery()
  const schoolCompetitionGroup =
    schoolData?.me?.school?.competitionGroup ?? null
  const { name: esportName, hasSeries } = useGeneralEsportAdapter(
    data?.scrimmageRequest?.esport?.slug ?? null
  )

  const onJoinRecommendedSuccess = async (
    scrimmageId: string
  ): Promise<void> => {
    await refetch({ id: scrimmageId })
    setSuccessStep()
  }
  const myAvailableTeams = data?.scrimmageRequest?.myAvailableTeams ?? []
  const scrimmageRequest = {
    ...data?.scrimmageRequest,
    bestOf: hasSeries
      ? data?.scrimmageRequest?.seriesBestOf
      : data?.scrimmageRequest?.bestOf,
  } as ScrimmageRequestQuery['scrimmageRequest']

  const scrimmageNotFound = !loading && !data?.scrimmageRequest
  const scrimmageFilled = !!data?.scrimmageRequest?.opposingTeam?.id
  const teamsNotAvailable = !loading && !myAvailableTeams.length

  const joinButtonDisabled =
    scrimmageNotFound || scrimmageFilled || teamsNotAvailable

  const showRecommendedScrimmages = joinButtonDisabled

  React.useEffect(() => {
    // reset the dialog step when join param is changed; covers two cases:
    // 1. join param somehow changes while the dialog is open
    // 2. we close this join dialog and then open another join dialog
    if (joinParam) setStep(JoinScrimmageStep.Initialize)
  }, [joinParam])
  React.useEffect(() => {
    if (showRecommendedScrimmages && step === JoinScrimmageStep.Initialize)
      setStep(JoinScrimmageStep.RecommendedScrimmages)
  }, [step, showRecommendedScrimmages])

  const spotModalData = getSpotModalData({
    joinRecommendedError,
    scrimmageFilled,
    scrimmageNotFound,
    teamsNotAvailable,
  })

  const renderScrimmageModals = getScrimmageModals({
    esportName,
    myAvailableTeams,
    onClose,
    scrimmageNotFound,
    scrimmageRequest,
    step,
    schoolId,
    setJoinRecommendedError,
    onJoinRecommendedSuccess,
    schoolCompetitionGroup,
    spotModalData,
    open,
    setSuccessStep,
    id,
    loadingScrimmageRequest: loading,
    toggleRefresh: () => toggleRefresh(!isRefreshed),
  })

  return <>{renderScrimmageModals}</>
}
