import React from 'react'

import { NxTextInput } from '@playvs-inc/nexus-components'

import type { InputProps } from './InputProps.type'
import { useStyles } from './Input.styles'

export const EmailInput: React.FC<InputProps> = React.forwardRef(
  ({ error, label = 'Email', helperText }, ref) => {
    useStyles()
    return (
      <NxTextInput
        ref={ref}
        data-testid="email"
        fullWidth
        helperText={helperText}
        label={label}
        name="email"
        // Setting style inline because className override base style
        style={{ height: '54px' }}
        type="email"
        variant={error ? 'error' : 'default'}
      />
    )
  }
)
