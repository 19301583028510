import React, { Fragment, useMemo } from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import {
  CompetitionGroup,
  EsportRating,
  useGetSchoolLeaguesQuery,
} from '@plvs/graphql/generated'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { isCoachAtOrganization } from '@plvs/utils'
import {
  NxTypography,
  NxButton,
  NxEsportCard,
} from '@playvs-inc/nexus-components'

import { useBreakpointXs } from '@plvs/respawn/features/layout'
import { sort, uniqBy } from 'ramda'
import { sortLeaguesByEsportName } from '@plvs/rally/features/explore/ExploreHelpers'

import { generatePath, useNavigate } from 'react-router-dom'
import { Path } from '@plvs/const'
import clsx from 'clsx'
import { useAllEsportAdapters } from '@plvs/respawn/features/esport/creator'
import { esportPlatformMap } from '@plvs/respawn/features/esport/creator/esportStaticDetails'

const MAX_NUM_ESPORTS_TO_DISPLAY = 8

const useStyles = makeStyles((theme) => ({
  viewAllButton: {
    minWidth: 'max-content',
    maxWidth: '30%',
    width: '100%',
  },
  viewAllButtonMobile: {
    width: '100%',
  },
  createTeamButton: {
    minWidth: 'max-content',
  },
  createTeamButtonMobile: {
    width: '100%',
  },
  exploreGrid: {
    display: 'grid',
    gap: '1em',
    margin: theme.spacing(3, 0),
    gridAutoRows: 'minmax(100px, auto)',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(4, 1fr)',
    },
    [theme.breakpoints.only('sm')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
}))

export const TeamCardsExploreView: React.FC = () => {
  const isMobile = useBreakpointXs()
  const theme = useTheme()
  const classes = useStyles({ isMobile })
  const navigate = useNavigate()

  const { selectedEntityId, roles } = useProfileContext()
  const isCoach = isCoachAtOrganization(roles, [selectedEntityId])

  const { data: leagueData, loading, error } = useGetSchoolLeaguesQuery({
    variables: {
      schoolIds: [selectedEntityId],
      schoolId: selectedEntityId,
    },
    skip: !selectedEntityId,
  })

  const esportEntries = useMemo((): {
    className?: string
    esport: string
    esportName: string
    onClick?: () => void
  }[] => {
    if (!leagueData) {
      return []
    }

    const schoolLeagues = sort(
      sortLeaguesByEsportName,
      uniqBy(
        (league) => league?.esport?.slug,
        leagueData.getLeaguesBySchoolId ?? []
      )
    )

    const highSchoolLeagues = schoolLeagues.filter(
      (league) =>
        league?.esport?.rating === EsportRating.General &&
        league?.competitionGroup === CompetitionGroup.HighSchool
    )

    const leaguesToDisplay = highSchoolLeagues.slice(
      0,
      MAX_NUM_ESPORTS_TO_DISPLAY
    )

    const esports = leaguesToDisplay.map((leagueEntry) => ({
      // todo MATCH-6784 fix as unknown cast
      esport: leagueEntry?.esport.slug ?? '',
      esportName: leagueEntry?.esport.name ?? '',
      onClick: (): void => {
        const url = generatePath(Path.ExploreEsportDetails, {
          competitionGroup:
            leagueEntry?.competitionGroup ?? CompetitionGroup.HighSchool,
          esportSlug: leagueEntry?.esport.slug ?? '',
        })
        navigate(url)
      },
    }))

    return esports
  }, [leagueData])

  const esportAdapters = useAllEsportAdapters()

  const navigateToCreateTeam = (): void => {
    navigate(Path.CreateTeam)
  }

  if (loading || error) {
    return <></>
  }

  return (
    <Box
      color="ColorTextBase"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      maxWidth="1042px"
      mb={4}
    >
      {/* // Header section */}
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={99999999999}
          gridGap={`${theme.spacing(2)}px`}
          mb={1}
        >
          <NxTypography variant="display3">Explore Our Leagues</NxTypography>
          <NxTypography colorToken="ColorTextAlt2" variant="subtitle1">
            {isCoach
              ? "Ready to compete? Create your school's first team!"
              : 'Excited to compete? Check out our competitions below!'}
          </NxTypography>
        </Box>
        {isCoach && (
          <Box
            display="flex"
            flexBasis="max-content"
            flexDirection="row"
            flexGrow={1}
            maxHeight={`${theme.spacing(4)}px`}
          >
            <NxButton
              className={
                isMobile
                  ? classes.createTeamButtonMobile
                  : classes.createTeamButton
              }
              label="Create A Team"
              onClick={navigateToCreateTeam}
              variant="secondary"
            />
          </Box>
        )}
      </Box>
      {/* // Exlore Area */}
      <Box
        className={clsx(
          classes.exploreGrid,
          'TeamCardsExploreView__CardContainer'
        )}
        my={2}
      >
        {esportEntries.map((es) => {
          const esportAdapter = esportAdapters.find(
            ({ slug }) => slug === es.esport
          )

          return (
            <Fragment key={es.esport}>
              <NxEsportCard
                data-cy={es.esport}
                esport={es.esport}
                esportName={es.esportName}
                gameType={esportAdapter?.gameType ?? ''}
                onClick={es.onClick}
                platforms={esportAdapter?.platforms.map(
                  (platform) => esportPlatformMap[platform] ?? platform
                )}
              />
            </Fragment>
          )
        })}
      </Box>
      {/* // end explore area */}

      <Box
        display="flex"
        flexDirection="row"
        flexGrow={1}
        justifyContent="center"
        maxHeight={`${theme.spacing(4)}px`}
      >
        <NxButton
          className={
            isMobile ? classes.viewAllButtonMobile : classes.viewAllButton
          }
          label="View All"
          onClick={(): void => navigate(Path.Explore)}
          variant="secondary"
        />
      </Box>
    </Box>
  )
}
