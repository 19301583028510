import React, { useEffect } from 'react'
import { NxCheckbox, NxTypography } from '@playvs-inc/nexus-components'
import { Race } from '@plvs/graphql/generated'
import { EthnicityValue } from './types'

interface Props {
  selected: boolean
  option: EthnicityValue
  onChange: React.Dispatch<React.SetStateAction<EthnicityValue[]>>
  values: EthnicityValue[]
}

export const EthnicityOption: React.FC<Props> = ({
  selected,
  option,
  onChange,
  values,
}) => {
  useEffect(() => {
    if (selected) {
      if (option.value === Race.PreferNotToAnswer) {
        onChange([
          {
            title: 'Prefer not to answer',
            category: '-OR-',
            value: Race.PreferNotToAnswer,
          },
        ])
      } else {
        const hasSelectedPreferNotToAnswer = values.find(
          (race) => race.value === Race.PreferNotToAnswer
        )

        if (hasSelectedPreferNotToAnswer) {
          const removed = values.filter(
            (race) => race.value !== Race.PreferNotToAnswer
          )

          onChange(removed)
        }
      }
    }
  }, [option, selected])

  return (
    <>
      <NxCheckbox checked={selected} style={{ marginRight: 8 }} />
      <NxTypography variant="body3">{option.title}</NxTypography>
    </>
  )
}
