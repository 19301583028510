import { yupEmailRequired, passwordRequirementsValidation } from '@plvs/utils'
import * as yup from 'yup'

export function getSchema(
  shouldRenderDOBField: boolean,
  shouldRenderUsernameField: boolean,
  isParentSignup: boolean
): yup.ObjectSchema<Record<string, unknown>> {
  return yup.object().shape({
    dateOfBirth: shouldRenderDOBField
      ? yup
          .string()
          .required()
          .label('Date of Birth')
          .matches(
            /^\d{2}\/\d{2}\/\d{4}$/,
            'Date must be in format MM/DD/YYYY.'
          )
      : yup.string(),
    email: yupEmailRequired,
    password: passwordRequirementsValidation,
    username:
      shouldRenderUsernameField && !isParentSignup
        ? yup
            .string()
            .matches(
              /^[a-zA-Z0-9\-_]+$/,
              'Username may only contain A-Z, 0-9, _, and -.'
            )
            .required()
        : yup.string(),
  })
}

export function getFriendlyError(
  error: string | undefined
): string | undefined {
  if (error?.includes('user_username_unique')) {
    return 'An account with that display name already exists'
  }

  if (error?.includes('email')) {
    return 'An account with that email already exists'
  }

  if (error?.includes('contain the word "playvs"')) {
    return 'Password cannot contain the word "playvs"'
  }

  if (error?.includes('contain the word "password"')) {
    return 'Password cannot contain the word "password"'
  }

  if (error) {
    return 'An error occurred. Please try again.'
  }

  return undefined
}
