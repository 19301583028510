import { emotesMap } from '@playvs-inc/nexus-components'
import confetti from 'canvas-confetti'

interface ChatCommand {
  command: string
  callback(...props: unknown[]): string
}

interface CommandOptions {
  execute?: boolean
  replace?: boolean
}

export const fireConfetti = (
  message: string,
  options: CommandOptions
): string => {
  const myCanvas = document.createElement('canvas')
  document.body.appendChild(myCanvas)

  if (options.execute) {
    confetti({
      particleCount: 100,
      spread: 100,
    })

    setTimeout(() => {
      confetti.reset()
    }, 2000)
  }

  let newMsg = message
  if (options.replace) {
    newMsg = message.replace('/confetti', `:${emotesMap.fbcelebrate.name}:`)
  }

  return newMsg
}

export const ChatCommands: Record<string, ChatCommand> = {
  Confetti: {
    command: '/confetti',
    callback: fireConfetti,
  },
  EmoteConfetti: {
    command: ':fbcelebrate:',
    callback: fireConfetti,
  },
}

const COMMAND_PATTERN = new RegExp(
  `^(${Object.values(ChatCommands)
    .map(({ command }) => command)
    .join('|')})$`
)

export const executeCommands = (
  message: string,
  options: CommandOptions = { execute: true, replace: true }
): string => {
  const matches = message.trim().match(COMMAND_PATTERN)
  const commandEntries = Object.values(ChatCommands)
  let newMsg = message

  matches?.forEach((cmd) => {
    const entry = commandEntries.find(({ command }) => command === cmd)
    if (entry) {
      const { callback } = entry
      newMsg = callback(newMsg, options)
    }
  })

  return newMsg
}
