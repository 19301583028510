import React, { useContext, useEffect } from 'react'

import { Polling } from '@plvs/const'
import {
  useGetMatchAndGameStatsQuery,
  GetMatchAndGameStatsQuery,
  GameStatus,
} from '@plvs/graphql'

import { isMatchActive } from '@plvs/utils'

export interface UseMatchStatsReturn {
  isLoading: boolean
  hasStats: boolean
  statsData?: GetMatchAndGameStatsQuery
  hasStatsPerGame: Record<string, boolean>
}

const context = React.createContext<UseMatchStatsReturn>({
  isLoading: false,
  hasStats: false,
  statsData: undefined,
  hasStatsPerGame: {},
})
const { Provider } = context

interface MatchStatsProviderProps {
  matchId: string
  hasApiIntegration: boolean
}

export const useMatchStatsProvider = ({
  matchId,
  hasApiIntegration,
}: MatchStatsProviderProps): UseMatchStatsReturn => {
  const {
    data: statsData,
    loading,
    startPolling,
    stopPolling,
  } = useGetMatchAndGameStatsQuery({
    variables: { matchId },
    skip: !matchId || !hasApiIntegration,
  })

  useEffect(() => {
    if (!hasApiIntegration) {
      return
    }

    const matchIsActive = statsData?.match?.status
      ? isMatchActive(statsData?.match?.status)
      : false
    if (matchIsActive) {
      startPolling(Polling.Slow)
    }
    // eslint-disable-next-line consistent-return
    return (): void => stopPolling()
  }, [statsData?.match?.status])

  const hasMatchStats = Boolean(
    statsData?.match?.teamMatchStats?.find(
      (matchStats) =>
        matchStats.stats &&
        Object.values(matchStats.stats).find((val) => Boolean(val))
    )
  )

  const hasStatsPerGame =
    statsData?.match?.games?.reduce((acc, game) => {
      acc[game.id] =
        game.status !== GameStatus.Cancelled &&
        Boolean(
          game.teamGameStats?.find(
            (teamStats) =>
              teamStats.stats &&
              Object.values(teamStats.stats).find((val) => Boolean(val))
          )
        )
      return acc
    }, {} as Record<string, boolean>) || {}

  const hasStats =
    hasMatchStats || Boolean(Object.values(hasStatsPerGame).find((val) => val))

  return {
    statsData,
    isLoading: loading,
    hasStats,
    hasStatsPerGame,
  }
}

export const MatchStatsProvider: React.FC<MatchStatsProviderProps> = ({
  children,
  matchId,
  hasApiIntegration,
}) => {
  const values = useMatchStatsProvider({
    matchId,
    hasApiIntegration,
  })
  return <Provider value={values}>{children}</Provider>
}

export const useMatchStatsContext = (): UseMatchStatsReturn => {
  return useContext(context)
}
