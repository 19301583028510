import { NodeEnv } from './types'

// ======================
// isBrowser / IS_BROWSER
// A reliable and safe check to see if we're executing code in the browser (or
//  on the server).

export const isBrowser = (): boolean => {
  try {
    return typeof window !== 'undefined'
  } catch (_) {
    return false
  }
}

export const IS_BROWSER = isBrowser()

// =============
// createSafeEnv
// Two things: 1) a way to load env vars from process.env (the server) or
//  window.env (the browser) depending on where our code is running, and 2) a
//  way to create a fallback set of env vars for browser environments that
//  don't have window.env defined, like storybook.

let isStorybook = false
export const createSafeEnv = <T>(fallback: T): T => {
  // Storybook will only run in the browser and won't define `window.env` etc.
  if (IS_BROWSER && !window.env) {
    // We remember isStorybook because this conditional check won't trip the
    //  second time...
    isStorybook = true
  }
  // We merge any fallbacks into the existing window.env. Again, this is for
  //  storybook.
  if (isStorybook) window.env = { ...window.env, ...fallback }

  // Putting process.env behind a conditional makes it a runtime variable
  //  instead of a build time, i.e. an injected during compililation, variable.
  const value = (IS_BROWSER ? window.env : process.env) as unknown
  return value as T
}

// =========
// getApiEnv
// A naive method of determining the environment of the API that the
//  application is consuming.
export const getApiEnv = (apiUrl: string): NodeEnv => {
  if (apiUrl.includes('demo')) return 'demo'
  if (apiUrl.includes('localhost')) return 'development'
  if (apiUrl.includes('staging')) return 'staging'
  return 'production'
}

export const getApmRumSampleRate = (
  envSampleRate: string | undefined
): number => {
  const APM_RUM_SAMPLE_RATE_DEFAULT = 0.3

  const sampleRate = Number(envSampleRate)

  return !Number.isNaN(sampleRate) && sampleRate > 0 && sampleRate <= 1
    ? sampleRate
    : APM_RUM_SAMPLE_RATE_DEFAULT
}
