import React from 'react'
import { Route } from 'react-router-dom'

import { UserRoleName } from '@plvs/graphql'
import { RequiredRoleForMatchWrapper } from '@plvs/respawn/features/match-lobby/RequiredRoleForMatchRoute'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { MatchDisputeViewPage } from './MatchDisputeViewPage'
import { MatchLobbyPage } from './MatchLobbyPage'
import { MatchDisputePage } from './MatchDisputePage'

export const MatchRoute: React.FC = () => {
  return (
    <ApmRoutes>
      <Route element={<MatchLobbyPage />} path=":matchId/*" />

      <Route
        element={
          <RequiredRoleForMatchWrapper requireAnyOf={[UserRoleName.Coach]}>
            <MatchDisputePage />
          </RequiredRoleForMatchWrapper>
        }
        path=":matchId/dispute/*"
      />

      <Route
        element={
          <RequiredRoleForMatchWrapper requireAnyOf={[UserRoleName.Coach]}>
            <MatchDisputeViewPage />
          </RequiredRoleForMatchWrapper>
        }
        path=":matchId/view-dispute/*"
      />
    </ApmRoutes>
  )
}
