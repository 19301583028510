import React, { useState } from 'react'
import tz from 'dayjs/plugin/timezone'
import dayjs from '@plvs/respawn/init/dayjs'
import { makeStyles } from '@material-ui/core'
import { Provider, SchoolProvider } from '@plvs/graphql'
import { isString } from 'ramda-adjunct'
import { MOMENT_SHORT_DATE_AND_TIME } from '@plvs/const'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import {
  NxButton,
  NxTooltip,
  NxTypography,
  NxModal,
} from '@playvs-inc/nexus-components'
import { useNavigate } from 'react-router-dom'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { AccountProviderLogo } from '@plvs/respawn/features/account/connections/AccountProviderLogo'
import { canDisconnect, getConnectTooltip, isConnectDisabled } from './utils'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  connectionTextClass: {
    color: theme.palette.ColorTextSuccess,
    marginTop: theme.spacing(0.5),
  },
  errorTextClass: {
    color: theme.palette.ColorTextError,
    marginTop: theme.spacing(0.5),
  },
  typographyClass: { marginBottom: theme.spacing(0.5) },
  connectButtonLabel: {
    letterSpacing: '0.05em',
    textTransform: 'capitalize',
    width: 114,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  disconnectButtonLabel: {
    color: theme.palette.ColorTextAlt,
    letterSpacing: '0.05em',
    textTransform: 'capitalize',
    width: 114,
  },
}))

export interface LogoMap {
  [key: string]: string | React.ReactElement
}
interface ConnectionCardProps {
  providerName?: string
  lastUpdated?: string
  buttonMessage?: string
  disabled?: boolean
  disabledText?: string
  isConnected?: boolean
  onConnectClick?(): void
  onUpdateClick?(): void
  subtitle?: string | React.ReactNode
  title?: string
  EditComponent?: React.ReactNode
  errorText?: string
  connectedText?: string | React.ReactNode
  note?: React.ReactNode
  checkpoint?: boolean
  userId?: string
  isParent?: boolean
}

const ConnectionCard = ({
  providerName = '',
  lastUpdated,
  buttonMessage,
  disabled,
  disabledText,
  EditComponent,
  isConnected,
  onConnectClick,
  onUpdateClick,
  subtitle,
  title,
  errorText,
  connectedText,
  note,
  checkpoint = false,
  userId,
  isParent = false,
}: ConnectionCardProps): React.ReactElement => {
  const {
    connectionTextClass,
    errorTextClass,
    typographyClass,
    ...styles
  } = useStyles()
  const navigate = useNavigate()
  const isMobile = useBreakpointXs()
  const { userId: myUserId } = useUserIdentityFn()

  const [open, setOpen] = useState<boolean>(false)
  const closeModal = (): void => setOpen(false)
  const openModal = (): void => setOpen(true)

  const isRiotProvider = providerName === Provider.Riot

  const canDisconnectAccount = canDisconnect({
    providerName: providerName as Provider,
    checkpoint,
    isParent,
  })

  const onClickEvent = (): void => {
    if (open) {
      closeModal()
    }
    return !disabled && onConnectClick ? onConnectClick() : undefined
  }

  const UpdateButton = (
    <NxButton
      className={styles.disconnectButtonLabel}
      disabled={disabled}
      label={buttonMessage || 'Disconnect'}
      onClick={onUpdateClick}
      variant="secondary"
    />
  )

  const ConnectButton = (
    <NxButton
      className={styles.connectButtonLabel}
      disabled={isConnectDisabled({
        checkpoint,
        isParent,
        disabled,
        isOwnUser: userId === myUserId,
      })}
      label="Connect"
      onClick={canDisconnectAccount ? onClickEvent : openModal}
      variant="primary"
    />
  )

  return (
    <Box mb={3} mt={3}>
      <Box
        alignItems="center"
        display={['block', 'flex']}
        justifyContent="space-between"
      >
        <Box alignItems="center" display="flex">
          <AccountProviderLogo
            provider={providerName as Provider | SchoolProvider}
          />
          <Box display="flex" flexDirection="column" ml={2}>
            <NxTypography
              className={typographyClass}
              variant={title?.includes('School') ? 'body2' : 'h4'}
            >
              {title}
            </NxTypography>
            <NxTypography variant="body1">{subtitle}</NxTypography>
            {note && <NxTypography variant="body1">{note}</NxTypography>}
            {disabled && (
              <NxTypography variant="body1">
                {isString(disabledText)
                  ? disabledText
                  : 'Updating your account is currently disabled'}
              </NxTypography>
            )}
            {isConnected && (
              <>
                <NxTypography className={connectionTextClass} variant="body2">
                  {connectedText || 'Account Connected'}
                </NxTypography>
                {lastUpdated && checkpoint && (
                  <NxTypography className={connectionTextClass} variant="body2">
                    {dayjs(lastUpdated).format(MOMENT_SHORT_DATE_AND_TIME)}
                  </NxTypography>
                )}
              </>
            )}
            {errorText && (
              <NxTypography className={errorTextClass} variant="body2">
                {errorText}
              </NxTypography>
            )}
            {!isMobile && EditComponent && <Box mt={0.5}>{EditComponent}</Box>}
          </Box>
        </Box>
        {isMobile && EditComponent && <Box mt={3}>{EditComponent}</Box>}
        <Box display="flex" justifyContent="flex-end" ml={[0, 3]} mt={[3, 0]}>
          {checkpoint && isRiotProvider ? (
            <Box mr={1}>
              <NxButton
                label="Unlink"
                onClick={(): void => {
                  navigate('/riot-unlink', { state: { userId } })
                }}
                variant="secondary"
              />
            </Box>
          ) : null}
          {isConnected ? (
            <>
              {canDisconnectAccount ? (
                UpdateButton
              ) : (
                <NxTooltip
                  arrow
                  placement="bottom"
                  title="Contact support to change this to a different account."
                >
                  <div>
                    <NxButton
                      className={styles.disconnectButtonLabel}
                      disabled
                      label={buttonMessage || 'Disconnect'}
                      variant="secondary"
                    />
                  </div>
                </NxTooltip>
              )}
            </>
          ) : (
            <>
              {isParent || checkpoint ? (
                <NxTooltip
                  arrow
                  placement="bottom"
                  title={getConnectTooltip(isParent, checkpoint)}
                >
                  <div>{ConnectButton}</div>
                </NxTooltip>
              ) : (
                ConnectButton
              )}
            </>
          )}
        </Box>
      </Box>
      <NxModal
        actions={
          <>
            <NxButton label="cancel" onClick={closeModal} variant="text" />
            <NxButton
              label="Connect Account"
              onClick={onClickEvent}
              variant="primary"
            />
          </>
        }
        onClose={closeModal}
        open={open}
        showTopRightClose
        subtitle="Once you connect a game account, you will not be able to change it without contacting PlayVS Support. Please be sure to connect the correct account."
        title="Game Account Connection"
      />
    </Box>
  )
}

export default ConnectionCard
