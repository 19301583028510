import { Box, FormLabel, Button, useTheme } from '@material-ui/core'
import React from 'react'
import * as analytics from '@plvs/respawn/features/analytics'
import { UserRoleName, UserRole, Maybe, CompetitionGroup } from '@plvs/graphql'
import { userRoleNamesToUserType } from '@plvs/utils'
import { Colors } from '@plvs/rally/themes'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { NxTypography } from '@playvs-inc/nexus-components'
import { OnboardReferByLinkForm } from './OnboardReferByLinkForm'
import { OnboardReferByEmailForm } from './OnboardReferByEmailForm'
import { StudentOrFaculty } from './ReferUser'

const getTypographyText = (
  userRoleName: StudentOrFaculty,
  isFaculty: boolean
): string => {
  if (userRoleName === UserRoleName.Student) {
    return isFaculty
      ? 'Students will receive a link to sign up as a player.'
      : 'We‘ll send an email to your friends with your link.'
  }
  return isFaculty
    ? 'We‘ll send an invite to your colleagues with your link.'
    : 'Your nominee will receive an email from us.'
}

interface Props {
  refer: StudentOrFaculty
  hasIncentive: boolean
  isFaculty: boolean
  myUserRoles: Pick<UserRole, 'roleName'>[]
  referralLink?: string
  source?: string
  competitionGroup?: Maybe<CompetitionGroup>
}

export const OnboardReferUser: React.FC<Props> = ({
  refer,
  hasIncentive,
  isFaculty,
  myUserRoles,
  referralLink,
  source,
  competitionGroup,
}) => {
  const { orgId } = useUserIdentityFn()
  const theme = useTheme()

  return (
    <>
      <Box
        borderRadius="12px"
        px={5}
        py={6}
        style={{
          textAlign: 'center',
          backgroundColor: Colors.White,
          boxShadow:
            '0px 2px 8px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)',
        }}
        width="100%"
      >
        <FormLabel
          style={{ color: theme.palette.ColorTextBase, fontWeight: 600 }}
        >
          Share your link
        </FormLabel>
        <OnboardReferByLinkForm
          link={referralLink}
          onClickCopy={(value): void =>
            analytics.copyInviteLink({
              inviteeUserType: userRoleNamesToUserType([refer]),
              inviteLink: value,
              myUserRoles,
            })
          }
        />
        <Box mt={3}>
          <div style={{ textAlign: 'center' }}>
            <FormLabel
              style={{ color: theme.palette.ColorTextBase, fontWeight: 600 }}
            >
              Invite via email
            </FormLabel>
          </div>
          <NxTypography colorToken="ColorTextAlt2" variant="body1">
            {getTypographyText(refer, isFaculty)}
          </NxTypography>
          <OnboardReferByEmailForm
            competitionGroup={competitionGroup}
            myUserRoles={myUserRoles}
            recipientUserRoleName={refer}
            schoolId={orgId ?? ''}
            source={source}
          />
        </Box>
      </Box>
      {hasIncentive ? (
        <Box mt={3} textAlign="center">
          <Button
            // TODO: we need a better way to dynmically swap this
            href="https://help.playvs.com/en/articles/4919211"
            target="_blank"
          >
            View Terms & Conditions
          </Button>
        </Box>
      ) : null}
    </>
  )
}
