import React, { useState } from 'react'
import { Box, useTheme, makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import {
  NxButton,
  NxFirebobMessage,
  NxRadioButtonGroup,
  NxSelect,
  NxSelectOption,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import {
  useGetAvailableSpectatorsByTeamIdQuery,
  useGetEpicPlatformSelectionOptionsByUserIdsQuery,
  useSubmitGameAssistantStepSelectionsMutation,
} from '@plvs/graphql/generated'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { getAccountProviderUsername } from '@plvs/utils'
import { esportSlugToProvider } from '@plvs/rally/features/account/connections/utils'
import { EsportSlug } from '@plvs/graphql/types'
import { logger } from '@plvs/rally/logging'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { MatchAssistantFooter } from '../../../MatchAssistantFooter'
import { useStyles as maUseStyles } from '../../../MatchAssistant.styles'
import { useMatchAssistantContext } from '../../../useMatchAssistant'

const useStyles = makeStyles((theme) => ({
  hideSelect: {
    visibility: 'hidden',
    maxHeight: '16px',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(3),
  },
  userClusterOverrides: ({
    hasSelectedSpectator,
  }: {
    hasSelectedSpectator: boolean
  }): CreateCSSProperties => ({
    overflow: hasSelectedSpectator ? 'hidden' : 'visible',
  }),
}))

export const Spectator: React.FC = () => {
  const theme = useTheme()
  const styles = maUseStyles()

  // Potentially design will change the designs to a checkbox with one CTA, that way we don't have to block a loading state for the button not clicked.
  const [skipLoading, setSkipLoading] = useState(false)
  const [confirmSpectatorLoading, setConfirmSpectatorLoading] = useState(false)
  const { myTeam, currentStep, esportSlug } = useMatchAssistantContext()

  const {
    match: { canParticipate },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const myTeamId = myTeam?.id ?? ''
  const [selectedSpectatorId, setSelectedSpectatorId] = useState<string>('')
  const classes = useStyles({
    hasSelectedSpectator: Boolean(selectedSpectatorId),
  })
  const [selectedUserPlatform, setSelectedUserPlatform] = useState<{
    selectedUserId: string
    selectedPlatform: string
  }>({ selectedPlatform: '', selectedUserId: '' })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [hasSubmitted, setHasSubmitted] = useState(
    currentStep?.step?.submitted ?? false
  )

  const { data, loading } = useGetAvailableSpectatorsByTeamIdQuery({
    variables: {
      teamId: myTeamId,
    },
    skip: !myTeamId,
  })

  const availableSpectators = (
    data?.availableSpectatorsByTeamId?.spectators ?? []
  ).map((spectator) => ({
    ...spectator,
    publisherAccountName: getAccountProviderUsername(
      spectator ?? null,
      esportSlugToProvider[esportSlug as EsportSlug]
    ),
  }))
  const hasAvailableSpectators = Boolean(availableSpectators.length)

  const {
    data: userPlatformOptions,
    loading: epicPlatformOptionsLoading,
  } = useGetEpicPlatformSelectionOptionsByUserIdsQuery({
    variables: {
      userIds: availableSpectators?.map(({ id }) => id) ?? [],
    },
    skip: !hasAvailableSpectators,
  })

  const [
    submitGameAssistantStepSelections,
    { loading: updateLoading },
  ] = useSubmitGameAssistantStepSelectionsMutation()

  const isWaitingForOpponent = hasSubmitted
  const isSpectator = !canParticipate || isWaitingForOpponent

  const renderNoAvailableSpectators = (
    <>
      <NxSpot
        domain="coach"
        height={100}
        size="large"
        variant="secondary"
        width={100}
      />
      <Box pb={1} pt={3}>
        <NxTypography variant="h1">No Coach Assigned</NxTypography>
      </Box>
      <NxTypography variant="body1">
        To add a coach spectator, they must be on the team with a connected Epic
        account.
      </NxTypography>
    </>
  )

  const handleChangeConfirmPlatform = (
    userId: string,
    evnt: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const { value } = evnt.target
    const newSelectedUserPlatform = {
      selectedUserId: userId,
      selectedPlatform: value as string,
    }
    setSelectedUserPlatform({ ...newSelectedUserPlatform })
  }

  const handleChangeSelectSpectator = (
    evnt: React.ChangeEvent<{ name?: string; value: unknown }>
  ): void => {
    const { value } = evnt.target

    setSelectedSpectatorId(value as string)
  }

  const handleNext = async (skip = false): Promise<void> => {
    setIsSubmitting(true)

    try {
      await submitGameAssistantStepSelections({
        variables: {
          input: {
            stepId: currentStep?.step?.id ?? '',
            selectionOptions: skip
              ? undefined
              : [
                  {
                    userId: selectedSpectatorId,
                    selectionOptionId: selectedUserPlatform.selectedPlatform,
                  },
                ],
          },
        },
      })

      setHasSubmitted(true)
      setIsSubmitting(false)
    } catch (err) {
      logger.error(err)
    }
  }

  const blockSubmittingSpectator =
    !selectedSpectatorId ||
    selectedSpectatorId !== selectedUserPlatform.selectedUserId ||
    !selectedUserPlatform.selectedPlatform

  return (
    <>
      <WaitTillLoaded
        loading={loading || epicPlatformOptionsLoading}
        showSpinnerWhileLoading
      >
        {hasAvailableSpectators ? (
          <Box alignItems="center" display="flex" flexDirection="column">
            <Box pb={1} textAlign="center">
              <NxTypography variant="h1">Select a Spectator</NxTypography>
              <Box pt={1}>
                <NxTypography variant="body1">
                  Only coaches with connected Epic accounts will show.
                </NxTypography>
              </Box>
            </Box>
            <Box alignItems="center" display="flex" mt={3}>
              <NxRadioButtonGroup
                onChange={handleChangeSelectSpectator}
                radioOptions={availableSpectators?.map(
                  ({ id, avatarUrl, publisherAccountName, name }) => {
                    const platformOptions = userPlatformOptions?.epicPlatformSelectionOptionsByUserIds?.selectionOptions?.find(
                      ({ userId }) => userId === id
                    )?.selectionOptions
                    const renderSelect = id === selectedSpectatorId
                    const shouldShowPlatformOptions =
                      platformOptions && platformOptions.length
                    const value =
                      selectedSpectatorId ===
                      selectedUserPlatform.selectedUserId
                        ? selectedUserPlatform.selectedPlatform
                        : ''

                    return {
                      label: (
                        <Box className={classes.grid}>
                          <NxUserCluster
                            avatarHashId={id}
                            avatarUrl={avatarUrl ?? ''}
                            className={classes.userClusterOverrides}
                            subHeader="Coach"
                            subtitles={[{ title: publisherAccountName }]}
                            title={name ?? ''}
                          />

                          <Box
                            className={
                              renderSelect ? undefined : classes.hideSelect
                            }
                          >
                            {shouldShowPlatformOptions ? (
                              <NxSelect
                                key={id}
                                disabled={isWaitingForOpponent || isSubmitting}
                                fullWidth
                                id={id}
                                label="Confirm Platform"
                                name={id}
                                onChange={(evnt): void =>
                                  handleChangeConfirmPlatform(id, evnt)
                                }
                                value={value}
                              >
                                <NxSelectOption value="">
                                  Choose a platform
                                </NxSelectOption>
                                {platformOptions?.map((selectionOption) => {
                                  const {
                                    displayName,
                                    id: platformId,
                                  } = selectionOption

                                  return (
                                    <NxSelectOption
                                      key={platformId}
                                      value={platformId}
                                    >
                                      {displayName}
                                    </NxSelectOption>
                                  )
                                })}
                              </NxSelect>
                            ) : (
                              <NxTypography
                                colorToken="ColorTextError"
                                variant="body1"
                              >
                                This user must connect their Epic account from
                                their own Account Settings Page.
                              </NxTypography>
                            )}
                          </Box>
                        </Box>
                      ),
                      value: id,
                    }
                  }
                )}
              />
            </Box>
          </Box>
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            textAlign="center"
          >
            {renderNoAvailableSpectators}
          </Box>
        )}
      </WaitTillLoaded>
      <MatchAssistantFooter>
        <NxFirebobMessage message="Only 1 spectator per team is allowed." />
        {isWaitingForOpponent && (
          <NxTypography variant="body1">Waiting on opponent</NxTypography>
        )}
        <Box
          className={styles.ctaContainer}
          display="flex"
          gridGap={theme.spacing(1)}
          width="100%"
        >
          <NxButton
            className={styles.cta}
            disabled={isSpectator || updateLoading}
            fullWidth
            label="No Spectator, Skip"
            loading={(isWaitingForOpponent || updateLoading) && skipLoading}
            onClick={(): void => {
              setSkipLoading(true)
              const skipSpectatorSelections = true
              handleNext(skipSpectatorSelections)
            }}
            variant="secondary"
          />
          <NxButton
            className={styles.cta}
            disabled={isSpectator || blockSubmittingSpectator || updateLoading}
            fullWidth
            label="Confirm Spectator"
            loading={
              (isWaitingForOpponent || updateLoading) && confirmSpectatorLoading
            }
            onClick={(): void => {
              setConfirmSpectatorLoading(true)
              handleNext()
            }}
            variant="primary"
          />
        </Box>
      </MatchAssistantFooter>
    </>
  )
}
