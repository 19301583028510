import React from 'react'
import {
  OverlayStep,
  OverlayStepper,
} from '@plvs/rally/components/overlay/OverlayStepper'
import { useUserAttrsContext } from '@plvs/rally/containers/app'
import {
  EnrollmentLoadingSpinner,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'

export interface EnrollmentLoadingModalProps {
  isModalOpen: boolean
}
export const EnrollmentLoadingModal: React.FC<EnrollmentLoadingModalProps> = ({
  isModalOpen,
}) => {
  const { attributes } = useUserAttrsContext()
  const isParticipatingInMetaseason = attributes?.isParticipatingInMetaseason
  return (
    <OverlayStepper
      hideNextButton
      hideNumberOfSteps
      onClose={(): void => {}}
      open={isModalOpen}
    >
      {[
        <OverlayStep
          key="loading"
          icon={
            <EnrollmentLoadingSpinner
              spot={
                <NxSpot
                  domain="enrollmentLoader"
                  height={200}
                  size="large"
                  variant="primary"
                  width={200}
                />
              }
            />
          }
          title={
            isParticipatingInMetaseason
              ? 'We are updating your enrollment!'
              : 'We are enrolling your program'
          }
        >
          <NxTypography variant="body1">
            Please do not refresh the browser. We will automatically redirect
            you upon success.
          </NxTypography>
        </OverlayStep>,
      ]}
    </OverlayStepper>
  )
}
