/**
 * This component is the final step for most flows and will be doing the
 * bulk of the mutations for finalizing the account.
 */

import { ApolloError } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers'
import { makeStyles } from '@material-ui/core'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import {
  SchoolType,
  UserRoleName,
  useGetMyAccountDetailsQuery,
} from '@plvs/graphql'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import {
  assert,
  cleanGraphQLError,
  isValidPhoneNumber,
  yupEmail,
  yupPhoneNumberRequired,
} from '@plvs/utils'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'
import {
  SetFormErrorCallback,
  useOnboardAdditonalContactController,
} from './OnboardAdditionalContact.controller'
import { Checked, FacultyDetailsInput } from './components/types'
import { OnboardStudentEmailConfirmation } from './components/OnboardStudentEmailConfirmation'
import { OnboardFacultyEmailConfirmation } from './components/OnboardFacultyEmailConfirmation'
import { getSubtitle } from './onboardAdditionalContactUtils'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
}))

const StudentDetails = yup.object().shape({
  otherEmail: yupEmail,
})

interface CustomError {
  message: string
}

export const OnboardAdditionalContact: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const { data: onboardContext, assign } = useOnboardingContext()
  const flags = useFlags()
  const { personalEmailOptional } = flags

  // State
  const [selected, setSelected] = React.useState<Checked | null>(null)
  const [changes, setChanges] = useState<boolean>(false)
  const [hasFilledRequiredFields, setHasFilledRequiredFields] = useState<
    boolean
  >(false)

  // Queries
  const {
    loading: loggedInUserDataLoading,
    data: loggedInUserData,
  } = useGetMyAccountDetailsQuery()

  // https://playvs.atlassian.net/browse/GP-3545 Disabling until email is reworked
  // const [referCoachMutation] = useReferCoachEmailMutation()

  // Computed Values
  const roleStatuses = loggedInUserData?.me?.schoolRoleStatus
  const isStudent =
    onboardContext.role === 'student' ||
    roleStatuses?.some(
      (roleStatus) => roleStatus?.role?.roleName === UserRoleName.Student
    )

  const showNASEFUx = onboardContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const FacultyDetails = yup.object().shape({
    ...(!personalEmailOptional && { otherEmail: yupEmail }),
    phone: yupPhoneNumberRequired.test({
      name: 'phone',
      message: 'Please provide a valid phone number',
      /* istanbul ignore next */
      test: isValidPhoneNumber,
    }),
  })
  const UserAccountDetails = isStudent ? StudentDetails : FacultyDetails

  const isPersonal = selected === Checked.Personal
  const isWork = selected === Checked.Work
  const isSchool = selected === Checked.School

  const [error, setError] = useState<ApolloError | CustomError | null>(null)
  type UserAccountDetailsInput = FacultyDetailsInput

  const {
    errors,
    handleSubmit,
    register,
    formState,
    getValues,
    setError: setFormError,
  } = useForm<UserAccountDetailsInput>({
    resolver: yupResolver<UserAccountDetailsInput>(UserAccountDetails),
    mode: 'onBlur',
  })

  const controller = useOnboardAdditonalContactController(
    setFormError as SetFormErrorCallback
  )

  // Side Effects

  // Guard user from entering this page again by forwarding to the next step
  // if the info is already submitted.
  useEffect(() => {
    if (onboardContext.workEmail) {
      // this checks if it is a falculty that completed verification form.
      navigate(Path.Dashboard)
    } else if (onboardContext.schoolEmail) {
      // Checks if we are a student that completed personal/school email step
      if (onboardContext.schoolHasCoach) {
        navigate(
          `${Path.SpawnPoint}/faculty/student-invite-player${showNASEFUx}`
        )
      } else {
        navigate(
          `${Path.SpawnPoint}/faculty/student-invite-coach${showNASEFUx}`
        )
      }
    }
  }, [])

  const handleChange = (): void => {
    setChanges(true)

    const values = getValues()
    let filledReqFields = false
    if (onboardContext.role === UserRoleName.Student) {
      filledReqFields = !!values.otherEmail
    } else {
      filledReqFields = personalEmailOptional
        ? !!values.phone
        : !!values.phone && !!values.otherEmail
    }

    setHasFilledRequiredFields(filledReqFields)
  }

  const initialEmail = loggedInUserData?.me?.emails?.[0]?.email

  const submitUserAccountDetails = handleSubmit(
    async (input: FacultyDetailsInput): Promise<void> => {
      const email = initialEmail
      assert(email)

      const inputPhone = input?.phone

      try {
        if (!isStudent && personalEmailOptional) {
          assign({
            workEmail: email,
            phone: inputPhone,
          })

          await controller.onboardFaculty()

          navigate(
            `${Path.SpawnPoint}/faculty/scholastic-verification${showNASEFUx}`
          )

          // Note: we are setting onboarding complete after we push so that
          // the SideEffect in OnboardV2.tsx does not trigger until we are
          // navigated off this page.
          setImmediate(assign, { onboardingComplete: true })
          return
        }

        if (!input.otherEmail) {
          enqueueSnackbar(`Email is required.`)
          return
        }

        const otherEmail = input.otherEmail.trim()
        yupEmail.validateSync(otherEmail)

        if (otherEmail.toLowerCase() === email.toLowerCase()) {
          throw new Error(
            'Your personal email and school email cannot be the same. Please enter a unique email.'
          )
        }

        if ((!isStudent && !inputPhone) || !otherEmail) {
          throw new Error('Please enter all required information.')
        }

        if (!selected) {
          throw new Error('Please select if your email is work or personal.')
        }

        const personalEmail = isPersonal ? email : otherEmail
        const schoolOrWorkEmail = isPersonal ? otherEmail : email

        assert(personalEmail)
        assert(schoolOrWorkEmail)

        if (!isStudent) {
          // Faculty flow
          assign({
            personalEmail,
            workEmail: schoolOrWorkEmail,
            phone: inputPhone,
          })

          await controller.onboardFaculty()

          navigate(
            `${Path.SpawnPoint}/faculty/scholastic-verification${showNASEFUx}`
          )

          // Note: we are setting onboarding complete after we push so that
          // the SideEffect in OnboardV2.tsx does not trigger until we are
          // navigated off this page.
          setImmediate(assign, { onboardingComplete: true })
        } else if (isStudent) {
          // Student flow
          const schoolEmail = isPersonal ? otherEmail : email

          assign({ personalEmail, schoolEmail })

          await controller.onboardStudent()

          // https://playvs.atlassian.net/browse/GP-3545 Disabling until email is reworked
          // await referCoachMutation()

          if (onboardContext.schoolType === SchoolType.College) {
            navigate(`${Path.SpawnPoint}/scholastic-verification${showNASEFUx}`)
          } else if (onboardContext.schoolHasCoach) {
            navigate(`${Path.SpawnPoint}/student-invite-player${showNASEFUx}`)
          } else {
            navigate(`${Path.SpawnPoint}/student-invite-coach${showNASEFUx}`)
          }

          // Note: we are setting onboarding complete after we push so that
          // the SideEffect in OnboardV2.tsx does not trigger until we are
          // navigated off this page.
          setImmediate(assign, { onboardingComplete: true })
        }
      } catch (e: unknown) {
        setError(e as Error)
      }
    }
  )

  // computed styles / dom props
  const isUnchanged = personalEmailOptional ? !changes : !changes || !selected

  const errorMessage =
    error && error.message ? cleanGraphQLError(error.message) : null

  const title = isStudent
    ? 'Add your school email address'
    : 'Verify Your Employment'
  const subtitle = getSubtitle({ isStudent, personalEmailOptional })

  return (
    <form
      className={classes.form}
      noValidate
      onChange={handleChange}
      onSubmit={submitUserAccountDetails}
    >
      <WaitTillLoaded loading={loggedInUserDataLoading}>
        <QuestionnaireCard
          buttonOpacity={formState.isSubmitting}
          childWidth={416}
          disableContinue={!hasFilledRequiredFields || isUnchanged}
          onContinue={submitUserAccountDetails}
          subtitle={subtitle}
          title={title}
        >
          {errorMessage && (
            <Box maxWidth={400} pb={3} width="100%">
              <Banner
                subtitle={errorMessage}
                title="Unable to update account details"
                type={BannerType.Error}
              />
            </Box>
          )}
          {isStudent ? (
            <OnboardStudentEmailConfirmation
              email={initialEmail}
              errors={errors}
              isPersonal={isPersonal}
              isSchool={isSchool}
              register={register}
              selected={selected}
              setSelected={setSelected}
            />
          ) : (
            <OnboardFacultyEmailConfirmation
              email={initialEmail}
              errors={errors}
              isPersonal={isPersonal}
              isWork={isWork}
              personalEmailOptional={personalEmailOptional}
              register={register}
              selected={selected}
              setSelected={setSelected}
            />
          )}
        </QuestionnaireCard>
      </WaitTillLoaded>
    </form>
  )
}
