/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-this-in-sfc */
import React from 'react'
import { renderToString } from 'react-dom/server'
import { Path } from '@plvs/const'

import Step from 'shepherd.js/src/types/step'
import { findMatchTourClicked } from '@plvs/respawn/features/analytics'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import dayjs from '@plvs/respawn/init/dayjs'
import { pollForItem } from './utils/shepherdUtils'
import { ShepherdSlide } from './ShepherdSlide'

export const createFindMatchTourSteps = ({ userId }): Step.StepOptions[] => {
  const now = dayjs().toISOString()

  const steps: Step.StepOptions[] = [
    {
      id: 'first-match-intro',
      text: renderToString(
        <ShepherdSlide
          spot={
            <NxSpot
              domain="flagsLight"
              height={133}
              size="large"
              variant="primary"
              width={133}
            />
          }
          subtitle="Your first match has been scheduled. Take this tour to learn how to find your matches."
          title="First Match Scheduled"
        />
      ),
      classes: 'shepherd-modal large-slide',
      cancelIcon: {
        enabled: false,
      },
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Skip',
          action(): void {
            findMatchTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 0,
              clickTarget: 'Skip Button',
            })
            return this.complete()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Take Tour',
          action(): void {
            findMatchTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 0,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      beforeShowPromise: pollForItem({ selector: '.shepherd-match-line-up' }),
    },
    {
      id: 'upcoming-matches',
      attachTo: { element: '.shepherd-match-line-up', on: 'top-end' },
      canClickTarget: false,
      scrollTo: true,
      scrollToHandler(): void {
        setTimeout(() => {
          window.scrollTo(0, 300)
        }, 2)
      },
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal dashboard-matches',
      text: renderToString(
        <ShepherdSlide
          showTopRightHighlight
          step={1}
          subtitle="Upcoming matches will appear on your dashboard here."
          totalSteps={3}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            findMatchTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 1,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
        {
          classes: 'shepherd-primary-button',
          text: 'Next',
          action(): void {
            findMatchTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 1,
              clickTarget: 'Next Button',
            })
            return this.next()
          },
        },
      ],
      beforeShowPromise: pollForItem({ selector: '.shepherd-match-line-up' }),
    },
    {
      id: 'shedule-link',
      attachTo: { element: '.schedule', on: 'right-start' },
      scrollTo: true,
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal app-link',
      text: renderToString(
        <ShepherdSlide
          leftArrowStyles={{ top: 0, left: -80 }}
          step={2}
          subtitle="You can also find your matches on the schedule page. Click here to view them."
          totalSteps={3}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            findMatchTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 2,
              clickTarget: 'Back Button',
            })

            if (
              !window.location.pathname.includes(Path.App) ||
              !window.location.pathname.includes(Path.Dashboard)
            ) {
              // Had to put second back for redirect down when landing on the schedule page
              window.history.back()
              window.history.back()
            }
            return this.back()
          },
        },
      ],
    },
    {
      id: 'open-match',
      attachTo: { element: '.open-match', on: 'left-end' },
      canClickTarget: true,
      cancelIcon: {
        enabled: true,
      },
      classes: 'shepherd-modal shepherd-open-match',
      text: renderToString(
        <ShepherdSlide
          rightArrowStyles={{
            top: -29,
            right: -70,
          }}
          step={3}
          subtitle="Click here to access your match!"
          totalSteps={3}
        />
      ),
      buttons: [
        {
          classes: 'shepherd-text-button',
          text: 'Back',
          action(): void {
            findMatchTourClicked({
              userId,
              initiatedTimestamp: now,
              timeStamp: dayjs().toISOString(),
              stepNumber: 3,
              clickTarget: 'Back Button',
            })
            return this.back()
          },
        },
      ],
      beforeShowPromise: pollForItem({ selector: '.open-match' }),
      modalOverlayOpeningPadding: 12,
    },
  ]

  return steps
}
