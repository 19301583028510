/* eslint-disable react/jsx-key */
import React from 'react'

import { MatchStatus, EsportSlug } from '@plvs/graphql'
import { Box } from '@material-ui/core'
import { DisputeMatch, StepType } from '@plvs/utils'

import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'
import { MatchFinalScore } from '../dynamic/matchFinalScore/MatchFinalScore'
import {
  CharacterSelect,
  StageSelect,
  Reporting,
  MatchPreview,
  GameIntermission,
} from './steps'
import { useMatchAssistantContext } from './useMatchAssistant'
import { PlatformCheck } from './steps/rocketLeague/platformCheck/PlatformCheck'
import { PrepareForInvite } from './steps/rocketLeague/prepareForInvite/PrepareForInvite'
import { Scoreboard } from './steps/rocketLeague/scoreboard/Scoreboard'
import { RosterSelect } from './steps/general/rosterSelect'
import { DisputeResultsButton } from '../dynamic/buttons/DisputeResultsButton'
import { MatchAssistantActions } from './MatchAssistantActions'
import { TeamReporting } from './steps/reporting/TeamReporting'
import { MatchAssistantIntro } from './steps/general/intro'
import { PlayerSelect } from './steps/smash/playerSelect/PlayerSelect'
import { Spectator } from './steps/rocketLeague/spectator/Spectator'
import { ReadyUp } from './steps/general/readyUp'
import { GamePreview } from './steps/general/gamePreview'
import { MatchPreview as NBA2kMatchPreview } from './steps/nba2k/matchPreview/MatchPreview'
import { MatchPreview as MaddenMatchPreview } from './steps/madden/matchPreview/MatchPreview'
import { Scoreboard as ScoreboardWithInputedScore } from './steps/madden/scoreboard/Scoreboard'
import { GamePreview as GamePreviewWithScore } from './steps/madden/gamePreview/GamePreview'
import { LoadoutSelect } from './steps/splatoon/loadoutSelect/LoadoutSelect'
import { StageSelect as SplatoonStageSelect } from './steps/splatoon/stageSelect/StageSelect'
import { Beta } from './steps/general/beta/Beta'
import { PlaybookSelect } from './steps/madden'
import { TeamSelect } from './steps/nba2k/teamSelect/TeamSelect'
import { TournamentCode } from './steps/leagueOfLegends/tournamentCode/TournamentCode'

type MatchAssistantAdapterStepValue = Array<{
  step: React.ReactNode
  title: string | React.ReactNode
  actions?: React.ReactElement
  subtitle?: string
}>

interface MatchAssistantAdapterSteps {
  assistantSteps: Record<StepType | string, MatchAssistantAdapterStepValue>
}

export const useMatchAssistantAdapterSteps = (
  esportSlug?: EsportSlug
): MatchAssistantAdapterSteps => {
  const {
    match,
    teamId,
    opposingTeamId,
    currentStep,
    stepIndex,
    setStepIndex,
    currentSeries,
    seriesOrdinal = 1,
    gameOrdinal = 1,
    myTeam,
  } = useMatchAssistantContext()

  const {
    match: { canManageMatch },
  } = useMatchLobbyRenderControllerState().getMatchLobbyRenderControllerState()

  const isMatchComplete = currentStep?.matchDetails?.isComplete ?? false

  const isSeriesTitle = Boolean(currentSeries && seriesOrdinal)
  const subtitle = isSeriesTitle
    ? `Series ${seriesOrdinal} - Game ${gameOrdinal}`
    : `Game ${gameOrdinal}`

  const showDisputeButton =
    (isMatchComplete || match?.status === MatchStatus.Completed) &&
    canManageMatch &&
    match?.id

  const rosterSelectSteps: MatchAssistantAdapterStepValue = []
  if (gameOrdinal === 1) {
    rosterSelectSteps.push({
      step: (
        <MatchAssistantIntro onNext={(): void => setStepIndex(stepIndex + 1)} />
      ),
      title: 'Guide',
      actions: <MatchAssistantActions />,
    })
  }
  rosterSelectSteps.push({
    step: <RosterSelect teamId={teamId} />,
    title: 'My Roster',
    subtitle,
    actions: <MatchAssistantActions />,
  })

  const generalSteps: Record<
    StepType | string,
    MatchAssistantAdapterStepValue
  > = {
    [StepType.ReadyUp]: [
      {
        step: <ReadyUp />,
        title: 'Ready Up',
        actions: <MatchAssistantActions />,
      },
    ],
    // Only beta-enabled esports will see this step
    [StepType.BetaOptIn]: [
      {
        step: <Beta />,
        title: 'New Feature Alert',
        actions: <MatchAssistantActions />,
      },
    ],
    [StepType.RosterSelect]: rosterSelectSteps,
    [StepType.OpponentPreview]: [
      {
        step: <RosterSelect teamId={opposingTeamId} />,
        subtitle,
        title: 'Opponent',
        actions: <MatchAssistantActions />,
      },
    ],
  }

  const playerSelectStep: MatchAssistantAdapterStepValue = []
  if (seriesOrdinal > 1) {
    playerSelectStep.push({
      step: (
        <GameIntermission
          buttonText="Choose Your Player"
          firebobMessage="Get ready to select your players!"
          isSeriesIntermission
          onNext={(): void => setStepIndex(stepIndex + 1)}
        />
      ),
      title: 'Intermission',
      subtitle,
      actions: <MatchAssistantActions />,
    })
  }
  playerSelectStep.push({
    step: <PlayerSelect />,
    subtitle,
    title: 'Player Select',
    actions: <MatchAssistantActions />,
  })

  const assistantSteps: Record<
    string | EsportSlug,
    Record<StepType | string, MatchAssistantAdapterStepValue>
  > = {
    [EsportSlug.SmashBrosUltimate]: {
      ...generalSteps,
      [StepType.PlayerSelect]: playerSelectStep,

      [StepType.CharacterSelect]: [
        {
          step: (
            <GameIntermission
              onNext={(): void => setStepIndex(stepIndex + 1)}
            />
          ),
          title: 'Intermission',
          subtitle,
          actions: <MatchAssistantActions />,
        },
        {
          step: <CharacterSelect />,
          subtitle,
          title: 'Characters',
          actions: <MatchAssistantActions />,
        },
      ],

      [StepType.StageSelect]: [
        {
          step: (
            <CharacterSelect
              buttonText="Continue"
              onNext={(): void => setStepIndex(stepIndex + 1)}
              readonly
            />
          ),
          subtitle,
          title: 'Characters',
          actions: <MatchAssistantActions />,
        },
        {
          step: <StageSelect />,
          title: 'Stage Striking',
          subtitle,
          actions: <MatchAssistantActions />,
        },
      ],

      [StepType.Report]: [
        {
          step: (
            <MatchPreview onNext={(): void => setStepIndex(stepIndex + 1)} />
          ),
          title: 'Match Preview',
          subtitle,
          actions: <MatchAssistantActions />,
        },
        {
          step: <Reporting />,
          title: 'Scoreboard',
          subtitle,
          actions: <MatchAssistantActions />,
        },
      ],

      [StepType.Final]: [
        {
          step: <MatchFinalScore match={match} />,
          title: 'Scoreboard',
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <></>
          ),
        },
      ],
    },

    [EsportSlug.RocketLeague]: {
      ...generalSteps,
      [StepType.Spectator]: [
        {
          step: <Spectator />,
          title: 'Spectator',
          subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.PlatformCheck]: [
        {
          step: <PlatformCheck />,
          title: 'Platform Check',
          subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.PrepareForInvite]: [
        {
          step: <PrepareForInvite />,
          title: 'Match Preview',
          subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.Report]: [
        {
          step: <Scoreboard isFinal={isMatchComplete} />,
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <MatchAssistantActions />
          ),
        },
      ],
      [StepType.Scoreboard]: [
        {
          step: <Scoreboard isFinal={isMatchComplete} />,
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <MatchAssistantActions />
          ),
        },
      ],
      // Repeat of this step for the the final step
      [StepType.Final]: [
        {
          step: <Scoreboard isFinal />,
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <MatchAssistantActions />
          ),
        },
      ],
    },

    [EsportSlug.Splatoon]: {
      ...generalSteps,
      [StepType.StageSelect]: [
        {
          step: <SplatoonStageSelect />,
          subtitle,
          title: 'Stage Select',
          actions: <MatchAssistantActions />,
        },
      ],

      [StepType.CharacterSelect]: [
        {
          step: <LoadoutSelect />,
          subtitle,
          title: 'Loadouts',
          actions: <MatchAssistantActions />,
        },
      ],

      [StepType.Report]: [
        {
          step: (
            <GamePreview onNext={(): void => setStepIndex(stepIndex + 1)} />
          ),
          title: 'Game Preview',
          subtitle,
          actions: <MatchAssistantActions />,
        },
        {
          step: <TeamReporting />,
          title: 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: <MatchAssistantActions />,
        },
      ],

      [StepType.Final]: [
        {
          step: <MatchFinalScore match={match} />,
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <></>
          ),
        },
      ],
    },

    [EsportSlug.Madden]: {
      ...generalSteps,

      [StepType.CharacterSelect]:
        gameOrdinal > 1
          ? [
              {
                step: (
                  <GamePreviewWithScore
                    onNext={(): void => setStepIndex(stepIndex + 1)}
                  />
                ),
                title: 'Intermission',
                subtitle,
                actions: <MatchAssistantActions />,
              },
              {
                step: <PlaybookSelect />,
                subtitle,
                title: 'Team Picker',
                actions: <MatchAssistantActions />,
              },
            ]
          : [
              {
                step: <PlaybookSelect />,
                subtitle,
                title: 'Team Picker',
                actions: <MatchAssistantActions />,
              },
            ],

      [StepType.Report]: [
        {
          step: (
            <MaddenMatchPreview
              onNext={(): void => setStepIndex(stepIndex + 1)}
            />
          ),
          subtitle,
          title: 'Match Preview',
          actions: <MatchAssistantActions />,
        },
        {
          step: <TeamReporting />,
          title: 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: <MatchAssistantActions />,
        },
      ],

      [StepType.Final]: [
        {
          step: (
            <ScoreboardWithInputedScore
              isFinal={isMatchComplete}
              match={match}
            />
          ),
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <></>
          ),
        },
      ],
    },

    [EsportSlug.NBA2K]: {
      ...generalSteps,
      [StepType.CharacterSelect]:
        gameOrdinal > 1
          ? [
              {
                step: (
                  <GamePreviewWithScore
                    onNext={(): void => setStepIndex(stepIndex + 1)}
                  />
                ),
                title: 'Intermission',
                subtitle,
                actions: <MatchAssistantActions />,
              },
              {
                step: <TeamSelect />,
                subtitle,
                title: 'Team Picker',
                actions: <MatchAssistantActions />,
              },
            ]
          : [
              {
                step: <TeamSelect />,
                subtitle,
                title: 'Team Picker',
                actions: <MatchAssistantActions />,
              },
            ],

      [StepType.Report]: [
        {
          step: (
            <NBA2kMatchPreview
              onNext={(): void => setStepIndex(stepIndex + 1)}
            />
          ),
          title: 'Match Preview',
          subtitle,
          actions: <MatchAssistantActions />,
        },
        {
          step: <TeamReporting />,
          title: 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.Final]: [
        {
          step: (
            <ScoreboardWithInputedScore
              isFinal={isMatchComplete}
              match={match}
            />
          ),
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <></>
          ),
        },
      ],
    },
    [EsportSlug.LeagueOfLegends]: {
      ...generalSteps,
      [StepType.Report]: [
        {
          step: (
            <TournamentCode onNext={(): void => setStepIndex(stepIndex + 1)} />
          ),
          title: 'Tournament Code',
          subtitle,
          actions: <MatchAssistantActions />,
        },
        {
          step: (
            <GamePreview onNext={(): void => setStepIndex(stepIndex + 1)} />
          ),
          title: 'Game Preview',
          actions: <MatchAssistantActions />,
        },
        {
          step: <TeamReporting />,
          title: 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.Final]: [
        {
          step: (
            <Box mb={3}>
              <MatchFinalScore match={match} />
            </Box>
          ),
          subtitle: isMatchComplete ? '' : subtitle,
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <MatchAssistantActions />
          ),
        },
      ],
    },
  }

  const adapter: Record<string | EsportSlug, MatchAssistantAdapterSteps> = {
    // All Smash Bros
    [EsportSlug.SmashBrosUltimate]: {
      assistantSteps: assistantSteps[EsportSlug.SmashBrosUltimate],
    },
    [EsportSlug.SuperSmashBrosUltimateSolos]: {
      assistantSteps: assistantSteps[EsportSlug.SmashBrosUltimate],
    },
    [EsportSlug.SuperSmashBrosUltimateCrew]: {
      assistantSteps: assistantSteps[EsportSlug.SmashBrosUltimate],
    },

    [EsportSlug.StreetFighter]: {
      assistantSteps: assistantSteps[EsportSlug.SmashBrosUltimate],
    },

    [EsportSlug.RocketLeague]: {
      assistantSteps: assistantSteps[EsportSlug.RocketLeague],
    },

    [EsportSlug.Splatoon]: {
      assistantSteps: assistantSteps[EsportSlug.Splatoon],
    },

    // All Maddens
    [EsportSlug.Madden]: {
      assistantSteps: assistantSteps[EsportSlug.Madden],
    },
    [EsportSlug.Madden21PS4]: {
      assistantSteps: assistantSteps[EsportSlug.Madden],
    },
    [EsportSlug.Madden21SolosXboxOne]: {
      assistantSteps: assistantSteps[EsportSlug.Madden],
    },
    [EsportSlug.Madden21XboxOne]: {
      assistantSteps: assistantSteps[EsportSlug.Madden],
    },

    [EsportSlug.NBA2K]: {
      assistantSteps: assistantSteps[EsportSlug.NBA2K],
    },

    [EsportSlug.LeagueOfLegends]: {
      assistantSteps: assistantSteps[EsportSlug.LeagueOfLegends],
    },
  }

  const generalAdapter = {
    assistantSteps: {
      ...generalSteps,
      [StepType.Report]: [
        {
          step: (
            <GamePreview onNext={(): void => setStepIndex(stepIndex + 1)} />
          ),
          title: 'Game Preview',
          actions: <MatchAssistantActions />,
        },
        {
          step: <TeamReporting />,
          title: 'Scoreboard',
          subtitle: isMatchComplete ? '' : subtitle,
          actions: <MatchAssistantActions />,
        },
      ],
      [StepType.Final]: [
        {
          step: (
            <Box mb={3}>
              <MatchFinalScore match={match} />
            </Box>
          ),
          subtitle: isMatchComplete ? '' : subtitle,
          title: isMatchComplete ? 'Final Score' : 'Scoreboard',
          actions: showDisputeButton ? (
            <DisputeResultsButton
              canManageMatch={!!myTeam}
              match={match as DisputeMatch}
            />
          ) : (
            <MatchAssistantActions />
          ),
        },
      ],
    },
  }

  return (esportSlug && adapter[esportSlug]) ?? generalAdapter
}
