import React from 'react'
import {
  makeStyles,
  Menu,
  MenuItem,
  Fade,
  Box,
  SwipeableDrawer,
} from '@material-ui/core'
import { ArrowLeft, CaretRight } from '@playvs-inc/nexus-icons'
import { Path } from '@plvs/const'
import {
  userDropdownMenuItemClicked,
  productTourClicked,
} from '@plvs/respawn/features/analytics'
import {
  NxTextLinkExternal,
  NxTypography,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import clsx from 'clsx'
import {
  useSelectedOrganizationFn,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'
import { Skeleton } from '@material-ui/lab'
import { Hidden } from '@plvs/respawn/features/layout'
import dayjs from '@plvs/respawn/init/dayjs'

import { useNavigate } from 'react-router-dom'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { UserMinimalUser } from './AppDrawer'
import { SwitchSchoolModal } from './SwitchSchoolModal'
import { MenuItemConfig, useMenuItems } from './useMenuItems'

const useStyles = makeStyles((theme) => ({
  accountContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    cursor: 'pointer',
  },
  profile: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    '& div': {
      cursor: 'pointer',
    },
  },
  menuContainer: {
    '& .MuiPaper-root': {
      width: theme.spacing(26.25),
      marginLeft: theme.spacing(1),
    },
    '& .MuiMenuItem-root': {
      textTransform: 'unset !important',
    },
    '& .MuiListItem-gutters': {
      padding: `${theme.spacing(2, 2.625)} !important`,
    },
    '& .MuiList-padding': {
      padding: 0,
    },
    '& ul': {
      padding: 0,
    },
  },
  menuItem: {
    fontSize: '1.1250em',
    color: theme.palette.ColorTextBase,
  },
  menuItemDesktop: {
    padding: theme.spacing(2, 3),
  },
  dropdown: {
    width: theme.spacing(13.75),
  },
  avatarSkeleton: {
    height: theme.spacing(4),
    width: theme.spacing(4),
  },
  profileContainer: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  swipeContainer: {
    '& .MuiDrawer-paper ': {
      backgroundColor: 'unset !important',
    },
    '& .MuiPaper-elevation16': {
      boxShadow: 'unset',
    },
  },
  drawerContent: {
    overflow: 'auto',
    width: '100vw',
    backgroundColor: theme.palette.ColorBackgroundBase,
    height: 'calc(100vh - 93px)',
  },
  backArrow: {
    marginRight: theme.spacing(2),
  },
  userName: {
    maxWidth: theme.spacing(15),
    fontSize: theme.typography.fontSize * 1.3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  schoolName: {
    maxWidth: '142px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  topShadow: {
    position: 'relative',
    height: theme.spacing(2),
    top: `-${theme.spacing(2)}px`,
    boxShadow: theme.mixins.boxShadow.elevation3,
  },
  externalLink: {
    color: theme.palette.ColorTextBase,
    '&:hover': {
      color: theme.palette.ColorTextBase,
    },
  },
}))

export interface ProfileDropdown {
  userNameAndAvatar?: UserMinimalUser | null
}

type DataCyProps = {
  ['data-cy']?: string
}

export const ProfileDropdown: React.FC<ProfileDropdown> = ({
  userNameAndAvatar,
}): React.ReactElement => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<any>(null)
  const [isSwitchSchoolModalOpen, setSwitchSchoolModalOpen] = React.useState(
    false
  )
  const classes = useStyles()
  const { id: orgId } = useSelectedOrganizationFn()
  const { userId, isPlayerAtOrg, orgName } = useUserIdentityFn()
  const { onboardingTour, initiatedTimestamp } = useProductTours()

  const handleClose = (): void => {
    setAnchorEl(null)
  }

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    if (onboardingTour?.isActive()) {
      productTourClicked({
        userId,
        initiatedTimestamp: initiatedTimestamp ?? '',
        timeStamp: dayjs().toISOString(),
        stepNumber: 15,
        clickTarget: 'Profile Dropdown',
      })
      onboardingTour?.next()
    }
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (path: Path | string): void => {
    navigate(path)
    handleClose()
  }

  const handleAnalytics = (menuItem = ''): void => {
    userDropdownMenuItemClicked({
      menuItem,
      userId: userNameAndAvatar?.id ?? '',
      timeStamp: new Date().toISOString(),
    })
  }

  const { menuItems } = useMenuItems({
    handleAnalytics,
    handleMenuItemClick,
    handleClose,
    openSwitchSchoolModal: (): void => setSwitchSchoolModalOpen(true),
  })

  const handleAvatarClick = (e?: React.MouseEvent): void => {
    if (isPlayerAtOrg) {
      e?.preventDefault()
      e?.stopPropagation()
      navigate(`${Path.App}${Path.Profile}/${userId}`)
    }
  }

  return (
    <Box
      className={classes.accountContainer}
      data-cy="account-settings-navigation"
    >
      <Box
        className={clsx(classes.profileContainer, 'shepherd-profile')}
        onClick={handleOpen}
        px={4}
        py={3}
      >
        {userNameAndAvatar ? (
          <Box className={classes.profile} id="shepherd-profile">
            <NxUserCluster
              avatarHashId={userNameAndAvatar.id}
              avatarUrl={userNameAndAvatar.avatarUrl ?? undefined}
              compact
              onClickAvatar={handleAvatarClick}
              subtitles={[
                {
                  title: (
                    <NxTypography
                      className={classes.schoolName}
                      variant="body4"
                    >
                      {orgName}
                    </NxTypography>
                  ),
                },
              ]}
              title={
                <NxTypography className={classes.userName}>
                  {userNameAndAvatar?.name}
                </NxTypography>
              }
            />
          </Box>
        ) : (
          <Skeleton className={classes.avatarSkeleton} variant="circle" />
        )}
        <CaretRight className="profile-menu-options" />
      </Box>
      <Hidden smDown>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          className={classes.menuContainer}
          disableAutoFocusItem
          getContentAnchorEl={null}
          keepMounted
          onClose={handleClose}
          open={!!anchorEl}
          transformOrigin={{
            vertical: 0,
            horizontal: -8,
          }}
          TransitionComponent={Fade}
        >
          {menuItems.map(
            (menuItem: MenuItemConfig): JSX.Element => {
              const {
                dataCy,
                handleOnClick,
                icon,
                key,
                label,
                className,
                isExternal,
              } = menuItem
              const dataCyProps: DataCyProps = {}
              if (dataCy) {
                dataCyProps['data-cy'] = dataCy
              }

              return (
                <MenuItem
                  key={key}
                  className={clsx(classes.menuItemDesktop, className)}
                  onClick={handleOnClick}
                >
                  {icon}
                  {isExternal ? (
                    <NxTextLinkExternal
                      {...dataCyProps}
                      className={classes.externalLink}
                      label={label}
                      rel="noopener"
                      target="_blank"
                    />
                  ) : (
                    <NxTypography className={classes.menuItem} {...dataCyProps}>
                      {label}
                    </NxTypography>
                  )}
                </MenuItem>
              )
            }
          )}
        </Menu>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor="left"
          BackdropProps={{ invisible: true }}
          className={classes.swipeContainer}
          data-testid="ProfileDropdownMobileToggle__Drawer"
          onClose={handleClose}
          onOpen={(): void => {}}
          open={!!anchorEl}
        >
          <Box className={classes.drawerContent} minWidth="100vw" mt={11.6}>
            <div className={classes.topShadow} />

            <Box
              alignItems="center"
              display="flex"
              mb={5}
              mt={2}
              mx={3}
              onClick={handleClose}
            >
              <ArrowLeft className={classes.backArrow} />
              <NxTypography variant="body2">
                {userNameAndAvatar?.name}
              </NxTypography>
            </Box>
            <Box ml={1.5}>
              {menuItems.map(
                (menuItem: MenuItemConfig): JSX.Element => {
                  const { dataCy, handleOnClick, icon, key, label } = menuItem
                  const dataCyProps: DataCyProps = {}

                  if (dataCy) {
                    dataCyProps['data-cy'] = dataCy
                  }

                  return (
                    <MenuItem key={key} onClick={handleOnClick}>
                      {icon}
                      <NxTypography
                        className={classes.menuItem}
                        {...dataCyProps}
                      >
                        {label}
                      </NxTypography>
                    </MenuItem>
                  )
                }
              )}
            </Box>
          </Box>
        </SwipeableDrawer>
      </Hidden>
      {orgId && (
        <SwitchSchoolModal
          onClose={(): void => setSwitchSchoolModalOpen(false)}
          open={isSwitchSchoolModalOpen}
          orgId={orgId}
        />
      )}
    </Box>
  )
}
