import React from 'react'
import { makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { CreateCSSProperties } from '@material-ui/styles'

interface StyleProps {
  color: string
  size: number
}

export const useStyles = makeStyles({
  arrowBase: {
    width: 0,
    height: 0,
  },
  arrowTop: ({ color, size }: StyleProps): CreateCSSProperties => ({
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
    borderBottom: `${size}px solid ${color}`,
  }),
  arrowRight: ({ color, size }: StyleProps): CreateCSSProperties => ({
    borderTop: `${size}px solid transparent`,
    borderBottom: `${size}px solid transparent`,
    borderLeft: `${size}px solid ${color}`,
  }),
  arrowLeft: ({ color, size }: StyleProps): CreateCSSProperties => ({
    borderTop: `${size}px solid transparent`,
    borderBottom: `${size}px solid transparent`,
    borderRight: `${size}px solid ${color}`,
  }),
  arrowBottom: ({ color, size }: StyleProps): CreateCSSProperties => ({
    borderLeft: `${size}px solid transparent`,
    borderRight: `${size}px solid transparent`,
    borderTop: `${size}px solid ${color}`,
  }),
})

interface Props {
  className?: string
  color?: string
  direction: 'top' | 'right' | 'bottom' | 'left'
  size?: number
}

export const CssTriangle: React.FC<Props> = ({
  color = '#181A1B',
  className,
  direction,
  size = 7,
}) => {
  const classes = useStyles({ color, size })

  const directionClassMap: Record<string, string> = {
    top: classes.arrowTop,
    right: classes.arrowRight,
    bottom: classes.arrowBottom,
    left: classes.arrowLeft,
  }

  const directionClass = directionClassMap[direction]

  return (
    <div
      className={clsx(classes.arrowBase, directionClass, className)}
      data-testid={`triangle-${direction}`}
    />
  )
}
