import {
  Schools,
  Teams,
  Users,
  VarsityAssociations,
  Matches,
  Leagues,
  LeagueGroups,
  Clips,
  Metaseasons,
  Esports,
  BulkAction,
} from '@plvs/respawn/features/app-drawer/links'
import { ProductType } from '@plvs/client-data/models'
import { useGetNavigationLinks } from '@plvs/respawn/features/shepherd/utils/Links'
import { useMemo } from 'react'
import {
  AppDrawerRenderLinksPropsCheckpoint,
  AppDrawerRenderLinksReturnCheckpoint,
  AppDrawerRenderLinksProps,
  AppDrawerRenderLinksReturn,
} from '../types/appDrawerRenderController.types'

export const getAppDrawerRenderLinksForCheckpoint = ({
  productType,
}: AppDrawerRenderLinksPropsCheckpoint): AppDrawerRenderLinksReturnCheckpoint => {
  if (productType === ProductType.Scholastic) {
    return {
      mainLinks: [Users, Teams, Schools, VarsityAssociations],
      secondaryLinks: [
        Matches,
        Leagues,
        LeagueGroups,
        Clips,
        Metaseasons,
        Esports,
        BulkAction,
      ],
    }
  }

  if (productType === ProductType.Stadium) {
    return {
      mainLinks: [Users, Teams],
      secondaryLinks: [
        Matches,
        Leagues,
        Clips,
        Metaseasons,
        Esports,
        BulkAction,
      ],
    }
  }

  return {
    mainLinks: [],
    secondaryLinks: [],
  }
}

export const useAppDrawerRenderLinks = ({
  isPublic,
  productType,
  isCoachAtOrg = false,
  isPlayerAtOrg = false,
  isCaptainAtOrg = false,
  isParent = false,
  isMobile,
  hasResourcesFlag = false,
}: AppDrawerRenderLinksProps): AppDrawerRenderLinksReturn => {
  const links = useGetNavigationLinks({
    isCoachAtOrg,
    isPlayerAtOrg,
    isCaptainAtOrg,
    isParent,
    isMobile,
    isPublic,
    hasResourcesFlag,
    productType,
  })

  return useMemo(() => ({ links }), [links])
}
