import React, { useEffect, useMemo, useState } from 'react'
import {
  Route,
  useLocation,
  useNavigate,
  Outlet,
  Navigate,
} from 'react-router-dom'
import dayjs from 'dayjs'
import { makeStyles } from '@material-ui/core'
import { any } from 'ramda'

import { HeroGutter } from '@plvs/rally/components/hero'
import {
  PageContentGutter,
  WaitTillLoaded,
  useBreakpointXs,
} from '@plvs/respawn/features/layout'
import {
  useMyTeamsAndLeaguesQuery,
  useGetScheduledAndQueueMatchesByTeamIdsQuery,
  UserRoleName,
  EsportSlug,
} from '@plvs/graphql'
import { getNow, MinimalMetaseason } from '@plvs/utils'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import {
  NxTab,
  NxTabs,
  NxPlayVsBanner,
  PersonaImageVariant,
  NxEsportBanner,
} from '@playvs-inc/nexus-components'
import { MobileHero } from '@plvs/rally/components/hero/MobileHero'

import ScheduleBreakWeeksWidget from '@plvs/rally/components/schedule/ScheduleBreakWeeksWidget'
import { TablePagination, usePagination } from '@plvs/rally/components/table'
import { useProductTypeFn } from '@plvs/client-data/hooks'
import {
  useScheduleControllerState,
  useScheduleRenderControllerContext,
} from '@plvs/respawn/renderController/schedule/ScheduleRenderControllerProvider'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { AllMatchesContainer } from './AllMatchesContainer'
import { SortFilter } from './schedule'
import { HERO_SUBTITLE, HERO_TITLE } from './scheduleConst'
import { mapMatches, mapQueueMatches } from './matchesHelper'

const SchedulePageMatchLimit = 10

const useStyles = makeStyles((theme) => ({
  tabs: {
    margin: theme.spacing(0, 2),
  },
}))

interface ScheduleWrapperProps {
  path: string
  teamIds: string[]
  isLoading: boolean
  metaseason?: MinimalMetaseason
  heroPersona?: PersonaImageVariant | string
  heroEsport?: EsportSlug
}

const ScheduleWrapper = ({
  path: basePath,
  teamIds = [],
  isLoading,
  metaseason,
  heroPersona = PersonaImageVariant.Schedule,
  heroEsport,
}: ScheduleWrapperProps): React.ReactElement => {
  const productType = useProductTypeFn()
  const { schedule } = useScheduleRenderControllerContext()
  const {
    getRenderControllerState,
    setRenderControllerStateFn,
  } = useScheduleControllerState()
  const scheduleComponentDetails = schedule.getScheduleComponentDetails({
    productType,
  })
  const { setPage, page } = usePagination({
    itemsPerPage: SchedulePageMatchLimit,
  })
  const [isPrev, setIsPrev] = useState(false)
  const [offsetQueue, setOffsetQueue] = useState<
    { queue: number; scheduled: number }[]
  >([])

  const mobile = useBreakpointXs()
  const navigate = useNavigate()
  const location = useLocation()
  const classes = useStyles()

  const [twoHoursAgo] = useState(getNow({ minuteDifference: -120 }))

  const {
    loading: selectedEntityLoading,
    roles,
    selectedEntityId,
  } = useProfileContext()

  const aMonthAgo = useMemo(() => {
    return dayjs(new Date(twoHoursAgo)).subtract(30, 'days').toISOString()
  }, [])

  const aMonthInTheFuture = useMemo(() => {
    return dayjs(new Date(twoHoursAgo)).add(30, 'days').toISOString()
  }, [])

  // queries
  const isUpcoming = !location.pathname.includes('past')

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useGetScheduledAndQueueMatchesByTeamIdsQuery({
    variables: {
      input: {
        teamIds,
        pageLimit: SchedulePageMatchLimit,
        scheduledStartsAtRange: {
          ...(isUpcoming
            ? { after: twoHoursAgo, before: aMonthInTheFuture }
            : { after: aMonthAgo, before: twoHoursAgo }),
        },
        scheduledMatchesOffset: 0,
        queueMatchesOffset: 0,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: isLoading || selectedEntityLoading || !teamIds.length,
  })

  const newQueueOffset =
    data?.getScheduledAndQueueMatchesByTeamIds?.queueMatchesOffset ?? 0
  const newScheduledOffset =
    data?.getScheduledAndQueueMatchesByTeamIds?.scheduledMatchesOffset ?? 0
  const totalQueueMatches =
    data?.getScheduledAndQueueMatchesByTeamIds?.totalQueueMatches ?? 0
  const totalScheduledMatches =
    data?.getScheduledAndQueueMatchesByTeamIds?.totalScheduledMatches ?? 0
  const totalMatchesCount = totalQueueMatches + totalScheduledMatches
  const totalNumberOfPages = Math.ceil(
    totalMatchesCount / SchedulePageMatchLimit
  )

  const isCoach = roles.some(
    (role) =>
      role.roleName === UserRoleName.Coach ||
      role.roleName === UserRoleName.PrimaryCoach
  )
  const {
    data: teamsAndLeagueData,
    loading: teamsAndLeagueDataLoading,
    error: teamsAndLeagueDataError,
  } = useMyTeamsAndLeaguesQuery({
    variables: {
      metaseasonId: metaseason?.id ?? '',
      isCoach,
      filters: {
        teamIds,
      },
    },
    skip: !metaseason?.id,
  })

  const hasTeamInCurrentSeason = any((team) => {
    const leagues = team?.leagues ?? []
    return leagues.length > 0
  }, teamsAndLeagueData?.getTeams?.teams ?? [])

  const isEnrolledInCurrentSeason =
    (isCoach && hasTeamInCurrentSeason) ||
    !!teamsAndLeagueData?.me?.leagues?.length

  const matches = mapMatches(
    data?.getScheduledAndQueueMatchesByTeamIds?.scheduledMatches ?? []
  )
  const queueMatches = mapQueueMatches(
    data?.getScheduledAndQueueMatchesByTeamIds?.queueMatches ?? []
  )

  const isDataLoading =
    loading || selectedEntityLoading || teamsAndLeagueDataLoading

  const handleFetchMoreMatches = async (pageNumber: number): Promise<void> => {
    const index = pageNumber - 1
    const scheduledOffset = offsetQueue[index]?.scheduled ?? 0
    const queueOffset = offsetQueue[index]?.queue ?? 0
    try {
      await fetchMore({
        variables: {
          input: {
            teamIds,
            pageLimit: SchedulePageMatchLimit,
            scheduledStartsAtRange: {
              ...(isUpcoming
                ? { after: twoHoursAgo, before: aMonthInTheFuture }
                : { after: aMonthAgo, before: twoHoursAgo }),
            },
            scheduledMatchesOffset: scheduledOffset,
            queueMatchesOffset: queueOffset,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev
          const { getScheduledAndQueueMatchesByTeamIds } = fetchMoreResult
          return {
            getScheduledAndQueueMatchesByTeamIds: {
              ...getScheduledAndQueueMatchesByTeamIds,
              scheduledMatches: [
                ...(getScheduledAndQueueMatchesByTeamIds.scheduledMatches ??
                  []),
              ],
              queueMatches: [
                ...(getScheduledAndQueueMatchesByTeamIds.queueMatches ?? []),
              ],
              queueMatchesOffset:
                getScheduledAndQueueMatchesByTeamIds.queueMatchesOffset,
              scheduledMatchesOffset:
                getScheduledAndQueueMatchesByTeamIds.scheduledMatchesOffset,
            },
          }
        },
      })
    } catch (e) {
      // Swallowing error until we have a way to handle it in the UI.
    }
  }

  useEffect(() => {
    if (isPrev) {
      const newOffsetQueue = offsetQueue.slice(0, -1)
      setOffsetQueue(newOffsetQueue)
    } else {
      const newOffsetQueue = [
        ...offsetQueue,
        { queue: newQueueOffset, scheduled: newScheduledOffset },
      ]
      setOffsetQueue(newOffsetQueue)
    }
  }, [newQueueOffset, newScheduledOffset])

  useEffect(() => {
    handleFetchMoreMatches(page)
  }, [page])

  React.useEffect(() => {
    const currentState = getRenderControllerState()
    setRenderControllerStateFn({
      ...currentState,
      schedule: {
        ...currentState.schedule,
        ...scheduleComponentDetails,
      },
    })
  }, [
    productType,
    scheduleComponentDetails.emptyMatchesButtonPath,
    scheduleComponentDetails.emptyMatchesButtonTitle,
    scheduleComponentDetails.emptyMatchesSubtitleCopyPartial,
  ])

  const handleCallToRouter = (
    _event: React.ChangeEvent<unknown>,
    value: string
  ): void => {
    setPage(1)
    setOffsetQueue([])
    setIsPrev(false)
    navigate(value)
  }

  const bannerSize = hasTeamInCurrentSeason ? 'large' : 'small'
  const widget = hasTeamInCurrentSeason ? (
    <ScheduleBreakWeeksWidget
      isCoach={isCoach}
      metaseasonId={metaseason?.id ?? ''}
      metaseasonName={metaseason?.name ?? ''}
      schoolId={selectedEntityId}
    />
  ) : undefined

  const Hero = heroEsport ? (
    <NxEsportBanner
      esportSlug={heroEsport}
      shouldUseGradient={false}
      size={bannerSize}
      subtitle={HERO_SUBTITLE}
      title={HERO_TITLE}
      variant={heroPersona}
      widget={widget}
    />
  ) : (
    <NxPlayVsBanner
      size={bannerSize}
      subtitle={HERO_SUBTITLE}
      title={HERO_TITLE}
      variant={heroPersona}
      widget={widget}
    />
  )

  return (
    <>
      <HeroGutter mb={mobile ? 3 : 4}>
        {mobile ? (
          <MobileHero subtitle={HERO_SUBTITLE} title={HERO_TITLE} />
        ) : (
          Hero
        )}
      </HeroGutter>

      <NxTabs
        className={classes.tabs}
        onChange={handleCallToRouter}
        size="large"
        value={location.pathname}
      >
        <NxTab label="Upcoming" value={`${basePath}/upcoming`} />
        <NxTab label="Past" value={`${basePath}/past`} />
      </NxTabs>

      <PageContentGutter style={{ marginTop: '24px' }}>
        <ApmRoutes>
          <Route element={<Navigate to={`${basePath}/upcoming`} />} path="/" />

          <Route
            element={
              <AllMatchesContainer
                error={!!error || !!teamsAndLeagueDataError}
                isEnrolledInCurrentSeason={isEnrolledInCurrentSeason}
                loading={isDataLoading}
                matches={matches}
                queueMatches={queueMatches}
                show={SortFilter.Upcoming}
              />
            }
            path="upcoming"
          />

          <Route
            element={
              <AllMatchesContainer
                error={!!error || !!teamsAndLeagueDataError}
                isEnrolledInCurrentSeason={isEnrolledInCurrentSeason}
                loading={isDataLoading}
                matches={matches}
                queueMatches={[]}
                show={SortFilter.Past}
              />
            }
            path="past"
          />
        </ApmRoutes>

        <Outlet />

        {totalNumberOfPages > 0 && (
          <WaitTillLoaded loading={isDataLoading}>
            <TablePagination
              numPages={totalNumberOfPages}
              page={page}
              setIsPrev={setIsPrev}
              setPage={setPage}
              totalCount={totalMatchesCount}
            />
          </WaitTillLoaded>
        )}
      </PageContentGutter>
    </>
  )
}

export default ScheduleWrapper
