import React from 'react'
import { AppPage, PublicPage } from '@plvs/rally/pages/page'
import { LeagueDetails } from '@plvs/rally/features/explore/LeagueDetails'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { AllMetaseasons } from '@plvs/rally/containers/filter/league/AllMetaseasons'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Path } from '@plvs/const'
import { useGetLeagueByPublicSlugQuery } from '@plvs/graphql/generated'

export const LeagueDetailsPage: React.FC = () => {
  const location = useLocation()
  const isPublic = !location.pathname.includes('/app')

  const { userId, loading } = useUserIdentityFn()

  const { publicSlug } = useParams<{
    publicSlug: string
  }>()

  const { data } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })
  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''

  if (!publicSlug) {
    return <></>
  }

  // if logged in already, redirect users back to /app url
  if (isPublic && userId && !loading) {
    const prependApp = `${Path.App}${location.pathname}${location.search}`
    return <Navigate to={prependApp} />
  }
  return (
    <AllMetaseasons leagueId={leagueId}>
      {isPublic ? (
        <PublicPage
          description="Public Leage Page"
          hasDrawer
          title="Competitions"
        >
          <LeagueDetails isPublic={isPublic} publicSlug={publicSlug} />
        </PublicPage>
      ) : (
        <AppPage title="Competitions">
          <LeagueDetails isPublic={isPublic} publicSlug={publicSlug} />
        </AppPage>
      )}
    </AllMetaseasons>
  )
}
