import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles'
import { Box, useBreakpointXs } from '@plvs/respawn/features/layout'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

interface StyleProps {
  buttonOpacity?: boolean
  isMobile?: boolean
}

const useStyles = makeStyles((theme) => ({
  outerBox: ({ isMobile }: StyleProps): CreateCSSProperties => ({
    width: isMobile ? '100%' : undefined,
    margin: isMobile ? 0 : undefined,
    paddingLeft: isMobile ? theme.spacing(2) : undefined,
    paddingRight: isMobile ? theme.spacing(2) : undefined,
    overflowY: 'auto',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  }),
  positive: {
    color: theme.palette.ColorTextSuccess,
    display: 'flex',
    alignItems: 'center',
  },
  gridItem: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  secondaryButton: {
    marginTop: 16,
    paddingTop: 12,
    paddingBottom: 12,
  },
  subtitle: {
    textAlign: 'center',
    color: theme.palette.ColorTextAlt2,
  },
  continueButton: ({ buttonOpacity }: StyleProps): CreateCSSProperties => ({
    opacity: buttonOpacity ? 0.5 : 1,
    paddingTop: 12,
    paddingBottom: 12,
  }),
}))

export interface QuestionnaireCardProps {
  onContinue?(): void
  onBack?(): void
  childWidth: number | string
  title: string
  subtitle?: string
  disableContinue?: boolean
  disableOnBack?: boolean
  isContinuing?: boolean
  buttonOpacity?: boolean
  marginBetweenChildAndButton?: number
  /**
   * Some views the top and bottom gets clipped because the
   * content gets bigger than the screen.  This height override
   * allows us to fix the content height so it becomes a scroll
   * pane.
   */
  mobileHeightOverride?: string
  subtitleMaxWidth?: number
  isMobileOverride?: boolean
  maxChildWidth?: number
}

export const QuestionnaireCard: React.FC<QuestionnaireCardProps> = ({
  children,
  onContinue,
  onBack,
  childWidth,
  title,
  subtitle,
  disableContinue,
  disableOnBack,
  isContinuing,
  buttonOpacity,
  marginBetweenChildAndButton = 4,
  mobileHeightOverride,
  subtitleMaxWidth = 544,
  isMobileOverride,
  maxChildWidth = childWidth,
}) => {
  const xsBreakpoint = useBreakpointXs()
  const isMobile = isMobileOverride ?? xsBreakpoint
  const classes = useStyles({
    isMobile,
    buttonOpacity,
  })

  return (
    <Box
      className={classes.outerBox}
      height={
        isMobile && mobileHeightOverride ? mobileHeightOverride : undefined
      }
      id="QuestionaireCard-outerbox"
      // This is a workaround for mobile layout issues.  Since this Box is vertically centered
      // the top of the page keeps getting pushed off the page in small screen sizes.  In mobile view
      // this will fix the height at 100% and turn this Box into a scrollable container.
      justifyContent="center"
      mx={2}
      my={2}
    >
      <Grid container justifyContent={isMobile ? 'flex-start' : 'center'}>
        <Grid className={classes.gridItem} item>
          <Box
            alignItems="center"
            display="flex"
            flex={1}
            flexDirection="column"
            my={2}
          >
            <NxTypography align="center" variant="display3">
              {title}
            </NxTypography>
            {subtitle && (
              <Box maxWidth={subtitleMaxWidth} my={2}>
                <NxTypography
                  align="center"
                  className={classes.subtitle}
                  data-testid="onboard-subtitle"
                  variant="body1"
                >
                  {subtitle}
                </NxTypography>
              </Box>
            )}
          </Box>
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            maxWidth={isMobile ? '100%' : maxChildWidth}
            width={isMobile ? '100%' : childWidth}
          >
            {children}
            <Box
              mb={isMobile ? 2 : 3}
              mt={marginBetweenChildAndButton}
              width={isMobile ? '100%' : 400}
            >
              {onContinue && (
                <NxButton
                  className={classes.continueButton}
                  data-testid="continueButton"
                  disabled={disableContinue}
                  fullWidth
                  label="Continue"
                  loading={isContinuing}
                  onClick={onContinue}
                  type="submit"
                  variant="primary"
                />
              )}
              {onBack && (
                <NxButton
                  className={classes.secondaryButton}
                  data-testid="backButton"
                  disabled={disableOnBack}
                  fullWidth
                  label="Go Back"
                  onClick={onBack}
                  variant="secondary"
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
