import React from 'react'

import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import ScheduleWrapper from './ScheduleWrapper'

interface ScheduleContainerProps {
  path: string
}

export const ScheduleContainer = ({
  path: basePath,
}: ScheduleContainerProps): React.ReactElement => {
  const { teamIds, loading } = useUserIdentityFn()
  const { metaseason } = useSchoolLeagueInfoContext()

  return (
    <ScheduleWrapper
      isLoading={Boolean(loading)}
      metaseason={metaseason}
      path={basePath}
      teamIds={teamIds}
    />
  )
}
