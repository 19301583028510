import React, { useState } from 'react'
import { Card, CardActions, Collapse, makeStyles } from '@material-ui/core'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { Box } from '@plvs/respawn/features/layout'
import { Colors } from '@plvs/rally/themes'
import { NxTypography } from '@playvs-inc/nexus-components'
import { ExpandCardButton } from './ExpandCardButton'
import { minHeightOptions } from './shared'

interface Props {
  expandText?: string
  hideButton?: boolean
  buttonPosition?: 'flex-start' | 'center' | 'flex-end'
  previewContent?: React.ReactNode
  expandedContent?: React.ReactNode
  title?: string
  color?: string
  showRandomImage?: boolean
  imageSrc?: string
  startExpanded?: boolean
  imageSize?: string
  mb?: number
  mt?: number
  buttonUseTopPosition?: boolean
}

const useStyles = makeStyles((theme) => ({
  cardActions: {
    padding: theme.spacing(3),
  },
  cardActionsTopPosition: {
    padding: theme.spacing(3),
    width: 'auto',
  },
}))

export const ExpandableCard = ({
  color = Colors.Black,
  expandedContent,
  expandText = 'View All',
  hideButton,
  buttonPosition = 'flex-end',
  buttonUseTopPosition,
  previewContent,
  title,
  showRandomImage = false,
  imageSrc = '',
  imageSize = 'sm',
  startExpanded = false,
  mb = 3,
  mt = 1,
}: Props): React.ReactElement => {
  const [expanded, setExpanded] = useState(startExpanded)
  const toggleExpanded = (): void => setExpanded(!expanded)

  const { cardActions, cardActionsTopPosition } = useStyles()

  const renderActionButton = !hideButton && expandedContent && (
    <CardActions
      className={buttonUseTopPosition ? cardActionsTopPosition : cardActions}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent={buttonPosition}
        width="100%"
      >
        <ExpandCardButton
          collapsedText={expandText}
          expanded={expanded}
          expandedText="Show Less"
          onClick={toggleExpanded}
        />
      </Box>
    </CardActions>
  )

  return (
    <Box color={color} mb={mb} mt={mt}>
      <Card>
        {(!!imageSrc || showRandomImage) && (
          <MatchImageCard
            borderRadius="0"
            boxShadow="none"
            mb={1}
            minHeight={minHeightOptions[imageSize]}
            mt={-4}
            py={2}
            showOverlay={false}
            src={imageSrc}
          />
        )}
        {title && (
          <Box pt={1} px={2}>
            <NxTypography gutterBottom variant="overline">
              {title}
            </NxTypography>
          </Box>
        )}
        {buttonUseTopPosition ? (
          <Box display="flex" justifyContent="space-between">
            {previewContent}
            {renderActionButton}
          </Box>
        ) : (
          previewContent
        )}
        {expandedContent && (
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {expandedContent}
          </Collapse>
        )}
        {!buttonUseTopPosition && renderActionButton}
      </Card>
    </Box>
  )
}
