import { GetEsportsForNexusQuery } from '@plvs/graphql/generated/graphql'
import { EsportSlug } from '@plvs/graphql'

type EsportPalette = {
  slug: EsportSlug
  name: string
  palette: {
    1: string
    2: string
    3: string
    4: string
    5: string
    6: string
    7: string
    8: string
    baseColor: string
    gradientStart: string
    gradientEnd: string
  }
}

export function normalizeNexusEsportsData(
  esports: GetEsportsForNexusQuery['esports'] = []
): Record<string, EsportPalette> {
  return esports
    .map((esport) => ({
      slug: esport.slug,
      name: esport.name ?? '',
      palette: {
        1: esport.colors?.color1 ?? '',
        2: esport.colors?.color2 ?? '',
        3: esport.colors?.color3 ?? '',
        4: esport.colors?.color4 ?? '',
        5: esport.colors?.color5 ?? '',
        6: esport.colors?.color6 ?? '',
        7: esport.colors?.gradientStart ?? '',
        8: esport.colors?.gradientEnd ?? '',
        baseColor: esport.colors?.mainColor ?? '',
        gradientStart: esport.colors?.gradientStart ?? '',
        gradientEnd: esport.colors?.gradientEnd ?? '',
      },
    }))
    .reduce((acc, esport) => {
      acc[esport.slug] = esport
      return acc
    }, {})
}
