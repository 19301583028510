import { Box, makeStyles } from '@material-ui/core'
import {
  refetchGetUserTasksQuery,
  TaskState,
  useHandbookViewedMutation,
} from '@plvs/graphql/generated'
import React, { useState } from 'react'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import {
  NxTextLink,
  NxTypography,
  NxTaskCard,
} from '@playvs-inc/nexus-components'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { IntercomArticleMappings, Path } from '@plvs/const'

import { useNavigate } from 'react-router-dom'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { TaskCtaButton } from '../components/TaskCtaButton'

const useStyles = makeStyles((theme) => ({
  ctaLink: {
    color: theme.palette.ColorTextLink,
    textDecoration: 'none',
    fontWeight: 'lighter',
  },
  inline: {
    display: 'inline-flex',
  },
}))

export const DownloadHandbookTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isMobile: boolean
  index: number
}> = ({ task, isMobile, index }) => {
  const { userId } = useUserIdentityFn()
  const classes = useStyles()

  const navigate = useNavigate()

  const [completeTask] = useHandbookViewedMutation({
    refetchQueries: [
      { ...refetchGetUserTasksQuery(), variables: { id: userId } },
    ],
  })
  const [completedState, setCompletedState] = useState<boolean>(
    task.state === TaskState.Completed
  )

  const handleDownloadHandbook = async (): Promise<void> => {
    showIntercomArticle(IntercomArticleMappings.coachHandbook)
    completeTask()
    setCompletedState(true)
  }

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography data-cy="download-coach-handbook" variant="h4">
              Download Coach Handbook
            </NxTypography>
          </Box>
          <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
            You can also find it in the{' '}
            <NxTextLink
              className={classes.inline}
              label="Resources"
              onClick={(): void => {
                navigate(Path.Resources)
              }}
            />{' '}
            page at anytime.
          </NxTypography>
        </>
      }
      taskCta={
        <TaskCtaButton
          buttonText="Download"
          completed={completedState}
          onClick={handleDownloadHandbook}
          task={task}
        />
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <NxSpot
            domain="resources"
            height={64}
            size="small"
            variant="primary"
            width={64}
          />
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
