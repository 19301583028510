import { Box, Grid, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useAutoskipQuery } from '@plvs/utils'
import {
  useCompetitionIntervalsForOrgQuery,
  useGetHelpLinksQuery,
} from '@plvs/graphql/generated'
import { Hidden, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import dayjs from 'dayjs'
import tz from 'dayjs/plugin/timezone'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { IntercomArticleMappings, PLAYVS_HELP_ARTICLE_REGEX } from '@plvs/const'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import {
  NxEsportAvatar,
  NxTab,
  NxTabs,
  NxButton,
  NxTextLinkExternal,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { EsportSlug } from '@plvs/graphql/types'
import { buildEsportsInfoDictionary } from '../../../../rally/src/containers/resources/esportResourcers.helper'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  infoContainer: {
    width: '100%',
  },
  esportInfoBox: {
    display: 'flex',
    height: '255px',
    minWidth: '239px',
    width: '100%',
    background: theme.palette.ColorBackgroundBase,
    boxShadow: theme.shadows[1],
    borderRadius: theme.mixins.cornerRadius['border-radius-10'],
    padding: theme.spacing(3),
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
    },
  },
  ctaButton: {
    alignSelf: 'flex-end',
    display: 'flex',
    marginTop: 'auto',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      alignSelf: 'center',
      width: '100%',
      justifyContent: 'center',
    },
  },
  avatarTab: {
    width: '100%',
    height: 'auto',
  },
}))

export const EsportsResourcesContainer: React.FC<{
  allAvailableEsports: string[]
}> = ({ allAvailableEsports }) => {
  const { data } = useGetHelpLinksQuery()
  const classes = useStyles()

  const esports: any = buildEsportsInfoDictionary()

  const initialEsportSlug = Object.keys(esports).find((esportName) =>
    allAvailableEsports.some((esportSlug) => esportSlug === esportName)
  )

  const [tab, setTab] = useState<string>(initialEsportSlug ?? '')
  const onTabChange = (_: any, esport: string): void => setTab(esport)

  const handleLinkInNewTab = (link: string): void => {
    const articleId = PLAYVS_HELP_ARTICLE_REGEX.exec(link)?.groups?.id
    if (articleId) {
      const articleIdToShow =
        articleId !== IntercomArticleMappings.pricingFaq.toString()
          ? articleId
          : data?.getLinks?.find((dataLink) => dataLink.slug === 'pricing-faqs')
              ?.url ?? IntercomArticleMappings.pricingFaq
      showIntercomArticle(articleIdToShow)
    }

    if (!articleId) {
      window.open(link, '_blank', 'noreferrer')
      window?.focus()
    }
  }

  const { orgId } = useUserIdentityFn()
  const { data: compData, loading: competitionIntervalsLoading } =
    useAutoskipQuery(useCompetitionIntervalsForOrgQuery, {
      variables: { schoolId: orgId ?? '' },
      skip: !orgId,
    })
  const intervalData = compData?.competitionIntervalsForOrganization.results
  const results = (intervalData ?? [])
    .slice()
    .sort(
      (a, b) =>
        dayjs(a?.interval?.startsAt).unix() - dayjs(b?.interval.startsAt).unix()
    )
    .map((sortedResult, i) => {
      const interval = {
        ...sortedResult.interval,
        position: i,
      }
      return {
        interval,
      }
    })

  const now = dayjs()

  const competitionIntervals = results.map((result) => result.interval)
  const competitionInterval =
    competitionIntervals && competitionIntervals.length > 0
      ? competitionIntervals.find((ci) =>
          now.isBetween(ci?.startsAt, ci?.endsAt, null, '[]')
        ) || competitionIntervals[competitionIntervals.length - 1]
      : null

  return (
    <WaitTillLoaded
      loading={competitionIntervalsLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      <Box>
        <Box mb={2}>
          <NxTypography variant="h2">Esport Information</NxTypography>
        </Box>
        <Box mb={3}>
          <NxTabs onChange={onTabChange} value={tab}>
            {Object.keys(esports)
              .filter((esportName) =>
                allAvailableEsports.some(
                  (esportSlug) => esportSlug === esportName
                )
              )
              .map((esport) => {
                return (
                  <NxTab
                    key={esport}
                    label={
                      <NxEsportAvatar
                        esport={esport as EsportSlug}
                        iconHeight="38px"
                        iconWidth="38px"
                      />
                    }
                    value={esport}
                  />
                )
              })}
          </NxTabs>
        </Box>
        <Grid container spacing={2}>
          <Grid className={classes.infoContainer} item lg={4} sm={12}>
            <Box className={classes.esportInfoBox}>
              <Hidden mdDown>{esports[tab].icon}</Hidden>
              <Box display="flex" flexDirection="row">
                <Hidden lgUp>
                  <Box mr={2}>{esports[tab].icon}</Box>
                </Hidden>
                <Box>
                  <Box mb={1}>
                    <NxTypography variant="h4">
                      What is {esports[tab].name}?
                    </NxTypography>
                  </Box>
                  <Box mb={1}>
                    <NxTypography variant="body1">
                      Learn about the basics of gameplay, strategies and more.
                    </NxTypography>
                  </Box>
                  {esports[tab].whatIsOther ? (
                    <Box mb={1}>
                      <NxTextLinkExternal
                        href={esports[tab].whatIsOther}
                        label={esports[tab].whatIsOtherLabel}
                        rel="noopener"
                        target="_blank"
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box className={classes.ctaButton}>
                <NxButton
                  fullWidth
                  label="Read Overview"
                  onClick={(): void => handleLinkInNewTab(esports[tab].whatIs)}
                  variant="secondary"
                />
              </Box>
            </Box>
          </Grid>

          <Grid className={classes.infoContainer} item lg={4} sm={12}>
            <Box className={classes.esportInfoBox}>
              <Hidden mdDown>
                <NxSpot
                  domain="resources"
                  height="40px"
                  size="small"
                  variant="primary"
                  width="40px"
                />
              </Hidden>
              <Box display="flex" flexDirection="row">
                <Hidden lgUp>
                  <Box mr={2}>
                    <NxSpot
                      domain="resources"
                      height="40px"
                      size="small"
                      variant="primary"
                      width="40px"
                    />
                  </Box>
                </Hidden>
                <Box>
                  <Box mb={1}>
                    <NxTypography variant="h4">
                      {esports[tab].name} Rulebook
                    </NxTypography>
                  </Box>
                  <Box mb={1}>
                    <NxTypography variant="body1">
                      Review the official {esports[tab].name} rules
                      {competitionInterval
                        ? ` for the ${competitionInterval.name}.`
                        : '.'}
                    </NxTypography>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.ctaButton}>
                <NxButton
                  fullWidth
                  label="View Rulebook"
                  onClick={(): void =>
                    handleLinkInNewTab(esports[tab].rulebook)
                  }
                  variant="secondary"
                />
              </Box>
            </Box>
          </Grid>

          <Grid className={classes.infoContainer} item lg={4} sm={12}>
            <Box className={classes.esportInfoBox}>
              <Hidden mdDown>
                <NxSpot
                  domain="arena"
                  height="40px"
                  size="small"
                  variant="primary"
                  width="40px"
                />
              </Hidden>
              <Box display="flex" flexDirection="row">
                <Hidden lgUp>
                  <Box mr={2}>
                    <NxSpot
                      domain="arena"
                      height="40px"
                      size="small"
                      variant="primary"
                      width="40px"
                    />
                  </Box>
                </Hidden>
                <Box>
                  <Box mb={1}>
                    <NxTypography variant="h4">Match Instructions</NxTypography>
                  </Box>
                  <Box mb={1}>
                    <NxTypography variant="body1">
                      Read over match expectations and set up instructions.
                    </NxTypography>
                  </Box>
                  {esports[tab].protocolOther ? (
                    <Box mb={1}>
                      <NxTextLinkExternal
                        href={esports[tab].protocolOther}
                        label={esports[tab].protocolOtherLabel}
                        rel="noopener"
                        target="_blank"
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box className={classes.ctaButton}>
                <NxButton
                  fullWidth
                  label="Read Overview"
                  onClick={(): void =>
                    handleLinkInNewTab(esports[tab].protocol)
                  }
                  variant="secondary"
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </WaitTillLoaded>
  )
}
