import React from 'react'
import { TaskState } from '@plvs/graphql/generated'
import { Box } from '@material-ui/core'
import { Path } from '@plvs/const'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { NxTypography, NxTaskCard } from '@playvs-inc/nexus-components'

import { useNavigate } from 'react-router-dom'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { TaskCtaButton } from '../components/TaskCtaButton'

export const EnrollTeamTaskCard: React.FC<{
  task: { state: string; id: string; type: string }
  isMobile: boolean
  index: number
}> = ({ task, isMobile, index }) => {
  const { promotedMetaseason } = useSchoolLeagueInfoContext()
  const metaseasonId =
    (promotedMetaseason?.id && `/${promotedMetaseason?.id}`) ?? ''
  const navigate = useNavigate()
  const onNavigateToEnrollTeam = (): void => {
    // This will go to the team management page if the teams are not enrolled.
    navigate(`${Path.Enrollment}${metaseasonId}`)
  }

  return (
    <NxTaskCard
      taskBody={
        <>
          <Box mb={1}>
            <NxTypography data-cy="download-coach-handbook" variant="h4">
              Enroll Team
            </NxTypography>
          </Box>
          <NxTypography variant={isMobile ? 'body3' : 'subtitle1'}>
            Enroll your teams and get ready to compete
          </NxTypography>
        </>
      }
      taskCta={
        <TaskCtaButton
          buttonText="Enroll"
          completed={task.state === TaskState.Completed}
          disabled={task.state === TaskState.Disabled}
          onClick={onNavigateToEnrollTeam}
          task={task}
          tooltipText={
            task.state === TaskState.Disabled ? (
              <>
                <NxTypography>
                  In order to enroll, your school must:
                </NxTypography>
                <NxTypography>- Have a team created</NxTypography>
                <NxTypography>
                  - Esport&apos;s season enrollment window is open
                </NxTypography>
              </>
            ) : undefined
          }
        />
      }
      taskHeader={
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <NxSpot
            domain="enrollment"
            height={64}
            size="small"
            variant="primary"
            width={64}
          />
          <NxTypography variant="overline">TASK {index}</NxTypography>
        </Box>
      }
    />
  )
}
