/* istanbul ignore file */
// This is a top level routing file and context provider. Pages will be unit tested individually.

import { useGetMyAccountDetailsQuery } from '@plvs/graphql/generated/graphql'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { PenaltyBox } from '@plvs/respawn/features/match/PenaltyBox'
import { usePromptOnWindowUnload } from '@plvs/utils'
import React, { useEffect } from 'react'
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom'

import { OnboardUserName } from '@plvs/rally/containers/onboard/v2/OnboardUserName'
import { Path } from '@plvs/const'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { AuthRenderControllerProvider } from '@plvs/respawn/renderController'
import {
  OnboardingContext,
  useOnboardingContextProvider,
} from '@plvs/respawn/features/onboard/OnboardingContext'
import { ParentOnboardVerification } from './ParentOnboardVerification'

export const ParentOnboard: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  // Query Hooks
  const {
    data: loggedInUserData,
    loading: loggedInUserDataLoading,
    refetch: refetchAccountDetails,
  } = useGetMyAccountDetailsQuery()

  const { assign, data: contextValues } = useOnboardingContextProvider()

  // Side Effects

  // Prompt user if they abruptly exit onboarding by closing the browser.
  // exclude final pages in the onboarding flow.
  // adding condition for schools not in CIF to go back to normal logo without prompt
  const doPrompt = !pathname.includes('/parent-verification')
  usePromptOnWindowUnload(doPrompt)

  // Refresh account details as we go through the flow in the event user forcefully
  // navigates off the flow.  This will ensure non-onboarding pages will receive the
  // user with most up to date account props.
  useEffect(() => {
    refetchAccountDetails()
  }, [])

  // Using this prompt till more support for react-router v6 is available
  window.onpopstate = (): void => {
    if (!!contextValues.onboardingComplete && !pathname.includes('invite-')) {
      // eslint-disable-next-line
      const result = window.confirm(
        'Your account has been created so you will be returning to the signup screen. Are you sure you want to go back?'
      )
      if (result) {
        navigate(Path.Registration)
      }
    }
  }

  return (
    <PenaltyBox>
      <AuthRenderControllerProvider>
        <WaitTillLoaded loading={loggedInUserDataLoading}>
          <OnboardingContext.Provider value={{ assign, data: contextValues }}>
            <ApmRoutes>
              <Route element={<Navigate to="about" />} path="/" />

              <Route
                element={<OnboardUserName isParentSignup />}
                path="about"
              />

              <Route
                element={
                  <ParentOnboardVerification
                    email={loggedInUserData?.me?.email ?? ''}
                  />
                }
                path="parent-verification"
              />
            </ApmRoutes>
          </OnboardingContext.Provider>
        </WaitTillLoaded>
      </AuthRenderControllerProvider>
    </PenaltyBox>
  )
}
