/* eslint-disable func-names */
/* eslint-disable no-useless-escape */
/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* istanbul ignore file */

// Dummy pub key since this is just the FE pushing notifications to FE
// If we want to incorporate BE pushes to the FE, we'll need to generate
// a real key
// TODO - in that case, find a better way to store this per environment
const applicationServerPublicKey =
  'BAHiTp46F8kLyo1eeaTKVoLCPi4Txo_K3aGfZta3oumTR4Vk6zuidHJJHGdr7mJiyYzPNfEP10fBE4r5QDMKMCM'

function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

let swRegistration: ServiceWorkerRegistration | null = null
let isSubscribed = false

export function pushMessage(
  title: string,
  options?: NotificationOptions
): void {
  if (swRegistration && isSubscribed) {
    swRegistration?.showNotification(title, options).catch(function (error) {
      console.error('Service Worker Push Error', error)
    })
  }
}

export function initializePushMessaging(): void {
  if ('serviceWorker' in navigator && 'PushManager' in window) {
    console.log('Service Worker and Push is supported')

    navigator.serviceWorker
      .register('/push-service-worker.js')
      .then(function (swReg) {
        console.log('Service Worker is registered', swReg)

        swRegistration = swReg
        checkSubscription()
      })
      .catch(function (error) {
        console.error('Service Worker Error', error)
      })
  } else {
    console.warn('Push messaging is not supported')
  }
}

function subscribeUser() {
  const applicationServerKey = urlB64ToUint8Array(applicationServerPublicKey)
  swRegistration?.pushManager
    .subscribe({
      userVisibleOnly: true,
      applicationServerKey,
    })
    .then(function () {
      isSubscribed = true
    })
    .catch(function (err) {
      console.log('Failed to subscribe the user: ', err)
    })
}

function unsubscribeUser() {
  swRegistration?.pushManager
    .getSubscription()
    .then(function (subscription) {
      if (subscription) {
        return subscription.unsubscribe()
      }
    })
    .catch(function (error) {
      console.log('Error unsubscribing', error)
    })
    .then(function () {
      console.log('User is unsubscribed.')
      isSubscribed = false
    })
}

function checkSubscription() {
  if (isSubscribed) {
    unsubscribeUser()
  } else {
    subscribeUser()
  }
  swRegistration?.pushManager.getSubscription().then(function (subscription) {
    isSubscribed = !(subscription === null)

    if (isSubscribed) {
      console.log('User IS subscribed.')
    } else {
      console.log('User is NOT subscribed.')
    }
  })
}
