import React from 'react'
import { Box, useTheme, Avatar } from '@material-ui/core'
import { isEmpty } from 'ramda'

import { NxEmote, FB, NxTypography } from '@playvs-inc/nexus-components'
import { Stats } from '@playvs-inc/nexus-icons'

import { useRosterContext } from '../../RosterProvider'
import { StatsAvatarWrapper } from './RosterRowFilledStats.helpers'
import { useStyles } from './RosterRowFilledStats.styles'

export interface RosterRowFilledStatsSplatoonProps {
  memberId: string
}

export const RosterRowFilledStatsSplatoon: React.FC<
  RosterRowFilledStatsSplatoonProps
> = ({ memberId }) => {
  const theme = useTheme()
  const styles = useStyles()

  const { statsByPlayer: splatoonStatsByPlayer } = useRosterContext()

  const splatoonStats = splatoonStatsByPlayer[memberId]
  const showSplatoonStats =
    splatoonStats?.splatoonStats && !isEmpty(splatoonStats?.splatoonStats)

  const splatoonStatsAvatar = (
    { name, winRate, played },
    options?: { avatar: React.ReactElement; title: string }
  ): React.ReactNode => {
    const showEasterEgg = winRate >= 80 && played > 3
    const initials = name.split(' ').filter((group) => !group.includes('&'))

    return (
      <StatsAvatarWrapper
        tooltipTitle={
          <Box alignItems="center" display="flex" gridGap={theme.spacing(1)}>
            <Box>
              {options?.title && <Box>{options.title}</Box>}
              <Box>{name}</Box>
              <Box>
                {played} {played > 1 ? 'Games' : 'Game'}
              </Box>
              <Box>{winRate}% Win Rate</Box>
            </Box>

            {showEasterEgg && (
              <NxEmote
                emoteKey={FB.Shock}
                height={30}
                shouldAnimate
                width={30}
              />
            )}
          </Box>
        }
      >
        <Avatar className={styles.statsAvatar} data-cy="splatoon-stat-icon">
          <NxTypography variant="body4">
            {initials[0][0]}
            {initials[1]?.[0]}
          </NxTypography>
        </Avatar>
      </StatsAvatarWrapper>
    )
  }

  return showSplatoonStats ? (
    <Box
      alignItems="center"
      alignSelf="flex-start"
      display="flex"
      gridGap={theme.spacing(0.5)}
    >
      {splatoonStats?.splatoonStats?.mostPlayedStages[0] &&
        splatoonStatsAvatar(splatoonStats?.splatoonStats.mostPlayedStages[0], {
          avatar: <Stats />,
          title: 'Most Played Stage',
        })}
      {splatoonStats?.splatoonStats?.mostPlayedCharacters?.map(
        (player) => player && splatoonStatsAvatar(player)
      )}
    </Box>
  ) : (
    <></>
  )
}
