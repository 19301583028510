import React, { useState } from 'react'
import { Card, CardContent, Grid, makeStyles } from '@material-ui/core'
import { Box } from '@plvs/respawn/features/layout'
import { NxTypography, NxButton } from '@playvs-inc/nexus-components'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { ScheduleConsultationModal } from './ConsultationModal/ScheduleConsultationModal'

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '18rem',
  },
  textContainer: {
    maxWidth: '69%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
      marginLeft: 0,
      textAlign: 'center',
      alignItems: 'center',
    },
  },
  scheduleButton: {
    width: 'max-content',
    marginTop: theme.spacing(1),
  },
  contentContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  ctaContent: {
    display: 'flex',
  },
  spotContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  spot: {
    justifyContent: 'center',
  },
}))

export const DashboardConsultationComponent: React.FC<{
  link?: string
}> = ({ link }) => {
  const classes = useStyles()

  const [isConsultationOpen, setIsConsultationOpen] = useState(false)

  const handleOnOpenConsultation = (): void => {
    setIsConsultationOpen(true)
  }
  const handleOnCloseConsultation = (): void => {
    setIsConsultationOpen(false)
  }

  return (
    <Box className={classes.container}>
      <Card>
        <CardContent>
          <Grid className={classes.contentContainer} container>
            <Grid className={classes.spotContainer} item md={3} xs={12}>
              <Box className={classes.spot}>
                <NxSpot
                  domain="schedule"
                  height={200}
                  size="large"
                  variant="primary"
                  width={200}
                />
              </Box>
            </Grid>
            <Grid className={classes.ctaContent} item md={9} xs={12}>
              <Box
                className={classes.textContainer}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                maxWidth="80%"
                mb={2}
                ml={12}
                mt={2}
              >
                <NxTypography variant="h1">
                  Let&apos;s get your school ready to compete.
                </NxTypography>
                <NxTypography variant="h1">Schedule a call today!</NxTypography>
                <NxTypography variant="body1">
                  Contact your PlayVS representative today and we&apos;ll help
                  answer any questions you may have about competing in the
                  upcoming season.
                </NxTypography>
                <NxButton
                  className={classes.scheduleButton}
                  label="Schedule A Consultation"
                  onClick={handleOnOpenConsultation}
                  variant="primary"
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ScheduleConsultationModal
        handleClose={handleOnCloseConsultation}
        link={link}
        open={isConsultationOpen}
      />
    </Box>
  )
}
