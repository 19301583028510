import React from 'react'
import { noop } from 'ramda-adjunct'

import { Substitute, Trashcan } from '@playvs-inc/nexus-icons'

import { SwapCoachModal } from '@plvs/respawn/features/manage-teams/SwapCoachModal'
import { RosterCardMenuRowCoachItems } from '@plvs/respawn/features/roster/teamManagement/rosterMenuRequirements'
import {
  ContentContextPermissions,
  GrantedRosterMenuPermissions,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import { RemoveCoachModal } from '@plvs/respawn/features/manage-teams/RemoveCoachModal'
import { TeamCoach, UserRole } from '../../RosterProvider.types'
import { ActionItemProps, RosterRowActions } from './RosterRowActions'

export interface RosterCardRowBenchMenuProps {
  member: TeamCoach
  teamId: string
  schoolId: string
  getPermissions(
    memberRoles: UserRole[],
    contentContext: ContentContextPermissions,
    isSelf?: boolean
  ): GrantedRosterMenuPermissions
  onMutationSuccess(): Promise<void>
  coaches: TeamCoach[]
  userId: string
}

export const RosterRowFilledCoachMenu: React.FC<
  RosterCardRowBenchMenuProps
> = ({
  teamId,
  schoolId,
  member,
  getPermissions,
  onMutationSuccess,
  coaches,
  userId,
}) => {
  const isSelf = member.id === userId
  const allowedAction = getPermissions(
    member.directRoles ?? [],
    coaches.length > 1
      ? ContentContextPermissions.MULTIPLE_COACH_ITEMS_MENU
      : ContentContextPermissions.COACH_ITEMS_MENU,
    isSelf
  ) as RosterCardMenuRowCoachItems[]

  const rosterCardRowMenuItemsMap: Record<
    RosterCardMenuRowCoachItems,
    ActionItemProps
  > = {
    [RosterCardMenuRowCoachItems.RemoveCoach]: {
      label: 'Remove Coach',
      icon: <Trashcan />,
      dialogProps: {
        children: (
          <RemoveCoachModal
            coach={member}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            teamId={teamId}
          />
        ),
      },
      key: 'Remove Coach',
    },
    [RosterCardMenuRowCoachItems.SwapCoach]: {
      label: 'Replace Coach',
      icon: <Substitute />,
      dialogProps: {
        children: (
          <SwapCoachModal
            allTeamCoaches={coaches}
            coach={member}
            onClose={noop}
            onMutationSuccess={onMutationSuccess}
            schoolId={schoolId}
            teamId={teamId}
          />
        ),
      },
      key: 'Replace Coach',
    },
  }

  const menuItems = allowedAction.map((rosterMenuItem) => {
    return rosterCardRowMenuItemsMap[rosterMenuItem]
  })

  return <RosterRowActions actionItemProps={menuItems} />
}
