import React, { useCallback } from 'react'
import { noop } from 'ramda-adjunct'

import { determineAuthRender } from './utils/authRenderController.helpers'
import {
  UseAuthRenderControllerContextReturn,
  AuthRenderControllerState,
} from './AuthRenderController.types'

type AuthRenderControllerContext = UseAuthRenderControllerContextReturn & {
  getRenderControllerState: () => AuthRenderControllerState
  setRenderControllerStateFn: (
    updatedStateValues: Partial<AuthRenderControllerState>
  ) => void
}

export const useAuthRenderControllerContext =
  (): AuthRenderControllerContext => {
    return {
      auth: {
        getAuthComponentsToRender: determineAuthRender,
      },
      getRenderControllerState: () => ({
        auth: {
          shouldRenderUsernameField: false,
          shouldRenderDOBField: false,
          shouldRenderOAuth: false,
          shouldRenderAgeGate: false,
          shouldRenderParentFields: false,
          shouldRedirectToParentSignup: false,
          shouldShowFullDemographicForm: false,
          shouldShowPlayerSignup: false,
        },
      }),
      setRenderControllerStateFn: noop,
    }
  }

export const authRenderControllerContext =
  React.createContext<AuthRenderControllerContext>({
    auth: {
      getAuthComponentsToRender: () => ({
        shouldRenderUsernameField: false,
        shouldRenderDOBField: false,
        shouldRenderOAuth: false,
        shouldRenderAgeGate: false,
        shouldRenderParentFields: false,
        shouldRedirectToParentSignup: false,
        shouldShowFullDemographicForm: false,
        shouldShowPlayerSignup: false,
      }),
    },
    getRenderControllerState: () => ({
      auth: {
        shouldRenderUsernameField: false,
        shouldRenderDOBField: false,
        shouldRenderOAuth: false,
        shouldRenderAgeGate: false,
        shouldRenderParentFields: false,
        shouldRedirectToParentSignup: false,
        shouldShowFullDemographicForm: false,
        shouldShowPlayerSignup: false,
      },
    }),
    setRenderControllerStateFn: noop,
  })

export const AuthRenderControllerProvider: React.FC = ({ children }) => {
  const [renderControllerState, setRenderControllerState] =
    React.useState<AuthRenderControllerState>({
      auth: {
        shouldRenderUsernameField: false,
        shouldRenderDOBField: false,
        shouldRenderOAuth: false,
        shouldRenderAgeGate: false,
        shouldRenderParentFields: false,
        shouldRedirectToParentSignup: false,
        shouldShowFullDemographicForm: false,
        shouldShowPlayerSignup: false,
      },
    })

  const getRenderControllerState =
    useCallback((): AuthRenderControllerState => {
      return renderControllerState
    }, [renderControllerState])

  const setRenderControllerStateFn = useCallback(
    (newState: Partial<AuthRenderControllerState>): void => {
      setRenderControllerState((prevState) => ({
        ...prevState,
        ...newState,
      }))
    },
    []
  )

  const values = useAuthRenderControllerContext()
  return (
    <authRenderControllerContext.Provider
      value={{
        ...values,
        getRenderControllerState,
        setRenderControllerStateFn,
      }}
    >
      {children}
    </authRenderControllerContext.Provider>
  )
}

export const useAuthRenderControllerState = (): {
  getRenderControllerState: () => AuthRenderControllerState
  setRenderControllerStateFn: (
    updatedStateValues: Partial<AuthRenderControllerState>
  ) => void
} => {
  const { getRenderControllerState, setRenderControllerStateFn } =
    React.useContext(authRenderControllerContext)

  return { getRenderControllerState, setRenderControllerStateFn }
}
