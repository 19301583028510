import React from 'react'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'

export const NotificationSpotWrapper: React.FC = () => {
  return (
    <NxSpot
      domain="notification"
      height={64}
      size="small"
      variant="primary"
      width={64}
    />
  )
}
