import { yupResolver } from '@hookform/resolvers'
import { makeStyles } from '@material-ui/core'
import { SchoolType, UserRoleName, useGetSchoolTypeQuery } from '@plvs/graphql'
import { Callout } from '@plvs/rally/components/callout'
import { Box } from '@plvs/respawn/features/layout/Box'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { getGradYearOptions, useAutoskipQuery } from '@plvs/utils'
import dayjs from 'dayjs'
import React, { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import {
  NxRadioButtonGroup,
  NxSelectOption,
  NxTooltip,
  NxTypography,
} from '@playvs-inc/nexus-components'
import { NxSelectController } from '@plvs/respawn/features/form/NxSelectController'
import { InfoOutlined } from '@playvs-inc/nexus-icons'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'
import { Over18MoreDetails, Under18MoreDetails, getInitialRole } from './utils'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  questionText: {
    marginBottom: theme.spacing(3),
  },
  schoolTypeRadioGroup: {
    marginBottom: theme.spacing(5),
    '& p': {
      paddingLeft: 0,
    },
    '& span': {
      paddingLeft: 0,
    },
  },
  gradYear: {
    marginBottom: theme.spacing(5),
  },
  gradYearSelect: {
    backgroundColor: theme.palette.common.white,
  },
}))

export const TellUsMoreMiddleSchool: React.FC = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { assign, data: onboardingContext } = useOnboardingContext()
  const { data: schoolTypeData } = useAutoskipQuery(useGetSchoolTypeQuery, {
    variables: {
      schoolId: (onboardingContext.schoolId as string) || '',
    },
  })

  const isUnder18 =
    dayjs().diff(dayjs(onboardingContext.dateOfBirth as Date), 'years') < 18

  const initialRole = getInitialRole(onboardingContext, isUnder18)

  const gradYearOptions = useMemo(() => getGradYearOptions(), [])

  useEffect(() => {
    if (
      schoolTypeData &&
      !onboardingContext.schoolType &&
      !onboardingContext.roleName
    ) {
      assign({ schoolType: schoolTypeData?.school?.type })
    }
  }, [schoolTypeData, onboardingContext.schoolType, onboardingContext.roleName])

  // Form handlers

  const { errors, handleSubmit, clearErrors, control, watch } = useForm({
    resolver: isUnder18
      ? yupResolver(Under18MoreDetails)
      : yupResolver(Over18MoreDetails),
  })

  const role = watch('role') || initialRole
  const graduationYear =
    watch('graduationYear') || onboardingContext.graduationYear
  const schoolType = watch('schoolType') || onboardingContext.schoolType

  // Computed Values
  const showGradYear = role === UserRoleName.Student
  const showNASEFUx = onboardingContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const canContinue =
    (role === UserRoleName.Fac ||
      (role === UserRoleName.Student && graduationYear)) &&
    schoolType

  const onFormUpdate = (): void => {
    clearErrors()
  }

  const submitTellUsMoreDetails = handleSubmit(
    async (input): Promise<void> => {
      const selectedRole = input?.role ?? role

      const gradYear =
        selectedRole === UserRoleName.Student ? +graduationYear : undefined

      assign({
        role: selectedRole,
        schoolType: input.schoolType,
        graduationYear: gradYear,
      })

      if (selectedRole === UserRoleName.Fac) {
        navigate(`${Path.SpawnPoint}/faculty-school${showNASEFUx}`)
      } else {
        navigate(
          `${Path.SpawnPoint}${Path.StudentEsportInterests}${showNASEFUx}`
        )
      }
    }
  )

  const SchoolTypeRadioGroup = (
    <Controller
      control={control}
      defaultValue={onboardingContext.schoolType as string}
      name="schoolType"
      render={({ name, onChange }): React.ReactElement => (
        <NxRadioButtonGroup
          className={classes.schoolTypeRadioGroup}
          data-cy="onboard-school-type"
          data-testid="onboard-school-type"
          defaultValue={onboardingContext.schoolType as string}
          name={name}
          onChange={(e): void => {
            onChange(e)
          }}
          radioOptions={[
            { label: 'High School', value: SchoolType.HighSchool },
            { label: 'Middle School', value: SchoolType.MiddleSchool },
          ]}
        />
      )}
      required
    />
  )

  const GradYearSelect = (
    <>
      <NxTypography className={classes.questionText} variant="body2">
        What year will you graduate?
      </NxTypography>
      <NxSelectController
        className={classes.gradYearSelect}
        control={control}
        data-cy="onboard-graduation-year"
        data-testid="onboard-graduation-year"
        defaultValue={graduationYear}
        error={!!errors?.graduationYear}
        fullWidth
        helperText={errors?.graduationYear?.message}
        label="Graduation Year"
        name="graduationYear"
      >
        {gradYearOptions.map((x) => (
          <NxSelectOption key={x} value={x}>
            {x}
          </NxSelectOption>
        ))}
      </NxSelectController>
    </>
  )

  return (
    <form
      className={classes.form}
      data-testid="tell-us-more-middle-school"
      noValidate
      onChange={onFormUpdate}
      onSubmit={submitTellUsMoreDetails}
    >
      <QuestionnaireCard
        childWidth={400}
        data-testid="OnboardTellUsMore__QuestionaireCard"
        disableContinue={!canContinue}
        onContinue={submitTellUsMoreDetails}
        title="Add your school details"
      >
        <Box mt={5} width="100%">
          {isUnder18 ? (
            <div data-testid="under-18">
              <NxTypography className={classes.questionText} variant="body2">
                What&apos;s your school type?
              </NxTypography>
              {SchoolTypeRadioGroup}
              {GradYearSelect}
            </div>
          ) : (
            <div data-testid="over-18">
              <NxTypography className={classes.questionText} variant="body2">
                What&apos;s your role at school?
              </NxTypography>
              <Controller
                control={control}
                defaultValue={role}
                name="role"
                render={({ name, onChange }): React.ReactElement => (
                  <NxRadioButtonGroup
                    className={classes.schoolTypeRadioGroup}
                    data-cy="onboard-school-role"
                    data-testid="onboard-school-role"
                    defaultValue={role}
                    name={name}
                    onChange={(e): void => {
                      onChange(e)
                    }}
                    radioOptions={[
                      { label: 'Student', value: UserRoleName.Student },
                      { label: 'Faculty Member', value: UserRoleName.Fac },
                    ]}
                    size="small"
                  />
                )}
                required
              />
              {showGradYear && (
                <div className={classes.gradYear}>{GradYearSelect}</div>
              )}
              <Box alignItems="center" display="flex" mb={3}>
                <NxTypography variant="body2">
                  What&apos;s your school type?
                </NxTypography>
                <NxTooltip
                  arrow
                  maxWidth={267}
                  placement="top-start"
                  title="If you want to coach both middle school and high school competitions, select one for now and contact support after you finish signing up to PlayVS."
                >
                  <Box alignItems="center" display="flex" ml={0.25}>
                    <InfoOutlined fontSize="small" />
                  </Box>
                </NxTooltip>
              </Box>

              {SchoolTypeRadioGroup}
              {role === UserRoleName.Fac && (
                <Callout>
                  Faculty will need to verify their employment before creating
                  school teams.
                </Callout>
              )}
            </div>
          )}
        </Box>
      </QuestionnaireCard>
    </form>
  )
}
