import React from 'react'
import { makeStyles, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { CaretDown } from '@playvs-inc/nexus-icons'
import { EthnicityValue } from './types'
import { EthnicityOption } from './EthnicityOption'
import { ETHNICITY_OPTIONS } from './utils'

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    '& label': {
      color: theme.palette.ColorTextAlt2,
    },
  },
  groupLabel: {
    color: theme.palette.ColorTextAlt2,
    textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: 11.25,
    lineHeight: '114%',
    letterSpacing: '1.349px',
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  option: {
    paddingLeft: `${theme.spacing(1)}px !important`,
  },
}))

interface Props {
  fullWidth?: boolean
  onChange: React.Dispatch<React.SetStateAction<EthnicityValue[]>>
  required?: boolean
  values: EthnicityValue[]
}

export const EthnicityAutocomplete: React.FC<Props> = ({
  fullWidth = true,
  onChange,
  required,
  values,
}) => {
  const classes = useStyles()

  return (
    <Autocomplete
      classes={{
        root: classes.input,
        groupLabel: classes.groupLabel,
        option: classes.option,
      }}
      data-testid="ethnicity-autocomplete"
      disableCloseOnSelect
      fullWidth={fullWidth}
      getOptionLabel={(option): string => option.title}
      getOptionSelected={(option, incValue): boolean =>
        option.value === incValue.value
      }
      groupBy={(option): string => option.category}
      multiple
      onChange={(_e, newValues): void => {
        onChange(newValues)
      }}
      options={ETHNICITY_OPTIONS}
      popupIcon={<CaretDown />}
      renderInput={(params): React.ReactElement => (
        <TextField
          {...params}
          autoComplete="off"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          label="Race/Ethnicity"
          required={required}
          variant="outlined"
        />
      )}
      renderOption={(option, { selected }): React.ReactElement => {
        return (
          <EthnicityOption
            onChange={onChange}
            option={option}
            selected={selected}
            values={values}
          />
        )
      }}
      value={values}
    />
  )
}
