import { Box, NoSsr } from '@material-ui/core'
import { Path } from '@plvs/const'
import {
  FullHeightBox,
  Hidden,
  useBreakpointMd,
  useBreakpointSm,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import { InitAppState } from '@plvs/rally/containers/app'
import { useGetMissingFields } from '@plvs/rally/containers/app/fetchers/GetMissingFields'
import { Esport } from '@plvs/respawn/features/esport/Esport'
import { NotificationOverlay } from '@plvs/rally/containers/notificationOverlay'
import { RallyAppDrawer } from '@plvs/rally/features/app/drawer'
import React from 'react'
import { Route } from 'react-router-dom'
import { MatchCheckInsPage } from '@plvs/rally/pages/app/match/MatchCheckInsPage'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { matchPath, useLocation } from 'react-router'
import { AppDrawerToggles } from '@plvs/rally/features/app/drawer/AppDrawerToggles'
import { GlobalChat } from '@plvs/rally/features/app/drawer/globalChat/GlobalChat'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Tour } from '@plvs/graphql/generated'
import { FilterCacheProvider } from '@plvs/respawn/containers/filter/FilterCacheProvider'
import { useProductTours } from '@plvs/respawn/features/shepherd/utils/useProductTours'
import { ShepherdStarter } from '@plvs/respawn/features/shepherd/ShepherdStarter'
import { ApmRoutes } from '@elastic/apm-rum-react'
import { ChildDetailsRoute } from './child/ChildDetailsRoute'
import { MySchoolLeagues } from '../../containers/filter/league/MySchoolLeagues'
import { NotFoundPage } from '../NotFoundPage'
import AccountPage from './AccountPage'
import { HomePage } from './HomePage'
import { LeaguePage } from './LeaguePage'
import { ManageRoute } from './manage'
import { MatchRoute } from './match'
import { MySchoolPage } from './MySchoolPage'
import { ReferPage } from './ReferPage'
import { GlobalReferPage } from './GlobalReferPage'
import SchedulePage from './SchedulePage'
import { SchoolPage } from './SchoolPage'
import { ScrimmagePage } from './ScrimmagePage'
import StandingsPage from './StandingsPage'
import { TeamPage } from './TeamPage'
import { CreateTeamPage } from './CreateTeamPage'
import { QueueLobbyPage } from './QueueLobbyPage'
import { ProfilePage } from './profile/ProfilePage'
import { ResourcesPage } from './ResourcesPage'
import { MissingFieldsRedirect } from './MissingFieldsRedirect'
import { SchoolSignupRedirect } from './SchoolSignupRedirect'
import { CompetitionsRoute } from './competitions'

export const RootRedirect = ({
  loading,
  isParent,
}: {
  loading: boolean
  isParent: boolean
}): React.ReactElement => {
  return (
    <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
      <HomePage isParent={isParent} />
    </WaitTillLoaded>
  )
}

const ONBOARDING_TOUR_PATHS = [
  Path.App,
  `${Path.App}/`,
  Path.Dashboard,
  `${Path.Dashboard}/`,
] as string[]

export const AppRoute = (): React.ReactElement => {
  const { pathname } = useLocation()
  const isUserOnboarding = !!matchPath(
    {
      path: Path.SpawnPoint,
      end: false,
      caseSensitive: false,
    },
    pathname
  )
  const pathMatchesEnrollment = matchPath(
    {
      path: Path.Enrollment,
      end: false,
      caseSensitive: false,
    },
    pathname
  )
  const pathMatchesCheckIn = matchPath(
    {
      path: Path.MatchCheckIns,
      end: false,
      caseSensitive: false,
    },
    pathname
  )
  const removeFullScreenStyling = Boolean(
    pathMatchesEnrollment || pathMatchesCheckIn
  )

  const location = useLocation()

  // Data hooks
  // CORE-1440: Refactor this in a way that makes it reusable.

  const [missingFields, missingFieldsLoading] = useGetMissingFields()
  const {
    loading: identityLoading,
    orgId: schoolId,
    userName,
    isCoachAtOrg,
    isParent,
  } = useUserIdentityFn()

  const isTablet = useBreakpointMd()
  const isMobile = useBreakpointSm()
  const flags = useFlags()

  const maxScreenWidth =
    isTablet && !isMobile ? 'calc(100vw - 320px)' : '1042px'

  // derived props
  // Loading prop here is very important as we have a lot of conflicting rules for redirect or which
  // routes to display on this page.  It is very important this 'loading' var captures any pending
  // queries.
  const loading = identityLoading || missingFieldsLoading

  const { onboardingTour } = useProductTours()

  React.useEffect(() => {
    const handleEvent = (): void => {
      if (onboardingTour?.isActive()) {
        onboardingTour?.complete()
      }
    }
    window.addEventListener('popstate', handleEvent)
    return (): void => window.removeEventListener('popstate', handleEvent)
  }, [])

  if (loading) {
    return (
      <>
        <InitAppState />
      </>
    )
  }

  // Redirect users who require filling in additional details before using the app.
  if (!loading && !!missingFields && !isUserOnboarding && !identityLoading) {
    return (
      <MissingFieldsRedirect
        isParent={isParent}
        missingFields={missingFields}
      />
    )
  }

  // Redirect users to school signup if they do not belong to a school.
  const shouldRedirectToSchoolSignup =
    !loading && !schoolId && !isUserOnboarding && !identityLoading && !isParent
  if (shouldRedirectToSchoolSignup) {
    return <SchoolSignupRedirect userName={userName} />
  }

  const productTourEnabled =
    flags.productTour &&
    ONBOARDING_TOUR_PATHS.includes(location.pathname) &&
    isCoachAtOrg &&
    onboardingTour

  return (
    <>
      <InitAppState />
      <NotificationOverlay />
      {productTourEnabled && (
        <ShepherdStarter tour={onboardingTour} tourType={Tour.Product} />
      )}
      <Esport data-testid="app-route">
        <MySchoolLeagues>
          <Box
            display="flex"
            justifyContent={removeFullScreenStyling ? undefined : 'center'}
          >
            <Hidden smDown>
              <RallyAppDrawer isPublic={false} />
            </Hidden>

            <FullHeightBox
              flexDirection={removeFullScreenStyling ? 'row' : 'row-reverse'}
              maxWidth={removeFullScreenStyling ? '100%' : maxScreenWidth}
            >
              <Hidden smDown>{!isParent && <AppDrawerToggles />}</Hidden>
              <NoSsr>
                <ApmRoutes>
                  <Route
                    element={RootRedirect({
                      loading,
                      isParent: isParent ?? false,
                    })}
                    path="/"
                  />
                  <Route
                    element={RootRedirect({
                      loading,
                      isParent: isParent ?? false,
                    })}
                    path={`${isParent ? 'parent-dashboard/*' : 'dashboard'}`}
                  />
                  <Route
                    element={<ChildDetailsRoute />}
                    path="child/:userId/*"
                  />

                  <Route element={<CompetitionsRoute />} path="explore/*" />

                  <Route element={<MySchoolPage />} path="my-school/*" />

                  <Route
                    element={
                      <FilterCacheProvider id={Path.Manage}>
                        <ManageRoute />
                      </FilterCacheProvider>
                    }
                    path="manage/*"
                  />

                  <Route
                    element={<ProfilePage />}
                    path={`${Path.Profile}/:userId`}
                  />
                  <Route element={<ResourcesPage />} path="resources" />
                  <Route element={<SchedulePage />} path="schedule/*" />
                  <Route element={<AccountPage />} path="account/*" />
                  <Route element={<ReferPage />} path="refer/:invite" />
                  <Route element={<GlobalReferPage />} path="refer-a-friend" />

                  <Route element={<CreateTeamPage />} path="create-team" />
                  <Route element={<MatchCheckInsPage />} path="check-in" />
                  <Route
                    element={<MatchCheckInsPage />}
                    path="check-in/:matchId"
                  />
                  <Route element={<MatchRoute />} path="match/*" />
                  <Route
                    element={<QueueLobbyPage />}
                    path="queue-lobby/:slotId/:teamId"
                  />
                  <Route element={<StandingsPage />} path="standings" />
                  <Route element={<TeamPage />} path="team/:teamId" />
                  <Route element={<SchoolPage />} path="school" />
                  <Route element={<SchoolPage />} path="school/:slug" />
                  <Route element={<LeaguePage />} path="league/:slug" />
                  <Route element={<ScrimmagePage />} path="scrimmage/*" />
                  <Route element={NotFoundPage} />
                </ApmRoutes>
              </NoSsr>
            </FullHeightBox>
            <Box
              display="flex"
              flexDirection={removeFullScreenStyling ? 'row' : 'row-reverse'}
              maxWidth={removeFullScreenStyling ? '100%' : maxScreenWidth}
              zIndex={5}
            >
              <GlobalChat />
            </Box>
          </Box>
        </MySchoolLeagues>
      </Esport>
    </>
  )
}
