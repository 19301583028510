import React from 'react'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'

export const TourSpotWrapper: React.FC = () => {
  return (
    <NxSpot
      domain="productTour"
      height={200}
      size="large"
      variant="primary"
      width={200}
    />
  )
}
