import { Grid, Hidden, makeStyles } from '@material-ui/core'
import { NxEmptyState, NxTypography } from '@playvs-inc/nexus-components'

import { useSelectedOrganizationFn } from '@plvs/client-data/hooks'
import { useGetSchoolInvoiceDataQuery } from '@plvs/graphql/generated'
import { Box } from '@plvs/respawn/features/layout'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import React from 'react'
import { SchoolInvoice } from './SchoolInvoice'

const useStyles = makeStyles((theme) => ({
  subHeader: {
    color: `${theme.palette.ColorTextAlt2} !important`,
  },
  headerTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  emptyText: {
    marginLeft: theme.spacing(2),
  },
  spot: {
    width: 190,
    height: 190,
  },
}))

/**
 * This is the panel in the school settings page that holds school invoices
 */
export const SchoolInvoicePanel: React.FC<{
  isCheckpoint?: boolean
  cpSchoolId?: string
}> = ({ isCheckpoint, cpSchoolId }) => {
  const classes = useStyles()

  // Queries
  // Grab school data from cache.
  const { id: orgId } = useSelectedOrganizationFn()
  const { data: invoiceData } = useGetSchoolInvoiceDataQuery({
    variables: { schoolId: cpSchoolId ?? orgId },
    skip: !cpSchoolId && !orgId,
  })

  const schoolInvoices = invoiceData?.getSchoolInvoiceData ?? []
  const detailsText = isCheckpoint
    ? 'A list of recent billing transactions.'
    : 'A list of all your schools recent billing & pending transactions.'

  return (
    <Box>
      <NxTypography variant="h2">Invoices</NxTypography>
      <NxTypography className={classes.subHeader} variant="body1">
        {detailsText}
      </NxTypography>
      {schoolInvoices.length ? (
        <Box mt={4}>
          {/* Invoice column names */}
          <Hidden smDown>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box className={classes.headerTitle} pl={3} width={112}>
                <NxTypography variant="overline">INVOICE #</NxTypography>
              </Box>
              <Box className={classes.headerTitle} width={105}>
                <NxTypography variant="overline">DATE ISSUED</NxTypography>
              </Box>
              <Box display="flex" justifyContent="flex-start" width={200}>
                <NxTypography variant="overline">DESCRIPTION</NxTypography>
              </Box>
              <Box className={classes.headerTitle} width={88}>
                <NxTypography variant="overline">DUE DATE</NxTypography>
              </Box>
              <Box className={classes.headerTitle} width={59}>
                <NxTypography variant="overline">AMOUNT</NxTypography>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                pl={1}
                width={136}
              >
                <NxTypography variant="overline">STATUS</NxTypography>
              </Box>
            </Box>
          </Hidden>

          <Grid />
          {/* Invoices */}
          {schoolInvoices.map((invoice) => (
            <SchoolInvoice
              key={invoice.referenceId}
              {...invoice}
              schoolId={cpSchoolId ?? orgId}
            />
          ))}
        </Box>
      ) : (
        <Box mt={2}>
          <NxEmptyState
            spot={
              <NxSpot
                domain="billing"
                height={190}
                size="large"
                variant="secondary"
                width={190}
              />
            }
            subtitle="Your school currently has no paid invoices"
            title="No Invoices Found"
          />
        </Box>
      )}
    </Box>
  )
}
