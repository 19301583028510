import {
  EsportRating,
  ResourceType,
  useCreateRoleInviteCodeMutation,
  useCreateTeamV2Mutation,
  useFindOrgCompetitionGroupQuery,
  useGetAllEsportsQuery,
  useGetEsportsV2Query,
  useGetMySchoolEsportsQuery,
  UserRoleName,
} from '@plvs/graphql'
import { Box, PageContentGutter } from '@plvs/respawn/features/layout'
import { MatchCard } from '@plvs/respawn/features/match/MatchCard'
import { NxEsportSelectable, NxTypography } from '@playvs-inc/nexus-components'
import React, { useEffect, useState } from 'react'
import {
  cleanGraphQLError,
  isAssociatedToOrganization,
  isCoachAtOrganization,
  useAutoskipQuery,
} from '@plvs/utils'
import { useProfileContext } from '@plvs/respawn/containers/filter/profile/ProfileProvider'
import { head } from 'ramda'
import { ApolloError } from '@apollo/client'
import { Banner, BannerType } from '@plvs/respawn/features/banner'

import { Param, Path } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { updateUserIdentityFn } from '@plvs/client-data/mutations'

import { useTheme } from '@material-ui/core'
import { useNavigate } from 'react-router-dom'
import {
  CompetitionGroupOption,
  CreateTeamFormInput,
  PlatformOption,
  TeamFormatOption,
} from './CreateTeam.types'
import { CreateTeamForm } from './CreateTeam.form'
import {
  getCreateTeamFormOptions,
  getDefaultCreateTeamFormOption,
  getEsportV2Configs,
} from './CreateTeam.helpers'
import { EsportsLoading } from './esports/Esports.loading'

export interface CreateTeamProps {
  esportId?: string
}

export const CreateTeam: React.FC<CreateTeamProps> = ({ esportId }) => {
  // Hooks
  const {
    organizationIds,
    isMemberOfOrg,
    selectedEntityId,
    loading: profileLoading,
  } = useProfileContext()

  const theme = useTheme()
  const identity = useUserIdentityFn()
  const { userName, userRoles: roles } = identity

  const navigate = useNavigate()

  // State
  const [isCreatingTeam, setIsCreatingTeam] = useState<boolean>(false)
  const [newTeamId, setNewTeamId] = useState<string>('')
  const [createTeamState, setCreateTeamState] = useState<CreateTeamFormInput>({
    name: '',
    selectedEsportGroupId: '',
    size: 0,
  })
  const [newTeamEsportSlug, setNewTeamEsportSlug] = useState<string>('')
  const [error, setError] = useState<Error | null>(null)

  // Queries

  const orgId = (selectedEntityId || head(organizationIds)) ?? ''
  const { data } = useAutoskipQuery(useFindOrgCompetitionGroupQuery, {
    variables: {
      id: orgId,
    },
  })

  const {
    data: esportOrgData,
    loading: esportOrgLoading,
  } = useGetMySchoolEsportsQuery({
    variables: { schoolId: orgId },
    skip: !orgId || profileLoading,
  })

  const { data: esportV2Data, loading } = useGetEsportsV2Query()
  const {
    data: esportData,
    loading: esportDataLoading,
  } = useGetAllEsportsQuery()
  // Mutations

  const [mutate] = useCreateTeamV2Mutation()

  const [createTeamPlayerInviteLink] = useCreateRoleInviteCodeMutation()

  // Data

  const orgLeaguesEsportGroupIds = esportOrgData?.getLeaguesBySchoolId?.map(
    (l) => l?.esport?.esportGroupId
  )

  const mappedEsportV2Data = (esportV2Data?.esportsV2 ?? [])
    .filter((esportV2) =>
      esportData?.esports?.some(
        (esport) => esport.esportGroupId === esportV2.id
      )
    )
    .filter((esportV2) =>
      orgId
        ? esportV2?.offerings?.some(
            (offering) =>
              offering.competitionGroup === data?.school?.competitionGroup &&
              orgLeaguesEsportGroupIds?.some(
                (leagueEsportGroupId) =>
                  leagueEsportGroupId === offering.esportId
              )
          )
        : true
    )
    .map((esportV2) => {
      const esportSlug = esportData?.esports?.find(
        (esport) => esport.esportGroupId === esportV2.id
      )?.slug
      return getEsportV2Configs({
        esportV2,
        schoolData: data?.school,
        esportSlug,
      })
    })
  const esportSlugs = mappedEsportV2Data.map(({ esportSlug }) => esportSlug)
  const esportNames: Record<string, string> = mappedEsportV2Data.reduce(
    (acc, { esportSlug, name, rating }) => {
      if (esportSlug) {
        const displayName =
          rating === EsportRating.Restricted ? `${name} *` : name
        acc[esportSlug] = displayName
      }

      return acc
    },
    {}
  )

  const selectedEsportGroup = mappedEsportV2Data.find(
    (e) =>
      (createTeamState.selectedEsportGroupId &&
        e.esportGroupId === createTeamState.selectedEsportGroupId) ||
      (!createTeamState.selectedEsportGroupId && e.esportSlug === esportId)
  )

  const userId = head(roles)?.userId ?? ''
  const isAssociatedToOrg = isAssociatedToOrganization(
    createTeamState.competitionGroup?.competitionGroup
  )

  const user = {
    name: userName ?? '',
    id: userId,
    isCoachForOrg: isCoachAtOrganization(roles, [orgId]),
    isMemberOfOrg,
    organization: {
      competitionGroup: data?.school?.competitionGroup,
      id: orgId,
      name: data?.school?.name ?? '',
    },
  }

  // Options

  const {
    competitionGroupOptions,
    platformOptions,
    teamSizeOptions,
  } = getCreateTeamFormOptions({
    esport: selectedEsportGroup,
  })

  const defaultCompetitionGroupOption = getDefaultCreateTeamFormOption({
    options: competitionGroupOptions,
  }) as CompetitionGroupOption
  const defaultPlatformOption = getDefaultCreateTeamFormOption({
    options: platformOptions,
  }) as PlatformOption
  const defaultTeamSizeOption = getDefaultCreateTeamFormOption({
    options: teamSizeOptions,
  }) as TeamFormatOption

  // Functions

  const createTeamInvite = async (input: string): Promise<void> => {
    if (isAssociatedToOrg) {
      setNewTeamId(input)
      return
    }
    try {
      if (!userId || !input) {
        throw new ApolloError({ errorMessage: 'Unable to authenticate you.' })
      }
      await createTeamPlayerInviteLink({
        variables: {
          resourceId: input,
          userId,
          resourceType: ResourceType.Team,
          roleName: UserRoleName.Player,
        },
      })
      setNewTeamId(input)
    } catch (e: unknown) {
      setError(e as Error)
    }
  }

  const createTeam = async (): Promise<void> => {
    setIsCreatingTeam(true)
    const createTeamV2Input = {
      name: createTeamState.name,
      esportV2Id: createTeamState.selectedEsportGroupId,
      teamFormat: createTeamState.size,
      platform: createTeamState.platform,
    }

    const schoolOptions = isAssociatedToOrg
      ? {
          schoolId: createTeamState.competitionGroup?.id ?? '',
        }
      : undefined

    try {
      const mutation = await mutate({
        variables: {
          schoolOptions,
          input: createTeamV2Input,
        },
      })
      const newTeam = mutation?.data?.createTeamV2?.team
      const updatedTeamCreatorRoles = newTeam?.createdByUser?.roles ?? []

      if (newTeam?.id) {
        updateUserIdentityFn({
          ...identity,
          userRoles: updatedTeamCreatorRoles,
        })

        await createTeamInvite(newTeam.id)

        if (user.isCoachForOrg) {
          navigate({
            pathname: Path.ManageTeams,
            search: `?${Param.Esport}=${newTeamEsportSlug}`,
          })
        } else {
          navigate(`${Path.Team}/${newTeamId}`)
        }
      } else {
        throw new ApolloError({
          errorMessage: 'Unable to complete team creation.',
        })
      }
    } catch (e: unknown) {
      setError(e as Error)
    } finally {
      setIsCreatingTeam(false)
    }
  }

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault()
    await createTeam()
  }

  const onChange = (input: CreateTeamFormInput): void => {
    setError(null)
    setCreateTeamState({ ...input })
  }

  const onEsportChange = (
    _evt: React.SyntheticEvent,
    esportIndex: number
  ): void => {
    const slug = esportSlugs[esportIndex]
    const esport = mappedEsportV2Data.find(
      // todo MATCH-6784 fix
      // @ts-ignore No types for this but they're not really necessary
      (esportV2) => esportV2.esportSlug === slug
    )
    setNewTeamEsportSlug(esport?.esportSlug ?? '')

    setCreateTeamState((previousState) => ({
      ...previousState,
      selectedEsportGroupId: esport?.esportGroupId ?? '',
    }))
  }

  useEffect(() => {
    setCreateTeamState({
      ...createTeamState,
      selectedEsportGroupId: selectedEsportGroup?.esportGroupId ?? '',
      competitionGroup: {
        id: defaultCompetitionGroupOption?.value,
        competitionGroup: defaultCompetitionGroupOption?.label ?? null,
      },
      platform: defaultPlatformOption?.value,
      size: defaultTeamSizeOption?.value,
    })
    if (error) {
      setError(null)
    }
  }, [selectedEsportGroup?.esportGroupId])

  const anyRestricted = mappedEsportV2Data.some(
    (e) => e.rating === EsportRating.Restricted
  )

  return (
    <PageContentGutter style={{ marginTop: theme.spacing(8.5) }}>
      <MatchCard>
        {error && (
          <Box pt={9}>
            <Banner
              subtitle={cleanGraphQLError(error?.message ?? '')}
              title="Unable to Create New Team"
              type={BannerType.Error}
            />
          </Box>
        )}
        <Box my={3}>
          <NxTypography variant="h1">Create a team</NxTypography>
        </Box>
        <Box mb={2}>
          <NxTypography variant="h4">Select Esport</NxTypography>
        </Box>
        <Box display="flex" flexDirection="row" pb={1}>
          {loading || esportDataLoading || esportOrgLoading ? (
            <EsportsLoading />
          ) : (
            <Box display="flex" flexDirection="column">
              <NxEsportSelectable
                esportNames={esportNames}
                esports={esportSlugs as string[]}
                onChange={onEsportChange}
              />
              {anyRestricted && (
                <Box display="flex" justifyContent="center" my={4}>
                  <NxTypography variant="body3">
                    * These titles and leagues are unaffiliated with the NFHS
                    Network and certain state associations.
                  </NxTypography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box py={2}>
          <CreateTeamForm
            formOptions={{
              competitionGroupOptions,
              platformOptions,
              teamSizeOptions,
            }}
            formValues={createTeamState}
            isCreatingTeam={isCreatingTeam}
            onChange={onChange}
            onSubmit={handleSubmit}
            selectedEsport={selectedEsportGroup}
            user={user}
          />
        </Box>
      </MatchCard>
    </PageContentGutter>
  )
}
