import { ProductType } from '@plvs/client-data/models/ProductType'
import { CompetitionGroup, UserRole } from '@plvs/graphql/generated'

export type RosterCardComponentProps = {
  productType: ProductType
  isTeamOwnerForResource: boolean
  isInMatchAssistantContainer: boolean
  teamFormat?: number
}

export type RosterRowItemRenderProps = {
  userRoles: Pick<
    UserRole,
    'userId' | 'resourceId' | 'resourceType' | 'roleName'
  >[]
  competitionGroup: CompetitionGroup | undefined
  teamId: string
}

export enum DeleteTeamType {
  deleteScholastic = 'deleteScholastic',
  deleteStadium = 'deleteStadium',
}

export type RosterCardComponentReturn = {
  shouldRenderTeamAvatar: boolean
  shouldShowInvitePlayer: boolean
  shouldShowDeleteTeamDisclaimer: boolean
  shouldShowRivalsTab: boolean
  shouldShowRosterTitle: boolean
  shouldShowEvent: boolean
  deleteTeamSubtitle: string
  isContactsTabEnabled: boolean
  deleteTeamMutation: DeleteTeamType
  enableScoutingToTeamsPage: boolean
  standingsHref: string | null
}

export type RosterRowItemRenderReturn = {
  shouldRenderCaptainIcon: boolean
}

export type RosterCardRenderController = {
  getRosterCardComponentsToRender: ({
    productType,
    isTeamOwnerForResource,
    isInMatchAssistantContainer,
    teamFormat,
  }: RosterCardComponentProps) => RosterCardComponentReturn
  getRosterRowItemRender: ({
    userRoles,
    competitionGroup,
    teamId,
  }: RosterRowItemRenderProps) => RosterRowItemRenderReturn
}

export type RosterCardRenderControllerState = {
  rosterCard: RosterCardComponentReturn & RosterRowItemRenderReturn
}

export type UseRosterCardRenderControllerContextReturn = {
  rosterCard: RosterCardRenderController
}
