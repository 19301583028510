import React from 'react'
import { useParams } from 'react-router-dom'
import { AllMetaseasons } from '@plvs/rally/containers/filter/league/AllMetaseasons'
import { useGetLeagueByPublicSlugQuery } from '@plvs/graphql/generated'
import { useThemeProviderContext } from '@plvs/rally/features/theme'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { EmbedSchedule } from './EmbedSchedule'

export const EmbedSchedulePage: React.FC = () => {
  const { publicSlug, themeMode } = useParams<{
    publicSlug: string
    themeMode: MUIThemeModeEnum
  }>()

  const { data } = useGetLeagueByPublicSlugQuery({
    skip: !publicSlug,
    variables: { publicSlug },
  })
  const leagueId = data?.getLeagueByPublicSlug?.id ?? ''

  const { setThemeMode } = useThemeProviderContext()
  setThemeMode(themeMode ?? MUIThemeModeEnum.Dark)

  if (!publicSlug) {
    return <></>
  }

  return (
    <AllMetaseasons leagueId={leagueId}>
      <EmbedSchedule leagueId={leagueId} />
    </AllMetaseasons>
  )
}
