/* eslint-disable no-nested-ternary */
import React from 'react'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import tz from 'dayjs/plugin/timezone'
import { useFlags } from 'launchdarkly-react-client-sdk'

import {
  NxPlayVsBanner,
  PersonaImageVariant,
} from '@playvs-inc/nexus-components'

import { Param } from '@plvs/const'
import {
  PageContentGutter,
  useBreakpointSm,
} from '@plvs/respawn/features/layout'
import {
  useScrimmageRequestQuery,
  useMySchoolDetailsQuery,
  ScrimmageRequestStatus,
} from '@plvs/graphql'
import { useQueryParam } from '@plvs/rally/components/filter'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { CopyButton } from '@plvs/rally/components/button'
import { getAppScrimmageUrl } from '@plvs/utils'
import { MatchImageCard } from '@plvs/rally/components/match/MatchImageCard'
import { HeroGutter, TitleHero } from '@plvs/rally/components/hero'
import dayjs from '@plvs/respawn/init/dayjs'
import { MobileHero } from '@plvs/rally/components/hero/MobileHero'
import { AvailableScrimmages } from './AvailableScrimmages'
import { JoinScrimmageDialog } from './JoinScrimmage'
import { MyScrimmages } from './MyScrimmages'
import { useScrimmageEsportFilter } from './ScrimmageFilters'

dayjs.extend(tz)

const useStyles = makeStyles((theme) => ({
  scrimmageErrorBanner: {
    marginBottom: theme.spacing(5),
  },
}))

const HERO_PROPS = {
  subtitle:
    'Practice against other teams in your league, or teams you coach, without changing your standings',
  title: 'Scrimmage',
}

export const Scrimmages: React.FC = () => {
  const theme = useTheme()
  const isMobile = useBreakpointSm()
  const { scrimmageErrorBanner } = useStyles()
  const { data: schoolData } = useMySchoolDetailsQuery()
  const { esportSlugFilter, selectedEsportSlug } = useScrimmageEsportFilter()

  const schoolId = schoolData?.me?.school?.id ?? ''
  const schoolCompetitionGroup = schoolData?.me?.school?.competitionGroup
  const [scrimmageId] = useQueryParam(Param.Scrimmage)
  const [error] = useQueryParam(Param.Error)
  const scrimmageLink = getAppScrimmageUrl(scrimmageId ?? '')

  const { data: scrimmageData } = useScrimmageRequestQuery({
    variables: {
      id: scrimmageId || '',
      schoolId,
    },
    skip: !scrimmageId,
  })
  const scrimmage = scrimmageData?.scrimmageRequest ?? ''
  const timezone = dayjs.tz.guess()
  const time = scrimmage
    ? dayjs(scrimmage.startsAt ?? new Date()).tz(timezone)
    : dayjs(new Date()).tz(timezone)

  const timeDiffHours = dayjs
    .duration(time.diff(dayjs(new Date()).tz(timezone)))
    .asHours()
  const fromNow = timeDiffHours > 24 ? 24 : Math.floor(timeDiffHours)
  const fromNowLabel =
    // eslint-disable-next-line no-nested-ternary
    fromNow < 1
      ? 'less than one hour to accept.'
      : fromNow === 1
      ? `${fromNow} hour to accept.`
      : `${fromNow} hours to accept.`
  const flags = useFlags()

  return (
    <>
      <JoinScrimmageDialog schoolId={schoolId} />

      <HeroGutter>
        {flags.activateFullBleedBanners && !isMobile ? (
          <NxPlayVsBanner
            size="small"
            {...HERO_PROPS}
            variant={PersonaImageVariant.Scrimmage}
          />
        ) : !isMobile ? (
          <MatchImageCard esportSlug={selectedEsportSlug}>
            <TitleHero {...HERO_PROPS} />
          </MatchImageCard>
        ) : (
          <MobileHero {...HERO_PROPS} />
        )}
      </HeroGutter>

      <PageContentGutter>
        {error && (
          <div className={scrimmageErrorBanner}>
            <Banner
              subtitle={error}
              title="Unable to create a scrimmage."
              type={BannerType.Error}
            />
          </div>
        )}

        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
          mb={3}
        >
          {scrimmage &&
            scrimmageLink &&
            scrimmage?.status === ScrimmageRequestStatus.NeedsOpponent && (
              <Banner
                subtitle="Your scrimmage was created. Share a link with other coaches to join the scrimmage."
                title="Scrimmage created"
                type={BannerType.Success}
              >
                <CopyButton
                  buttonTextAfterCopying="Link copied!"
                  buttonTextBeforeCopying="Copy Share Link"
                  textToCopy={scrimmageLink}
                />
              </Banner>
            )}

          {scrimmage &&
            scrimmageLink &&
            scrimmage?.status === ScrimmageRequestStatus.Requested && (
              <Banner
                subtitle={`Your scrimmage invite was sent to ${scrimmage.opposingTeam?.name}. They have ${fromNowLabel}`}
                title="Scrimmage created"
                type={BannerType.Success}
              />
            )}
        </Box>

        <MyScrimmages
          esportSlugFilter={esportSlugFilter}
          schoolId={schoolId}
          selectedEsportSlug={selectedEsportSlug}
        />

        <Box pt={3} />

        <AvailableScrimmages
          schoolCompetitionGroup={schoolCompetitionGroup}
          schoolId={schoolId}
        />
      </PageContentGutter>
    </>
  )
}
