import React, { useState } from 'react'
import { yupString } from '@plvs/utils'
import { Grid, makeStyles } from '@material-ui/core'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { NxButton, NxTextInput } from '@playvs-inc/nexus-components'

import { useNavigate, useParams } from 'react-router-dom'
import { useSetChildAccountSettingsMutation } from '@plvs/graphql/generated'
import { useSnackbar } from 'notistack'
import { ChildOnboardPath } from '@plvs/const'
import { getNextPath } from './helpers'
import { CountrySelector } from '../form/CountrySelector'
import { StateSelector } from '../form/StateSelector'
import { useOnboardingContext } from '../onboard/OnboardingContext'

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
  gradWrapper: {
    width: '100%',
    '& label': {
      color: theme.palette.ColorTextAlt2,
    },
  },
  gradYearSelect: {
    backgroundColor: theme.palette.common.white,
  },
  button: {
    marginTop: theme.spacing(3),
  },
}))

interface ChildInfoInput {
  firstName: string
  lastName: string
}

const ChildInfoSchema = yup.object().shape({
  firstName: yupString.required(),
  lastName: yupString.required(),
})

export const OnboardChildDemographics: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()
  const params = useParams<{ childId: string | undefined }>()

  const [areaState, setAreaState] = useState<string | undefined>(undefined)
  const [country, setCountry] = useState<string | undefined>(undefined)

  const { handleSubmit, register, errors, watch } = useForm<ChildInfoInput>({
    resolver: yupResolver<ChildInfoInput>(ChildInfoSchema),
  })

  const [setChildAccountSettings, { loading }] =
    useSetChildAccountSettingsMutation()

  const { data: onboardingData } = useOnboardingContext()

  const onSubmit = async (data: ChildInfoInput): Promise<void> => {
    try {
      const childId = onboardingData.childId || params.childId
      if (!childId) {
        enqueueSnackbar('No child ID found', { variant: 'error' })
        return
      }

      await setChildAccountSettings({
        variables: {
          input: {
            firstName: data.firstName,
            lastName: data.lastName,
            state: areaState,
            country,
          },
          userId: childId as string,
        },
      })

      navigate(
        getNextPath({
          childId: params.childId,
          path: ChildOnboardPath.ChildReview,
        })
      )
    } catch (error) {
      enqueueSnackbar('Error setting child info', { variant: 'error' })
    }
  }

  const shouldDisableContinue =
    !areaState ||
    !country ||
    watch('firstName') === '' ||
    watch('lastName') === '' ||
    loading

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <QuestionnaireCard
        childWidth={416}
        subtitle="Tell us a bit more about your child to set up their account"
        title="Child Information"
      >
        <NxTextInput
          ref={register}
          data-testid="firstName"
          fullWidth
          label="First Name"
          name="firstName"
          required
          style={{ height: '56px' }}
          variant={errors?.firstName ? 'error' : 'default'}
        />
        <NxTextInput
          ref={register}
          data-testid="lastName"
          fullWidth
          label="Last Name"
          name="lastName"
          required
          style={{ height: '56px' }}
          variant={errors?.lastName ? 'error' : 'default'}
        />
        <Grid container spacing={2}>
          <Grid data-testid="onboard-child-country" item sm={6} xs={12}>
            <CountrySelector onChange={setCountry} value={country ?? ''} />
          </Grid>
          <Grid data-testid="onboard-child-state" item sm={6} xs={12}>
            <StateSelector onChange={setAreaState} value={areaState ?? ''} />
          </Grid>
        </Grid>
        <NxButton
          className={classes.button}
          data-testid="submit"
          disabled={shouldDisableContinue}
          fullWidth
          label="Continue"
          type="submit"
          variant="primary"
        >
          Continue
        </NxButton>
      </QuestionnaireCard>
    </form>
  )
}
