import { Avatar as MuiAvatar, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { Colors as OldColors, getRandomColor } from '@plvs/rally/themes'
import { Box } from '@plvs/respawn/features/layout'
import { CreateCSSProperties } from '@material-ui/styles'
/* eslint-enable import/no-restricted-paths */
import clsx from 'clsx'
import { useImageLoader } from '@plvs/respawn/features/avatar'

export type AvatarSizes =
  | 'xxsmall'
  | 'xsmall'
  | 'small'
  | 'smedium'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'xxxlarge'

export type AvatarShapes = 'circle' | 'rounded'

interface AvatarStyleProps {
  width: number
  height: number
  borderRadius: string
  borderSize: string
  ringColor?: OldColors | string | null
  isLoaded: boolean
  disabled?: boolean
}

const useStyles = makeStyles({
  avatarWrapper: ({
    width,
    height,
    borderRadius,
  }: AvatarStyleProps): CreateCSSProperties => ({
    width,
    height,
    borderRadius,
    position: 'relative',
  }),
  ring: ({
    width,
    height,
    borderRadius,
    borderSize,
    ringColor,
    isLoaded,
  }: AvatarStyleProps): CreateCSSProperties => ({
    width,
    height,
    position: 'absolute',
    border: `${borderSize} solid ${ringColor}`,
    borderRadius,
    transition: 'transform 1s',
    transform: isLoaded ? `scale(1.15)` : `scale(1)`,
  }),
  avatarInnerWrapper: ({
    width,
    height,
    isLoaded,
  }: AvatarStyleProps): CreateCSSProperties => ({
    width,
    height,
    transition: 'opacity 0.3s',
    opacity: isLoaded ? 1 : 0,
  }),
  avatar: ({
    borderRadius,
    disabled,
  }: AvatarStyleProps): CreateCSSProperties => ({
    height: '100%',
    width: '100%',
    borderRadius,
    opacity: disabled ? 0.35 : 1,
  }),
})

export const sizes = {
  xxxlarge: {
    height: 150,
    width: 150,
    borderSize: 4,
  },
  xxlarge: {
    height: 128,
    width: 128,
    borderSize: 4,
  },
  xlarge: {
    height: 96,
    width: 96,
    borderSize: 3,
  },
  large: {
    height: 80,
    width: 80,
    borderSize: 2,
  },
  medium: {
    height: 54,
    width: 54,
    borderSize: 2,
  },
  smedium: {
    height: 40,
    width: 40,
    borderSize: 1,
  },
  small: {
    height: 32,
    width: 32,
    borderSize: 1,
  },
  xsmall: {
    height: 24,
    width: 24,
    borderSize: 2,
  },
  xxsmall: {
    height: 16,
    width: 16,
    borderSize: 1,
  },
}

export interface Props {
  src?: string | null
  className?: string
  fallbackSrc?: string
  shape?: AvatarShapes
  size: AvatarSizes
  ringColor?: OldColors | string | null
  innerClassName?: string
  backgroundColor?: OldColors | string | null
  disabled?: boolean
  /**
   * Override border size.
   */
  borderSize?: string
}

export const Avatar = ({
  src,
  className,
  fallbackSrc,
  shape = 'circle',
  size,
  ringColor,
  innerClassName,
  backgroundColor,
  disabled,
  borderSize: origBorderSize,
}: Props): React.ReactElement => {
  const [randomColor] = useState<string>(getRandomColor({ id: null }))
  const { imageSrc, isLoaded } = useImageLoader({ src, fallbackSrc })
  const showRing = !!ringColor
  const { width, height, borderSize } = sizes[size]
  const borderRadius = shape === 'circle' ? '100%' : '6px'

  const classes = useStyles({
    width,
    height,
    borderRadius,
    borderSize: origBorderSize || `${borderSize}px`,
    ringColor,
    isLoaded,
    disabled,
  })

  return (
    <Box
      className={clsx(classes.avatarWrapper, className)}
      data-testid="avatarWrapper"
      style={{
        backgroundColor: src
          ? undefined
          : backgroundColor || ringColor || randomColor,
      }}
    >
      {showRing ? <Box className={classes.ring} data-testid="ring" /> : null}
      <Box className={classes.avatarInnerWrapper}>
        {isLoaded ? (
          <MuiAvatar
            alt="avatar"
            className={clsx(classes.avatar, innerClassName)}
            src={imageSrc}
          />
        ) : null}
      </Box>
    </Box>
  )
}
