import { ProductType } from '@plvs/client-data/models/ProductType'
import { Path } from '@plvs/const'
import {
  hasRoleForResource,
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
} from '@plvs/utils'
import { UserRoleName } from '@plvs/graphql/generated'
import {
  captainScheduleMenuItemIdsScholastic,
  coachScheduleMenuItemIds,
  MenuItemIdsForSchedulePageManageMatchDropdownProps,
  ownerScheduleMenuItemIds,
  playerScheduleMenuItemIdsNonScholastic,
  playerScheduleMenuItemIdsScholastic,
  ScheduleComponentDetailsProps,
  ScheduleComponentDetailsReturn,
  SchedulePageDropdownMenuItemsReturn,
} from '../types/scheduleController.types'

export const determineScheduleRender = ({
  productType,
}: ScheduleComponentDetailsProps): ScheduleComponentDetailsReturn => {
  const isInStadium = productType === ProductType.Stadium

  if (isInStadium) {
    return {
      emptyMatchesButtonTitle: 'Explore Events',
      emptyMatchesButtonPath: Path.Dashboard,
      emptyMatchesSubtitleCopyPartial: 'You are not a part of any events',
    }
  }

  return {
    emptyMatchesButtonTitle: 'Explore Leagues',
    emptyMatchesButtonPath: Path.Explore,
    emptyMatchesSubtitleCopyPartial: 'You are not a part of any leagues',
  }
}

export const determineMenuItemIdsForScheduleManageMatchDropdown = ({
  competitionGroup,
  userRoles,
  team1,
  team2,
}: MenuItemIdsForSchedulePageManageMatchDropdownProps): SchedulePageDropdownMenuItemsReturn => {
  if (!competitionGroup) {
    return {
      items: [],
    }
  }
  // Early return if the user is not associated with the match.
  const hasRoleAssociatedWithSomeResource = hasRoleForResource(userRoles, [
    team1?.id,
    team2?.id,
    team1?.schoolId,
    team2?.schoolId,
  ])

  if (!hasRoleAssociatedWithSomeResource) {
    return { items: [] }
  }
  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const isCoachForSomeResource = hasRoleForResource(
      userRoles,
      [team1?.id, team2?.id, team1?.schoolId, team2?.schoolId],
      UserRoleName.Coach
    )
    if (isCoachForSomeResource) {
      return { items: coachScheduleMenuItemIds }
    }
    const isCaptainForSomeResource = hasRoleForResource(
      userRoles,
      [team1?.id, team2?.id, team1?.schoolId, team2?.schoolId],
      UserRoleName.Captain
    )
    if (isCaptainForSomeResource) {
      return { items: captainScheduleMenuItemIdsScholastic }
    }
    return { items: playerScheduleMenuItemIdsScholastic }
  }
  const isTeamOwnerForResource = hasRoleForResource(
    userRoles,
    [team1?.id, team2?.id],
    UserRoleName.Owner
  )
  const isCaptainForResource = hasRoleForResource(
    userRoles,
    [team1?.id, team2?.id],
    UserRoleName.Captain
  )
  if (isTeamOwnerForResource || isCaptainForResource) {
    return {
      items: ownerScheduleMenuItemIds,
    }
  }
  return { items: playerScheduleMenuItemIdsNonScholastic }
}
