import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles(() => ({
  matchRowContainer: {
    display: 'flex',
    flexDirection: 'column',

    // Stacks rows on top of each other
    '&:not(:last-child)': {
      '&>div': {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.15) inset',
      },
    },
    '&:not(:first-child)': {
      '&>div': {
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      },
    },
  },
}))
