export const EMBED_TWITCH_VIDEO_URL = 'https://player.twitch.tv/?video='
export const EMBED_TWICH_CLIP_URL = 'https://clips.twitch.tv/embed?clip='
export const TWITCH_VIDEO_REGEX = /https?:\/\/www\.twitch\.tv\/videos\/(\d{1,10})/

// consts

export const EMBED_YOUTUBE_URL = 'https://www.youtube.com/embed/'
export const YOUTUBE_VIDEO_REGEX = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/

// utils

export const addParent = (url: string): string =>
  `${url}&parent=${window.location.hostname}`

export const embedTwitchUrl = (url: string): string => {
  if (url.includes('clips')) {
    const clipId = url.split('/').pop()
    return addParent(`${EMBED_TWICH_CLIP_URL}${clipId}`)
  }

  if (url.includes('clip')) {
    const startIndex = url.indexOf('/clip/') + 6
    const endIndex = url.length
    const twitchClipSlug = url.substring(startIndex, endIndex)
    return addParent(`${EMBED_TWICH_CLIP_URL}${twitchClipSlug}`)
  }

  const result = url.match(TWITCH_VIDEO_REGEX)
  if (result?.[1]) {
    return addParent(`${EMBED_TWITCH_VIDEO_URL}${result[1]}&autoplay=false`)
  }

  return ''
}

export const embedYoutubeUrl = (url: string): string => {
  const match = url.match(YOUTUBE_VIDEO_REGEX)
  if (match && match[2].length === 11) {
    return `${EMBED_YOUTUBE_URL}${match[2]}`
  }

  return ''
}
