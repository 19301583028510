import {
  Esport,
  GetMatchesByDateForScheduleQuery,
  GetTeamsForScheduleMatchesWithSlotsQuery,
  GetTeamMatchesForScheduleQuery,
  CompetitionGroup,
  MatchScheduleContext,
  Maybe,
  Match,
  PhaseType,
  EsportRating,
  EsportSlug,
  MatchStatus,
  MatchRescheduleRequestStatus,
} from '@plvs/graphql'

export type ScheduleMatch = NonNullable<
  NonNullable<GetMatchesByDateForScheduleQuery['season']>['allTeamMatches']
>[0]

export type TeamSlot = NonNullable<
  NonNullable<GetTeamsForScheduleMatchesWithSlotsQuery['teamsByIds']>
>[0]

export type SchedulePageMatch = Match

export type SchedulePageQueueMatch = {
  __typename?: 'QueueMatchDetails'
  slotId: string
  esport: NonNullable<Pick<Esport, 'id' | 'name' | 'rating' | 'slug'>>
  bestOf: number
  scheduledStartsAt: string
  metaseasonId?: string
  teamId: string
  teamName: string
  schoolLogoUrl: string
  schoolName: string
  competitionGroup: CompetitionGroup
  isScrimmage: boolean
  scheduleContext?: Maybe<MatchScheduleContext>
  id: string
  slot: {
    phase: {
      type: PhaseType | null
    }
  }
}

export type ScheduleSeason = NonNullable<
  NonNullable<TeamSlot['seasonTeams']>[0]['season']
>

export type TeamMatch = NonNullable<
  NonNullable<GetTeamMatchesForScheduleQuery['findMatchesByTeamIds']>
>[0]

export type SchedulePhase = NonNullable<
  NonNullable<ScheduleSeason>['activePhases']
>[0]

export enum SortFilter {
  Upcoming = 'upcoming',
  Past = 'past',
}

// This is the consolidated type that is used for both queue matches
// and scheduled matches throughout the schedule page flow.
export type MappedMatch = {
  slotId: string
  esport: {
    id: string
    name: string
    rating: EsportRating | undefined
    slug: EsportSlug | undefined | null
  }
  bestOf: number
  metaseasonId: string
  isScrimmage: boolean
  competitionGroup: CompetitionGroup | null | undefined
  id: string
  league: {
    id: string
  }
  matchResults: Array<{
    id: string
    gamesWon: number
    gamesLost: number
    gamesTied: number
    seriesWon: number
    seriesLost: number
    seriesTied: number
    placing: number | null
    team: {
      id: string
      name: string
      isMyTeam: boolean
      avatarUrl: string
      archivedAt: string | null
      school: {
        id: string
        name: string
        logoUrl: string
      }
    }
  }>
  scheduleContext: MatchScheduleContext | null | undefined
  scheduledStartsAt: string
  slot: {
    id: string
    phase: {
      id: string
      type: PhaseType | null
      season: {
        id: string
        metaseasonId: string
      }
    }
  }
  status: MatchStatus | undefined
  team1: {
    avatarUrl: string
    id: string
    name: string
    isMyTeam: boolean
    school: {
      id: string
      logoUrl: string
      name: string
    }
  }
  team2: {
    avatarUrl?: string
    id: string
    name: string
    isMyTeam: boolean
    school: {
      id: string
      logoUrl: string
      name: string
    } | null
  }
  matchRescheduleRequests: {
    id: string
    status: MatchRescheduleRequestStatus | null
    proposedTimes: string[]
    teamId: string
    matchId: string
  }[]
  __typename: string
}
