import React from 'react'

import { Box, Tooltip, makeStyles } from '@material-ui/core'
import { UserCluster } from '@plvs/rally/components/cluster'
import { Table, TableColumn } from '@plvs/rally/components/table'
import { Colors } from '@plvs/rally/themes'
import {
  EsportSlug,
  EsportPositionsAttributes,
  Maybe,
  IntegrationType,
  Platform,
} from '@plvs/graphql'

import {
  SmallGreenCircleCheck,
  SmallRedCircleX,
  SmallInfoIcon,
} from '@plvs/rally/components/icon/custom'
import { NxTypography } from '@playvs-inc/nexus-components'

type MemberWithUserName = {
  username: string
  platform?: Platform
  esportSlug: EsportSlug | null
  id: string
  isCaptain: boolean
  lastSeen: Maybe<string>
  position: number
  name: Maybe<string>
  avatarUrl: Maybe<string>
  accountProviderPrettyName: string
  userProviderAccounts: Maybe<
    | {
        id: string
        userId: string
        providerName: string
        providerAccountId: string
        providerDisplayName: string
        providerIntegrationType: IntegrationType
      }[]
    | undefined
  >

  userEsportPlatforms: Maybe<
    | {
        platform: Platform
        esportId: string
      }[]
    | undefined
  >
}

const useStyles = makeStyles({
  tricons: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  deckIcon1: {
    position: 'absolute',
    zIndex: 3,
    top: 'calc(50% - 12px)',
  },
  deckIcon2: {
    position: 'absolute',
    left: '20px',
    zIndex: 2,
    top: 'calc(50% - 12px)',
  },
  deckIcon3: {
    position: 'absolute',
    left: '40px',
    zIndex: 1,
    top: 'calc(50% - 12px)',
  },
  gameAccountField: {
    display: 'flex',
    alignItems: 'center',
  },
  blah: {
    marginLeft: '6px',
    marginRight: '6px',
  },
})

const columns = (
  teamColor: string | undefined,
  positions: EsportPositionsAttributes[],
  getPositionName: (position: number) => string
): TableColumn<MemberWithUserName>[] => [
  {
    id: 'player',
    accessor: 'player',
    minWidth: 200,
    Header: (): React.ReactElement => (
      <NxTypography variant="overline">Player</NxTypography>
    ),
    Cell: (member: MemberWithUserName): React.ReactElement => {
      const { id, avatarUrl, username, name, lastSeen, isCaptain } = member

      return (
        <UserCluster
          avatarUrl={avatarUrl ?? ''}
          dim={!id}
          id={id}
          isCaptain={isCaptain}
          lastSeen={lastSeen}
          ringColor={teamColor || Colors.Grey4}
          subtitle={username || null}
          subtitleColor="inherit"
          title={name || 'Empty'}
        />
      )
    },
  },
  {
    id: 'gameAccount',
    accessor: 'gameAccount',
    minWidth: 200,
    Header: (): React.ReactElement => (
      <NxTypography variant="overline">Game Account</NxTypography>
    ),
    Cell: (member: MemberWithUserName): React.ReactElement => {
      const { gameAccountField, blah } = useStyles()
      const { username, accountProviderPrettyName } = member

      if (!username) {
        return (
          <Box className={gameAccountField} data-testid="not-connected-status">
            {SmallRedCircleX}
            <NxTypography className={blah} variant="body1">
              Not Connected
            </NxTypography>
            <Tooltip
              arrow
              placement="right-end"
              title={`Players must connect their ${accountProviderPrettyName} Account in Account Settings`}
            >
              {SmallInfoIcon}
            </Tooltip>
          </Box>
        )
      }
      return (
        <Box className={gameAccountField} data-testid="connected-status">
          {SmallGreenCircleCheck}
          <NxTypography className={blah} variant="body1">
            Connected
          </NxTypography>
        </Box>
      )
    },
  },
  ...(positions?.length
    ? [
        {
          id: 'Position',
          accessor: 'position',
          minWidth: 200,
          Header: (): React.ReactElement => (
            <NxTypography variant="overline">Position</NxTypography>
          ),
          Cell: (member: MemberWithUserName): React.ReactElement => {
            const { position } = member
            return (
              <NxTypography variant="body1">
                {getPositionName(position ?? null)}
              </NxTypography>
            )
          },
        },
      ]
    : []),
]
export interface RosterListProps {
  members: MemberWithUserName[]
  teamColor?: string
  positions: EsportPositionsAttributes[]
  getPositionName: (position: number) => string
}

export const RosterList: React.FC<RosterListProps> = ({
  members,
  teamColor,
  positions,
  getPositionName,
}) => {
  return (
    <Table
      columns={columns(teamColor, positions, getPositionName)}
      data={members}
    />
  )
}
