import { makeStyles } from '@material-ui/core'
import { NxSelect, NxSelectOption } from '@playvs-inc/nexus-components'
import { countryNames } from '@plvs/const'

import React from 'react'

interface CountrySelectorProps {
  value: string
  onChange: React.Dispatch<React.SetStateAction<string | undefined>>
  fullWidth?: boolean
  required?: boolean
}

const useStyles = makeStyles((theme) => ({
  input: {
    backgroundColor: theme.palette.ColorBackgroundBase,
    color: theme.palette.ColorTextAlt2,
  },
  inputWrapper: {
    '& label': {
      color: theme.palette.ColorTextAlt2,
    },
  },
}))

export const CountrySelector: React.FC<CountrySelectorProps> = ({
  fullWidth = true,
  required,
  value,
  onChange,
}) => {
  const classes = useStyles()

  return (
    <div className={classes.inputWrapper} data-testid="country-select">
      <NxSelect
        className={classes.input}
        fullWidth={fullWidth}
        label="Country"
        onChange={(e): void => {
          onChange(e.target.value as string)
        }}
        required={required}
        value={value}
        variant="outlined"
      >
        {Object.entries(countryNames).map((country) => (
          <NxSelectOption key={country[0]} value={country[0]}>
            {country[1]}
          </NxSelectOption>
        ))}
      </NxSelect>
    </div>
  )
}
