import React, { useCallback } from 'react'
import { noop } from 'ramda-adjunct'

import {
  determineMenuItemIdsForScheduleManageMatchDropdown,
  determineScheduleRender,
} from './utils/scheduleRenderController.helpers'
import {
  UseScheduleRenderControllerContextReturn,
  ScheduleRenderControllerState,
} from './types/scheduleController.types'

const DEFAULT_EMPTY_MATCHES_BUTTON_TITLE = 'Explore'
const DEFAULT_EMPTY_MATCHES_BUTTON_PATH = ''
const DEFAULT_EMPTY_MATCHES_SUBTITLE_COPY_PARTIAL = ''

type ScheduleRenderControllerContext =
  UseScheduleRenderControllerContextReturn & {
    getRenderControllerState: () => ScheduleRenderControllerState
    setRenderControllerStateFn: (
      updatedStateValues: Partial<ScheduleRenderControllerState>
    ) => void
  }

export const useScheduleRenderControllerContext =
  (): ScheduleRenderControllerContext => {
    return {
      schedule: {
        getScheduleComponentDetails: determineScheduleRender,
        getMenuItemIdsForSchedulePageDropdown:
          determineMenuItemIdsForScheduleManageMatchDropdown,
      },
      getRenderControllerState: () => ({
        schedule: {
          emptyMatchesButtonTitle: DEFAULT_EMPTY_MATCHES_BUTTON_TITLE,
          emptyMatchesButtonPath: DEFAULT_EMPTY_MATCHES_BUTTON_PATH,
          emptyMatchesSubtitleCopyPartial:
            DEFAULT_EMPTY_MATCHES_SUBTITLE_COPY_PARTIAL,
          items: [],
        },
      }),
      setRenderControllerStateFn: noop,
    }
  }

export const scheduleRenderControllerContext =
  React.createContext<ScheduleRenderControllerContext>({
    schedule: {
      getScheduleComponentDetails: () => ({
        emptyMatchesButtonTitle: DEFAULT_EMPTY_MATCHES_BUTTON_TITLE,
        emptyMatchesButtonPath: DEFAULT_EMPTY_MATCHES_BUTTON_PATH,
        emptyMatchesSubtitleCopyPartial:
          DEFAULT_EMPTY_MATCHES_SUBTITLE_COPY_PARTIAL,
      }),
      getMenuItemIdsForSchedulePageDropdown: () => ({
        items: [],
      }),
    },
    getRenderControllerState: () => ({
      schedule: {
        emptyMatchesButtonPath: DEFAULT_EMPTY_MATCHES_BUTTON_PATH,
        emptyMatchesButtonTitle: DEFAULT_EMPTY_MATCHES_BUTTON_TITLE,
        emptyMatchesSubtitleCopyPartial:
          DEFAULT_EMPTY_MATCHES_SUBTITLE_COPY_PARTIAL,
        items: [],
      },
    }),
    setRenderControllerStateFn: noop,
  })

export const ScheduleRenderControllerProvider: React.FC = ({ children }) => {
  const [renderControllerState, setRenderControllerState] =
    React.useState<ScheduleRenderControllerState>({
      schedule: {
        emptyMatchesButtonTitle: DEFAULT_EMPTY_MATCHES_BUTTON_TITLE,
        emptyMatchesButtonPath: DEFAULT_EMPTY_MATCHES_BUTTON_PATH,
        emptyMatchesSubtitleCopyPartial:
          DEFAULT_EMPTY_MATCHES_SUBTITLE_COPY_PARTIAL,
        items: [],
      },
    })

  const getRenderControllerState =
    useCallback((): ScheduleRenderControllerState => {
      return renderControllerState
    }, [renderControllerState])

  const setRenderControllerStateFn = useCallback(
    (newState: Partial<ScheduleRenderControllerState>): void => {
      setRenderControllerState((prevState) => ({
        ...prevState,
        ...newState,
      }))
    },
    []
  )

  const values = useScheduleRenderControllerContext()
  return (
    <scheduleRenderControllerContext.Provider
      value={{
        ...values,
        getRenderControllerState,
        setRenderControllerStateFn,
      }}
    >
      {children}
    </scheduleRenderControllerContext.Provider>
  )
}

export const useScheduleControllerState = (): {
  getRenderControllerState: () => ScheduleRenderControllerState
  setRenderControllerStateFn: (
    updatedStateValues: Partial<ScheduleRenderControllerState>
  ) => void
} => {
  const { getRenderControllerState, setRenderControllerStateFn } =
    React.useContext(scheduleRenderControllerContext)

  return { getRenderControllerState, setRenderControllerStateFn }
}
