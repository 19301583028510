import numeral from 'numeral'
import { EsportSlug, Platform, Provider } from '@plvs/graphql'

import {
  RoundToTwoDecimalPlaces,
  RoundToOneDecimalPlace,
  Round,
  PercentWithOneDecimalPlace,
  Km,
} from '@plvs/utils'
import {
  VersusBar,
  StatsScope,
  GameAndMatchObjective,
  TopPerformerCategoryInterface,
  PlatformTypeFormat,
  MapListDetail,
  OverwatchMapType,
} from '@plvs/respawn/features/esport/creator/types'
import {
  Nintendoswitch,
  Pc,
  Playstation,
  Xboxonepc,
  Xbox,
  Epicgames,
} from '@playvs-inc/nexus-icons'
import { esportGameObjectiveAssets, EsportRecord } from './esportDesignAssets'

/** ******************************************************************
 * Static details
 */

// Colors is deprecated and should be removed. This will happen when the GeneralEsportAdapter is audited.
export const TeamBlue = '#23CCF7'
export const TeamRed = '#DB383A'
export const TeamOrange = '#F78823'
export const TeamPurple = '#B153FA'
export const Blue = '#0F72CC'
export const Red = '#DB383A'
export const esportTeamColors: Record<EsportSlug | string, string[]> = {
  [EsportSlug.LeagueOfLegends]: [TeamBlue, TeamRed],
  [EsportSlug.RocketLeague]: [TeamBlue, TeamOrange],
  [EsportSlug.RocketLeagueGMA]: [TeamBlue, TeamOrange],
  [EsportSlug.Smite]: [TeamBlue, TeamRed],
  [EsportSlug.Fortnite]: [Blue, TeamPurple],
  [EsportSlug.FortniteSolos]: [Blue, TeamPurple],
  [EsportSlug.SmashBrosUltimate]: [TeamBlue, TeamRed],
  [EsportSlug.Splatoon]: [TeamBlue, TeamRed],
  [EsportSlug.Overwatch]: [TeamBlue, TeamRed],
  [EsportSlug.Valorant]: [TeamBlue, TeamRed],
  [EsportSlug.Hearthstone]: [TeamBlue, TeamRed],
  [EsportSlug.Fifa21PS4]: [TeamBlue, TeamRed],
  [EsportSlug.Fifa21SolosPS4]: [TeamBlue, TeamRed],
  [EsportSlug.Fifa21XboxOne]: [TeamBlue, TeamRed],
  [EsportSlug.Fifa21SolosXboxOne]: [TeamBlue, TeamRed],
  [EsportSlug.Madden21PS4]: [TeamBlue, TeamRed],
  [EsportSlug.Madden]: [TeamBlue, TeamRed],
  [EsportSlug.Madden21XboxOne]: [TeamBlue, TeamRed],
  [EsportSlug.Madden21SolosXboxOne]: [TeamBlue, TeamRed],
  [EsportSlug.BlackOpsColdWar]: [TeamBlue, TeamRed],
  [EsportSlug.MarioKart]: [TeamBlue, TeamRed],
  [EsportSlug.NBA2K]: [TeamBlue, TeamRed],
  [EsportSlug.FC]: [TeamBlue, TeamRed],
}

export const esportVersusBars: Record<
  EsportSlug | string,
  Record<StatsScope, VersusBar[]>
> = {
  [EsportSlug.LeagueOfLegends]: {
    [StatsScope.Game]: [
      {
        accessor: 'eliminationRespawnRatio',
        format: RoundToTwoDecimalPlaces,
        label: 'KDA',
      },
      {
        accessor: 'eliminations',
        label: 'Eliminations',
      },

      {
        accessor: 'assists',
        label: 'Assists',
      },
    ],
    [StatsScope.Match]: [
      {
        accessor: 'averageGoldEarned',
        format: (x: number): string => numeral(x).format('0a'),
        label: 'Gold earned',
      },

      {
        accessor: 'eliminationRespawnRatio',
        format: RoundToTwoDecimalPlaces,
        label: 'KDA',
      },
      {
        accessor: 'eliminations',
        label: 'Eliminations',
      },

      {
        accessor: 'assists',
        label: 'Assists',
      },
    ],
    [StatsScope.Default]: [],
  },
  [EsportSlug.RocketLeague]: {
    [StatsScope.Default]: [
      {
        accessor: 'score',
        label: 'Score',
      },
      {
        accessor: 'goals',
        label: 'Goals',
      },
      {
        accessor: 'shots',
        label: 'Shots',
      },
      { accessor: 'saves', label: 'Saves' },
      { accessor: 'assists', label: 'Assists' },
    ],
    [StatsScope.Match]: [
      {
        accessor: 'score',
        label: 'Score',
      },
      {
        accessor: 'goals',
        label: 'Goals',
      },
      {
        accessor: 'shots',
        label: 'Shots',
      },
      { accessor: 'saves', label: 'Saves' },
      { accessor: 'assists', label: 'Assists' },
    ],
    [StatsScope.Game]: [
      {
        accessor: 'score',
        label: 'Score',
      },
      {
        accessor: 'goals',
        label: 'Goals',
      },
      {
        accessor: 'shots',
        label: 'Shots',
      },
      { accessor: 'saves', label: 'Saves' },
      { accessor: 'assists', label: 'Assists' },
    ],
  },
  [EsportSlug.Smite]: {
    [StatsScope.Default]: [
      {
        accessor: 'eliminationRespawnRatio',
        format: RoundToTwoDecimalPlaces,
        label: 'KDA',
      },
      {
        accessor: 'eliminations',
        label: 'Eliminations',
      },
      {
        accessor: 'respawns',
        label: 'Respawns',
      },
      {
        accessor: 'assists',
        label: 'Assists',
      },
      {
        accessor: 'averageGoldPerMinute',
        format: (x: number): string => numeral(x).format('0a'),
        label: 'Average Gold Per Minute',
      },
    ],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Fortnite]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.FortniteSolos]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.SmashBrosUltimate]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Splatoon]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Overwatch]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Valorant]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Hearthstone]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Fifa21PS4]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Fifa21SolosPS4]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Fifa21XboxOne]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Fifa21SolosXboxOne]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Madden21PS4]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Madden]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Madden21XboxOne]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.Madden21SolosXboxOne]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.BlackOpsColdWar]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.MarioKart]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.NBA2K]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
  [EsportSlug.FC]: {
    [StatsScope.Default]: [],
    [StatsScope.Match]: [],
    [StatsScope.Game]: [],
  },
}

export const esportObjectives: Record<
  EsportSlug | string,
  GameAndMatchObjective
> = {
  [EsportSlug.LeagueOfLegends]: {
    [StatsScope.Game]: [
      {
        description: 'Towers',
        accessor: 'towersDestroyed',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Tower,
      },
      {
        description: 'Inhibitors',
        accessor: 'inhibitorsDestroyed',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Inhibitor,
      },
      {
        description: 'Dragons',
        accessor: 'dragonEliminations',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Dragon,
      },
      {
        description: 'Barons',
        accessor: 'baronEliminations',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Baron,
      },
    ],
    [StatsScope.Match]: [
      {
        description: 'Towers',
        accessor: 'towersDestroyed',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Tower,
      },
      {
        description: 'Inhibitors',
        accessor: 'inhibitorsDestroyed',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Inhibitor,
      },
      {
        description: 'Dragons',
        accessor: 'dragonEliminations',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Dragon,
      },
      {
        description: 'Barons',
        accessor: 'baronEliminations',
        Icon: esportGameObjectiveAssets[EsportSlug.LeagueOfLegends]?.Baron,
      },
    ],
  },
  [EsportSlug.RocketLeague]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Smite]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Fortnite]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.FortniteSolos]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.SmashBrosUltimate]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Splatoon]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Overwatch]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Valorant]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Hearthstone]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Fifa21PS4]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Fifa21SolosPS4]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Fifa21XboxOne]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Fifa21SolosXboxOne]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Madden21PS4]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Madden]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Madden21XboxOne]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.Madden21SolosXboxOne]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.BlackOpsColdWar]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.MarioKart]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.NBA2K]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
  [EsportSlug.FC]: {
    [StatsScope.Game]: [],
    [StatsScope.Match]: [],
  },
}

export const esportTopPerformerCategories: Record<
  EsportSlug | string,
  TopPerformerCategoryInterface[]
> = {
  [EsportSlug.LeagueOfLegends]: [
    {
      header: 'KDA',
      accessor: 'eliminationRespawnRatio',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Most Kills',
      accessor: 'eliminations',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Most Assists',
      accessor: 'assists',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Gold Per Minute',
      accessor: 'goldPerMinute',
      format: Round,
    },
  ],
  [EsportSlug.RocketLeague]: [
    {
      accessor: 'shootingPercentage',
      header: 'Shooting Percentage',
      format: PercentWithOneDecimalPlace,
    },
    {
      accessor: 'goals',
      header: 'Goals',
      format: RoundToOneDecimalPlace,
    },
    {
      accessor: 'saves',
      header: 'Saves',
      format: RoundToOneDecimalPlace,
    },
    {
      accessor: 'assists',
      header: 'Assists',
      format: RoundToOneDecimalPlace,
    },
  ],
  [EsportSlug.Smite]: [
    {
      header: 'KDA',
      accessor: 'eliminationRespawnRatio',
      format: RoundToOneDecimalPlace,
    },
    { header: 'Most Eliminations', accessor: 'eliminations' },
    { header: 'Most Assists', accessor: 'assists' },
    {
      header: 'Gold Earned',
      accessor: 'goldEarned',
      format: Km,
    },
  ],
  [EsportSlug.Fortnite]: [],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.SmashBrosUltimate]: [],
  [EsportSlug.Splatoon]: [],
  [EsportSlug.Overwatch]: [],
  [EsportSlug.Valorant]: [],
  [EsportSlug.Hearthstone]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
}

export const esportPhaseTopPerformerCategories: Record<
  EsportSlug | string,
  TopPerformerCategoryInterface[]
> = {
  [EsportSlug.LeagueOfLegends]: [
    {
      header: 'Avg. KDA',
      accessor: 'eliminationRespawnRatio',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Avg. Most Kills',
      accessor: 'averageEliminations',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Avg. Most Assists',
      accessor: 'averageAssists',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Avg. Gold Per Minute',
      accessor: 'averageGoldPerMinute',
      format: Round,
    },
  ],
  [EsportSlug.RocketLeague]: [
    {
      accessor: 'shootingPercentage',
      header: 'Avg. Shooting Percentage',
      format: PercentWithOneDecimalPlace,
    },
    {
      accessor: 'averageGoals',
      header: 'Avg. Goals',
      format: RoundToOneDecimalPlace,
    },
    {
      accessor: 'averageSaves',
      header: 'Avg. Saves',
      format: RoundToOneDecimalPlace,
    },
    {
      accessor: 'averageAssists',
      header: 'Avg. Assists',
      format: RoundToOneDecimalPlace,
    },
  ],
  [EsportSlug.Smite]: [
    {
      header: 'KDA',
      accessor: 'eliminationRespawnRatio',
      format: RoundToOneDecimalPlace,
    },
    { header: 'Average Eliminations', accessor: 'averageEliminations' },
    { header: 'Average Assists', accessor: 'averageAssists' },
    {
      header: 'Average Gold Earned',
      accessor: 'averageGoldEarned',
      format: Km,
    },
  ],
  [EsportSlug.Fortnite]: [
    {
      header: 'Average Rank per Match',
      accessor: 'avgRankPerMatch',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Average Points per Match',
      accessor: 'avgPointsPerMatch',
      format: RoundToOneDecimalPlace,
    },
    {
      header: 'Matches Played',
      accessor: 'matchesPlayed',
    },
    {
      header: 'Wins',
      accessor: 'totalVictoryRoyales',
    },
  ],
  [EsportSlug.SmashBrosUltimate]: [
    {
      header: 'Matches Played',
      accessor: 'matchesPlayed',
    },
  ],
  [EsportSlug.Splatoon]: [
    {
      header: 'Matches Played',
      accessor: 'matchesPlayed',
    },
  ],
  [EsportSlug.Overwatch]: [
    {
      header: 'Matches Played',
      accessor: 'matchesPlayed',
    },
  ],
  [EsportSlug.Valorant]: [
    {
      header: 'Matches Played',
      accessor: 'matchesPlayed',
    },
  ],
  [EsportSlug.Hearthstone]: [
    {
      header: 'Matches Played',
      accessor: 'matchesPlayed',
    },
  ],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
}

export const esportPlatformIcon: Record<EsportSlug | string, React.FC> = {
  [EsportSlug.LeagueOfLegends]: Pc,
  [EsportSlug.RocketLeague]: Xboxonepc,
  [EsportSlug.Smite]: Pc,
  [EsportSlug.Fortnite]: Xboxonepc,
  [EsportSlug.FortniteSolos]: Xboxonepc,
  [EsportSlug.SmashBrosUltimate]: Nintendoswitch,
  [EsportSlug.Splatoon]: Nintendoswitch,
  [EsportSlug.Overwatch]: Xboxonepc,
  [EsportSlug.Valorant]: Pc,
  [EsportSlug.Hearthstone]: Xboxonepc,
  [EsportSlug.Fifa21PS4]: Playstation,
  [EsportSlug.Fifa21SolosPS4]: Playstation,
  [EsportSlug.Fifa21XboxOne]: Xbox,
  [EsportSlug.Fifa21SolosXboxOne]: Xbox,
  [EsportSlug.Madden21PS4]: Playstation,
  [EsportSlug.Madden]: Playstation,
  [EsportSlug.Madden21XboxOne]: Xbox,
  [EsportSlug.Madden21SolosXboxOne]: Xbox,
  [EsportSlug.BlackOpsColdWar]: Xboxonepc,
  [EsportSlug.MarioKart]: Nintendoswitch,
  [EsportSlug.NBA2K]: Playstation,
  [EsportSlug.FC]: Playstation,
}

export const esportPlatformType: EsportRecord<PlatformTypeFormat> = {
  [EsportSlug.LeagueOfLegends]: 'PC',
  [EsportSlug.RocketLeague]: 'Cross Play',
  [EsportSlug.Smite]: 'PC',
  [EsportSlug.Fortnite]: 'Cross Play',
  [EsportSlug.FortniteSolos]: 'Cross Play',
  [EsportSlug.SmashBrosUltimate]: 'Nintendo',
  [EsportSlug.Splatoon]: 'Nintendo',
  [EsportSlug.Overwatch]: 'Cross Play',
  [EsportSlug.Valorant]: 'PC',
  [EsportSlug.Hearthstone]: 'Cross Play',
  [EsportSlug.Fifa21PS4]: 'PS4',
  [EsportSlug.Fifa21SolosPS4]: 'PS4',
  [EsportSlug.Fifa21XboxOne]: 'Xbox One',
  [EsportSlug.Fifa21SolosXboxOne]: 'Xbox One',
  [EsportSlug.Madden21PS4]: 'PS4',
  [EsportSlug.Madden]: 'PS4',
  [EsportSlug.Madden21XboxOne]: 'Xbox One',
  [EsportSlug.Madden21SolosXboxOne]: 'Xbox One',
  [EsportSlug.BlackOpsColdWar]: 'Cross Play',
  [EsportSlug.MarioKart]: 'Nintendo',
  [EsportSlug.NBA2K]: 'PS4',
  [EsportSlug.FC]: 'PC',
}

export const esportPlatformMap: Partial<
  Record<Platform | string, PlatformTypeFormat>
> = {
  [Platform.NintendoSwitch]: 'Nintendo',
  [Platform.PlayStation4]: 'PS4',
  [Platform.XboxOne]: 'Xbox One',
  [Platform.Pc]: 'PC',
  [Platform.PcMac]: 'PC/Mac',
  [Platform.EpicGames]: 'Epic Games',
  PlayStation5: 'PS5',
  XboxSeriesXS: 'Xbox Series X|S',
}

export const rocketLeaguePlatformMap: Partial<Record<Platform, string>> = {
  [Platform.EpicGames]: 'epic',
  [Platform.Steam]: 'steam',
  [Platform.NintendoSwitch]: 'switch',
  [Platform.PlayStation4]: 'psn',
  [Platform.XboxOne]: 'xbl',
}

export const esportPlatformIconMap: Record<PlatformTypeFormat, React.FC> = {
  PC: Pc,
  'Cross Play': Xboxonepc,
  Nintendo: Nintendoswitch,
  'Xbox One': Xbox,
  'Xbox Series X|S': Xbox,
  PS4: Playstation,
  PS5: Playstation,
  'PC/Mac': Pc,
  'Epic Games': Epicgames,
}

export const providerPrettyNames: Record<Provider, string> = {
  [Provider.BattleDotNet]: 'Battle.net',
  [Provider.Discord]: Provider.Discord,
  [Provider.Twitch]: Provider.Twitch,
  [Provider.Riot]: Provider.Riot,
  [Provider.EpicGames]: 'Epic Games',
  [Provider.Nintendo]: 'Nintendo',
  [Provider.Smite]: 'SMITE',
  [Provider.Steam]: 'Steam',
  [Provider.XBoxLive]: 'Xbox Live',
  [Provider.PlayStationNetwork]: 'PlayStation Network',
  [Provider.Youtube]: 'YouTube',
  [Provider.Spin]: Provider.Spin,
  [Provider.Vesl]: 'VESL',
  [Provider.ChessKid]: Provider.ChessKid,
  [Provider.ChessDotCom]: 'Chess.com',
  [Provider.ElectronicArts]: 'EA',
  [Provider.StreetFighter]: 'Street Fighter',
  [Provider.PokemonUnite]: 'Pokémon Unite',
  [Provider.Nba2K]: 'NBA2K',
}

export const esportStandardMapList: Record<
  EsportSlug | string,
  MapListDetail[]
> = {
  [EsportSlug.LeagueOfLegends]: [],
  [EsportSlug.RocketLeague]: [],
  [EsportSlug.Smite]: [],
  [EsportSlug.Fortnite]: [],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.SmashBrosUltimate]: [],
  [EsportSlug.Splatoon]: [],
  [EsportSlug.Overwatch]: [
    {
      name: OverwatchMapType.Control,
      bestOf: 3,
    },
    {
      name: OverwatchMapType.Hybrid,
      bestOf: 2,
    },
    {
      name: OverwatchMapType.Assault,
      bestOf: 2,
    },
    {
      name: OverwatchMapType.Escort,
      bestOf: 2,
    },
    {
      name: OverwatchMapType.Control,
      bestOf: 3,
    },
  ],
  [EsportSlug.Valorant]: [],
  [EsportSlug.Hearthstone]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
}

export const esportPlayoffMapList: Record<
  EsportSlug | string,
  MapListDetail[]
> = {
  [EsportSlug.LeagueOfLegends]: [],
  [EsportSlug.RocketLeague]: [],
  [EsportSlug.Smite]: [],
  [EsportSlug.Fortnite]: [],
  [EsportSlug.FortniteSolos]: [],
  [EsportSlug.SmashBrosUltimate]: [],
  [EsportSlug.Splatoon]: [],
  [EsportSlug.Overwatch]: [
    {
      name: OverwatchMapType.Control,
      bestOf: 3,
    },
    {
      name: OverwatchMapType.Hybrid,
      bestOf: 2,
    },
    {
      name: OverwatchMapType.Assault,
      bestOf: 2,
    },
    {
      name: OverwatchMapType.Escort,
      bestOf: 2,
    },
    {
      name: OverwatchMapType.Control,
      bestOf: 3,
    },
  ],
  [EsportSlug.Valorant]: [],
  [EsportSlug.Hearthstone]: [],
  [EsportSlug.Fifa21PS4]: [],
  [EsportSlug.Fifa21SolosPS4]: [],
  [EsportSlug.Fifa21XboxOne]: [],
  [EsportSlug.Fifa21SolosXboxOne]: [],
  [EsportSlug.Madden21PS4]: [],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21XboxOne]: [],
  [EsportSlug.Madden21SolosXboxOne]: [],
  [EsportSlug.BlackOpsColdWar]: [],
  [EsportSlug.MarioKart]: [],
  [EsportSlug.NBA2K]: [],
  [EsportSlug.FC]: [],
}

export const esportHasArena: Record<EsportSlug | string, boolean> = {
  [EsportSlug.LeagueOfLegends]: false,
  [EsportSlug.RocketLeague]: false,
  [EsportSlug.Smite]: false,
  [EsportSlug.Fortnite]: false,
  [EsportSlug.FortniteSolos]: false,
  [EsportSlug.SmashBrosUltimate]: true,
  [EsportSlug.Splatoon]: false,
  [EsportSlug.Overwatch]: false,
  [EsportSlug.Valorant]: false,
  [EsportSlug.Hearthstone]: false,
  [EsportSlug.Fifa21PS4]: false,
  [EsportSlug.Fifa21SolosPS4]: false,
  [EsportSlug.Fifa21XboxOne]: false,
  [EsportSlug.Fifa21SolosXboxOne]: false,
  [EsportSlug.Madden21PS4]: false,
  [EsportSlug.Madden]: false,
  [EsportSlug.Madden21XboxOne]: false,
  [EsportSlug.Madden21SolosXboxOne]: false,
  [EsportSlug.BlackOpsColdWar]: false,
  [EsportSlug.MarioKart]: false,
  [EsportSlug.NBA2K]: false,
  [EsportSlug.FC]: false,
}

export const esportHasStatsEnabled: Record<EsportSlug | string, boolean> = {
  [EsportSlug.LeagueOfLegends]: true,
  [EsportSlug.RocketLeague]: true,
  [EsportSlug.Smite]: true,
  [EsportSlug.Fortnite]: true,
  [EsportSlug.FortniteSolos]: true,
  [EsportSlug.SmashBrosUltimate]: false,
  [EsportSlug.Splatoon]: false,
  [EsportSlug.Overwatch]: false,
  [EsportSlug.Valorant]: false,
  [EsportSlug.Hearthstone]: false,
  [EsportSlug.Fifa21PS4]: false,
  [EsportSlug.Fifa21SolosPS4]: false,
  [EsportSlug.Fifa21XboxOne]: false,
  [EsportSlug.Fifa21SolosXboxOne]: false,
  [EsportSlug.Madden21PS4]: false,
  [EsportSlug.Madden]: false,
  [EsportSlug.Madden21XboxOne]: false,
  [EsportSlug.Madden21SolosXboxOne]: false,
  [EsportSlug.BlackOpsColdWar]: false,
  [EsportSlug.MarioKart]: false,
  [EsportSlug.NBA2K]: false,
  [EsportSlug.FC]: false,
}

export const esportMatchesCanBeSettled: Record<EsportSlug | string, boolean> = {
  [EsportSlug.LeagueOfLegends]: true,
  [EsportSlug.RocketLeague]: true,
  [EsportSlug.Smite]: true,
  [EsportSlug.Fortnite]: false,
  [EsportSlug.FortniteSolos]: false,
  [EsportSlug.SmashBrosUltimate]: true,
  [EsportSlug.Splatoon]: true,
  [EsportSlug.Overwatch]: true,
  [EsportSlug.Valorant]: true,
  [EsportSlug.Hearthstone]: true,
  [EsportSlug.Fifa21PS4]: true,
  [EsportSlug.Fifa21SolosPS4]: true,
  [EsportSlug.Fifa21XboxOne]: true,
  [EsportSlug.Fifa21SolosXboxOne]: true,
  [EsportSlug.Madden21PS4]: true,
  [EsportSlug.Madden]: true,
  [EsportSlug.Madden21XboxOne]: true,
  [EsportSlug.Madden21SolosXboxOne]: true,
  [EsportSlug.BlackOpsColdWar]: false,
  [EsportSlug.MarioKart]: true,
  [EsportSlug.NBA2K]: true,
  [EsportSlug.FC]: true,
}

export const esportIsPlayerDesignatedMatchSetUp: Record<
  EsportSlug | string,
  boolean
> = {
  [EsportSlug.LeagueOfLegends]: false,
  [EsportSlug.RocketLeague]: false,
  [EsportSlug.Smite]: false,
  [EsportSlug.Fortnite]: false,
  [EsportSlug.FortniteSolos]: false,
  [EsportSlug.SmashBrosUltimate]: true,
  [EsportSlug.Splatoon]: true,
  [EsportSlug.Overwatch]: true,
  [EsportSlug.Valorant]: true,
  [EsportSlug.Hearthstone]: false,
  [EsportSlug.Fifa21PS4]: false,
  [EsportSlug.Fifa21SolosPS4]: false,
  [EsportSlug.Fifa21XboxOne]: false,
  [EsportSlug.Fifa21SolosXboxOne]: false,
  [EsportSlug.Madden21PS4]: false,
  [EsportSlug.Madden]: false,
  [EsportSlug.Madden21XboxOne]: false,
  [EsportSlug.Madden21SolosXboxOne]: false,
  [EsportSlug.BlackOpsColdWar]: false,
  [EsportSlug.MarioKart]: true,
  [EsportSlug.NBA2K]: false,
  [EsportSlug.FC]: false,
}

export const esportIsAccountConnectionOptional: Record<
  EsportSlug | string,
  boolean
> = {
  [EsportSlug.LeagueOfLegends]: false,
  [EsportSlug.RocketLeague]: false,
  [EsportSlug.Smite]: false,
  [EsportSlug.Fortnite]: false,
  [EsportSlug.FortniteSolos]: false,
  [EsportSlug.SmashBrosUltimate]: true,
  [EsportSlug.Splatoon]: true,
  [EsportSlug.Overwatch]: false,
  [EsportSlug.Valorant]: false,
  [EsportSlug.Hearthstone]: false,
  [EsportSlug.Fifa21PS4]: false,
  [EsportSlug.Fifa21SolosPS4]: false,
  [EsportSlug.Fifa21XboxOne]: false,
  [EsportSlug.Fifa21SolosXboxOne]: false,
  [EsportSlug.Madden21PS4]: false,
  [EsportSlug.Madden]: false,
  [EsportSlug.Madden21XboxOne]: false,
  [EsportSlug.Madden21SolosXboxOne]: false,
  [EsportSlug.BlackOpsColdWar]: false,
  [EsportSlug.MarioKart]: true,
  [EsportSlug.NBA2K]: false,
  [EsportSlug.FC]: false,
}

export const esportMatchChecklistArticle: Record<EsportSlug | string, string> =
  {
    [EsportSlug.LeagueOfLegends]: 'https://help.playvs.com/en/articles/2890444',
    [EsportSlug.RocketLeague]: 'https://help.playvs.com/en/articles/5301489',
    [EsportSlug.Smite]: 'https://help.playvs.com/en/articles/2890512',
    [EsportSlug.Fortnite]: 'https://help.playvs.com/en/articles/4919202',
    [EsportSlug.FortniteSolos]: 'https://help.playvs.com/en/articles/4919202',
    [EsportSlug.SmashBrosUltimate]:
      'https://help.playvs.com/en/articles/4919137',
    [EsportSlug.Splatoon]: 'https://help.playvs.com/en/articles/5433514',
    [EsportSlug.Overwatch]: 'https://help.playvs.com/en/articles/4919157',
    [EsportSlug.Valorant]: '',
    [EsportSlug.Hearthstone]: '',
    [EsportSlug.Fifa21PS4]: 'https://help.playvs.com/en/articles/4919165',
    [EsportSlug.Fifa21SolosPS4]: 'https://help.playvs.com/en/articles/4919165',
    [EsportSlug.Fifa21XboxOne]: 'https://help.playvs.com/en/articles/4919165',
    [EsportSlug.Fifa21SolosXboxOne]:
      'https://help.playvs.com/en/articles/4919165',
    [EsportSlug.Madden21PS4]: 'https://help.playvs.com/en/articles/4919164',
    [EsportSlug.Madden]: 'https://help.playvs.com/en/articles/4919164',
    [EsportSlug.Madden21XboxOne]: 'https://help.playvs.com/en/articles/4919164',
    [EsportSlug.Madden21SolosXboxOne]:
      'https://help.playvs.com/en/articles/4919164',
    [EsportSlug.BlackOpsColdWar]: '',
    [EsportSlug.MarioKart]: '',
    [EsportSlug.NBA2K]: '',
    [EsportSlug.FC]: '',
  }

export const esportConnectionsPlatformMap: Record<
  EsportSlug | string,
  Provider[]
> = {
  [EsportSlug.LeagueOfLegends]: [Provider.Riot],
  [EsportSlug.RocketLeague]: [Provider.EpicGames],
  [EsportSlug.Smite]: [Provider.Smite],
  [EsportSlug.Fortnite]: [Provider.EpicGames],
  [EsportSlug.FortniteSolos]: [Provider.EpicGames],
  [EsportSlug.Overwatch]: [Provider.BattleDotNet],
  [EsportSlug.Valorant]: [Provider.Riot],
  [EsportSlug.Hearthstone]: [Provider.BattleDotNet],
  [EsportSlug.Madden]: [],
  [EsportSlug.Madden21PS4]: [Provider.PlayStationNetwork],
  [EsportSlug.Madden21XboxOne]: [Provider.XBoxLive],
  [EsportSlug.Madden21SolosXboxOne]: [Provider.XBoxLive],
  [EsportSlug.Fifa21PS4]: [Provider.PlayStationNetwork],
  [EsportSlug.Fifa21SolosPS4]: [Provider.PlayStationNetwork],
  [EsportSlug.Fifa21XboxOne]: [Provider.XBoxLive],
  [EsportSlug.Fifa21SolosXboxOne]: [Provider.XBoxLive],
  [EsportSlug.SmashBrosUltimate]: [Provider.Nintendo],
  [EsportSlug.BlackOpsColdWar]: [Provider.BattleDotNet],
  [EsportSlug.Splatoon]: [Provider.Nintendo],
  [EsportSlug.MarioKart]: [Provider.Nintendo],
  [EsportSlug.NBA2K]: [Provider.Steam, Provider.PlayStationNetwork],
  [EsportSlug.FC]: [Provider.Steam, Provider.EpicGames],
}

export const esportResources: Record<string, string> = {
  [EsportSlug.Hearthstone]: 'https://help.playvs.com/en/articles/5860005',
  [EsportSlug.LeagueOfLegends]: 'https://help.playvs.com/en/articles/4919292',
  [EsportSlug.Madden]: 'https://help.playvs.com/en/articles/4919173',
  [EsportSlug.Madden21PS4]: 'https://help.playvs.com/en/articles/4919173',
  [EsportSlug.Madden21XboxOne]: 'https://help.playvs.com/en/articles/4919173',
  [EsportSlug.Madden21SolosXboxOne]:
    'https://help.playvs.com/en/articles/4919173',
  [EsportSlug.MarioKart]: 'https://help.playvs.com/en/articles/5777110',
  [EsportSlug.NBA2K]: 'https://help.playvs.com/en/articles/6417963',
  [EsportSlug.Overwatch]: 'https://help.playvs.com/en/articles/4919296',
  [EsportSlug.RocketLeague]: 'https://help.playvs.com/en/articles/4919293',
  [EsportSlug.Splatoon]: 'https://help.playvs.com/en/articles/5517722',
  [EsportSlug.SmashBrosUltimate]: 'https://help.playvs.com/en/articles/4919170',
  [EsportSlug.Valorant]: 'https://help.playvs.com/en/articles/8659612',
  [EsportSlug.FC]: 'https://help.playvs.com/en/articles/8659612',
}

export const scrimmageRatings: Record<string | EsportSlug, string[]> = {
  default: ['Beginner', 'Intermediate', 'Advanced', 'Pro'],

  [EsportSlug.LeagueOfLegends]: [
    'Iron',
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Master',
    'Grandmaster',
    'Challenger',
  ],

  [EsportSlug.RocketLeague]: [
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Champion',
    'Grand Champion',
    'Supersonic Legend',
  ],

  [EsportSlug.Overwatch]: [
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Master',
    'Grandmaster',
  ],

  [EsportSlug.Hearthstone]: [
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Legend',
  ],

  [EsportSlug.Valorant]: [
    'Iron',
    'Bronze',
    'Silver',
    'Gold',
    'Platinum',
    'Diamond',
    'Ascendant',
    'Immortal',
    'Radiant',
  ],
}

export const getScrimmageRatings = (
  esportSlug?: EsportSlug | null
): string[] => {
  return (
    (esportSlug && scrimmageRatings[esportSlug]) || scrimmageRatings.default
  )
}
