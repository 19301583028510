import React, { useEffect, useState } from 'react'

import { SingleChat } from '@playvs-inc/nexus-icons'

import { Box, useBreakpointSm } from '@plvs/respawn/features/layout'
import dayjs from '@plvs/respawn/init/dayjs'
import * as analytics from '@plvs/respawn/features/analytics'
import { useChatConversationsContext } from '@plvs/rally/containers/chat/ChatConversationsProvider'
import { DrawerWrapper } from '../DrawerWrapper'
import { LoadingSkeleton } from '../notifications/LoadingSkeleton'
import { EmptyChats } from './EmptyChats'
import { ChatPreviewSection } from './ChatPreviewSection'

export const ChatDrawer: React.FC<{
  close?: () => void
}> = ({ close }) => {
  // State tracking for analytics
  const [openTimestamp, setOpenTimestamp] = useState('')

  const {
    loading,
    usersActiveConversationsMap,
    setRefetchGetConversations,
  } = useChatConversationsContext()
  const isMobile = useBreakpointSm()

  const sortedConversations = Object.values(usersActiveConversationsMap)
    .filter(
      (c) =>
        Boolean(c.lastMessage?.body) || Boolean(c.lastMessage?.attachedMedia)
    )
    .sort((convoA, convoB) => {
      return (
        dayjs(convoB?.chatLastUpdated).unix() -
        dayjs(convoA?.chatLastUpdated).unix()
      )
    })

  const todaysConversations = sortedConversations.filter((c) =>
    dayjs(c.chatLastUpdated).isAfter(dayjs().startOf('day'))
  )
  const olderConversations = sortedConversations.filter((c) =>
    dayjs(c.chatLastUpdated).isBefore(dayjs().startOf('day'))
  )

  const renderEmpty = !loading && !sortedConversations.length

  const [renderEmptyState, setRenderEmptyState] = React.useState(renderEmpty)

  useEffect(() => {
    setRenderEmptyState(renderEmpty)
  }, [loading, sortedConversations.length])

  useEffect(() => {
    setOpenTimestamp(new Date().toISOString())
    // This ensures the latest message is shown when the drawer is opened.
    setRefetchGetConversations(true)
  }, [])

  useEffect(() => {
    if (!loading) {
      const renderingFinishedTimestamp = new Date().toISOString()
      analytics.globalChatDrawerOpenedAndLoaded({
        openedTimestamp: openTimestamp,
        finishedLoading: renderingFinishedTimestamp,
        numberOfConversations: sortedConversations.length,
      })
    }
  }, [loading])

  if (loading) {
    return <LoadingSkeleton />
  }

  return (
    <DrawerWrapper
      ContainerIcon={SingleChat}
      containerTitle="Messages"
      EmptyState={EmptyChats}
      isMobile={isMobile}
      onClose={close}
      renderEmptyState={renderEmptyState}
    >
      <Box ml={isMobile ? 2 : 0}>
        {todaysConversations.length > 0 && (
          <ChatPreviewSection
            conversations={todaysConversations}
            isMobile={isMobile}
            title="Today"
          />
        )}

        {olderConversations.length > 0 && (
          <ChatPreviewSection
            conversations={olderConversations}
            isMobile={isMobile}
            title="Older"
          />
        )}
      </Box>
    </DrawerWrapper>
  )
}
