import React, { useMemo, useState } from 'react'
import { Dayjs } from 'dayjs'
import { Box } from '@material-ui/core'
import { FieldErrors, FieldValues, useForm } from 'react-hook-form'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useCookies } from 'react-cookie'

import { NxButton } from '@playvs-inc/nexus-components'

import {
  formErrorToString,
  formMultiErrorResolver,
  isInputValid,
} from '@plvs/utils'
import {
  DateOfBirthInput,
  EmailInput,
  ErrorMessage,
  PasswordInput,
  UsernameInput,
} from '@plvs/rally/features/auth/components'

import { OAuth } from '@plvs/rally/features/auth/components/OAuth'
import { useOAuth } from '@plvs/rally/features/auth/utils'
import { useAuthRenderControllerContext } from '@plvs/respawn/renderController'
import { Param, Cookie, SessionStorageKey } from '@plvs/const'
import {
  FormValidationMessages,
  extractValidationMessagesFromSchemaForField,
} from '@plvs/rally/components/form/InputValidationErrorView'
import { getEmailLabel, getHelperText } from '../utils/helpers'
import { getFriendlyError, getSchema } from './utils'

interface SignupEmailFormProps<TMutation> {
  birthday?: Dayjs
  isParentSignup: boolean
  onSubmit(
    input: FieldValues,
    referralToken: string,
    birthday?: string
  ): Promise<TMutation | undefined | null>
  oAuthRedirect: string
  resourceId?: string | null
  roleName?: string
}

export const SignupEmailForm = <TInput extends FieldValues, TMutation>({
  birthday,
  isParentSignup,
  onSubmit,
  oAuthRedirect,
  resourceId,
  roleName,
}: SignupEmailFormProps<TMutation>): React.ReactElement => {
  const [cookies] = useCookies([(Param.ReferralToken as unknown) as Cookie])
  const { isEnabled: isOAuthEnabled } = useOAuth()
  const flags = useFlags()
  const [error, setError] = useState<Error>()
  const [isBusy, setIsBusy] = useState<boolean>(false)

  const { auth } = useAuthRenderControllerContext()
  const {
    shouldRenderDOBField,
    shouldRenderUsernameField,
    shouldRenderOAuth,
  } = auth.getAuthComponentsToRender({
    location: window.location.href,
  })

  const SignUpFormSchema = useMemo(
    () =>
      getSchema(
        shouldRenderDOBField,
        shouldRenderUsernameField,
        isParentSignup
      ),
    [shouldRenderDOBField, shouldRenderUsernameField, isParentSignup]
  )

  const passwordMessage: FormValidationMessages[] = extractValidationMessagesFromSchemaForField(
    SignUpFormSchema,
    'password'
  )

  const { errors, handleSubmit, register, watch } = useForm<TInput>({
    resolver: formMultiErrorResolver<TInput>(SignUpFormSchema),
    mode: 'onBlur',
  })
  const fieldErrors = errors as FieldErrors

  const isValid = isInputValid(watch(), SignUpFormSchema)
  const canSubmit = !isBusy && isValid

  const onOAuth = async (): Promise<void> => {
    const formattedBirthday = birthday?.format('YYYY-MM-DD')
    const url = `${oAuthRedirect}?dateOfBirth=${formattedBirthday}&isParent=${isParentSignup}`
    window.location.href = url
  }

  const submit = handleSubmit(
    async (input: FieldValues): Promise<void> => {
      setError(undefined)
      setIsBusy(true)

      try {
        const referralToken = cookies[Cookie.ReferralToken]
        if (sessionStorage) {
          sessionStorage.setItem(
            SessionStorageKey.OrgInvite,
            JSON.stringify({ resourceId, roleName })
          )
        }
        await onSubmit(input, referralToken as string, birthday?.toISOString())
      } catch (e) {
        setError(e as Error)
      } finally {
        setIsBusy(false)
      }
    }
  )

  return (
    <Box
      alignItems="center"
      component="form"
      data-testid="signup-email-form"
      display="flex"
      flex={1}
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
      maxWidth={['100%', '391px']}
      // @ts-ignore This is a valid prop
      method="post"
      noValidate
      onSubmit={submit}
      py={[2]}
      width="100%"
    >
      <ErrorMessage error={getFriendlyError(error?.message)} />

      <Box
        display="flex"
        flexDirection="column"
        maxWidth={['100%', '391px']}
        mt={3}
        textAlign="center"
        width="100%"
      >
        <OAuth
          isBusy={isBusy}
          isEnabled={isOAuthEnabled}
          onOAuth={onOAuth}
          shouldHide={!shouldRenderOAuth}
        />

        {shouldRenderUsernameField && !isParentSignup && (
          <Box mb={0.5}>
            <UsernameInput ref={register} error={fieldErrors.username} />
          </Box>
        )}

        <Box mb={1}>
          <EmailInput
            ref={register}
            error={fieldErrors.email}
            helperText={
              formErrorToString(error) ||
              getHelperText(shouldRenderUsernameField && !isParentSignup)
            }
            label={getEmailLabel(
              flags.personalEmailOptional,
              isParentSignup,
              shouldRenderUsernameField
            )}
          />
        </Box>

        <Box mb={1.5} mt={0.5}>
          <PasswordInput ref={register} message={passwordMessage[0].message} />
        </Box>

        {shouldRenderDOBField && (
          <Box>
            <DateOfBirthInput
              ref={register}
              error={fieldErrors.dateOfBirth}
              label="Date of Birth"
            />
          </Box>
        )}

        <Box mt={2}>
          <NxButton
            data-testid="submit"
            disabled={!canSubmit}
            fullWidth
            label="Create Account"
            loading={isBusy}
            type="submit"
            variant="primary"
          />
        </Box>
      </Box>
    </Box>
  )
}
