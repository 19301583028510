import {
  EsportRating,
  EsportSlug,
  SeasonTeamMatchSort,
  useGetPastMatchesForTeamQuery,
} from '@plvs/graphql'
import { format, toDate } from 'date-fns-tz'
import React, { useMemo } from 'react'
import dayjs from 'dayjs'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { useGetAdapter } from '@plvs/respawn/features/esport/adapters'
import { NxTypography } from '@playvs-inc/nexus-components'
import MatchHistoryItem from '@plvs/respawn/features/teams/MatchHistoryItem'

interface RosterMatchHistoryProps {
  esportSlug: EsportSlug | undefined
  esportRating: EsportRating | undefined
  teamId: string
}

export const RosterMatchHistory: React.FC<RosterMatchHistoryProps> = ({
  esportSlug,
  esportRating,
  teamId,
}) => {
  const beforeDateTimeString = useMemo(() => dayjs().toISOString(), [])
  const { data, loading } = useGetPastMatchesForTeamQuery({
    variables: {
      limit: 500,
      filters: {
        esportSlug: [esportSlug ?? ''],
        teamIds: [teamId],
        startsAt: {
          before: beforeDateTimeString,
        },
      },
      sortFields: [
        {
          fieldName: 'scheduled_starts_at',
          fieldSortDir: SeasonTeamMatchSort.Desc,
        },
      ],
    },
    skip: !teamId,
  })

  const { hasSeries } = useGetAdapter(esportSlug ?? null)

  const pastMatches = (data?.getMatches?.matches ?? []).filter(
    (match) => !match.isScrimmage && match.matchResults?.length
  )

  const matchHistoryItems = pastMatches.slice(0, 7).map((match) => {
    const matchResults = match?.matchResults ?? []

    const homeTeam = matchResults.find(
      (matchResult) => matchResult?.team?.id === teamId
    )

    const status = match?.status

    const opponent = matchResults.filter(
      (matchResult) => matchResult.team?.id !== teamId
    )

    const opponentDetails = {
      avatarUrl: opponent[0]?.team?.school?.logoUrl ?? '',
      schoolName: opponent[0]?.team?.school?.name ?? '',
      name: opponent[0]?.team?.name ?? '',
      teamId: opponent[0]?.team?.id ?? '',
    }

    const date = format(toDate(match?.scheduledStartsAt ?? ''), 'MMM d')

    return (
      <MatchHistoryItem
        key={match.id}
        avatarUrl={opponentDetails?.avatarUrl || undefined}
        esportRating={esportRating}
        lost={(hasSeries ? homeTeam?.seriesLost : homeTeam?.gamesLost) ?? 0}
        matchDate={date}
        matchId={match.id}
        placing={homeTeam?.placing ?? 0}
        schoolName={opponentDetails?.schoolName}
        status={status}
        teamId={opponentDetails?.teamId}
        teamName={opponentDetails?.name}
        won={(hasSeries ? homeTeam?.seriesWon : homeTeam?.gamesWon) ?? 0}
      />
    )
  })

  return (
    <Box py={1}>
      <WaitTillLoaded
        loading={loading}
        loadingSpinnerProps={{ size: 'medium' }}
        showSpinnerWhileLoading
      >
        {matchHistoryItems.length ? (
          matchHistoryItems
        ) : (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            py={6}
          >
            <NxSpot
              domain="megaphone"
              height="136px"
              size="large"
              variant="secondary"
              width="136px"
            />
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              py={2}
            >
              <NxTypography variant="h4">No matches yet</NxTypography>
              <NxTypography variant="body1">
                This team hasn‘t played any matches
              </NxTypography>
            </Box>
          </Box>
        )}
      </WaitTillLoaded>
    </Box>
  )
}
