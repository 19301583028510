import React, { useState, useEffect } from 'react'
import {
  Theme,
  ThemeProvider as MuiThemeProvider,
  CssBaseline,
} from '@material-ui/core'
import { MUIThemeModeEnum } from '@playvs-inc/nexus-theme'
import { LocalStorageKey } from '@plvs/const'
import { mergeThemes, theme as defaultTheme } from '@plvs/rally/themes'
import { useUserIdentityFn } from '@plvs/client-data/hooks'

export interface ThemeProviderContext {
  changeTheme(): void
  setThemeMode(themeMode: MUIThemeModeEnum): void
  themeMode: MUIThemeModeEnum
  theme: Theme
}

export const themeProviderContext = React.createContext<ThemeProviderContext>({
  changeTheme: () => {},
  setThemeMode: () => {},
  themeMode: MUIThemeModeEnum.Light,
  theme: defaultTheme,
})

function getThemeMode(themeNamespace: string): MUIThemeModeEnum {
  const storedThemeMode = window.localStorage.getItem(themeNamespace)
  switch (storedThemeMode) {
    case MUIThemeModeEnum.Dark:
      return MUIThemeModeEnum.Dark
    case MUIThemeModeEnum.Light:
    default:
      return MUIThemeModeEnum.Light
  }
}

export function useThemeProviderContext(): ThemeProviderContext {
  return React.useContext(themeProviderContext)
}

export const ThemeProvider: React.FC = ({ children }) => {
  const { userId } = useUserIdentityFn()
  const themeNamespace = `${userId}_${LocalStorageKey.PlayVsTheme}`
  const [themeMode, setThemeMode] = useState<MUIThemeModeEnum>(
    getThemeMode(themeNamespace)
  )
  const [theme, setTheme] = useState<Theme>(defaultTheme)

  const getNextTheme = (): MUIThemeModeEnum => {
    return !themeMode || themeMode === MUIThemeModeEnum.Light
      ? MUIThemeModeEnum.Dark
      : MUIThemeModeEnum.Light
  }

  const changeTheme = (): void => {
    const nextTheme = getNextTheme()
    setThemeMode(nextTheme)
    window.localStorage.setItem(themeNamespace, getNextTheme())
  }

  useEffect(
    function updateTheme() {
      const incomingTheme = mergeThemes(themeMode)
      setTheme(incomingTheme)
      if (window.document) {
        const htmlRoot = window.document.querySelector('#root')
        if ((htmlRoot as HTMLDivElement)?.style) {
          document.body.style.backgroundColor =
            incomingTheme.palette.ColorBackgroundBody ??
            theme.palette.background.default
          ;(htmlRoot as HTMLDivElement).style.backgroundColor =
            incomingTheme.palette.ColorBackgroundBody ??
            theme.palette.background.default
          const htmlTag = document.querySelector('html')
          if (htmlTag) {
            htmlTag.className = incomingTheme.palette.type
          }
        }
      }
    },
    [themeMode]
  )

  useEffect(
    function updateThemeForUser() {
      setThemeMode(getThemeMode(themeNamespace))
    },
    [themeNamespace]
  )

  const values = {
    changeTheme,
    setThemeMode,
    themeMode,
    theme,
  }

  return (
    <themeProviderContext.Provider value={values}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {children instanceof Function
          ? children({ themeMode, theme })
          : children}
      </MuiThemeProvider>
    </themeProviderContext.Provider>
  )
}
