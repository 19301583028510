import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { MetaseasonFilter } from '@plvs/rally/components/filter/MetaseasonFilter'
import { PhaseFilter } from '@plvs/rally/components/filter/PhaseFilter'
import { makeStyles } from '@material-ui/core'
import {
  PhaseFilterType,
  SlotFilterType,
} from '@plvs/rally/features/explore/LeagueDetails.types'
import {
  FilterSlot,
  SlotFilter,
} from '@plvs/rally/components/filter/SlotFilter'
import { sortByMaybeStartsAt } from '@plvs/utils'
import { SelectedTeam } from '@plvs/rally/components/search/TeamSearch/types'
import { head } from 'ramda'

export const useStyles = makeStyles((theme) => ({
  metaseasonFilter: {
    marginRight: theme.spacing(2),
  },
  slotFilter: {
    marginLeft: theme.spacing(2),
  },
}))

interface Props {
  phase: PhaseFilterType | undefined
  phases: PhaseFilterType[]
  setPhase: Dispatch<SetStateAction<PhaseFilterType | undefined>>
  slotFilter: SlotFilterType | undefined
  setSlotFilter:
    | Dispatch<SetStateAction<SlotFilterType | undefined>>
    | undefined
  team: SelectedTeam | null
}

export const EmbedFilters: React.FC<Props> = ({
  phase,
  phases,
  setPhase,
  slotFilter,
  setSlotFilter,
  team,
}) => {
  const classes = useStyles()

  const {
    setMetaseason,
    metaseason,
    metaseasons,
  } = useSchoolLeagueInfoContext()
  const handleSetPhase = (phaseId: string): void => {
    const newPhase = phases.find((p) => p.id === phaseId)
    setPhase(newPhase)
  }
  const slots = phase?.slots ?? []

  const filterSlots = sortByMaybeStartsAt(slots).map((slot, index) => ({
    ...slot,
    name: `Week ${index + 1}`,
  }))
  const handleSetSlot = (slotId: string): void => {
    if (setSlotFilter) {
      const newSlot = filterSlots.find((s) => s.id === slotId)
      setSlotFilter(newSlot)
    }
  }
  useEffect(() => {
    if (filterSlots) {
      const firstSlot = head(filterSlots)
      if (firstSlot) {
        handleSetSlot(firstSlot.id)
      } else {
        handleSetSlot('')
      }
    }
  }, [filterSlots.toString()])
  return (
    <>
      <MetaseasonFilter
        className={classes.metaseasonFilter}
        metaseason={metaseason}
        metaseasons={metaseasons}
        setMetaseason={setMetaseason}
      />
      <PhaseFilter phase={phase} phases={phases} setPhase={handleSetPhase} />
      {setSlotFilter && (
        <SlotFilter
          className={classes.slotFilter}
          disabled={!!team}
          setSlot={handleSetSlot}
          slot={slotFilter as FilterSlot}
          slots={filterSlots}
        />
      )}
    </>
  )
}
