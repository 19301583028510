import React, { useEffect, useState } from 'react'
import { NxTypography, NxEmptyState } from '@playvs-inc/nexus-components'
import {
  useAvailableWeeklyAwardPlayersQuery,
  useAvailableWeeklyAwardWeeksQuery,
} from '@plvs/graphql/generated'
import { Box, WaitTillLoaded } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { SelectObject } from '@plvs/rally/components/filter'
import dayjs from '@plvs/respawn/init/dayjs'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { isNil } from 'ramda'
import { MySchoolLeagues } from '@plvs/rally/containers/filter/league/MySchoolLeagues'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SelectPlayerOfTheWeek } from './SelectPlayer/SelectPlayerOfTheWeek'
import { formatWeekRange } from './awardsHelpers'
import { SeasonalAwards } from './seasonal-awards/SeasonalAwards'
import { WeekCompletedMatches } from './WeekCompletedMatches'

export const useStyles = makeStyles((theme) => ({
  selectPlayer: {
    marginBottom: theme.spacing(4),
  },
  matchCard: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  weekFilter: {
    marginLeft: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      marginTop: theme.spacing(2),
    },
  },
}))

interface Props {
  schoolId: string
  schoolName: string
}

export const Awards: React.FC<Props> = ({ schoolId, schoolName }) => {
  const flags = useFlags()

  const classes = useStyles()

  const [week, setWeek] = useState<{ id: string; name: string }>()

  const {
    data: weeksData,
    loading: weeksLoading,
    refetch,
  } = useAvailableWeeklyAwardWeeksQuery()

  const {
    data: playersData,
    loading: playersLoading,
  } = useAvailableWeeklyAwardPlayersQuery({
    skip: weeksLoading || !week,
    variables: {
      input: { beginningOfWeekTime: week?.id ?? '' },
    },
  })

  const refetchAwardData = async (): Promise<void> => {
    await refetch()
  }

  useEffect(() => {
    if (!weeksLoading) {
      const weeks = weeksData?.availableWeeklyAwardWeeks?.availableWeeks ?? []
      if (weeks.length > 0) {
        const now = dayjs()

        const currentWeek = weeks.find((w) =>
          now.isBetween(
            dayjs(w?.beginningOfWeekTime ?? ''),
            dayjs(w?.endDate ?? ''),
            '[]'
          )
        )

        if (currentWeek) {
          setWeek({
            id: currentWeek?.beginningOfWeekTime ?? '',
            name: formatWeekRange(
              currentWeek?.beginningOfWeekTime ?? '',
              currentWeek?.endDate ?? ''
            ),
          })
        } else {
          setWeek({
            id: weeks?.[0]?.beginningOfWeekTime ?? '',
            name: formatWeekRange(
              weeks?.[0]?.beginningOfWeekTime ?? '',
              weeks?.[0]?.endDate ?? ''
            ),
          })
        }
      }
    }
  }, [weeksLoading])

  const availableWeeks =
    weeksData?.availableWeeklyAwardWeeks?.availableWeeks ?? []

  const weekOptions = availableWeeks.map((availableWeek) => ({
    id: availableWeek?.beginningOfWeekTime ?? '',
    name: formatWeekRange(
      availableWeek?.beginningOfWeekTime ?? '',
      availableWeek?.endDate ?? ''
    ),
  }))

  const players =
    playersData?.availableWeeklyAwardPlayers?.availablePlayers ?? []

  const currentAssignedAwardUserId = availableWeeks.find(
    (aw) => aw?.beginningOfWeekTime === week?.id
  )?.assignedUserId

  return (
    <>
      <Box
        alignItems={['start', 'center']}
        display="flex"
        flexDirection={['column', 'row']}
        mb={3}
      >
        <NxTypography variant="h3">
          Celebrate Your Player’s Achievements
        </NxTypography>
        {!isNil(week) && !weeksLoading && (
          <SelectObject
            className={classes.weekFilter}
            option={week}
            options={weekOptions}
            renderValue={(w): string => w?.name ?? 'n/a'}
            setOption={(w): void => setWeek(w)}
          />
        )}
      </Box>
      <WaitTillLoaded
        loading={weeksLoading || playersLoading}
        showSpinnerWhileLoading
      >
        {availableWeeks.length > 0 ? (
          <SelectPlayerOfTheWeek
            beginningOfWeek={week}
            className={classes.selectPlayer}
            currentAssignedAwardUserId={currentAssignedAwardUserId}
            players={players}
            refetchAwardData={refetchAwardData}
            schoolName={schoolName}
          />
        ) : (
          <NxEmptyState
            spot={
              <NxSpot
                domain="trophy"
                height={100}
                size="large"
                variant="secondary"
                width={100}
              />
            }
            subtitle="Coaches can nominate players starting on the first week of the regular season."
            title="Player Nomination Not Available"
          />
        )}
        <WeekCompletedMatches
          beginningOfWeekTime={week?.id}
          schoolId={schoolId}
        />
      </WaitTillLoaded>
      {flags.seasonalPlayerAwards && (
        <MySchoolLeagues shouldUseEligibleMetaseasons>
          <SeasonalAwards />
        </MySchoolLeagues>
      )}
    </>
  )
}
