import React from 'react'
import { Card, CardContent, makeStyles } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useGetMyAccountDetailsQuery } from '@plvs/graphql'
import { Box } from '@plvs/respawn/features/layout'
import { MyAvatarCircle } from '@plvs/rally/containers/avatar'
import { NxTypography } from '@playvs-inc/nexus-components'

import { NxAvatarSize } from '@plvs/respawn/features/avatar/NxUserAvatar'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { SetUserAvatar } from '@plvs/respawn/features/account/SetUserAvatar'
import { DisconnectParentAlert } from '@plvs/respawn/features/account/childSettings/DisconnectParentAlert'
import { AccountSettingsAccountInfo } from './AccountSettingsAccountInfo'
import AccountSettingsForm from './AccountSettingsForm'
import AboutUs from './AboutUs'

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  cardContent: {
    padding: theme.spacing(3),
  },
}))

const AccountProfileSettings: React.FC = () => {
  const { data, loading, error, refetch } = useGetMyAccountDetailsQuery({
    fetchPolicy: 'network-only',
  })

  const { userId, isUnderage } = useUserIdentityFn()

  const me = data?.me

  const classes = useStyles()
  const parentUserId = me?.parent?.id

  return (
    <>
      {!isUnderage && userId && parentUserId && (
        <Box className={classes.card}>
          <DisconnectParentAlert
            onDisconnect={refetch}
            parentUserId={parentUserId}
            userId={userId}
          />
        </Box>
      )}
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <NxTypography variant="h2">Personal Information</NxTypography>
          <Box alignItems="center" display="flex" mb={4} mt={2}>
            <MyAvatarCircle size={NxAvatarSize.LG} />
            <Box ml={2}>
              <SetUserAvatar userId={userId} />
            </Box>
          </Box>
          {((): React.ReactElement => {
            if (loading)
              return (
                <Skeleton
                  data-testid="skeleton"
                  height="12rem"
                  variant="rect"
                  width="100%"
                />
              )
            if (error) return <span>Cannot display account details</span>

            return me ? (
              <AccountSettingsForm isUnderage={isUnderage} me={me} />
            ) : (
              <></>
            )
          })()}
        </CardContent>
      </Card>
      <Card>
        <CardContent className={classes.cardContent}>
          <NxTypography variant="h2">Security &amp; Privacy</NxTypography>
          {loading ? (
            <Skeleton height="20rem" variant="rect" width="100%" />
          ) : (
            <Box mt={2}>
              <AccountSettingsAccountInfo
                accountDetailsData={data}
                accountDetailsError={error}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      <AboutUs />
    </>
  )
}

export default AccountProfileSettings
