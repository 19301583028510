import {
  MATCH_PAST_STATUSES,
  ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS,
  hasRoleForResource,
  isAdminForSystem,
  isCoachForScholasticRelatedTeam,
  isMemberOfOrganization,
  isTeamOwnerForResource,
} from '@plvs/utils'
import {
  EsportRating,
  MatchStatus,
  UserRoleName,
} from '@plvs/graphql/generated'
import { MenuGroup } from '@playvs-inc/nexus-components'
import {
  coachMenuItemIds,
  genericQueueBannerActionsCopy,
  HomeAndAwayTeamDisplayDetailsProps,
  HomeAndAwayTeamDisplayDetailsReturn,
  LobbyComponentProps,
  LobbyComponentReturn,
  ManageMatchDropdownMenuItems,
  ManageMatchDropdownMenuItemsReturn,
  MenuItemIdsForManageMatchDropdownProps,
  nonScholasticMenuItemIdsForAllRoles,
  ownerMenuItemIds,
  scholasticMenuItemIdsForAllRoles,
} from '../types/lobbyRenderController.types'
import { UserRolesForMatchLobbyRenderController } from '../../MatchLobbyRenderController.types'
import { MatchTeamForRenderController } from '../../match/types/matchRenderController.types'

export const determineTeamIds = ({
  team1,
  team2,
  userRoles,
  teamId,
}: {
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  userRoles: UserRolesForMatchLobbyRenderController
  teamId?: string
}): {
  myTeamIds: Array<string>
  opposingTeamId: string
  isMemberOfBothTeams: boolean
} => {
  const isAdmin = isAdminForSystem(userRoles ?? [])
  const isCoachForTeam1 =
    isCoachForScholasticRelatedTeam(userRoles, team1) || team1.id === teamId
  const isCoachForTeam2 =
    isCoachForScholasticRelatedTeam(userRoles, team2) || team2.id === teamId
  const isMemberOfBothTeams = isCoachForTeam1 && isCoachForTeam2

  if (isMemberOfBothTeams || isAdmin) {
    return {
      myTeamIds: [team1.id, team2.id],
      opposingTeamId: '',
      isMemberOfBothTeams: true,
    }
  }

  if (isCoachForTeam1) {
    return {
      myTeamIds: [team1.id],
      opposingTeamId: team2.id,
      isMemberOfBothTeams: false,
    }
  }

  if (isCoachForTeam2) {
    return {
      myTeamIds: [team2.id],
      opposingTeamId: team1.id,
      isMemberOfBothTeams: false,
    }
  }

  if (isAdmin) {
    return {
      myTeamIds: [team1.id],
      opposingTeamId: team2.id,
      isMemberOfBothTeams: false,
    }
  }

  return {
    // Default to home vs away
    myTeamIds: [team1.id],
    opposingTeamId: team2.id,
    isMemberOfBothTeams: false,
  }
}

export const determineLobbyComponentsRenderForOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
  shouldRenderMatchAssistant,
  status,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  shouldRenderMatchAssistant: boolean
  status: MatchStatus | undefined
}): {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  showActionAndInfoSection: boolean
  canManageQueueBannerActions: boolean
  canManageQueueBannerActionsCopy: string
  showRescheduleMatchAlert: boolean
} => {
  const isMemberOfEitherSchoolInMatch = isMemberOfOrganization(userRoles, [
    team1.schoolId ?? '',
    team2.schoolId ?? '',
  ])

  const userIsCoachForEitherTeam =
    isCoachForScholasticRelatedTeam(userRoles, team1) ||
    isCoachForScholasticRelatedTeam(userRoles, team2)

  const isMatchClosed = status && MATCH_PAST_STATUSES.includes(status)

  return {
    showManageMatchSection: userIsCoachForEitherTeam,
    showMatchLobbyTour:
      !isMatchClosed &&
      isMemberOfEitherSchoolInMatch &&
      shouldRenderMatchAssistant,
    showActionAndInfoSection: isMemberOfEitherSchoolInMatch,
    canManageQueueBannerActions: userIsCoachForEitherTeam,
    canManageQueueBannerActionsCopy: userIsCoachForEitherTeam
      ? genericQueueBannerActionsCopy
      : 'After finishing your match or if your opponent forfeits, your coach can now enter your team to play against other teams without worrying about your standings. Ask your coach to find a match!',
    showRescheduleMatchAlert: userIsCoachForEitherTeam,
  }
}

export const determineLobbyComponentsRenderForNonOrgBasedMatch = ({
  userRoles,
  team1,
  team2,
  shouldRenderMatchAssistant,
  status,
}: {
  userRoles: UserRolesForMatchLobbyRenderController
  team1: MatchTeamForRenderController
  team2: MatchTeamForRenderController
  shouldRenderMatchAssistant: boolean
  status: MatchStatus | undefined
}): {
  showManageMatchSection: boolean
  showMatchLobbyTour: boolean
  showActionAndInfoSection: boolean
  canManageQueueBannerActions: boolean
  canManageQueueBannerActionsCopy: string
  showRescheduleMatchAlert: boolean
} => {
  const isParticipantInMatch =
    hasRoleForResource(userRoles, [team1.id, team1.schoolId]) ||
    hasRoleForResource(userRoles, [team2.id, team2.schoolId])

  const isTeamOwnerOfEitherTeam = isTeamOwnerForResource(userRoles, [
    team1.id,
    team2.id,
  ])

  const isMatchClosed = status && MATCH_PAST_STATUSES.includes(status)

  return {
    showManageMatchSection: isTeamOwnerOfEitherTeam,
    showMatchLobbyTour:
      !isMatchClosed && isParticipantInMatch && shouldRenderMatchAssistant,
    showActionAndInfoSection: isParticipantInMatch,
    canManageQueueBannerActions: isTeamOwnerOfEitherTeam,
    canManageQueueBannerActionsCopy: isTeamOwnerOfEitherTeam
      ? genericQueueBannerActionsCopy
      : 'After finishing your match or if your opponent forfeits, your team owner can now enter your team to play against other teams without worrying about your standings. Ask your team owner to find a match!',
    showRescheduleMatchAlert: false,
  }
}

export const determineLobbyComponentsRender = ({
  status,
  userRoles,
  team1,
  team2,
  isMatchAssistantEnabled,
  competitionGroup,
}: LobbyComponentProps): LobbyComponentReturn => {
  if (!team1 || !team2) {
    return {
      showManageMatchSection: false,
      showMatchLobbyTour: false,
      myTeamIds: [],
      opposingTeamId: '',
      isMemberOfBothTeams: false,
      showActionAndInfoSection: false,
      canManageQueueBannerActions: false,
      canManageQueueBannerActionsCopy: '',
      showRescheduleMatchAlert: false,
      enableScoutingToTeamsPage: false,
    }
  }

  const matchIsNotQuarantined = status !== MatchStatus.Quarantined
  const shouldRenderMA = isMatchAssistantEnabled && matchIsNotQuarantined
  const { myTeamIds, opposingTeamId, isMemberOfBothTeams } = determineTeamIds({
    team1,
    team2,
    userRoles,
  })
  const renderedOpposingTeamId = isMemberOfBothTeams
    ? myTeamIds[1]
    : opposingTeamId

  if (!competitionGroup) {
    return {
      showManageMatchSection: false,
      showMatchLobbyTour: false,
      myTeamIds,
      opposingTeamId: renderedOpposingTeamId,
      isMemberOfBothTeams,
      showActionAndInfoSection: false,
      canManageQueueBannerActions: false,
      canManageQueueBannerActionsCopy: '',
      showRescheduleMatchAlert: false,
      enableScoutingToTeamsPage: false,
    }
  }

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    const {
      showManageMatchSection,
      showMatchLobbyTour,
      showActionAndInfoSection,
      canManageQueueBannerActions,
      canManageQueueBannerActionsCopy,
      showRescheduleMatchAlert,
    } = determineLobbyComponentsRenderForOrgBasedMatch({
      userRoles,
      team1,
      team2,
      shouldRenderMatchAssistant: shouldRenderMA,
      status,
    })
    return {
      showManageMatchSection,
      showMatchLobbyTour,
      myTeamIds,
      opposingTeamId,
      isMemberOfBothTeams,
      showActionAndInfoSection,
      canManageQueueBannerActions,
      canManageQueueBannerActionsCopy,
      showRescheduleMatchAlert,
      enableScoutingToTeamsPage: true,
    }
  }

  const {
    showManageMatchSection,
    showMatchLobbyTour,
    showActionAndInfoSection,
    canManageQueueBannerActionsCopy,
    canManageQueueBannerActions,
    showRescheduleMatchAlert,
  } = determineLobbyComponentsRenderForNonOrgBasedMatch({
    userRoles,
    team1,
    team2,
    shouldRenderMatchAssistant: shouldRenderMA,
    status,
  })
  return {
    showManageMatchSection,
    showMatchLobbyTour,
    myTeamIds,
    opposingTeamId,
    isMemberOfBothTeams,
    showActionAndInfoSection,
    canManageQueueBannerActions,
    canManageQueueBannerActionsCopy,
    showRescheduleMatchAlert,
    enableScoutingToTeamsPage: false,
  }
}

export const determineHomeAndAwayTeamDisplayDetails = ({
  team1,
  team2,
  competitionGroup,
  esportRating,
}: HomeAndAwayTeamDisplayDetailsProps): HomeAndAwayTeamDisplayDetailsReturn => {
  if (!competitionGroup) {
    return {
      homeTeam: {
        id: team1?.id ?? '',
        teamName: team1?.name ?? '',
        avatarUrl: team1?.avatarUrl ?? '',
      },

      awayTeam: {
        id: team2?.id ?? '',
        teamName: team2?.name ?? '',
        avatarUrl: team2?.avatarUrl ?? '',
      },
    }
  }
  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    if (esportRating === EsportRating.Restricted) {
      return {
        homeTeam: {
          id: team1?.id ?? '',

          teamName: team1?.name ?? '',
          avatarUrl: '',
          schoolSlug: '',
          schoolName: '',
        },
        awayTeam: {
          id: team2?.id ?? '',
          teamName: team2?.name ?? '',
          avatarUrl: '',
          schoolSlug: '',
          schoolName: '',
        },
      }
    }
    return {
      homeTeam: {
        id: team1?.id ?? '',
        teamName: team1?.name ?? '',
        avatarUrl: team1?.avatarUrl ?? '',
        schoolSlug: team1?.schoolSlug ?? '',
        schoolName: team1?.schoolName ?? '',
      },
      awayTeam: {
        id: team2?.id ?? '',
        teamName: team2?.name ?? '',
        avatarUrl: team2?.avatarUrl ?? '',
        schoolSlug: team2?.schoolSlug ?? '',
        schoolName: team2?.schoolName ?? '',
      },
    }
  }
  return {
    homeTeam: {
      id: team1?.id ?? '',
      teamName: team1?.name ?? '',
      avatarUrl: team1?.avatarUrl ?? '',
    },
    awayTeam: {
      id: team2?.id ?? '',
      teamName: team2?.name ?? '',
      avatarUrl: team2?.avatarUrl ?? '',
    },
  }
}

export function getRenderedMenuItemsForManageMatchDropdown(
  menuItems: MenuGroup[],
  menuItemIds: ManageMatchDropdownMenuItems[]
): MenuGroup[] {
  return menuItems
    .map((menuItem) => {
      const filteredItems = menuItem.items.filter((item) =>
        menuItemIds.includes(item.id as ManageMatchDropdownMenuItems)
      )

      return filteredItems.length > 0
        ? { ...menuItem, items: filteredItems }
        : null
    })
    .filter(Boolean) as MenuGroup[]
}

export const determineMenuItemIdsForManageMatchDropdown = ({
  competitionGroup,
  userRoles,
  team1,
  team2,
}: MenuItemIdsForManageMatchDropdownProps): ManageMatchDropdownMenuItemsReturn => {
  if (!competitionGroup) {
    return {
      items: [],
    }
  }

  const isCoachForSomeResource = hasRoleForResource(
    userRoles,
    [team1?.id, team2?.id, team1?.schoolId, team2?.schoolId],
    UserRoleName.Coach
  )

  const isOwnerForSomeResource = hasRoleForResource(
    userRoles,
    [team1?.id, team2?.id],
    UserRoleName.Owner
  )

  if (ORGANIZATION_ASSOCIATED_COMPETITION_GROUPS.includes(competitionGroup)) {
    return {
      items: isCoachForSomeResource
        ? [...scholasticMenuItemIdsForAllRoles, ...coachMenuItemIds]
        : scholasticMenuItemIdsForAllRoles,
    }
  }
  return {
    items: isOwnerForSomeResource
      ? [...nonScholasticMenuItemIdsForAllRoles, ...ownerMenuItemIds]
      : nonScholasticMenuItemIdsForAllRoles,
  }
}
