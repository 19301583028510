import { Box } from '@plvs/respawn/features/layout'
import React, { useMemo } from 'react'
import { NxTextLink, NxTypography, Pill } from '@playvs-inc/nexus-components'
import { generatePath } from 'react-router-dom'
import { Path } from '@plvs/const'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { UserRoleStatusName } from '@plvs/graphql/generated'
import { RosterCardMenu } from './rosterChildren/menus/RosterCardMenu'
import { TeamLeaguesStatus } from './TeamLeaguesStatus'
import { useRosterContext } from './RosterProvider'
import { RosterSeasonBanner } from './RosterSeasonBanner'
import { getStatusIconFromTeamStatus } from './RosterCard.helpers'
import { useRosterDefaultHeaderStyles } from './RosterDefaultHeader.styles'
import { NxAvatarSize, NxUserAvatar } from '../avatar/NxUserAvatar'

interface RosterDefaultHeaderProps {
  showAdditionalContainerActions: boolean
  shouldRenderTeamAvatar: boolean
  enableScoutingToTeamsPage: boolean
  shouldShowRosterTitle?: boolean
}

export const RosterDefaultHeader: React.FC<RosterDefaultHeaderProps> = ({
  showAdditionalContainerActions,
  shouldRenderTeamAvatar,
  enableScoutingToTeamsPage,
  shouldShowRosterTitle = true,
}) => {
  const {
    team,
    teamId,
    isEnrolled,
    isRosterActivelyLocked,
    teamStatus,
    onMutationSuccessInRosterFlow,
    userRoles,
    enrolledSeason,
    metaseason,
    promotedMetaseason,
    getRosterCardPermissions,
    isTeamUnenrollmentLocked,
    rosterPlayers,
  } = useRosterContext()

  const flags = useFlags()

  const isTeamFull = !teamStatus?.isMissingPlayers

  const isTeamFullyConnected = teamStatus?.isFullyConnected ?? false

  const classes = useRosterDefaultHeaderStyles({
    active: isEnrolled && isTeamFull && isTeamFullyConnected,
  })

  const hasArchivedPlayers = rosterPlayers.some((player) => {
    return (player?.schoolRoleStatus ?? []).some(
      (schoolRoleStatus) =>
        schoolRoleStatus?.status === UserRoleStatusName.Graduated ||
        schoolRoleStatus?.status === UserRoleStatusName.Transferred
    )
  })

  const teamStatusIcon = useMemo(
    (): React.ReactElement | null =>
      getStatusIconFromTeamStatus(teamStatus, isEnrolled, hasArchivedPlayers),
    [teamStatus, isEnrolled, hasArchivedPlayers]
  )
  const scoutPath = teamId ? generatePath(Path.TeamScouting, { teamId }) : ''

  return (
    <Box className={classes.titleContainer}>
      <Box px={3} width="100%">
        <Box
          alignItems="center"
          className={classes.titleWrapper}
          display="flex"
          flexDirection="row"
          pt={1}
        >
          {shouldRenderTeamAvatar && (
            <NxUserAvatar
              avatarUrl={`${team?.avatarUrl ?? ''}?${Date.now()}`}
              className={classes.avatar}
              hashId={team?.id ?? ''}
              size={NxAvatarSize.MD}
            />
          )}
          <Box display="flex" flex={1}>
            {!shouldRenderTeamAvatar && <Box pr={0.5}>{teamStatusIcon}</Box>}

            {flags.addTeamLinkOnTeamCard && enableScoutingToTeamsPage ? (
              <NxTextLink
                className={classes.titleLink}
                href={scoutPath}
                label={team?.name ?? ''}
                labelVariant="h3"
              />
            ) : (
              <NxTypography className={classes.title} variant="h3">
                {team?.name ?? ''}
              </NxTypography>
            )}
          </Box>
          {showAdditionalContainerActions && (
            <Box alignItems="center" display="flex" flex={0}>
              <RosterCardMenu
                getPermissions={getRosterCardPermissions}
                isEnrolled={isEnrolled}
                isTeamUnenrollmentLocked={isTeamUnenrollmentLocked}
                onMutationSuccess={onMutationSuccessInRosterFlow}
                teamId={teamId}
                teamName={team?.name ?? ''}
                userRoles={userRoles}
              />
            </Box>
          )}
        </Box>
        {shouldShowRosterTitle && team?.leagues?.length ? (
          <Box className={classes.titleDetail}>
            <Box className={classes.titleDetailContent}>
              <TeamLeaguesStatus enrolledLeagueNames={team?.leagues || []} />
            </Box>
          </Box>
        ) : null}
        <Box>
          {!shouldRenderTeamAvatar && (
            <>
              {isEnrolled ? (
                <Pill label="Enrolled" size="small" variant="success" />
              ) : (
                <Pill label="Not Enrolled" size="small" variant="warn" />
              )}
            </>
          )}
        </Box>

        {metaseason && promotedMetaseason && (
          <RosterSeasonBanner
            enrolledSeason={enrolledSeason}
            isRosterActivelyLocked={isRosterActivelyLocked}
            metaseason={metaseason}
            promotedMetaseason={promotedMetaseason}
          />
        )}
      </Box>
    </Box>
  )
}
