import { Path, ChildOnboardPath } from '@plvs/const'

export const getNextPath = ({
  childId,
  path,
}: {
  childId: string | undefined
  path: ChildOnboardPath
}): string => {
  const basePath = `${Path.App}${Path.ChildSpawnPoint}/${path}`
  if (childId) {
    return `${basePath}/${childId}`
  }

  return basePath
}
