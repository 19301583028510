import {
  CompetitionGroup,
  CompetitionModel,
  EsportRating,
  EsportSlug,
  League,
  PlayerSeasonStatsFragment,
  ResourceType,
  SmashUltimateStatsPlayerFragment,
  useGetPlayerSeasonStatsQuery,
  useGetSmashUltimateStatsQuery,
  useTeamRosterQuery,
} from '@plvs/graphql'
import {
  ESPORTS_WITH_STATS,
  MinimalMetaseason,
  RelativeTiming,
  canUnenrollTeam,
  hasRoleForResource,
  isAdminForSystem,
  isAssociatedToOrganization,
  isCoachForScholasticRelatedTeam,
  isCurrentSeason,
  isSeasonRostersActivelyLocked,
  isTeamOwnerForResource,
  useAutoskipQuery,
} from '@plvs/utils'

import {
  useAllTeamsAccountStatusFns,
  useUserIdentityFn,
} from '@plvs/client-data/hooks'

import { head } from 'ramda'
import React from 'react'
import { Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import {
  ContentContextPermissions,
  getRosterMenuRowItems,
  getRulesByContentPermissions,
  GrantedRosterMenuPermissions,
  Roles,
} from '@plvs/respawn/features/roster/teamManagement/rosterMenuHelpersV2'
import {
  EnrolledSeason,
  ProviderBenchRosterPlayer,
  ProviderStarterRosterPlayer,
  RosterMember,
  TeamRoster,
  TeamStatus,
  UserRole,
} from './RosterProvider.types'

type RosterContext = {
  userId: string
  teamId: string
  teamIds?: string[] | []
  loading: boolean
  isPast: boolean
  isTeamPlayerLed: boolean
  metaseason: MinimalMetaseason | undefined
  promotedMetaseason: MinimalMetaseason | undefined
  onMutationSuccessInRosterFlow(effectiveAtDate?: string): Promise<void>
  isTeamAssociatedWithOrg: boolean
  competitionGroup: CompetitionGroup | undefined
  competitionModel: CompetitionModel
  isEnrolled: boolean
  esport: {
    id: string
    slug: EsportSlug | undefined
    rating: EsportRating | undefined
  }
  isEnrolledSeasonActivelyLocked: boolean
  isRosterActivelyLocked: boolean
  isTeamUnenrollmentLocked: boolean
  rosterPlayers: RosterMember[]
  starters: ProviderStarterRosterPlayer[]
  substitutes: ProviderBenchRosterPlayer[]
  teamCaptain: RosterMember | undefined
  userRoles: UserRole[]
  team: TeamRoster
  teamStatus: TeamStatus | undefined
  enrolledSeason: EnrolledSeason | undefined
  isTeamHidden: boolean
  teamFormat: number
  addToTeamMutationSuccess(
    input?: string,
    effectiveAtDate?: string
  ): Promise<void>
  isBenchFull: boolean
  getRosterCardPermissions(
    memberRoles: Roles,
    contentContext: ContentContextPermissions,
    isSelf: boolean
  ): GrantedRosterMenuPermissions
  schoolId: string
  teamColor: string | undefined
  league: Pick<League, 'id' | 'name'> | undefined
  publicView: boolean
  smashStatsByPlayer: Record<string, SmashUltimateStatsPlayerFragment>
  statsByPlayer: Record<string, PlayerSeasonStatsFragment>
  isAssociatedWithTeam: boolean
  isCoach: boolean
  isTeamOwnerForResource: boolean
}

export interface RosterProviderProps {
  teamId: string
  teamIds?: string[]
  date?: string | null
  teamColor?: string
  metaseason?: MinimalMetaseason
  promotedMetaseason?: MinimalMetaseason
  isPast?: boolean
  overrideTeamRosterViewPermissions?: boolean
  userRoles?: UserRole[]
  publicView?: boolean
}

export const rosterContext = React.createContext<RosterContext>({
  userId: '',
  teamId: '',
  teamIds: [],
  loading: false,
  isPast: false,
  isTeamPlayerLed: false,
  metaseason: undefined,
  onMutationSuccessInRosterFlow: async (): Promise<void> => {},
  isTeamAssociatedWithOrg: false,
  competitionGroup: undefined,
  competitionModel: CompetitionModel.Rec,
  isEnrolled: false,
  esport: {
    id: '',
    slug: undefined,
    rating: undefined,
  },
  isEnrolledSeasonActivelyLocked: false,
  isTeamUnenrollmentLocked: false,
  isRosterActivelyLocked: false,
  rosterPlayers: [],
  substitutes: [],
  starters: [],
  teamCaptain: undefined,
  // teamCoaches: [],
  promotedMetaseason: undefined,
  userRoles: [],
  team: null,
  teamStatus: undefined,
  enrolledSeason: undefined,
  isTeamHidden: false,
  teamFormat: 0,
  addToTeamMutationSuccess: async (): Promise<void> => {},
  isBenchFull: false,
  getRosterCardPermissions: () => [],
  schoolId: '',
  teamColor: undefined,
  league: undefined,
  publicView: false,
  smashStatsByPlayer: {},
  statsByPlayer: {},
  isAssociatedWithTeam: false,
  isCoach: false,
  isTeamOwnerForResource: false,
})

export const useRosterContext = (): RosterContext => {
  return React.useContext(rosterContext)
}

export const RosterProvider: React.FC<RosterProviderProps> = ({
  children,
  teamId,
  teamIds,
  metaseason,
  promotedMetaseason,
  date,
  teamColor,
  isPast = false,
  userRoles: defaultUserRoles,
  overrideTeamRosterViewPermissions = false,
  publicView,
}) => {
  const navigate = useNavigate()
  const [currentTeamId, setCurrentTeamId] = React.useState<string>(teamId)
  const {
    userRoles: roles,
    loading: rolesLoading,
    userId,
  } = useUserIdentityFn()

  const isPublicView = publicView ?? !userId

  const { allTeamsAccountStatusFns, loading: teamAccountStatusLoading } =
    useAllTeamsAccountStatusFns(teamIds || [currentTeamId])

  const {
    getTeamAccountStatus,
    getPlayerAccountStatus,
    getTeamEsportProviderName,
  } = allTeamsAccountStatusFns

  const userRoles = (defaultUserRoles || roles) ?? []

  const isUserAdmin = isAdminForSystem(userRoles)
  const metaseasonId = metaseason?.id ?? ''
  const isInPastContext = isPast || metaseason?.timing === RelativeTiming.Past

  const isAssociatedWithTeamFn = (resource: {
    id: string
    parentEntityId?: string
  }): boolean =>
    hasRoleForResource(roles, [resource.id, resource.parentEntityId])

  // Adding error policy here to show errors for cards that don't have data
  const {
    data: teamRosterData,
    loading: loadingTeamRosterData,
    error,
    refetch: refetchTeamRoster,
  } = useAutoskipQuery(useTeamRosterQuery, {
    variables: {
      id: currentTeamId,
      effectiveAt: date,
      metaseasonId: metaseasonId || null,
    },
    skip: !currentTeamId || rolesLoading,
    errorPolicy: 'all',
  })
  const team = teamRosterData?.team
  const schoolId = team?.school?.id ?? ''

  const isCoachOfTeam = isCoachForScholasticRelatedTeam(userRoles, {
    id: currentTeamId,
    schoolId: schoolId || null,
  })

  const teamStatus = getTeamAccountStatus(currentTeamId)
  const isTeamHidden = team?.isHidden ?? false
  const competitionGroup = team?.competitionGroup ?? CompetitionGroup.HighSchool
  const competitionModel = CompetitionModel.Rec
  const isAssociatedWithTeam = isAssociatedWithTeamFn({
    id: currentTeamId,
    parentEntityId: schoolId,
  })
  const canUserViewTeamRoster =
    isUserAdmin || isAssociatedWithTeam || overrideTeamRosterViewPermissions
  const isTeamAssociatedWithOrg = isAssociatedToOrganization(competitionGroup)
  const esport = {
    id: team?.esport.id ?? '',
    slug: team?.esport?.slug,
    rating: team?.esport?.rating,
  }
  const isTeamPlayerLed =
    (competitionGroup === CompetitionGroup.College && team?.isPlayerLed) ??
    false

  const teamRoster = team?.roster
  //  Currently we do not support more than one team format per esport.
  const teamRosterByFormat = head(teamRoster?.formats ?? [])
  const teamFormat = teamRosterByFormat?.teamSize ?? 0
  const teamMaxSize = teamRoster?.maxNumPlayers ?? 0
  const starters = (teamRosterByFormat?.starters ?? []).map(
    ({ player, position }) => {
      const filteredUser = team?.roster?.players
        ?.filter((teamPlayer) => Boolean(teamPlayer.user.id))
        ?.find((teamPlayer) => teamPlayer?.user?.id === player?.user?.id)

      return {
        user: player?.user,
        position,
        isCaptain: player?.isCaptain ?? false,
        accountStatus: getPlayerAccountStatus(
          currentTeamId,
          player?.user?.id ?? ''
        ),
        providerName: getTeamEsportProviderName(currentTeamId),
        providerOnboardUrl: '',
        userName:
          team?.esport.slug === EsportSlug.RocketLeague &&
          filteredUser?.user?.userEsportPlatforms?.length
            ? (filteredUser.user.userEsportPlatforms[0]
                .platformUsername as string)
            : undefined,
      }
    }
  ) as ProviderStarterRosterPlayer[]

  const substitutes = (teamRosterByFormat?.substitutes ?? []).map(
    ({ user, isCaptain }) => {
      const filteredUser = team?.roster?.players
        ?.filter((teamPlayer) => Boolean(teamPlayer.user.id))
        ?.find((teamPlayer) => teamPlayer.user?.id === user?.id)
      return {
        user,
        isCaptain,
        position: null,
        accountStatus: getPlayerAccountStatus(currentTeamId, user?.id ?? ''),
        providerName: getTeamEsportProviderName(currentTeamId),
        providerOnboardUrl: '',
        userName:
          team?.esport.slug === EsportSlug.RocketLeague &&
          filteredUser?.user?.userEsportPlatforms?.length
            ? (filteredUser.user.userEsportPlatforms[0]
                .platformUsername as string)
            : undefined,
      }
    }
  ) as ProviderBenchRosterPlayer[]

  const allTeamRosterPlayers = (teamRoster?.players ?? []).map((player) => ({
    ...player.user,
    isCaptain: player.isCaptain,
  }))

  const isBenchFull = substitutes.length >= teamMaxSize - starters.length

  const teamCaptain = allTeamRosterPlayers.find((player) => player.isCaptain)
  const teamContext = {
    id: teamId,
    resourceType: ResourceType.Team,
    competitionGroup,
    competitionModel,
    schoolId: team?.school?.id ?? null,
  }
  const enrolledSeasons = team?.enrolledSeasons ?? []
  const activeEnrolledSeasons = enrolledSeasons.filter((season) => {
    return isCurrentSeason(season)
  })
  const enrolledSeason = enrolledSeasons.find(
    (season) => season?.metaseason?.id === metaseasonId
  )
  const enrolledSeasonId = enrolledSeason?.id ?? ''

  const isEnrolled = !!enrolledSeasonId
  const isEnrolledSeasonActivelyLocked = isSeasonRostersActivelyLocked(
    metaseason?.timing,
    enrolledSeason?.rostersLockAt
  )
  const isRosterActivelyLocked = isSeasonRostersActivelyLocked(
    metaseason?.timing,
    enrolledSeason?.rostersLockAt
  )
  const isTeamUnenrollmentLocked = !canUnenrollTeam(activeEnrolledSeasons)
  const league = team?.leagues?.find((lg) => lg.esportId === esport.id)

  // stats

  const { data: smashStats, error: smashStatErrors } =
    useGetSmashUltimateStatsQuery({
      variables: {
        input: {
          playerIds: allTeamRosterPlayers.map((player) => player.id),
          seasonId: enrolledSeasonId,
        },
      },
      skip: !enrolledSeasonId || esport.slug !== EsportSlug.SmashBrosUltimate,
    })

  const { data: playerStats } = useGetPlayerSeasonStatsQuery({
    variables: {
      input: {
        seasonId: enrolledSeasonId,
        playerIds: allTeamRosterPlayers.map((player) => player.id),
      },
    },
    skip:
      !enrolledSeasonId ||
      !esport.slug ||
      !ESPORTS_WITH_STATS.includes(esport.slug),
  })

  const smashStatsByPlayer =
    smashStats?.getSmashUltimateMostPlayedStatsByPlayerIds.players.reduce(
      (acc, player) => {
        acc[player.playerId] = player
        return acc
      },
      {} as Record<string, SmashUltimateStatsPlayerFragment>
    ) ?? {}

  const statsByPlayer = (
    playerStats?.getPlayerSeasonStats?.results ?? []
  ).reduce((acc, player) => {
    acc[player.playerId] = player
    return acc
  }, {} as Record<string, PlayerSeasonStatsFragment>)

  // helpers

  const refetchTeamAndProfile = async (
    effectiveAtDate?: string
  ): Promise<void> => {
    try {
      await refetchTeamRoster({
        id: currentTeamId,
        metaseasonId: metaseasonId || null,
        effectiveAt: effectiveAtDate ?? null,
      })
    } catch (e) {
      // Ignore error
    }
  }

  const getRosterCardPermissions = (
    memberRoles: Roles,
    contentContext: ContentContextPermissions,
    isSelf: boolean
  ): GrantedRosterMenuPermissions => {
    return getRosterMenuRowItems({
      team: teamContext,
      userRoles,
      rules: getRulesByContentPermissions(contentContext),
      isSelf,
      memberRoles,
    })
  }

  const onAddPlayerMutationSuccessDefault = async (): Promise<void> => {
    await refetchTeamAndProfile()
  }

  if (!rolesLoading && !canUserViewTeamRoster && !loadingTeamRosterData) {
    navigate(Path.Dashboard, { replace: true })
  }

  React.useEffect(() => {
    if (teamId !== currentTeamId) {
      setCurrentTeamId(teamId)
    }
  }, [currentTeamId, teamId])

  const graphErrors = error ?? smashStatErrors
  if (graphErrors) {
    throw graphErrors
  }

  return (
    <rosterContext.Provider
      value={{
        userId,
        teamId: currentTeamId,
        loading:
          rolesLoading || loadingTeamRosterData || teamAccountStatusLoading,
        isPast: isInPastContext,
        isTeamPlayerLed,
        metaseason,
        onMutationSuccessInRosterFlow: refetchTeamAndProfile,
        isTeamAssociatedWithOrg,
        competitionGroup,
        competitionModel,
        rosterPlayers: allTeamRosterPlayers,
        starters,
        substitutes,
        teamCaptain,
        isEnrolled,
        esport,
        enrolledSeason,
        isEnrolledSeasonActivelyLocked,
        isRosterActivelyLocked,
        isTeamUnenrollmentLocked,
        promotedMetaseason,
        userRoles,
        team,
        teamStatus,
        isTeamHidden,
        teamFormat,
        addToTeamMutationSuccess: onAddPlayerMutationSuccessDefault,
        isBenchFull,
        getRosterCardPermissions,
        schoolId,
        teamColor,
        league,
        publicView: isPublicView,
        smashStatsByPlayer,
        statsByPlayer,
        isAssociatedWithTeam: isAssociatedWithTeamFn({
          id: teamId,
          parentEntityId: schoolId,
        }),
        isCoach: isCoachOfTeam,
        isTeamOwnerForResource: isTeamOwnerForResource(userRoles, [teamId]),
      }}
    >
      {children}
    </rosterContext.Provider>
  )
}
