import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Box, makeStyles } from '@material-ui/core'
import { NxSkeletonLoader, NxTabs, NxTab } from '@playvs-inc/nexus-components'
import { useBreakpointSm, WaitTillLoaded } from '@plvs/respawn/features/layout'
import {
  CompetitionGroup,
  EsportSlug,
  PhaseType,
  useGetMatchPhaseQuery,
} from '@plvs/graphql'
import { useMatchStatsContext } from '@plvs/respawn/features/match-lobby/MatchStatsContainer'
import { head } from 'ramda'
import { HeroTabsGutter } from '@plvs/respawn/features/layout/HeroTabsGutter'
import { MatchQueueBanner } from '@plvs/respawn/features/queue/MatchQueueBanner'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'

const useStyles = makeStyles((theme) => ({
  skeletonLoaderContainer: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  tabActionInfoBar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
}))

interface ControlPanelProps {
  matchId: string
  showMatchQueueBanner: boolean
  esportSlug: EsportSlug | null | undefined
  competitionGroup: CompetitionGroup | null | undefined
  ActionAndInfoSectionComponent: React.FC<{
    esportSlug: EsportSlug | null | undefined
    matchId: string
    competitionGroup: CompetitionGroup | null | undefined
  }>
}

export const ControlPanel: React.FC<ControlPanelProps> = ({
  matchId,
  esportSlug,
  showMatchQueueBanner,
  ActionAndInfoSectionComponent,
  competitionGroup,
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useBreakpointSm()

  const { data, loading } = useGetMatchPhaseQuery({
    variables: { matchId },
    skip: !matchId,
  })
  const { getMatchLobbyRenderControllerState } =
    useMatchLobbyRenderControllerState()
  const { lobby } = getMatchLobbyRenderControllerState()

  const {
    myTeamIds,
    showActionAndInfoSection,
    canManageQueueBannerActions,
    canManageQueueBannerActionsCopy,
  } = lobby
  const myTeamId = head(myTeamIds) ?? ''

  const isPreseasonMatch =
    data?.match?.slot?.phase?.type === PhaseType.Preseason

  const { hasStats } = useMatchStatsContext()

  const tabs: {
    label: string
    path: string
    id?: string
    className?: string
  }[] = [
    // Always include the Lobby Tab in the tabs array.
    {
      label: 'Lobby',
      path: `mission-control`,
      id: 'lobby-tabs-mission-control',
    },
  ]

  if (hasStats) {
    tabs.push({
      label: `Match Stats`,
      path: `match-stats`,
      id: 'lobby-tabs-match-stats',
    })
  }

  tabs.push({
    label: 'Teams',
    path: `teams`,
    id: 'lobby-tabs-teams',
    className: 'shepherd-teams-tab',
  })

  const [tabIndex, setTabIndex] = useState(0)
  const onTabChange = (
    _event: React.ChangeEvent<unknown>,
    value: number
  ): void => {
    setTabIndex(value)
    if (tabs[value].path) {
      navigate(tabs[value].path)
    }
  }

  useEffect(() => {
    const currentTab: number = tabs.findIndex((tab) =>
      location.pathname.includes(tab.path)
    )
    if (currentTab >= 0) {
      setTabIndex(currentTab)
    }
  }, [tabs, location.pathname])

  return (
    <>
      <WaitTillLoaded
        loading={loading}
        LoadingComponent={(): React.ReactElement => (
          <Box className={classes.skeletonLoaderContainer}>
            <NxSkeletonLoader height="36px" variant="rect" width="280px" />
          </Box>
        )}
        showSpinnerWhileLoading
      >
        <Box>
          <HeroTabsGutter>
            {showMatchQueueBanner && (
              <Box my={3}>
                <MatchQueueBanner
                  esportSlug={esportSlug ?? null}
                  isPreseason={isPreseasonMatch}
                  showButton={canManageQueueBannerActions}
                  subtitle={
                    isPreseasonMatch
                      ? 'If you would like to play again after finishing your match, you can queue up for another preseason match!  From there you can see how many other teams are still looking for another match as well.  Have fun any take advantage of the extra practice & meet new teams!'
                      : canManageQueueBannerActionsCopy
                  }
                  teamId={myTeamId}
                  title={
                    isPreseasonMatch
                      ? 'Unlimited Play in Preseason!'
                      : 'Eager to play? Queue up!'
                  }
                />
              </Box>
            )}
            <Box className={classes.tabActionInfoBar}>
              {Boolean(tabs.length) && (
                <NxTabs onChange={onTabChange} value={tabIndex}>
                  {tabs.map((tab, ndx: number) => (
                    <NxTab
                      key={tab.id}
                      className={tab?.className}
                      data-testid={tab.id}
                      id={tab.id}
                      label={tab.label}
                      selected={tabIndex === ndx}
                      value={ndx}
                    />
                  ))}
                </NxTabs>
              )}
              {showActionAndInfoSection && !isMobile && (
                <ActionAndInfoSectionComponent
                  competitionGroup={competitionGroup}
                  esportSlug={esportSlug ?? null}
                  matchId={matchId}
                />
              )}
            </Box>
          </HeroTabsGutter>
        </Box>
      </WaitTillLoaded>
    </>
  )
}
