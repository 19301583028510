import { CompetitionGroup, MatchStatus } from '@plvs/graphql/generated'
import { ManageMatchDropdownMenuItems } from '@plvs/respawn/renderController/matchLobby/lobby/types/lobbyRenderController.types'
import { isMatchCancellableByStatus } from '@plvs/utils'

type ManageMatchDropdownConditionsMap = Record<
  Partial<ManageMatchDropdownMenuItems>,
  boolean
>
export const determineScheduleMatchRowConditions = ({
  status,
  team2Id,
  isQueueMatch,
  matchId,
  showRescheduleItem,
  showViewRescheduleItem,
}: {
  status: MatchStatus | undefined
  team2Id: string
  isQueueMatch: boolean
  matchId: string
  showRescheduleItem: boolean
  showViewRescheduleItem: boolean
}): ManageMatchDropdownConditionsMap => {
  const isSoloForfeit = status === MatchStatus.Forfeited && !team2Id
  const showScoutOpponentItem = Boolean(team2Id) && !isQueueMatch
  const showForfeitItem = status ? isMatchCancellableByStatus(status) : false
  const showDisputeItem =
    status === MatchStatus.Completed ||
    (status === MatchStatus.Forfeited && Boolean(team2Id))
  const showChat = Boolean(matchId) && !isSoloForfeit
  const manageMatchDropdownConditionMap = {
    [ManageMatchDropdownMenuItems.Reschedule]: showRescheduleItem,
    [ManageMatchDropdownMenuItems.ViewReschedule]: showViewRescheduleItem,
    [ManageMatchDropdownMenuItems.Forfeit]: showForfeitItem,
    [ManageMatchDropdownMenuItems.Dispute]: showDisputeItem,
    [ManageMatchDropdownMenuItems.Scout]: showScoutOpponentItem,
    [ManageMatchDropdownMenuItems.Chat]: showChat,
    [ManageMatchDropdownMenuItems.JoinQueue]: isQueueMatch,
  }

  return manageMatchDropdownConditionMap as ManageMatchDropdownConditionsMap
}

export const isDropdownDisabled = ({
  status,
  team2Id,
  competitionGroup,
  isQueueMatch,
}: {
  status: MatchStatus | undefined
  team2Id: string
  competitionGroup: CompetitionGroup | null | undefined
  isQueueMatch: boolean
}): boolean => {
  return (
    (status === MatchStatus.Forfeited && !team2Id && !isQueueMatch) ||
    status === MatchStatus.Bye ||
    !competitionGroup
  )
}
