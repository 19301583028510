import { makeStyles } from '@material-ui/core'
import React from 'react'

import { Box } from '@plvs/respawn/features/layout'
import { Colors } from '@plvs/rally/themes'
import { PlayerGameStats } from '@plvs/graphql'
import {
  NxAvatarSize,
  NxUserAvatar,
} from '@plvs/respawn/features/avatar/NxUserAvatar'
import { NxTooltip } from '@playvs-inc/nexus-components'

const GUTTER = 4

const useStyles = makeStyles((theme) => ({
  chocolateBar: {
    backgroundColor: theme.palette.ColorBackgroundInactive,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    borderRadius: 6,
    height: 124,
    marginBottom: 16,
    marginLeft: GUTTER,
    marginRight: GUTTER,
    position: 'relative',
    width: 68,
  },
  gumDrop: {
    backgroundColor: Colors.White,
    borderRadius: '50%',
    bottom: -16,
    left: 16,
    padding: 2,
    position: 'absolute',
  },
}))

type LolCandyItemInterface = Partial<PlayerGameStats>

const LolCandyItem: React.FC<LolCandyItemInterface> = ({
  leagueOfLegendsStats,
  player,
}) => {
  const classes = useStyles()
  const championId = leagueOfLegendsStats?.champion?.id
  const championImageSrc = leagueOfLegendsStats?.champion?.image.src
  const championName = leagueOfLegendsStats?.champion?.name ?? ''
  const playerId = player?.id

  const avatarUrl = player?.avatarUrl

  return (
    <NxTooltip
      key={`${playerId}-${championId}`}
      arrow
      placement="top"
      title={championName}
    >
      <div
        className={classes.chocolateBar}
        style={{
          backgroundImage: `url(${championImageSrc})`,
        }}
      >
        <div className={classes.gumDrop} data-testid={championName}>
          <NxUserAvatar
            avatarUrl={avatarUrl}
            hashId={playerId ?? ''}
            size={NxAvatarSize.SM}
          />
        </div>
      </div>
    </NxTooltip>
  )
}

interface Props {
  opposing?: boolean
  playerGameStats: Pick<PlayerGameStats, '__typename' | 'id'>[]
}

export const LolCandy: React.FC<Props> = ({ opposing, playerGameStats }) => (
  <Box
    display="flex"
    flexDirection={opposing ? 'row' : 'row-reverse'}
    marginLeft={opposing && -GUTTER}
    marginRight={opposing || -GUTTER}
  >
    {playerGameStats.map((stat) => (
      <LolCandyItem key={stat.id} {...stat} />
    ))}
  </Box>
)
