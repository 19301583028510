import { UserNotification } from '@plvs/graphql/generated/graphql'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import React from 'react'
import { UserNotificationContentAdapter } from '../notification.types'
import { SchoolSystemMessage } from '../templates/SchoolSystemMessage'

const ReviewVerifiedCoachesAdapterContainer: React.FC<{
  notification: UserNotification
}> = ({ notification }) => {
  return (
    <SchoolSystemMessage
      IconComponent={
        <NxSpot
          domain="notVerified"
          height={64}
          size="small"
          variant="primary"
          width={64}
        />
      }
      message={notification.contentData.message}
      messageDates={notification.contentData.messageDates}
      url={notification.contentData.url || undefined}
    />
  )
}

export const ReviewVerifiedCoachesAdapter: UserNotificationContentAdapter = {
  notificationSource: 'Review Verified Coaches',

  createNotification(notification: UserNotification): React.ReactElement {
    return <ReviewVerifiedCoachesAdapterContainer notification={notification} />
  },
}
