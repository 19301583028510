import React from 'react'

import {
  findTopThreeScrimmages,
  getRefetchScrimmageQueries,
} from '@plvs/rally/components/scrimmage/scrimmageHelpers'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { TopRecommendedScrimmages } from '@plvs/rally/components/scrimmage'
import {
  NxSpotModal,
  NxSelect,
  NxSelectOption,
  NxButton,
  NxTypography,
} from '@playvs-inc/nexus-components'

import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import {
  useGetSchoolRecommendedScrimmagesQuery,
  ScrimmageRequestQuery,
  Team,
  CompetitionGroup,
  useJoinScrimmageMutation,
} from '@plvs/graphql'

import { Box } from '@material-ui/core'
import { Path } from '@plvs/const'
import { useSnackbar } from 'notistack'
import { SpotModalData } from './types'

type JoinScrimmageStepBProps = {
  schoolId: string
  scrimmageRequest?: ScrimmageRequestQuery['scrimmageRequest']
  onClose(): void
  onSuccess: (scrimmageId: string) => Promise<void>
  myAvailableTeams: Pick<Team, 'id' | 'name'>[]
  resetError(): void
  schoolCompetitionGroup: CompetitionGroup | null
  open: boolean
  spotModalData: SpotModalData
  loading: boolean
}

/*
 * Dialog box shown after user encounters error on previous step.
 * Step B self hosts a seperate join allowing users to select
 * a new team and scrimmage to join.
 */
export const JoinScrimmageStepB = ({
  schoolId,
  scrimmageRequest,

  onClose,
  onSuccess,
  myAvailableTeams,
  resetError,
  schoolCompetitionGroup,
  open,
  spotModalData,
  loading,
}: JoinScrimmageStepBProps): React.ReactElement => {
  const [selectedScrimmageId, setSelectedScrimmageId] = React.useState<string>(
    ''
  )
  const [selectedTeamId, setSelectedTeamId] = React.useState<string>('')
  const { enqueueSnackbar } = useSnackbar()

  const [
    joinScrimmage,
    { loading: joiningScrimmage },
  ] = useJoinScrimmageMutation()

  // Queries for joining recommended scrimmages
  const {
    data: availableScrimmagesData,
    loading: scrimmageDataLoading,
    refetch: refetchRecommendedScrimmages,
  } = useGetSchoolRecommendedScrimmagesQuery({
    variables: {
      id: schoolId,
      offset: 0,
      limit: 50,
      teamId: selectedTeamId,
    },
    skip: !schoolId || !selectedTeamId,
  })

  const onSubmit = async (): Promise<void> => {
    if (selectedTeamId && selectedScrimmageId) {
      try {
        await joinScrimmage({
          awaitRefetchQueries: true,
          // @ts-ignore any type
          refetchQueries: [getRefetchScrimmageQueries(schoolId)],
          variables: {
            input: {
              id: selectedScrimmageId,
              opposingTeamId: selectedTeamId,
            },
          },
        })

        onSuccess(selectedScrimmageId)
      } catch (e) {
        enqueueSnackbar('Unable to join scrimmage', {
          variant: 'error',
        })
      }
    }
  }

  const availableScrimmages = findTopThreeScrimmages({
    startsAt: scrimmageRequest?.startsAt ?? null,
    availableScrimmagesData,
    esportSlug: scrimmageRequest?.esport?.slug ?? null,
    teamId: selectedTeamId,
    schoolCompetitionGroup,
  })

  const updateSelectedScrimmageId = (id: string): void => {
    resetError()
    setSelectedScrimmageId(id)
  }

  const renderRecommendedScrimmages =
    !!availableScrimmages.length &&
    !!selectedTeamId &&
    !scrimmageDataLoading ? (
      <TopRecommendedScrimmages
        availableScrimmages={availableScrimmages}
        selectedScrimmageId={selectedScrimmageId}
        setSelectedScrimmageId={updateSelectedScrimmageId}
      />
    ) : (
      <NxTypography style={{ marginTop: '16px' }} variant="body1">
        No available scrimmages.
      </NxTypography>
    )

  const renderScrimmagesWithLoading = (
    <WaitTillLoaded
      loading={scrimmageDataLoading}
      loadingSpinnerProps={{ size: 'medium' }}
      showSpinnerWhileLoading
    >
      {renderRecommendedScrimmages}
    </WaitTillLoaded>
  )

  React.useEffect(() => {
    // skip doesn't block a refetch, unfortunately
    if (selectedTeamId) {
      refetchRecommendedScrimmages({
        id: schoolId,
        offset: 0,
        limit: 50,
        teamId: selectedTeamId,
      })
    }
  }, [selectedTeamId])

  const selectionOfTeams = (
    <NxSelect
      disabled={!myAvailableTeams.length}
      fullWidth
      label="Team"
      onChange={(e): void => {
        setSelectedTeamId(e.target.value as string)
        resetError()
      }}
    >
      {myAvailableTeams.map((team) => {
        const label = team.name

        return (
          <NxSelectOption key={team.id} value={team.id}>
            {label}
          </NxSelectOption>
        )
      })}
    </NxSelect>
  )

  const actions = (
    <>
      <NxButton label="Cancel" onClick={onClose} variant="text" />
      <NxButton
        disabled={
          !availableScrimmages.length ||
          joiningScrimmage ||
          !selectedScrimmageId
        }
        label="Join"
        onClick={onSubmit}
        variant="primary"
      />
    </>
  )

  return (
    <NxSpotModal
      actions={actions}
      onClose={onClose}
      open={open}
      showTopRightClose
      spot={
        <NxSpot
          domain="highAlert"
          height={100}
          size="large"
          variant="primary"
          width={100}
        />
      }
      subtitle={spotModalData?.spotSubtitle}
      title={spotModalData?.spotTitle}
    >
      <WaitTillLoaded loading={loading} showSpinnerWhileLoading>
        <Box display="flex" justifyContent="center">
          <NxButton
            href={Path.ManageTeams}
            label="Manage Teams"
            variant="secondary"
          />
        </Box>

        <Box pt={2}>
          <NxTypography variant="h4">Choose a New Scrimmage Team</NxTypography>
          <Box py={1}>{selectionOfTeams}</Box>
          {!!selectedTeamId && renderScrimmagesWithLoading}
        </Box>
      </WaitTillLoaded>
    </NxSpotModal>
  )
}
