import React from 'react'
import { SponsorshipComponentName } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { SponsorshipComponent } from '@plvs/respawn/features/sponsorship/SponsorshipComponent'

type SponsorshipComponentProps = {
  name: SponsorshipComponentName
}

export const SponsorshipComponentWrapper = ({
  name,
}: SponsorshipComponentProps): React.ReactElement => {
  const { activeCampaign } = useUserIdentityFn()
  const component = activeCampaign?.sponsorshipComponents?.find(
    (c) => c.name === name
  )
  if (!component) {
    return <></>
  }

  return <SponsorshipComponent component={component} />
}
