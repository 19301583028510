import React from 'react'
import { range } from 'ramda'
import tz from 'dayjs/plugin/timezone'

import { NxSelectOption } from '@playvs-inc/nexus-components'

import dayjs from '@plvs/respawn/init/dayjs'

import { PlaceHolderOption } from './PlaceHolderOption'

dayjs.extend(tz)

const getFormattedPrettyTime = ({
  date,
  hour,
  minute,
  timezone,
}: {
  date: string
  hour: number
  minute: number
  timezone: string
}): string =>
  dayjs
    .tz(date, timezone)
    .set('hour', hour)
    .set('minute', minute)
    .format('h:mma z')

const getISOTimezoneDateTime = ({
  date,
  hour,
  minute,
  timezone,
}: {
  date: string
  hour: number
  minute: number
  timezone: string
}): string =>
  dayjs.tz(date, timezone).set('hour', hour).set('minute', minute).toISOString()

const remainderToMinutesMap: Record<string, number> = {
  '0': 0,
  '1': 15,
  '2': 30,
  '3': 45,
}

interface TimeOptionsProps {
  date: string
  timezone: string
}

export const TimeOptions: React.FC<TimeOptionsProps> = ({ date, timezone }) => {
  const today = dayjs()
  const isToday = dayjs(date).format('YYYYMMDD') === today.format('YYYYMMDD')
  const currentHour = dayjs.tz(today, timezone).get('hour')
  // current hour before 6AM OR after 9PM
  // start at 6 AM OR start at the next hour
  const adjustedStartHour =
    currentHour < 6 || currentHour > 22
      ? 6
      : dayjs.tz(today, timezone).add(30, 'minutes').get('hour')
  const startHour = isToday ? adjustedStartHour : 6

  const endHour = 21 // 9PM

  return (
    <>
      <PlaceHolderOption text="Select a Time" />,
      {range(
        startHour * 4,
        endHour * 4 + 1 // makes sure that there is a 9PM option, but not a 9:30PM option
      ).map((quarterHour, index) => {
        const hour = Math.floor(quarterHour / 4)
        const remainder = (index % 4).toString()
        const minute = remainderToMinutesMap[remainder]

        const payload = {
          date,
          hour,
          minute,
          timezone,
        } as const
        const value = getISOTimezoneDateTime(payload)
        const name = getFormattedPrettyTime(payload)

        return (
          <option key={value} value={value}>
            {name}
          </option>
        )
      })}
    </>
  )
}

export function useTimeOptions({
  date,
  timezone,
}: TimeOptionsProps): React.ReactElement[] {
  if (!date || !timezone) {
    return []
  }

  const today = dayjs()
  const isToday = dayjs(date).format('YYYYMMDD') === today.format('YYYYMMDD')
  const currentHour = dayjs.tz(today, timezone).get('hour')
  // current hour before 6AM OR after 9PM
  // start at 6 AM OR start at the next hour
  const adjustedStartHour =
    currentHour < 6 || currentHour > 22
      ? 6
      : dayjs.tz(today, timezone).add(30, 'minutes').get('hour')
  const startHour = isToday ? adjustedStartHour : 6

  const endHour = 21 // 9PM

  return [
    <NxSelectOption key="placeholder" value="">
      Select a time
    </NxSelectOption>,

    ...range(
      startHour * 4,
      endHour * 4 + 1 // makes sure that there is a 9PM option, but not a 9:30PM option
    ).map((quarterHour, index) => {
      const hour = Math.floor(quarterHour / 4)
      const remainder = (index % 4).toString()
      const minute = remainderToMinutesMap[remainder]

      const payload = {
        date,
        hour,
        minute,
        timezone,
      } as const
      const value = getISOTimezoneDateTime(payload)
      const name = getFormattedPrettyTime(payload)

      return (
        <NxSelectOption key={value} value={value}>
          {name}
        </NxSelectOption>
      )
    }),
  ]
}
