import { EmptyPage } from '@plvs/rally/components/empty'
import { getDateFormatMonthDateYear } from '@plvs/utils'
import { isToday, isTomorrow } from 'date-fns'
import React from 'react'
import dayjs from 'dayjs'
import { MatchStatus } from '@plvs/graphql'
import { useSchoolLeagueInfoContext } from '@plvs/respawn/containers/filter/league/hooks'
import { MappedMatch, SortFilter } from './schedule'
import { MatchDayGroup } from '../../../components/schedule/match/MatchDayGroup'

export function groupByDayObj<T extends MappedMatch>(
  passedInMatches: MappedMatch[]
): Record<string, T[]> {
  const dayObj: Record<string, T[]> = {}
  passedInMatches.forEach((passedInMatch): void => {
    const { scheduledStartsAt } = passedInMatch
    const fullDateOfMatch = scheduledStartsAt
      ? new Date(scheduledStartsAt)
      : new Date()
    let dateLabel = getDateFormatMonthDateYear(fullDateOfMatch)
    if (isToday(fullDateOfMatch)) {
      dateLabel = `Today · ${dateLabel}`
    } else if (isTomorrow(fullDateOfMatch)) {
      dateLabel = `Tomorrow · ${dateLabel}`
    }
    if (!dayObj[dateLabel]) {
      dayObj[dateLabel] = [passedInMatch as T]
    } else {
      dayObj[dateLabel].push(passedInMatch as T)
    }
  })
  return dayObj
}

export function filterOldForfeits(
  teamMatches: MappedMatch[],
  queueMatches: MappedMatch[]
): MappedMatch[] {
  const slotsWithQueueMatches = new Set(
    queueMatches.map((match) => match.slotId)
  )

  return teamMatches.filter((teamMatch: MappedMatch) => {
    // A match should pass this filter if:
    // - The status is not Forfeited
    // - OR there's no queue match in the same slot
    return (
      teamMatch.status !== MatchStatus.Forfeited ||
      (teamMatch.slotId && !slotsWithQueueMatches.has(teamMatch.slotId))
    )
  })
}

export const MatchScheduleContainer: React.FC<{
  teamMatches: MappedMatch[]
  queueMatches: MappedMatch[]
  show: SortFilter
}> = ({ teamMatches, queueMatches, show }) => {
  const { metaseasons } = useSchoolLeagueInfoContext()
  // Remove forfeited matches for slots that have a TBD match
  const teamMatchesToShow = filterOldForfeits(teamMatches, queueMatches)

  const combinedArray = [...teamMatchesToShow, ...queueMatches].sort((a, b) => {
    const dateA = dayjs(a.scheduledStartsAt)
    const dateB = dayjs(b.scheduledStartsAt)

    const isPast = show === SortFilter.Past

    return isPast ? dateB.diff(dateA) : dateA.diff(dateB)
  })

  const matchObj = groupByDayObj(combinedArray)

  if (!Object.keys(matchObj).length) {
    if (show === SortFilter.Upcoming) {
      return <EmptyPage showIcon subtitle="No upcoming matches" />
    }
    return <EmptyPage showIcon subtitle="No previous matches" />
  }

  return (
    <>
      {Object.keys(matchObj).map(
        (date): React.ReactElement => (
          <MatchDayGroup
            key={date}
            date={date}
            matches={matchObj[date]}
            metaseasons={metaseasons}
          />
        )
      )}
    </>
  )
}
