import { yupResolver } from '@hookform/resolvers'
import { makeStyles } from '@material-ui/core'
import { SchoolType, UserRoleName, useGetSchoolTypeQuery } from '@plvs/graphql'
import { Callout } from '@plvs/rally/components/callout'
import { Box } from '@plvs/respawn/features/layout/Box'
import { OnboardSelectField } from '@plvs/rally/components/onboard/OnboardSelectField'
import { UserTellUsMoreInput } from '@plvs/rally/components/onboard/types'
import { QuestionnaireCard } from '@plvs/respawn/features/questionnaireCard/QuestionnaireCard'
import { arrayOfN, useAutoskipQuery, yupGradYearRequired } from '@plvs/utils'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { useNavigate } from 'react-router-dom'
import { useOnboardingContext } from '@plvs/respawn/features/onboard/OnboardingContext'

const NUM_GRAD_YEARS_TO_DISPLAY = 7

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      paddingTop: theme.spacing(2),
    },
  },
}))

const UserTellUsMoreDetails = yup.object().shape({
  graduationYear: yupGradYearRequired,
  role: yup.string().required('Please select an option'),
})

export const OnboardTellUsMore: React.FC = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { assign, data: onboardingContext } = useOnboardingContext()
  const { data: schoolTypeData } = useAutoskipQuery(useGetSchoolTypeQuery, {
    variables: {
      schoolId: (onboardingContext.schoolId as string) || '',
    },
  })

  const isUnder18 =
    dayjs().diff(dayjs(onboardingContext.dateOfBirth as Date), 'years') < 18

  let initialRole: UserRoleName | null =
    (onboardingContext.role as UserRoleName) ?? null
  if (isUnder18) {
    initialRole = UserRoleName.Student
  }

  const [role, setRole] = useState<UserRoleName | ''>(initialRole ?? '')

  const [graduationYear, setGraduationYear] = useState<string>(
    (onboardingContext.graduationYear as string) ?? ''
  )

  const gradYearOptions = useMemo(() => {
    const thisYear = dayjs().year()
    return arrayOfN(NUM_GRAD_YEARS_TO_DISPLAY).map((i: number) => thisYear + i)
  }, [])

  if (
    schoolTypeData &&
    !onboardingContext.schoolType &&
    !onboardingContext.roleName
  ) {
    assign({ schoolType: schoolTypeData?.school?.type })
  }

  // Computed Values
  const showGradYear = role === UserRoleName.Student
  const showRoleSelect =
    dayjs().diff(dayjs(onboardingContext.dateOfBirth as Date), 'years') >= 18
  const showNASEFUx = onboardingContext?.isNasefSignUp ? NASEF_QUERY_PARAM : ''

  const continueAllowedConditions =
    role === UserRoleName.Fac ||
    (role === UserRoleName.Student && graduationYear)

  // Form handlers

  const { errors, handleSubmit, register, clearErrors } = useForm<
    UserTellUsMoreInput
  >({
    resolver: continueAllowedConditions
      ? undefined
      : yupResolver<UserTellUsMoreInput>(UserTellUsMoreDetails),
  })

  const onFormUpdate = (): void => {
    clearErrors()
  }

  const submitTellUsMoreDetails = handleSubmit(
    async (input: UserTellUsMoreInput): Promise<void> => {
      // New version of react-hook-forms will hide exclude the
      // values when input element is hidden.
      const selectedRole = input.role ?? role

      const gradYearNumber: number = +graduationYear
      assign({
        role: selectedRole,
        schoolType:
          input.role !== UserRoleName.User ? SchoolType.HighSchool : undefined,
        graduationYear: selectedRole ? gradYearNumber : undefined,
      })

      if (selectedRole === UserRoleName.Fac) {
        navigate(`faculty-school${showNASEFUx}`)
      } else {
        navigate(
          `${Path.SpawnPoint}${Path.StudentEsportInterests}${showNASEFUx}`
        )
      }
    }
  )

  return (
    <form
      className={classes.form}
      data-testid="onboard-tell-us-more"
      noValidate
      onChange={onFormUpdate}
      onSubmit={submitTellUsMoreDetails}
    >
      <QuestionnaireCard
        buttonOpacity={!continueAllowedConditions}
        childWidth={400}
        data-testid="OnboardTellUsMore__QuestionaireCard"
        onContinue={submitTellUsMoreDetails}
        title="Add your school details"
      >
        <Box
          style={{
            position: 'relative',
          }}
        />
        {showRoleSelect && (
          <OnboardSelectField
            data-testid="OnboardTellUsMore__SchoolRole"
            defaultValue={role}
            error={!!errors?.role}
            helperText={errors?.role?.message}
            hidden={!showRoleSelect}
            inputRef={register}
            label="School Role"
            name="role"
            onChange={(e): void => {
              setRole(e.target.value as UserRoleName)
            }}
            prompt="School Role"
          >
            <option value={UserRoleName.Student}>Student</option>
            <option value={UserRoleName.Fac}>Faculty Member</option>
          </OnboardSelectField>
        )}

        {showGradYear && (
          <OnboardSelectField
            data-testid="OnboardTellUsMore__GradYear"
            defaultValue={graduationYear}
            error={!!errors?.graduationYear}
            helperText={errors?.graduationYear?.message}
            inputRef={register}
            label="Graduation Year"
            name="graduationYear"
            onChange={(e): void => setGraduationYear(e.target.value as string)}
            prompt="Graduation Year"
          >
            {gradYearOptions.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </OnboardSelectField>
        )}
        {role === UserRoleName.Fac && (
          <Callout>
            Faculty will need to verify their employment before creating school
            teams.
          </Callout>
        )}
      </QuestionnaireCard>
    </form>
  )
}
