import React, { useState } from 'react'
import {
  Box,
  PageContentGutter,
  WaitTillLoaded,
} from '@plvs/respawn/features/layout'
import {
  NxButton,
  NxCardPaginationButtons,
  NxEmptyState,
} from '@playvs-inc/nexus-components'

import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useUserHighlightsQuery } from '@plvs/graphql/generated'
import { UploadVideoModal } from './UploadVideoModal'
import { getEmptyStateProps } from './highlightHelpers'
import { HighlightVideo } from './HighlightVideo'
import { HighlightTitle } from './HighlightTitle'

interface StyleProps {
  currentIndex: number
}
const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  highlightRow: {
    overflowX: 'hidden',
  },
  videoCard: ({ currentIndex }: StyleProps): CreateCSSProperties => ({
    width: 304,
    height: 322,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2.5,
    boxShadow: theme.mixins.boxShadow.elevation1,
    backgroundColor: theme.palette.ColorBackgroundBase,
    marginRight: theme.spacing(2),
    transition: 'all 250ms linear',
    transform: `translateX(-${currentIndex * 100}%)`,
  }),
}))

interface Props {
  userId: string
  userName: string
}

export const Highlights: React.FC<Props> = ({ userId, userName }) => {
  const { userId: myUserId } = useUserIdentityFn()

  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const openModal = (): void => setModalOpen(true)
  const closeModal = (): void => setModalOpen(false)

  const classes = useStyles({ currentIndex })

  const { data, loading, refetch } = useUserHighlightsQuery({
    variables: { userId },
  })

  const refetchHighlights = (): void => {
    refetch({ userId })
  }

  const highlights = data?.user?.gameHighlights ?? []

  const highlightsSize = highlights.length

  const handleNext = (): void => {
    if (currentIndex < highlightsSize - 1) {
      setCurrentIndex(currentIndex + 1)
    }
  }

  const handlePrev = (): void => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const isMyProfile = myUserId === userId

  const UploadVideoButton = (
    <NxButton label="Upload Video" onClick={openModal} variant="primary" />
  )

  const PaginationButtons = (
    <NxCardPaginationButtons
      enableLeft={currentIndex > 0}
      enableRight={currentIndex < highlightsSize - 1}
      onLeft={handlePrev}
      onRight={handleNext}
    />
  )

  return (
    <WaitTillLoaded loading={loading}>
      {!isMyProfile && highlightsSize === 0 ? (
        <></>
      ) : (
        <>
          <PageContentGutter>
            <HighlightTitle
              highlightsSize={highlightsSize}
              isMyProfile={isMyProfile}
              PaginationButtons={PaginationButtons}
              UploadVideoButton={UploadVideoButton}
            />
            {highlightsSize > 0 ? (
              <Box
                alignItems="center"
                className={classes.highlightRow}
                display="flex"
              >
                {highlights.map((highlight) => (
                  <HighlightVideo
                    key={highlight?.id}
                    className={classes.videoCard}
                    highlight={highlight}
                    isMyProfile={isMyProfile}
                    refetchHighlights={refetchHighlights}
                    userId={userId}
                    userName={userName}
                  />
                ))}
              </Box>
            ) : (
              <NxEmptyState
                cta={isMyProfile && UploadVideoButton}
                {...getEmptyStateProps(isMyProfile)}
              />
            )}
          </PageContentGutter>
          {isMyProfile && (
            <UploadVideoModal
              onClose={closeModal}
              open={isModalOpen}
              refetchHighlights={refetchHighlights}
              userId={userId}
            />
          )}
        </>
      )}
    </WaitTillLoaded>
  )
}
