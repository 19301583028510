import React from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'

import { NxTypography } from '@playvs-inc/nexus-components'

import { EsportSlug } from '@plvs/graphql/types'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { showIntercomArticle } from '@plvs/respawn/features/analytics/intercom/intercom'
import { Box } from '@plvs/respawn/features/layout/Box'
import { useEsport as useNexusEsport } from '@playvs-inc/nexus-theme'

export const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: 160,
    [theme.breakpoints.down('xs')]: {
      borderRadius: theme.shape.borderRadius * 2,
      height: 130,
    },
  },
  rulebookOverlay: ({
    overlayColor,
  }: {
    overlayColor: string
  }): CreateCSSProperties => ({
    display: 'flex',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: overlayColor,
      opacity: 0.7,
      [theme.breakpoints.down('xs')]: {
        borderRadius: theme.shape.borderRadius * 2,
      },
    },
  }),
  icon: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    width: 70,
    height: 70,
  },
  resourceText: {
    marginTop: theme.spacing(2),
    textAlign: 'left',
  },
}))

const NINTENDO_ESPORT_SLUGS = [
  EsportSlug.SmashBrosUltimate,
  EsportSlug.MarioKart,
  EsportSlug.Splatoon,
]

interface Props {
  esportSlug?: EsportSlug
  imgClassName?: string
  isPublic: boolean
  rulebookUrl: string
  rulebookImgSrc?: string
  rulebookName?: string
  titleClassName?: string
  alt?: string
}

export const RulebookImg: React.FC<Props> = ({
  esportSlug,
  imgClassName,
  isPublic,
  rulebookUrl,
  rulebookImgSrc,
  rulebookName,
  titleClassName,
  alt = 'alt',
}) => {
  const theme = useTheme()

  const {
    name: esportName,
    NexusIcon,
    rulebookImage,
  } = useGeneralEsportAdapter(esportSlug)

  const imgSrc = rulebookImgSrc || rulebookImage || ''
  const name = rulebookName || esportName

  const esport = useNexusEsport(esportSlug ?? '')
  const overlayColor = NINTENDO_ESPORT_SLUGS.includes(
    esportSlug || EsportSlug.LeagueOfLegends
  )
    ? theme.palette.common.black
    : esport?.palette[6]

  const classes = useStyles({
    overlayColor: overlayColor || theme.palette.common.white,
  })

  const handleClick = (): void => {
    if (isPublic) {
      window.open(rulebookUrl, '_blank', 'noreferrer')
    } else {
      const rulebookArticleId = rulebookUrl?.split('/').pop() || ''
      showIntercomArticle(rulebookArticleId)
    }
  }

  return (
    <Box className={classes.root} onClick={handleClick}>
      <div className={classes.rulebookOverlay}>
        <img
          alt={alt}
          className={clsx(classes.img, imgClassName)}
          src={imgSrc}
        />
        <NexusIcon
          className={classes.icon}
          color={theme.palette.common.white}
          mode="dark"
        />
      </div>
      <NxTypography
        className={clsx(classes.resourceText, titleClassName)}
        variant="body3"
      >
        {name} Rulebook
      </NxTypography>
    </Box>
  )
}
