import { head, map } from 'ramda'
import React from 'react'
import { useQuery } from '@apollo/client'

import { useEsportContext } from '@plvs/respawn/features/esport/Esport'
import {
  GetTopPerformersStatsByTeamIdQuery,
  GetTopPerformersStatsByTeamIdDocument,
  GetTopPerformersStatsByTeamIdQueryVariables,
  Phase,
} from '@plvs/graphql'
import { PhaseTopPerformers } from './PhaseTopPerformers'

export const PlayerPhaseTopPerformers: React.FC<{
  teamId: string
  phase?: Pick<Phase, 'id' | 'endsAt'>
  metaseasonId: string
}> = ({ teamId, phase, metaseasonId }) => {
  const { data, loading } = useQuery<
    GetTopPerformersStatsByTeamIdQuery,
    GetTopPerformersStatsByTeamIdQueryVariables
  >(GetTopPerformersStatsByTeamIdDocument, {
    variables: {
      id: teamId,
      phaseId: phase?.id ?? '',
      date: phase?.endsAt ?? '',
      metaseasonId,
    },
  })

  const { phaseTopPerformerCategories } = useEsportContext()

  const members = data?.team?.roster?.players ?? []
  const membersForTableData = members.filter(({ user }) => user.phaseStats)
  const categories = phaseTopPerformerCategories ?? []
  const tableData = map(({ user }) => {
    return {
      player: {
        id: user.id ?? '',
        avatarUrl: user.avatarUrl,
        name: user.name || 'N/A',
      },
      logoUrl: user?.school?.logoUrl,
      stats: user?.phaseStats ? head(user?.phaseStats)?.stats ?? null : null,
    }
  }, membersForTableData)

  return (
    <PhaseTopPerformers
      categories={categories}
      data={tableData}
      loading={loading}
    />
  )
}
