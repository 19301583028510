import React from 'react'
import {
  NxButton,
  NxEmptyState,
  NxTypography,
  Pill,
} from '@playvs-inc/nexus-components'
import { makeStyles } from '@material-ui/core'
import {
  Provider,
  SchoolProvider,
  useGetChildPlatformConnectionsQuery,
} from '@plvs/graphql/generated'
import { Path } from '@plvs/const'
import { Box } from '@plvs/respawn/features/layout'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { useNavigate } from 'react-router-dom'
import { ChildSettingsCard } from '../childSettings/ChildSettingsCard'
import { AccountProviderLogo } from '../connections/AccountProviderLogo'
import { providerTitleMap } from '../connections/ProviderConnection/utils'

const useStyles = makeStyles((theme) => ({
  providerName: {
    marginLeft: theme.spacing(2),
  },
  pill: {
    marginLeft: 'auto',
  },
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  emptyState: {
    borderRadius: 0,
  },
}))

interface Props {
  userId: string
}

export const ChildPlatformConnections: React.FC<Props> = ({ userId }) => {
  const navigate = useNavigate()

  const classes = useStyles()

  const { data, loading } = useGetChildPlatformConnectionsQuery({
    variables: { id: userId },
    skip: !userId,
  })

  const handleManage = (): void => {
    navigate(`${Path.App}/child/${userId}/platform-connections`)
  }

  const connections = data?.user?.userProviderAccounts ?? []
  const content = connections.map((connection) => {
    return {
      id: connection?.id ?? '',
      content: (
        <Box
          alignItems="center"
          data-testid={`${connection?.providerName}-connection`}
          display="flex"
          width="100%"
        >
          <AccountProviderLogo
            provider={connection.providerName as Provider | SchoolProvider}
          />
          <div className={classes.providerName}>
            <NxTypography variant="body2">
              {providerTitleMap[connection?.providerName]}
            </NxTypography>
            <NxTypography colorToken="ColorTextAlt2" variant="body3">
              {connection?.providerDisplayName ?? ''}
            </NxTypography>
          </div>
          <Pill
            className={classes.pill}
            label="Connected"
            size="medium"
            variant="success"
          />
        </Box>
      ),
    }
  })

  if (loading) {
    return null
  }

  return (
    <div className={classes.root}>
      <ChildSettingsCard
        customContent={content}
        emptyState={
          connections.length === 0 && (
            <NxEmptyState
              className={classes.emptyState}
              cta={
                <NxButton
                  label="Manage Connections"
                  onClick={handleManage}
                  variant="secondary"
                />
              }
              data-testid="empty"
              spot={
                <NxSpot
                  domain="connectedAccounts"
                  height={100}
                  size="large"
                  variant="secondary"
                  width={100}
                />
              }
              subtitle="Your child doesn’t have connected accounts. You can manage their connections here."
              title="No Connected Accounts"
            />
          )
        }
        title="Platform Connections"
        titleCta={
          connections.length > 0 && (
            <NxButton
              data-testid="manage"
              label="Manage"
              onClick={handleManage}
              variant="secondary"
            />
          )
        }
      />
    </div>
  )
}
