import React, { useContext, useEffect, useMemo, useState } from 'react'
import { noop } from 'ramda-adjunct'

import {
  NxScoreboardSide,
  NxUserClusterProps,
} from '@playvs-inc/nexus-components'
import { useAppLocationFn, useUserIdentityFn } from '@plvs/client-data/hooks'
import { Location } from '@plvs/client-data/models'
import * as analytics from '@plvs/respawn/features/analytics'
import {
  GameAssistantSelectionFragment,
  GameAssistantStepUpdatedDocument,
  MatchStatus,
  GameAssistantTurnType,
  useGetCurrentGameAssistantStepByMatchIdQuery,
  EsportSlug,
  GameAssistantStepUpdatedSubscription,
  GameAssistantStepUpdatedSubscriptionVariables,
  GameAssistantSelection,
  useGetMatchForMatchAssistantQuery,
} from '@plvs/graphql'

import {
  PlayerSide,
  MatchAssistantStep,
  StepType,
  stepToStepType,
  getPickForGameOrdinal,
  getTeamAndRosterForSteps,
} from '@plvs/utils'

import { LocalStorageKey } from '@plvs/const'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { useMatchLobbyRenderControllerState } from '@plvs/respawn/renderController'

import {
  MatchAssistantProviderProps,
  UseMatchAssistantReturn,
  LobbySeries,
} from './useMatchAssistant.types'
import { mapSeriesToScoreboardRowArray } from './MatchAssistant.helpers'
import { esportSlugToProvider } from '../../account/connections/utils'
import { getDisplayForTeamsInMatchAssistant } from '../lobby/VsMissionControl.helpers'

const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24

export function mapTeamRoster({
  teamRoster,
  teamName,
}: {
  teamName?: string
  teamRoster: {
    id: string
    name: string
    avatarUrl: string
    publisherAccountName: string
  }[]
}): NxUserClusterProps[] {
  return teamRoster.map((player) => ({
    title: player.name ?? '',
    subtitles: [{ title: player.publisherAccountName || teamName || '' }],
    avatarUrl: player.avatarUrl ?? '',
    avatarHashId: player.id,
  }))
}

export function useMatchAssistant({
  matchId,
  myTeamId: teamId,
  esport,
  refetch,
  skipMatchAssistant,
}: MatchAssistantProviderProps): UseMatchAssistantReturn {
  const { id: esportId, hasSeries, rating, name } = useGeneralEsportAdapter(
    esport
  )

  const {
    getMatchLobbyRenderControllerState,
  } = useMatchLobbyRenderControllerState()
  // The below logic is for analytics only
  const [actionsTakenInSteps, setActionsTakenInSteps] = useState<
    Record<string, string>
  >({})
  const [stepTimestamps, setStepTimestamps] = useState<
    Record<
      string,
      {
        fromStepToStep: string
        timeActionWasResolved: string
      }
    >
  >({})

  const location = useAppLocationFn()
  const isInCheckpoint = location === Location.Checkpoint

  // This state is for firing the event to trigger the NPS Wootric survey
  const [submittedUserIdForNPS, setSubmittedUserIdForNPS] = useState('')
  const [showMatchAssistantIntro, setShowMatchAssistantIntro] = useState(true)
  const [showPreview, setShowPreview] = useState(false)
  const closePreview = (): void => {
    setShowPreview(false)
  }

  const [prevStepId, setPrevStepId] = useState('')
  const { userId } = useUserIdentityFn()

  const {
    data: matchData,
    refetch: refetchMatch,
  } = useGetMatchForMatchAssistantQuery({
    variables: {
      matchId: matchId ?? '',
      includeSeries: hasSeries,
    },
    skip: !matchId,
  })

  const match = {
    ...matchData?.match,
    esport: {
      id: esportId,
      slug: esport,
      rating,
      name,
    },
  }

  const indexNameSpace = `${LocalStorageKey.MatchAssistantStepIndex}-${teamId}`
  const stepNameSpace = `${LocalStorageKey.MatchAssistantStep}-${teamId}`
  const gameOrdinalNameSpace = `${LocalStorageKey.MatchAssistantGameOrdinal}-${teamId}`

  const [stepIndex, setStepIndex] = useState<number>(
    () => Number(localStorage.getItem(indexNameSpace)) ?? 0
  )

  const [gameOrdinal, setGameOrdinal] = useState<number>(
    () => Number(localStorage.getItem(gameOrdinalNameSpace)) ?? 1
  )

  const setStepIndexInLocalStorage = (input: number): void => {
    setStepIndex(input)
    localStorage.setItem(indexNameSpace, input.toString())
  }

  const setGameOrdinalInLocalStorage = (input: number): void => {
    setGameOrdinal(input)
    localStorage.setItem(gameOrdinalNameSpace, input.toString())
  }

  const homeTeam = match?.team1
  const awayTeam = match?.team2

  const refinedHomeTeam = getDisplayForTeamsInMatchAssistant({
    team: homeTeam,
    esportRating: rating,
  })

  const refinedAwayTeam = getDisplayForTeamsInMatchAssistant({
    team: awayTeam,
    esportRating: rating,
  })

  const analyticsFn = ({
    prevState,
    newState,
  }: {
    prevState: { stepOrdinal: number | undefined; stepId: string | undefined }
    newState: { stepOrdinal: number | undefined }
  }): void => {
    if (prevState.stepId && newState) {
      setStepTimestamps({
        ...stepTimestamps,
        [prevState.stepId]: {
          fromStepToStep: `${prevState.stepOrdinal} to ${newState.stepOrdinal}`,
          timeActionWasResolved: new Date().toISOString(),
        },
      })
    }

    setPrevStepId(prevState?.stepId ?? '')
  }

  const matchAssistantActions = getMatchLobbyRenderControllerState().match

  const shouldSkipSubscription =
    !matchId ||
    (!matchAssistantActions.canSpectate &&
      !matchAssistantActions.canParticipate)

  const {
    data: queryData,
    loading: currentStepLoading,
    refetch: refetchCurrentGameAssistantStepByMatchId,
    subscribeToMore,
    previousData,
  } = useGetCurrentGameAssistantStepByMatchIdQuery({
    variables: {
      input: {
        matchId: matchId || '',
        teamId: teamId || '',
      },
    },
    skip: shouldSkipSubscription,
  })

  useEffect(() => {
    if (shouldSkipSubscription) {
      return
    }

    refetchCurrentGameAssistantStepByMatchId({
      input: {
        matchId: matchId || '',
        teamId: teamId || '',
      },
    })
  }, [teamId, matchId])

  useEffect(() => {
    if (teamId) {
      subscribeToMore<
        GameAssistantStepUpdatedSubscription,
        GameAssistantStepUpdatedSubscriptionVariables
      >({
        document: GameAssistantStepUpdatedDocument,
        variables: {
          input: {
            teamId,
            matchId: matchId ?? '',
          },
        },
        updateQuery: (prev, { subscriptionData }) => {
          const newState =
            subscriptionData?.data?.gameAssistantStepUpdated?.currentState
          const prevState = prev.currentGameAssistantStateByMatchId

          if (!newState?.showMatchAssistant) {
            skipMatchAssistant?.()
            // Returning prev only for typing purposes.
            return prev
          }

          if (newState.step?.stepOrdinal !== prevState.step?.stepOrdinal) {
            const p = {
              stepOrdinal: prevState.step?.stepOrdinal,
              stepId: prevState.step?.id,
            }
            const n = { stepOrdinal: newState.step?.stepOrdinal }
            analyticsFn({ prevState: p, newState: n })
          }

          return {
            ...prev,
            currentGameAssistantStateByMatchId: newState,
          }
        },
      })
    }
  }, [subscribeToMore, teamId, matchId])

  useEffect(() => {
    if (prevStepId && actionsTakenInSteps[prevStepId]) {
      analytics.matchAssistantStepTimestamps({
        ...stepTimestamps[prevStepId],
        timeActionWasTaken: actionsTakenInSteps[prevStepId],
      })
    } else if (
      previousData?.currentGameAssistantStateByMatchId?.step?.stepOrdinal !==
        queryData?.currentGameAssistantStateByMatchId?.step?.stepOrdinal &&
      actionsTakenInSteps[
        previousData?.currentGameAssistantStateByMatchId?.step?.stepOrdinal ??
          ''
      ]
    ) {
      const prevState = {
        stepId:
          previousData?.currentGameAssistantStateByMatchId?.step?.id ?? '',
        stepOrdinal:
          previousData?.currentGameAssistantStateByMatchId?.step?.stepOrdinal ??
          0,
      }
      const newState = {
        stepOrdinal:
          queryData?.currentGameAssistantStateByMatchId?.step?.stepOrdinal ?? 0,
      }
      analyticsFn({ prevState, newState })
      analytics.matchAssistantStepTimestamps({
        ...stepTimestamps[
          previousData?.currentGameAssistantStateByMatchId?.step?.id ?? ''
        ],
        timeActionWasTaken:
          actionsTakenInSteps[
            previousData?.currentGameAssistantStateByMatchId?.step?.id ?? ''
          ],
      })
    }
  }, [
    prevStepId,
    actionsTakenInSteps[prevStepId],
    previousData?.currentGameAssistantStateByMatchId?.step?.stepOrdinal,
    queryData?.currentGameAssistantStateByMatchId?.step?.stepOrdinal,
  ])

  const currentStep = queryData?.currentGameAssistantStateByMatchId as MatchAssistantStep

  const step =
    match?.status === MatchStatus.Completed ||
    match?.status === MatchStatus.Forfeited
      ? StepType.Final
      : stepToStepType({
          selectionOptionType: currentStep?.step?.selectionOptionType,
          stepType: currentStep?.step?.type ?? '',
          isComplete: currentStep?.matchDetails?.isComplete ?? false,
        })

  const [stepState, setStepState] = useState<string | null>(
    () => localStorage.getItem(stepNameSpace) ?? step
  )

  const myRoster = queryData?.currentGameAssistantStateByMatchId?.roster
  const opponentRoster =
    queryData?.currentGameAssistantStateByMatchId?.opponentRoster
  const homeTeamRoster =
    homeTeam?.id === myRoster?.teamId ? myRoster : opponentRoster
  const awayTeamRoster =
    awayTeam?.id === myRoster?.teamId ? myRoster : opponentRoster

  const homeTeamWithRoster = useMemo(
    () =>
      getTeamAndRosterForSteps(
        homeTeamRoster,
        refinedHomeTeam,
        esportSlugToProvider[esport as EsportSlug]
      ),
    [homeTeamRoster, refinedHomeTeam?.id, esport]
  )
  const awayTeamWithRoster = useMemo(
    () =>
      getTeamAndRosterForSteps(
        awayTeamRoster,
        refinedAwayTeam,
        esportSlugToProvider[esport as EsportSlug]
      ),
    [awayTeamRoster, refinedAwayTeam?.id, esport]
  )

  const matchSeasonId = match?.slot?.phase?.season?.metaseasonId ?? ''

  const currentSeries =
    queryData?.currentGameAssistantStateByMatchId?.currentSeries

  const currentPlayers = [
    ...(currentSeries?.team1?.roster || []),
    ...(currentSeries?.team2?.roster || []),
  ]
  const currentPlayerOptions: NxScoreboardSide[] = mapSeriesToScoreboardRowArray(
    {
      homeTeamId: homeTeam?.id,
      series: (currentSeries ? [currentSeries] : []) as LobbySeries[],
    }
  )

  const isMyTeamHome = homeTeam?.isMyTeam || homeTeam?.id === teamId
  const selectionSide = isMyTeamHome ? PlayerSide.Home : PlayerSide.Away

  const currentTurn = currentStep?.step?.turn || GameAssistantTurnType.All

  const isMyTurn = currentStep?.step?.teamId === teamId

  const myTeamSelection = getPickForGameOrdinal({
    gameOrdinal: currentStep?.step?.gameOrdinal,
    teamId,
    options: currentStep?.characterPicks as GameAssistantSelection[],
  })

  const opposingTeam =
    homeTeam?.id === teamId ? awayTeamWithRoster : homeTeamWithRoster
  const opposingTeamId = opposingTeam?.id
  const opposingTeamSelection = getPickForGameOrdinal({
    gameOrdinal: currentStep?.step?.gameOrdinal,
    teamId: opposingTeamId,
    options: currentStep?.characterPicks as GameAssistantSelection[],
  })

  const homeCharacter = isMyTeamHome ? myTeamSelection : opposingTeamSelection
  const awayCharacter = isMyTeamHome ? opposingTeamSelection : myTeamSelection

  const selectedStage = currentStep?.mapPicks?.find(
    (map) =>
      map.gameAssistantStep?.gameOrdinal === currentStep?.step?.gameOrdinal
  )

  const shouldRenderMatchAssistant = match?.status !== MatchStatus.Quarantined

  const matchDoesNotOpenSoon =
    Date.parse(match?.scheduledStartsAt ?? '') - Date.now() >= ONE_DAY_IN_MS
  const isFallback = step === StepType.Initial && !currentStepLoading
  const shouldRenderIntro =
    !isInCheckpoint && (isFallback || matchDoesNotOpenSoon)

  const charactersByUserId: Record<string, GameAssistantSelectionFragment> =
    currentStep?.characterPicks?.reduce((acc, pick) => {
      if (pick.userId) {
        acc[pick.userId] = {
          ...(acc[pick.userId] || {}),
          [pick.gameAssistantStep?.gameOrdinal ?? 0]: pick,
        }
      }
      return acc
    }, {}) || {}

  useEffect(() => {
    if (stepState !== step || currentStep?.step?.gameOrdinal !== gameOrdinal) {
      setStepState(step)
      setGameOrdinalInLocalStorage(currentStep?.step?.gameOrdinal ?? 1)
      setStepIndexInLocalStorage(0)
      if (step) {
        localStorage.setItem(stepNameSpace, step)
      }
      if (currentStep?.step?.gameOrdinal) {
        localStorage.setItem(
          gameOrdinalNameSpace,
          currentStep?.step?.gameOrdinal.toString()
        )
      }
    }
  }, [step, stepState, currentStep?.step?.gameOrdinal, gameOrdinal])

  function refetchData(): void {
    refetch?.()
    refetchMatch({
      matchId: matchId ?? '',
    })
  }

  const data = {
    currentSeries,
    shouldRenderMatchAssistant,
    shouldRenderIntro,
    currentPlayers,
    currentStep,
    currentStepLoading,
    match,
    isMyTeamHome,
    esportSlug: esport,
    esportId,
    matchId: match?.id ?? '',
    userId,
    teamId,
    homeTeam: homeTeamWithRoster,
    awayTeam: awayTeamWithRoster,
    opposingTeam,
    myTeam: homeTeam?.isMyTeam ? homeTeamWithRoster : awayTeamWithRoster,
    homeTeamId: homeTeam?.id,
    awayTeamId: awayTeam?.id,
    opposingTeamId,
    stepIndex,
    setStepIndex: setStepIndexInLocalStorage,
    currentPlayerOptions,
    gameOrdinal: currentStep?.step?.gameOrdinal,
    seriesOrdinal: currentStep?.step?.seriesOrdinal,
    stepOrdinal: currentStep?.step?.stepOrdinal,
    isInCheckpoint,
    step,
    selectionSide,
    currentTurn,
    isMyTurn,
    characterSelections: currentStep?.characterPicks ?? [],
    charactersByUserId,
    mapSelections: currentStep?.mapBans ?? [],
    homeCharacter,
    awayCharacter,
    selectedStage,
    lolGameCode: currentStep?.lolGameCode,
    gameMode: currentStep?.splatoonGameMode,
    refetchCurrentGameAssistantStepByMatchId,
    showPreview,
    closePreview,
    setShowPreview,
    setActionsTakenInSteps,
    actionsTakenInSteps,
    showMatchAssistantIntro,
    setShowMatchAssistantIntro,
    submittedUserIdForNPS,
    matchSeasonId,
    setSubmittedUserIdForNPS,
    refetch: refetchData,
    showRulebookButton: matchAssistantActions?.showRulebookButton ?? false,
  }

  return (data as unknown) as UseMatchAssistantReturn
}

const context = React.createContext<UseMatchAssistantReturn>({
  refetch: noop,
  shouldRenderMatchAssistant: false,
  currentPlayers: [],
  currentStep: undefined,
  isCoachForTeamsOrSchools: false,
  subscriptionData: undefined,
  teamId: undefined,
  homeTeam: null,
  awayTeam: null,
  myTeam: null,
  opposingTeam: null,
  homeTeamId: '',
  awayTeamId: '',
  opposingTeamId: '',
  matchId: '',
  userId: '',
  currentPlayerOptions: [],
  gameOrdinal: 1,
  seriesOrdinal: 1,
  stepOrdinal: 1,
  characterSelections: [],
  mapSelections: [],
  currentStepLoading: false,
  step: StepType.CharacterSelect,
  selectionSide: PlayerSide.Home,
  charactersByUserId: {},
  currentTurn: undefined,
  isMyTurn: false,
  homeCharacter: undefined,
  awayCharacter: undefined,
  MatchAssistantActions: undefined,
  match: undefined,
  isMyTeamHome: false,
  esportSlug: undefined,
  esportId: undefined,
  isInCheckpoint: false,
  stepIndex: 0,
  showMatchAssistantIntro: false,
  setShowMatchAssistantIntro: noop,
  setStepIndex: noop,
  closePreview: noop,
  setShowPreview: noop,
  showPreview: false,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  refetchCurrentGameAssistantStepByMatchId: noop,
  submittedUserIdForNPS: '',
  setSubmittedUserIdForNPS: noop,
  matchSeasonId: '',
  gameMode: undefined,
  showRulebookButton: false,
})
const { Provider } = context

export const MatchAssistantProvider: React.FC<MatchAssistantProviderProps> = ({
  esport,
  children,
  myTeamId,
  skipMatchAssistant,
  refetch,
  matchId,
}) => {
  const values = useMatchAssistant({
    myTeamId,
    esport,
    skipMatchAssistant,
    refetch,
    matchId,
  })
  return <Provider value={values}>{children}</Provider>
}

export const useMatchAssistantContext = (): UseMatchAssistantReturn => {
  return useContext(context)
}
