import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { Polling } from '@plvs/const'
import {
  OnlineStatus,
  useGetUsersOnlineStatusQuery,
} from '@plvs/graphql/generated'
import { flatten, uniq } from 'ramda'
import React, { useState } from 'react'

export type UserOnlineStatusMap = Record<string, OnlineStatus>
export interface UserOnlineStatusContext {
  onlineStatusByUserId: UserOnlineStatusMap | undefined
  loading: boolean
  setCurrentUserIdsMap?(input: Record<string, string[]>): void
  currentUserIdsMap: Record<string, string[]>
}

export const userOnlineStatusContext =
  React.createContext<UserOnlineStatusContext>({
    onlineStatusByUserId: {},
    loading: false,

    currentUserIdsMap: {},
  })

export const useUserOnlineStatusContext = (): UserOnlineStatusContext => {
  return React.useContext(userOnlineStatusContext)
}

export const UserOnlineStatusProvider: React.FC = ({ children }) => {
  const { loading: identityLoading, isLoggedIn } = useUserIdentityFn()

  const [currentUserIdsMap, setCurrentUserIdsMap] = useState<
    Record<string, string[]>
  >({})

  const userIds = uniq(flatten(Object.values(currentUserIdsMap)))

  const { data, loading } = useGetUsersOnlineStatusQuery({
    variables: {
      input: {
        userIds,
      },
    },
    pollInterval: Polling.Normal,
    skip: !userIds.length || !isLoggedIn || identityLoading,
  })

  const usersOnlineStatusMap =
    data?.getUsersOnlineStatus?.usersOnlineStatus?.reduce((acc, curr) => {
      if (!acc[curr.userId]) {
        acc[curr.userId] = curr.onlineStatus as OnlineStatus
      }
      return acc
    }, {} as Record<string, OnlineStatus>)

  return (
    <userOnlineStatusContext.Provider
      value={{
        onlineStatusByUserId: usersOnlineStatusMap,
        loading,
        setCurrentUserIdsMap,
        currentUserIdsMap,
      }}
    >
      {children}
    </userOnlineStatusContext.Provider>
  )
}
