import React from 'react'
import { Box, Button, IconButton, Menu, MenuItem } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { MoreVert } from '@material-ui/icons'
import { useMutation } from '@apollo/client'

import { cleanGraphQLError } from '@plvs/utils'
import {
  NxModal,
  NxButton,
  NxTypography,
  NxMatchDetailsCluster,
  NxTooltip,
} from '@playvs-inc/nexus-components'
import { Column, TableColumn } from '@plvs/rally/components/table'
import {
  CancelScrimmageDocument,
  CancelScrimmageMutationVariables,
  CancelScrimmageMutation,
  DeclineScrimmageMutation,
  useDeclineScrimmageMutation,
  EsportSlug,
  Esport,
  Maybe,
  School,
  ScrimmageRequest,
  ScrimmageRequestStatus,
  Team,
  refetchScrimmageRequestQuery,
} from '@plvs/graphql'
import { Banner, BannerType } from '@plvs/respawn/features/banner'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { getRefetchScrimmageQueries } from '@plvs/rally/components/scrimmage/scrimmageHelpers'

import { getRefetchMatchLobbyQueries } from '../match'

const useGetCopy = ({
  bestOf,
  esport,
  opposingTeam,
  requestingTeam,

  isDirectRequestOpponent,
}: CancelScrimmageButtonProps['scrimmageRequest'] & {
  scrimmageIsFilled: boolean
  isDirectRequestOpponent: boolean
}): {
  details: string
  title: string
  actionWord: string
  actionWordPastTense: string
  esportSlug: string | undefined
  teamA: string
  teamB: string
  esportName: string
} => {
  const requestingTeamName = requestingTeam?.name ?? 'TBD'
  const opposingTeamName = opposingTeam?.name ?? 'TBD'
  const esportAdapter = useGeneralEsportAdapter(
    esport?.slug ?? EsportSlug.LeagueOfLegends
  )
  const esportName = esportAdapter?.name ?? 'Unknown'

  return {
    teamA: requestingTeamName,
    teamB: opposingTeamName,
    details: `${esportName}, Best of ${bestOf}.`,
    title: isDirectRequestOpponent ? 'Decline Scrimmage' : 'Cancel Scrimmage',
    actionWord: isDirectRequestOpponent ? 'Decline' : 'Cancel',
    actionWordPastTense: isDirectRequestOpponent ? 'Declined' : 'Cancelled',
    esportSlug: esport?.slug ?? '',
    esportName,
  }
}

const getScrimmageTeamIdFromSchoolId = ({
  schoolId,
  scrimmageRequest,
}: {
  schoolId: string
  scrimmageRequest: CancelScrimmageButtonProps['scrimmageRequest']
}): string | undefined => {
  const requestingTeamSchoolId = scrimmageRequest?.requestingTeam?.school?.id
  const requestingTeamId = scrimmageRequest?.requestingTeam?.id
  const opposingTeamSchoolId = scrimmageRequest?.opposingTeam?.school?.id
  const opposingTeamId = scrimmageRequest?.opposingTeam?.id

  if (requestingTeamSchoolId === schoolId) return requestingTeamId
  if (opposingTeamSchoolId === schoolId) return opposingTeamId
  return undefined
}

type ButtonComponentProps = Pick<
  ButtonProps,
  'children' | 'disabled' | 'ref' | 'style'
>

type ScrimmageTeam = Maybe<
  Pick<Team, 'id' | 'name'> & { school?: Maybe<Pick<School, 'id'>> }
>

type CancelScrimmageButtonProps = {
  ButtonComponent?(
    props: ButtonComponentProps & Pick<ButtonProps, 'onClick'>
  ): React.ReactElement
  buttonProps?: ButtonComponentProps
  matchIdForRefetchingMatchLobbyQueries?: string
  schoolId?: string
  schoolIdForRefetchingScrimmageQueries?: string
  scrimmageRequest: Pick<ScrimmageRequest, 'id' | 'bestOf' | 'startsAt'> & {
    esport?: Maybe<Pick<Esport, 'id' | 'slug' | 'name'>>
    requestingTeam?: ScrimmageTeam
    opposingTeam?: ScrimmageTeam
    status?: ScrimmageRequestStatus
  }
}

export const CancelScrimmageButton = React.forwardRef(
  (
    {
      ButtonComponent = Button,
      buttonProps,
      matchIdForRefetchingMatchLobbyQueries,
      schoolId, // used to determine teamId
      schoolIdForRefetchingScrimmageQueries,
      scrimmageRequest,
    }: CancelScrimmageButtonProps,
    ref: React.Ref<any>
  ): React.ReactElement => {
    const [error, setError] = React.useState<Error>()
    const [isSuccess, setSuccess] = React.useState<boolean>(false)
    const [isDialogOpen, setIsDialogOpen] = React.useState(false)
    const openDialog = (): void => setIsDialogOpen(true)
    const closeDialog = (): void => setIsDialogOpen(false)

    const onSuccess = (val: boolean): void => {
      setSuccess(val ?? false)
    }

    const [submitCancel, { loading: isSubmittingCancel }] = useMutation<
      CancelScrimmageMutation,
      CancelScrimmageMutationVariables
    >(CancelScrimmageDocument, {
      onCompleted: (data: CancelScrimmageMutation): void =>
        onSuccess(data?.cancelScrimmage.success ?? false),
    })

    const [
      submitDecline,
      { loading: isSubmittingDecline },
    ] = useDeclineScrimmageMutation({
      onCompleted: (data: DeclineScrimmageMutation): void => {
        return onSuccess(data?.declineScrimmage?.success ?? false)
      },
    })

    const isDirectRequestOpponent =
      scrimmageRequest.status === ScrimmageRequestStatus.Requested &&
      schoolId === scrimmageRequest.opposingTeam?.school?.id

    const teamId = schoolId
      ? getScrimmageTeamIdFromSchoolId({ schoolId, scrimmageRequest })
      : undefined

    const payload = {
      awaitRefetchQueries: true,
      refetchQueries: [
        ...getRefetchMatchLobbyQueries(
          matchIdForRefetchingMatchLobbyQueries ?? null
        ),
        ...getRefetchScrimmageQueries(
          schoolIdForRefetchingScrimmageQueries ?? null
        ),
        refetchScrimmageRequestQuery({
          id: scrimmageRequest.id,
          schoolId: schoolIdForRefetchingScrimmageQueries ?? '',
        }),
      ],
      variables: {
        input: {
          id: scrimmageRequest.id,
          teamId,
        },
      },
    }

    const submitCancelScrimmageInfo = async (): Promise<void> => {
      try {
        if (isDirectRequestOpponent) {
          await submitDecline(payload)
        } else {
          await submitCancel(payload)
        }
      } catch (e: any) {
        setError(e)
        onSuccess(false)
      }
    }

    const copy = useGetCopy({
      ...scrimmageRequest,
      scrimmageIsFilled: !!scrimmageRequest?.opposingTeam,
      isDirectRequestOpponent,
    })

    const canCancelScrimmage =
      Date.now() < Date.parse(scrimmageRequest.startsAt) &&
      scrimmageRequest.status &&
      [
        ScrimmageRequestStatus.Processed,
        ScrimmageRequestStatus.Scheduled,
        ScrimmageRequestStatus.NeedsOpponent,
        ScrimmageRequestStatus.NoResponse,
      ].includes(scrimmageRequest.status)

    return (
      <div>
        {canCancelScrimmage ? (
          <ButtonComponent ref={ref} onClick={openDialog} {...buttonProps}>
            {buttonProps?.children ?? copy.title}
          </ButtonComponent>
        ) : (
          <NxTooltip title="Cannot cancel a scrimmage that is not open or has a start time in the past.">
            <Box>
              <ButtonComponent
                ref={ref}
                onClick={openDialog}
                {...buttonProps}
                disabled={!canCancelScrimmage}
              >
                {buttonProps?.children ?? copy.title}
              </ButtonComponent>
            </Box>
          </NxTooltip>
        )}

        {isSuccess ? (
          <NxModal
            actions={
              <NxButton label="Done" onClick={closeDialog} variant="primary" />
            }
            onClose={closeDialog}
            open={isDialogOpen}
            showTopRightClose
            size="small"
            title={`Scrimmage ${copy.actionWordPastTense}`}
          >
            <NxTypography variant="body1">{`Your scrimmage was ${copy.actionWordPastTense} successfully.`}</NxTypography>
          </NxModal>
        ) : (
          <NxModal
            actions={
              <>
                <NxButton label="Close" onClick={closeDialog} variant="text" />
                <NxButton
                  data-cy="cancelScrimmageDialogButton"
                  disabled={isSubmittingCancel || isSubmittingDecline}
                  label="Confirm"
                  onClick={submitCancelScrimmageInfo}
                  variant="primary"
                />
              </>
            }
            onClose={closeDialog}
            open={isDialogOpen}
            showTopRightClose
            size="small"
            title={copy.title}
          >
            {error && (
              <div style={{ marginBottom: 40 }}>
                <Banner
                  subtitle={cleanGraphQLError(error.message)}
                  title={`Unable to ${copy.actionWord} scrimmage.`}
                  type={BannerType.Error}
                />
              </div>
            )}

            <>
              <NxTypography variant="body1">
                Are you sure you want to {copy.actionWord} this scrimmage?{'\n'}
                This cannot be undone
              </NxTypography>
              <Box pt={2}>
                <NxMatchDetailsCluster
                  bestOf={scrimmageRequest.bestOf ?? undefined}
                  esport={copy.esportSlug ?? ''}
                  esportName={copy.esportName}
                  showAvatar
                  teamAName={copy.teamA}
                  teamBName={copy.teamB}
                  timestamp={scrimmageRequest.startsAt}
                />
              </Box>
            </>
          </NxModal>
        )}
      </div>
    )
  }
)

export const getScrimmageCancelColumn = ({
  schoolId,
}: {
  schoolId: string
}): TableColumn<Column.ScrimmageRow> => ({
  id: 'cancelLink',
  Cell: (scrimmageRequest): React.ReactElement => {
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const openMenu = (): void => setIsMenuOpen(true)
    const closeMenu = (): void => setIsMenuOpen(false)
    const ref = React.useRef() as any

    return (
      <>
        <IconButton
          ref={ref}
          data-cy="cancelScrimmageButton"
          onClick={openMenu}
          style={{ marginLeft: -8, padding: 6 }}
        >
          <MoreVert />
        </IconButton>
        <Menu anchorEl={ref.current} onClose={closeMenu} open={isMenuOpen}>
          <CancelScrimmageButton
            ButtonComponent={MenuItem}
            schoolId={schoolId}
            schoolIdForRefetchingScrimmageQueries={schoolId}
            // @ts-ignore TODO
            scrimmageRequest={scrimmageRequest}
          />
        </Menu>
      </>
    )
  },
  Header: '',
  sortable: false,
  width: 72,
})
