import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers'
import { Box, makeStyles, useTheme } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useCookies } from 'react-cookie'

import { NASEF_QUERY_PARAM, Path } from '@plvs/const'
import { NxButton, NxTypography } from '@playvs-inc/nexus-components'

import {
  useLoginUserMutation,
  LoginUserInput,
  ResourceType,
} from '@plvs/graphql/generated/graphql'
import { formErrorToString, isInputValid } from '@plvs/utils'
import { env } from '@plvs/env'
import { useResourceImageProvider } from '@plvs/respawn/features/resources/ResourceImageProvider'
import { useLocation } from 'react-router'
import { StyledRouterLink } from '@plvs/respawn/features/route/StyledRouterLink'
import { LoginFormSchema, postLogin } from '../auth/utils/helpers'
import { OAuth } from '../auth/components/OAuth'
import { EmailInput, ErrorMessage, PasswordInput } from '../auth'
import { useOAuth } from '../auth/utils'
import { UpdateEnvironment } from './UpdateEnvironment'

const useStyles = makeStyles(() => ({
  ctaText: {
    display: 'flex',
  },
}))

type LoginFormProps = {
  cookieDomain: string
  isNASEFLogin?: boolean
  oAuthRedirect: string
  hideResetPassword?: boolean
  shouldHideGoogleOAuth?: boolean
}

export const LoginForm = ({
  isNASEFLogin,
  oAuthRedirect,
  hideResetPassword = false,
  shouldHideGoogleOAuth = false,
  cookieDomain = env.COOKIE_DOMAIN,
}: LoginFormProps): React.ReactElement => {
  const theme = useTheme()
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setCookie] = useCookies()
  const { isEnabled: isOAuthEnabled } = useOAuth()
  const { updateResources } = useResourceImageProvider()

  const { errors, handleSubmit, register, watch } = useForm<LoginUserInput>({
    resolver: yupResolver<LoginUserInput>(LoginFormSchema),
  })

  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [error, setError] = useState<Error>()
  const classes = useStyles()

  const isValid = isInputValid(watch(), LoginFormSchema)
  const canSubmit = isValid && !isBusy

  const [mutate] = useLoginUserMutation()

  const onSubmit = handleSubmit(
    async (input: LoginUserInput): Promise<void> => {
      setError(undefined)
      setIsBusy(true)

      try {
        const loginResponse = ((await mutate({ variables: { input } })) || {})
          ?.data?.loginUser

        if (!loginResponse) {
          throw new Error('Empty response received from server.')
        }

        postLogin({
          setCookie,
          token: loginResponse.token || '',
          domain: cookieDomain,
          userData: {
            email: input.email,
            userId: loginResponse.user?.id || '',
            isUnderage: loginResponse.user?.isUnderage ?? false,
          },
        })
        updateResources({
          resources: [
            {
              id: loginResponse.user?.id ?? '',
              type: ResourceType.User,
            },
          ],
        })
      } catch (e: any) {
        setError(e)
      } finally {
        setIsBusy(false)
      }
    }
  )

  const onOAuth = async (): Promise<void> => {
    window.location.href = oAuthRedirect
  }

  const signupPath = isNASEFLogin
    ? `${Path.Registration}${NASEF_QUERY_PARAM}`
    : `${Path.Registration}${location.search}`

  return (
    <>
      <ErrorMessage error={error?.message} />

      <Box
        alignItems="center"
        component="form"
        data-testid="login-form"
        display="flex"
        flex={1}
        flexDirection="column"
        height="100%"
        justifyContent="space-between"
        // @ts-ignore This is a valid prop
        method="post"
        noValidate
        onSubmit={onSubmit}
        padding={[[0, 2], [0]]}
      >
        <Box
          display="flex"
          flexDirection="column"
          gridGap={theme.spacing(2)}
          maxWidth={391}
          mt={3}
          textAlign="center"
          width="100%"
        >
          <UpdateEnvironment />

          <OAuth
            isBusy={isBusy}
            isEnabled={isOAuthEnabled}
            onOAuth={onOAuth}
            shouldHide={shouldHideGoogleOAuth}
          />

          <EmailInput
            ref={register}
            error={errors.email}
            helperText={formErrorToString(error)}
          />

          <PasswordInput ref={register} error={errors.password} />

          {!hideResetPassword && (
            <Box textAlign="left">
              <StyledRouterLink
                data-testid="forgotPassword"
                label="Forgot your password?"
                to={Path.ForgotPassword}
              />
            </Box>
          )}

          <Box mt={2}>
            <NxButton
              data-testid="loginButton"
              disabled={!canSubmit}
              fullWidth
              label="Login"
              size="large"
              type="submit"
              variant="primary"
            />
          </Box>
        </Box>

        <Box
          alignItems="center"
          display="flex"
          flexDirection="row"
          justifyContent="center"
          mt={3}
        >
          <NxTypography className={classes.ctaText} variant="body1">
            Need an account?&nbsp;
          </NxTypography>
          <StyledRouterLink
            data-testid="registration"
            label="Sign up"
            to={signupPath}
          />
        </Box>
      </Box>
    </>
  )
}
