import React from 'react'
import {
  NxTypography,
  NxTooltip,
  NxUserCluster,
} from '@playvs-inc/nexus-components'
import { Box } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { CreateCSSProperties } from '@material-ui/styles'
import { NxMiscAssets } from '@playvs-inc/nexus-misc-assets'
import { MatchStatus, MatchTeam, Maybe, School } from '@plvs/graphql/generated'

import { defaultToEmptyString } from '@plvs/utils'
import { getTooltip } from './leagueMatchesHelpers'

const { FirebobDisabled } = NxMiscAssets

interface StyleProps {
  isLeftSide: boolean
}

export const useStyles = makeStyles((theme) => ({
  avatarLeft: {
    marginLeft: theme.spacing(2),
  },
  avatarRight: {
    marginRight: theme.spacing(2),
  },
  teamSection: ({ isLeftSide }: StyleProps): CreateCSSProperties => ({
    textAlign: isLeftSide ? 'right' : 'left',
  }),
  opaqueTeam: {
    opacity: '30%',
  },
}))

interface Props {
  didTeamLose: boolean
  isLeftSide: boolean
  isRestrictedEsport: boolean
  team: Maybe<
    Pick<MatchTeam, 'id' | 'name'> & {
      school: Maybe<Pick<School, 'id' | 'name' | 'logoUrl'>>
    }
  >
  matchStatus: MatchStatus | undefined
}

export const LeagueMatchTeamCluster: React.FC<Props> = ({
  didTeamLose,
  isLeftSide,
  isRestrictedEsport,
  team,
  matchStatus,
}) => {
  const classes = useStyles({ isLeftSide })

  const shouldTooltipAvatar = !isLeftSide && !team?.id
  const tooltip = getTooltip(matchStatus)

  const AvatarCluster = shouldTooltipAvatar ? (
    <Box alignItems="center" display="flex">
      {tooltip ? (
        <NxTooltip arrow placement="right" title={tooltip}>
          <Box alignItems="center" display="flex">
            <FirebobDisabled />
          </Box>
        </NxTooltip>
      ) : (
        <FirebobDisabled />
      )}
      <Box display="flex" flexDirection="column" ml={2}>
        <NxTypography variant="body2">N/A</NxTypography>
        <NxTypography variant="body4">N/A</NxTypography>
      </Box>
    </Box>
  ) : (
    <>
      <NxUserCluster
        alignment={isLeftSide ? 'right' : 'left'}
        avatarHashId={team?.id ?? ''}
        avatarUrl={
          isRestrictedEsport ? '' : defaultToEmptyString(team?.school?.logoUrl)
        }
        className={isLeftSide ? classes.avatarLeft : classes.avatarRight}
        disabled={didTeamLose}
        subtitles={
          team?.school?.name
            ? [
                {
                  title: team?.school?.name,
                },
              ]
            : []
        }
        title={defaultToEmptyString(team?.name)}
      />
    </>
  )

  return AvatarCluster
}
