import { intersection, isNil } from 'ramda'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { UserRoleName } from '@plvs/graphql'
import { LoadingSpinner } from '@playvs-inc/nexus-components'
import { Box } from '@material-ui/core'
import { Path } from '@plvs/const'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { ReplaceWithLogin } from './ReplaceWithLogin'

const ALLOWED_APP_REDIRECTS = [
  `${Path.App}/explore/leagues/`,
  `${Path.App}${Path.Profile}`,
]

export const AuthorizedContainer = ({
  component,
  allowedRoles = [],
}: {
  component: JSX.Element
  allowedRoles?: UserRoleName[]
}): JSX.Element => {
  const { userId, loading, userRoleNames } = useUserIdentityFn()

  const { pathname } = useLocation()

  if (loading)
    return (
      <Box
        alignItems="center"
        display="flex"
        flexGrow={1}
        justifyContent="center"
      >
        <LoadingSpinner />
      </Box>
    )

  // redirects users to url without '/app' in it
  if (
    isNil(userId) &&
    ALLOWED_APP_REDIRECTS.some(
      (allowedPath) => pathname.indexOf(allowedPath) > -1
    )
  ) {
    // need to preserve query string params for explore league detail page
    let stripApp = `${pathname.replace('/app', '')}?`
    const params = new URLSearchParams(window.location.search)
    // eslint-disable-next-line no-return-assign
    params.forEach((param, key) => (stripApp += `${key}=${param}&`))
    return <Navigate replace to={stripApp} />
  }

  if (userId) {
    if (
      allowedRoles &&
      (intersection(userRoleNames, allowedRoles).length || !allowedRoles.length)
    ) {
      return <>{component}</>
    }
    // otherwise, the user does not have the correct roles to access this page
    return <div>Incorrect roles...</div>
  }

  // if we're not logged in, redirect to the /login page
  return <ReplaceWithLogin />
}
