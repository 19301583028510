import React, { useCallback } from 'react'
import { determineProvidersRender } from '@plvs/respawn/renderController/account/providers/utils/providersRenderController.helpers'
import { ProvidersRenderController } from '@plvs/respawn/renderController/account/providers/types/providersRenderController.types'
import { SettingsRenderController } from './settings/types/settingsRenderController.types'
import { determineSettingsRender } from './settings/utils/settingsRenderController.helpers'
import { AccountRenderControllerState } from './AccountRenderController.types'

type UseAccountRenderControllerContextReturn = {
  settings: SettingsRenderController
  providers: ProvidersRenderController
}

type AccountRenderControllerContext =
  UseAccountRenderControllerContextReturn & {
    getAccountRenderControllerState: () => AccountRenderControllerState
    setAccountRenderControllerStateFn: (
      updatedStateValues: Partial<AccountRenderControllerState>
    ) => void
  }

export const initialState = {
  settings: {
    shouldRenderSchool: false,
    shouldRenderUsername: false,
    shouldDisableStadiumEdits: false,
    shouldRenderOAuth: false,
    shouldRenderLocation: false,
  },
  providers: {
    shouldRender: {},
  },
}

export const useAccountRenderControllerContext =
  (): UseAccountRenderControllerContextReturn => {
    return {
      settings: {
        getSettingsComponentsToRender: determineSettingsRender,
      },
      providers: {
        getProvidersComponentsToRender: determineProvidersRender,
      },
    }
  }

export const accountRenderControllerContext =
  React.createContext<AccountRenderControllerContext>({
    settings: {
      getSettingsComponentsToRender: () => initialState.settings,
    },
    providers: {
      getProvidersComponentsToRender: () => initialState.providers,
    },
    getAccountRenderControllerState: () => initialState,
    setAccountRenderControllerStateFn: () => {},
  })

export const AccountRenderControllerProvider: React.FC = ({ children }) => {
  const [accountRenderControllerState, setAccountRenderControllerState] =
    React.useState<AccountRenderControllerState>(initialState)

  const getAccountRenderControllerState =
    useCallback((): AccountRenderControllerState => {
      return accountRenderControllerState
    }, [accountRenderControllerState])

  const setAccountRenderControllerStateFn = useCallback(
    (newState: Partial<AccountRenderControllerState>): void => {
      setAccountRenderControllerState((prevState) => ({
        ...prevState,
        ...newState,
      }))
    },
    []
  )

  const values = useAccountRenderControllerContext()

  return (
    <accountRenderControllerContext.Provider
      value={{
        ...values,
        getAccountRenderControllerState,
        setAccountRenderControllerStateFn,
      }}
    >
      {children}
    </accountRenderControllerContext.Provider>
  )
}

export const useAccountRenderControllerState = (): {
  getAccountRenderControllerState: () => AccountRenderControllerState
  setAccountRenderControllerStateFn: (
    updatedStateValues: Partial<AccountRenderControllerState>
  ) => void
} => {
  const { getAccountRenderControllerState, setAccountRenderControllerStateFn } =
    React.useContext(accountRenderControllerContext)

  return {
    getAccountRenderControllerState,
    setAccountRenderControllerStateFn,
  }
}
