import React from 'react'
import { MatchHistory } from '@plvs/rally/features/profile/match-history/MatchHistory'
import { TeamHistory } from '@plvs/rally/features/profile/team-history/TeamHistory'
import { NonLoggedInUserProvider } from '@plvs/rally/features/profile/NonLoggedInUserProvider'
import {
  ResourceType,
  UserRoleName,
  useUserProfileQuery,
  useGetLastPlayedMetaseasonIdByPlayerIdQuery,
} from '@plvs/graphql/generated'
import { WaitTillLoaded } from '@plvs/respawn/features/layout'
import { makeStyles } from '@material-ui/core'
import { NxEmptyState } from '@playvs-inc/nexus-components'

import { isNil } from 'ramda'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { NxSpot } from '@playvs-inc/nexus-spots-v2'
import { PlayerAwardsProvider } from './awards/PlayerAwardsProvider'
import { PlayerAwards } from './awards/PlayerAwards'
import { ProfileHero } from './ProfileHero/ProfileHero'
import { Highlights } from './highlights/Highlights'

export const useStyles = makeStyles((theme) => ({
  profileNotFound: {
    marginTop: theme.spacing(5),
  },
}))

interface Props {
  userId: string
}

export const Profile: React.FC<Props> = ({ userId }) => {
  const classes = useStyles()

  const flags = useFlags()
  const isGameHighlightsEnabled = flags.gameHighlights

  const { data: profileData, loading: profileLoading } = useUserProfileQuery({
    variables: { userId },
    skip: !userId,
  })

  const roles = profileData?.user?.roles ?? []
  const isProfileValid =
    !isNil(profileData) ||
    (roles.length > 0 &&
      !roles.some((role) => role?.roleName === UserRoleName.Coach))

  const {
    data: latestMetaseasonIdData,
    loading: latestMetaseasonIdLoading,
  } = useGetLastPlayedMetaseasonIdByPlayerIdQuery({
    variables: { playerId: userId },
    skip: !isProfileValid,
  })

  const latestMetaseasonId =
    latestMetaseasonIdData?.getLastPlayedMetaseasonIdByPlayerId || ''
  const user = profileData?.user

  const schoolId = profileData?.user?.roles?.find(
    (role) => role.resourceType === ResourceType.Organization
  )?.resourceId

  return (
    <WaitTillLoaded loading={profileLoading} showSpinnerWhileLoading>
      <ProfileHero
        avatarUrl={user?.avatarUrl}
        gradYear={user?.gradYear}
        id={user?.id}
        isProfileValid={isProfileValid}
        name={user?.name}
        playerProfileSnapshot={user?.playerProfileSnapshot}
        school={user?.school}
        userProviderAccounts={user?.userProviderAccounts ?? []}
      />
      {isProfileValid ? (
        <WaitTillLoaded
          loading={latestMetaseasonIdLoading}
          showSpinnerWhileLoading
        >
          <PlayerAwardsProvider userId={userId}>
            <PlayerAwards />
          </PlayerAwardsProvider>
          {isGameHighlightsEnabled && (
            <Highlights userId={userId} userName={user?.name ?? ''} />
          )}
          <NonLoggedInUserProvider schoolId={schoolId}>
            <MatchHistory
              latestMetaseasonId={latestMetaseasonId}
              userId={userId}
            />
          </NonLoggedInUserProvider>
          <NonLoggedInUserProvider schoolId={schoolId}>
            <TeamHistory
              latestMetaseasonId={latestMetaseasonId}
              userId={userId}
            />
          </NonLoggedInUserProvider>
        </WaitTillLoaded>
      ) : (
        <NxEmptyState
          className={classes.profileNotFound}
          spot={
            <NxSpot
              domain="highAlert"
              height={200}
              size="large"
              variant="secondary"
              width={200}
            />
          }
          subtitle="The profile you are trying to find does not exist. As a reminder, profiles are only available for Players."
          title="Profile Not Found"
        />
      )}
    </WaitTillLoaded>
  )
}
