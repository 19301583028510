import { CompetitionGroup, Platform } from '@plvs/graphql'
import {
  CompetitionGroupMap,
  isAssociatedToOrganization,
  teamFormatToString,
} from '@plvs/utils'
import { esportPlatformMap } from '@plvs/respawn/features/esport/creator/esportStaticDetails'

import { head, uniq, uniqBy } from 'ramda'
import {
  EsportConfig,
  FormOptions,
  UserCreateTeam,
  CompetitionGroupOption,
  PlatformOption,
  TeamFormatOption,
  GetEsportV2ConfigProps,
} from './CreateTeam.types'

export const getEsportV2Configs = ({
  esportV2,
  schoolData,
  esportSlug,
}: GetEsportV2ConfigProps): EsportConfig => {
  const orgCompetitionGroup = schoolData?.competitionGroup
  const offerings = esportV2.offerings ?? []
  const offeringsByCompetitionGroup = orgCompetitionGroup
    ? offerings.filter((offering) =>
        !isAssociatedToOrganization(offering.competitionGroup)
          ? true
          : offering.competitionGroup === orgCompetitionGroup
      )
    : offerings

  const competitionGroupOptions = uniqBy(
    (o) => o.competitionGroup,
    offeringsByCompetitionGroup?.map((o) => {
      const isOrgCompetitionGroup = isAssociatedToOrganization(
        o.competitionGroup
      )
      return {
        competitionGroup: o.competitionGroup,
        id: (isOrgCompetitionGroup ? schoolData?.id : '') || '',
      }
    })
  )

  const platformOptions = uniq(
    offeringsByCompetitionGroup.map((o) => o.platform.slug)
  )
  const teamSizeOptions = uniq(
    offeringsByCompetitionGroup.map((o) => o.teamFormat.size)
  )

  return {
    esportGroupId: esportV2?.id,
    name: esportV2.name,
    competitionGroupOptions,
    platformOptions,
    teamSizeOptions,
    esportSlug,
    rating: esportV2.rating,
  }
}

export const getCreateTeamFormOptions = ({
  esport,
}: {
  esport: EsportConfig | undefined
}): FormOptions => {
  const competitionGroupOptions = (esport?.competitionGroupOptions ?? []).map(
    (config, i) => {
      return {
        label: config.competitionGroup,
        value: config.id || `${i}`,
      }
    }
  )
  const platformOptions = (esport?.platformOptions ?? []).map((platform) => ({
    value: platform,
    label: platform,
  }))

  const teamSizeOptions = (esport?.teamSizeOptions ?? []).map((teamSize) => ({
    value: teamSize,
    label: teamSize,
  }))
  return {
    competitionGroupOptions,
    platformOptions,
    teamSizeOptions,
  }
}

export const getDefaultCreateTeamFormOption = ({
  options,
  value,
}: {
  options: Array<CompetitionGroupOption | PlatformOption | TeamFormatOption>
  value?: Platform | number | string | null
}): CompetitionGroupOption | PlatformOption | TeamFormatOption | undefined => {
  return value ? options.find((o) => o.value === value) : head(options)
}

export const getCompGroupDisplayLabel = ({
  label,
  user,
}: {
  label: CompetitionGroup
  user: UserCreateTeam
}): string => {
  if (isAssociatedToOrganization(label)) {
    return user.organization?.name || CompetitionGroupMap[label]
  }
  return user?.name
}

export const getPlatformDisplayLabel = ({
  label,
}: {
  label: Platform | null
}): string => {
  if (!label) {
    return 'All'
  }
  return esportPlatformMap[label] || ''
}

export const getTeamFormatSizeDisplayLabel = ({
  label,
}: {
  label: number
}): string => {
  return teamFormatToString({ teamFormat: label })
}

export const hasAccessToCompetitionGroup = ({
  selectedEsport,
  selectedCompetitionGroup,
  user,
}: {
  selectedEsport: EsportConfig | undefined
  selectedCompetitionGroup:
    | {
        id?: string
        competitionGroup: CompetitionGroup | null
      }
    | undefined
  user: UserCreateTeam
}): boolean => {
  const isEsportSelected = !!selectedEsport

  if (
    isEsportSelected &&
    selectedCompetitionGroup &&
    isAssociatedToOrganization(selectedCompetitionGroup?.competitionGroup)
  ) {
    return user?.organization?.id
      ? selectedCompetitionGroup?.id === user?.organization?.id
      : false
  }
  return isEsportSelected
    ? (selectedEsport?.competitionGroupOptions ?? []).some((cg) => {
        if (isAssociatedToOrganization(cg.competitionGroup)) {
          return user?.organization?.id
            ? cg.id === user?.organization?.id
            : false
        }

        return true
      })
    : true
}
