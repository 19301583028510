import { yupResolver } from '@hookform/resolvers'

import { Box, Grid, useTheme } from '@material-ui/core'
import {
  Gender,
  Maybe,
  refetchGetMyAccountDetailsQuery,
  School,
  SetAccountSettingsInput,
  User,
  UserDemographics,
  UserEmail,
  useSetMyAccountSettingsMutation,
  useSetMyUserDemographicsMutation,
  useSetUserGradYearMutation,
} from '@plvs/graphql'
import {
  formErrorToString,
  isValidPhoneNumber,
  yupFirstNameRequired,
  yupGradYearRequired,
  yupLastNameRequired,
  yupPhoneNumber,
} from '@plvs/utils'
import React, { useMemo, useState } from 'react'
import { FieldErrors, useForm } from 'react-hook-form'
import * as yup from 'yup'
import {
  NxTypography,
  NxTextInput,
  NxTextArea,
  NxButton,
} from '@playvs-inc/nexus-components'

import { ApolloError } from '@apollo/client'
import ProfanityFilter from 'bad-words'
import { useUserIdentityFn } from '@plvs/client-data/hooks'
import { useAccountRenderControllerState } from '@plvs/respawn/renderController/account/AccountRenderControllerProvider'
import { UsernameInput } from '@plvs/rally/features/auth'
import { EthnicityValue } from '@plvs/respawn/features/account/ethnicity-autocomplete/types'
import { EthnicityAutocomplete } from '@plvs/respawn/features/account/ethnicity-autocomplete/EthnicityAutocomplete'
import { GenderSelect } from '@plvs/respawn/features/account/GenderSelect'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { getRaces } from '@plvs/rally/containers/onboard/v2/onboardHelpers'
import { formatEthnicity } from '@plvs/respawn/features/account/ethnicity-autocomplete/utils'
import { DisabledToolTip } from '@plvs/respawn/features/account/DisabledToolTip'

const profanityFilter = new ProfanityFilter()

export const CoachDefaultAccountSchema = yup.object().shape({
  /* istanbul ignore next */
  additionalInfo: yup.string().test({
    name: 'additionalInfo',
    message: 'Please check the content of this field and try again.',
    /* istanbul ignore next */
    test: (value: string): boolean => {
      return !profanityFilter.isProfane(value)
    },
  }),
  firstName: yupFirstNameRequired,
  lastName: yupLastNameRequired,
  /* istanbul ignore next */
  phone: yupPhoneNumber.test({
    name: 'phone',
    message: 'Please enter a valid phone number',
    /* istanbul ignore next */
    test: isValidPhoneNumber,
  }),
  /* istanbul ignore next */
  phoneExt: yup.string().test({
    name: 'phoneExt',
    message: 'Please enter a valid extension',
    /* istanbul ignore next */
    test: (value: string): boolean => {
      return /^\d*$/.test(value)
    },
  }),
})

export function getAccountSchema({
  shouldRenderUsername,
  isStudentAtOrg,
  isFacultyAtOrg,
}): yup.ObjectSchema<Record<string, unknown>> {
  if (isFacultyAtOrg) {
    return CoachDefaultAccountSchema
  }

  if (shouldRenderUsername) {
    return yup.object().shape({
      gradYear: isStudentAtOrg
        ? yupGradYearRequired
        : yup.string().notRequired(),
      username: yup.string().required(),
    })
  }

  return yup.object().shape({
    firstName: yupFirstNameRequired,
    gradYear: isStudentAtOrg ? yupGradYearRequired : yup.string().notRequired(),
    lastName: yupLastNameRequired,
    username: yup.string().notRequired(),
  })
}

interface AccountSettingsInput {
  firstName?: string
  lastName?: string
  username?: string
  email?: string
  phone?: string
  phoneExt?: string
  workEmail?: string
  isNotCoaching?: string
  dateOfBirth?: string
  additionalInfo?: string
  state?: string
  country?: string
  gradYear?: number | null
}

const AccountSettingsForm: React.FC<{
  me: Pick<
    User,
    | 'firstName'
    | 'lastName'
    | 'gradYear'
    | 'phone'
    | 'phoneExt'
    | 'dateOfBirth'
    | 'additionalInfo'
    | 'username'
    | 'userDemographics'
    | 'state'
    | 'country'
    | 'id'
  > & {
    emails: Maybe<Array<Maybe<Pick<UserEmail, 'email' | 'isSchoolEmail'>>>>
    school: Maybe<Pick<School, 'id'>>
  } & {
    userDemographics: Maybe<UserDemographics>
  }
  isUnderage: boolean
}> = ({ me, isUnderage }) => {
  const flags = useFlags()
  const isUserDemographicsEnabled = flags.userDemographicFields

  const { isFacultyAtOrg, isStudentAtOrg, isParent } = useUserIdentityFn()

  const { getAccountRenderControllerState } = useAccountRenderControllerState()
  const {
    settings: {
      shouldRenderUsername,
      shouldDisableStadiumEdits,
      shouldRenderLocation,
    },
  } = getAccountRenderControllerState()

  const formattedEthnicities = (me?.userDemographics?.race ?? []).map(
    formatEthnicity
  )
  const [ethnicities, setEthnicities] = useState<EthnicityValue[]>(
    formattedEthnicities
  )
  const [gender, setGender] = useState<Gender | undefined>(
    me?.userDemographics?.gender ?? undefined
  )

  const {
    firstName,
    lastName,
    gradYear,
    phone,
    phoneExt,
    dateOfBirth,
    additionalInfo,
    username,
    state,
    country,
  } = me
  const theme = useTheme()

  const currentAccountSchema = useMemo(
    () =>
      getAccountSchema({
        shouldRenderUsername,
        isFacultyAtOrg,
        isStudentAtOrg,
      }),
    [shouldRenderUsername, isStudentAtOrg, isFacultyAtOrg]
  )

  const { errors, handleSubmit, register, setError: setFormError } = useForm<
    AccountSettingsInput
  >({
    defaultValues: {
      additionalInfo: additionalInfo ?? '',
      firstName: firstName ?? '',
      lastName: lastName ?? '',
      username: username ?? '',
      phone: phone ?? '',
      phoneExt: phoneExt ?? '',
      state: state ?? '',
      country: country ?? '',
      gradYear: gradYear ?? null,
    },
    resolver: yupResolver<AccountSettingsInput>(currentAccountSchema),
  })
  const fieldErrors = errors as FieldErrors

  const [error, setError] = useState<Error | string>()
  const [isSuccess, setSuccess] = useState<boolean>(false)

  const [mutate] = useSetMyAccountSettingsMutation()
  const [
    setMyUserDemographics,
    { loading: isSettingDemographics },
  ] = useSetMyUserDemographicsMutation()
  const [setUserGradYear] = useSetUserGradYearMutation()

  const onSubmit = handleSubmit(
    async (values: SetAccountSettingsInput): Promise<void> => {
      setError(undefined)
      try {
        const response = await mutate({
          refetchQueries: [refetchGetMyAccountDetailsQuery()],
          variables: {
            input: {
              additionalInfo: values.additionalInfo,
              country: values.country,
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone?.replace(/\D/g, ''),
              phoneExt: values.phoneExt?.toString(),
              state: values.state,
              username: values.username,
            },
          },
        })

        if (!!me.school?.id && values.gradYear) {
          await setUserGradYear({
            refetchQueries: [refetchGetMyAccountDetailsQuery()],
            variables: {
              userId: me.id,
              schoolId: me.school.id,
              gradYear: +values.gradYear,
            },
          })
        }

        if (gender && ethnicities.length > 0 && isUserDemographicsEnabled) {
          await setMyUserDemographics({
            variables: {
              input: { gender, race: ethnicities.map(getRaces) },
            },
          })
        }

        const setMyAccountSettings = response?.data?.setMyAccountSettings

        if (setMyAccountSettings) {
          setSuccess(true)
        }
      } catch (err: unknown) {
        const graphQLErrors = (err as ApolloError)?.graphQLErrors ?? []

        if (
          graphQLErrors.some((gqlErr) => gqlErr.message.includes('Profanity'))
        ) {
          setFormError('additionalInfo', {
            shouldFocus: true,
            message: 'Please check the content of this field and try again.',
          })
          setError(
            'Could not save profile changes. Please remove any inappropriate content and try again.'
          )
          return
        }

        setError(err as Error)
      }
    }
  )

  const handleChange = (): void => {
    setSuccess(false)
  }

  const supportText = 'Please contact support to change this information'

  const shouldRenderUsernameInput = shouldRenderUsername && !isParent

  return (
    <form
      data-cy="account-settings-form"
      noValidate
      onChange={handleChange}
      onSubmit={(e): void => {
        onSubmit(e)
      }}
    >
      {error instanceof Error && (
        <NxTypography colorToken="ColorTextError">
          Could not save profile changes. Please check that all fields are
          filled correctly.
        </NxTypography>
      )}
      {typeof error === 'string' && (
        <NxTypography colorToken="ColorTextError">{error}</NxTypography>
      )}
      <Box mt={2}>
        <Grid container spacing={1}>
          {(isUserDemographicsEnabled || !shouldRenderUsername) && (
            <>
              <Grid item sm={6} xs={12}>
                <DisabledToolTip
                  disabled={!shouldDisableStadiumEdits}
                  tooltipContent={supportText}
                >
                  <NxTextInput
                    ref={register}
                    data-testid="first-name"
                    defaultValue={firstName ?? ''}
                    disabled={shouldDisableStadiumEdits}
                    fullWidth
                    helperText={
                      shouldDisableStadiumEdits
                        ? ''
                        : formErrorToString(errors.firstName)
                    }
                    label="First Name"
                    name="firstName"
                    type="text"
                    variant={errors.firstName ? 'error' : 'default'}
                  />
                </DisabledToolTip>
              </Grid>

              <Grid item sm={6} xs={12}>
                <DisabledToolTip
                  disabled={!shouldDisableStadiumEdits}
                  tooltipContent={supportText}
                >
                  <NxTextInput
                    ref={register}
                    data-testid="last-name"
                    defaultValue={lastName ?? ''}
                    disabled={shouldDisableStadiumEdits}
                    fullWidth
                    helperText={
                      shouldDisableStadiumEdits
                        ? ''
                        : formErrorToString(errors.lastName)
                    }
                    label="Last Name"
                    name="lastName"
                    type="text"
                    variant={errors.lastName ? 'error' : 'default'}
                  />
                </DisabledToolTip>
              </Grid>
            </>
          )}
          {shouldRenderUsernameInput && (
            <Grid item sm={6} xs={12}>
              <UsernameInput
                ref={register}
                error={fieldErrors.username}
                style={{ height: '48px' }}
              />
            </Grid>
          )}
          {isFacultyAtOrg && (
            <Grid item sm={6} xs={12}>
              <Box
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                gridGap={`${theme.spacing(1)}px`}
              >
                <Box flexBasis="10em" flexGrow={3}>
                  {/* // This is a controlled input, because we want to display formatted phone number. */}
                  <NxTextInput
                    ref={register}
                    fullWidth
                    helperText={formErrorToString(errors.phone)}
                    label="Phone Number"
                    name="phone"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    type="tel"
                    variant={errors.phone ? 'error' : 'default'}
                  />
                </Box>
                <Box flexBasis="8em" flexGrow={1}>
                  <NxTextInput
                    ref={register}
                    defaultValue={phoneExt || ''}
                    fullWidth
                    helperText={formErrorToString(errors.phoneExt)}
                    label="Phone Ext"
                    name="phoneExt"
                    type="text"
                    variant={errors.phoneExt ? 'error' : 'default'}
                  />
                </Box>
              </Box>
            </Grid>
          )}
          {isStudentAtOrg ? (
            <Grid item sm={6} xs={12}>
              <NxTextInput
                ref={register}
                defaultValue={gradYear || ''}
                fullWidth
                helperText={formErrorToString(errors.gradYear)}
                label="Graduation Year"
                name="gradYear"
                type="text"
                variant={errors.gradYear ? 'error' : 'default'}
              />
            </Grid>
          ) : null}
          <Grid item sm={6} xs={12}>
            <DisabledToolTip
              disabled={!shouldDisableStadiumEdits}
              tooltipContent={supportText}
            >
              <NxTextInput
                defaultValue={dateOfBirth || ''}
                disabled
                fullWidth
                helperText={formErrorToString(errors.dateOfBirth)}
                label="Date of Birth"
                type="text"
                variant="default"
              />
            </DisabledToolTip>
          </Grid>

          {isUserDemographicsEnabled && (
            <>
              {shouldRenderLocation && (
                <>
                  <Grid item sm={6} xs={12}>
                    <DisabledToolTip
                      disabled={!shouldDisableStadiumEdits}
                      tooltipContent={supportText}
                    >
                      <NxTextInput
                        ref={register}
                        data-testid="country"
                        defaultValue={country ?? ''}
                        disabled
                        fullWidth
                        label="Country"
                        name="country"
                        type="text"
                        variant="default"
                      />
                    </DisabledToolTip>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <DisabledToolTip
                      disabled={!shouldDisableStadiumEdits}
                      tooltipContent={supportText}
                    >
                      <NxTextInput
                        ref={register}
                        data-testid="state"
                        defaultValue={state ?? ''}
                        disabled
                        fullWidth
                        label="Select State"
                        name="state"
                        type="text"
                        variant="default"
                      />
                    </DisabledToolTip>
                  </Grid>
                </>
              )}

              {!isUnderage && (
                <>
                  <Grid item sm={6} xs={12}>
                    <EthnicityAutocomplete
                      onChange={setEthnicities}
                      values={ethnicities}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <GenderSelect
                      fullWidth
                      onChange={setGender}
                      value={gender}
                    />
                  </Grid>
                </>
              )}
            </>
          )}

          {isFacultyAtOrg && (
            <Grid item sm={6} xs={12}>
              <NxTextArea
                ref={register}
                defaultValue={additionalInfo ?? ''}
                fullWidth
                helperText={
                  errors.additionalInfo
                    ? formErrorToString(errors.additionalInfo)
                    : 'This info will display to other coaches in the Match Lobby & Team Profile pages.'
                }
                label="Additional Info"
                maxLength={300}
                name="additionalInfo"
                placeholder="(Preferred contact method, best time to contact, etc.)"
                rows={5}
                type="text"
                variant={errors.additionalInfo ? 'error' : 'default'}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <NxButton
          disabled={isSuccess || isSettingDemographics}
          label={isSuccess ? 'Changes Saved' : 'Save Changes'}
          type="submit"
          variant="primary"
        />
      </Box>
    </form>
  )
}

export default AccountSettingsForm
