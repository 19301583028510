import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core'
import {
  SingleEliminationBracket,
  createTheme,
} from '@g-loot/react-tournament-brackets'
import { Box } from '@plvs/respawn/features/layout'
import { EsportSlug } from '@plvs/graphql/types'
import { useEsport as useNexusEsport } from '@playvs-inc/nexus-theme'
import { NxBracketMatch } from './BracketMatch'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
}))

export function getRoundName(
  roundNumber: number,
  totalNumRounds: number
): string {
  if (roundNumber === totalNumRounds) {
    return 'Final'
  }
  if (roundNumber + 1 === totalNumRounds) {
    return 'Semi Final'
  }
  return `Round ${roundNumber}`
}

export interface BracketTeam {
  id: string
  seed: number | null
  status: string | null
  displayName: string | null
  picture: string | null
  schoolName?: string | null
  score: number | null
}

export interface BracketMatch {
  id: string
  matchId: string
  name: string
  nextMatchId: string | null
  tournamentRoundText: string
  startTime: string
  participants?: (BracketTeam | null)[]
}

export interface BracketProps {
  matches: BracketMatch[]
  esportSlug: EsportSlug
}

export function NxBracket({
  matches,
  esportSlug,
}: BracketProps): React.ReactElement {
  const classes = useStyles()
  const theme = useTheme()
  const bracketTheme = createTheme({
    fontFamily: theme.typography.fontFamily,
    roundHeaders: {
      background: theme.palette.ColorBackgroundBase,
    },
  })

  const esport = useNexusEsport(esportSlug)
  const overlayColor = esport?.palette.baseColor || ''

  return (
    <Box className={classes.container}>
      <SingleEliminationBracket
        matchComponent={(
          params
        ): React.ReactElement<
          any,
          string | React.JSXElementConstructor<any>
        > => {
          return NxBracketMatch({ ...params, esportColor: overlayColor })
        }}
        matches={matches}
        options={{
          style: {
            roundHeader: {
              fontFamily: theme.typography.fontFamily,
              fontSize: 20.25,
              fontColor: theme.palette.ColorTextBase,
              roundTextGenerator: getRoundName,
            },
            connectorColor: theme.palette.BorderMedium,
            connectorColorHighlight: overlayColor,
            boxHeight: 158,
            width: 363,
          },
        }}
        theme={bracketTheme}
      />
    </Box>
  )
}
