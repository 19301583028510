import React from 'react'
import { Grid, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import { includes } from 'ramda'
import tz from 'dayjs/plugin/timezone'

import { NxEsportIcon, NxTypography } from '@playvs-inc/nexus-components'

import dayjs from '@plvs/respawn/init/dayjs'
import { Box } from '@plvs/respawn/features/layout'
import { EsportRating, MatchStatus } from '@plvs/graphql/generated'
import { Path, SHORT_MONTH_AND_DAY_AND_DOW } from '@plvs/const'
import {
  getInvalidMatchResults,
  INVALID_RESULTS_MATCH_STATUSES,
} from '@plvs/rally/features/explore/LeagueMatches/leagueMatchesHelpers'
import { MatchRowMobile } from '@plvs/rally/features/explore/LeagueMatches/MatchRow/MatchRowMobile'
import { LiveIndicator } from '@plvs/rally/features/app/drawer/matches/LiveIndicator'
import { LeagueMatchTeamCluster } from '@plvs/rally/features/explore/LeagueMatches/LeagueMatchTeamCluster'
import { MatchResults } from '@plvs/rally/features/explore/LeagueMatches/MatchResults'
import { useGeneralEsportAdapter } from '@plvs/respawn/features/esport/creator'
import { PreseasonBadge, ScrimmageBadge } from '@plvs/rally/components/badge'
import { defaultTo0, isMatchPreseason } from '@plvs/utils'
import { OverflowTooltip } from '@plvs/respawn/components/OverflowTooltip'
import { MappedMatch } from '@plvs/rally/features/match/schedule/schedule'
import { useNxEsportsContext } from '@playvs-inc/nexus-theme'
import { useStyles } from './MatchRow.styles'

dayjs.extend(tz)

interface MatchRowProps {
  className?: string
  match: MappedMatch
  showEsportAccent?: boolean
  actions?: React.ReactNode
  showFullDate?: boolean
  isMobile?: boolean
  isQueueMatch?: boolean
}

export const MatchRow: React.FC<MatchRowProps> = ({
  className,
  match,
  showEsportAccent = false,
  showFullDate = false,
  isQueueMatch = false,
  actions,
  isMobile = false,
}) => {
  const theme = useTheme()
  const esportSlug = match?.esport?.slug
  const { name: esportName } = useGeneralEsportAdapter(esportSlug)
  const now = Date.now()

  const isMatchLive =
    match?.status === MatchStatus.Live || match?.status === MatchStatus.Open

  const esportPalettes = useNxEsportsContext()
  const classes = useStyles({
    isMatchLive,
    showEsportAccent,
    esportSlug,
    esportPalettes,
  })

  const matchDate = dayjs(match?.scheduledStartsAt).format(
    SHORT_MONTH_AND_DAY_AND_DOW
  )
  const matchTime = dayjs(match?.scheduledStartsAt).format('h:mm A')

  const matchResults = match?.matchResults ?? []

  const myTeam = match?.team1?.isMyTeam ? match?.team1 : match?.team2
  const oppTeam = match?.team1?.isMyTeam ? match?.team2 : match?.team1

  const isPastMatch =
    !isMatchLive && dayjs(match?.scheduledStartsAt).isBefore(now)

  const hasValidResults = !includes(
    match?.status,
    INVALID_RESULTS_MATCH_STATUSES
  )

  const myMatchResults = matchResults?.find(
    ({ team }) => team?.id === myTeam?.id
  )
  const oppMatchResults = matchResults?.find(
    ({ team }) => team?.id === oppTeam?.id
  )
  const myTeamLost =
    hasValidResults && isPastMatch && myMatchResults?.placing === 2
  const oppTeamLost =
    hasValidResults && isPastMatch && oppMatchResults?.placing === 2

  const isRestricted = match?.esport?.rating === EsportRating.Restricted

  let badge
  if (match.isScrimmage) {
    badge = <ScrimmageBadge size="medium" />
  }
  if (isMatchPreseason(match)) {
    badge = <PreseasonBadge />
  }

  const matchDateTime = (
    <Box mb={0.5}>
      {isMatchLive ? (
        <LiveIndicator
          isLive={isMatchLive}
          typographyClassName={classes.liveText}
          typographyVariant="body2"
        />
      ) : (
        <>
          {showFullDate && (
            <NxTypography
              className={clsx(classes.matchDate, classes.matchDateSection)}
              data-testid="matchDate"
              variant="body2"
            >
              {matchDate}
            </NxTypography>
          )}
          <NxTypography className={classes.matchDateSection} variant="body2">
            {matchTime}
          </NxTypography>
        </>
      )}
    </Box>
  )

  const matchEsport = (
    <Box alignItems="center" display="flex" gridGap={4}>
      <NxEsportIcon
        esportSlug={esportSlug ?? ''}
        height="20"
        mode={theme.palette.type}
        width="20"
      />
      <NxTypography colorToken="ColorTextAlt" noWrap variant="body3">
        <OverflowTooltip>{esportName}</OverflowTooltip>
      </NxTypography>
    </Box>
  )
  const middleSection = (
    <Box
      alignItems="center"
      className={classes.matchResults}
      display="flex"
      justifyContent="center"
    >
      {isPastMatch ? (
        <>
          {hasValidResults ? (
            <MatchResults
              leftTeam={{
                score: defaultTo0(myMatchResults?.gamesWon),
                placing: defaultTo0(myMatchResults?.placing),
              }}
              rightTeam={{
                score: defaultTo0(oppMatchResults?.gamesWon),
                placing: defaultTo0(oppMatchResults?.placing),
              }}
            />
          ) : (
            getInvalidMatchResults(match?.status)
          )}
        </>
      ) : (
        badge || <NxTypography variant="subtitle2">VS</NxTypography>
      )}
    </Box>
  )

  return (
    <>
      {isMobile ? (
        <MatchRowMobile
          actions={actions}
          className={className}
          isQueueMatch={isQueueMatch}
          match={match}
          showEsportAccent={showEsportAccent}
        />
      ) : (
        <Box
          className={clsx(classes.root, className)}
          data-cy="teamMatchRow"
          data-testid="match-row"
          px={3}
          py={2}
        >
          <Grid
            alignItems="center"
            container
            direction="row"
            onClick={(): void => {
              if (actions) {
                return
              }

              window.open(`${Path.Match}/${match.id}`, '_blank')
            }}
          >
            <Grid item xs={2}>
              {matchDateTime}
              {matchEsport}
            </Grid>

            <Grid item xs={3}>
              <LeagueMatchTeamCluster
                didTeamLose={myTeamLost}
                isLeftSide
                isRestrictedEsport={isRestricted}
                matchStatus={match?.status}
                team={myTeam}
              />
            </Grid>

            <Grid item xs={2}>
              {middleSection}
            </Grid>

            <Grid item xs={actions ? 3 : 4}>
              <LeagueMatchTeamCluster
                didTeamLose={oppTeamLost}
                isLeftSide={false}
                isRestrictedEsport={isRestricted}
                matchStatus={match?.status}
                team={oppTeam}
              />
            </Grid>

            {actions && (
              <Grid item xs={2}>
                {actions}
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </>
  )
}
